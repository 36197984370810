import React from 'react'
import { connect } from 'react-redux'
import { Store } from '../../../modules/rootReducer'
import { Redirect, RouteChildrenProps } from 'react-router-dom'
import { ROASReport } from './ROASReport'
import { AdsAndRevenueWrapper, UpgradePlanScreen } from '../shared'
import { CompanyFeaturesPayload } from '../../../modules/companyFeatures'
import { WomanCoinIcon } from '../../../components/designSystem/assets/WomanCoinIcon/WomanCoinIcon'
import { hasReturnOnAdSpendFeature } from '../../../util/companyFeatures'
import { Layout } from '../../../layout'

type MyProps = {
  companyFeatures: CompanyFeaturesPayload
}

type MyParams = {
  interval: 'day' | 'month'
}

const RoasImpl: React.FunctionComponent<
  MyProps & RouteChildrenProps<MyParams>
> = ({ match, companyFeatures }) => {
  const interval = match?.params.interval

  if (interval !== 'day' && interval !== 'month') {
    return <Redirect to="/roas" />
  }

  return (
    <>
      {hasReturnOnAdSpendFeature(companyFeatures) ? (
        <AdsAndRevenueWrapper
          title={'Start Tracking Your Return On Ad Spend'}
          renderReport={(props): JSX.Element => (
            <ROASReport interval={interval} {...props} />
          )}
        />
      ) : (
        <Layout>
          <UpgradePlanScreen
            title="Start Tracking Your Return On Ad Spend"
            subtitle="Monitor ad performance by channel and determine whether you are meeting return on ad spend targets."
            renderIcon={(): React.ReactNode => (
              <WomanCoinIcon height={170} width={167} />
            )}
          />
        </Layout>
      )}
    </>
  )
}

const mapStateToProps = (state: Store): MyProps => {
  return {
    companyFeatures: state.companyFeatures.companyFeatures,
  }
}

export const RoasScreen = connect(mapStateToProps)(RoasImpl)
