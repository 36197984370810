import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { trackPage } from '../lib/analytics'
import { Store } from '../modules/rootReducer'

interface TrackedRouteProps extends RouteProps {
  title: string
  userEmail: string
}

const TrackedRouteImpl: React.FC<TrackedRouteProps> = ({
  title,
  userEmail,
  location,
  children,
  ...rest
}) => {
  const isClientSide = typeof window !== 'undefined'

  useEffect(() => {
    if (!location?.pathname || !isClientSide) return
    trackPage(location.pathname, title, userEmail)
  }, [location?.pathname, title, userEmail, isClientSide])

  return <Route {...rest}>{children}</Route>
}

const mapStateToProps = (
  store: Store,
): Pick<TrackedRouteProps, 'userEmail'> => ({
  userEmail: store.user.data.login,
})

export const TrackedRoute = connect(mapStateToProps)(TrackedRouteImpl)
