import React from 'react'
import { CompanyToAddRelation } from '../types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

interface Props {
  companyToAddRelation: CompanyToAddRelation
}
export const CompanySummary = (props: Props): JSX.Element => {
  const { companyToAddRelation } = props
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Company Name</strong>
            </TableCell>
            <TableCell>{companyToAddRelation.company.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Company ID:</strong>
            </TableCell>
            <TableCell>{companyToAddRelation.company.id}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
