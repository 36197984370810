import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

type Props = {
  renderIcon: () => React.ReactNode
  title: string
  subtitle: string | JSX.Element
  emailLink?: React.ReactNode
  children?: React.ReactNode
  message?: string
  className?: string
}

type UserGuidanceBaseT = React.FunctionComponent<Props> & {
  Grid: typeof Grid
}

export const UserGuidanceBase: UserGuidanceBaseT = ({
  children,
  renderIcon,
  title,
  subtitle,
  emailLink,
  message,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {renderIcon()}
        <Typography
          variant="h6"
          sx={{
            marginTop: '50px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            margin: '34px 0 23px 0',
            textAlign: 'center',
            maxWidth: '565px',
          }}
        >
          {subtitle}
          {emailLink}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        {children}
      </Grid>
    </Box>
  )
}

UserGuidanceBase.Grid = Grid
