import React, { useState } from 'react'

import { alpha, styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { Color } from '../../../../Color'

type Props = {
  description: React.ReactNode
}

const DarkArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: alpha(Color.Black, 0.8),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(Color.Black, 0.8),
    color: Color.White,
    maxWidth: 500,
  },
})

export const InfoTooltip = ({ description }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <DarkArrowTooltip
      key="widget-tooltip"
      title={description}
      onClick={(): void => setIsOpen(true)}
      onMouseOver={(): void => setIsOpen(true)}
      onMouseLeave={(): void => setIsOpen(false)}
      onClose={(): void => setIsOpen(false)}
      open={isOpen}
    >
      <InfoIcon color="action" sx={{ fontSize: 12 }} />
    </DarkArrowTooltip>
  )
}
