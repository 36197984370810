import { addDays, subDays } from 'date-fns'
// NOTE: there is a sister date-fns-tz but it doesn't always
//  get the timezone conversion right in some edge cases
//  DO NOT USE date-fns-tz _for conversion_, hence Intl _for conversion_
// HOWEVER, Intl.DateTimeFormat { timeZoneName: 'long' } sometimes returns a UTC and sometimes a long name
// So finding out the UTC offset for a date/time + timeZone isn't reliable
//  DO NOT USE Intl _for getting an offset_, use date-fns-tz _for getting an offset_
import { format as dfzFormat } from 'date-fns-tz'

/**
 * Class to maintain a particular timezone and then format / adjust per that tz
 */
export class TimezonedTime {
  private partsToUse = [
    'month',
    'day',
    'year',
    'hour',
    'minute',
    'second',
    'timeZoneName',
  ]

  private leadingZeros = (source: string): string =>
    '00'.concat(source).substring(source.length)

  // eslint-disable-next-line no-useless-constructor
  constructor(protected readonly timezoneName: string) {}

  formatterObject?: Intl.DateTimeFormat
  get formatter(): Intl.DateTimeFormat {
    if (!this.formatterObject) {
      this.formatterObject = Intl.DateTimeFormat('en-US', {
        timeZone: this.timezoneName,
        hour12: false,
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: '2-digit',
        timeZoneName: 'long',
      })
    }
    return this.formatterObject
  }

  tzOffset = (source: Date): string | undefined => {
    return dfzFormat(
      source,
      // NOTE(ivy): XXX is the ISO 8601-formatted timezone offset (e.g. -04:00)
      'XXX',
      { timeZone: this.timezoneName },
    )
  }

  // tried several libraries, landed on Intl to get the parts correctly
  parseInTimezone = (source: Date, parseWithTimezone: boolean): Date => {
    const parts = this.formatter.formatToParts(source)
    const values: { [key: string]: any } = {}

    parts.forEach((part: { type: string; value: string }) => {
      if (this.partsToUse.includes(part.type)) {
        values[part.type] = part.value as any
      }
    })

    // XXX(ivy): NodeJS 14.x is locale-dependent which can sometimes result in
    // an hour of "24". See https://github.com/nodejs/node/issues/33089
    const baseValue = `${values.year}-${this.leadingZeros(
      values.month,
    )}-${this.leadingZeros(values.day)}T${
      values.hour === '24' ? '00' : values.hour
    }:${values.minute}:${values.second}`

    return new Date(
      parseWithTimezone ? `${baseValue}${this.tzOffset(source)}` : baseValue,
    )
  }

  startOfDay = (source: Date): Date => {
    const newResult = this.parseInTimezone(new Date(source.getTime()), false)
    newResult.setHours(0, 0, 0, 0)
    return newResult
  }

  startOfToday = (): Date => {
    return this.startOfDay(new Date())
  }

  startOfYesterday = (): Date => subDays(this.startOfToday(), 1)

  startOfTomorrow = (): Date => addDays(this.startOfToday(), 1)
}
