import React from 'react'
import Grid from '@mui/material/Grid'

import { TimeParams } from '../../Overview.types'
import { useOverviewHighlightBar } from './OverviewHighlightBar.hooks'
import { HighlightsBar } from '../../../../../components/designSystem/organisms/HighlightsBar/HighlightsBar'

export const OverviewHighlightBar: React.FunctionComponent<TimeParams> = ({
  duration,
  granularity,
}) => {
  const {
    highlightCategories,
    isLoading,
    shouldHide,
  } = useOverviewHighlightBar({
    duration,
    granularity,
  })

  if (shouldHide) return null

  return (
    <Grid item xs={12} lg={12}>
      <HighlightsBar categories={highlightCategories} isLoading={isLoading} />
    </Grid>
  )
}
