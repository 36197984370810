import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { MenuItem } from '@mui/material'
import { StyledTextField } from '../../atoms/TextFieldSelect/TextFieldSelect'
import { Color } from '../../../../Color'
import { TimePeriodFilterProps } from './TimePeriodFilter.types'
import {
  getDurationOptions,
  getInitialFilterOption,
} from './TimePeriodFilter.utils'

export const TimePeriodFilter = <K extends string | number>({
  filterOptions,
  handleSubmit,
  initialDuration,
  initialGranularity,
}: TimePeriodFilterProps<K>): JSX.Element => {
  const initialState = getInitialFilterOption(filterOptions, initialGranularity)

  const [timePeriod, setTimePeriod] = useState({
    granularity: initialState.granularity,
    duration: initialDuration || initialState.defaultDuration,
  })

  const [durationOptions, setDurationOptions] = useState(
    getDurationOptions(initialState.minDuration, initialState.maxDuration),
  )

  const handleGranularityChange = (value: string): void => {
    const newState =
      filterOptions.find(({ granularity }) => granularity === value) ||
      initialState

    setDurationOptions(
      getDurationOptions(newState.minDuration, newState.maxDuration),
    )

    const shouldUpdateDuration =
      timePeriod.duration > newState.maxDuration ||
      (newState.minDuration && timePeriod.duration < newState.minDuration)

    setTimePeriod({
      duration: shouldUpdateDuration
        ? newState.defaultDuration
        : timePeriod.duration,
      granularity: newState.granularity,
    })
  }

  const handleDurationChange = (value: string): void => {
    setTimePeriod({
      ...timePeriod,
      duration: parseInt(value),
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '25px',
        height: '25px',
      }}
    >
      <StyledTextField
        label="Time Period"
        value={timePeriod.granularity}
        onChange={(event): void => handleGranularityChange(event.target.value)}
      >
        {filterOptions.map(({ granularityLabel, granularity }) => (
          <MenuItem
            key={`granularity-menu-${granularityLabel}`}
            value={granularity}
          >
            {granularityLabel}
          </MenuItem>
        ))}
      </StyledTextField>
      <StyledTextField
        label="Number of Time Periods"
        value={timePeriod.duration}
        onChange={(event): void => handleDurationChange(event.target.value)}
      >
        {durationOptions.map(value => (
          <MenuItem key={`duration-menu-${value}`} value={value}>
            {value}
          </MenuItem>
        ))}
      </StyledTextField>
      <Button
        variant="contained"
        endIcon={<ArrowCircleRightIcon fontSize="small" />}
        onClick={(): void =>
          handleSubmit(timePeriod.granularity, timePeriod.duration)
        }
        sx={{ width: '100px', height: '48px', backgroundColor: Color.Green }}
      >
        Apply
      </Button>
    </Box>
  )
}
