import {
  TimeSeriesChannel,
  useGetCashPositionSeriesByDurationQuery,
} from '../../../../../../server/app-data-service/generatedTypes'
import { TimeParams } from '../../Overview.types'

export const useOverviewBankingCard = ({
  duration,
  granularity,
}: TimeParams) => {
  const { loading, error, data } = useGetCashPositionSeriesByDurationQuery({
    variables: { duration, granularity },
  })

  const cashBalance = data?.getCashPositionSeriesByDuration?.balanceCents
    ?.totals ?? {
    total: null,
    timeSeries: null,
  }

  const cashBalanceKeys: Array<
    'balanceCents' | 'cashInflowCents' | 'cashOutflowCents'
  > = ['balanceCents', 'cashInflowCents', 'cashOutflowCents']

  const channels = cashBalanceKeys
    .map(key => {
      const channel =
        data?.getCashPositionSeriesByDuration?.[key]?.channels?.[0] ?? null

      if (!channel) {
        return null
      }

      if (key !== 'balanceCents' && channel?.timeSeries) {
        return {
          ...channel,
          timeSeries: channel.timeSeries.map(entry => ({
            ...entry,
            value: Math.abs(entry?.value ?? 0),
          })),
        }
      }

      return channel
    })
    .filter((channel): channel is TimeSeriesChannel => !!channel)

  const hasNoData =
    channels.reduce((acc, channel) => {
      const timeSeriesSum =
        channel.timeSeries?.reduce(
          (innerAcc, entry) => innerAcc + (entry?.value || 0),
          0,
        ) || 0
      return acc + timeSeriesSum
    }, 0) === 0

  return {
    channels,
    isEmpty: hasNoData || !!error,
    isLoading: loading,
    totals: cashBalance,
  }
}
