import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'

export const PrimaryText = styled('p')`
  color: ${Color.Black};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 40px;
  padding: 0.219rem;
`

export const SecondaryText = styled('p')`
  color: ${Color.LightGrey};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 40px;
  padding: 0.219rem;
`

export const ContainerTitleText = styled('p')`
  color: ${Color.Black};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
`

export const IntroText = styled('div')`
  font-size: 15px;
  margin-bottom: 32px;
  width: 700px;
`

export const IntroTextBold = styled(IntroText)({
  fontWeight: 500,
  marginTop: '20px',
})

export const ChartInfoText = styled('p')`
  color: ${Color.VioletEggplant};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  padding: 0px;
  margin: 0px;
`
