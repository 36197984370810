import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { App } from './App'
import { bootstrap } from './modules/bootstrap'
import { dispatch, store } from './store'
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import {
  SentryErrorBoundary,
  SentryErrorComponentProps,
} from './components/SentryErrorBoundary'
import { materialUITheme } from './muiTheme'
import { createEmotionCache } from './util/createEmotionCache'

dispatch(bootstrap())
const clientEmotionCache = createEmotionCache()

ReactDOM.render(
  <Provider store={store}>
    <CacheProvider value={clientEmotionCache}>
      <ThemeProvider theme={materialUITheme}>
        <SentryErrorBoundary
          errorComponent={({
            eventId,
          }: SentryErrorComponentProps): JSX.Element => (
            <p>
              Sorry, an error occurred{eventId && ` (${eventId})`}. We&apos;ve
              alerted people to check it out ASAP.
            </p>
          )}
        >
          <Router>
            <App />
          </Router>
        </SentryErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
  </Provider>,
  document.getElementById('root'),
)
