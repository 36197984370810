import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'
import { Color } from '../../../../Color'

import { InfoTooltip } from '../InfoTooltip/InfoTooltip'
import { TrendLabel } from '../../atoms/TrendLabel/TrendLabel'
import { TrendTarget } from '../../atoms/TrendLabel/types'

interface Props {
  label: string
  value: number | string
  percentChangeFromPrevious: number | null
  percentChangeFromAverage: number | null
  target?: TrendTarget
  description: React.ReactNode
  sx?: SxProps<Theme>
}

export const HighlightsCard = ({
  label,
  value,
  percentChangeFromPrevious,
  percentChangeFromAverage,
  target = TrendTarget.positive,
  description,
  sx,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      padding: '10px 15px',
      borderRadius: '8px',
      border: `3px solid ${Color.LightestGrey}`,
      ...sx,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="caption" color={Color.LightGrey}>
        {label}
      </Typography>
      <InfoTooltip description={description} />
    </Box>
    <Typography variant="h5">{value}</Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <TrendLabel trendPercent={percentChangeFromPrevious} target={target} />
      <Typography variant="caption" color={Color.DarkGrey}>
        vs Previous
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <TrendLabel trendPercent={percentChangeFromAverage} target={target} />
      <Typography variant="caption" color={Color.DarkGrey}>
        vs Average
      </Typography>
    </Box>
  </Box>
)
