import React from 'react'
import { styled } from '@mui/material/styles'
import { Store } from '../modules/rootReducer'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Navigation } from '../components/designSystem/organisms/Navigation/Navigation'
import { useDispatchApiCall } from '../hooks'
import { Color } from '../Color'
import { CompanyFeaturesPayload } from '../modules/companyFeatures'
import { getCompanyFeatures } from '../modules/companyFeatures/companyFeatures'
import {
  UIFeatures,
  hasCashPositionFeature,
  hasLifetimeCustomerValueFeature,
  hasProductDemandForecastFeature,
  hasReturnOnAdSpendFeature,
  hasRevenueForecastFeature,
} from '../util/companyFeatures'
export * from '../components/designSystem/molecules/Headline/Headline'
export * from '../components/designSystem/atoms/Text/Text'

import Box from '@mui/material/Box'
import { HeroHeader } from '../components/designSystem/molecules/HeroHeader/HeroHeader'
import { urlPathPrefixToHeroHeaderPropsMap } from './Layout.consts'

type StateProps = {
  activeEmail: string
  companyName: string
  companyFeatures: CompanyFeaturesPayload
}

type Props = {
  children?: React.ReactNode
  showSidebar?: boolean
  background?: string
  activeEmail: string
  companyName: string
  paddingRight?: boolean
  shouldCenter?: boolean
  companyFeatures: CompanyFeaturesPayload
}

const Main = styled('main', {
  shouldForwardProp: (prop: any): boolean =>
    prop !== 'centered' &&
    prop !== 'background' &&
    prop !== 'marginBottom' &&
    prop !== 'paddingRight',
})<{
  centered: boolean
  background: string
  marginBottom: boolean
  paddingRight?: boolean
}>(
  ({ centered, background, paddingRight }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  padding: ${paddingRight ? '1.75rem' : '1.75rem 0 1.75rem 1.75rem'};
  background: ${background};
  ${
    centered
      ? `
          align-items: center;
          justify-content: center;
        `
      : ''
  }
`,
)

// NOTE(jose):
//   paddings made to calculate a `max-width: 1024px`
//   without centering through `margin: auto`
const Padding = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const LayoutComponent: React.FunctionComponent<Props> = ({
  children,
  showSidebar = true,
  background = Color.White,
  paddingRight = true,
  shouldCenter,
  companyName,
  activeEmail,
  companyFeatures,
}) => {
  useDispatchApiCall([getCompanyFeatures])
  const isInternalUser = /@brightflow.ai$/.test(activeEmail)
  // If the features are loading do not show the lock icon so it appears once loaded(small milliseconds)
  // Not moving the isLoading logic to the shared method because that is going to cause re-renders on the report screen
  const uiFeatures: UIFeatures = {
    shouldHideLtv: !companyFeatures.companyFeatures
      ? false
      : !hasLifetimeCustomerValueFeature(companyFeatures),
    shouldHideRoas: !companyFeatures.companyFeatures
      ? false
      : !hasReturnOnAdSpendFeature(companyFeatures),
    shouldHideSalesForecast: !companyFeatures.companyFeatures
      ? false
      : !hasRevenueForecastFeature(companyFeatures),
    shouldHideProductDemand: !companyFeatures.companyFeatures
      ? false
      : !hasProductDemandForecastFeature(companyFeatures),
    shouldHideCashPosition: !companyFeatures.companyFeatures
      ? false
      : !hasCashPositionFeature(companyFeatures),
    shouldHideInsights: !isInternalUser,
  }
  // Get the copy for the HeroHeader for each page from the url path
  const { pathname } = useLocation()
  const urlPathPrefix =
    pathname.indexOf('/', 1) > 0
      ? pathname.slice(0, pathname.indexOf('/', 1))
      : pathname
  const heroHeaderProps = urlPathPrefixToHeroHeaderPropsMap[urlPathPrefix]
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: { xs: 'column', lg: 'row' },
          overflow: 'scroll',
        }}
      >
        {showSidebar && (
          <Navigation
            companyName={companyName}
            activeEmail={activeEmail}
            uiFeatures={uiFeatures}
          />
        )}
        <Main
          centered={shouldCenter ?? !showSidebar}
          marginBottom={showSidebar}
          background={background}
          paddingRight={paddingRight}
        >
          {showSidebar ? (
            <Padding>
              {heroHeaderProps && <HeroHeader {...heroHeaderProps} />}
              {children}
            </Padding>
          ) : (
            <div>{children}</div>
          )}
        </Main>
      </Box>
    </>
  )
}

const mapStateToProps = (state: Store): StateProps => ({
  activeEmail: state.user.data.login,
  companyName: state.companies.data.name,
  companyFeatures: state.companyFeatures.companyFeatures,
})

export const Layout = connect(mapStateToProps)(LayoutComponent)
