import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Box from '@mui/material/Box'

import { UserGuidanceBase } from '../../../../shared'
import { LoadingCircular } from '../../../../../components/designSystem/atoms/Loading/Loading'
import { Caution } from '../../../../../components/designSystem/assets/Caution/Caution'
import { Color } from '../../../../../../ui/Color'
import { useCashPosition } from '../../../cashPosition/CashPositionProvider'
import { useGetInsightQuery } from '../../../../../../server/app-data-service/generatedTypes'

type Insight = {
  insight: string
  // eslint-disable-next-line camelcase
  data_used: string
}
type Props = {
  isLoading: boolean
}

export const InsightsCard = ({ isLoading }: Props): JSX.Element => {
  const { duration, granularity } = useCashPosition()

  const { data, loading: loadingInsights } = useGetInsightQuery({
    variables: {
      duration,
      granularity,
    },
  })
  try {
    const res: Insight[] = JSON.parse(data?.InsightResult?.content || '[]')
    return !isLoading && !loadingInsights ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '48px',
          marginLeft: -6,
          marginTop: -3,
        }}
      >
        {res.map(val => (
          <Card
            sx={{
              padding: '24px',
              backgroundColor: Color.White,
              borderRadius: '20px',
              border: `1px solid ${Color.LightGrey}`,
              maxWidth: 'xl',
            }}
            key={val.insight}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: '400', color: Color.LightGrey }}
            >
              {val.insight}
            </Typography>
            <CardActions disableSpacing sx={{ marginLeft: '-7px' }}>
              <Typography variant="caption" color={Color.Green2}>
                <b>Data used:</b> {val.data_used}
              </Typography>
            </CardActions>
          </Card>
        ))}
      </Box>
    ) : (
      <LoadingCircular />
    )
  } catch (err) {
    console.error(err)
    return (
      <UserGuidanceBase
        renderIcon={(): React.ReactNode => <Caution height={98} width={114} />}
        title="Insights are momentarily unavailable "
        subtitle="We’ve ran into a snag loading your insights. Please try refreshing the page."
      />
    )
  }
}
