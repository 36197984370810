import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Link } from '@mui/material'
import { loginUser } from '../../modules/user/user'
import { LoggedInUserPayload } from '../../modules/user'
import { dispatch } from '../../store'
import { Store } from '../../modules/rootReducer'
import { PasswordInput } from '../designSystem/molecules/PasswordInput/PasswordInput'
import { Lock } from '../designSystem/assets/Lock/Lock'
import { ForgotPasswordForm } from '../users'
import { Color } from '../../Color'
import {
  CampaignRoute,
  useNavigationCampaign,
} from '../../hooks/useNavigationCampaign'

const StackedMargin = styled(Stack)`
  margin: 40px 0;
`

const StackedContainer = styled(Stack)`
  margin: 10px 0;
`

interface Props {
  loggedInUser: LoggedInUserPayload
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Enter your business email.'),
  password: yup.string().required('Enter your password.'),
})

export const LoginFormImpl: React.FC<Props> = ({ loggedInUser }) => {
  const navigate = useNavigationCampaign()

  const { user, error } = loggedInUser

  const [openModal, setOpenModal] = useState<boolean>(false)

  const isLoginError = !!error

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(loginUser(values))
    },
  })

  if (user?.id) {
    navigate(CampaignRoute.Home)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {isLoginError && (
            <Alert severity="error">Incorrect email or password.</Alert>
          )}
          <TextField
            name="email"
            label="Business Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />
          <PasswordInput
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password ? formik.errors.password : undefined
            }
            onBlur={formik.handleBlur}
          />
        </Stack>
        <StackedContainer spacing={3}>
          <Link
            sx={{
              cursor: 'pointer',
            }}
            fontWeight={'bold'}
            underline="none"
            color={Color.DarkerBlue}
            onClick={(): void => setOpenModal(true)}
          >
            Forgot password?
          </Link>
        </StackedContainer>
        <StackedMargin spacing={3}>
          <Button
            startIcon={<Lock height={19} width={16} />}
            type="submit"
            variant="contained"
            color="success"
            size={'large'}
            style={{ height: '45px' }}
          >
            Login
          </Button>
        </StackedMargin>
      </form>
      {openModal && (
        <ForgotPasswordForm handleClose={(): void => setOpenModal(false)} />
      )}
    </>
  )
}

const mapStateToProps = ({ loggedInUser: { loggedInUser } }: Store): Props => ({
  loggedInUser,
})

export const LoginForm = connect(mapStateToProps)(LoginFormImpl)
