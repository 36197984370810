import React, { useEffect, useState } from 'react'
import {
  AdminDashboardCompany,
  AdminDashboardCompanyFeature,
  AdminDashboardSubscription,
} from '../../../server/services/adminDashboard'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Color } from '../../Color'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import {
  CompanyRelationType,
  CompanyToAddRelation,
  UserWithSessionData,
} from './types'

import { api } from '../../lib/api'
import { FullStoryUserSession } from '../../../server/services/fullstory/types'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { AdminCompanyDetail } from './AdminCompanyDetail'
import { AdminUserDetail } from './AdminUserDetail'
import { AdminFeatureDetail } from './AdminFeatureDetail'
import { AdminPortfolioDetail } from './AdminPortfolioDetail'
import { AdminPerformanceDetail } from './AdminPerformanceDetail'
import { format } from 'date-fns'

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import BarChartIcon from '@mui/icons-material/BarChart'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import PeopleIcon from '@mui/icons-material/People'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import { AdminConnectionDetail } from './AdminConnectionDetail'
import { AdminCreditScoreDetail } from './AdminCreditScoreDetail'
import { AdminSubscriptionsDetail } from './AdminSubscriptionsDetail'

export const formatTimestamp = (date: Date): string => {
  return format(date, 'MM/dd/yyyy h:mm a')
}

interface MenuItem {
  label: string
  index: number
  icon: JSX.Element
}
enum TabOptions {
  COMPANY = 1,
  CONNECTIONS = 6,
  FEATURES = 2,
  USERS = 3,
  PORTFOLIO = 4,
  CREDIT_SCORE = 5,
  PERFORMANCE_METRICS = 7,
  SUBSCRIPTIONS = 8,
}

const menuItems: MenuItem[] = [
  {
    label: 'Company',
    index: TabOptions.COMPANY,
    icon: <HomeWorkIcon />,
  },
  {
    label: 'Connections',
    index: TabOptions.CONNECTIONS,
    icon: <ElectricalServicesIcon />,
  },
  {
    label: 'Features',
    index: TabOptions.FEATURES,
    icon: <EmojiFlagsIcon />,
  },
  {
    label: 'Users',
    index: TabOptions.USERS,
    icon: <PeopleIcon />,
  },
  {
    label: 'Portfolio',
    index: TabOptions.PORTFOLIO,
    icon: <BusinessCenterIcon />,
  },
  {
    label: 'Credit Score',
    index: TabOptions.CREDIT_SCORE,
    icon: <CreditScoreIcon />,
  },
  {
    label: 'Forecast Metrics',
    index: TabOptions.PERFORMANCE_METRICS,
    icon: <BarChartIcon />,
  },
  {
    label: 'Subscriptions',
    index: TabOptions.SUBSCRIPTIONS,
    icon: <SubscriptionsIcon />,
  },
]

interface Props {
  company: AdminDashboardCompany
  deleteCompanyFeature: (feature: AdminDashboardCompanyFeature) => void
  deleteCompanySubscription: (subscription: AdminDashboardSubscription) => void
  handleAddCompanyRelationClicked: (
    companyToAddRelation: CompanyToAddRelation,
  ) => void
  deletePortfolio: ({
    companyId,
    portfolioName,
  }: {
    companyId: string
    portfolioName: string
  }) => void
}

export const TableSectionHeader = ({
  title,
  renderButton,
}: {
  title: string
  renderButton?: () => JSX.Element
}): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ margin: '20px 0px 10px 0px' }}
    >
      <Grid item>
        <Typography variant={'h6'} sx={{ marginRight: '10px' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item>{renderButton ? renderButton() : null}</Grid>
    </Grid>
  )
}

const fetchSessions = async (
  companyId: string,
): Promise<FullStoryUserSession[]> => {
  try {
    const result: any = await api.get(
      `/customer-service/sessions?companyId=${companyId}`,
    )
    return result?.results as FullStoryUserSession[]
  } catch (err) {
    return []
  }
}

export const DashboardGridPanelDetail = ({
  company,
  deleteCompanyFeature,
  deleteCompanySubscription,
  handleAddCompanyRelationClicked,
  deletePortfolio,
}: Props): JSX.Element | null => {
  const [sessions, setSessions] = useState<FullStoryUserSession[]>([])

  useEffect(() => {
    const getSessions = async (): Promise<void> => {
      const sessions = await fetchSessions(company.id)
      setSessions(sessions)
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getSessions().catch(() => {})
  }, [company.id])

  const {
    users,
    connections,
    companyFeatures,
    portfolios,
    subscriptions,
  } = company

  const usersWithSessionData: UserWithSessionData[] = users.map(user => {
    const userSessionData = sessions.find(session => session.userId === user.id)
    return {
      ...user,
      lastSession: userSessionData?.sessions?.[0] ?? null,
    }
  })
  const [selectedIndex, setSelectedIndex] = useState(1)

  const hasActiveSubscription = subscriptions.length > 0

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: `${Color.Gray}`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Box
            sx={{
              p: 2,
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
            }}
          >
            <nav>
              <List>
                {menuItems.map((menuItem: MenuItem) => (
                  <>
                    <ListItemButton
                      key={menuItem.label}
                      selected={menuItem.index === selectedIndex}
                      onClick={(): void => setSelectedIndex(menuItem.index)}
                    >
                      <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      <ListItemText primary={menuItem.label} />
                    </ListItemButton>
                    <Divider />
                  </>
                ))}
              </List>
            </nav>
          </Box>
        </Grid>
        <Grid item xs={8} md={8}>
          {selectedIndex === TabOptions.COMPANY && (
            <AdminCompanyDetail
              company={company}
              onClick={(): void =>
                handleAddCompanyRelationClicked({
                  company,
                  type: CompanyRelationType.deletion,
                })
              }
            />
          )}
          {selectedIndex === TabOptions.CONNECTIONS && (
            <AdminConnectionDetail connections={connections} />
          )}
          {selectedIndex === TabOptions.FEATURES && (
            <AdminFeatureDetail
              renderButton={(): JSX.Element => (
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={(): void =>
                    handleAddCompanyRelationClicked({
                      company,
                      type: CompanyRelationType.features,
                    })
                  }
                  sx={{ ml: 1 }}
                >
                  Add Flag
                </Button>
              )}
              adminDashboardCompanyFeatures={companyFeatures}
              deleteCompanyFeature={deleteCompanyFeature}
            />
          )}
          {selectedIndex === TabOptions.USERS && (
            <AdminUserDetail
              renderButton={(): JSX.Element => (
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={(): void =>
                    handleAddCompanyRelationClicked({
                      company,
                      type: CompanyRelationType.users,
                    })
                  }
                  sx={{ ml: 1 }}
                >
                  Add User
                </Button>
              )}
              usersWithSessionData={usersWithSessionData}
            />
          )}
          {selectedIndex === TabOptions.PORTFOLIO && (
            <AdminPortfolioDetail
              renderButton={(): JSX.Element => (
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={(): void =>
                    handleAddCompanyRelationClicked({
                      company,
                      type: CompanyRelationType.portfolios,
                    })
                  }
                  sx={{ ml: 1 }}
                >
                  Add Portfolio
                </Button>
              )}
              strings={portfolios}
              companyId={company.id}
              deletePortfolio={deletePortfolio}
            ></AdminPortfolioDetail>
          )}
          {selectedIndex === TabOptions.CREDIT_SCORE && (
            <AdminCreditScoreDetail companyId={company.id} />
          )}
          {selectedIndex === TabOptions.PERFORMANCE_METRICS && (
            <AdminPerformanceDetail companyId={company.id} />
          )}
          {selectedIndex === TabOptions.SUBSCRIPTIONS && (
            <AdminSubscriptionsDetail
              subscriptions={subscriptions}
              renderButton={(): JSX.Element => (
                <Button
                  disabled={hasActiveSubscription}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={(): void =>
                    handleAddCompanyRelationClicked({
                      company,
                      type: CompanyRelationType.subscriptions,
                    })
                  }
                  sx={{ ml: 1 }}
                >
                  Add Subscription
                </Button>
              )}
              deleteCompanySubscription={deleteCompanySubscription}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
