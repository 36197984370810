import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import { TimezonedTime } from '../../server/util/timezoned'
import {
  DatePrecision,
  InvalidDatePrecisionError,
} from '../../server/data/models/DatePrecision'
import { Money } from '../../util/money/Money'
import { compactInteger } from 'humanize-plus'

/*
 * This file containes functions that transform values into
 * user-friendly strings. Use these to display values to the
 * user without worrying about undesired results being shown
 * to the user such as: undefined, null, NaN, Infinity, -0
 *
 * Look at the tests for this file to see some examples.
 * __tests__/ui/.../displayValue.tsx
 *
 */

type maybeNumber = number | string | null | undefined

export const isNumeric = (v: maybeNumber): boolean =>
  Number.isFinite(v as number)

export const UnavailableValue = '-'

const formatNumber = (
  v: maybeNumber,
  formatter: (v: number) => string,
): string => (isNumeric(v) ? formatter(v as number) : UnavailableValue)

export const numberAsString = (v: maybeNumber): string =>
  formatNumber(v, String)

export const compactNumber = (v: maybeNumber): string =>
  formatNumber(v, compactInteger)

export const commaDelimitedThousands = (v: maybeNumber): string =>
  formatNumber(v, (val): string => {
    return new BigNumber(val).toFormat(0, BigNumber.ROUND_HALF_EVEN, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    })
  })

export const commaDelimitedThousandsWithOptionalDecimals = (
  v: maybeNumber,
): string =>
  formatNumber(v, (val): string => {
    return Intl.NumberFormat('en-US').format(val)
  })

export const fractionAsPercentage = (v: number): string =>
  formatNumber(v, v => `${Math.round(v * 100)}%`)

export const numberAsMoney = (v: maybeNumber, s?: boolean): string =>
  formatNumber(v, v => new Money(v).humanize(s))

export const numberAsCommaDelimitiedMoney = (v: maybeNumber): string =>
  formatNumber(v, v => new Money(v).toString())

export function formatDate(
  ts: Date | number | string,
  precision: DatePrecision,
): string {
  let date: Date
  if (ts instanceof Date) {
    date = ts
  } else {
    date = new Date(ts)
  }

  const timezoned = new TimezonedTime('UTC').parseInTimezone(date, false)

  switch (precision) {
    case DatePrecision.Day:
      return format(timezoned, 'M/d')
    case DatePrecision.Month:
      return format(timezoned, 'M/yy')
    default:
      throw new InvalidDatePrecisionError(precision)
  }
}

export const formatMaxDecimals = (
  v: maybeNumber,
  maxDecimals: number,
): string =>
  formatNumber(v, val => {
    const decimalsMultiplier = Math.pow(10, maxDecimals ?? 0)
    return String(
      Math.round(Number(val) * decimalsMultiplier) / decimalsMultiplier,
    )
  })

export const formatMaxSignificantFigures = (
  v: maybeNumber,
  maxSignificantFigures: number,
): string =>
  formatNumber(v, val => {
    return String(Number(val.toPrecision(maxSignificantFigures)))
  })

export interface DisplayValueFormat {
  prefix?: string
  suffix?: string
  maxDecimals?: number
  maxSignificantFigures?: number
  deliminateThousands?: boolean
  excludeAdornement?: boolean
}

export const formatDisplayValue = (
  v: maybeNumber,
  format?: DisplayValueFormat,
): string => {
  let formattedValue = numberAsString(v)
  if (typeof format?.maxDecimals === 'number') {
    formattedValue = formatMaxDecimals(
      Number(formattedValue),
      format.maxDecimals,
    )
  }
  if (typeof format?.maxSignificantFigures === 'number') {
    formattedValue = formatMaxSignificantFigures(
      Number(formattedValue),
      format.maxSignificantFigures,
    )
  }
  if (format?.deliminateThousands) {
    formattedValue = commaDelimitedThousandsWithOptionalDecimals(
      Number(formattedValue),
    )
  }
  return format?.excludeAdornement
    ? formattedValue
    : `${format?.prefix ?? ''}${formattedValue}${format?.suffix ?? ''}`
}

export const centsAsDollars = (
  value?: number,
  decimalPlaces?: number,
): string => {
  if (!value) return '$0.00'
  const dollars = value / 100
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(dollars)
}

export const createCentsAsDollarsFormatter = (decimalPlaces: number) => {
  return (value: number): string => centsAsDollars(value, decimalPlaces)
}
