import React from 'react'
import Container from '@mui/material/Container'
import { BFLogo } from '../../components/designSystem/atoms/BFLogo/BFLogo'

import { TrackedRoute } from '../TrackedRoute'
import { ThankYouPage } from '../guidedOnboarding/creditScore/ThankYouPage'
import { Switch } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import LogoutIcon from '@mui/icons-material/Logout'
import { Color } from '../../Color'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { BankingConnectScreen } from '../guidedOnboarding/creditScore/BankingConnectScreen'
import { TermsOfServiceScreen } from '../users/TermsOfServiceScreen'

export const ToolbarLessMaster = (): JSX.Element => {
  const logout = (): void => {
    window.location.href = '/users/logout'
  }
  return (
    <Container sx={{ overflow: 'auto', paddingBottom: 3 }} maxWidth="md">
      <Grid container={true} alignItems="center" mt={7} mb={8}>
        <Grid item={true} xs={6}>
          <BFLogo />
        </Grid>
        <Grid item={true} xs={6} container={true} justifyContent="flex-end">
          <Button
            sx={{ ml: 1, color: Color.DarkBlue }}
            variant="text"
            onClick={logout}
          >
            <LogoutIcon />
            &nbsp;<Typography variant="body1">Log out</Typography>
          </Button>
        </Grid>
      </Grid>
      <Switch>
        <TrackedRoute
          path="/users/terms-of-service"
          component={TermsOfServiceScreen}
          title={'TOS'}
        />
        <TrackedRoute
          exact
          path="/get-started/credit-score/:modalToShow?"
          component={BankingConnectScreen}
          title={'Credit Score Onboarding'}
        />
        <TrackedRoute
          exact
          path="/get-started/thank-you"
          component={ThankYouPage}
          title={'Credit Score Thank You'}
        />
      </Switch>
    </Container>
  )
}
