import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Alert, { AlertColor } from '@mui/material/Alert'

type Props = {
  icon: ReactNode
  message: ReactNode
  severity: AlertColor
}

export const CashPositionContentAlert = ({
  icon,
  message,
  severity,
}: Props): JSX.Element => (
  <Box sx={{ alignSelf: 'flex-start', paddingLeft: '48px' }}>
    <Alert icon={icon} severity={severity}>
      {message}
    </Alert>
  </Box>
)
