import React from 'react'
import { format } from 'date-fns'

import { DashboardHeadingSection } from '../../../../../components/designSystem/organisms/DashboardHeadingSection/DashboardHeadingSection'

type Props = {
  start: string | null
  end: string | null
  synced?: string
}

export const TransactionsHeadingSection = ({
  start,
  end,
  synced,
}: Props): JSX.Element => {
  const startDate = start ? new Date(start) : null
  const endDate = end ? new Date(end) : null
  const syncedTimeStamp = synced ? new Date(synced) : null

  const subHeadingDates =
    startDate && endDate
      ? `aggregated from ${format(startDate, 'MMMM d, y')} to ${format(
          endDate,
          'MMMM d, y',
        )}`
      : ''

  return (
    <DashboardHeadingSection
      subHeading={`Actuals transactions from available data ${subHeadingDates}`}
      synced={
        syncedTimeStamp ? format(syncedTimeStamp, "M/d/y 'at' ppp") : undefined
      }
    />
  )
}
