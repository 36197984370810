import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { CreditScoreRating } from '../../../../../hooks/useRatingCreditScore'

const RatingProgressBarStyle = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;

  .segment {
    .bar {
      margin-right: 1px;

      .MuiSlider-thumb {
        display: none;
        overflow: hidden;
      }
    }

    .labels {
      display: flex;
      width: 100%;
      justify-content: end;
      font-size: 0.762rem;
      color: #aaaaaa;
    }

    margin-right: 0.5px;

    /*only this segment has 2 labels*/
    &:nth-child(1) {
      flex-basis: 40%;

      .labels {
        justify-content: space-between;
      }
    }

    &:nth-child(2) {
      flex-basis: 10%;
    }

    &:nth-child(3) {
      flex-basis: 10%;
    }

    &:nth-child(4) {
      flex-basis: 10%;
    }

    &:nth-child(5) {
      flex-basis: 30%;
    }
  }
`

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop: any): boolean => prop !== 'ratingcolor',
})<{ ratingcolor: string }>(({ theme, ratingcolor }) => ({
  height: 6,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: ratingcolor,
  },
}))

export const SegmentLinearProgress = ({
  score,
  max,
  min,
  color,
}: {
  score: number
  max: number
  min: number
  color: string
}): JSX.Element => {
  const [newValue, setNewValue] = useState(0)
  useEffect(() => {
    if (min > score) {
      setNewValue(0)
    } else {
      const total = max - min
      const realValue = score - min
      const ratingPercentage = (realValue / total) * 100
      const value = max > score ? ratingPercentage : 100
      setNewValue(value)
    }
  }, [score, min, max])

  return (
    <BorderLinearProgress
      variant="determinate"
      sx={{ height: '6px' }}
      ratingcolor={newValue > 0 ? color : '#ececec'}
      value={newValue}
    />
  )
}
export interface RatingLinearProgressProps {
  color: string
  score?: number
  segments: CreditScoreRating[]
}
export const RatingProgressBar = ({
  color,
  score,
  segments,
}: RatingLinearProgressProps): JSX.Element => {
  return (
    <RatingProgressBarStyle>
      {segments.map(
        ({ maximum, minimum }: CreditScoreRating, index: number) => (
          <div key={index} className="segment">
            <div className="bar">
              <SegmentLinearProgress
                max={maximum}
                min={minimum}
                score={score ?? 0}
                color={color}
              />
            </div>
            <div className="labels">
              {index === 0 && <span>{minimum}</span>}
              <span>{maximum + (maximum < 100 ? 1 : 0)}</span>
            </div>
          </div>
        ),
      )}
    </RatingProgressBarStyle>
  )
}
