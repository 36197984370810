import React from 'react'

import { LoadingCircular } from '../../atoms/Loading/Loading'
import { DataPresentationCard } from '../../molecules/DataPresentationCard/DataPresentationCard'
import { InfoTooltip } from '../../molecules/InfoTooltip/InfoTooltip'
import { LineGraph } from '../../molecules/Graph/LineGraph/LineGraph'
import { LegendBottomContainer } from '../../molecules/Graph/Legend/LegendBottomContainer'

import { CardBody, LoadingBody } from './LineGraphPresentationCard.styles'

import { Props } from './LineGraphPresentationCard.types'

import {
  formatLegendData,
  formatLineObject,
  formatXAxisTicks,
  presentationTypeToFormatterMap,
} from './LineGraphPresentationCard.utils'

export const LineGraphPresentationCard = ({
  chartData,
  emptyState,
  isEmpty,
  isLoading,
  legendData,
  presentationType,
  shouldExcludeTotal,
  sx,
  title,
  tooltipDescription,
}: Props): JSX.Element => {
  const { tickFormatter, tooltipFormatter } = presentationTypeToFormatterMap[
    presentationType
  ]

  return (
    <DataPresentationCard
      title={title}
      sx={sx}
      widgets={
        tooltipDescription && <InfoTooltip description={tooltipDescription} />
      }
    >
      {isLoading ? (
        <LoadingBody>
          <LoadingCircular />
        </LoadingBody>
      ) : (
        <>
          {isEmpty ? (
            emptyState
          ) : (
            <CardBody>
              <LineGraph
                title={title}
                lines={formatLineObject(chartData[0], shouldExcludeTotal)}
                tickFormatter={tickFormatter}
                tooltipFormatter={tooltipFormatter}
                chartData={chartData}
                xAxisTicks={formatXAxisTicks(chartData)}
              />
              <LegendBottomContainer
                legendItems={formatLegendData(
                  legendData,
                  presentationType,
                  shouldExcludeTotal,
                )}
                titles={['vs Previous', 'vs Average']}
              />
            </CardBody>
          )}
        </>
      )}
    </DataPresentationCard>
  )
}
