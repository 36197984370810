import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { InputSlider } from '../../components/designSystem/molecules/InputSlider/InputSlider'
import Switch from '@mui/material/Switch'
import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'

import { LoadingCircular } from '../../components/designSystem/atoms/Loading/Loading'
import { Color } from '../../Color'
import {
  AlertTypeProps,
  NotificationAlert,
} from '../../components/designSystem/molecules/NotificationAlert/NotificationAlert'
import { useAdminCreditScore } from '../../hooks/useAdminCreditScore'
import { useRatingCreditScore } from '../../hooks/useRatingCreditScore'
import Stack from '@mui/material/Stack'
import { ConfirmationDialog } from '../../components/designSystem/organisms/ConfirmationDialog/ConfirmationDialog'
import Alert from '@mui/material/Alert'

const successAlert: AlertTypeProps = {
  severity: 'success',
  title: 'Success',
  description: 'Credit Score was successfully saved',
  showAlert: false,
}
const errorAlert: AlertTypeProps = {
  severity: 'error',
  title: 'Error',
  description: `Credit Score couldn't be saved.`,
  showAlert: false,
}

const AdminCreditScoreProgress = ({
  score,
  enabled,
}: {
  score: number
  enabled: boolean
}): JSX.Element => {
  const { rating } = useRatingCreditScore(score)
  const [ratingColor, setRatingColor] = useState(Color.VioletEggplant)
  const [ratingText, setRatingText] = useState('Invalid')

  React.useEffect(() => {
    setRatingColor(rating.color)
    setRatingText(rating.rating)
  }, [score, rating])

  return (
    <Grid
      container={true}
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
          }}
        >
          <CircularProgress
            thickness={8}
            size={200}
            variant="determinate"
            sx={{
              color: `${enabled ? ratingColor : Color.Gray}`,
            }}
            value={score}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <div>
                <Typography variant="h6" color="text.secondary">
                  {score}
                </Typography>
              </div>
              <div style={{ minWidth: '40px', textAlign: 'center' }}>
                <Typography variant="caption" color={Color.LightBlue}>
                  {ratingText}
                </Typography>
              </div>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
const getNotificationDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }

  return date.toLocaleDateString('en-US', options)
}
export const AdminCreditScoreDetail = ({
  companyId,
}: {
  companyId: string
}): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const [alertType, setAlertType] = useState<AlertTypeProps>(successAlert)
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showWarningAlert, setShowWarningAlert] = useState(false)

  const { creditScore, notifyUsers, updateCreditScore } = useAdminCreditScore({
    companyId,
  })

  const [liquidity, setLiquidity] = useState(0)
  const [ability, setAbility] = useState(0)
  const [embedded, setEmbedded] = useState(0)
  const [isEnabled, setIsEnabled] = useState(false)
  const [enableNotification, setEnableNotification] = useState(false)

  React.useEffect(() => {
    setLiquidity(creditScore.liquidity ?? 0)
    setEmbedded(creditScore.embedded ?? 0)
    setAbility(creditScore.ability ?? 0)
    setIsEnabled(creditScore.isActive === true)
    setEnableNotification(
      creditScore?.creditScore !== undefined && creditScore.isActive === true,
    )
    setShowWarningAlert(!!creditScore?.notificationDate)
  }, [creditScore])

  const onSavedHandler = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const newScore = liquidity + ability + embedded
      await updateCreditScore({
        companyId,
        liquidity,
        ability,
        embedded,
        creditScore: newScore,
        isActive: isEnabled,
      })

      setAlertType(successAlert)
      setShowAlert(true)
      setEnableNotification(true)
      setShowWarningAlert(false)
      setLoading(false)
    } catch (e: any) {
      setShowAlert(true)
      setLoading(false)
      setEnableNotification(false)
      setAlertType({
        ...errorAlert,
        description: `Credit Score couldn't be saved. Error message: ${e.errorMessage}`,
      })
    }
    setTimeout(() => {
      setShowAlert(false)
    }, 5000)
  }, [companyId, isEnabled, liquidity, ability, embedded, updateCreditScore])

  const onResetHandler = React.useCallback(async (): Promise<void> => {
    setLiquidity(0)
    setAbility(0)
    setEmbedded(0)
  }, [])

  return (
    <Grid container={true}>
      <Grid item xs={8} md={6}>
        <Card>
          <CardHeader title="Credit Score" subheader="" />
          <CardContent>
            <NotificationAlert
              severity={alertType.severity}
              title={alertType.title}
              description={alertType.description}
              showAlert={showAlert}
            />

            <Box component="form" noValidate autoComplete="off" sx={{ p: 2 }}>
              <Grid container spacing={1} sx={{ mb: 4 }}>
                <Grid item xs={8} md={6}>
                  <h6>Score Factors</h6>
                </Grid>
                <Grid item>
                  <h6>Credit Score</h6>
                </Grid>
              </Grid>
              <Grid container={true} spacing={1} alignItems="center">
                <Grid item xs={8} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                      <InputSlider
                        label="Embedded Value"
                        min={0}
                        max={44}
                        value={embedded}
                        helperText="Embedded Value with a range of 0-44."
                        disabled={!isEnabled}
                        onValueChanged={(value): void => setEmbedded(value)}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                      <InputSlider
                        label="Ability to Scale"
                        min={0}
                        max={28}
                        value={ability}
                        helperText="Ability to Scale with a range of 0-28."
                        disabled={!isEnabled}
                        onValueChanged={(value): void => setAbility(value)}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                      <InputSlider
                        label="Liquidity"
                        min={0}
                        max={28}
                        value={liquidity}
                        helperText="Liquidity with a range of 0-28."
                        disabled={!isEnabled}
                        onValueChanged={(value): void => setLiquidity(value)}
                      />
                      <Grid item xs={12} sx={{ mt: 4 }}>
                        <Switch
                          checked={isEnabled}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            if (event?.target) {
                              setIsEnabled(event.target.checked)
                            }
                          }}
                        />
                        &nbsp;
                        <label>Enabled Credit Score</label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={6}>
                  <AdminCreditScoreProgress
                    enabled={isEnabled}
                    score={liquidity + ability + embedded}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions>
            <Grid container={true} spacing={4} justifyContent="end">
              <Grid item xs={12}>
                {showWarningAlert && (
                  <Alert severity="warning">
                    A notification has already been sent via email regarding
                    this credit score on&nbsp;
                    {getNotificationDate(
                      new Date(creditScore?.notificationDate || new Date()),
                    )}
                  </Alert>
                )}
              </Grid>
              <Grid item sx={{ p: 2 }}>
                {!loading && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={!enableNotification}
                      onClick={async (): Promise<void> => {
                        setShowConfirmation(true)
                      }}
                    >
                      Notify customer
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ ml: 2 }}
                      onClick={onResetHandler}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ ml: 2 }}
                      onClick={onSavedHandler}
                    >
                      Save
                    </Button>
                  </>
                )}
                {loading && <LoadingCircular />}
              </Grid>
            </Grid>
          </CardActions>

          <ConfirmationDialog
            cancelButtonText="Cancel"
            confirmationButtonText="Send Email"
            content={`Are you sure you want to send an email notification 
            to customers informing them that their credit score is ready?         
            `}
            title="Confirm Email Notification"
            onCancel={async (): Promise<void> => setShowConfirmation(false)}
            onConfirmation={async (): Promise<void> => {
              await notifyUsers()
              setShowConfirmation(false)
              setShowWarningAlert(true)
            }}
            visible={showConfirmation}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
