import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { Color } from '../../../../Color'

export type Selection<ValueType extends string> = {
  value: ValueType
  text: string
}

const toggleButtonStyles = {
  height: '40px',
  fontSize: '15px',
  fontWeight: 400,
  borderRadius: '0px',
  backgroundColor: 'transparent',
  borderWidth: '0px',
  borderBottomWidth: '2px',
  borderBottomColor: Color.LighterGrey,
  color: Color.LightGrey,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: Color.VioletEggplant,
    backgroundColor: 'transparent',
    borderBottomColor: Color.VioletEggplant,
    borderWidth: '0px',
    borderBottomWidth: '2px',
  },
}

interface Props<ValueType extends string> {
  key: string
  value: string
  setValue: (value: ValueType) => void
  selections: Selection<ValueType>[]
}

export const Toggle = <ValueType extends string>(): React.FunctionComponent<
  Props<ValueType>
> => ({ key, value, setValue, selections }: Props<ValueType>): JSX.Element => (
  <ToggleButtonGroup
    key={`${key} Toggle Button Group`}
    aria-label={`${key} Selection`}
    value={value}
    exclusive
    onChange={(event: React.MouseEvent<HTMLElement>, value: string): void =>
      setValue(value as ValueType)
    }
  >
    {selections.map(({ value: selectionValue, text }) => (
      <ToggleButton
        key={selectionValue}
        value={selectionValue}
        aria-label={selectionValue}
        disabled={value === selectionValue}
        sx={toggleButtonStyles}
      >
        {text}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)
