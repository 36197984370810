import React from 'react'

import Button from '@mui/material/Button'
import { UserGuidanceBase } from '../../shared'

export const UpgradePlanScreen = ({
  renderIcon,
  title,
  subtitle,
}: {
  renderIcon: () => React.ReactNode
  title: string
  subtitle: string
  email?: string
}): JSX.Element => {
  return (
    <UserGuidanceBase renderIcon={renderIcon} title={title} subtitle={subtitle}>
      <Button
        href="/users/pricing"
        size="large"
        variant="contained"
        color="primary"
      >
        Upgrade
      </Button>
    </UserGuidanceBase>
  )
}
