export { Table, HeadingTr } from '../shared'
import { GetMarketingExpenses } from '../../../modules/reports/roas'
import { GetRevenueResults } from '../../../../server/routes/api/reports'

import {
  AccountCategories,
  InflowCategory,
  MarketingAccountCategory,
  marketingAccountCategories,
} from '../../../../server/data/models/sync/types'

export const ROAS_CATEGORY_LABELS: { [key: string]: string } = {
  roas: 'Return on Ad Spend',
  revenue: 'Order Revenue',
  expense: 'Total Marketing Spend',
}

export type ROASConfigKey =
  | 'all'
  | typeof AccountCategories.shopifySales
  | typeof AccountCategories.amazonSales

const all = 'all'

type ROASConfigEntry = {
  key: ROASConfigKey
  label: string
  marketingCategories: Array<MarketingAccountCategory>
  revenueCategories: Array<InflowCategory>
}

// key cannot be union type but note that string should be ROASConfigKey
export const ROASConfig: { [key: string]: ROASConfigEntry } = {
  [all]: {
    key: all,
    label: 'All',
    marketingCategories: marketingAccountCategories,
    revenueCategories: [
      AccountCategories.shopifySales,
      AccountCategories.amazonSales,
    ],
  },
  [AccountCategories.shopifySales]: {
    key: AccountCategories.shopifySales,
    label: 'Shopify',
    marketingCategories: [
      AccountCategories.facebookAds,
      AccountCategories.googleAds,
    ],
    revenueCategories: [AccountCategories.shopifySales],
  },
  [AccountCategories.amazonSales]: {
    key: AccountCategories.amazonSales,
    label: 'Amazon',
    marketingCategories: [AccountCategories.amazonAds],
    revenueCategories: [AccountCategories.amazonSales],
  },
}

const timestampArraysMatch = (
  firstArray: string[],
  secondArray: string[],
): boolean =>
  firstArray.length === secondArray.length &&
  firstArray.every((timestamp, index) => secondArray[index] === timestamp)

export type ROASData = {
  ts: number
  revenue: number
  expense: number
  roas: number
}
export const aggregateRoasData = (
  key: ROASConfigKey,
  revenueResults: GetRevenueResults,
  marketingExpenses: GetMarketingExpenses,
): Array<ROASData> => {
  const revenueCategories = ROASConfig[key].revenueCategories
  const expenseCategories = ROASConfig[key].marketingCategories
  if (
    revenueResults.hasLoaded &&
    marketingExpenses.hasLoaded &&
    !timestampArraysMatch(
      revenueResults.timestamps,
      marketingExpenses.timestamps,
    )
  ) {
    throw new Error('Revenue data has different timestamps from expense data')
  }
  const timestamps = revenueResults.timestamps.map(timestamp =>
    new Date(timestamp).getTime(),
  )

  const flattenCentsFromCategories = (result: number[], current: number[]) =>
    current.map((cents, index) => cents + (result?.[index] ?? 0))

  const revenueCentsFromCategories = Object.entries(revenueResults.categories)
    .filter(category =>
      revenueCategories.includes(category[0] as InflowCategory),
    )
    .map(([_, inflowCategory]) => inflowCategory.revenueCents)
  const revenueCentsAggregated = revenueCentsFromCategories.reduce(
    flattenCentsFromCategories,
    [],
  )

  const expenseCentsFromCategories = Object.entries(
    marketingExpenses.categories,
  )
    .filter(category =>
      expenseCategories.includes(category[0] as MarketingAccountCategory),
    )
    .map(([_, outflowCategory]) => outflowCategory.marketingExpenseCents)
  const expenseCentsAggregated = expenseCentsFromCategories.reduce(
    flattenCentsFromCategories,
    [],
  )

  const returnOnAdSpend = revenueCentsAggregated.map((revenueCents, idx) => {
    const roas = revenueCents / expenseCentsAggregated[idx]
    return isFinite(roas) ? roas : NaN
  })

  const aggregateData = timestamps.map((ts, idx) => ({
    ts: ts,
    revenue: revenueCentsAggregated[idx],
    expense: expenseCentsAggregated[idx],
    roas: returnOnAdSpend[idx],
  }))

  return aggregateData
}
