import React from 'react'

import Box from '@mui/material/Box'

import { ChartLegendMetric } from '../../../../../../components/designSystem/molecules/ChartLegendMetric/ChartLegendMetric'

type Props = {
  actuals: string
  forecast: string
  timePeriod: string
}

export const SalesForecastTopLineMetrics = ({
  actuals,
  forecast,
  timePeriod,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      gap: '10px',
    }}
  >
    <ChartLegendMetric
      metricValue={actuals}
      title="Actuals"
      tooltip={`Last ${timePeriod}`}
      sx={{ flex: 1 }}
    />
    <ChartLegendMetric
      metricValue={forecast}
      title="Forecast"
      tooltip={`Next ${timePeriod}`}
      sx={{ flex: 1 }}
    />
  </Box>
)
