import { Field } from 'formik'
import React from 'react'
import { styled } from '@mui/material/styles'
import Radio, { RadioProps } from '@mui/material/Radio'
import { Color } from '../../../../Color'

type Props = {
  value: any
  label?: string | number
  propName?: string
  onClick?: Function
  rightSpace?: number
}

const DisplayValue = styled('span')({
  paddingLeft: '10px',
})

const StyledIcon = styled('span')({
  borderRadius: '50%',
  borderStyle: 'dashed',
  borderWidth: '1.5px',
  borderColor: `${Color.Green}`,
  width: 23,
  height: 23,
  'input:hover ~ &': {
    backgroundColor: `${Color.White}`,
  },
})

const StyledCheckedIcon = styled('span')({
  backgroundColor: `${Color.White}`,
  borderRadius: '50%',
  borderStyle: 'dashed',
  borderWidth: '1.5px',
  borderColor: `${Color.Green}`,
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${Color.Green},${Color.Green} 40%,transparent 20%)`,
    content: '""',
  },
})

const StyledRadio = (props: RadioProps): JSX.Element => {
  return (
    <Radio
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      color="default"
      checkedIcon={<StyledCheckedIcon />}
      icon={<StyledIcon />}
      {...props}
    />
  )
}

export const RadioField: React.FunctionComponent<Props> = props => {
  const { label = '', value, propName, onClick } = props
  return (
    <>
      <Field
        type="radio"
        name={propName}
        value={value}
        as={StyledRadio}
        {...(onClick && { onChange: onClick })}
      />
      <DisplayValue>{label}</DisplayValue>
    </>
  )
}
