import React from 'react'
import { ExternalLink } from '../../../forms/StyledElements'

export const TermsOfServiceLink = (): JSX.Element => (
  <ExternalLink
    target={'_blank'}
    href="https://brightflow.ai/terms-of-service/"
  >
    Terms of Service
  </ExternalLink>
)
