import { UrlAttributes } from '../../../transactions/Transactions.types'
import { encodeAttributeHash } from '../../../transactions/Transactions.utils'
import { TableHeaderClasses } from '../CashPositionTable/CashPositionTable.consts'

const noLinkRows = [
  TableHeaderClasses.cashChange,
  TableHeaderClasses.ending,
  TableHeaderClasses.parent,
]

export const formatCellAttributesHash = (
  endDate: string,
  startDate: string,
  categoryId?: string,
  merchantName?: string,
  subCategories?: string[],
): string => {
  const attributes: UrlAttributes = {
    cat: subCategories || (categoryId ? [categoryId] : undefined),
    ed: endDate,
    merch: merchantName,
    sd: startDate,
  }

  return encodeAttributeHash(attributes)
}

export const shouldRenderLink = (headerClassName?: string): boolean => {
  if (!headerClassName) return true

  return !noLinkRows.includes(headerClassName)
}

export const shouldRenderRedNegatives = (headerClassName?: string): boolean => {
  switch (headerClassName) {
    case 'cash-change':
      return true
    default:
      return false
  }
}
