import React, { useState } from 'react'
import { CompanyToAddRelation } from '../types'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Feature } from '../../../../server/data/models'
import Grid from '@mui/material/Grid'
import { dispatch } from '../../../store'
import { createCompanyFeature } from '../../../modules/adminDashboard/adminDashboard'
import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Stack from '@mui/material/Stack'
import { CompanySummary } from './CompanySummary'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { AdminDashboardCompanyFeature } from '../../../../server/services/adminDashboard'

interface Props {
  companyToAddRelation: CompanyToAddRelation
  features: Feature[]

  onCancel: () => void
  onCreate: (newFeature: AdminDashboardCompanyFeature) => void
}
export const CreateFeatureModal = (props: Props): JSX.Element => {
  const { companyToAddRelation, features, onCancel, onCreate } = props
  const [featureToAdd, setFeatureToAdd] = useState<string>('')
  const handleAddFeatureSelectChange = (event: SelectChangeEvent): void => {
    setFeatureToAdd(event.target.value)
  }

  const handleAddFeatureCreateButtonPressed = (): void => {
    const newFeature: Feature | undefined = features.find(
      feature => feature.label === featureToAdd,
    )
    const companyId = companyToAddRelation?.company?.id
    if (newFeature && companyToAddRelation) {
      const adminDashboardFeature = {
        id: newFeature.id,
        label: newFeature.label,
        description: newFeature.description,
        companyId: companyId,
        key: newFeature.key,
      }
      onCreate(adminDashboardFeature)
      dispatch(
        createCompanyFeature({
          companyId: companyToAddRelation?.company?.id,
          featureId: newFeature.id,
        }),
      )
    }
    setFeatureToAdd('')
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Add feature flag</h5>
        <CompanySummary companyToAddRelation={companyToAddRelation} />
        <Grid container={true} justifyContent={'end'}>
          <Grid container={true} xs={12}>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: '100%' }}
              >
                <InputLabel>Feature Flag</InputLabel>
                <Select
                  labelId="FeatureSelect"
                  id="featureSelect"
                  value={featureToAdd}
                  onChange={handleAddFeatureSelectChange}
                  label="Feature"
                >
                  {features
                    .filter(
                      feature =>
                        !companyToAddRelation.company.companyFeatures.some(
                          existingCompanyFeature =>
                            existingCompanyFeature.key === feature.key,
                        ),
                    )
                    .map(feature => (
                      <MenuItem key={feature.id} value={feature.label}>
                        {feature.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {featureToAdd && (
                <p>{`Description: ${
                  features.find(feature => feature.label === featureToAdd)
                    ?.description
                }`}</p>
              )}
            </Grid>
          </Grid>
          <Grid item sx={{ mr: 1 }}>
            <Button
              variant={'outlined'}
              color={'warning'}
              onClick={(): void => {
                setFeatureToAdd('')
                onCancel()
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!featureToAdd}
              variant={'contained'}
              color={'success'}
              onClick={(): void => handleAddFeatureCreateButtonPressed()}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
