import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { createThunkReducer } from '../../thunkReducerBuilder'
import { api } from '../../../lib/api'

import type { ExecutiveResponse } from '../../../../server/routes/api/reports/executive'

export const getExecutiveResults = createAsyncThunk(
  'reports/executive/get',
  async () => api.get(`/api/reports/executive`),
)

type ExecutiveStore = Readonly<{
  executiveResults:
    | (ExecutiveResponse & {
        hasLoaded: true
        error: null
      })
    | { hasLoaded: false; error: null }
    | { hasLoaded: false; error: string }
}>

const initialState: ExecutiveStore = {
  executiveResults: {
    hasLoaded: false,
    error: null,
  },
}

export const executiveReducer = createReducer(initialState, builder => {
  createThunkReducer(
    builder,
    getExecutiveResults.typePrefix,
    'executiveResults',
    (state, action) => ({
      ...state,
      executiveResults: {
        ...action.payload,
        hasLoaded: true,
      },
    }),
  )
})
