import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getProductDemandForecast } from './productDemandForecast'
import { ProductDemandForecastResponse } from '../../../server/services/forecast/types'

import { LoadingAndError } from '../types'

const PRODUCT_DEMAND_FORECAST_KEY = 'productDemandForecast'

export type ProductDemandForecastPayload = {
  [PRODUCT_DEMAND_FORECAST_KEY]: ProductDemandForecastResponse | null
} & LoadingAndError

// REDUCERS
type ProductDemandForecastStoreData = {
  [PRODUCT_DEMAND_FORECAST_KEY]: ProductDemandForecastPayload
}

export type ProductDemandForecastData = Readonly<ProductDemandForecastStoreData>

const initialCompanyFeaturesState: ProductDemandForecastData = {
  [PRODUCT_DEMAND_FORECAST_KEY]: {
    [PRODUCT_DEMAND_FORECAST_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const productDemandForecastReducer = createReducer(
  initialCompanyFeaturesState,
  builder => {
    createThunkReducer(
      builder,
      getProductDemandForecast.typePrefix,
      PRODUCT_DEMAND_FORECAST_KEY,
      (state, action) => {
        return {
          ...state,
          [PRODUCT_DEMAND_FORECAST_KEY]: {
            ...state[PRODUCT_DEMAND_FORECAST_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_DEMAND_FORECAST_KEY]: action.payload,
          },
        } as ProductDemandForecastData
      },
    )
  },
)
