import Avatar from '@mui/material/Avatar'
import React from 'react'

type CircularBulletProps = { color: string; size: number }

export const CircularBullet = ({
  color,
  size,
}: CircularBulletProps): JSX.Element => {
  return (
    <Avatar
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
      }}
      variant="circular"
    >
      &nbsp;
    </Avatar>
  )
}
