import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Color } from '../../../../../Color'

export interface LegendEntryContentVariant1Props {
  value: number
  description: string
}

export const LegendEntryContentVariant1 = ({
  value,
  description,
}: LegendEntryContentVariant1Props): JSX.Element => {
  return (
    <Grid container={true}>
      <Grid item>
        <Typography variant="h5">{value}</Typography>
      </Grid>
      <Grid
        item={true}
        container={true}
        sx={{ pl: 1, paddingBottom: '2px' }}
        alignItems="end"
        justifyContent="flex-start"
        xs={8}
      >
        <Typography variant="caption">{description}</Typography>
      </Grid>
    </Grid>
  )
}
