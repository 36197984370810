// TODO: use env once the frontend/backend is split up
// import {env} from './env'

let API_ROOT = '/'
// let API_ROOT = 'http://localhost:31111'
// let API_ROOT = env.API_BASE_URL
if (!API_ROOT.endsWith('/')) {
  API_ROOT = `${API_ROOT}/`
}

type PostData = any

type UrlAndHeaders = {
  apiUrl: string
  headers: { [key: string]: string }
}
function getUrlAndHeaders(url: string): UrlAndHeaders {
  const baseHeaders = {
    Accept: 'application/json',
  }
  const extraHeaders = {
    'Content-Type': 'application/json',
  }

  // If a full URL is passed, then don't prefix it or pass our internal headers
  if (url.startsWith('http')) {
    return {
      apiUrl: url,
      headers: baseHeaders,
    }
  } else {
    // Remove leading slash if it's present
    if (url.startsWith('/')) {
      url = url.substr(1)
    }

    return {
      apiUrl: API_ROOT + url,
      headers: { ...baseHeaders, ...extraHeaders },
    }
  }
}

async function makeRequest(
  url: string,
  method: string,
  postData?: PostData,
): Promise<object> {
  const { apiUrl, headers } = getUrlAndHeaders(url)

  // Fetch config
  const config: any = {
    headers,
    method,
  }

  // Add post data if necessary
  if (postData) {
    config.body = JSON.stringify(postData)
  }

  // Fetch & process result
  try {
    const res = await fetch(apiUrl, config)
    if (!res.ok) {
      const errorMessage = await res.text()
      const err = {
        status: res.status,
        statusText: res.statusText,
        errorMessage,
        url: res.url,
        redirected: res.redirected,
        type: res.type,
        headers: res.headers,
      }
      throw err
    }

    const json = await res.json()
    return json
  } catch (err) {
    console.error(`Failed to get resource from ${apiUrl}. Error:`, err)
    throw err
  }
}

export const api = {
  get: (url: string): Promise<any> => makeRequest(url, 'GET'),
  post: (url: string, postData: PostData): Promise<any> =>
    makeRequest(url, 'POST', postData),
  put: (url: string, postData: PostData): Promise<any> =>
    makeRequest(url, 'PUT', postData),
  delete: (url: string): Promise<any> => makeRequest(url, 'DELETE'),
}
