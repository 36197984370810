import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

export const DialogCloseIcon = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent) => void
}): JSX.Element => {
  return (
    <CloseIcon
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
      htmlColor={'#BBBBBB'}
    />
  )
}
