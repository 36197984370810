import React from 'react'
import { IconProps } from '../../assets.types'

export const GoogleAdsIcon: React.FunctionComponent<IconProps> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 65 59`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>icons8-google-ads</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icons8-google-ads"
          transform="translate(0.000000, 0.012315)"
          fillRule="nonzero"
        >
          <polygon
            id="Path"
            fill="#FFC107"
            points="41.8819794 16.2295959 23.118506 5.39618164 1.45167751 42.0631221 20.2151509 52.8965363"
          ></polygon>
          <path
            d="M44.7853344,52.8965363 C47.7770234,58.0782417 54.5304072,59.8032546 59.5837782,56.8615659 C64.7554835,53.8515435 66.5404969,47.2448275 63.5488079,42.0631221 L41.8819794,5.39618164 C38.8902904,0.214476278 32.1569068,-1.5105366 27.0835356,1.43115203 C21.9068302,4.43284111 20.126817,11.0478905 23.118506,16.2295959 L44.7853344,52.8965363 Z"
            id="Path"
            fill="#1E88E5"
          ></path>
          <circle
            id="Oval"
            fill="#43A047"
            cx="10.8334142"
            cy="47.4798292"
            r="10.8334142"
          ></circle>
        </g>
      </g>
    </svg>
  )
}
