import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'

const H1 = styled('h1')`
  font-size: 1.31rem;
  font-weight: var(--font-weight--medium);
  display: flex;
  align-items: center;
`

const Title = styled('span', {
  shouldForwardProp: (prop: any): boolean => prop !== 'sticky',
})<{ sticky?: boolean }>(
  ({ sticky }) => `
  position: ${sticky ? 'sticky' : ''};
  left: ${sticky ? '0' : ''};
`,
)

const Subtitle = styled('span')`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${Color.LightGrey};
  margin-bottom: 0;
`

const HeadlineContainer = styled('div')`
  margin: 0 0 0.438rem 0;
`

export type HeadlineProps = {
  title: string
  subtitle?: string
  sticky?: boolean
  icon?: JSX.Element
  marginBottom?: number
}

export const Headline: React.FunctionComponent<HeadlineProps> = ({
  title,
  subtitle,
  sticky,
  icon,
}) => (
  <HeadlineContainer>
    <Typography
      sx={{
        typography: { lg: 'h5', sm: 'h6', xs: 'h7' },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Title sticky={sticky}>{title}</Title>
      {icon && icon}
    </Typography>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </HeadlineContainer>
)
