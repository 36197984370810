import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect, Switch } from 'react-router-dom'
import { AccountScreen } from './screens/users/AccountScreen'
import { RevenueScreen } from './screens/reports/revenue'
import { SalesForecastScreen } from './screens/reports/forecast/salesForecast'
import { ProductDemandForecastScreen } from './screens/reports/forecast/productDemandForecast'
import { LtvScreen } from './screens/reports/ltv'
import { RoasScreen } from './screens/reports/roas'
import { LoginScreen } from './screens/users/LoginScreen'
import { ResetPasswordScreen } from './screens/users/ResetPasswordScreen'
import { SignupScreen } from './screens/users/SignupScreen'
import { TrackedRoute } from './screens/TrackedRoute'
import { ConnectorsScreen } from './screens/connectors/ConnectorsScreen'
import { PricingScreen } from './screens/pricing/PricingScreen'
import { AdminDashboardScreen } from './screens/admin/Dashboard'
import { CreditDashboardScreen } from './screens/admin/credit/AccountLabelling'
import { ProductSegmentScreen } from './screens/admin/productDemand/ProductSegments'
import { ToolbarLessMaster } from './screens/master/ToolbarLessMaster'
import { OverviewScreen } from './screens/reports/overview/Overview'
import { InsightsScreen } from './screens/reports/insights/Insights'
import { CashPositionScreen } from './screens/reports/cashPosition/CashPosition'
import { TransactionsScreen } from './screens/reports/transactions/Transactions'

export const AppRoutes = (): JSX.Element => (
  <Switch>
    <TrackedRoute
      exact
      path="/transactions/:attrHash?"
      title={'Transactions'}
      component={TransactionsScreen}
    ></TrackedRoute>
    <TrackedRoute exact path="/revenue" title={'Revenue Tracker'}>
      <Redirect to="/revenue/month" />
    </TrackedRoute>
    <TrackedRoute
      path="/revenue/:interval"
      component={RevenueScreen}
      title={'Revenue Tracker'}
    />
    <TrackedRoute exact path="/ltv" component={LtvScreen} title={'LTV'} />
    <TrackedRoute
      exact
      path="/cash-position"
      component={CashPositionScreen}
      title={'Cash Position'}
    />
    <TrackedRoute
      exact
      path="/forecast"
      component={SalesForecastScreen}
      title={'Sales Planning'}
    />
    <TrackedRoute
      exact
      path="/product/forecast"
      component={ProductDemandForecastScreen}
      title={'Product Demand'}
    />
    <TrackedRoute exact path="/roas" title={'ROAS'}>
      <Redirect to="/roas/month" />
    </TrackedRoute>
    <TrackedRoute
      path="/roas/:interval"
      component={RoasScreen}
      title={'ROAS'}
    />
    {/** When we changed signup to include company name companies/add became a dup of connectors */}
    <TrackedRoute path="/companies/add" title={'Add Companies'}>
      <Redirect to="/connectors" />
    </TrackedRoute>
    <TrackedRoute
      exact
      path="/connectors/:modalToShow?"
      component={ConnectorsScreen}
      title={'Connections'}
    />
    <TrackedRoute
      path="/users/account"
      component={AccountScreen}
      title={'Settings'}
    />
    <TrackedRoute
      exact
      path="/users/login"
      component={LoginScreen}
      title={'Login'}
    />
    <TrackedRoute
      path="/users/reset-password"
      component={ResetPasswordScreen}
      title={'Password Reset'}
    />
    <TrackedRoute
      path="/users/pricing"
      component={PricingScreen}
      title={'Pricing'}
    />
    <Route
      path="/customer-service/productDemand/productSegments/:companyId"
      component={ProductSegmentScreen}
      title={'Product Segments Dashboard'}
    />
    <TrackedRoute
      path="/users/terms-of-service"
      component={ToolbarLessMaster}
      title={'TOS'}
    />
    <TrackedRoute
      path="/get-started/"
      component={ToolbarLessMaster}
      title={'Get Started'}
    />
    <Route
      path="/customer-service/credit/labels"
      component={CreditDashboardScreen}
      title={'Credit Labels'}
    />
    <Route
      path="/customer-service/dashboard"
      component={AdminDashboardScreen}
      title={'Admin Dashboard'}
    />
    <TrackedRoute
      path="/users/signup"
      component={SignupScreen}
      title={'Signup'}
    />
    <TrackedRoute
      exact
      path="/overview/:modalToShow?"
      component={OverviewScreen}
      title={'Overview'}
    />
    <TrackedRoute
      path="/insights"
      component={InsightsScreen}
      title={'Insights'}
    />
  </Switch>
)
