import React, { ReactNode } from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Color } from '../../../../Color'

interface Props {
  title: string
  widgets?: ReactNode
  children: ReactNode
  sx?: SxProps<Theme>
}

export const DataPresentationCard = ({
  title,
  widgets,
  children,
  sx,
}: Props): JSX.Element => (
  <Card
    sx={{
      borderRadius: '8px',
      padding: '24px',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
      width: '100%',
      ...sx,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: '600', color: Color.LightGrey }}
      >
        {title}
      </Typography>
      {widgets && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {widgets}
        </Box>
      )}
    </Box>
    <Box>{children}</Box>
  </Card>
)
