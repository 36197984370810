import React from 'react'
import ReactModal from 'react-modal'
import { styled } from '@mui/material/styles'

import { Color } from '../../../../Color'

const Modal = styled('div')`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #888;
  max-width: 500px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalOverlay = styled('div')`
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 30px 20px 30px;
  width: 100%;
`

const ModalCloseButton = styled('button')`
  border: none;
  color: #bbbbbb;
  background-color: ${Color.White};
  font-size: 1.31rem;
  cursor: pointer;

  &:active:enabled {
    opacity: 0.4;
  }
`

const ModalBody = styled('div')`
  padding: 0px 40px 30px 40px;
  width: 100%;
`

type Props = {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}
export const PostAuthModal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
  children,
}) => {
  ReactModal.setAppElement('#root')
  return (
    <ReactModal
      className="PostAuthConnectorModal"
      overlayClassName="PostAuthConnectorModalOverlay"
      contentElement={(props, children) => <Modal {...props}>{children}</Modal>}
      overlayElement={(props, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
      isOpen={isOpen}
    >
      <ModalHeader>
        <ModalCloseButton onClick={(): void => onClose()}>
          &#10005;
        </ModalCloseButton>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ReactModal>
  )
}
