import React from 'react'

import {
  ACCOUNTING_CONNECTORS,
  CONNECTORS_CATEGORIES,
} from '../../../../../../../../server/connectors/known-connectors'
import { Connector } from '../../../../../../connectors/components/Connector'

import {
  OverviewEmptyStateContainer,
  OverviewEmptyStateDescription,
} from '../../../OverviewEmptyStateContainer/OverviewEmptyStateContainer'

import { OverviewCardConsts } from '../../../../Overview.consts'
import { OverviewCardTypes } from '../../../../Overview.types'

export const CreditScoreEmptyState = () => {
  const { emptyStateDescription } = OverviewCardConsts[
    OverviewCardTypes.CreditScore
  ]
  return (
    <OverviewEmptyStateContainer>
      <OverviewEmptyStateDescription>
        Choose <b>+ Add</b> to {emptyStateDescription}
      </OverviewEmptyStateDescription>
      {ACCOUNTING_CONNECTORS.map(c => (
        <Connector
          key={c}
          providerName={c}
          category={CONNECTORS_CATEGORIES.accounting}
          redirectUrl="/overview"
          isSmall={true}
        />
      ))}
    </OverviewEmptyStateContainer>
  )
}
