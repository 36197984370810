import React from 'react'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import { BFLogo } from '../../atoms/BFLogo/BFLogo'
import { Color } from '../../../../Color'

type Props = {
  menuAction: () => void
  isVisible?: boolean
}

export const NavigationTopbar: React.FunctionComponent<Props> = ({
  menuAction,
  isVisible = true,
}) => {
  return (
    <>
      {isVisible && (
        <Box
          sx={{
            background: Color.BrandPurple,
            display: 'flex',
            padding: '13px 0',
            position: 'relative',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="open menu"
            sx={{
              position: 'absolute',
              left: 10,
            }}
            onClick={menuAction}
          >
            <MenuIcon sx={{ color: Color.White }} />
          </IconButton>
          <Box
            sx={{
              margin: '0 auto',
            }}
          >
            <BFLogo width={211} height={47} useVariant />
          </Box>
        </Box>
      )}
    </>
  )
}
