import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Color } from '../../../Color'

const DisclaimerParagraph = styled(Typography)`
  font-size: 12px;
  max-width: 700px;
  color: ${Color.DarkCharcoal};
`

interface Props {
  type: DisclaimerType
}

// add additional types as needed
export enum DisclaimerType {
  forecast = 'forecast',
}

const DisclaimerTextMap: { [key in DisclaimerType]: string } = {
  [DisclaimerType.forecast]:
    'All content is for information purposes only. Nothing in this application constitutes professional and/or financial advice nor is the information a comprehensive or complete statement of financial matters of a business.',
}

export const FooterDisclaimerText = ({ type }: Props): JSX.Element => {
  return <DisclaimerParagraph>{DisclaimerTextMap[type]}</DisclaimerParagraph>
}
