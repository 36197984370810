import React from 'react'

import { useSalesForecast } from '../../SalesForecastProvider'

import { NoConnections, UpgradePlanScreen } from '../../../../shared'
import { WomanCoinIcon } from '../../../../../../components/designSystem/assets/WomanCoinIcon/WomanCoinIcon'
import { WomanCoinBillIcon } from '../../../../../../components/designSystem/assets/WomanCoinBillIcon/WomanCoinBillIcon'
import { LoadingCircular } from '../../../../../../components/designSystem/atoms/Loading/Loading'
import { SalesForecastErrorState } from '../SalesForecastErrorState/SalesForecastErrorState'
import { SalesForecastHeadingSection } from '../SalesForecastHeadingSection/SalesForecastHeadingSection'
import { SalesForecastPageActions } from '../SalesForecastPageActions/SalesForecastPageActions'
import { SalesForecastContentBody } from '../SalesForecastContentBody/SalesForecastContentBody'

type Props = {
  hasFeatureAccess: boolean
  hasRevenueConnection: boolean
  isSynced: boolean
}

export const SalesForecastWrapper = ({
  hasFeatureAccess,
  hasRevenueConnection,
  isSynced,
}: Props): JSX.Element => {
  const { isLoading, hasError, chartRows } = useSalesForecast()

  const hasForecastData = chartRows?.length

  if (isLoading) {
    return <LoadingCircular />
  }

  if (!hasFeatureAccess) {
    return (
      <UpgradePlanScreen
        title="Get An Automated Sales Forecast"
        subtitle="Leverage our AI-enabled technology to speed up budgeting & planning processes."
        renderIcon={(): React.ReactNode => (
          <WomanCoinIcon height={170} width={167} />
        )}
      />
    )
  }

  if (!hasRevenueConnection) {
    return (
      <NoConnections
        title="Get An Automated Sales Forecast"
        subtitle="To get started, connect a revenue account."
        icon={<WomanCoinIcon height={170} width={167} />}
      />
    )
  }

  if (!isSynced || (!hasForecastData && !hasError)) {
    return (
      <NoConnections
        title="Sync Incomplete"
        subtitle="We are collecting the data for your forecast."
        icon={<WomanCoinBillIcon height={177} width={131.62} />}
        buttonText="Check Connection Status"
      />
    )
  }

  if (hasError) {
    return <SalesForecastErrorState />
  }

  return (
    <>
      <SalesForecastHeadingSection />
      <SalesForecastPageActions />
      <SalesForecastContentBody />
    </>
  )
}
