import React, { useMemo } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'

import { NumericCriteriaOperators } from '../../../../../components/designSystem/molecules/NumericCriteriaInput/NumberCriteriaInput.types'
import { NumericCriteriaInput } from '../../../../../components/designSystem/molecules/NumericCriteriaInput/NumericCriteriaInput'
import { DateRangeSelector } from '../../../../../components/designSystem/molecules/DateRangeSelector/DateRangeSelector'

import { Color } from '../../../../../Color'

import {
  ColumnFilterContainer,
  ColumnFilters,
  StyledInput,
  TableActionButton,
  TableActions,
  ToolBarContainer,
} from './TransactionsTableToolbar.styles'
import { BulkActionsMenu } from '../BulkActionsMenu/BulkActionsMenu'
import {
  Account,
  Category,
  Merchant,
  UrlAttributes,
} from '../../Transactions.types'

import { TransactionsTableAutocomplete } from '../TransactionsTableAutocomplete/TransactionsTableAutocomplete'
import {
  HandleAccountFilterChangeFn,
  HandleAmountFilterChangeFn,
  HandleBulkUpdateFn,
  HandleDateRangeFilterChangeFn,
  HandleDescriptionFilterChangeFn,
  HandleOpenAddMerchantModalFn,
  SelectedRows,
} from '../TransactionsTable/TransactionsTable.types'
import { TransationsAccountSelect } from '../TransationsAccountSelect/TransationsAccountSelect'
import {
  BlankCategoryId,
  BlankLabelOrName,
  BlankMerchantId,
} from '../../../../../../server/app-data-service/cash-position/cash-position.consts'
import { TransactionsTableMultiSelect } from '../TransactionsTableMultiSelect/TransactionsTableMultiSelect'

type Props = {
  accounts: Account[]
  categories: Category[]
  handleAccountFilterChange: HandleAccountFilterChangeFn
  handleAmountFilterChange: HandleAmountFilterChangeFn
  handleBulkUpdate: HandleBulkUpdateFn
  handleCategoryFilterChange: (value: unknown) => void
  handleDateRangeFilterChange: HandleDateRangeFilterChangeFn
  handleDescriptionFilterChange: HandleDescriptionFilterChangeFn
  handleExportData: () => void
  handleMerchantFilterChange: (value: unknown) => void
  handleOpenAddMerchantModal: HandleOpenAddMerchantModalFn
  isLoading?: boolean
  merchants: Merchant[]
  selectedRows: SelectedRows
  urlAttributes: UrlAttributes
  descriptionFilterWidth: number
}

export const TransactionsTableToolbar: React.FunctionComponent<Props> = ({
  accounts,
  categories,
  handleAccountFilterChange,
  handleAmountFilterChange,
  handleBulkUpdate,
  handleCategoryFilterChange,
  handleDateRangeFilterChange,
  handleDescriptionFilterChange,
  handleExportData,
  handleMerchantFilterChange,
  handleOpenAddMerchantModal,
  isLoading,
  merchants,
  selectedRows,
  urlAttributes,
  descriptionFilterWidth,
}) => {
  const { amt, cat, desc, ed, merch, sd } = urlAttributes

  const initalDateRange = useMemo<[Date | null, Date | null]>(() => {
    const start = sd ? new Date(sd) : null
    const end = ed ? new Date(ed) : null

    return [start, end]
  }, [sd, ed])

  if (isLoading) return null

  return (
    <ToolBarContainer>
      <TableActions>
        <TransationsAccountSelect
          handleAccountFilterChange={handleAccountFilterChange}
          options={accounts}
        />
        <BulkActionsMenu
          merchants={merchants}
          categories={categories}
          selectedRows={selectedRows}
          handleBulkUpdate={handleBulkUpdate}
          handleOpenAddMerchantModal={handleOpenAddMerchantModal}
        />
        <TableActionButton
          onClick={handleExportData}
          startIcon={<SimCardDownloadOutlinedIcon />}
        >
          Export Data
        </TableActionButton>
      </TableActions>
      <ColumnFilters>
        <ColumnFilterContainer sx={{ flex: 1 }}>
          <DateRangeSelector
            intialValue={initalDateRange}
            onChange={handleDateRangeFilterChange}
            placeholder="Select Date Range"
            isFutureDisabled={true}
          />
        </ColumnFilterContainer>
        <ColumnFilterContainer sx={{ flex: 0.5 }}></ColumnFilterContainer>
        <ColumnFilterContainer
          sx={{
            ...(descriptionFilterWidth
              ? { width: descriptionFilterWidth }
              : { flex: 1 }),
            maxWidth: 650,
          }}
        >
          <StyledInput
            startAdornment={
              <SearchIcon sx={{ height: '15px', color: Color.LightGrey }} />
            }
            onChange={handleDescriptionFilterChange}
            initialValue={desc}
          />
        </ColumnFilterContainer>
        <ColumnFilterContainer sx={{ flex: 1 }}>
          <NumericCriteriaInput
            onChange={handleAmountFilterChange}
            defaultOperator={amt?.op || NumericCriteriaOperators.GREATEROREQUAL}
            defaultValue={amt?.val}
            placeholder="e.g. 0"
          />
        </ColumnFilterContainer>
        <ColumnFilterContainer sx={{ flex: 1 }}>
          <TransactionsTableAutocomplete
            defaultValue={merch}
            onChange={handleMerchantFilterChange}
            options={[
              ...merchants,
              { merchantId: BlankMerchantId, merchantName: BlankLabelOrName },
            ]}
          />
        </ColumnFilterContainer>
        <ColumnFilterContainer sx={{ flex: 1 }}>
          <TransactionsTableMultiSelect
            initialValue={cat}
            onChange={handleCategoryFilterChange}
            options={[
              ...categories,
              { id: BlankCategoryId, label: BlankLabelOrName, parent: '' },
            ]}
          />
        </ColumnFilterContainer>
      </ColumnFilters>
    </ToolBarContainer>
  )
}
