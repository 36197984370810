import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { ExternalLink, Link } from '../../components/forms/StyledElements'
import { TermsOfServiceLink } from '../../components/designSystem/atoms/TermOfServiceLink/TermsOfServiceLink'
import useApiRequest from '../../hooks/useApiRequest'
import { AgreementType } from '../../../server/data/models/types'

export const TermsOfServiceScreen = (): JSX.Element => {
  const { makeRequest } = useApiRequest()

  const acceptTermsOfService = async (): Promise<void> => {
    const { results } = await makeRequest('/users/agreements/create', 'post', {
      type: AgreementType.TERMS_OF_SERVICE,
    })

    if (results.success) {
      window.location.replace('/users/home')
    }
  }
  return (
    <>
      <Typography mb={6} variant="h4">
        We&apos;ve made changes to our Terms of Service
      </Typography>
      <Typography mb={2} variant="h5">
        What do I need to do?
      </Typography>
      <Typography mb={5} variant="body1" sx={{ fontSize: '16px' }}>
        <span>You must accept the new </span>
        <TermsOfServiceLink />
        <span> in order to proceed to the</span>
        <Link to="/users/login">platform</Link>
        <span>
          . If you do not accept these terms, you will not be able to access
          your account. If you would like to opt out, or if you have any
          questions, please email{' '}
        </span>
        <ExternalLink href="mailto:support@brightflow.ai">
          support@brightflow.ai
        </ExternalLink>
        <span>.</span>
      </Typography>
      <Grid container direction="row" justifyContent="right">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size={'large'}
            onClick={acceptTermsOfService}
          >
            I accept
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
