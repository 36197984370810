import React from 'react'
import Box from '@mui/material/Box'

import { Color } from '../../../../Color'
import { TooltipRow } from './BasicTooltip.styles'
import { TooltipPayload } from 'recharts'

interface BasicTooltipProps {
  active?: boolean
  payload?: TooltipPayload[]
  label?: string | number
  labelFormatter?: (label: string | number) => React.ReactNode
  dataFormatter?: (data: TooltipPayload) => React.ReactNode
}

export const BasicTooltip = (props: BasicTooltipProps): JSX.Element | null => {
  const { active, payload, label, labelFormatter, dataFormatter } = props

  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          width: '243.5px',
          height: '148px',
          color: Color.White,
          backgroundColor: Color.Black,
          opacity: '80%',
          borderRadius: 1,
          padding: 3,
        }}
      >
        {label && labelFormatter && (
          <TooltipRow>{labelFormatter(label)}</TooltipRow>
        )}
        {dataFormatter &&
          payload.map(data => (
            <TooltipRow key={String(data.dataKey)}>
              {dataFormatter(data)}
            </TooltipRow>
          ))}
      </Box>
    )
  }

  return null
}
