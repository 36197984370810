import React from 'react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { SpeedBumpModal } from '../../designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { SuccessIcon } from '../../designSystem/assets/SuccessIcon/SuccessIcon'
import { ActionButton } from '../../designSystem/atoms/ActionButton/ActionButton'

const StackedMargin = styled(Stack)`
  margin: 25px 0px;
  width: 100%;
`

const Link = (): JSX.Element => {
  return (
    <StackedMargin spacing={3}>
      <ActionButton
        onClick={() => {
          window.location.replace('/users/login')
        }}
        buttonType="primary"
        text="Login"
        style={{ height: '45px', width: '100%' }}
      />
    </StackedMargin>
  )
}

export const PasswordUpdated = (): JSX.Element => (
  <div>
    <SpeedBumpModal
      title={'Success'}
      text={`Your password has been reset.`}
      secondaryContent={<Link />}
    >
      <SuccessIcon height={35} width={35} />
    </SpeedBumpModal>
  </div>
)
