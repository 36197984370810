import { endOfWeek, format } from 'date-fns'
import {
  GridColumns,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import {
  DateGranularity,
  ForecastEntry,
  SalesForecastByDuration,
  SalesForecastStoreAggregate,
} from '../../../../../server/app-data-service/generatedTypes'
import {
  SalesForecastCalculatedStateType,
  SalesForecastChartRow,
  SalesForecastDataCategory,
  SalesForecastTableGroups,
} from './SalesForecast.types'

const formatTableFieldName = (
  index: number,
  granularity: DateGranularity,
  field: SalesForecastDataCategory,
): string => `${index}${granularity}${field}`

export const formatDateString = (
  date: number,
  granularity: DateGranularity,
): string =>
  granularity === 'Week'
    ? `${format(new Date(date), 'M/dd')}-${format(
        endOfWeek(new Date(date), { weekStartsOn: 1 }),
        'M/dd',
      )}`
    : format(new Date(date), 'M/yy')

const formatDataAsChartRows = (
  actuals: ForecastEntry[],
  forecasted: ForecastEntry[],
): SalesForecastChartRow[] => {
  const actualsData = actuals.reduce(
    (acc: any, curr: any): any =>
      Object.assign(acc, {
        [curr.start]: {
          ds: format(new Date(curr.start), 'PP'),
          y: curr.value / 100,
        },
      }),
    {},
  )

  const forecastedWithActualsData = forecasted.reduce(
    (acc: any, curr: any): any =>
      Object.assign(acc, {
        [curr.start]: {
          ds: format(new Date(curr.start), 'PP'),
          y: null,
          yhat: curr.value / 100,
          trend: curr.linearTrend / 100,
          ...acc[curr.start],
        },
      }),
    actualsData,
  )

  return Object.values(forecastedWithActualsData)
}

const formatDataAsTableColumns = (
  actuals: ForecastEntry[],
  forecasted: ForecastEntry[],
  granularity: DateGranularity,
): GridColumns => {
  const actualsColumns = actuals.map(({ start }, i) => ({
    field: formatTableFieldName(
      i + 1,
      granularity,
      SalesForecastDataCategory.Actuals,
    ),
    headerName: formatDateString(start, granularity),
    sortable: false,
  }))

  const forecastedColumns = forecasted
    .slice(actuals.length)
    .map(({ start }, i) => ({
      field: formatTableFieldName(
        i + 1 + actuals.length,
        granularity,
        SalesForecastDataCategory.Forecast,
      ),
      headerName: formatDateString(start, granularity),
      sortable: false,
    }))

  return [...actualsColumns, ...forecastedColumns]
}

const formatStoreDataAsTableRow = (
  store: SalesForecastStoreAggregate,
  granularity: DateGranularity,
): GridRowModel => {
  const { storeId, storeName, actuals, forecasted } = store

  const actualsTotals = actuals.reduce(
    (acc, curr, i) =>
      Object.assign(acc, {
        [formatTableFieldName(
          i + 1,
          granularity,
          SalesForecastDataCategory.Actuals,
        )]: curr.value,
      }),
    {},
  )

  const forecastedTotals = forecasted.slice(actuals.length).reduce(
    (acc, curr, i) =>
      Object.assign(acc, {
        [formatTableFieldName(
          i + 1 + actuals.length,
          granularity,
          SalesForecastDataCategory.Forecast,
        )]: curr.value,
      }),
    {},
  )

  return {
    id: storeId,
    category: ['Sales', storeName],
    ...actualsTotals,
    ...forecastedTotals,
  }
}

const formatDataAsTableRows = (
  actuals: ForecastEntry[],
  forecasted: ForecastEntry[],
  stores: SalesForecastStoreAggregate[],
  granularity: DateGranularity,
): GridValidRowModel[] => {
  const actualsTotals = actuals.reduce(
    (acc, curr, i) =>
      Object.assign(acc, {
        [formatTableFieldName(
          i + 1,
          granularity,
          SalesForecastDataCategory.Actuals,
        )]: curr.value,
      }),
    {},
  )

  const forecastedTotals = forecasted.slice(actuals.length).reduce(
    (acc, curr, i) =>
      Object.assign(acc, {
        [formatTableFieldName(
          i + 1 + actuals.length,
          granularity,
          SalesForecastDataCategory.Forecast,
        )]: curr.value,
      }),
    {},
  )

  return [
    {
      id: 'salesRow',
      category: ['Sales'],
      headerClassName: 'parent',
      ...actualsTotals,
      ...forecastedTotals,
    },
    ...stores.map(store => formatStoreDataAsTableRow(store, granularity)),
  ]
}

const formatDataAsTableGroups = (
  actuals: ForecastEntry[],
  forecasted: ForecastEntry[],
  granularity: DateGranularity,
): SalesForecastTableGroups => {
  const actualsFields = actuals.map((_, i) => ({
    field: formatTableFieldName(
      i + 1,
      granularity,
      SalesForecastDataCategory.Actuals,
    ),
  }))

  const forecastedFields = forecasted.slice(actuals.length).map((_, i) => ({
    field: formatTableFieldName(
      i + 1 + actuals.length,
      granularity,
      SalesForecastDataCategory.Forecast,
    ),
  }))

  return {
    actuals: actualsFields,
    forecasted: forecastedFields,
  }
}

export const getCalculatedStateFromSalesForecastResponse = (
  salesForecastResponse: SalesForecastByDuration,
  granularity: DateGranularity,
): SalesForecastCalculatedStateType => {
  const {
    combined: { actuals, forecasted },
    stores,
    totalActualValue,
    totalProjectedValue,
  } = salesForecastResponse

  return {
    chartRows: formatDataAsChartRows(actuals, forecasted),
    tableColumns: formatDataAsTableColumns(actuals, forecasted, granularity),
    tableRows: formatDataAsTableRows(actuals, forecasted, stores, granularity),
    tableGroups: formatDataAsTableGroups(actuals, forecasted, granularity),
    totalActualValue,
    totalProjectedValue,
  }
}
