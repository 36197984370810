import { useEffect, useState } from 'react'

/**
 * Useful for knowing if the user should be shown
 * a particular message based on a supplied query param + value.
 * Sets a timer based on the supplied displayedTime
 *
 * @param paramName // the name of a query param
 * @param paramValue // the expected value of the query param
 * @param displayedTime // milliseconds for which to show the display
 * @returns boolean
 *
 * Use case is there is a query param of ?isUpgrade
 * This hook can be used to set a timer if that queryParam
 * is detected on the first render
 */
export const TimedMessageFromQueryParam = (
  paramName: string,
  paramValue: string,
  displayedTime = 5000,
): boolean => {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [hasRendered, setHasRendered] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const windowUrl = window.location.search
      const params = new URLSearchParams(windowUrl)
      const isUpgrade = params.get(paramName)

      if (isUpgrade === paramValue && !hasRendered && !showMessage) {
        setShowMessage(true)
        setHasRendered(true)
        params.delete(paramName)
        setTimeout(() => {
          setShowMessage(false)
        }, displayedTime)
      }
    }
  })
  return showMessage
}
