import {
  TimeSeriesChannel,
  TimeSeriesData,
  useGetSalesByDurationQuery,
} from '../../../../../../server/app-data-service/generatedTypes'

import { Props } from './OverviewRevenueCard.types'
import { extractMetricValueByType } from './OverviewRevenueCard.utils'

export const useOverviewRevenueCard = ({
  cardType,
  duration,
  granularity,
  revenueConnections,
}: Props) => {
  const { data, loading } = useGetSalesByDurationQuery({
    variables: {
      duration,
      granularity,
    },
  })
  const hasOneYearRevenueData = revenueConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const metricValue = data ? extractMetricValueByType(cardType, data) : null

  const channels = metricValue?.channels?.filter(
    (channel): channel is TimeSeriesChannel => !!channel,
  )

  const nonZeroChannels = channels?.filter(
    channel => channel?.total && channel?.total > 0,
  )

  const totals: TimeSeriesData = metricValue?.totals ?? {
    total: null,
    timeSeries: null,
  }

  return {
    channels: nonZeroChannels?.length ? nonZeroChannels : null,
    isEmpty: !hasOneYearRevenueData,
    isLoading: loading,
    totals,
  }
}
