import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/react-hooks'

// TODO Use en var for UI? BASE_URL?
const httpUri = '/graphql'

const httpLink = createHttpLink({
  uri: httpUri,
})

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})
