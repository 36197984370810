import React from 'react'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { Layout } from '../../../layout'
import { UserGuidanceBase } from '../../shared'

export const ScheduleACallScreen = ({
  renderIcon,
  title,
  subtitle,
  email,
}: {
  renderIcon: () => React.ReactNode
  title: string
  subtitle: string
  email?: string
}): JSX.Element => {
  return (
    <Layout>
      <UserGuidanceBase
        renderIcon={renderIcon}
        title={title}
        subtitle={subtitle}
        emailLink={email && <Link href={`mailto:${email}`}>{email}</Link>}
      >
        <Button
          href="https://brightflow.ai/request-a-call/"
          size="large"
          variant="contained"
          color="primary"
        >
          Schedule a Call
        </Button>
      </UserGuidanceBase>
    </Layout>
  )
}
