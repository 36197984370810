import React from 'react'
import { LegendCard } from './components/LegendCard'
import { LegendHeader } from './components/LegendHeader'
import { LegendContent } from './components/LegendContent'
import { LegendAction } from './components/LegendAction'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

export interface LegendEntryProps {
  title: string
  color: string
  actionText?: string
  renderBody: JSX.Element
  onActionClick: (event: React.MouseEvent) => void
  onClose?: (event: React.MouseEvent) => void
  sx?: SxProps<Theme>
}

export const LegendEntry = ({
  actionText,
  title,
  color,
  renderBody,
  onActionClick,
  onClose,
  sx,
}: LegendEntryProps): JSX.Element => {
  return (
    <>
      <LegendCard
        onClose={onClose}
        sx={{ ...sx }}
        titleComponent={<LegendHeader color={color} title={title} />}
        contentComponent={<LegendContent>{renderBody}</LegendContent>}
        renderLegendAction={
          actionText ? (
            <LegendAction
              text={actionText}
              onClick={(event: React.MouseEvent): void => {
                if (onActionClick) {
                  onActionClick(event)
                }
              }}
            />
          ) : undefined
        }
      />
    </>
  )
}
