import React, { ReactNode } from 'react'

import { NoConnections } from '../../../shared'
import { WomanCoinBillIcon } from '../../../../../components/designSystem/assets/WomanCoinBillIcon/WomanCoinBillIcon'
import { UpgradePlanScreen } from '../../../shared/UpgradePlanScreen'

import { useCashPosition } from '../../CashPositionProvider'
import { CashPositionHeadingSection } from '../CashPositionHeadingSection/CashPositionHeadingSection'
import { CashPositionPageActions } from '../CashPositionPageActions/CashPositionPageActions'
import { CashPositionContentBody } from '../CashPositionContentBody/CashPositionContentBody'
import { CashPositionErrorState } from '../CashPositionErrorState/CashPositionErrorState'
import { LoadingCircular } from '../../../../../components/designSystem/atoms/Loading/Loading'

type Props = {
  hasBankingConnection: boolean
  hasFeatureAccess: boolean
  isSynced: boolean
}

export const CashPositionWrapper = ({
  hasBankingConnection,
  hasFeatureAccess,
  isSynced,
}: Props): JSX.Element => {
  const { syncTimestamp, hasError, isLoading } = useCashPosition()

  if (isLoading) {
    return <LoadingCircular />
  }

  if (!hasFeatureAccess) {
    return (
      <UpgradePlanScreen
        title="Analyze Insights And Trends On Your Daily Cash Position"
        subtitle="Our technology aggregates and enriches transaction data across all your cash sources to provide a real-time view on your
        cash balance, inflows, and outflows."
        renderIcon={(): ReactNode => (
          <WomanCoinBillIcon height={177} width={131.62} />
        )}
      />
    )
  }

  if (!hasBankingConnection) {
    return (
      <NoConnections
        title="Analyze Your Current Cash Position"
        subtitle="To get started, please connect your bank account."
        icon={<WomanCoinBillIcon height={177} width={131.62} />}
        buttonText="Make a Connection"
      />
    )
  }

  if (!isSynced || (!syncTimestamp && !hasError)) {
    return (
      <NoConnections
        title="Collecting And Enriching Transaction Data"
        subtitle="Please allow 24 hours to collect and enrich transaction data."
        icon={<WomanCoinBillIcon height={177} width={131.62} />}
        buttonText="Check Connection Status"
      />
    )
  }

  if (hasError) return <CashPositionErrorState />

  return (
    <>
      <CashPositionHeadingSection />
      <CashPositionPageActions />
      <CashPositionContentBody />
    </>
  )
}
