import React from 'react'
import { WomanWaitingIcon } from '../../../components/designSystem/assets/WomanWaitingIcon/WomanWaitingIcon'
import { UserGuidanceBase } from '../../shared/'

interface Props {
  title?: string
  subtitle?: string
  message?: string
}

export const WaitingData: React.FunctionComponent<Props> = ({
  title = '',
  subtitle = '',
  message = '',
}) => {
  return (
    <UserGuidanceBase
      renderIcon={(): React.ReactNode => (
        <WomanWaitingIcon height={170} width={167} />
      )}
      title={title}
      subtitle={subtitle}
      message={message}
    />
  )
}
