import React from 'react'
import Button from '@mui/material/Button'
import { Color } from '../../../../../Color'

export const LegendAction = ({
  text,
  onClick,
}: {
  text: string
  onClick: (event: React.MouseEvent) => void
}): JSX.Element => {
  return (
    <>
      <Button
        size="medium"
        variant="text"
        style={{ color: Color.DarkBlue }}
        onClick={onClick}
      >
        {text}
      </Button>
    </>
  )
}
