import React from 'react'
import { DataSyncing } from '../../../shared'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Color } from '../../../../../Color'
import { Layout } from '../../../../../layout'

export const TransactionsErrorState = (): JSX.Element => (
  <Layout>
    <DataSyncing
      title="Transactions are Unavailable"
      subtitle="We've ran into a snag loading your report. Please try again later."
      icon={
        <WarningAmberIcon
          sx={{ color: Color.ErrorRed, height: '98px', width: '113.5px' }}
        />
      }
    />
  </Layout>
)
