import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import { DialogCloseIcon } from '../../atoms/DialogCloseIcon/DialogCloseIcon'

const ModalContainer = styled(Box, {
  shouldForwardProp: (props: any): boolean =>
    props !== 'height' && props !== 'width',
})<{
  height?: number
  width?: number
}>(
  ({ height, width }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e7e7e7;
  height: ${height}
  width: ${width}

  :focus {
    outline: none !important;
  }
`,
)

interface Props {
  title?: string
  isOpen: boolean
  handleClose:
    | ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void)
    | undefined
  height: number
  width: number
  ariaLabel?: string
  ariaDescription?: string
  onDismiss: () => void
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirmButtonClicked?: () => void
  onCancelButtonClicked?: () => void
  buttonJustifyContent: 'center' | 'flex-end' | 'flex-start'
  renderContent: () => JSX.Element
  onConfirmDisabled?: boolean
  confirmButtonColor?: 'primary' | 'error' | 'warning'
}

export const BasicModal: React.FunctionComponent<Props> = ({
  isOpen,
  handleClose,
  title,
  height,
  width,
  ariaLabel,
  ariaDescription,
  onDismiss,
  confirmButtonText,
  cancelButtonText,
  onConfirmButtonClicked,
  onCancelButtonClicked,
  buttonJustifyContent,
  renderContent,
  onConfirmDisabled = false,
  confirmButtonColor = 'primary',
}) => {
  return (
    <Modal
      BackdropProps={{ invisible: false, timeout: 500 }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
    >
      <ModalContainer sx={{ minHeight: height }} width={width}>
        <Stack justifyContent={'space-between'} sx={{ minHeight: height }}>
          <Stack>
            {title && (
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={11}>
                  <Typography variant="h5">{title}</Typography>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end">
                  <Box display="flex" justifyContent="flex-end">
                    <DialogCloseIcon onClick={onDismiss} />
                  </Box>
                </Grid>
              </Grid>
            )}
            {renderContent()}
          </Stack>

          {confirmButtonText && (
            <Box display="flex" justifyContent={buttonJustifyContent}>
              <Grid
                container
                justifyContent={'center'}
                sx={{ maxWidth: '250px', mt: 4 }}
              >
                <Grid item xs={6}>
                  {cancelButtonText && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        sx={{ width: '100px' }}
                        onClick={onCancelButtonClicked}
                        variant="outlined"
                        color={'primary'}
                        size="large"
                      >
                        {cancelButtonText}
                      </Button>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      sx={{ width: '100px' }}
                      disabled={onConfirmDisabled}
                      onClick={onConfirmButtonClicked}
                      variant="contained"
                      size="large"
                      color={confirmButtonColor}
                    >
                      {confirmButtonText}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>
      </ModalContainer>
    </Modal>
  )
}
