import React, { ReactNode } from 'react'
import { WomanCoinBillIcon } from '../../../../../components/designSystem/assets/WomanCoinBillIcon/WomanCoinBillIcon'
import { NoConnections } from '../../../shared'
import { UpgradePlanScreen } from '../../../shared/UpgradePlanScreen'
import { useCashPosition } from '../../../cashPosition/CashPositionProvider'
import { CashPositionErrorState } from '../../../cashPosition/components/CashPositionErrorState/CashPositionErrorState'
import { InsightsCard } from '../../components/InsightsCard/InsightsCard'
import { InsightsHeadingSection } from '../InsightsHeadingSection/InsightsHeadingSection'
import { InsightsPageActions } from '../InsightsPageActions/InsightsPageActions'

type Props = {
  hasBankingConnection: boolean
  hasFeatureAccess: boolean
  isSynced: boolean
}

export const InsightsWrapper = ({
  hasBankingConnection,
  hasFeatureAccess,
  isSynced,
}: Props): JSX.Element => {
  const { syncTimestamp, hasError, isLoading } = useCashPosition()

  if (!hasFeatureAccess && !isLoading) {
    return (
      <UpgradePlanScreen
        title="Analyze Insights And Trends On Your Daily Cash Position"
        subtitle="Our technology aggregates and enriches transaction data across all your cash sources to provide a real-time view on your
            cash balance, inflows, and outflows."
        renderIcon={(): ReactNode => (
          <WomanCoinBillIcon height={177} width={131.62} />
        )}
      />
    )
  }

  if (!hasBankingConnection && !isLoading) {
    return (
      <NoConnections
        title="Analyze Your Current Cash Position"
        subtitle="To get started, please connect your bank account."
        icon={<WomanCoinBillIcon height={177} width={131.62} />}
        buttonText="Make a Connection"
      />
    )
  }

  if ((!isSynced || (!syncTimestamp && !hasError)) && !isLoading) {
    return (
      <NoConnections
        title="Collecting And Enriching Transaction Data"
        subtitle="Please allow 24 hours to collect and enrich transaction data."
        icon={<WomanCoinBillIcon height={177} width={131.62} />}
        buttonText="Check Connection Status"
      />
    )
  }

  if (hasError && !isLoading) return <CashPositionErrorState />

  return (
    <>
      <InsightsHeadingSection />
      <InsightsPageActions />
      <InsightsCard isLoading={isLoading} />
    </>
  )
}
