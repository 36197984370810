import React from 'react'
import { IconProps } from '../../assets.types'

export const XeroIcon: React.FunctionComponent<IconProps> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 634.69 633.99"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Xero</title>
      <path
        d="M316.9 2.75C144.89 2.75 5.48 142.18 5.48 314.17s139.4 311.42 311.41 311.42 311.43-139.45 311.43-311.42S488.85 2.75 316.9 2.75"
        style={{
          fill: '#00b9ea',
          fillRule: 'evenodd',
        }}
      />
      <path
        d="M347.08 314.56c3.21-3.99 4.42-9.21 3.39-14.71-4.17-19.94-14.91-35.91-31.04-46.19-11.08-7.11-24.01-10.87-37.39-10.87-14.77 0-28.83 4.49-40.66 12.97-18.5 13.27-29.54 34.96-29.54 58 0 5.79.71 11.53 2.11 17.07 7.11 28.07 31.01 49.29 59.49 52.82 2.75.33 5.51.5 8.19.5 5.7 0 11.25-.73 16.96-2.23 7.42-1.8 14.48-4.83 21.01-9.01 6.16-4.03 11.84-9.46 17.81-17.04l.39-.41c1.99-2.5 2.91-5.72 2.53-8.83-.34-2.78-1.66-5.18-3.71-6.76-1.95-1.51-4.26-2.35-6.53-2.35s-5.44.81-8.37 4.66l-.23.31c-.97 1.3-1.97 2.65-3.12 3.98-3.95 4.47-8.48 8.16-13.47 10.95-7.14 3.84-14.86 5.82-22.92 5.88-25.32-.28-40.82-17.29-46.88-33.08-.95-2.84-1.63-5.44-2.07-7.85 0-.24-.03-.5-.04-.75l98.03-.02c6.81-.15 12.51-2.66 16.06-7.06Zm-113.32-13.45c5.59-22.12 25.29-37.56 47.92-37.56s42.36 15.4 47.97 37.56h-95.9Zm273.97 11.42c0 9.91-7.99 17.97-17.81 17.97s-17.85-8.06-17.85-17.97 8.01-17.96 17.85-17.96 17.81 8.06 17.81 17.96Zm-93.07-48.48c-.46 0-2.25.01-3.92.21-20.17 2.52-22.18 12.34-22.18 35.83v72.51c0 5.62-4.53 10.19-10.1 10.19s-10.06-4.57-10.1-10.19l-.03-117.9c.04-5.7 4.48-10.16 10.12-10.16 4.37 0 8.25 2.9 9.58 7.03 6.93-5.1 15.15-7.79 23.84-7.79l2.86.04c5.6 0 10.17 4.59 10.17 10.23s-4.31 9.76-10.25 9.98ZM211.2 366.24c1.91 1.95 2.96 4.5 2.96 7.2 0 5.65-4.56 10.24-10.17 10.24-2.72 0-5.33-1.14-7.33-3.22l-52.24-52.65-52.47 52.9c-1.96 1.9-4.5 2.95-7.15 2.95-5.58 0-10.12-4.58-10.12-10.22 0-2.79 1.11-5.4 3.13-7.34l52.27-52.85-52.35-52.78c-1.97-1.97-3.05-4.57-3.05-7.3 0-5.63 4.54-10.22 10.12-10.22 2.7 0 5.23 1.05 7.15 2.95l52.52 52.79L196.79 246c1.93-1.97 4.49-3.05 7.2-3.05 5.61 0 10.17 4.58 10.17 10.22 0 2.73-1.06 5.28-2.97 7.17l-52.31 52.96 52.32 52.95Zm278.71-124.23c-38.55 0-69.91 31.64-69.91 70.52s31.36 70.51 69.91 70.51 69.87-31.63 69.87-70.51-31.35-70.52-69.87-70.52Zm0 119.94c-27.03 0-49.01-22.17-49.01-49.41s21.99-49.47 49.01-49.47 48.96 22.19 48.96 49.47-21.96 49.41-48.96 49.41Z"
        style={{
          fillRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </svg>
  )
}
