import React from 'react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Brighflow } from '../../assets/Brightflow/Brightflow'

interface Props {
  title: string
  children?: JSX.Element
}

const LogoContainer = styled('div')`
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 18px;
`

export const MuiCard = ({ title, children }: Props): JSX.Element => {
  return (
    <Grid container justifyContent={'center'}>
      <Grid item width={285}>
        <LogoContainer>
          <Brighflow width={135} height={40} />
        </LogoContainer>
        <Typography mb={5} variant="h5" textAlign={'center'}>
          {title}
        </Typography>
        {children}
      </Grid>
    </Grid>
  )
}
