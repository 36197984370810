import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import { format } from 'date-fns'
import { CircularProgressWithLabel } from '../../../components/designSystem/molecules/CircularProgressWithLabel/CircularProgressWithLabel'

import {
  CategoryKeys,
  KnownConnectors,
} from '../../../../server/connectors/known-connectors'
import { Providers } from './Providers'
import { Color } from '../../../Color'
import { Store } from '../../../modules/rootReducer'

import { selectConnectors, selectIsLoaded } from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'

import { ConnectorStatusContainer } from './ConnectorStatusContainer'
import useLocalStorage from '../../../hooks/useLocalStorage'

const SyncStatus = styled('div')`
  align-items: center;
  height: 100%;
  text-align: right;
  margin-right: 15px;
  flex-grow: 1;
`

const SyncStatusHeader = styled('div')`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`

const Link12 = styled('a')`
  color: ${Color.DarkBlue};
  font-size: 12px;
  text-decoration: none;
`

const SyncStatusInfo = styled('div')`
  color: #8b8b8b;
  font-size: 12px;
`

const BadgeContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 55px;
  width: 85px;
`

const renderResponsiveButton = (
  text: string,
  color: 'success' | 'error' | 'warning',
  variant: 'contained' | 'outlined',
  onClick: () => void,
): JSX.Element => {
  return (
    <>
      <Hidden smDown>
        <Button size="large" onClick={onClick} variant={variant} color={color}>
          {text}
        </Button>
      </Hidden>
      <Hidden smUp>
        <Button size="small" onClick={onClick} variant={variant} color={color}>
          {text}
        </Button>
      </Hidden>
    </>
  )
}

type Props = {
  connection?: Connection
  isLoaded: boolean
  providerName: KnownConnectors
  category: CategoryKeys
  redirectUrl?: string
  isSmall?: boolean
  isButtonFilled?: boolean
}

const ConnectorImpl: React.FunctionComponent<Props> = ({
  providerName,
  category,
  connection,
  redirectUrl = '/connectors',
  isSmall,
  isButtonFilled = false,
}) => {
  const { modal: Modal, url, isOutboundUrl, shouldRedirect } = Providers[
    providerName
  ]

  const { modalToShow } = useParams<{ modalToShow: string }>()

  // Using localStorage to handle modal opening mostly for external connections since we dont rely fully on modalToShow now
  // If exists on localStorage then the modal is opened if url is /connector/:connection
  // localStorage value is created on modal opening and removed on modal close
  // Need to allow opening the specific connector modal on multiple connections
  const localStorageKey = `connection-${providerName}`

  const localStorageValue = connection?.id ?? 'new'

  const [activeConnection, setActiveConnection] = useLocalStorage(
    localStorageKey,
    '',
  )

  const history = useHistory()

  const outboundNav = (): void => {
    const parsedURL = shouldRedirect
      ? `${url}${encodeURIComponent(`${redirectUrl}/${providerName}`)}`
      : url
    document.location.href = parsedURL
  }
  const localNav = (): void => history.push(`${redirectUrl}${url}`)

  const initAuth: () => void = isOutboundUrl ? outboundNav : localNav

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    activeConnection === localStorageValue,
  )

  const handleClick = () => {
    setActiveConnection(localStorageValue)
    setIsModalOpen(true)
    initAuth()
  }

  const handleCloseModal = () => {
    localStorage.removeItem(localStorageKey)
    setIsModalOpen(false)
    history.push(redirectUrl)
  }

  const buttonVariant = isButtonFilled ? 'contained' : 'outlined'

  return (
    <>
      {Modal && isModalOpen && modalToShow === providerName && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          connection={connection}
        />
      )}
      <ConnectorStatusContainer
        providerKey={providerName}
        customTitle={connection?.connectionInfo?.name}
        isSmall={isSmall}
      >
        {(() => {
          if (!connection) {
            return renderResponsiveButton(
              '+ Add',
              'success',
              buttonVariant,
              handleClick,
            )
          } else if (connection.invalidatedAt) {
            return (
              <React.Fragment>
                <Hidden smDown>
                  <SyncStatus>
                    <SyncStatusHeader style={{ color: Color.ErrorRed }}>
                      Sync Error
                    </SyncStatusHeader>
                  </SyncStatus>
                </Hidden>
                {renderResponsiveButton(
                  'Reconnect',
                  'error',
                  buttonVariant,
                  handleClick,
                )}
              </React.Fragment>
            )
          } else if (connection.initialSyncStatus === 'pending') {
            return (
              <React.Fragment>
                <Hidden smDown>
                  <SyncStatus>
                    <SyncStatusHeader>Syncing</SyncStatusHeader>
                    {(() => {
                      if (connection.percentOfWeekComplete === 100) {
                        return (
                          <>
                            <SyncStatusInfo>
                              <CheckCircleIcon
                                color="success"
                                sx={{ fontSize: 12 }}
                              />
                              &nbsp; One week has synced
                            </SyncStatusInfo>
                            {category === 'asset' && (
                              <Link12 href="/revenue/day">
                                View Revenue Tracker
                              </Link12>
                            )}
                          </>
                        )
                      }
                    })()}
                  </SyncStatus>
                </Hidden>
                <BadgeContainer>
                  <CircularProgressWithLabel
                    value={connection.percentOfYearComplete}
                    color="secondary"
                  />
                </BadgeContainer>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                <Hidden smDown>
                  <SyncStatus>
                    <SyncStatusHeader style={{ color: Color.VioletEggplant }}>
                      Sync Complete
                    </SyncStatusHeader>
                    <SyncStatusInfo>
                      Updated{' '}
                      {connection.lastSyncedAt &&
                        format(connection.lastSyncedAt, 'hh:mmaaaa MM/dd/yyyy')}
                    </SyncStatusInfo>
                  </SyncStatus>
                </Hidden>
                <BadgeContainer>
                  <CheckCircleIcon color="success" sx={{ fontSize: 45 }} />
                </BadgeContainer>
              </React.Fragment>
            )
          }
        })()}
      </ConnectorStatusContainer>
    </>
  )
}

type ownProps = {
  providerName: KnownConnectors
  providerId?: string
  redirectUrl?: string
  allowMultipleConnection?: boolean
  category: CategoryKeys
  isSmall?: boolean
  isButtonFilled?: boolean
}

const mapStateToProps = (state: Store, ownProps: ownProps): Props => {
  const {
    providerName,
    providerId,
    allowMultipleConnection,
    category,
  } = ownProps
  const key = providerName
  const id = providerId
  let connection: Connection | undefined
  if (allowMultipleConnection) {
    // set connection as undefined so we can just add a new one
    connection = undefined
  } else if (id) {
    // search by id instead
    connection = selectConnectors(state.connectors).find(c => c.id === id)
  } else {
    connection = selectConnectors(state.connectors).find(c => c.key === key)
  }
  return {
    connection: connection,
    providerName: key,
    isLoaded: selectIsLoaded(state.connectors),
    category: category,
    redirectUrl: ownProps.redirectUrl,
    isSmall: ownProps.isSmall,
  }
}

export const Connector = connect(mapStateToProps)(ConnectorImpl)
