import React from 'react'

import {
  OverviewEmptyStateContainer,
  OverviewEmptyStateDescription,
} from '../../OverviewEmptyStateContainer/OverviewEmptyStateContainer'

import {
  CONNECTORS_CATEGORIES,
  REVENUE_CONNECTORS,
} from '../../../../../../../server/connectors/known-connectors'
import { Connector } from '../../../../../connectors/components/Connector'

import { OverviewCardConsts } from '../../../Overview.consts'
import { OverviewCardTypes } from '../../../Overview.types'

export const OverviewOrderRevenueEmptyState = () => {
  const { emptyStateDescription } = OverviewCardConsts[
    OverviewCardTypes.OrderRevenue
  ]
  return (
    <OverviewEmptyStateContainer>
      <OverviewEmptyStateDescription>
        Choose <b>+ Add</b> to {emptyStateDescription}
      </OverviewEmptyStateDescription>
      {REVENUE_CONNECTORS.map(c => (
        <Connector
          key={c}
          providerName={c}
          category={CONNECTORS_CATEGORIES.asset}
          redirectUrl="/overview"
          isSmall={true}
        />
      ))}
    </OverviewEmptyStateContainer>
  )
}
