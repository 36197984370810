import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { SquareBullet } from './SquareBullet'

export const LegendHeader = ({
  color,
  title,
}: {
  color: string
  title: string
}): JSX.Element => {
  return (
    <Grid
      item={true}
      container={true}
      xs={12}
      alignItems="top"
      sx={{ pb: 2, marginTop: '4px' }}
      style={{ flexWrap: 'nowrap', minHeight: '52px' }}
    >
      <Grid item={true}>
        <SquareBullet color={color} />
      </Grid>
      <Grid item={true} sx={{ pl: 1 }}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
    </Grid>
  )
}
