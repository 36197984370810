import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

const Modal = styled('div')`
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalContent = styled('div')`
  margin: 15% auto;
  padding: 32px;
  border: 1px solid #888;
  width: 350px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Title = styled('div')`
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 20px 0px;
`

const Text = styled('div')`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 14px 0px;
`

interface BasicModalProps {
  contentWidth?: string
  children: React.ReactNode
}

interface SpeedBumpModalProps extends BasicModalProps {
  title: string
  text: string
  secondaryContent?: React.ReactNode
  handleClose?: () => void
}

export const SpeedBumpModal: React.FunctionComponent<SpeedBumpModalProps> = ({
  title,
  text,
  children,
  secondaryContent,
  handleClose,
}) => {
  return (
    <Modal>
      <ModalContent>
        {children}
        <Title>{title}</Title>
        <Text>{text}</Text>
        {secondaryContent}
        {handleClose && (
          <IconButton
            sx={{
              right: 0,
              top: 0,
              zIndex: 999,
              position: 'absolute' as 'absolute',
            }}
            color="default"
            aria-label="close"
            component="label"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </ModalContent>
    </Modal>
  )
}

export const BasicModal: React.FunctionComponent<BasicModalProps> = ({
  contentWidth = '350px',
  children,
}) => {
  return (
    <Modal>
      <ModalContent style={{ width: contentWidth }}>{children}</ModalContent>
    </Modal>
  )
}
