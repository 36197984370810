import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getCreditScoreRequest = createAsyncThunk(
  'app/credit/score-request/get',
  async () => api.get('/api/credit/score-request'),
)

export const createCreditScoreRequest = createAsyncThunk(
  'app/credit/score-request/create',
  async () => api.post('api/credit/score-request/create', {}),
)
