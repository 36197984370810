import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { catchThunk } from '../error'
import { createThunkReducer } from '../thunkReducerBuilder'
import {
  ListGoogleAdsCustomersResponse,
  PutGoogleAdsCustomerArgs,
} from '../../../server/routes/api/welcome/google-customers'
import { api } from '../../lib/api'
import {
  ListFacebookAdsAccountsResponse,
  PutFacebookAdsAccountArgs,
} from '../../../server/routes/api/welcome/facebook-ads-accounts'

// REDUCERS
type WelcomeStoreData = {
  googleAdsCustomers: ListGoogleAdsCustomersResponse & {
    isLoading: boolean
    isPutting: boolean
    didPut: boolean
    error: Error | null
  }
  facebookAdsAccounts: ListFacebookAdsAccountsResponse & {
    isLoading: boolean
    isPutting: boolean
    didPut: boolean
    error: Error | null
  }
}

export type StoreWelcome = Readonly<WelcomeStoreData>

const googleAdsKey = 'googleAdsCustomers'
const facebookAdsKey = 'facebookAdsAccounts'

const initialState: StoreWelcome = {
  [googleAdsKey]: {
    customers: [],
    isLoading: false,
    isPutting: false,
    didPut: false,
    error: null,
  },
  [facebookAdsKey]: {
    accounts: [],
    isLoading: false,
    isPutting: false,
    didPut: false,
    error: null,
  },
}

export const getFacebookAdsAccounts = createAsyncThunk(
  'welcome/facebookAdsAccounts/get',
  async (_, thunkApi) =>
    api.get('/api/welcome/facebook-ads-accounts').catch(catchThunk(thunkApi)),
)

export const putFacebookAdsAccount = createAsyncThunk(
  'welcome/facebookAdsAccounts/put',
  async (selected: PutFacebookAdsAccountArgs) =>
    api.put('/api/welcome/facebook-ads-accounts', selected),
)

export const getGoogleAdsCustomers = createAsyncThunk(
  'welcome/googleAdsCustomers/get',
  async (adSpendDays: number, thunkApi) =>
    api
      .get(`/api/welcome/google-customers?days=${adSpendDays}`)
      .catch(catchThunk(thunkApi)),
)

export const putGoogleAdsCustomer = createAsyncThunk(
  'welcome/googleAdsCustomers/put',
  async (selected: PutGoogleAdsCustomerArgs) =>
    api.put('/api/welcome/google-customers', selected),
)

export const getAccountSpends = createAsyncThunk(
  'welcome/googleAdsCustomers/spend/get',
  async () => api.get('/api/welcome/google-customers/spend'),
)

export const welcomeReducer = createReducer(initialState, builder => {
  // GET Facebook AdAccounts
  createThunkReducer(
    builder,
    getFacebookAdsAccounts.typePrefix,
    facebookAdsKey,
    (state, action) => ({
      ...state,
      [facebookAdsKey]: {
        ...state[facebookAdsKey],
        isLoading: false,
        error: null,
        accounts: action.payload.accounts,
      },
    }),
  )

  // PUT Facebook AdAccount
  createThunkReducer(
    builder,
    putFacebookAdsAccount.typePrefix,
    facebookAdsKey,
    state => ({
      ...state,
      [facebookAdsKey]: {
        ...state[facebookAdsKey],
        isPutting: false,
        didPut: true,
        error: null,
      } as any,
    }),
    'isPutting',
  )

  // GET Google Ads Customers
  createThunkReducer(
    builder,
    getGoogleAdsCustomers.typePrefix,
    googleAdsKey,
    (state, action) => ({
      ...state,
      [googleAdsKey]: {
        ...state[googleAdsKey],
        isLoading: false,
        error: null,
        customers: action.payload.customers,
      },
    }),
  )

  // PUT Google Ad Customer
  createThunkReducer(
    builder,
    putGoogleAdsCustomer.typePrefix,
    googleAdsKey,
    state => ({
      ...state,
      [googleAdsKey]: {
        ...state[googleAdsKey],
        isPutting: false,
        didPut: true,
        error: null,
      } as any,
    }),
    'isPutting',
  )
})
