import React from 'react'
import Box from '@mui/material/Box'
import { Brighflow } from '../../assets/Brightflow/Brightflow'
import { BrightflowVariant } from '../../assets/Brightflow/BrightflowVariant'

type Props = {
  height?: number
  width?: number
  useVariant?: boolean
}

export const BFLogo: React.FunctionComponent<Props> = ({
  height = 40,
  width = 135,
  useVariant,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: `${height}px`,
        textAlign: 'left',
      }}
    >
      {useVariant ? (
        <BrightflowVariant width={width} height={height} />
      ) : (
        <Brighflow width={width} height={height} />
      )}
    </Box>
  )
}
