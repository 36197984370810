import React from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'

import { OverviewChartCard } from '../../../../../components/overview/NewOverviewChartCard/NewOverviewChartCard'

import { OverviewCustomerValueProps } from './OverviewCustomerValueCard.types'
import { useOverviewCustomerValueCard } from './OverviewCustomerValueCard.hooks'
import { ColumnEmphasisTable } from '../../../../../components/designSystem/molecules/ColumnEmphasisTable/ColumnEmphasisTable'
import { DataPresentationCard } from '../../../../../components/designSystem/molecules/DataPresentationCard/DataPresentationCard'
import { LoadingCircular } from '../../../../../components/designSystem/atoms/Loading/Loading'
import { OverviewCardConsts } from '../../Overview.consts'
import { OverviewCardTypes } from '../../Overview.types'
import { InfoTooltip } from '../../../../../components/designSystem/molecules/InfoTooltip/InfoTooltip'

const ROW_HEADINGS = [
  'Lifetime Value (LTV)',
  'Acquisition Cost (CAC)',
  'Avg Customer Margin',
  'LTV / CAC',
]

export const OverviewCustomerValueCard = (
  Props: OverviewCustomerValueProps,
) => {
  const { columns, isLoading, shouldRender } = useOverviewCustomerValueCard(
    Props,
  )

  const { title, description } = OverviewCardConsts[
    OverviewCardTypes.CustomerValue
  ]

  if (!shouldRender) return null

  return (
    <Grid item xs={12} lg={6}>
      <DataPresentationCard
        title={title}
        widgets={<InfoTooltip description={description} />}
        sx={{ minHeight: '100%' }}
      >
        <Box sx={{ padding: '10px' }}>
          {isLoading ? (
            <Box sx={{ height: '350px' }}>
              <LoadingCircular />
            </Box>
          ) : (
            <ColumnEmphasisTable columns={columns} rowHeadings={ROW_HEADINGS} />
          )}
        </Box>
      </DataPresentationCard>
    </Grid>
  )
}
