import {
  ActionReducerMapBuilder,
  AnyAction,
  CaseReducer,
} from '@reduxjs/toolkit'

// not exported from reduxjs/toolkit
interface ActionMatcher<AnyAction> {
  (action: AnyAction): action is AnyAction
}

// TODO(connor): figure out how to use this/document how to use this
export function createThunkReducer<TStore>(
  builder: ActionReducerMapBuilder<TStore>,
  typePrefix: string,
  key: string,
  fulfilledCallback: CaseReducer<TStore, AnyAction>,
): Omit<ActionReducerMapBuilder<TStore>, 'addCase'>
export function createThunkReducer<TStore>(
  builder: ActionReducerMapBuilder<TStore>,
  typePrefix: string,
  key: string,
  fulfilledCallback: CaseReducer<TStore, AnyAction>,
  loadingKey: string,
): Omit<ActionReducerMapBuilder<TStore>, 'addCase'>
export function createThunkReducer<TStore>(
  builder: ActionReducerMapBuilder<TStore>,
  typePrefix: string,
  key: string,
  fulfilledCallback: CaseReducer<TStore, AnyAction>,
  loadingKey?: never,
  rejectedCallback?: CaseReducer<TStore, AnyAction>,
  pendingCallback?: CaseReducer<TStore, AnyAction>,
): Omit<ActionReducerMapBuilder<TStore>, 'addCase'>
export function createThunkReducer<TStore>(
  builder: ActionReducerMapBuilder<TStore>,
  typePrefix: string,
  key: string,
  fulfilledCallback: CaseReducer<TStore, AnyAction>,
  loadingKey = 'isLoading',
  rejectedCallback?: CaseReducer<TStore, AnyAction>,
  pendingCallback?: CaseReducer<TStore, AnyAction>,
): Omit<ActionReducerMapBuilder<TStore>, 'addCase'> {
  return builder
    .addMatcher(
      (action =>
        action.type === `${typePrefix}/pending` &&
        action.meta.requestId) as ActionMatcher<any>,
      pendingCallback ??
        ((state, action): TStore =>
          ({
            ...state,
            [key]: {
              ...(state ?? ({} as any))[key],
              [loadingKey]: true,
            },
          } as TStore)),
    )
    .addMatcher(
      (action =>
        action.type === `${typePrefix}/rejected` &&
        action.meta.requestId) as ActionMatcher<any>,
      rejectedCallback ??
        ((state, action): TStore =>
          ({
            ...state,
            [key]: {
              ...(state ?? ({} as any))[key],
              [loadingKey]: false,
              error: action || new Error('Unknown - thunkReducerBuilder'),
            },
          } as TStore)),
    )
    .addMatcher(
      (action =>
        action.type === `${typePrefix}/fulfilled` &&
        action.meta.requestId) as ActionMatcher<any>,
      fulfilledCallback,
    )
}
