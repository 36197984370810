import React, { useCallback, useState } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import { Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { dispatch } from '../../../store'
import { Color } from '../../../Color'
import {
  CustomerSegmentProperties,
  ProductSegmentData,
} from '../../../../server/services/productSegmentDashboard/ProductSegmentService'
import { BasicModal } from '../../../components/designSystem/organisms/BasicModal/BasicModal'
import {
  deleteProductSegmentMapping,
  upsertProductSegmentMappings,
} from '../../../modules/adminDashboard/productDemand/productSegmentActions'

import { ProductAggregateInfoWithCustomerSegment } from '../../../../server/data/models/sync/ProductAggregate'

type Props = {
  row: ProductAggregateInfoWithCustomerSegment
  availableProductSegments: ProductSegmentData[]
  companyId: string
}

const SegmentCard = ({
  segment,
  product,
}: {
  segment: CustomerSegmentProperties
  product: {
    productId: string
    name: string
    oneYearCumulativeRevenue: number
    unitVolume: number
  }
}): JSX.Element => {
  const { segmentName, quantity = 0, id, companyId } = segment

  const [segmentQuantity, setQuantity] = useState<number | null>(quantity)
  const height = '32px'
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 500,
        width: 'fit-content',
        display: 'flex',
        height: '48px',
        margin: '8px',
      }}
    >
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', padding: '4px' }}
      >
        <Typography variant="subtitle2">{segmentName}</Typography>
        <FormControl
          required
          sx={{
            m: 1,
            width: 50,
            height,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            value={segmentQuantity}
            multiline
            rows={1}
            error={!!segmentQuantity && segmentQuantity < 1}
            helperText={
              !!segmentQuantity && segmentQuantity < 1
                ? 'Quantity must be at least 1'
                : null
            }
            size="small"
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ): void => {
              const valueString = event?.target?.value
              if (typeof valueString !== 'string') {
                return
              }
              const numericValue = Number(valueString.replace(/,/g, ''))
              if (isNaN(numericValue)) {
                return
              }
              setQuantity(numericValue)
            }}
            inputProps={{ min: 1, inputMode: 'numeric' }}
            onBlur={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ): void => {
              const updateVals = [
                {
                  productId: product.productId,
                  customerSegmentId: id,
                  quantity: segmentQuantity,
                },
              ]

              dispatch(
                upsertProductSegmentMappings({
                  mappings: updateVals,
                  companyId,
                }),
              )
            }}
          />
        </FormControl>
      </CardContent>
      <CardActions
        sx={{
          borderLeft: `1px solid ${Color.LighterGrey}`,
        }}
      >
        <div>
          <IconButton
            aria-label="Delete product segment"
            onClick={(): void => {
              dispatch(
                deleteProductSegmentMapping({
                  productId: product.productId,
                  customerSegmentId: id,
                }),
              )
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <DeleteForeverIcon
              sx={{
                height: '14px',
                width: '14px',
                color: Color.ErrorRed,
                '&:hover': {
                  color: Color.VioletEggplant,
                },
              }}
            />
          </IconButton>
        </div>
      </CardActions>
    </Card>
  )
}

export const ProductSegmentDetail = (props: Props): JSX.Element | null => {
  const {
    row: { customerSegments, ...rest },
    availableProductSegments,
    companyId,
  } = props

  const product = rest

  const [addNewClicked, setAddNewClicked] = useState<boolean>(false)

  const [selectedSegment, setSelectedSegment] = useState('')

  const [quantity, setQuantity] = useState<number>(1)

  const onSave = useCallback(() => {
    const selectedSegmentId = availableProductSegments.find(
      ({ segmentName }) => segmentName === selectedSegment,
    )?.id

    if (selectedSegmentId) {
      const updateVals = [
        {
          productId: product.productId,
          customerSegmentId: selectedSegmentId,
          quantity: quantity,
        },
      ]

      dispatch(
        upsertProductSegmentMappings({ mappings: updateVals, companyId }),
      )

      setSelectedSegment('')
      setQuantity(1)
      setAddNewClicked(false)
    }
  }, [
    availableProductSegments,
    selectedSegment,
    product.productId,
    quantity,
    companyId,
  ])

  return (
    <div
      style={{ margin: '8px', borderBottom: `1px solid ${Color.LightGrey}` }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {customerSegments.map(seg => (
          <SegmentCard key={seg.id} segment={seg} product={product} />
        ))}
      </div>

      <div
        style={{
          margin: '4px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={(): void => {
            setAddNewClicked(true)
          }}
          variant="contained"
        >
          Add Segment
        </Button>
      </div>

      <BasicModal
        height={200}
        width={400}
        isOpen={addNewClicked}
        handleClose={() => setAddNewClicked(false)}
        onDismiss={() => setAddNewClicked(false)}
        title={`Add a Segment to ${product.name}`}
        confirmButtonText={'Add Segment'}
        cancelButtonText={'Cancel'}
        onConfirmButtonClicked={onSave}
        onCancelButtonClicked={onSave}
        buttonJustifyContent={'flex-end'}
        renderContent={(): JSX.Element => (
          <div style={{ display: 'flex' }}>
            <FormControl required sx={{ m: 1, width: 200 }}>
              <p>Product Segment</p>
              <Select
                labelId="main category"
                value={selectedSegment}
                onChange={(event: SelectChangeEvent): void => {
                  const seg = event.target.value
                  setSelectedSegment(seg)
                }}
              >
                {availableProductSegments.map((segment, i) => (
                  <MenuItem key={i} value={segment.segmentName}>
                    {segment.segmentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required sx={{ m: 1, width: 200 }}>
              <p>Quantity</p>
              <TextField
                value={quantity}
                error={quantity < 1}
                helperText={quantity < 1 ? 'Quantity must be at least 1' : null}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >,
                ): void => {
                  const valueString = event?.target?.value
                  if (typeof valueString !== 'string') {
                    return
                  }
                  const numericValue = Number(valueString.replace(/,/g, ''))
                  if (isNaN(numericValue)) {
                    return
                  }
                  setQuantity(numericValue)
                }}
                inputProps={{ min: 1, inputMode: 'numeric' }}
              />
            </FormControl>
          </div>
        )}
        confirmButtonColor="warning"
      />
    </div>
  )
}
