import React from 'react'

import { LegendItem, LegendItemData } from './LegendItem'
import { LegendItemHeader } from './LegendItemHeader'
import { LegendBody, LegendBottom } from './LegendBottomContainer.styles'

export const LegendBottomContainer = ({
  legendItems,
  titles,
}: {
  legendItems: LegendItemData[]
  titles: [string, string]
}): JSX.Element => {
  return (
    <LegendBottom>
      <LegendItemHeader titles={titles} />
      <LegendBody>
        {legendItems.map(({ name, color, value, trendLabelData }) => (
          <LegendItem
            key={`legend-item-${name}`}
            name={name}
            color={color}
            value={value}
            trendLabelData={trendLabelData}
          />
        ))}
      </LegendBody>
    </LegendBottom>
  )
}
