import { AdminDashboardCompanyFeature } from '../../../server/services/adminDashboard'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import { TableSectionHeader } from './DashboardGridPanelDetail'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'

interface Props {
  renderButton: () => JSX.Element
  deleteCompanyFeature: (feature: AdminDashboardCompanyFeature) => void
  adminDashboardCompanyFeatures: AdminDashboardCompanyFeature[]
}
export const AdminFeatureDetail = (props: Props): JSX.Element => {
  const {
    deleteCompanyFeature,
    adminDashboardCompanyFeatures,
    renderButton,
  } = props
  return (
    <>
      <TableSectionHeader title={'Feature Flags'} renderButton={renderButton} />
      <TableContainer component={Paper} sx={{ width: '650px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Delete</TableCell>
              <TableCell component="th">Label</TableCell>
              <TableCell component="th">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminDashboardCompanyFeatures.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th">
                  <IconButton onClick={(): void => deleteCompanyFeature(row)}>
                    <DeleteForeverIcon color={'error'} />
                  </IconButton>
                </TableCell>
                <TableCell scope="row">{row.label}</TableCell>
                <TableCell scope="row">{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
