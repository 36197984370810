export enum NumericCriteriaOperators {
  EQUAL = 'EQUAL',
  NOTEQUAL = 'NOTEQUAL',
  LESS = 'LESS',
  LESSOREQUAL = 'LESSOREQUAL',
  GREATER = 'GREATER',
  GREATEROREQUAL = 'GREATEROREQUAL',
}

export type NumericCriteriaInputProps = {
  onChange: (operator: NumericCriteriaOperators, value: string) => void
  defaultOperator?: NumericCriteriaOperators
  defaultValue?: string
  max?: number
  min?: number
  placeholder?: string
}
