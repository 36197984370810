import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const TooltipRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  gap: '10px',
})
