import React from 'react'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import Table from '@mui/material/Table'
import { Color } from '../../../../Color'
import { tableCellClasses } from '@mui/material/TableCell'
import { Toggle } from '../../../../components/designSystem/molecules/Toggle/Toggle'

export const SubHeading = styled('div', {
  shouldForwardProp: (prop: any): boolean => prop !== 'marginTop',
})<{ marginTop?: number }>(
  ({ marginTop }) => `
  display: flex !important;
  align-items: center;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: ${marginTop ? `${marginTop}px !important` : '0px !important'};
`,
)

export const CalloutText = styled('p')`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0px;
`

export const StyledToggleButton = styled(ToggleButton)({
  height: '40px',
  width: '150px',
  fontSize: '14px',
  fontWeight: 400,
  color: Color.Black,
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: Color.VioletEggplant,
  },
})

type Selection = {
  value: 'cumulative' | 'monthly'
  text: string
}

const defaultSelections: Selection[] = [
  { value: 'monthly', text: 'Monthly' },
  { value: 'cumulative', text: 'Cumulative' },
]

interface ToggleProps {
  value: string
  setValue: (value: Selection['value']) => void
  selections?: Selection[]
}

const TypedToggle = Toggle<'monthly' | 'cumulative'>()

export const CumulativeOrMonthlyToggle: React.FunctionComponent<ToggleProps> = ({
  value,
  setValue,
  selections = defaultSelections,
}) => {
  return (
    <TypedToggle
      key="Current or Next Period"
      value={value}
      setValue={setValue}
      selections={selections}
    />
  )
}

export const ForecastTableComponent = styled(Table)`
  .${tableCellClasses.root} {
    font-size: 14px;

    @media screen and (min-width: 1400px) {
      font-size: 16px;
    }
  }
`
