import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { createThunkReducer } from '../../thunkReducerBuilder'
import { api } from '../../../lib/api'

import { DatePrecision } from '../../../../server/data/models/DatePrecision'
import {
  AccountCategoryBreakdown,
  DepositAggregateSeries,
  InflowCategory,
} from '../../../../server/data/models/sync'
import { GetRevenueResults } from '../../../../server/routes/api/reports/index'

export type GetRevenue = {
  start: Date | number | string
  end: Date | number | string
  precision: DatePrecision
}

export const getRevenueResults = createAsyncThunk(
  'reports/revenue/get',
  async ({ start, end, precision }: GetRevenue) =>
    api.get(
      `/api/reports/revenue?start=${start}&end=${end}&precision=${precision}`,
    ),
)

export type RevenueStore = Readonly<{
  revenueResults: GetRevenueResults
}>

const initialState: RevenueStore = {
  revenueResults: {
    hasLoaded: false,
    timestamps: [],
    precision: DatePrecision.Month,
    categories: {} as AccountCategoryBreakdown<
      InflowCategory,
      Omit<DepositAggregateSeries, 'timestamps'>
    >,
  },
}

export const revenueReducer = createReducer(initialState, builder => {
  createThunkReducer(
    builder,
    getRevenueResults.typePrefix,
    'revenueResults',
    (state, action) => ({
      ...state,
      revenueResults: action.payload,
      hasLoaded: true,
    }),
  )
})
