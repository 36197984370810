import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { Layout } from '../../../layout'
import { Store } from '../../../modules/rootReducer'

import { dispatch } from '../../../store'
import {
  getConnectors,
  selectIsLoaded,
  selectRevenueConnectors,
} from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'
import { CompanyFeaturesPayload } from '../../../modules/companyFeatures'
import { useInterval } from '../../../hooks/useInterval'

import { DataSyncing, NoConnections, UpgradePlanScreen } from '../shared'
import { WomanCoinIcon } from '../../../components/designSystem/assets/WomanCoinIcon/WomanCoinIcon'

import { LTVReport } from './LTVReport'
import { RevenueLoading } from '../shared/RevenueLoading'
import { hasLifetimeCustomerValueFeature } from '../../../util/companyFeatures'

type FCProps = {
  connectorsLoaded: boolean
  revenueConnections: Connection[]
  loadingRevenueConnections: Connection[]
  companyFeatures: CompanyFeaturesPayload
}

const LtvImpl: React.FunctionComponent<FCProps> = ({
  connectorsLoaded,
  revenueConnections,
  loadingRevenueConnections,
  companyFeatures,
}) => {
  // NOTE(connor): basically every report screen independently polls
  // for connections, that's a bit of a TODO
  useEffect(() => {
    dispatch(getConnectors())
  }, [])
  useInterval(() => {
    dispatch(getConnectors())
  }, 5000)

  return (
    <Layout>
      {hasLifetimeCustomerValueFeature(companyFeatures) ? (
        (() => {
          if (!connectorsLoaded) {
            return (
              <RevenueLoading connections={loadingRevenueConnections}>
                {null}
              </RevenueLoading>
            )
          } else if (revenueConnections.length === 0) {
            return (
              <NoConnections
                title={'Start Tracking Your Customer Lifetime Value'}
                subtitle={'To start tracking, connect a revenue source.'}
              />
            )
          } else if (
            revenueConnections.length === loadingRevenueConnections.length
          ) {
            return (
              <RevenueLoading connections={loadingRevenueConnections}>
                <DataSyncing />
              </RevenueLoading>
            )
          } else {
            return <LTVReport />
          }
        })()
      ) : (
        <UpgradePlanScreen
          title="Start Tracking Your Customer Lifetime Value"
          subtitle="Analyze cumulative spend of customer cohorts and determine profitability on newly acquired customers."
          renderIcon={(): React.ReactNode => (
            <WomanCoinIcon height={170} width={167} />
          )}
        />
      )}
    </Layout>
  )
}

const mapStateToProps = (store: Store): FCProps => {
  return {
    connectorsLoaded: selectIsLoaded(store.connectors),
    revenueConnections: selectRevenueConnectors(store.connectors),
    loadingRevenueConnections: selectRevenueConnectors(store.connectors).filter(
      c => c.percentOfYearComplete !== 100 && c.initialSyncStatus === 'pending',
    ),
    companyFeatures: store.companyFeatures.companyFeatures,
  }
}

export const LtvScreen = connect(mapStateToProps)(React.memo(LtvImpl))
