import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { TrendLabelData, TrendTarget } from './types'
import { Color } from '../../../../Color'
import { TrendLabelContainer } from './TrendLabel.styles'
import { hasDesiredTrend } from './TrendLabel.utils'

const MAX_PERCENTAGE = 999

export const TrendLabel = ({
  target = TrendTarget.positive,
  trendPercent,
}: TrendLabelData): JSX.Element => {
  const trendColor = hasDesiredTrend(trendPercent, target)
    ? Color.Black
    : Color.ErrorRed

  return (
    <TrendLabelContainer>
      {trendPercent === null || trendPercent > MAX_PERCENTAGE ? (
        <Typography
          variant="body2"
          sx={{ textAlign: 'center' }}
          color={Color.LightGrey}
        >
          -
        </Typography>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          flexWrap="nowrap"
          alignItems="center"
          spacing={1}
          sx={{ p: '2px' }}
        >
          <Grid item>
            <ArrowIndicator trendPercent={trendPercent} target={target} />
          </Grid>
          <Grid item>
            <Typography variant="body2" color={trendColor} fontWeight={700}>
              {`${Math.abs(trendPercent)}%`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </TrendLabelContainer>
  )
}

const ArrowIndicator = ({
  trendPercent,
  target,
}: {
  trendPercent: number
  target: TrendTarget
}): JSX.Element => {
  const isTrendPositive = hasDesiredTrend(trendPercent, target)
  return (
    <Typography variant={'body1'}>
      {trendPercent >= 0 ? (
        <ArrowUpwardIcon
          fontSize="inherit"
          color={isTrendPositive ? 'inherit' : 'error'}
        />
      ) : (
        <ArrowDownwardIcon
          fontSize="inherit"
          color={isTrendPositive ? 'inherit' : 'error'}
        />
      )}
    </Typography>
  )
}
