import React from 'react'

interface Props {
  height: number
  width: number
}

export const Caution: React.FunctionComponent<Props> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 114 98" // need to be static on an aspect ratio in order to re-size as needed with height/width
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>CDA6A00A-38A3-42CF-A5B1-5CDFF8D59E3A</title>
      <g
        id="Google-ad-flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Connect-was-not-completed"
          transform="translate(-651.000000, -236.000000)"
          fill="#BF0711"
          fillRule="nonzero"
        >
          <path
            d="M707.52093,256.511628 L746.265116,323.516279 L668.776744,323.516279 L707.52093,256.511628 M707.748837,236 L651,334 L764.497674,334 L707.748837,236 Z M712.990698,308.018605 L702.506977,308.018605 L702.506977,318.502326 L712.990698,318.502326 L712.990698,308.018605 Z M712.990698,277.47907 L702.506977,277.47907 L702.506977,297.990698 L712.990698,297.990698 L712.990698,277.47907 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
