import { useEffect, useState } from 'react'

/**
 * Used to detect user's timezone from browser
 */
export const useTimezone = (): string => {
  const [timezone, setTimezone] = useState<string>('America/Los_Angeles')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimezone(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  })
  return timezone
}
