import React from 'react'
import { SignupForm } from '../../components/forms'
import { Link } from '../../components/forms/StyledElements'
import { SignContent } from '../../components/users/Sign'

export const SignupScreen = (): JSX.Element => {
  return (
    <SignContent
      title={'Create an account'}
      Form={<SignupForm />}
      InstructionsContent={
        <>
          <span>Already have an account?</span>
          <Link to="/users/login">Log in</Link>
        </>
      }
    />
  )
}
