import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useRutterLink } from 'react-rutter-link'

import { Store } from '../../../../modules/rootReducer'
import { ProviderModalProps } from '../Providers'
import { dispatch } from '../../../../store'
import { authRutterAPI } from '../../../../modules/rutterApi/rutterApi'
import { LoadingCircular } from '../../../../components/designSystem/atoms/Loading/Loading'
import { BasicModal } from '../../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { KNOWN_CONNECTOR_KEYS } from '../../../../../server/connectors/known-connectors'
import {
  SupportedConnectorKey,
  setAuthIsPending,
} from '../../../../modules/rutterApi'

const RUTTER_PUBLIC_KEY = '20f53776-8be7-4748-90da-19128e0bce89'

/**
 * Gets rutter platform key from connection key.
 */
const getRutterPlatformKey = (connectionKey: string): string => {
  switch (connectionKey) {
    case 'netSuite':
      return 'NETSUITE'
    case 'xero':
      return 'XERO'
    default:
      throw Error(
        `Unknown rutter platform key for connection key: ${connectionKey}`,
      )
  }
}

/**
 * Checks if a function is a noop. Implementation
 * is fairly rutter specific as it relies on function
 * name to include noop.
 */
const isNoop = (fn: Function): boolean => {
  return fn.toString().includes('noop')
}

/**
 * Creates rutter base model. Used for netSuite, xero.
 */
export const createRutterBaseModal = (
  connectionKey: string,
): React.FunctionComponent<ProviderModalProps> => {
  // Create base modal implementation
  const RutterBaseModalImpl: React.FunctionComponent<ProviderModalProps> = props => {
    const { isOpen, onClose, authIsPending } = props
    const [rutterLinkIsOpen, setRutterLinkIsOpen] = useState(true)

    // Initializes rutter link
    const { open, exit } = useRutterLink({
      publicKey: RUTTER_PUBLIC_KEY,
      onSuccess: (publicToken: string): void => {
        dispatch(setAuthIsPending({ isPending: true, connectionKey }))
        dispatch(authRutterAPI({ publicToken, connectionKey }))
      },
      onExit: () => setRutterLinkIsOpen(false),
    })

    // Rutter link manager, note: open() is noop while rutter initializing
    useEffect(() => {
      if (isOpen && !isNoop(open)) {
        open({ platform: getRutterPlatformKey(connectionKey) })
        return (): void => {
          exit()
        }
      }
    }, [isOpen, open])

    // On close manager
    useEffect(() => {
      if (!authIsPending && !rutterLinkIsOpen) {
        onClose()
      }
    }, [authIsPending, rutterLinkIsOpen])

    // Conditional loading screen
    if (authIsPending) {
      return (
        <BasicModal>
          <LoadingCircular />
        </BasicModal>
      )
    }

    return null
  }

  // Connect base modal implementation to state
  const mapStateToProps = ({
    rutterReducer,
  }: Store): {
    authIsPending: boolean
  } => {
    return {
      authIsPending:
        rutterReducer.rutter[connectionKey as SupportedConnectorKey]
          .authIsPending,
    }
  }
  const RutterBaseModal = connect(mapStateToProps)(
    React.memo(RutterBaseModalImpl),
  )

  return RutterBaseModal
}

// Instantiate rutter modals
export const NetSuiteModal = createRutterBaseModal(
  KNOWN_CONNECTOR_KEYS.netSuite,
)
export const XeroModal = createRutterBaseModal(KNOWN_CONNECTOR_KEYS.xero)
