import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DescriptionIcon from '@mui/icons-material/Description'

import { Color } from '../../../../../../Color'
import { SelectFilter } from '../../../../../../components/designSystem/organisms/SelectFilter/SelectFilter'
import { TimePeriodFilterOptions } from '../../SalesForecast.consts'
import { useSalesForecast } from '../../SalesForecastProvider'

export const SalesForecastPageActions = (): JSX.Element => {
  const {
    duration,
    granularity,
    handleTimePeriodChange,
    handleExportData,
  } = useSalesForecast()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0 30px 24px',
      }}
    >
      <SelectFilter
        filterOptions={TimePeriodFilterOptions.map(
          ({ granularity, duration, label }) => ({
            label: label,
            value: `${duration}-${granularity}`,
          }),
        )}
        title={'Time Period'}
        defaultValue={`${duration}-${granularity}`}
        handleChange={(value: string | number): void =>
          handleTimePeriodChange(String(value))
        }
      />
      <Button
        variant="contained"
        startIcon={<DescriptionIcon />}
        onClick={handleExportData}
        sx={{ width: '100px', height: '48px', backgroundColor: Color.Green }}
      >
        Export
      </Button>
    </Box>
  )
}
