import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import * as yup from 'yup'
import { EmailSent } from './EmailSent'
import { SpeedBumpModal } from '../../designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { forgotPasswordLink } from '../../../modules/user/user'
import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'

const StackedMargin = styled(Stack)`
  margin: 40px 0;
  width: 100%;
`

const StyledForm = styled('form')`
  width: 100%;
`

interface Props {
  forgotPassword?: any
}

interface OwnProps {
  handleClose: () => void
}

const Form = ({
  submit,
  error,
}: {
  submit: (email: string) => void
  error: any
}): JSX.Element => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Enter an email.')
      .email('Enter a valid email address format'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submit(values.email)
    },
  })
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <TextField
        name="email"
        label="Email address"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
        style={{ width: '100%' }}
      />
      {error && <Alert severity="error">{error.payload}</Alert>}
      <StackedMargin spacing={3}>
        <Button
          disabled={!(formik.isValid && formik.dirty)}
          type="submit"
          variant="contained"
          color="success"
          size={'large'}
          style={{ height: '45px', width: '100%' }}
        >
          Send link
        </Button>
      </StackedMargin>
    </StyledForm>
  )
}

export const ForgotPassword: React.FC<Props & OwnProps> = ({
  forgotPassword,
  handleClose,
}): JSX.Element => {
  const { error, emailSent } = forgotPassword
  const [email, setEmail] = useState('')
  const submit = (email: string): void => {
    dispatch(forgotPasswordLink(email))
    setEmail(email)
  }
  return !emailSent ? (
    <div>
      <SpeedBumpModal
        title={'Forgot password'}
        text={`We’ll send a password reset link to the email associated with your account.`}
        secondaryContent={<Form submit={submit} error={error} />}
        handleClose={handleClose}
      >
        <></>
      </SpeedBumpModal>
    </div>
  ) : (
    <EmailSent email={email} handleClose={handleClose} />
  )
}

const mapStateToProps = ({
  forgotPassword: { forgotPassword },
}: Store & OwnProps): Props => ({
  forgotPassword,
})

export const ForgotPasswordForm = connect(mapStateToProps)(ForgotPassword)
