import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { format, utcToZonedTime } from 'date-fns-tz'
import ToolTip from '@mui/material/Tooltip'
import { Headline } from '.'
import { Color } from '../Color'

// skip any prop named active,
//  strictly this `prop` is based on the type being styled
//  but doesn't matter for our purposes here
const shouldForwardProp = (prop: any): boolean => (prop as string) !== 'active'

const ViewButton = styled(Link, {
  shouldForwardProp,
})<{ active: boolean }>(
  ({ active }) => `
  margin: 0.875rem;
  text-decoration: none;
  cursor: pointer;
  color: #66b8d9;

  ${
    active
      ? `
      border-bottom: 1px solid #08678c;
      color: #08678c;
    `
      : ''
  }
`,
)

const DisabledButton = styled('p')`
  margin: 0.875rem;
  text-decoration: none;
  cursor: not-allowed;
  color: #68b9d9;
  opacity: 0.69;
`

const ActionButton = styled('button', {
  shouldForwardProp,
})<{ active: boolean }>(
  ({ active }) => `
  /*Reset's every elements appearance*/
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  font-size: 18px;
  list-style: none outside none;
  padding: 0;
  text-align: center;
  text-indent: 0;
  margin: 0.875rem;
  text-decoration: none;
  cursor: pointer;
  color: #66b8d9;

  ${
    active
      ? `
      border-bottom: 1px solid ${Color.DarkBlue};
      color: #08678c;
    `
      : ''
  }
`,
)

const ToolWrap = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonWrap = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 1em 0 0 0.5em;
  align-items: center;
`

const ChannelWrap = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 0 0;
  margin: 0;
  align-items: center;
`

const HeadlineWrap = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Toolbar: React.FunctionComponent<{
  interval: string
  type: string
  channels?: { [key: string]: any }
  setActive?: React.Dispatch<React.SetStateAction<string>>
  setChannel?: React.Dispatch<React.SetStateAction<string>>
  active?: string
  time?: string
  disableMonthly?: boolean
}> = ({
  interval,
  type,
  channels,
  setActive,
  setChannel,
  active,
  time,
  disableMonthly,
}): ReactElement => {
  // offsets the time to be UTC
  const offsetGmtToUtc = utcToZonedTime(time || new Date(), 'UTC')

  return (
    <ToolWrap>
      <HeadlineWrap>
        {time ? (
          <div>
            {interval === 'day' ? (
              <Headline
                title={`${format(offsetGmtToUtc, 'LLLL dd yyyy', {
                  timeZone: 'UTC',
                })}`}
              />
            ) : (
              <Headline
                title={`${format(offsetGmtToUtc, 'LLLL yyyy', {
                  timeZone: 'UTC',
                })} - Present (Month to Date)`}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </HeadlineWrap>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ChannelWrap>
          {channels && setActive && setChannel ? (
            <div>
              {Object.entries(channels).map(([label]) => (
                <ActionButton
                  onClick={(): void => {
                    setActive(label)
                    setChannel(label)
                  }}
                  active={active === label}
                  key={label}
                >
                  {label}
                </ActionButton>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </ChannelWrap>

        {type !== 'ltv' && (
          <ButtonWrap>
            <ViewButton to={`/${type}/day`} active={interval === 'day'}>
              Daily
            </ViewButton>
            {disableMonthly ? (
              <ToolTip title="Disabled until all data has synced">
                <DisabledButton>Monthly</DisabledButton>
              </ToolTip>
            ) : (
              <ViewButton to={`/${type}/month`} active={interval === 'month'}>
                Monthly
              </ViewButton>
            )}
          </ButtonWrap>
        )}
      </div>
    </ToolWrap>
  )
}
