import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getCompanyFeatures } from './companyFeatures'
import { Feature } from '../../../server/data/models'
import { LoadingAndError } from '../types'

export type CompanyFeaturesPayload = {
  companyFeatures: Feature[] | null
} & LoadingAndError

// REDUCERS
type CompanyFeaturesStoreData = {
  companyFeatures: CompanyFeaturesPayload
}

export type CompanyFeaturesData = Readonly<CompanyFeaturesStoreData>

const COMPANY_FEATURES_KEY = 'companyFeatures'

const initialCompanyFeaturesState: CompanyFeaturesData = {
  [COMPANY_FEATURES_KEY]: {
    [COMPANY_FEATURES_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const companyFeaturesReducer = createReducer(
  initialCompanyFeaturesState,
  builder => {
    // GET COMPANY_FEATURES
    createThunkReducer(
      builder,
      getCompanyFeatures.typePrefix,
      COMPANY_FEATURES_KEY,
      (state, action) =>
        ({
          ...state,
          [COMPANY_FEATURES_KEY]: {
            ...state[COMPANY_FEATURES_KEY],
            isLoading: null,
            error: null,
            companyFeatures: action.payload.companyFeatures,
          },
        } as CompanyFeaturesData),
    )
  },
)
