import React from 'react'

import { ResetPassword } from '../../components/users'
import { Layout } from '../../layout'
import { Color } from '../../Color'

export const ResetPasswordScreen = (): JSX.Element => (
  <Layout showSidebar={false} background={Color.StartScreenBlue}>
    <ResetPassword />
  </Layout>
)
