import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const LoadingPageWrapper = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingCircular = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export const PageLoading = (): JSX.Element => {
  return (
    <LoadingPageWrapper>
      <LoadingCircular />
    </LoadingPageWrapper>
  )
}
