import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { Color } from '../../../../Color'
import { SxProps } from '@mui/system'
import { styled } from '@mui/material/styles'

const ErrorBox = styled(Box)`
  text-align: center;
  border: 1px solid ${Color.ErrorRed};
  padding: 8px;
  color: ${Color.ErrorRed};
`

interface Props {
  sx?: SxProps<any>
  renderLabel: () => JSX.Element
  value: boolean
  name: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  isError: boolean
  errorMessage: string
}

// a checkbox input with standard error validation
export const CheckboxInput: React.FC<Props> = ({
  sx,
  renderLabel,
  value,
  name,
  onChange,
  isError,
  errorMessage,
}) => {
  return (
    <FormControl error={isError} sx={{ ...sx }}>
      <FormControlLabel
        label={renderLabel()}
        control={<Checkbox value={value} name={name} onChange={onChange} />}
      ></FormControlLabel>
      {isError && (
        <ErrorBox>
          <Typography variant={'body2'}>{errorMessage}</Typography>
        </ErrorBox>
      )}
    </FormControl>
  )
}
