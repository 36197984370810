import React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'

import { Connection } from '../../../modules/connectors/types'
import { ConnectorStatusContainer } from '../../connectors/components/ConnectorStatusContainer'
import { CircularProgressWithLabel } from '../../../components/designSystem/molecules/CircularProgressWithLabel/CircularProgressWithLabel'
import { Color } from '../../../Color'

const MessageText = styled('p')`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  color: ${Color.DarkCharcoal};
  margin-right: 20px;
`

interface Props {
  connections: Connection[]
  children: React.ReactNode
}

// NOTE(connor): currently only difference between this and "LoadingRevenueConnections" of
// the revenue tracker is that LTV bases percents off of percentOfYear
// instead of percentOfWeek. Might be worth merging, might not (ROAS
// is based off weekly as well).
export const RevenueLoading: React.FunctionComponent<Props> = ({
  connections,
  children,
}) => {
  return (
    <Stack spacing={7} sx={{ margin: '12px 0' }}>
      {connections.length > 0 && (
        <Stack spacing={1.5}>
          {connections.map((connection, index) => (
            <ConnectorStatusContainer
              key={`${connection.key}-${index}`}
              providerKey={connection.key}
            >
              <MessageText>Syncing</MessageText>
              <CircularProgressWithLabel
                value={connection.percentOfYearComplete}
                color={'secondary'}
              />
            </ConnectorStatusContainer>
          ))}
        </Stack>
      )}
      {children}
    </Stack>
  )
}
