import { createAsyncThunk } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'

import { api } from '../../lib/api'
import { createThunkReducer } from '../thunkReducerBuilder'
import { CompanyGoalPeriod } from '../../../server/data/models/types'
import { QuarterlyCompanyGoalToCreate } from '../../../server/services/companyGoals'
import { COMPANY_GOALS_KEY, GetRelativeCompanyGoals } from './types'
import { getRelativePeriodMap, getRelativeQuarterlyGoals } from './utils'
import { aggregateErrors, withErrorHandler } from '../../util/error'

/**
 * Gets company goals from api for provided period (e.g. quarter, month, week).
 */
export const getCompanyGoals = createAsyncThunk(
  'app/company-goals/get',
  async (period: CompanyGoalPeriod) =>
    withErrorHandler(async () => api.get(`/api/company-goals/${period}`)),
)

/**
 * Sets quarterly company goals.
 */
export const setQuarterlyCompanyGoals = createAsyncThunk(
  'app/company-goals/set',
  async (goals: QuarterlyCompanyGoalToCreate[]) => {
    withErrorHandler(async () =>
      api.post('/api/company-goals/quarterly', { goals }),
    )
  },
)

/**
 * Initializes company goals state.
 */
const initialCompanyGoalsState: GetRelativeCompanyGoals = {
  [COMPANY_GOALS_KEY]: {
    isLoading: true,
    error: null,
    relativePeriodMap: getRelativePeriodMap(),
  },
}

/**
 * Reduces company goals.
 */
export const companyGoalsReducer = createReducer(
  initialCompanyGoalsState,
  builder => {
    createThunkReducer(
      builder,
      getCompanyGoals.typePrefix,
      COMPANY_GOALS_KEY,
      (state, action) => {
        const relativePeriodMap = getRelativePeriodMap()
        return {
          ...state,
          [COMPANY_GOALS_KEY]: {
            isLoading: false,
            error: aggregateErrors([action.payload]),
            relativePeriodMap,
            relativeQuarters: action.payload.quarterlyGoals
              ? getRelativeQuarterlyGoals(
                  action.payload.quarterlyGoals,
                  relativePeriodMap.quarter,
                )
              : undefined,
          },
        }
      },
    )
  },
)
