import React from 'react'
import { styled } from '@mui/material/styles'
import { Color } from '../../Color'

const CardContainer = styled('div')`
  background: ${Color.White};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.31rem;
  width: 305px;
  height: 650px;
  padding: 35px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  margin: 10px;

  &:hover {
    border: 2px solid ${Color.VioletEggplant};
  }
`

interface Props {
  children?: React.ReactNode
}

export const PricingCard: React.FunctionComponent<Props> = ({ children }) => {
  return <CardContainer>{children}</CardContainer>
}
