import React from 'react'
import { ComparatorTooltip } from '../../../../components/designSystem/organisms/BFGraph/components/CustomTooltip'
import { commaDelimitedThousands } from '../../../../util/displayValue'
import { ForecastDataBySegmentMap } from './types'
import { AbstractProductDemandPeriodTransformer } from './transformers/AbstractProductDemandPeriodTransformer'

interface ToolTipPayload {
  name: string
  value: number
  color: string
  payload: { x: string; y: number | null; yhat: number | null }
}

export interface ToolTipProps {
  active: boolean
  payload: ToolTipPayload[]
  label: string
  selectedLine: string | null
  segmentDataMap: ForecastDataBySegmentMap
  transformer: AbstractProductDemandPeriodTransformer
}

export const CustomTooltip = (props: ToolTipProps): JSX.Element | null => {
  if (!props.active || !props.payload || !props.payload.length) {
    return null
  }

  const { active, label, payload, selectedLine, transformer } = props

  const isForecast = transformer.isLabelForecast(label)

  return (
    <ComparatorTooltip
      active={active}
      payload={transformer.getPayloadValues(label, payload, selectedLine)}
      label={label}
      baseName={transformer.getBaseName(label)}
      comparisonName="Forecast"
      showTotal={true}
      boldBase={!isForecast}
      boldComparison={isForecast}
      formatLabelFunc={commaDelimitedThousands}
    />
  )
}
