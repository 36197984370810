import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

import Typography from '@mui/material/Typography'

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
}))

const StyledDiv = styled('div')`
  width: 120px;
  margin-left: 16px;
`

interface LockableTextInputProps {
  value: string
  setValue: (value: string) => void
  isLocked: boolean
  error?: string
}

export const LockableTextInput = (
  props: LockableTextInputProps,
): JSX.Element => {
  const { value, setValue, isLocked, error } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [activeValue, setActiveValue] = useState<string>(value ?? '')

  useEffect(() => {
    if (!isFocused) {
      setActiveValue(value)
    }
  }, [value, isFocused])

  const onFocus = useCallback(() => setIsFocused(true), [])
  const onBlur = useCallback(() => setIsFocused(false), [])
  const onChange = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      const valueString = event?.target?.value
      if (typeof valueString !== 'string') {
        return
      }
      setActiveValue(valueString)
      setValue(valueString)
    },
    [setValue],
  )

  return (
    <StyledDiv>
      {isLocked ? (
        <Typography variant="body2">{value}</Typography>
      ) : (
        <StyledTextField
          value={activeValue}
          disabled={isLocked}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          error={Boolean(error)}
          helperText={error}
          size="small"
          inputProps={{ style: { fontSize: '0.875rem' } }}
        />
      )}
    </StyledDiv>
  )
}
