import { parseISO } from 'date-fns'

import { api } from '../../lib/api'
import { GetAllUserConnectionsResponse } from '../../../server/routes/api/connections/list'
import { Thunk } from '../../store'
import { StoreConnectorsData } from './types'

export const GET_PENDING = 'connectors/get/PENDING'
export const GET_SUCCESS = 'connectors/get/SUCCESS'
export const GET_FAILURE = 'connectors/get/FAILURE'

export type GetPendingAction = {
  type: typeof GET_PENDING
}
export type GetSuccessAction = {
  type: typeof GET_SUCCESS
  payload: StoreConnectorsData
}
export type GetFailureAction = {
  type: typeof GET_FAILURE
  payload: Error
}

function getPending(): GetPendingAction {
  return {
    type: GET_PENDING,
  }
}

// All these functions are identify function parsers and
// type lenses, could maybe rename? Or not.
// Need a whole library of little compositional parsers. Or not.
export function getSuccess(
  payload: GetAllUserConnectionsResponse,
): GetSuccessAction {
  return {
    type: GET_SUCCESS,
    payload: {
      all: payload.all,
      connections: payload.connections.map(e => {
        return {
          ...e,
          oldestTransactionDate: e.oldestTransactionDate
            ? parseISO(e.oldestTransactionDate)
            : undefined,
          invalidatedAt: e.invalidatedAt
            ? parseISO(e.invalidatedAt)
            : undefined,
          lastSyncedAt: e.lastSyncedAt ? parseISO(e.lastSyncedAt) : undefined,
        }
      }),
    },
  }
}
function getFailure(payload: Error): GetFailureAction {
  return {
    type: GET_FAILURE,
    payload,
  }
}

export function getConnectors(): Thunk {
  return async (dispatch): Promise<void> => {
    dispatch(getPending())

    try {
      const response = await api.get('/api/connections')
      dispatch(getSuccess(response))
    } catch (error: any) {
      dispatch(getFailure(error))
    }
  }
}
