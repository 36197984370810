export const mapChannelNameToDisplayValue = (channelName?: string): string => {
  if (!channelName) return ''

  const brandNameToTitleMap: { [key: string]: string } = {
    shopifySales: 'Shopify',
    amazonSales: 'Amazon',
    amazonAds: 'Amazon Ads',
    facebookAds: 'Facebook Ads',
    googleAds: 'Google Ads',
    total: 'Total',
    balanceCents: 'Cash Balance',
    cashInflowCents: 'Cash In',
    cashOutflowCents: 'Cash Out',
  }

  return brandNameToTitleMap[channelName] ?? channelName
}
