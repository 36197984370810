import { Buffer } from 'buffer'

import { Category, UrlAttributes } from './Transactions.types'
import { TransactionCategory } from '../../../../server/app-data-service/generatedTypes'

export const encodeAttributeHash = (attributes: UrlAttributes): string =>
  encodeURIComponent(Buffer.from(JSON.stringify(attributes)).toString('base64'))

export const decodeAttributeHash = (attributeHash: string): UrlAttributes =>
  JSON.parse(
    Buffer.from(decodeURIComponent(attributeHash), 'base64').toString('ascii'),
  )

export const transformCategories = (
  rawCategories: TransactionCategory[],
  parentId: string | null = null,
  parentLabel: string | null = null,
): Category[] => {
  const parentIds = new Set(
    rawCategories
      .filter(category => category.parentId)
      .map(category => category.parentId),
  )

  return rawCategories
    .filter(category => parentId === category.parentId)
    .filter(({ id }) => !parentIds.has(id))
    .map(({ id, label }) => ({
      id,
      label,
      parent: parentLabel || '',
    }))
    .concat(
      rawCategories
        .filter(category => parentId === category.parentId)
        .flatMap(({ id, label }) =>
          transformCategories(rawCategories, id, label),
        ),
    )
}
