import React from 'react'
import { format } from 'date-fns'

import { DashboardHeadingSection } from '../../../../../../components/designSystem/organisms/DashboardHeadingSection/DashboardHeadingSection'

export const SalesForecastHeadingSection = (): JSX.Element => {
  return (
    <DashboardHeadingSection
      subHeading={`Data aggregated on ${format(new Date() || 0, 'MMMM d, y')}`}
    />
  )
}
