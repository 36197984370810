import React, { useState } from 'react'

import { MultiSelect } from '../../../../../components/designSystem/atoms/MultiSelect/MultiSelect'
import { useCashPosition } from '../../CashPositionProvider'

export const CashPositionAccountSelect = (): JSX.Element => {
  const { accounts, setSelectedAccounts } = useCashPosition()
  const [selectedOptions, setSelectedOptions] = useState(accounts)

  const handleChangeClicked = (values: string[]): void => {
    const newSelectedOptions = accounts.map(option => ({
      ...option,
      isSelected: values.includes(option.id),
    }))
    setSelectedAccounts(values)
    setSelectedOptions(newSelectedOptions)
  }

  return (
    <MultiSelect
      allowEmptyOptions={false}
      title="Account Select"
      options={selectedOptions}
      onChange={handleChangeClicked}
      sx={{ minWidth: '750px' }}
    />
  )
}
