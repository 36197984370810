import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { CircularBullet } from '../../LegendEntry/components/CircularBullet'

export interface LegendRowProps {
  name: string
  value: string
  bulletColor?: string
  comparisonComponents: [JSX.Element, JSX.Element]
  alignItems?: string
  height?: number
}

export const LegendRow = ({
  name,
  value,
  comparisonComponents,
  bulletColor,
  alignItems = 'center',
  height = 40,
}: LegendRowProps): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems={alignItems}
      sx={{ minHeight: `${height}px` }}
    >
      <Grid
        item
        lg={5}
        sm={4}
        xs={12}
        sx={{
          height: '100%',
        }}
        display={'flex'}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems={alignItems}
          spacing={1}
        >
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            {bulletColor && <CircularBullet color={bulletColor} size={10} />}
            <Typography
              sx={{
                ml: 1,
                typography: {
                  lg: 'subtitle2',
                  xs: 'body1',
                },
              }}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                typography: {
                  lg: 'subtitle2',
                  xs: 'body1',
                },
              }}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={7}
        sm={8}
        xs={0}
        display={{ xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' }}
        sx={{
          height: '100%',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems={alignItems}
          justifyContent={'flex-end'}
        >
          <Grid item sx={{ width: '130px' }}>
            <Grid container direction="row">
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                {comparisonComponents[0]}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                {comparisonComponents[1]}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
