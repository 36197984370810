import React, { useState } from 'react'

import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SxProps, Theme } from '@mui/material/styles'

import { Control, ControlIcon } from './FlexibleActionMenu.styles'
import { Color } from '../../../../Color'

type Props<K extends string | number> = {
  handleOnSelect: (key: K, value: string) => void
  options: Array<{ key: K; value: string }>
  value: string
  sx?: SxProps<Theme>
}

export const FlexibleActionMenu = <K extends string | number>({
  handleOnSelect,
  options,
  value,
  sx,
}: Props<K>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const isOpen = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (key: K, value: string) => {
    handleOnSelect(key, value)
    setAnchorEl(null)
  }

  return (
    <>
      <Control
        id="dashboard-control-btn"
        aria-controls={isOpen ? 'dashboard-control-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        sx={{ ...sx }}
      >
        <Typography variant="body1" color={Color.Black}>
          {value}
        </Typography>
        <ControlIcon isActive={isOpen} />
      </Control>
      <Menu
        id="dashboard-control-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dashboard-control-btn',
        }}
      >
        {options.map(({ key, value }) => (
          <MenuItem
            key={`dashboard-control-menu-item-${key}`}
            onClick={() => handleMenuItemClick(key, value)}
            sx={{ ...sx }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
