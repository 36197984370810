import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Color } from '../../../../../Color'

import { Category } from '../../Transactions.types'

import {
  findMatchingIndex,
  isCategory,
} from './TransactionsTableMultiSelect.utils'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

type Props = {
  options: Category[]
  onChange: (value: Category[] | null) => void
  initialValue?: string[]
  DropDownAdditon?: ReactNode
}

export const TransactionsTableMultiSelect: React.FunctionComponent<Props> = ({
  options,
  onChange,
  initialValue,
}) => {
  const ref = useRef<HTMLElement>(null)

  const [maxWidth, setMaxWidth] = useState<number>(0)
  const [value, setValue] = useState<Category[]>(
    initialValue ? options.filter(({ id }) => initialValue.includes(id)) : [],
  )

  const handleChange = (_event: any, newValue: Category[]): void => {
    setValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setMaxWidth(ref.current.offsetWidth - 88)
      }
    }

    updateWidth()
  }, [])

  return (
    <Autocomplete
      ref={ref}
      multiple
      size="small"
      limitTags={1}
      getOptionLabel={(option: any): string => option.label}
      groupBy={(option: any): string => option.parent}
      value={value}
      onChange={handleChange}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      options={options}
      renderInput={(inputParams): JSX.Element => <TextField {...inputParams} />}
      renderTags={(value, getTagProps, { expanded }): ReactNode =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            variant="outlined"
            label={option.label}
            size="small"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: expanded ? 'auto' : `${maxWidth}px !important`,
            }}
            {...getTagProps({ index })}
          />
        ))
      }
      sx={{
        width: '100%',
        height: '38px',
        borderRadius: '5px',
        backgroundColor: Color.White,
        '&.Mui-expanded, &.Mui-focused': {
          zIndex: '10',
          position: 'relative',
          '.MuiFormControl-root': {
            backgroundColor: Color.White,
          },
          '.MuiOutlinedInput-root': {
            flexWrap: 'wrap',
          },
        },
        '.MuiOutlinedInput-root': {
          fontSize: '0.766rem',
          padding: '3px',
          display: 'inline-flex',
          flexWrap: 'nowrap',

          '.MuiAutocomplete-input': {
            minWidth: 0,
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: Color.LightGrey,
        },
      }}
    />
  )
}
