import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import { DataSyncing } from '../../../../shared'
import { Color } from '../../../../../../Color'

export const SalesForecastErrorState = (): JSX.Element => (
  <DataSyncing
    title="Sales Forecast Is Unavailable"
    subtitle="We've ran into a snag loading your report. Please try again later."
    icon={
      <WarningAmberIcon
        sx={{ color: Color.ErrorRed, height: '98px', width: '113.5px' }}
      />
    }
  />
)
