import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { createThunkReducer } from '../../thunkReducerBuilder'
import { api } from '../../../lib/api'

import { OverviewResponse } from '../../../../server/services/overview/types'

export const getOverviewResults = createAsyncThunk(
  'reports/overview/get',
  async () => api.get(`/api/reports/overview`),
)

type OverviewStore = Readonly<{
  overviewResults: OverviewResponse & {
    hasLoaded: boolean
    error: string | null
  }
}>

const initialState: OverviewStore = {
  overviewResults: {
    hasLoaded: false,
    error: null,
    cac: null,
    ltv: null,
    marketing: null,
    aov: null,
    newCustomers: null,
    sales: null,
    units: null,
    orders: null,
  },
}

export const overviewReducer = createReducer(initialState, builder => {
  createThunkReducer(
    builder,
    getOverviewResults.typePrefix,
    'overviewResults',
    (state, action) => ({
      ...state,
      overviewResults: {
        ...action.payload.result,
        hasLoaded: true,
      },
    }),
  )
})
