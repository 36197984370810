import BigNumber from 'bignumber.js'

import { HumanFormatter } from './formatter/HumanFormatter'

type Value = string | number | Money | BigNumber

export class Money {
  readonly value: BigNumber

  constructor(value: Value) {
    if (value instanceof Money) {
      this.value = value.value
    } else {
      this.value = new BigNumber(value)
    }
  }

  get fractional(): number {
    return this.value.modulo(100).toNumber()
  }

  /**
   * @returns The represented value as a human-readable string.
   */
  humanize(s?: boolean): string {
    return new HumanFormatter(this).toString(s)
  }

  toString(): string {
    return (
      '$' +
      this.value.dividedBy(100).toFormat(0, BigNumber.ROUND_HALF_EVEN, {
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
      })
    )
  }
}
