// types are from QBO ref
// we're only using two for now

import { SyncStatus } from '../../../sync/types'
import { KNOWN_CONNECTOR_KEYS } from '../../../connectors/known-connectors'

//  https://developer.intuit.com/app/developer/qbo/docs/api/accounting/most-commonly-used/account
// We plan to deprecate account classifications, all new accounts should use the
// Other field until the deprecation occurs
export enum AccountClassifications {
  Asset = 'asset',
  // Equity = 'equity',
  Expense = 'expense',
  // Liability = 'liability',
  // Revenue = 'revenue',
  Other = 'other',
}

export enum AccountCategories {
  amazonAds = 'amazonAds',
  amazonSales = 'amazonSales',
  facebookAds = 'facebookAds',
  googleAds = 'googleAds',
  shopifySales = 'shopifySales',
  plaid = 'plaid',

  // TODO: Deprecate below categories
  bankAccountsTotal = 'bankAccountsTotal',
  grossProfit = 'grossProfit',
  inventory = 'inventory',
  marketingSales = 'marketingSales',
  otherFixed = 'otherFixed',
  otherVariable = 'otherVariable',
  payroll = 'payroll',
  unknown = 'unknown',
  income = 'income',
  cogs = 'cogs',
}

/**
 * Lookup a KNOWN CONNECTOR KEY as an AccountCategories
 *
 * Why we have these two similar but differing types…
 */
export const KnownConnectorKeyToAccountCategories = (
  key: KNOWN_CONNECTOR_KEYS,
): AccountCategories | null => {
  switch (key) {
    case KNOWN_CONNECTOR_KEYS.amazon:
      return AccountCategories.amazonAds
    case KNOWN_CONNECTOR_KEYS.amazonSeller:
      return AccountCategories.amazonSales
    case KNOWN_CONNECTOR_KEYS.facebookMarketing:
      return AccountCategories.facebookAds
    case KNOWN_CONNECTOR_KEYS.googleAds:
      return AccountCategories.googleAds
    case KNOWN_CONNECTOR_KEYS.shopify:
      return AccountCategories.shopifySales
    default:
      return null
  }
}

export const salesCategories: AccountCategories[] = [
  AccountCategories.amazonSales,
  AccountCategories.shopifySales,
]

export type InflowCategory =
  | typeof AccountCategories.amazonSales
  | typeof AccountCategories.shopifySales

export const inflowCategories: InflowCategory[] = [
  AccountCategories.shopifySales,
  AccountCategories.amazonSales,
]

export type OutflowCategoriesType =
  | typeof AccountCategories.amazonAds
  | typeof AccountCategories.facebookAds
  | typeof AccountCategories.googleAds
  | typeof AccountCategories.inventory
  | typeof AccountCategories.marketingSales
  | typeof AccountCategories.otherVariable
  | typeof AccountCategories.otherFixed
  | typeof AccountCategories.payroll

export const outflowCategories: OutflowCategoriesType[] = [
  AccountCategories.amazonAds,
  AccountCategories.facebookAds,
  AccountCategories.googleAds,
  AccountCategories.inventory,
  AccountCategories.marketingSales,
  AccountCategories.otherFixed,
  AccountCategories.otherVariable,
  AccountCategories.payroll,
]

export type MarketingAccountCategory =
  | AccountCategories.facebookAds
  | AccountCategories.googleAds
  | AccountCategories.amazonAds

// QUESTION(connor): what's difference between this and adCategories?
export const marketingAccountCategories: MarketingAccountCategory[] = [
  AccountCategories.facebookAds,
  AccountCategories.googleAds,
  AccountCategories.amazonAds,
]

export const adCategories: AccountCategories[] = [
  AccountCategories.amazonAds,
  AccountCategories.facebookAds,
  AccountCategories.googleAds,
]

export const isVariableAccount = (account: {
  category: AccountCategories
}): boolean =>
  account.category === AccountCategories.otherVariable ||
  account.category === AccountCategories.marketingSales

export const isFixedAccount = (account: {
  category: AccountCategories
}): boolean =>
  account.category === AccountCategories.otherFixed ||
  account.category === AccountCategories.payroll

export type SyncRunStatus = SyncStatus | 'failed' | 'started'

export type ProductWithFullYearSalesData = {
  lastSaleDate: Date
  firstSaleDate: Date
  productId: string
}
