import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAdminDashboardData } from '../../modules/adminDashboard/adminDashboard'
import { Layout } from '../../layout'
import { connect } from 'react-redux'
import { Store } from '../../modules/rootReducer'
import { AdminDashboardPayload } from '../../modules/adminDashboard/'
import { PageLoading } from '../../components/designSystem/atoms/Loading/Loading'
import { dispatch } from '../../store'
import { DashboardGrid } from './DashboardGrid'
import { LicenseInfo } from '@mui/x-license-pro'

import Button from '@mui/material/Button'
import { Color } from '../../Color'

// As per https://mui.com/x/introduction/licensing/#where-to-install-the-key
// this is meant to be public, so okay that it's in JS
LicenseInfo.setLicenseKey(
  '6e3236396aa25077f8a6d5afb34a88b3Tz05MTIxMSxFPTE3NDgxMDUzNzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

interface Props {
  adminDashboard: AdminDashboardPayload
}

export const AdminDashboardImpl = ({ adminDashboard }: Props): JSX.Element => {
  useEffect(() => {
    dispatch(getAdminDashboardData())
  }, [])

  const {
    isLoading: adminDashboardCompaniesLoading,
    error,
    adminDashboard: dashboardResults,
  } = adminDashboard

  if (adminDashboardCompaniesLoading) {
    return <PageLoading />
  }

  if (error || !dashboardResults?.results) {
    return <p>Dang, something is broken...</p>
  }

  return (
    <Layout showSidebar={false} background={'white'}>
      <Link to="/customer-service/credit/labels" target="_blank">
        <Button sx={{ border: `1px solid ${Color.VioletEggplant}` }}>
          Account Labeler
        </Button>
      </Link>
      <DashboardGrid
        companies={dashboardResults.results.companies}
        features={dashboardResults.results.features}
        subscriptionPlans={dashboardResults.results.subscriptionPlans}
      />
    </Layout>
  )
}

const mapStateToProps = ({
  adminDashboard: { adminDashboard },
}: Store): Props => ({
  adminDashboard,
})

export const AdminDashboardScreen = connect(mapStateToProps)(
  React.memo(AdminDashboardImpl),
)
