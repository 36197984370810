import { LoadingAndError } from '../types'
import { CompanyGoalType } from '../../../server/data/models/types'
import { RelativeCompanyGoalSerialized } from '../../../server/services/companyGoals'
import {
  CurrentOrNextPeriod,
  CurrentOrPreviousPeriod,
  RelativePeriod,
} from '../../../server/util/relativePeriod'
import { Quarter } from '../../../server/util/quarterCalculator'

export const COMPANY_GOALS_KEY = 'companyGoals'

export type RelativeQuarterMap = {
  [relativePeriod in RelativePeriod]: Quarter
}

export type RelativePeriodMap = {
  quarter: RelativeQuarterMap
}

export type CompanyGoalsValues = {
  [goalType in CompanyGoalType]: number
}

export type RelativeCompanyGoalValues = {
  [relativePeriod in CurrentOrNextPeriod]: {
    [goalType in CompanyGoalType]: {
      value: number
      hasChanged: boolean
    }
  }
}

export type RelativeCompanyGoals = LoadingAndError & {
  relativePeriodMap: RelativePeriodMap
  relativeQuarters?: RelativeCompanyGoalSerialized
}

export type GetRelativeCompanyGoals = Readonly<{
  [COMPANY_GOALS_KEY]: RelativeCompanyGoals
}>

export type RelativeCompanyGoalsProgressValues = {
  [relativePeriod in CurrentOrPreviousPeriod]: CompanyGoalsValues
}

export type CompanyGoalLocks = {
  [goal in CompanyGoalType]: boolean
}

export type CompanyGoalRange = {
  min: CompanyGoalsValues
  max: CompanyGoalsValues
}
