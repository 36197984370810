import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Color } from '../../../../Color'

import {
  RowHeadingsContainer,
  TableBody,
  TableColumn,
} from './ColumnEmphasisTable.styles'

type Props = {
  rowHeadings: string[]
  columns: Array<{ heading: string; values: (string | null)[] }>
}

export const ColumnEmphasisTable = ({ rowHeadings, columns }: Props) => (
  <Box sx={{ display: 'flex', gap: '25px' }}>
    <RowHeadingsContainer>
      {rowHeadings.map((heading, key) => (
        <Typography
          key={`row-heading-${key}`}
          sx={{ color: Color.LightGrey, fontWeight: 600 }}
        >
          {heading}
        </Typography>
      ))}
    </RowHeadingsContainer>
    <TableBody>
      {columns.map((column, columnKey) => (
        <TableColumn key={`column-${columnKey}`}>
          <Typography
            sx={{ color: Color.LightGrey, textAlign: 'center' }}
            variant="caption"
          >
            {column.heading}
          </Typography>
          {column.values.map((value, columnRowKey) => (
            <Typography
              key={`column-${columnKey}-row-${columnRowKey}`}
              sx={{ fontWeight: 600, width: '100%', textAlign: 'center' }}
            >
              {value ?? '-'}
            </Typography>
          ))}
        </TableColumn>
      ))}
    </TableBody>
  </Box>
)
