import React from 'react'
import { OverviewCardTypes, OverviewHighlightMetrics } from './Overview.types'
import { OverviewOrderRevenueTooltip } from './components/OverviewRevenueCard/components/OverviewOrderRevenueTooltip'

export const OverviewHighlightConsts: {
  [key in OverviewHighlightMetrics]: {
    title: string
    description: React.ReactNode
  }
} = {
  [OverviewHighlightMetrics.AOV]: {
    title: 'Average Order Value',
    description: 'Order Revenue / Number of Orders',
  },
  [OverviewHighlightMetrics.CAC]: {
    title: 'Customer Acquisition Cost',
    description: 'Total Advertising Spend / Number of Customers',
  },
  [OverviewHighlightMetrics.CashBalance]: {
    title: 'Cash Balance',
    description:
      'All banking data reflects balance information at end of UTC day.',
  },
  [OverviewHighlightMetrics.NewCustomers]: {
    title: 'New Customers',
    description: 'Number of new customers within the selected time period.',
  },
  [OverviewHighlightMetrics.OrderRevenue]: {
    title: 'Order Revenue',
    description: <OverviewOrderRevenueTooltip />,
  },
  [OverviewHighlightMetrics.OrderVolume]: {
    title: 'Orders',
    description: 'Number of orders within the selected time period.',
  },
  [OverviewHighlightMetrics.ROAS]: {
    title: 'Return on Ad Spend',
    description: 'Order Revenue / Total Advertising Spend',
  },
}

export const OverviewCardConsts: {
  [key in OverviewCardTypes]: {
    title: string
    description: React.ReactNode
    emptyStateDescription?: string
  }
} = {
  [OverviewCardTypes.AOV]: {
    title: 'Average Order Value',
    description: 'Order Revenue / Number of Orders',
  },
  [OverviewCardTypes.Banking]: {
    title: 'Banking',
    emptyStateDescription:
      'connect your bank account and start analyzing your historical cash flow',
    description:
      'All banking data reflects balance information at end of UTC day.',
  },
  [OverviewCardTypes.CAC]: {
    title: 'Customer Acquisition Cost',
    description: 'Total Advertising Spend / Number of Customers',
  },
  [OverviewCardTypes.CreditScore]: {
    title: 'Credit Score',
    emptyStateDescription: `connect your accounting data and get your credit score.`,
    description:
      'Our business credit score helps you benchmark how your business is performing and understand your ability to qualify for a loan.',
  },
  [OverviewCardTypes.CustomerValue]: {
    title: 'New Customer Value',
    description:
      'Average values based on historical data. For example, 12 month average LTV is the total spend the average customer has made over the last 12 months. Customer margin = LTV - CAC.',
  },
  [OverviewCardTypes.Marketing]: {
    title: 'Advertising',
    emptyStateDescription:
      'connect your advertising data and start visualizing your spend.',
    description:
      'Total Advertising Spend across all sources within the selected time period.',
  },
  [OverviewCardTypes.NewCustomer]: {
    title: 'New Customers',
    description: 'Number of New Customers within the selected time period',
  },
  [OverviewCardTypes.OrderRevenue]: {
    title: 'Order Revenue',
    emptyStateDescription:
      'connect your online shop data and start visualizing your online revenue.',
    description: <OverviewOrderRevenueTooltip />,
  },
  [OverviewCardTypes.OrderVolume]: {
    title: 'Orders',
    description: 'Number of Orders within the selected time period',
  },
  [OverviewCardTypes.Units]: {
    title: 'Units',
    description: 'Number of Units sold within the selected time period',
  },
}
