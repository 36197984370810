import React from 'react'

interface Props {
  height: number
  width: number
}

export const CircleBang: React.FunctionComponent<Props> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${height} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>error_outline_black_24dp</title>
      <g id="v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Forecast---not-enough-revenue-data"
          transform="translate(-678.000000, -244.000000)"
          fill="#08678C"
          fillRule="nonzero"
        >
          <g
            id="error_outline_black_24dp"
            transform="translate(678.000000, 244.000000)"
          >
            <path
              d="M44.1,63.7 L53.9,63.7 L53.9,73.5 L44.1,73.5 L44.1,63.7 Z M44.1,24.5 L53.9,24.5 L53.9,53.9 L44.1,53.9 L44.1,24.5 Z M48.951,0 C21.903,0 0,21.952 0,49 C0,76.048 21.903,98 48.951,98 C76.048,98 98,76.048 98,49 C98,21.952 76.048,0 48.951,0 Z M49,88.2 C27.342,88.2 9.8,70.658 9.8,49 C9.8,27.342 27.342,9.8 49,9.8 C70.658,9.8 88.2,27.342 88.2,49 C88.2,70.658 70.658,88.2 49,88.2 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
