import React from 'react'
import { styled } from '@mui/material/styles'
import { FlexCenter, PlanButton } from './PricingScreen'
import { StandardSubscriptionPlanNames } from '../../../server/data/models/types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { LENDING_PLAN_PLACEHOLDER } from './PricingScreen'

const PlanCardContainer = styled('div')`
  width: 100%;
  height: 100%;
`

const FeaturesListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '20px',
})

const mapSubscriptionPlanNameToDisplayName = (name: string): string => {
  const subscriptionPlanNameToDisplayNameMap: {
    [key in StandardSubscriptionPlanNames]: string
  } = {
    [StandardSubscriptionPlanNames.free]: 'FREE PLAN',
    [StandardSubscriptionPlanNames.starter]: 'SELF-SERVICE FORECASTING',
    [StandardSubscriptionPlanNames.professional]: 'ADVANCED CASH MANAGEMENT',
  }

  return (
    subscriptionPlanNameToDisplayNameMap[
      name as StandardSubscriptionPlanNames
    ] ?? 'PREMIUM ADVISORY'
  )
}

const mapFeeCentsToDisplayCost = (feeCents: number): string => {
  if (feeCents === 0) return 'Free'

  return feeCents > 0 ? `$${feeCents / 100} / month` : 'Contact us'
}

const KEEP_THIS_TEXT = 'Keep this'
const SCHEDULE_CALL_TEXT = 'Schedule a call'
const SELECT_PLAN_TEXT = 'Select this plan'

// Always display the Schedule a call text for the Lending plan
// Display the Schedule a call text for the Starter and Professional plans if
// the company does not have a valid Shopify connection
const getButtonText = (
  hasShopifyConnection: boolean,
  isActiveSubscription: boolean,
  name: string,
): string => {
  if (
    (!hasShopifyConnection && name !== StandardSubscriptionPlanNames.free) ||
    name === LENDING_PLAN_PLACEHOLDER.name
  ) {
    return SCHEDULE_CALL_TEXT
  }

  return isActiveSubscription ? KEEP_THIS_TEXT : SELECT_PLAN_TEXT
}

interface Props {
  id: string
  name: string
  feeCents: number
  hasShopifyConnection: boolean
  isActiveSubscription: boolean
  features: string[]
  handlePlanSelectionClick: (id: string, name: string) => void
}

export const PricingCardContent: React.FunctionComponent<Props> = ({
  id,
  name,
  feeCents,
  hasShopifyConnection,
  isActiveSubscription,
  features,
  handlePlanSelectionClick,
}) => {
  return (
    <PlanCardContainer>
      <FlexCenter>
        <Typography variant="overline">
          {mapSubscriptionPlanNameToDisplayName(name)}
        </Typography>
        <Typography variant="h5">
          {mapFeeCentsToDisplayCost(feeCents)}
        </Typography>
      </FlexCenter>

      <PlanButton
        onClick={(): void => {
          handlePlanSelectionClick(id, name)
        }}
      >
        {getButtonText(hasShopifyConnection, isActiveSubscription, name)}
      </PlanButton>

      <FeaturesListContainer>
        {features.map((feature, index) => (
          <Typography
            variant="body1"
            sx={{
              ...(name !== StandardSubscriptionPlanNames.free && index === 0
                ? { fontWeight: 'bold' }
                : {}),
            }}
            key={feature}
          >
            {feature}
          </Typography>
        ))}
      </FeaturesListContainer>
    </PlanCardContainer>
  )
}
