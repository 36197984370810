import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { LegendEntry } from '../../../../components/designSystem/molecules/LegendEntry/LegendEntry'
import { LegendEntryContentVariant1 } from '../../../../components/designSystem/molecules/LegendEntry/components/LegendEntryContentVariant1'
import { Segment } from '../../../../../server/services/forecast/types'
import { AbstractProductDemandPeriodTransformer } from './transformers/AbstractProductDemandPeriodTransformer'
import { INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW } from './constants'
import Link from '@mui/material/Link'
import { Color } from '../../../../Color'
import { commaDelimitedThousands } from '../../../../util/displayValue'
import { SegmentDetailModal } from './SegmentDetailModal'

type LegendContainerProps = {
  segments: Segment[]
  onClose: (segmentId: string) => void
}

const formatNumber = (number: number): string =>
  commaDelimitedThousands(Math.round(number))

export const LegendContainer = ({
  segments,
  onClose,
}: LegendContainerProps): JSX.Element => {
  const [openSegmentModal, setOpenSegmentModal] = useState<{
    [key: number]: boolean
  }>({})
  return (
    <Grid container mt={1} mb={1} spacing={2}>
      {segments.map((segment, index) => (
        <Grid
          item
          key={`segment-legend-entry-${segment.segmentId}`}
          xl={12 / INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW}
          lg={12 / INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW}
          md={4}
          sm={12}
          xs={12}
        >
          <LegendEntry
            title={segment.segmentName}
            color={AbstractProductDemandPeriodTransformer.getLineColor(index)}
            onClose={
              segments.length > 1
                ? (): void => onClose(segment.segmentId)
                : undefined
            }
            onActionClick={(): void => console.log('onActionClick')}
            renderBody={
              <>
                <LegendEntryContentVariant1
                  value={segment?.products?.length ?? 0}
                  description="Products"
                />
                <Link
                  sx={{
                    cursor: 'pointer',
                  }}
                  fontSize={14}
                  fontWeight={'bold'}
                  underline="none"
                  color={Color.DarkerBlue}
                  onClick={(): void =>
                    setOpenSegmentModal({ ...openSegmentModal, [index]: true })
                  }
                >
                  View Segment
                </Link>
                <SegmentDetailModal
                  details={[
                    {
                      title: 'Total Products',
                      value: `${segment.products?.length}`,
                    },
                    {
                      title: 'Units sold last week',
                      value: `${formatNumber(segment.unitsSoldLastWeek || 0)}`,
                    },
                    {
                      title: 'Units Sold Last 6 Mo',
                      value: `${formatNumber(
                        segment.unitsSoldLastSixMonths || 0,
                      )}`,
                    },
                    {
                      title: 'Units forecasted in 6 mo',
                      value: `${formatNumber(
                        segment.forecastUnitsSoldNextSixMonths || 0,
                      )}`,
                    },
                  ]}
                  isOpen={openSegmentModal[index] || false}
                  handleClose={(): void =>
                    setOpenSegmentModal({ ...openSegmentModal, [index]: false })
                  }
                  title={segment.segmentName}
                  products={
                    segment.products?.map(product => product.productName) || []
                  }
                />
              </>
            }
            sx={{ width: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  )
}
