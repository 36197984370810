import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { decodeAttributeHash, transformCategories } from './Transactions.utils'
import {
  Merchant,
  TransactionsHook,
  TransactionsScreenProps,
} from './Transactions.types'

import {
  useGetTransactionAccountsQuery,
  useGetTransactionCategoriesQuery,
  useGetTransactionMerchantsQuery,
  useGetTransactionsByDateRangeQuery,
} from '../../../../server/app-data-service/generatedTypes'
import { hasCashPositionFeature } from '../../../util/companyFeatures'
import { DefaultEndDate, DefaultStartDate } from './Transactions.consts'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { useDispatchApiCall } from '../../../hooks'
import { getConnectors } from '../../../modules/connectors'

export const useTransactions = ({
  bankingConnections,
  companyFeatures,
}: TransactionsScreenProps): TransactionsHook => {
  const { attrHash } = useParams<{ attrHash?: string }>()
  const urlAttributes = attrHash ? decodeAttributeHash(attrHash) : {}

  const [dateRange, setDateRange] = useState<{
    startDate: string | null
    endDate: string | null
  }>({
    startDate:
      urlAttributes.sd !== undefined ? urlAttributes.sd : DefaultStartDate,
    endDate: urlAttributes.ed !== undefined ? urlAttributes.ed : DefaultEndDate,
  })

  const [selectedAccounts, setSelectedAccounts] = useLocalStorage<
    string[] | null
  >('transactionAccounts', null)

  useDispatchApiCall([getConnectors])

  const hasFeatureAccess = hasCashPositionFeature(companyFeatures)

  const hasBankingConnection = !!bankingConnections.length

  const isSynced = bankingConnections.some(
    ({ initialSyncStatus }) => initialSyncStatus === 'complete',
  )

  const {
    data: merchantsData,
    loading: isMerchantsLoading,
    refetch: refetchMerchants,
    error: hasMerchantsError,
  } = useGetTransactionMerchantsQuery()

  const {
    data: categoriesData,
    loading: isCategoriesLoading,
    error: hasCategoriesError,
  } = useGetTransactionCategoriesQuery()

  const {
    data: accountsData,
    loading: isAccountsLoading,
    error: hasAccountsError,
  } = useGetTransactionAccountsQuery()

  const {
    data: transactionsData,
    loading: isTransactionsLoading,
    error: hasTransactionsError,
  } = useGetTransactionsByDateRangeQuery({
    variables: { ...dateRange, accountIds: selectedAccounts },
  })

  return {
    accounts:
      accountsData?.transactionAccounts.map(
        ({ accountId, accountName, accountMask }) => ({
          id: accountId,
          name: `${accountName} <${accountMask}>`,
          isSelected: selectedAccounts
            ? selectedAccounts.includes(accountId)
            : true,
        }),
      ) || [],
    categories: transformCategories(
      categoriesData?.transactionCategories || [],
    ),
    dateRange,
    hasBankingConnection,
    hasError: !!(
      hasAccountsError ||
      hasCategoriesError ||
      hasMerchantsError ||
      hasTransactionsError
    ),
    hasFeatureAccess,
    isContentLoading: isTransactionsLoading,
    isLabelsLoading:
      isMerchantsLoading || isCategoriesLoading || isAccountsLoading,
    isSynced,
    merchants:
      merchantsData?.transactionMerchants.filter(
        (merchant): merchant is Merchant =>
          !!merchant && merchant.merchantId !== null,
      ) || [],
    refetchMerchants,
    rows: transactionsData?.enrichedTransactionsByDateRange || [],
    setDateRange,
    setSelectedAccounts,
  }
}
