import React from 'react'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import { KnownConnectors } from '../../../../server/connectors/known-connectors'
import { Providers } from './Providers'

const WrapperProps = {
  height: '80px',
  width: '95%',
  border: '2px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px',
  padding: '15px 25px 15px 25px',
}

const Content = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Logo = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin: 0 20px 0 0;
`

const LogoIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 20px;
`

interface Props {
  className?: string
  children?: React.ReactNode
  providerKey: KnownConnectors
  customTitle?: string | null
  isSmall?: boolean
}

const renderResponsiveText = (text: string, isSmall?: boolean): JSX.Element => {
  const largeScreenText = isSmall ? 'h7' : 'h5'
  return (
    <>
      <Hidden smDown>
        <Typography variant={largeScreenText}>{text}</Typography>
      </Hidden>
      <Hidden smUp>
        <Typography variant="body1">{text}</Typography>
      </Hidden>
    </>
  )
}

export const ConnectorStatusContainer: React.FunctionComponent<Props> = ({
  className,
  children,
  providerKey,
  customTitle,
  isSmall,
}) => {
  const { title, icon: LogoIcon } = Providers[providerKey]
  const iconDimension = isSmall ? 30 : 40
  return (
    <Container className={className} sx={WrapperProps} maxWidth={false}>
      <Logo>
        <LogoIconWrapper>
          <LogoIcon height={iconDimension} width={iconDimension} />
        </LogoIconWrapper>
        {renderResponsiveText(customTitle || title, isSmall)}
      </Logo>
      <Content>{children}</Content>
    </Container>
  )
}
