import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getRevenueForecast } from './revenueForecast'
import { LoadingAndError } from '../types'
import { RevenueForecastResponse } from '../../../server/services/forecast/types'

const REVENUE_FORECAST_KEY = 'revenueForecast'

export type RevenueForecastPayload = {
  [REVENUE_FORECAST_KEY]: RevenueForecastResponse | null
} & LoadingAndError

// REDUCERS
type RevenueForecastStoreData = {
  [REVENUE_FORECAST_KEY]: RevenueForecastPayload
}

export type RevenueForecastData = Readonly<RevenueForecastStoreData>

const initialCompanyFeaturesState: RevenueForecastData = {
  [REVENUE_FORECAST_KEY]: {
    [REVENUE_FORECAST_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const revenueForecastReducer = createReducer(
  initialCompanyFeaturesState,
  builder => {
    // GET REVENUE FORECAST DATA
    createThunkReducer(
      builder,
      getRevenueForecast.typePrefix,
      REVENUE_FORECAST_KEY,
      (state, action) =>
        ({
          ...state,
          [REVENUE_FORECAST_KEY]: {
            ...state[REVENUE_FORECAST_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [REVENUE_FORECAST_KEY]: action.payload,
          },
        } as RevenueForecastData),
    )
  },
)
