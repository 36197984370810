import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { BasicModal } from '../../../../../components/designSystem/organisms/BasicModal/BasicModal'
import { HandleAddMerchantFn } from '../TransactionsTable/TransactionsTable.types'

type Props = {
  handleAddMerchant: HandleAddMerchantFn
  handleClose: () => void
  isOpen: boolean
}

export const AddMerchantModal = ({
  handleAddMerchant,
  handleClose,
  isOpen,
}: Props): JSX.Element => {
  const [merchantName, setMerchantName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleAddClick = async (): Promise<void> => {
    setIsLoading(true)

    try {
      await handleAddMerchant(merchantName)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasicModal
      isOpen={isOpen}
      height={100}
      width={100}
      handleClose={handleClose}
      onDismiss={handleClose}
      buttonJustifyContent="center"
      renderContent={(): JSX.Element => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            height: '300px',
            justifyContent: 'center',
            justifyItems: 'center',
            padding: '25px 50px',
            width: '600px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Typography variant="h7">Add A New Merchant</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <TextField
              value={merchantName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setMerchantName(event.target.value)
              }
            />
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button color="inherit" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={handleAddClick}
                variant="contained"
                disabled={!merchantName.length || isLoading}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}
