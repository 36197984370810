import React from 'react'
import { format } from 'date-fns'
import { DashboardHeadingSection } from '../../../../../components/designSystem/organisms/DashboardHeadingSection/DashboardHeadingSection'
import { useCashPosition } from '../../../cashPosition/CashPositionProvider'

export const InsightsHeadingSection = (): JSX.Element => {
  const { endDate, hasSyncError, startDate, syncTimestamp } = useCashPosition()
  return (
    <DashboardHeadingSection
      heading="Insights"
      subHeading={`Data aggregated from ${format(
        startDate || 0,
        'MMMM d, y',
      )} to ${format(endDate || 0, 'MMMM d, y')}`}
      synced={syncTimestamp ? format(syncTimestamp || 0, 'M/d/y') : null}
      hasSyncError={hasSyncError}
    />
  )
}
