import React from 'react'
import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'

type ButtonType = 'primary' | 'secondary' | 'alert' | 'disabled'

const Button = styled('button', {
  shouldForwardProp: (prop: any): boolean => prop !== 'buttonType',
})<{ buttonType: ButtonType }>(
  ({ buttonType }) => `
  border: none;
  padding: 0.613rem 1.31rem;
  border-radius: 4px;
  font-family: var(--font-family);
  font-weight: var(--font-weight--medium);
  cursor: pointer;
  align-self: flex-start;

  &:active:enabled {
    opacity: 0.4;
  }

  &:disabled {
    color: ${Color.Disabled};
    background-color: ${Color.DisabledLight};
    cursor: default;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ${
    buttonType === 'primary'
      ? `
        background-color: ${Color.Green};
        color: ${Color.Black};
      `
      : ``
  }
  ${
    buttonType === 'secondary'
      ? `
        background-color: ${Color.White};
        color: ${Color.DarkBlue};
      `
      : ``
  }
  ${
    buttonType === 'alert'
      ? `
        background-color: ${Color.Red};
        color: ${Color.Black};
      `
      : ``
  }
  ${
    buttonType === 'disabled'
      ? `
      background-color: ${Color.DisabledLight};
      color: ${Color.Disabled};
      `
      : ``
  }
`,
)

type Props = {
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void
  text: string
  buttonType: ButtonType
  to?: string
  disabled?: boolean
  // https://github.com/couds/react-bulma-components/issues/230
  // Typescript doesn't believe in submit in this version
  type?: 'button' | 'submit' | 'reset'
}

export const ActionButton: React.FunctionComponent<
  Props & React.ButtonHTMLAttributes<HTMLElement>
> = ({ onClick, text, to, disabled, buttonType, ...buttonProps }) => {
  return (
    <Button
      {...buttonProps}
      buttonType={disabled ? 'disabled' : buttonType}
      onClick={onClick}
      disabled={disabled}
    >
      {to ? <a href={to}>{text}</a> : text}
    </Button>
  )
}
