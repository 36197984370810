import * as Sentry from '@sentry/browser'

import { BootstrapData } from '../../../server/routes/types'
import { Thunk } from '../../store'
import { setAnalyticsUser } from '../../lib/analytics'
import { setCompany } from '../companies'
import { setOwnConnectionsCount } from '../connectors'
import { setUser } from '../user'

// ACTIONS

const PENDING = 'bootstrap/PENDING'
const SUCCESS = 'bootstrap/SUCCESS'
const FAILURE = 'bootstrap/FAILURE'

type Actions = PendingAction | SuccessAction | FailureAction

type PendingAction = {
  type: typeof PENDING
}
type SuccessAction = {
  type: typeof SUCCESS
}
type FailureAction = {
  type: typeof FAILURE
  payload: Error
}
function pending(): PendingAction {
  return {
    type: PENDING,
  }
}
function success(): SuccessAction {
  return {
    type: SUCCESS,
  }
}
function failure(error: Error): FailureAction {
  return {
    type: FAILURE,
    payload: error,
  }
}

export function bootstrap(): Thunk {
  return async (dispatch): Promise<void> => {
    dispatch(pending())

    try {
      // Get initial data from server render
      const data: BootstrapData = (window as any).__INITIAL_DATA__
      Sentry.init(data.integrations.sentry)
      setAnalyticsUser({
        ...data.userInfo,
        ...(data?.company ? { company: data.company } : {}),
        connectionCount: data.connectionsCount,
        activeConnectionCount: data.activeConnectionsCount,
      })
      dispatch(setUser(data.userInfo, data.shopifyInfo))
      dispatch(
        setCompany({
          id: data.company.id,
          name: data.company.name,
          industry: data.company.industry,
        }),
      )
      dispatch(setOwnConnectionsCount(data.ownConnectionsCount))

      dispatch(success())
    } catch (error: any) {
      dispatch(failure(error))
    }
  }
}

// REDUCERS

export type StoreBootstrap = Readonly<{
  data: {
    isDone: boolean
    error: Error | null
  }
}>

const initialState: StoreBootstrap = {
  data: {
    isDone: false,
    error: null,
  },
}

export function bootstrapReducer(
  state: StoreBootstrap = initialState,
  action: Actions,
): StoreBootstrap {
  switch (action.type) {
    case PENDING:
      return {
        data: {
          isDone: false,
          error: null,
        },
      }
    case SUCCESS:
      return {
        data: {
          isDone: true,
          error: null,
        },
      }
    case FAILURE:
      return {
        data: {
          isDone: true,
          error: action.payload,
        },
      }
    default:
      return state
  }
}
