import React, { useState } from 'react'
import {
  AdminDashboardCompany,
  AdminDashboardCompanyFeature,
  AdminDashboardSubscription,
  AdminDashboardUser,
} from '../../../server/services/adminDashboard'
import {
  DataGridPro,
  GridCellEditStartParams,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridColDef,
  GridCsvExportMenuItem,
  GridEditSingleSelectCell,
  GridFilterModel,
  GridRowsProp,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { DashboardGridPanelDetail } from './DashboardGridPanelDetail'
import Button from '@mui/material/Button'
import { format, parseISO } from 'date-fns'
import { dispatch } from '../../store'
import {
  deleteCompany,
  deleteCompanyPortfolio,
  loginAdminUser,
  updateCompanyIcpAt,
  updateCompanyPlanType,
} from '../../modules/adminDashboard/adminDashboard'
import { BasicModal } from '../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { Banner } from '../../components/designSystem/organisms/Banner/Banner'
import { TextInput } from '../../components/designSystem/atoms/TextInput/TextInput'
import Stack from '@mui/material/Stack'
import { Feature, SubscriptionPlan } from '../../../server/data/models'
import Checkbox from '@mui/material/Checkbox'
import {
  COMPANY_PLAN_TYPE_OPTIONS,
  CompanyRelationType,
  CompanyToAddRelation,
} from './types'
import { CreateUserModal } from './modals/CreateUserModal'
import { CreatePortfolioModal } from './modals/CreatePortfolioModal'
import { CreateFeatureModal } from './modals/CreateFeatureModal'
import { DeleteFeatureModal } from './modals/DeleteFeatureModal'
import { CreateSubscriptionModal } from './modals/CreateSubscriptionModal'
import { DeleteSubscriptionModal } from './modals/DeleteSubscriptionModal'
import { CompanySummary } from './modals/CompanySummary'

interface Props {
  companies: AdminDashboardCompany[]
  features: Feature[]
  subscriptionPlans: SubscriptionPlan[]
}

const csvOptions = { delimiter: ',' }

const CustomToolbar = (props: any): JSX.Element => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton {...props} />
    <GridToolbarFilterButton {...props} />
    <GridToolbarDensitySelector {...props} />
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  </GridToolbarContainer>
)

const CustomPlanTypeEditComponent = (props: any): JSX.Element => {
  const { setRows, ...other } = props

  const handleValueChange = (): void => {
    setRows((prevRows: any) => {
      return prevRows.map((row: any) => {
        return row.id === props.id ? { ...row, planType: '' } : row
      })
    })
  }

  return (
    <GridEditSingleSelectCell onValueChange={handleValueChange} {...other} />
  )
}

export const DashboardGrid = (props: Props): JSX.Element => {
  const editingRow: any = React.useRef(null)
  const [
    featureToDelete,
    setFeatureToDelete,
  ] = useState<AdminDashboardCompanyFeature | null>(null)
  const [
    subscriptionToDelete,
    setSubscriptionToDelete,
  ] = useState<AdminDashboardSubscription | null>(null)
  const [filterOptions, setFilterOptions] = useState<any>(undefined)
  const [sortOptions, setSortOptions] = useState<any>(undefined)

  const [
    companyToAddRelation,
    setCompanyToAddRelation,
  ] = useState<CompanyToAddRelation | null>(null)

  const [deleteConfirmationText, setDeleteConfirmationText] = useState<string>(
    '',
  )

  const { companies, features, subscriptionPlans } = props

  const [initialCompanies, setCompanies] = useState<AdminDashboardCompany[]>(
    companies,
  )

  const filterModelChange = (params: GridFilterModel): void => {
    setFilterOptions(params)
  }

  const onSortModelChange = (params: GridSortModel): void => {
    setSortOptions(params)
  }

  const handleResetFilterClicked = (): void => {
    window.location.reload()
  }

  const handleDeleteCompanyFeatureClicked = (
    feature: AdminDashboardCompanyFeature,
  ): void => {
    setFeatureToDelete(feature)
  }

  const handleDeleteCompanySubscriptionClicked = (
    subscription: AdminDashboardSubscription,
  ): void => {
    setSubscriptionToDelete(subscription)
  }

  const canDeleteCompany = deleteConfirmationText
    .toLocaleLowerCase()
    .startsWith('delete')

  const handleFeatureDeleteModalCancelClicked = (): void => {
    setFeatureToDelete(null)
  }

  const handleSubscriptionDeleteModalCancelClicked = (): void => {
    setSubscriptionToDelete(null)
  }

  const handleFeatureDeleteConfirmClicked = (): void => {
    setCompanies(companies =>
      companies.map(company => {
        if (company.id !== featureToDelete?.companyId) {
          return company
        }

        return {
          ...company,
          companyFeatures: company.companyFeatures.filter(
            companyFeature => companyFeature.id !== featureToDelete.id,
          ),
        }
      }),
    )
    setFeatureToDelete(null)
  }

  const handleSubscriptionDeleteConfirmClicked = (): void => {
    setCompanies(companies =>
      companies.map(company => {
        if (company.id !== subscriptionToDelete?.companyId) {
          return company
        }

        // use the subscription plan name to filter because we're using a placeholder id
        return {
          ...company,
          subscriptions: company.subscriptions.filter(
            subscription =>
              subscription.plan.name !== subscriptionToDelete.plan.name,
          ),
        }
      }),
    )
    setSubscriptionToDelete(null)
  }

  const handleLoginButtonClicked = (login: string): void => {
    dispatch(loginAdminUser(login))

    // HACKY stuff, could actually implement redux
    // but this is just for internal users
    window.localStorage.removeItem('cashPositionFilters')
    window.localStorage.removeItem('transactionAccounts')
    setTimeout(resolve => {
      window.open('/connectors', '_blank')
      resolve()
    }, 2000)
  }

  const getAppUninstallLabelFromSource = (
    source: 'shopify' | 'app' | 'gdpr',
  ) => {
    switch (source) {
      case 'shopify':
        return 'SHOPIFY APP UNINSTALLED'
      case 'app':
        return 'REQUESTED DELETION'
      default:
        return ''
    }
  }

  const initialRows: GridRowsProp = initialCompanies.map(company => {
    const adminUserRegex = new RegExp(/app-access.*@brightflow.ai/)
    const adminUser = company.users.find(user =>
      adminUserRegex.test(user.email),
    )

    const nonAdminUsers = company.users.filter(
      user => !adminUserRegex.test(user.email),
    )

    return {
      login: adminUser?.email ?? '',
      id: company.id,
      name: company.name,
      accountStatus: company.appUninstallEvents
        .filter(
          (companyEvent, index, self) =>
            !self
              .slice(index + 1)
              .some(
                event => event.payload.source === companyEvent.payload.source,
              ),
        )
        .map(event => getAppUninstallLabelFromSource(event.payload.source))
        .join(', '),
      createdAt: format(
        parseISO(new Date(company.createdAt).toISOString()),
        'yyyy-MM-dd',
      ),
      shopName: company.shopName,
      companyUrl: company.companyUrl,
      invalidConnections: company.invalidConnections,
      validConnections: company.validConnections,
      subscriptionPlan: company.subscriptions?.[0]?.plan?.name,
      planType: company.planType,
      creditScore: company.creditScore,
      activeFeatureFlags: company.companyFeatures
        .map(feature => feature.label)
        .join(', '),
      users: nonAdminUsers.map(user => user.email).join(', '),
      userFullNames: nonAdminUsers
        .map(user =>
          user.firstName || user.lastName
            ? `${user.firstName} ${user.lastName}`
            : '',
        )
        .join(', '),
      icpAt: company.icpAt,
      portfolios: company.portfolios.join(', '),
    }
  })

  const [rows, setRows] = React.useState(initialRows)

  const handleDeletePortfolioClicked = ({
    companyId,
    portfolioName,
  }: {
    companyId: string
    portfolioName: string
  }): void => {
    setCompanies(companies =>
      companies.map(company => {
        if (company.id !== companyId) {
          return company
        }

        return {
          ...company,
          portfolios: company.portfolios.filter(
            portfolio => portfolio !== portfolioName,
          ),
        }
      }),
    )

    // really dumb that we have two divergent pieces of state here
    // for rows / companies
    // TODO fix this crap (that I wrote)
    setRows(rows =>
      rows.map(row => {
        if (row.id !== companyId) {
          return row
        }

        const portfolioArray: string[] = row.portfolios
          .split(', ')
          .filter(
            (portfolio: string) => !!portfolio && portfolio !== portfolioName,
          )

        return {
          ...row,
          portfolios: portfolioArray.length ? portfolioArray.join(', ') : '',
        }
      }),
    )

    dispatch(
      deleteCompanyPortfolio({
        companyId,
        portfolioName,
      }),
    )
  }

  const handleCheckboxChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: any,
  ): void => {
    setRows((prevRows: any) => {
      return prevRows.map((row: any) => {
        return row.id === props.id
          ? {
              ...row,
              icpAt: props.row?.icpAt ? null : new Date().toISOString(),
            }
          : row
      })
    })

    dispatch(updateCompanyIcpAt(props.id))
  }

  const columns: GridColDef[] = [
    {
      field: 'login',
      headerName: 'Login',
      width: 100,
      // eslint-disable-next-line react/display-name
      renderCell: (params: any): JSX.Element => (
        <Button
          variant="contained"
          size="small"
          disabled={!params?.row?.login}
          onClick={(): void => handleLoginButtonClicked(params?.row?.login)}
        >
          Login
        </Button>
      ),
    },
    {
      field: 'icpAt',
      headerName: 'ICP',
      width: 100,
      // eslint-disable-next-line react/display-name
      renderCell: (params: any): JSX.Element => (
        <>
          <Checkbox
            checked={!!params?.row?.icpAt}
            onChange={(e): void => handleCheckboxChanged(e, params)}
          />
          <p>
            {params?.row?.icpAt
              ? format(
                  parseISO(new Date(params?.row?.icpAt).toISOString()),
                  'MM/yyyy',
                )
              : null}
          </p>
        </>
      ),
    },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'shopName', headerName: 'Shop Name', width: 250 },
    { field: 'companyUrl', headerName: 'Company URL', width: 250 },
    { field: 'id', headerName: 'BrightFlow ID', width: 300 },
    { field: 'accountStatus', headerName: 'Account Status', width: 100 },
    {
      field: 'creditScore',
      headerName: 'Credit Score',
      type: 'number',
      width: 120,
    },
    {
      field: 'subscriptionPlan',
      headerName: 'Subscription Plan',
      width: 150,
    },
    {
      field: 'planType',
      headerName: 'Company Plan',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['', ...COMPANY_PLAN_TYPE_OPTIONS],
      // eslint-disable-next-line react/display-name
      renderEditCell: (params: any): JSX.Element => (
        <CustomPlanTypeEditComponent setRows={setRows} {...params} />
      ),
    },
    {
      field: 'portfolios',
      headerName: 'Portfolios',
      width: 150,
    },
    {
      field: 'users',
      headerName: 'Users',
      width: 200,
    },
    {
      field: 'userFullNames',
      headerName: 'User Names',
      width: 200,
    },
    { field: 'createdAt', headerName: 'Created at', width: 100 },
    {
      field: 'invalidConnections',
      headerName: 'Invalid connections',
      width: 250,
    },
    {
      field: 'validConnections',
      headerName: 'Valid Connections',
      width: 250,
    },
    {
      field: 'activeFeatureFlags',
      headerName: 'Feature Flags',
      width: 200,
    },
  ]

  const handleCellEditStart = (
    params: GridCellEditStartParams<any, any, any>,
  ): void => {
    editingRow.current = rows.find(row => row.id === params.id)
  }

  const handleCellEditStop = (
    params: GridCellEditStopParams<any, any, any>,
  ): void => {
    if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
      setRows(prevRows =>
        prevRows.map(row =>
          row.id === editingRow?.current?.id
            ? { ...row, planType: editingRow?.current?.planType }
            : row,
        ),
      )
    }
  }

  // fires after user clicks out of cell
  // that's when we make the API call
  const processRowUpdate = (newRow: any): any => {
    dispatch(
      updateCompanyPlanType({
        companyId: newRow.id,
        planType: newRow.planType,
      }),
    )

    setRows(prevRows =>
      prevRows.map(row => (row.id === editingRow?.current?.id ? newRow : row)),
    )

    return newRow
  }

  const handleCancelAddCompanyRelationClicked = (): void => {
    setDeleteConfirmationText('')
    setCompanyToAddRelation(null)
  }

  const handleAddCompanyRelationClicked = (
    companyToAddRelation: CompanyToAddRelation,
  ): void => {
    setCompanyToAddRelation(companyToAddRelation)
  }

  const handleAddFeatureCreateButtonPressed = (
    newFeature: AdminDashboardCompanyFeature,
  ): void => {
    setCompanies(companies =>
      companies.map(company => {
        return {
          ...company,
          companyFeatures: [...company.companyFeatures, newFeature],
        }
      }),
    )
    setCompanyToAddRelation(null)
  }

  const handleAddSubscriptionButtonPressed = (
    newSubscription: AdminDashboardSubscription,
  ): void => {
    setCompanies(companies =>
      companies.map(company => {
        return {
          ...company,
          subscriptions: [...company.subscriptions, newSubscription],
        }
      }),
    )
    setCompanyToAddRelation(null)
  }

  const handleAddPortfolioButtonClicked = (newPortfolio: string): void => {
    setCompanies(companies =>
      companies.map(company => {
        if (company.id !== companyToAddRelation?.company?.id) {
          return company
        }

        return {
          ...company,
          portfolios: [...company.portfolios, newPortfolio],
        }
      }),
    )

    // really dumb that we have two divergent pieces of state here
    // for rows / companies
    // TODO fix this crap (that I wrote)
    setRows(rows =>
      rows.map(row => {
        if (row.id !== companyToAddRelation?.company?.id) {
          return row
        }

        const portfolioArray: string[] = row.portfolios
          .split(', ')
          .filter((portfolio: string) => !!portfolio)
        portfolioArray.push(newPortfolio)
        return {
          ...row,
          portfolios: portfolioArray.join(', '),
        }
      }),
    )
    setCompanyToAddRelation(null)
  }

  const handleAddUserCreateButtonPressed = (
    newUser: AdminDashboardUser,
  ): void => {
    setCompanies(companies =>
      companies.map(company => {
        if (company.id !== companyToAddRelation?.company.id) {
          return company
        }
        return {
          ...company,
          users: [...company.users, newUser],
        }
      }),
    )
    setCompanyToAddRelation(null)
  }

  const handleCompanyDelete = (): void => {
    if (companyToAddRelation) {
      dispatch(
        deleteCompany({
          companyId: companyToAddRelation.company.id,
        }),
      )
      setCompanyToAddRelation(null)
      window.location.reload()
    }
  }

  return (
    <div className={'fs-exclude'}>
      {companyToAddRelation?.type === CompanyRelationType.features && (
        <CreateFeatureModal
          features={features}
          companyToAddRelation={companyToAddRelation}
          onCancel={handleCancelAddCompanyRelationClicked}
          onCreate={handleAddFeatureCreateButtonPressed}
        />
      )}
      {featureToDelete && (
        <DeleteFeatureModal
          feature={featureToDelete}
          onCancel={handleFeatureDeleteModalCancelClicked}
          onRemove={handleFeatureDeleteConfirmClicked}
        />
      )}
      {companyToAddRelation?.type === CompanyRelationType.users && (
        <CreateUserModal
          companyToAddRelation={companyToAddRelation}
          onCancel={handleCancelAddCompanyRelationClicked}
          onCreate={handleAddUserCreateButtonPressed}
        />
      )}
      {companyToAddRelation?.type === CompanyRelationType.portfolios && (
        <CreatePortfolioModal
          onCancel={handleCancelAddCompanyRelationClicked}
          companyToAddRelation={companyToAddRelation}
          onCreate={handleAddPortfolioButtonClicked}
        />
      )}
      {companyToAddRelation?.type === CompanyRelationType.deletion && (
        <BasicModal contentWidth="500px">
          <Stack spacing={2}>
            <h5>Delete Company</h5>
            <CompanySummary companyToAddRelation={companyToAddRelation} />
            <Banner bannerType="alert">
              Permanently deleting will remove this company, including all
              subscriptions, connections, users, features and jobs. Access will
              be removed instantly.
            </Banner>
            <p>{`To permanently delete this company, type delete in the field below.`}</p>
            <TextInput
              value={deleteConfirmationText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                setDeleteConfirmationText(e.target.value)
              }
            />
            <Stack direction="row" justifyContent="space-evenly">
              <Button
                style={{ width: '45%' }}
                variant={'outlined'}
                color={'primary'}
                onClick={handleCancelAddCompanyRelationClicked}
              >
                Cancel
              </Button>
              <Button
                style={{ width: '45%' }}
                variant={'contained'}
                color={'error'}
                onClick={(): void => handleCompanyDelete()}
                disabled={!canDeleteCompany}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </BasicModal>
      )}
      {companyToAddRelation?.type === CompanyRelationType.subscriptions && (
        <CreateSubscriptionModal
          subscriptionPlans={subscriptionPlans}
          companyToAddRelation={companyToAddRelation}
          onCancel={handleCancelAddCompanyRelationClicked}
          onCreate={handleAddSubscriptionButtonPressed}
        />
      )}
      {subscriptionToDelete && (
        <DeleteSubscriptionModal
          subscription={subscriptionToDelete}
          onCancel={handleSubscriptionDeleteModalCancelClicked}
          onRemove={handleSubscriptionDeleteConfirmClicked}
        />
      )}
      <div style={{ height: '80vh', width: '95vw' }}>
        <>
          <Button onClick={(): void => handleResetFilterClicked()}>
            Reset filters
          </Button>
          <DataGridPro
            getDetailPanelHeight={(): number | 'auto' => 'auto'}
            getDetailPanelContent={({ row }: { row: any }): JSX.Element => (
              <DashboardGridPanelDetail
                company={
                  initialCompanies.find(
                    company => company.id === row.id,
                  ) as AdminDashboardCompany
                }
                deleteCompanyFeature={handleDeleteCompanyFeatureClicked}
                deleteCompanySubscription={
                  handleDeleteCompanySubscriptionClicked
                }
                handleAddCompanyRelationClicked={
                  handleAddCompanyRelationClicked
                }
                deletePortfolio={handleDeletePortfolioClicked}
              />
            )}
            onSortModelChange={onSortModelChange}
            sortModel={sortOptions}
            filterModel={filterOptions}
            onFilterModelChange={filterModelChange}
            rows={rows}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </>
      </div>
    </div>
  )
}
