import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { SxProps, Theme } from '@mui/material'

const ALL_SELECTED_VALUE = 'all'

interface Option {
  id: string
  name: string
  isSelected?: boolean
}

interface Props {
  title: string
  options: Option[]
  onChange: (values: string[]) => void
  enableDelete?: boolean
  allowEmptyOptions?: boolean
  sx?: SxProps<Theme>
}

export const MultiSelect: React.FunctionComponent<Props> = ({
  title,
  options,
  onChange,
  enableDelete = true,
  allowEmptyOptions = true,
  sx,
}) => {
  const selectedOptions = options
    .filter(option => option.isSelected)
    .map(option => option.id)

  const isAllSelected = selectedOptions.length === options.length

  const allValues = options.map(option => option.id)

  // Sending the first item by default if not allowing empty selections
  const cleanValues = (): string[] => (allowEmptyOptions ? [] : [allValues[0]])

  const handleChange = (
    event: SelectChangeEvent<typeof selectedOptions>,
  ): void => {
    const {
      target: { value },
    } = event
    const valueToSave = typeof value === 'string' ? value.split(',') : value
    if (valueToSave.includes(ALL_SELECTED_VALUE)) {
      onChange(isAllSelected ? cleanValues() : allValues)
      return
    }

    if (!valueToSave.length && !allowEmptyOptions) {
      return
    }

    onChange(valueToSave)
  }

  const handleDelete = (valueToDelete: string): void => {
    const updatedValues = selectedOptions.filter(item => item !== valueToDelete)

    if (!updatedValues.length && !allowEmptyOptions) return
    onChange(updatedValues)
  }

  return (
    <FormControl>
      <InputLabel id="multi-select-label">{title}</InputLabel>
      <Select
        labelId="multi-select-label"
        label={title}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected): JSX.Element => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip
                key={value}
                label={options.find(option => option.id === value)?.name}
                onDelete={
                  enableDelete ? (): void => handleDelete(value) : undefined
                }
                onMouseDown={(event): void => event.stopPropagation()}
              />
            ))}
          </Box>
        )}
        sx={sx}
      >
        {options.length > 1 && (
          <MenuItem value={ALL_SELECTED_VALUE}>
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={'All'} />
          </MenuItem>
        )}
        {options.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selectedOptions.indexOf(id) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
