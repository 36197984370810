import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  GridRowId,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { DateRange } from '@mui/x-date-pickers-pro'

import {
  UpdatedLabelInput,
  useCreateOrFindCompnayMerchantMutation,
  useUpdateTransactionLabelsMutation,
} from '../../../../../../server/app-data-service/generatedTypes'

import { NumericCriteriaOperators } from '../../../../../components/designSystem/molecules/NumericCriteriaInput/NumberCriteriaInput.types'

import { DefaultEndDate, DefaultStartDate } from '../../Transactions.consts'
import { Category, Merchant, UrlAttributes } from '../../Transactions.types'
import {
  decodeAttributeHash,
  encodeAttributeHash,
} from '../../Transactions.utils'

import { NumericOperatorToFilterOperatorMap } from './TransactionsTable.consts'
import {
  SnackBarState,
  TransactionsTableHook,
  TransactionsTableHookProps,
} from './TransactionsTable.types'
import { BlankLabelOrName } from '../../../../../../server/app-data-service/cash-position/cash-position.consts'

export const useTransactionsTable = ({
  refetchMerchants,
  setDateRange,
  setSelectedAccounts,
}: TransactionsTableHookProps): TransactionsTableHook => {
  const apiRef = useGridApiRef()
  const history = useHistory()
  const [isAddMerchantOpen, setIsAddMerchantOpen] = useState(false)
  const [addMerchantRows, setAddMerchantRows] = useState<GridValidRowModel[]>(
    [],
  )
  const [snackBarState, setSnackBarState] = useState<SnackBarState>('Closed')

  const [updateTransactionLabelsMutation] = useUpdateTransactionLabelsMutation()
  const [
    createOrFindCompnayMerchantMutation,
  ] = useCreateOrFindCompnayMerchantMutation()

  const { attrHash } = useParams<{ attrHash?: string }>()
  const urlAttributes = useMemo(
    () =>
      attrHash
        ? decodeAttributeHash(attrHash)
        : {
            sd: DefaultStartDate,
            ed: DefaultEndDate,
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // leaving this empty so we only evaluate the url attributes on the initial render
  )

  const [filters, setFilters] = useState<UrlAttributes>(urlAttributes)
  const [descriptionFilterWidth, setDescriptionFilterWidth] = useState<number>(
    0,
  )

  const [selectedRows, setSelectedRows] = useState<
    Map<GridRowId, GridValidRowModel>
  >(new Map())

  useEffect(() => {
    const { amt, cat, desc, conf, merch } = urlAttributes

    if (amt) {
      apiRef.current.upsertFilterItem({
        columnField: 'amountCents',
        id: 'amountCents',
        operatorValue: NumericOperatorToFilterOperatorMap[amt.op],
        value: amt.val,
      })
    }

    if (cat) {
      apiRef.current.upsertFilterItem({
        columnField: 'categoryId',
        id: 'categoryId',
        operatorValue: 'isAnyOf',
        value: cat,
      })
    }

    if (desc) {
      apiRef.current.upsertFilterItem({
        columnField: 'description',
        id: 'description',
        operatorValue: 'contains',
        value: desc,
      })
    }

    if (merch) {
      apiRef.current.upsertFilterItem({
        columnField: 'merchantName',
        id: 'merchantName',
        operatorValue: merch === BlankLabelOrName ? 'isEmpty' : 'equals',
        value: merch,
      })
    }
  }, [apiRef, urlAttributes])

  const handleSelectionModelChange = (): void => {
    setSelectedRows(apiRef.current.getSelectedRows())
  }

  const handleAccountFilterChange = (values: string[]): void => {
    setSelectedAccounts(values)
  }

  const handleAmountFilterChange = (
    operator: NumericCriteriaOperators,
    value: string,
  ): void => {
    apiRef.current.upsertFilterItem({
      columnField: 'amountCents',
      id: 'amountCents',
      operatorValue: NumericOperatorToFilterOperatorMap[operator],
      value: value,
    })
  }

  const handleCategoryFilterChange = (values: Category[]): void => {
    apiRef.current.upsertFilterItem({
      columnField: 'categoryId',
      id: 'categoryId',
      operatorValue: 'isAnyOf',
      value: values.map(({ id }) => id),
    })
  }

  const handleDateRangeFilterChange = (value: DateRange<Date | null>): void => {
    // only want to make a request when both values are set
    if ((!value[0] && value[1]) || (value[0] && !value[1])) return

    const startDate = value[0] ? value[0].toISOString() : null
    const endDate = value[1] ? value[1].toISOString() : null

    setDateRange({ startDate, endDate })
  }

  const handleDescriptionFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    apiRef.current.upsertFilterItem({
      columnField: 'description',
      id: 'description',
      operatorValue: 'contains',
      value: event.target.value,
    })
  }

  const handleExportData = (): void => {
    apiRef.current.exportDataAsCsv()
  }

  const handleMerchantFilterChange = (value: Merchant | null): void => {
    apiRef.current.upsertFilterItem({
      columnField: 'merchantName',
      id: 'merchantName',
      operatorValue:
        value?.merchantName === BlankLabelOrName ? 'isEmpty' : 'equals',
      value: value?.merchantName || null,
    })
  }

  const handleUpdateRow = async (
    id: GridRowId,
    field: string,
    newValue: Category | Merchant | null,
    row: UpdatedLabelInput,
  ): Promise<void> => {
    if (!newValue) return

    const isCategoryUpdate = 'id' in newValue

    const transactions = isCategoryUpdate
      ? [
          {
            id: row.id,
            categoryId: newValue.id,
            categoryLabel: newValue.label,
            merchantId: row.merchantId,
            merchantName: row.merchantName,
            accountId: row.accountId,
            transactionDate: row.transactionDate,
            platformId: row.platformId,
          },
        ]
      : [
          {
            id: row.id,
            categoryId: row.categoryId,
            categoryLabel: row.categoryLabel,
            merchantId: newValue.merchantId,
            merchantName: newValue.merchantName,
            accountId: row.accountId,
            transactionDate: row.transactionDate,
            platformId: row.platformId,
          },
        ]

    try {
      await updateTransactionLabelsMutation({
        variables: {
          transactions,
        },
      })

      apiRef.current.setEditCellValue({
        id,
        field,
        value: isCategoryUpdate ? newValue.id : newValue.merchantName,
      })
      apiRef.current.setCellMode(id, field, 'view')
      setSnackBarState('Success')
    } catch {
      setSnackBarState('Error')
    }
  }

  const handleBulkUpdate = async (
    newValue: Category | Merchant,
    rows: UpdatedLabelInput[],
  ): Promise<void> => {
    const isCategoryUpdate = 'id' in newValue

    const transactions = rows.map(
      ({
        id,
        categoryId,
        categoryLabel,
        merchantId,
        merchantName,
        accountId,
        transactionDate,
        platformId,
      }) => {
        return isCategoryUpdate
          ? {
              id,
              categoryId: newValue.id,
              categoryLabel: newValue.label,
              merchantId,
              merchantName,
              accountId,
              transactionDate,
              platformId,
            }
          : {
              id,
              categoryId,
              categoryLabel,
              merchantId: newValue.merchantId,
              merchantName: newValue.merchantName,
              accountId,
              transactionDate,
              platformId,
            }
      },
    )

    try {
      await updateTransactionLabelsMutation({
        variables: {
          transactions,
        },
      })

      apiRef.current.updateRows(transactions)
      apiRef.current.setSelectionModel([])
      setSnackBarState('Success')
    } catch {
      setSnackBarState('Error')
      throw new Error('unable to perform bulk action')
    }
  }

  const handleOpenAddMerchantModal = (row?: GridValidRowModel): void => {
    if (row) {
      setAddMerchantRows([row])
    } else {
      setAddMerchantRows(Array.from(selectedRows.values()))
    }
    setIsAddMerchantOpen(true)
  }

  const handleCloseAddMerchantModal = (): void => {
    setAddMerchantRows([])
    setIsAddMerchantOpen(false)
  }

  const handleAddMerchant = async (merchantName: string): Promise<void> => {
    const { data } = await createOrFindCompnayMerchantMutation({
      variables: {
        merchantName,
      },
    })

    if (!data) return setIsAddMerchantOpen(false)

    const transactions = addMerchantRows.map(
      ({
        id,
        categoryId,
        categoryLabel,
        accountId,
        transactionDate,
        platformId,
      }) => ({
        id,
        categoryId,
        categoryLabel,
        accountId,
        transactionDate,
        merchantId: data?.createOrFindCompanyMerchant?.id,
        merchantName: data?.createOrFindCompanyMerchant?.merchantName,
        platformId,
      }),
    )

    try {
      await updateTransactionLabelsMutation({
        variables: {
          transactions,
        },
      })
      await refetchMerchants()
      apiRef.current.updateRows(transactions)
      setIsAddMerchantOpen(false)
      setSnackBarState('Success')
    } catch {
      setSnackBarState('Error')
    }
  }

  return {
    apiRef,
    handleAddMerchant,
    handleAccountFilterChange,
    handleAmountFilterChange,
    handleBulkUpdate,
    handleCategoryFilterChange,
    handleCloseAddMerchantModal,
    handleDateRangeFilterChange,
    handleDescriptionFilterChange,
    handleExportData,
    handleMerchantFilterChange,
    handleOpenAddMerchantModal,
    handleSelectionModelChange,
    handleUpdateRow,
    isAddMerchantOpen,
    selectedRows,
    setSnackBarState,
    snackBarState,
    urlAttributes,
    setDescriptionFilterWidth,
    descriptionFilterWidth,
  }
}
