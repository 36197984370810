import {
  UPSERT_FAILURE,
  UPSERT_PENDING,
  UPSERT_SUCCESS,
  UpsertFailureAction,
  UpsertPendingAction,
  UpsertSuccessAction,
} from './upsert'

export { upsertCompany } from './upsert'

// ACTIONS

const SET = 'company/SET'
const CLEAR = 'company/CLEAR'

type Actions =
  | SetAction
  | ClearAction
  | UpsertFailureAction
  | UpsertPendingAction
  | UpsertSuccessAction

type SetAction = {
  type: typeof SET
  payload: CompanyData
}

type ClearAction = {
  type: typeof CLEAR
}

export function setCompany(company: CompanyData): SetAction {
  return {
    type: SET,
    payload: company,
  }
}

export function clearCompany(): ClearAction {
  return {
    type: CLEAR,
  }
}

// REDUCERS

export type CompanyData = {
  id: string
  name: string
  industry: string
}

type CompanyStoreData = {
  data: CompanyData
  upsert: {
    isLoading: boolean
    error: Error | null
  }
}
export type StoreCompany = Readonly<CompanyStoreData>

const initialState: StoreCompany = {
  data: {
    id: '',
    name: '',
    industry: '',
  },
  upsert: {
    isLoading: false,
    error: null,
  },
}

export function companies(
  state: StoreCompany = initialState,
  action: Actions,
): StoreCompany {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case CLEAR:
      return initialState

    case UPSERT_PENDING:
      return {
        ...state,
        upsert: {
          ...state.upsert,
          isLoading: true,
          error: null,
        },
      }

    case UPSERT_FAILURE:
      return {
        ...state,
        upsert: {
          ...state.upsert,
          isLoading: false,
          error: action.payload,
        },
      }

    case UPSERT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          id: action.payload.companyId || '',
          name: action.payload.name || '',
          industry: action.payload.industry || '',
        },
        upsert: {
          ...state.upsert,
          isLoading: false,
          error: null,
        },
      }

    default:
      return state
  }
}
