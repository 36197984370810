import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { Color } from '../../../Color'
import {
  commaDelimitedThousands,
  numberAsMoney,
} from '../../../util/displayValue'

const Container = styled('ul')`
  display: flex;
  justify-content: space-evenly;
  max-width: 1024px;
  padding: 0;
`

const Indicator = styled('li')(
  ({ theme }) => `
  border-radius: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
  border-radius: 3px;
  border: 1px solid ${Color.Gray};
  display: flex;
  flex-direction: column;
  min-height: 10.5ch;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 15px 30px;
  text-align: flex-start;
  min-width: 24%;

  /* No padding on screen widths less than 600px */
  ${theme.breakpoints.down('sm')} {
    justify-content: flex-end;
    padding: 0;
  }
`,
)

const RevenueIndicator: React.FunctionComponent<{
  intervalLabel: string
  revenueCents: number | null
}> = ({ intervalLabel, revenueCents }) => {
  return (
    <Indicator>
      <Typography variant="body2">Order Revenue {intervalLabel}</Typography>
      <Typography variant="h5">{numberAsMoney(revenueCents, true)}</Typography>
    </Indicator>
  )
}

const UnitVolumeIndicator: React.FunctionComponent<{
  intervalLabel: string
  unitVolume: number | null
}> = ({ intervalLabel, unitVolume }) => (
  <Indicator>
    <Typography variant="body2">Units Sold {intervalLabel}</Typography>
    <Typography variant="h5">{commaDelimitedThousands(unitVolume)}</Typography>
  </Indicator>
)

const OrderVolumeIndicator: React.FunctionComponent<{
  intervalLabel: string
  orderVolume: number | null
}> = ({ intervalLabel, orderVolume }) => (
  <Indicator>
    <Typography variant="body2">Total Orders {intervalLabel}</Typography>
    <Typography variant="h5">{commaDelimitedThousands(orderVolume)}</Typography>
  </Indicator>
)

const AverageOrderValueIndicator: React.FunctionComponent<{
  intervalLabel: string
  averageOrderValueCents: number | null
}> = ({ intervalLabel, averageOrderValueCents }) => (
  <Indicator>
    <Typography variant="body2">Average Order Value {intervalLabel}</Typography>
    <Typography variant="h5">
      {numberAsMoney(averageOrderValueCents, true)}
    </Typography>
  </Indicator>
)

export const RevenueIndicators: React.FunctionComponent<{
  interval: string
  revenueCents: number | null
  unitVolume: number | null
  orderVolume: number | null
  averageOrderValueCents: number | null
}> = ({
  interval,
  revenueCents,
  unitVolume,
  orderVolume,
  averageOrderValueCents,
}) => {
  const intervalLabel = interval === 'day' ? 'Yesterday' : 'this Month'

  return (
    <Container>
      <RevenueIndicator
        intervalLabel={intervalLabel}
        revenueCents={revenueCents}
      />
      <UnitVolumeIndicator
        intervalLabel={intervalLabel}
        unitVolume={unitVolume}
      />
      <OrderVolumeIndicator
        intervalLabel={intervalLabel}
        orderVolume={orderVolume}
      />
      <AverageOrderValueIndicator
        intervalLabel={intervalLabel}
        averageOrderValueCents={averageOrderValueCents}
      />
    </Container>
  )
}
