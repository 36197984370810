export { Table, HeadingTr } from '../shared'

export { formatDate } from '../../../util/displayValue'

import { GetRevenueResults } from '../../../../server/routes/api/reports/index'

import {
  commaDelimitedThousands,
  numberAsMoney,
  numberAsString,
} from '../../../util/displayValue'

import {
  AccountCategories,
  InflowCategory,
} from '../../../../server/data/models/sync/types'

export const CATEGORY_LABELS: { [key in InflowCategory]: string } = {
  amazonSales: 'Amazon',
  shopifySales: 'Shopify',
}

export enum AggregateType {
  averageOrderValueCents = 'averageOrderValueCents',
  orderVolume = 'orderVolume',
  revenueCents = 'revenueCents',
  unitVolume = 'unitVolume',
}

/**
 * @returns `true` when value is not null or undefined
 */
export function isPresent(v?: number | null): boolean {
  return typeof v !== 'undefined' && v !== null
}

/**
 * @returns Average of two numbers, preserving `null` values
 */
export function average(a?: number | null, b?: number | null): number | null {
  if (isPresent(a) && isPresent(b)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (a! + b!) / 2
  }
  return [a, b].find(v => isPresent(v)) ?? null
}

/**
 * @returns Sum of two numbers, preserving `null` values
 */
export function sum(a?: number | null, b?: number | null): number | null {
  if (isPresent(a) || isPresent(b)) {
    return (a || 0) + (b || 0)
  }
  return null
}

export function formatValue(
  sectionType: AggregateType,
  v: number | null | undefined,
): string {
  switch (sectionType) {
    case AggregateType.unitVolume:
    case AggregateType.orderVolume:
      return commaDelimitedThousands(v)
    case AggregateType.averageOrderValueCents:
    case AggregateType.revenueCents:
      return numberAsMoney(v as number, true)
    default:
      console.error(
        'reports/revenue formatValue: invalid section type',
        sectionType,
      )
      return numberAsString(v)
  }
}

export type RevenueData = {
  ts: number
  revenue: number
}

export const aggregateAllRevenueData = (
  revenueResults: GetRevenueResults,
): Array<RevenueData> => {
  const revenueCategories = [
    AccountCategories.shopifySales,
    AccountCategories.amazonSales,
  ]
  const timestamps = revenueResults.timestamps.map(timestamp =>
    new Date(timestamp).getTime(),
  )
  const flattenCentsFromCategories = (result: number[], current: number[]) =>
    current.map((cents, index) => cents + (result?.[index] ?? 0))

  const revenueCentsFromCategories = Object.entries(revenueResults.categories)
    .filter(category =>
      revenueCategories.includes(category[0] as InflowCategory),
    )
    .map(([_, inflowCategory]) => inflowCategory.revenueCents)
  const revenueCentsAggregated = revenueCentsFromCategories.reduce(
    flattenCentsFromCategories,
    [],
  )
  const aggregateData = timestamps.map((ts, idx) => ({
    ts: ts,
    revenue: revenueCentsAggregated[idx],
  }))
  return aggregateData
}
