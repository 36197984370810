import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import CheckIcon from '@mui/icons-material/Check'
import { ScoreStatus } from '../../../../../../../hooks/useCreditScore'
import { UserGuidanceBase } from '../../../../../../shared'
import { WomanChartIcon } from '../../../../../../../components/designSystem/assets/WomanChartIcon/WomanChartIcon'

interface Props {
  status: ScoreStatus | undefined
  creditScoreRequest: () => void
}

export const OverviewCreditScoreRequest = ({
  status,
  creditScoreRequest,
}: Props) => {
  const getDataBasedOnScore = (status: ScoreStatus | undefined) => {
    switch (status) {
      case ScoreStatus.NotRequested:
        return {
          title: 'Get your business credit score today!',
          subTitle:
            'Ready for success? Get your Brightflow AI Credit Score in just a few clicks.',
          button: (
            <Button
              onClick={() => creditScoreRequest()}
              variant="contained"
              color="primary"
              size={'large'}
            >
              + Request now!
            </Button>
          ),
        }
      case ScoreStatus.Requested:
        return {
          title: 'Requested Credit Score',
          subTitle:
            'Check your email for our reply. May take 24 hours for approval.',
          button: (
            <Button disabled variant="contained" color="primary" size={'large'}>
              <CheckIcon /> Submitted
            </Button>
          ),
        }
      default:
        return {
          title: '',
          subTitle: '',
          button: <></>,
        }
    }
  }

  const data = getDataBasedOnScore(status)

  return (
    <Stack sx={{ marginTop: 5 }}>
      <UserGuidanceBase
        renderIcon={() => <WomanChartIcon height={170} width={167} />}
        title={data.title}
        subtitle={data.subTitle}
      >
        {data.button}
      </UserGuidanceBase>
    </Stack>
  )
}
