import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'
import { Color } from '../../../../Color'

export const NavigationLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 10px;
  color: ${Color.White};
`
