import React, { ReactNode, useMemo, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'

import { Color } from '../../../../../Color'

import { Category, Merchant } from '../../Transactions.types'

import {
  findMatchingIndex,
  isCategory,
} from './TransactionsTableAutocomplete.utils'

type Props = {
  options: (Category | Merchant)[]
  onChange: (value: Category | Merchant | null) => void
  defaultValue?: string
  isGridCell?: boolean
  DropDownAdditon?: ReactNode
}

const cellStyle = {
  width: '100%',
  '.MuiOutlinedInput-notchedOutline': { border: 'none', outline: 'none' },
}

const headerStyle = {
  width: '100%',
  height: '38px',
  borderRadius: '5px',
  backgroundColor: Color.White,
  '.MuiOutlinedInput-root': {
    fontSize: '0.766rem',
    padding: '3px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: Color.LightGrey,
  },
}

export const TransactionsTableAutocomplete: React.FunctionComponent<Props> = ({
  options,
  onChange,
  defaultValue,
  isGridCell,
  DropDownAdditon,
}) => {
  const initialValue = useMemo(
    () =>
      defaultValue
        ? options[findMatchingIndex(defaultValue, options)]
        : undefined,
    [defaultValue, options],
  )

  const [value, setValue] = useState<Category | Merchant | undefined | null>(
    initialValue,
  )
  const [inputValue, setInputValue] = useState(defaultValue)
  const shouldRenderCategory = isCategory(options[0])

  return (
    <Autocomplete
      disableClearable={isGridCell}
      getOptionLabel={(option: any): string =>
        shouldRenderCategory ? option.label || '' : option.merchantName || ''
      }
      getOptionKey={(option: any): string => option.id}
      groupBy={(option: any): string =>
        shouldRenderCategory ? option.parent : undefined
      }
      options={options}
      value={value}
      onChange={(_event: any, newValue: Category | Merchant | null): void => {
        setValue(newValue)
        onChange(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue)
      }}
      renderInput={(inputParams): JSX.Element => <TextField {...inputParams} />}
      sx={isGridCell ? cellStyle : headerStyle}
      PaperComponent={({ children, ...rest }): JSX.Element => (
        <Paper {...rest}>
          {children}
          {DropDownAdditon}
        </Paper>
      )}
    />
  )
}
