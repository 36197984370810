import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import { format, subDays, subMonths } from 'date-fns'

import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'
import { GetRevenueResults } from '../../../../server/routes/api/reports'
import { getRevenueResults } from '../../../modules/reports/revenue'
import {
  GetMarketingExpenses,
  getMarketingExpenses,
} from '../../../modules/reports/roas'
import { DatePrecision } from '../../../../server/data/models/DatePrecision'

import { ROASConfig, aggregateRoasData } from './shared'
import { ROASGraph } from './ROASGraph'
import { SectionedTable } from './SectionedTable'
import { Toolbar } from '../../../layout/Toolbar'

type RoasScreenProps = Readonly<{
  disableMonthly?: boolean
  revenueResults: GetRevenueResults
  marketingExpenses: GetMarketingExpenses
  interval: 'day' | 'month'
}>

const ROASChannels = {
  [ROASConfig.all.label]: ROASConfig.all.key,
  [ROASConfig.shopifySales.label]: ROASConfig.shopifySales.key,
  [ROASConfig.amazonSales.label]: ROASConfig.amazonSales.key,
}

const HeadlineRow = styled('div')`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const HISTORICAL_MONTHS_TO_SHOW = 12 // will actually show 13 months as we subtracting
const HISTORICAL_DAYS_TO_SHOW = 7 // will actually show 8 days as we are subtracting

const ROASReportImpl: React.FunctionComponent<RoasScreenProps> = ({
  disableMonthly,
  revenueResults,
  marketingExpenses,
  interval,
}) => {
  const history = useHistory()
  const [active, setActive] = useState('All')
  useEffect(() => {
    let start
    let end
    let precision

    if (interval === 'day') {
      // daily
      end = format(subDays(new Date(), 1), 'yyyyMMdd')
      start = format(
        subDays(subDays(new Date(), 1), HISTORICAL_DAYS_TO_SHOW),
        'yyyyMMdd',
      )
      precision = DatePrecision.Day
    } else if (interval === 'month') {
      //  monthly
      end = format(new Date(), 'yyyyMMdd')
      start = format(
        subMonths(new Date(), HISTORICAL_MONTHS_TO_SHOW),
        'yyyyMMdd',
      )
      precision = DatePrecision.Month
    } else {
      history.push({
        pathname: '/roas',
      })
      return
    }
    dispatch(
      getRevenueResults({
        start: start,
        end: end,
        precision,
      }),
    )
    dispatch(
      getMarketingExpenses({
        start: start,
        end: end,
        precision,
      }),
    )
  }, [interval])

  const [channel, setChannel] = useState(ROASConfig.all.label)

  if (disableMonthly && interval !== 'day') {
    return <Redirect to="/roas/day" />
  }

  return (
    <React.Fragment>
      <HeadlineRow>
        <Toolbar
          interval={interval}
          type="roas"
          channels={ROASChannels}
          active={active}
          setActive={setActive}
          setChannel={setChannel}
          time={_.last(revenueResults?.timestamps) || ''}
          disableMonthly={disableMonthly}
        />
      </HeadlineRow>
      <ROASGraph
        data={aggregateRoasData(
          ROASChannels[channel],
          revenueResults,
          marketingExpenses,
        )}
        precision={revenueResults.precision}
      />
      <SectionedTable data={{ revenueResults, marketingExpenses }} />
    </React.Fragment>
  )
}

type OwnProps = {
  disableMonthly?: boolean
  interval: 'day' | 'month'
}

const mapStateToProps = (store: Store, ownProps: OwnProps): RoasScreenProps => {
  return {
    revenueResults: store.revenue.revenueResults,
    marketingExpenses: store.roas.marketingExpenses,
    interval: ownProps.interval,
    disableMonthly: ownProps.disableMonthly,
  }
}

export const ROASReport = connect(mapStateToProps)(React.memo(ROASReportImpl))
