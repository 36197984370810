import React from 'react'
import MenuList from '@mui/material/MenuList'
import Divider from '@mui/material/Divider'
import { NavigationItem } from '../NavigationItem/NavigationItem'
import { Color } from '../../../../Color'

type Props = {
  header: string
  headerIcon: JSX.Element
  isLast: boolean
  headerLink?: string
  items?: {
    title: string
    link: string
    isLocked?: boolean
  }[]
}

export const NavigationSection: React.FunctionComponent<Props> = ({
  header,
  headerIcon,
  headerLink,
  isLast,
  items,
}) => {
  // If set will use header link (elements with no items) if not will use the first child link
  const link = headerLink || (items && items.length > 0 ? items[0].link : '/#')
  return (
    <MenuList sx={{ padding: '0 14px' }}>
      <NavigationItem
        title={header}
        link={link}
        icon={headerIcon}
        shouldBeSelected={!!headerLink}
        shouldIncludeSeparation={!!items?.length}
      />
      {items?.map((child, index) => {
        return (
          <NavigationItem
            key={index}
            title={child.title}
            link={child.link}
            isLocked={child.isLocked || false}
            isChildItem
            shouldIncludeSeparation={index !== items.length - 1}
          />
        )
      })}
      {!isLast && (
        <Divider sx={{ borderBottom: `0.5px solid ${Color.Grey6}` }} />
      )}
    </MenuList>
  )
}
