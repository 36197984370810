import React from 'react'

import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro'
import { GlobalStyles } from '@mui/material'

const styles = {
  '.MuiMenuItem-root': {
    display: 'flex !important',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative' as 'relative',
    textDecoration: 'none',
    padding: '6px 16px',
  },
}

export const AdminDataGrid = (props: DataGridProProps): JSX.Element => {
  return (
    <>
      <GlobalStyles styles={styles} />
      <DataGridPro {...props} />
    </>
  )
}
