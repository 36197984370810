import React from 'react'

import { OverviewGraphCard } from '../OverviewGraphCard/OverviewGraphCard'
import { OverviewCardTypes, TimeParams } from '../../Overview.types'

import { useOverviewNewCustomerCard } from './OverviewNewCustomerCard.hooks'

export const OverviewNewCustomerCard: React.FunctionComponent<TimeParams> = ({
  duration,
  granularity,
}) => {
  const { channels, totals, isLoading, isEmpty } = useOverviewNewCustomerCard({
    duration,
    granularity,
  })
  return (
    <OverviewGraphCard
      type={OverviewCardTypes.NewCustomer}
      isEmpty={isEmpty}
      channels={channels}
      totals={totals}
      isLoading={isLoading}
    />
  )
}
