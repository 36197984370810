import React from 'react'
import { connect } from 'react-redux'

import { useDispatchApiCall } from '../../../hooks'
import { CompanyFeaturesPayload } from '../../../modules/companyFeatures'
import { Connection } from '../../../modules/connectors/types'
import { Store } from '../../../modules/rootReducer'
import {
  getConnectors,
  selectBankingConnectors,
} from '../../../modules/connectors'
import { hasCashPositionFeature } from '../../../util/companyFeatures'
import { CashPositionProvider } from '../cashPosition/CashPositionProvider'
import { InsightsWrapper } from './components/InsightsWrapper/InsightsWrapper'

type Props = {
  bankingConnections: Connection[]
  companyFeatures: CompanyFeaturesPayload
}

const InsightsImpl = ({
  bankingConnections,
  companyFeatures,
}: Props): JSX.Element => {
  useDispatchApiCall([getConnectors])

  const hasBankingConnection = !!bankingConnections.length

  const isSynced = bankingConnections.some(
    ({ initialSyncStatus }) => initialSyncStatus === 'complete',
  )

  return (
    <>
      <CashPositionProvider>
        <InsightsWrapper
          hasBankingConnection={hasBankingConnection}
          hasFeatureAccess={hasCashPositionFeature(companyFeatures)}
          isSynced={isSynced}
        />
      </CashPositionProvider>
    </>
  )
}

const mapStateToProps = (store: Store): Props => {
  return {
    bankingConnections: selectBankingConnectors(store.connectors),
    companyFeatures: store.companyFeatures.companyFeatures,
  }
}

export const InsightsScreen = connect(mapStateToProps)(InsightsImpl)
