import { Link, Typography } from '@mui/material'
import React from 'react'
import {
  formatCellAttributesHash,
  shouldRenderLink,
  shouldRenderRedNegatives,
} from './CashPositionTableCell.utils'
import { centsAsDollars } from '../../../../../util/displayValue'
import { useCashPosition } from '../../CashPositionProvider'
import { Color } from '../../../../../Color'

type Props = {
  categoryId?: string
  headerClassName?: string
  merchantName?: string
  renderValue: any
  startDate: string
  subCategories?: string[]
}

export const CashPositionTableCell = ({
  categoryId,
  headerClassName,
  merchantName,
  renderValue,
  startDate,
  subCategories,
}: Props): JSX.Element => {
  const { dateRangeMap } = useCashPosition()
  const dateRange = dateRangeMap?.[startDate] || null

  const attributeHash = formatCellAttributesHash(
    dateRange?.end || '',
    dateRange?.start || '',
    categoryId,
    merchantName,
    subCategories,
  )
  const shouldShowRedNegativeStyle =
    shouldRenderRedNegatives(headerClassName) && renderValue < 0
  return shouldRenderLink(headerClassName) ? (
    <Link href={`/transactions/${attributeHash}`}>
      {centsAsDollars(renderValue)}
    </Link>
  ) : (
    <Typography
      sx={{
        fontWeight: 'inherit',
        color: shouldShowRedNegativeStyle ? Color.AccountingRed : 'inherit',
      }}
    >
      {centsAsDollars(renderValue)}
    </Typography>
  )
}
