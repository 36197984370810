import React, { useEffect } from 'react'
import { RouteChildrenProps } from 'react-router'
import { connect } from 'react-redux'

import { Layout } from '../../../layout'
import { dispatch } from '../../../store'
import { Store } from '../../../modules/rootReducer'
import { getProductSegmentDashboardData } from '../../../modules/adminDashboard/productDemand/productSegmentActions'
import {
  PRODUCT_SEGMENT_DASHBOARD_KEY,
  ProductSegmentDashboardPayload,
} from '../../../modules/adminDashboard/productDemand/productSegmentReducers'
import { ProductSegmentsGrid } from './ProductSegmentsGrid'

interface Props {
  companyId: string
  productSegmentDashboard: ProductSegmentDashboardPayload
}

export const ProductSegmentDashboardImpl = (props: Props): JSX.Element => {
  useEffect(() => {
    dispatch(getProductSegmentDashboardData(props.companyId))
  }, [props.companyId])
  const { productSegmentDashboard, companyId } = props
  const {
    error,
    productSegmentDashboard: productSegmentResults,
  } = productSegmentDashboard
  if (error || productSegmentResults?.errorMessages.length) {
    return (
      <p>{`Ben broke something go bug him ${
        JSON.stringify(error) ||
        JSON.stringify(productSegmentResults?.errorMessages)
      }`}</p>
    )
  }

  return (
    <Layout showSidebar={false} background="white">
      <ProductSegmentsGrid
        productSegmentResults={productSegmentResults}
        companyId={companyId}
      />
    </Layout>
  )
}

const mapStateToProps = (
  store: Store,
  companyIdProps: RouteChildrenProps<{ companyId: string }>,
): Props => {
  const productSegmentDashboard =
    store.productSegmentDashboard[PRODUCT_SEGMENT_DASHBOARD_KEY]

  return {
    companyId: companyIdProps.match?.params.companyId || '',
    productSegmentDashboard,
  }
}

export const ProductSegmentScreen = connect(mapStateToProps)(
  React.memo(ProductSegmentDashboardImpl),
)
