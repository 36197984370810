import React, { useState } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { NavigationTopbar } from '../../molecules/NavigationTopbar/NavigationTopbar'
import { NavigationSidebar } from '../../molecules/NavigationSidebar/NavigationSidebar'
import { Color } from '../../../../Color'
import { UIFeatures } from '../../../../util/companyFeatures'

type Props = {
  companyName: string
  activeEmail: string
  uiFeatures: UIFeatures
}

export const Navigation: React.FunctionComponent<Props> = ({
  companyName,
  activeEmail,
  uiFeatures,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [shouldShowSideBar, setShouldShowSideBar] = useState<boolean>(false)

  return (
    <>
      {isMobile ? (
        <>
          <NavigationTopbar
            menuAction={() => setShouldShowSideBar(!shouldShowSideBar)}
            isVisible={!shouldShowSideBar}
          />
          <Drawer
            open={shouldShowSideBar}
            onClose={() => setShouldShowSideBar(false)}
            sx={{
              '& .MuiDrawer-paper': {
                background: Color.BrandPurple,
              },
            }}
          >
            <Box sx={{ alignSelf: 'flex-end' }}>
              <IconButton onClick={() => setShouldShowSideBar(false)}>
                <CloseIcon sx={{ color: Color.White }} />
              </IconButton>
            </Box>
            <NavigationSidebar
              companyName={companyName}
              activeEmail={activeEmail}
              uiFeatures={uiFeatures}
            />
          </Drawer>
        </>
      ) : (
        !isMobile && (
          <NavigationSidebar
            companyName={companyName}
            activeEmail={activeEmail}
            uiFeatures={uiFeatures}
          />
        )
      )}
    </>
  )
}
