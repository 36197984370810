import React from 'react'
import Grid from '@mui/material/Grid'

import { DataPresentationCard } from '../../../../../components/designSystem/molecules/DataPresentationCard/DataPresentationCard'
import { useOverviewCreditScoreCard } from './OverviewCreditScoreCard.hooks'
import { OverviewCreditScoreCardProps } from './OverviewCreditScoreCard.types'
import { OverviewCardConsts } from '../../Overview.consts'
import { OverviewCardTypes } from '../../Overview.types'
import { InfoTooltip } from '../../../../../components/designSystem/molecules/InfoTooltip/InfoTooltip'
import { OverviewCreditScoreBody } from './components/CreditScoreBody/CreditScoreBody'
import { CreditScoreEmptyState } from './components/CreditScoreEmptyState/CreditScoreEmptyState'

export const OverviewCreditScoreCard: React.FunctionComponent<OverviewCreditScoreCardProps> = ({
  accountConnections,
}) => {
  const {
    creditScoreStatus,
    creditScoreRating,
    creditScoreRatingScale,
    creditScore,
    creditScoreFactors,
    creditScoreRequest,
    isEmpty,
  } = useOverviewCreditScoreCard({ accountConnections })
  const { title, description } = OverviewCardConsts[
    OverviewCardTypes.CreditScore
  ]

  return (
    <Grid item xs={12} lg={6}>
      <DataPresentationCard
        title={title}
        widgets={<InfoTooltip description={description} />}
        sx={{ minHeight: '100%' }}
      >
        {isEmpty ? (
          <CreditScoreEmptyState />
        ) : (
          <OverviewCreditScoreBody
            creditScoreFactors={creditScoreFactors}
            creditScoreRating={creditScoreRating}
            creditScoreRatingScale={creditScoreRatingScale}
            creditScoreRequest={creditScoreRequest}
            creditScore={creditScore}
            creditScoreStatus={creditScoreStatus}
          />
        )}
      </DataPresentationCard>
    </Grid>
  )
}
