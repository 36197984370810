import React from 'react'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { TrendTarget } from '../../atoms/TrendLabel/types'

import { DataPresentationCard } from '../../molecules/DataPresentationCard/DataPresentationCard'
import { CardsContainer } from './HighlightsBar.styles'
import { HighlightsCard } from '../../molecules/HighlightsCard/HighlightsCard'
import { Trends } from '../../../../../server/app-data-service/generatedTypes'

type Props = {
  categories: {
    description: React.ReactNode
    label: string
    trends: Trends | null
    trendTarget?: TrendTarget
    value: string
  }[]
  isLoading?: boolean
  sx?: SxProps<Theme>
}

export const HighlightsBar = ({
  categories,
  isLoading,
  sx,
}: Props): JSX.Element => (
  <DataPresentationCard
    title="Highlights"
    sx={{
      ...sx,
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      p: '20px',
    }}
  >
    {isLoading ? (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '140px',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <CardsContainer>
        {categories.map(
          ({ label, description, value, trends, trendTarget }) => (
            <React.Fragment key={`highlights-card-${label}`}>
              <HighlightsCard
                label={label}
                value={value}
                percentChangeFromPrevious={trends?.currentVsPrevious || null}
                percentChangeFromAverage={
                  trends?.currentVsIntervalAverage || null
                }
                target={trendTarget}
                description={description}
                sx={{
                  minWidth: '155px',
                  minHeight: '140px',
                  p: '10px',
                }}
              />
            </React.Fragment>
          ),
        )}
      </CardsContainer>
    )}
  </DataPresentationCard>
)
