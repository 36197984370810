import { GraphTrace } from '../../../../../components/designSystem/organisms/BFGraph/BFGraph.types'
import { addMonths, format, startOfMonth, subMonths } from 'date-fns'
import { ForecastData, ForecastDataBySegmentMap, ForecastPoint } from '../types'
import { AbstractProductDemandPeriodTransformer } from './AbstractProductDemandPeriodTransformer'

// a bit confusing but pacing month is sort of a combo of
// a forecast/actual month so we offset everything by 1 to account for that
// so one month = 1 actual + current month (PACING_MONTH_OFFSET), etc
const PACING_MONTH_OFFSET = 1
const ONE_MONTH_MONTHS = 1 + PACING_MONTH_OFFSET
const THREE_MONTH_MONTHS = 3 + PACING_MONTH_OFFSET
const SIX_MONTH_MONTHS = 6 + PACING_MONTH_OFFSET
const ONE_YEAR_MONTHS = 12 + PACING_MONTH_OFFSET
const TWO_YEARS_MONTHS = 24 + PACING_MONTH_OFFSET

/**
 * Transforms raw monthly product demand data provided by API
 * into data format that can be utilized in recharts graph
 */
export class MonthlyProductDemandDataTransformer extends AbstractProductDemandPeriodTransformer {
  public minTickGap = [-5, -25, -1000, -10000, -10000]

  public segmentData: ForecastData[] = []

  public segmentDataMap: ForecastDataBySegmentMap

  public firstForecastIndex: number

  public timePeriodArray: number[] = [
    TWO_YEARS_MONTHS,
    ONE_YEAR_MONTHS,
    SIX_MONTH_MONTHS,
    THREE_MONTH_MONTHS,
    ONE_MONTH_MONTHS,
  ]

  public tooltipDateStringFormat = 'LLL yyyy'

  constructor(segmentData: ForecastData[]) {
    super()
    this.segmentData = segmentData
    this.segmentDataMap = this.buildSegmentDataMap()
    this.firstForecastIndex = segmentData[0].data.reduce(
      (acc, { ds }, index) => {
        const currentMonthStart = startOfMonth(new Date(ds))

        // we need to add one month from current month because the "current"
        // month in progress is actually 'pacing' so is included in the actuals
        // series
        if (
          currentMonthStart > addMonths(startOfMonth(new Date()), 1) &&
          acc === 0
        ) {
          acc = index - 1
        }

        return acc
      },
      0,
    )
  }

  public getForecastDataPoints(data: ForecastPoint[]): ForecastPoint[] {
    return data.slice(
      this.lastActualIndex,
      this.firstForecastIndex + SIX_MONTH_MONTHS - 1,
    )
  }

  public getActualDataPoints(
    data: ForecastPoint[],
    selectedUnitSegmentIndex: number,
  ): ForecastPoint[] {
    return data.filter(({ ds }, index) => {
      const date = new Date(ds)

      const historicalStartDate = subMonths(
        new Date(data[this.firstForecastIndex].ds),
        this.timePeriodArray[selectedUnitSegmentIndex],
      )

      return date >= historicalStartDate && index <= this.lastActualIndex
    })
  }

  public getXAxisTicks(traces: GraphTrace[]): Array<string | number> {
    // actuals ticks can be gathered from first trace
    const axisTickDates = traces?.[0]?.x

    // forecast ticks can be gathered from last trace
    const forecastTickDates = traces?.[traces.length - 1]?.x

    return [...axisTickDates, ...forecastTickDates]
  }

  public static getLastYearActualFromForecastLabel = (
    forecastLabel: string,
    forecastPoints: ForecastPoint[],
  ): number => {
    const currentDataPoint = forecastPoints?.findIndex(
      data => data.ds === forecastLabel,
    )

    if (!currentDataPoint) {
      return 0
    }

    const offsetIndex = currentDataPoint - ONE_YEAR_MONTHS
    return forecastPoints?.[offsetIndex]?.y ?? 0
  }

  public getLastYearActualFromForecastLabel = (
    segmentName: string,
    forecastLabel: string,
  ): number => {
    const segment = this.segmentDataMap[segmentName]

    const forecastPoints = segment.data

    const currentDataPoint = forecastPoints?.findIndex(
      data =>
        format(new Date(data.ds), this.tooltipDateStringFormat) ===
        forecastLabel,
    )

    if (!currentDataPoint) {
      return 0
    }

    const offsetIndex = currentDataPoint - ONE_YEAR_MONTHS + PACING_MONTH_OFFSET // need to add back the pacing month here
    return forecastPoints?.[offsetIndex]?.y ?? 0
  }

  public getBaseName(label: string): string {
    const isForecast = this.isLabelForecast(label)

    if (isForecast) {
      return 'Units sold last year'
    }

    const lastActualDateString = this.lastActualDateString

    const lastActualMonth = format(
      new Date(lastActualDateString),
      this.tooltipDateStringFormat,
    )

    if (lastActualMonth === label) {
      return 'Pacing'
    }

    return 'Units sold'
  }
}
