export const TableTooltips = {
  'ATM Cash Inflows': 'Cash deposits',
  'ATM Cash Outflows': 'Cash disbursements',
  Advertising:
    'Payments for performance marketing, PR agencies, influencers or similar marketing',
  'Charges/Fees': 'Bank / finance charges and fees',
  'Check Outflows': 'Check outflows with no identifiable counterparty',
  'Company Investments':
    'Transfers to brokerages, daily cash sweeps, other round-up transactions',
  'Credit Card':
    'Payments to credit cards where the credit card account is not connected',
  'Debt Financing': 'Incoming transfers of loan principals',
  'Debt Repayment': 'Outgoing debt repayments',
  'Equity Financing':
    'Investments by Venture Capital or Private Equity Funds, any injections by business owners',
  'General Payment':
    'Transactions where it is not possible to assess the purpose e.g. "Paypal", or "Transferwise"',
  Insurance: 'Company insurance',
  Inventory:
    'Payments for direct inputs used in creating the companies products or services',
  'Other Expenses': 'Sundry expenses (restaurants, accountants, lawyers, etc.)',
  'Overdraft/NSF Fees': 'Charges for use of an unarranged overdraft',
  'Payroll and Consultants':
    'Payments to staff, contractors, consultants. Also includes benefits, medical insurances, general employee expenses',
  Postage: 'Payments for logistics, freight forwarding, shipping, etc.',
  'Reconciled Intra-Company Transfers':
    'Transfers between company accounts where there is a matching transfer',
  Refunds: 'Refunds from any category',
  Rent: 'Rent for offices, workspaces or storage facilities',
  Revenue: 'Payments for goods or services',
  Software: 'Spend on Software, Cloud or other infrastructure',
  'Special inflows':
    'This is generally where it is hard to assign a label without input from the customer',
  'Special outflows':
    'This is generally where it is hard to assign a label without input from the customer',
  Taxes: 'Tax Payments or Tax Refunds',
  Travel: 'Payment for hotels, flights, car rental or other travel',
  'Unreconciled Intra-Company Transfers':
    'Transfers between company accounts where there is no matching transfer',
  Utilities: 'Power, Telecoms, Internet etc.',
  'Check Deposits': 'Deposits made via check',
  'P2P Transfers': 'Payments to/from an individual or entity',
  Shipping: 'Payments for logistics, freight forwarding, shipping, etc.',
  Other:
    'This is generally where it is hard to assign a label without input from the customer',
  'Other Outflows': 'This category contains a list of subcategories',
  'Other Inflows': 'This category contains a list of subcategories',
}

export const TableHeaderClasses = {
  cashChange: 'cash-change',
  cashInflow: 'cash-inflow',
  cashOutflow: 'cash-outflow',
  categoryWithSubs: 'category-with-subs',
  ending: 'ending',
  parent: 'parent',
}
