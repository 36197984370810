import React from 'react'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material/styles'
import {
  Bar,
  BarProps,
  CartesianGrid,
  ComposedChart,
  Legend,
  LegendProps,
  Line,
  LineProps,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'

import { Color } from '../../../../../Color'

type Props = {
  title: string
  data: { [key: string]: any }[]
  lines: {
    dataKey: string
    color: Color | string
    options?: LineProps
  }[]
  bars: {
    dataKey: string
    color: Color | string
    options?: BarProps
  }[]
  xAxisOptions?: XAxisProps
  yAxisOptions?: YAxisProps
  hasNegativeValues?: boolean
  shouldRenderLegend?: boolean
  legendOptions?: LegendProps
  shouldRenderTooltip?: boolean
  tooltipOptions?: TooltipProps
  backgroundColor?: string
  sx?: SxProps<Theme>
}

export const LineBarChart = ({
  title,
  data,
  lines,
  bars,
  xAxisOptions,
  yAxisOptions,
  hasNegativeValues = false,
  shouldRenderLegend = true,
  legendOptions,
  shouldRenderTooltip = true,
  tooltipOptions,
  backgroundColor = 'white',
  sx,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '380px',
        backgroundColor: backgroundColor,
        ...sx,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data}>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            tick={{
              textAnchor: 'middle',
              fontSize: 12,
              fill: Color.DarkCharcoal,
            }}
            stroke={Color.DarkCharcoal}
            {...xAxisOptions}
          />
          <YAxis
            tick={{
              fontSize: 12,
              fill: Color.DarkCharcoal,
            }}
            stroke={Color.DarkCharcoal}
            {...yAxisOptions}
          />
          {bars.length &&
            bars.map(({ dataKey, color, options }) => (
              <Bar
                key={`${title}-${dataKey}-chart-bar`}
                dataKey={dataKey}
                fill={color}
                {...options}
              />
            ))}
          {lines.length &&
            lines.map(({ dataKey, color, options }) => (
              <Line
                key={`${title}-${dataKey}-chart-line`}
                dataKey={dataKey}
                stroke={color}
                {...options}
              />
            ))}
          {shouldRenderTooltip && <Tooltip {...tooltipOptions} />}
          {shouldRenderLegend && <Legend {...legendOptions} />}
          {hasNegativeValues && (
            <ReferenceLine
              y={0}
              stroke={Color.DarkCharcoal}
              strokeDasharray="3 3"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
