import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import { Color } from '../../../../../../../Color'
import { CreditScoreRatingContainer } from '../../OverviewCreditScoreCard.styles'
import { CreditScoreFactorContainer } from '../../OverviewCreditScoreCard.styles'
import { CreditScoreRating } from '../../../../../../../hooks/useRatingCreditScore'
import { RatingProgressBar } from '../../../../../../../components/designSystem/organisms/CreditScoreWidget/components/RatingProgressBar'
import { OverviewCreditScoreModalProps } from '../../OverviewCreditScoreCard.types'
import { mapCreditScoreFactorToDisplayValue } from '../../OverviewCreditScoreCard.utils'
import { getCreditScoreFactorDescription } from '../../OverviewCreditScoreCard.utils'

const CreditScoreRatingModal = ({
  rating,
  scale,
  score,
  color,
}: {
  rating: string
  scale: CreditScoreRating[]
  score: number
  color: Color
}): JSX.Element => (
  <CreditScoreRatingContainer borderColor={color}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
      }}
    >
      <Typography variant="h6">{score}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <FiberManualRecordIcon
          className="simple-chip--icon"
          style={{ color: color, fontSize: '12px' }}
        />
        <Typography variant="h6">{rating}</Typography>
      </Box>
    </Box>
    <RatingProgressBar segments={scale} score={score ?? 0} color={color} />
  </CreditScoreRatingContainer>
)

const CreditScoreFactorModal = ({
  title,
  description,
  score,
}: {
  title: string
  description: string
  score: number
}): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  return (
    <CreditScoreFactorContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '10px',
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>
        <Tooltip
          key="widget-tooltip"
          title={description}
          onClick={(): void => setIsTooltipOpen(true)}
          onMouseOver={(): void => setIsTooltipOpen(true)}
          onMouseLeave={(): void => setIsTooltipOpen(false)}
          onClose={(): void => setIsTooltipOpen(false)}
          open={isTooltipOpen}
        >
          <InfoIcon color="action" sx={{ fontSize: 12 }} />
        </Tooltip>
      </Box>
      <Typography variant="subtitle1">{score}</Typography>
    </CreditScoreFactorContainer>
  )
}

export const OverviewCreditScoreModal = ({
  rating,
  scale,
  score,
  factors,
  color,
}: OverviewCreditScoreModalProps): JSX.Element => (
  <Box
    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
  >
    <CreditScoreRatingModal
      rating={rating}
      scale={scale}
      score={score ?? 0}
      color={color}
    />
    {Object.entries(factors).map(([factorName, value]) => (
      <CreditScoreFactorModal
        title={mapCreditScoreFactorToDisplayValue(factorName)}
        description={getCreditScoreFactorDescription(factorName)}
        score={value ?? 0}
      />
    ))}
  </Box>
)
