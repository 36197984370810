import React from 'react'
import { OverviewCreditScoreModal } from '../CreditScoreRatingModal/CreditScoreRatingModal'
import { OverviewCreditScoreRequest } from '../OverviewCreditScoreRequest/OverviewCreditScoreRequest'
import { ScoreStatus } from '../../../../../../../hooks/useCreditScore'
import { CreditScoreFactors } from '../../OverviewCreditScoreCard.types'
import { CreditScoreRating } from '../../../../../../../hooks/useRatingCreditScore'

type Props = {
  creditScore?: number
  creditScoreFactors: CreditScoreFactors
  creditScoreRating: CreditScoreRating
  creditScoreRatingScale: CreditScoreRating[]
  creditScoreRequest: () => void
  creditScoreStatus?: ScoreStatus
}

export const OverviewCreditScoreBody = ({
  creditScore,
  creditScoreFactors,
  creditScoreRating,
  creditScoreRatingScale,
  creditScoreRequest,
  creditScoreStatus,
}: Props) => {
  if (creditScoreStatus === ScoreStatus.HasCreditScore) {
    return (
      <OverviewCreditScoreModal
        rating={creditScoreRating.rating}
        scale={creditScoreRatingScale}
        score={creditScore ?? 0}
        factors={creditScoreFactors}
        color={creditScoreRating.color}
      />
    )
  }

  return (
    <OverviewCreditScoreRequest
      status={creditScoreStatus}
      creditScoreRequest={creditScoreRequest}
    />
  )
}
