export enum TrendTarget {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
}

export interface TrendLabelData {
  target?: TrendTarget
  trendPercent: number | null
}
