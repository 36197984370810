import { head, last } from 'lodash'
import { format } from 'date-fns'

import {
  TimeSeriesChannel,
  TimeSeriesData,
} from '../../../../../../server/app-data-service/generatedTypes'
import { TrendTarget } from '../../../../../components/designSystem/atoms/TrendLabel/types'
import { OverviewChartCardTypes } from './OverviewGraphCard.types'
import { OverviewCardTypes } from '../../Overview.types'

import {
  LegendItem,
  LineGraphPresentationTypes,
} from '../../../../../components/designSystem/organisms/LineGraphPresentationCard/LineGraphPresentationCard.types'

export type AggregatedTimeSeriesDataPoint = {
  date: string
  total: number
}

export const cardTypeToTrendTargetHandlers: {
  [key in OverviewChartCardTypes]: (name?: string) => TrendTarget
} = {
  [OverviewCardTypes.AOV]: () => TrendTarget.positive,
  [OverviewCardTypes.Banking]: (name?: string) =>
    name === 'cashOutflowCents' ? TrendTarget.negative : TrendTarget.positive,
  [OverviewCardTypes.CAC]: () => TrendTarget.negative,
  [OverviewCardTypes.Marketing]: () => TrendTarget.neutral,
  [OverviewCardTypes.NewCustomer]: () => TrendTarget.positive,
  [OverviewCardTypes.OrderRevenue]: () => TrendTarget.positive,
  [OverviewCardTypes.OrderVolume]: () => TrendTarget.positive,
  [OverviewCardTypes.Units]: () => TrendTarget.positive,
}

export const cardTypeToGraphPresentationTypeMap: {
  [key in OverviewChartCardTypes]: LineGraphPresentationTypes
} = {
  [OverviewCardTypes.AOV]: LineGraphPresentationTypes.SmallMoney,
  [OverviewCardTypes.Banking]: LineGraphPresentationTypes.LargeMoney,
  [OverviewCardTypes.CAC]: LineGraphPresentationTypes.SmallMoney,
  [OverviewCardTypes.Marketing]: LineGraphPresentationTypes.LargeMoney,
  [OverviewCardTypes.NewCustomer]: LineGraphPresentationTypes.Volume,
  [OverviewCardTypes.OrderRevenue]: LineGraphPresentationTypes.LargeMoney,
  [OverviewCardTypes.OrderVolume]: LineGraphPresentationTypes.Volume,
  [OverviewCardTypes.Units]: LineGraphPresentationTypes.Volume,
}

export const formatAsChartData = (
  totals: TimeSeriesData,
  channels?: Array<TimeSeriesChannel> | null,
): AggregatedTimeSeriesDataPoint[] => {
  const dates = head(channels)?.timeSeries ?? totals.timeSeries ?? []

  return dates
    .map((date, idx) => {
      if (!date) return null

      const dateRange =
        date.start === date.end
          ? `${format(new Date(date.start), 'M/d')}`
          : `${format(new Date(date.start), 'M/d')} - ${format(
              new Date(date.end),
              'M/d',
            )}`

      return {
        date: dateRange,
        total: totals.timeSeries?.[idx]?.value,
        ...channels?.reduce(
          (acc, channel) => ({
            ...acc,
            [channel.name]: channel.timeSeries?.[idx]?.value,
          }),
          {},
        ),
      }
    })
    .filter((data): data is AggregatedTimeSeriesDataPoint => data !== null)
}

export const formatAsLegendData = (
  channels: Array<TimeSeriesChannel>,
  cardType: OverviewChartCardTypes,
): LegendItem[] =>
  channels.map(({ name, timeSeries, trends }) => ({
    name,
    value: last(timeSeries)?.value ?? 0,
    trendLabelData: [
      {
        trendPercent: trends?.currentVsPrevious ?? null,
        target: cardTypeToTrendTargetHandlers[cardType](name),
      },
      {
        trendPercent: trends?.currentVsIntervalAverage ?? null,
        target: cardTypeToTrendTargetHandlers[cardType](name),
      },
    ],
  }))
