import { SubscriptionPlanTypes } from '../../../server/data/models/types'
import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getSubscriptionPlans = createAsyncThunk(
  'app/subscription-plans/get',
  async () =>
    api.get(
      `/api/subscription-plans?planType=${SubscriptionPlanTypes.standard}`,
    ),
)
