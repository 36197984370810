import 'react';
export { S as AbandonedCartMajorMonotone } from './icons/polaris/abandoned-cart_major_monotone.svg.mjs';
export { S as AbandonedCartMajorTwotone } from './icons/polaris/abandoned-cart_major_twotone.svg.mjs';
export { S as AccessibilityMajorMonotone } from './icons/polaris/accessibility_major_monotone.svg.mjs';
export { S as AccessibilityMajorTwotone } from './icons/polaris/accessibility_major_twotone.svg.mjs';
export { S as ActivitiesMajorMonotone } from './icons/polaris/activities_major_monotone.svg.mjs';
export { S as ActivitiesMajorTwotone } from './icons/polaris/activities_major_twotone.svg.mjs';
export { S as AddMajorMonotone } from './icons/polaris/add_major_monotone.svg.mjs';
export { S as AddMajorTwotone } from './icons/polaris/add_major_twotone.svg.mjs';
export { S as AddCodeMajorMonotone } from './icons/polaris/add-code_major_monotone.svg.mjs';
export { S as AddCodeMajorTwotone } from './icons/polaris/add-code_major_twotone.svg.mjs';
export { S as AddImageMajorMonotone } from './icons/polaris/add-image_major_monotone.svg.mjs';
export { S as AddImageMajorTwotone } from './icons/polaris/add-image_major_twotone.svg.mjs';
export { S as AddNoteMajorMonotone } from './icons/polaris/add-note_major_monotone.svg.mjs';
export { S as AddNoteMajorTwotone } from './icons/polaris/add-note_major_twotone.svg.mjs';
export { S as AddProductMajorMonotone } from './icons/polaris/add-product_major_monotone.svg.mjs';
export { S as AddProductMajorTwotone } from './icons/polaris/add-product_major_twotone.svg.mjs';
export { S as AffiliateMajorMonotone } from './icons/polaris/affiliate_major_monotone.svg.mjs';
export { S as AffiliateMajorTwotone } from './icons/polaris/affiliate_major_twotone.svg.mjs';
export { S as AlertMinor } from './icons/polaris/alert_minor.svg.mjs';
export { S as AnalyticsMajorMonotone } from './icons/polaris/analytics_major_monotone.svg.mjs';
export { S as AnalyticsMajorTwotone } from './icons/polaris/analytics_major_twotone.svg.mjs';
export { S as AppExtensionMinor } from './icons/polaris/app-extension_minor.svg.mjs';
export { S as AppsMajorMonotone } from './icons/polaris/apps_major_monotone.svg.mjs';
export { S as AppsMajorTwotone } from './icons/polaris/apps_major_twotone.svg.mjs';
export { S as ArchiveMajorMonotone } from './icons/polaris/archive_major_monotone.svg.mjs';
export { S as ArchiveMajorTwotone } from './icons/polaris/archive_major_twotone.svg.mjs';
export { S as ArchiveMinor } from './icons/polaris/archive_minor.svg.mjs';
export { S as ArrowDownMinor } from './icons/polaris/arrow-down_minor.svg.mjs';
export { S as ArrowLeftMinor } from './icons/polaris/arrow-left_minor.svg.mjs';
export { S as ArrowRightMinor } from './icons/polaris/arrow-right_minor.svg.mjs';
export { S as ArrowUpMinor } from './icons/polaris/arrow-up_minor.svg.mjs';
export { S as AttachmentMajorMonotone } from './icons/polaris/attachment_major_monotone.svg.mjs';
export { S as AttachmentMajorTwotone } from './icons/polaris/attachment_major_twotone.svg.mjs';
export { S as AutomationMajorMonotone } from './icons/polaris/automation_major_monotone.svg.mjs';
export { S as AutomationMajorTwotone } from './icons/polaris/automation_major_twotone.svg.mjs';
export { S as BackspaceMajorMonotone } from './icons/polaris/backspace_major_monotone.svg.mjs';
export { S as BackspaceMajorTwotone } from './icons/polaris/backspace_major_twotone.svg.mjs';
export { S as BalanceMajorMonotone } from './icons/polaris/balance_major_monotone.svg.mjs';
export { S as BalanceMajorTwotone } from './icons/polaris/balance_major_twotone.svg.mjs';
export { S as BankMajorMonotone } from './icons/polaris/bank_major_monotone.svg.mjs';
export { S as BankMajorTwotone } from './icons/polaris/bank_major_twotone.svg.mjs';
export { S as BarcodeMajorMonotone } from './icons/polaris/barcode_major_monotone.svg.mjs';
export { S as BarcodeMajorTwotone } from './icons/polaris/barcode_major_twotone.svg.mjs';
export { S as BehaviorMajorMonotone } from './icons/polaris/behavior_major_monotone.svg.mjs';
export { S as BehaviorMajorTwotone } from './icons/polaris/behavior_major_twotone.svg.mjs';
export { S as BillingStatementDollarMajorMonotone } from './icons/polaris/billing-statement-dollar_major_monotone.svg.mjs';
export { S as BillingStatementDollarMajorTwotone } from './icons/polaris/billing-statement-dollar_major_twotone.svg.mjs';
export { S as BillingStatementEuroMajorMonotone } from './icons/polaris/billing-statement-euro_major_monotone.svg.mjs';
export { S as BillingStatementEuroMajorTwotone } from './icons/polaris/billing-statement-euro_major_twotone.svg.mjs';
export { S as BillingStatementPoundMajorMonotone } from './icons/polaris/billing-statement-pound_major_monotone.svg.mjs';
export { S as BillingStatementPoundMajorTwotone } from './icons/polaris/billing-statement-pound_major_twotone.svg.mjs';
export { S as BillingStatementRupeeMajorMonotone } from './icons/polaris/billing-statement-rupee_major_monotone.svg.mjs';
export { S as BillingStatementRupeeMajorTwotone } from './icons/polaris/billing-statement-rupee_major_twotone.svg.mjs';
export { S as BillingStatementYenMajorMonotone } from './icons/polaris/billing-statement-yen_major_monotone.svg.mjs';
export { S as BillingStatementYenMajorTwotone } from './icons/polaris/billing-statement-yen_major_twotone.svg.mjs';
export { S as BlockquoteMajorMonotone } from './icons/polaris/blockquote_major_monotone.svg.mjs';
export { S as BlockquoteMajorTwotone } from './icons/polaris/blockquote_major_twotone.svg.mjs';
export { S as BlogMajorMonotone } from './icons/polaris/blog_major_monotone.svg.mjs';
export { S as BlogMajorTwotone } from './icons/polaris/blog_major_twotone.svg.mjs';
export { S as BugMajorMonotone } from './icons/polaris/bug_major_monotone.svg.mjs';
export { S as BugMajorTwotone } from './icons/polaris/bug_major_twotone.svg.mjs';
export { S as BuyButtonMajorMonotone } from './icons/polaris/buy-button_major_monotone.svg.mjs';
export { S as BuyButtonMajorTwotone } from './icons/polaris/buy-button_major_twotone.svg.mjs';
export { S as BuyButtonButtonLayoutMajorMonotone } from './icons/polaris/buy-button-button-layout_major_monotone.svg.mjs';
export { S as BuyButtonButtonLayoutMajorTwotone } from './icons/polaris/buy-button-button-layout_major_twotone.svg.mjs';
export { S as BuyButtonHorizontalLayoutMajorMonotone } from './icons/polaris/buy-button-horizontal-layout_major_monotone.svg.mjs';
export { S as BuyButtonHorizontalLayoutMajorTwotone } from './icons/polaris/buy-button-horizontal-layout_major_twotone.svg.mjs';
export { S as BuyButtonVerticalLayoutMajorMonotone } from './icons/polaris/buy-button-vertical-layout_major_monotone.svg.mjs';
export { S as BuyButtonVerticalLayoutMajorTwotone } from './icons/polaris/buy-button-vertical-layout_major_twotone.svg.mjs';
export { S as CalendarMajorMonotone } from './icons/polaris/calendar_major_monotone.svg.mjs';
export { S as CalendarMajorTwotone } from './icons/polaris/calendar_major_twotone.svg.mjs';
export { S as CalendarMinor } from './icons/polaris/calendar_minor.svg.mjs';
export { S as CalendarTickMajorMonotone } from './icons/polaris/calendar-tick_major_monotone.svg.mjs';
export { S as CalendarTickMajorTwotone } from './icons/polaris/calendar-tick_major_twotone.svg.mjs';
export { S as CameraMajorMonotone } from './icons/polaris/camera_major_monotone.svg.mjs';
export { S as CameraMajorTwotone } from './icons/polaris/camera_major_twotone.svg.mjs';
export { S as CancelSmallMinor } from './icons/polaris/cancel-small_minor.svg.mjs';
export { S as CapitalMajorMonotone } from './icons/polaris/capital_major_monotone.svg.mjs';
export { S as CapitalMajorTwotone } from './icons/polaris/capital_major_twotone.svg.mjs';
export { S as CapturePaymentMinor } from './icons/polaris/capture-payment_minor.svg.mjs';
export { S as CardReaderMajorMonotone } from './icons/polaris/card-reader_major_monotone.svg.mjs';
export { S as CardReaderMajorTwotone } from './icons/polaris/card-reader_major_twotone.svg.mjs';
export { S as CardReaderChipMajorMonotone } from './icons/polaris/card-reader-chip_major_monotone.svg.mjs';
export { S as CardReaderChipMajorTwotone } from './icons/polaris/card-reader-chip_major_twotone.svg.mjs';
export { S as CardReaderTapMajorMonotone } from './icons/polaris/card-reader-tap_major_monotone.svg.mjs';
export { S as CardReaderTapMajorTwotone } from './icons/polaris/card-reader-tap_major_twotone.svg.mjs';
export { S as CaretDownMinor } from './icons/polaris/caret-down_minor.svg.mjs';
export { S as CaretUpMinor } from './icons/polaris/caret-up_minor.svg.mjs';
export { S as CartMajorMonotone } from './icons/polaris/cart_major_monotone.svg.mjs';
export { S as CartMajorTwotone } from './icons/polaris/cart_major_twotone.svg.mjs';
export { S as CartDownMajorMonotone } from './icons/polaris/cart-down_major_monotone.svg.mjs';
export { S as CartDownMajorTwotone } from './icons/polaris/cart-down_major_twotone.svg.mjs';
export { S as CartUpMajorMonotone } from './icons/polaris/cart-up_major_monotone.svg.mjs';
export { S as CartUpMajorTwotone } from './icons/polaris/cart-up_major_twotone.svg.mjs';
export { S as CashDollarMajorMonotone } from './icons/polaris/cash-dollar_major_monotone.svg.mjs';
export { S as CashDollarMajorTwotone } from './icons/polaris/cash-dollar_major_twotone.svg.mjs';
export { S as CashEuroMajorMonotone } from './icons/polaris/cash-euro_major_monotone.svg.mjs';
export { S as CashEuroMajorTwotone } from './icons/polaris/cash-euro_major_twotone.svg.mjs';
export { S as CashPoundMajorMonotone } from './icons/polaris/cash-pound_major_monotone.svg.mjs';
export { S as CashPoundMajorTwotone } from './icons/polaris/cash-pound_major_twotone.svg.mjs';
export { S as CashRupeeMajorMonotone } from './icons/polaris/cash-rupee_major_monotone.svg.mjs';
export { S as CashRupeeMajorTwotone } from './icons/polaris/cash-rupee_major_twotone.svg.mjs';
export { S as CashYenMajorMonotone } from './icons/polaris/cash-yen_major_monotone.svg.mjs';
export { S as CashYenMajorTwotone } from './icons/polaris/cash-yen_major_twotone.svg.mjs';
export { S as CategoriesMajorMonotone } from './icons/polaris/categories_major_monotone.svg.mjs';
export { S as CategoriesMajorTwotone } from './icons/polaris/categories_major_twotone.svg.mjs';
export { S as ChannelsMajorMonotone } from './icons/polaris/channels_major_monotone.svg.mjs';
export { S as ChannelsMajorTwotone } from './icons/polaris/channels_major_twotone.svg.mjs';
export { S as ChatMajorMonotone } from './icons/polaris/chat_major_monotone.svg.mjs';
export { S as ChatMajorTwotone } from './icons/polaris/chat_major_twotone.svg.mjs';
export { S as ChecklistMajorMonotone } from './icons/polaris/checklist_major_monotone.svg.mjs';
export { S as ChecklistMajorTwotone } from './icons/polaris/checklist_major_twotone.svg.mjs';
export { S as ChecklistAlternateMajorMonotone } from './icons/polaris/checklist-alternate_major_monotone.svg.mjs';
export { S as ChecklistAlternateMajorTwotone } from './icons/polaris/checklist-alternate_major_twotone.svg.mjs';
export { S as CheckoutMajorMonotone } from './icons/polaris/checkout_major_monotone.svg.mjs';
export { S as CheckoutMajorTwotone } from './icons/polaris/checkout_major_twotone.svg.mjs';
export { S as ChevronDownMinor } from './icons/polaris/chevron-down_minor.svg.mjs';
export { S as ChevronLeftMinor } from './icons/polaris/chevron-left_minor.svg.mjs';
export { S as ChevronRightMinor } from './icons/polaris/chevron-right_minor.svg.mjs';
export { S as ChevronUpMinor } from './icons/polaris/chevron-up_minor.svg.mjs';
export { S as CircleAlertMajorMonotone } from './icons/polaris/circle-alert_major_monotone.svg.mjs';
export { S as CircleAlertMajorTwotone } from './icons/polaris/circle-alert_major_twotone.svg.mjs';
export { S as CircleCancelMajorMonotone } from './icons/polaris/circle-cancel_major_monotone.svg.mjs';
export { S as CircleCancelMajorTwotone } from './icons/polaris/circle-cancel_major_twotone.svg.mjs';
export { S as CircleCancelMinor } from './icons/polaris/circle-cancel_minor.svg.mjs';
export { S as CircleChevronDownMinor } from './icons/polaris/circle-chevron-down_minor.svg.mjs';
export { S as CircleChevronLeftMinor } from './icons/polaris/circle-chevron-left_minor.svg.mjs';
export { S as CircleChevronRightMinor } from './icons/polaris/circle-chevron-right_minor.svg.mjs';
export { S as CircleChevronUpMinor } from './icons/polaris/circle-chevron-up_minor.svg.mjs';
export { S as CircleDisableMinor } from './icons/polaris/circle-disable_minor.svg.mjs';
export { S as CircleDisabledMajorMonotone } from './icons/polaris/circle-disabled_major_monotone.svg.mjs';
export { S as CircleDisabledMajorTwotone } from './icons/polaris/circle-disabled_major_twotone.svg.mjs';
export { S as CircleDotsMajorMonotone } from './icons/polaris/circle-dots_major_monotone.svg.mjs';
export { S as CircleDotsMajorTwotone } from './icons/polaris/circle-dots_major_twotone.svg.mjs';
export { S as CircleDownMajorMonotone } from './icons/polaris/circle-down_major_monotone.svg.mjs';
export { S as CircleDownMajorTwotone } from './icons/polaris/circle-down_major_twotone.svg.mjs';
export { S as CircleInformationMajorMonotone } from './icons/polaris/circle-information_major_monotone.svg.mjs';
export { S as CircleInformationMajorTwotone } from './icons/polaris/circle-information_major_twotone.svg.mjs';
export { S as CircleLeftMajorMonotone } from './icons/polaris/circle-left_major_monotone.svg.mjs';
export { S as CircleLeftMajorTwotone } from './icons/polaris/circle-left_major_twotone.svg.mjs';
export { S as CircleMinusMajorMonotone } from './icons/polaris/circle-minus_major_monotone.svg.mjs';
export { S as CircleMinusMajorTwotone } from './icons/polaris/circle-minus_major_twotone.svg.mjs';
export { S as CircleMinusMinor } from './icons/polaris/circle-minus_minor.svg.mjs';
export { S as CircleMinusOutlineMinor } from './icons/polaris/circle-minus-outline_minor.svg.mjs';
export { S as CirclePlusMajorMonotone } from './icons/polaris/circle-plus_major_monotone.svg.mjs';
export { S as CirclePlusMajorTwotone } from './icons/polaris/circle-plus_major_twotone.svg.mjs';
export { S as CirclePlusMinor } from './icons/polaris/circle-plus_minor.svg.mjs';
export { S as CirclePlusOutlineMinor } from './icons/polaris/circle-plus-outline_minor.svg.mjs';
export { S as CircleRightMajorMonotone } from './icons/polaris/circle-right_major_monotone.svg.mjs';
export { S as CircleRightMajorTwotone } from './icons/polaris/circle-right_major_twotone.svg.mjs';
export { S as CircleTickMajorMonotone } from './icons/polaris/circle-tick_major_monotone.svg.mjs';
export { S as CircleTickMajorTwotone } from './icons/polaris/circle-tick_major_twotone.svg.mjs';
export { S as CircleTickOutlineMinor } from './icons/polaris/circle-tick-outline_minor.svg.mjs';
export { S as CircleUpMajorMonotone } from './icons/polaris/circle-up_major_monotone.svg.mjs';
export { S as CircleUpMajorTwotone } from './icons/polaris/circle-up_major_twotone.svg.mjs';
export { S as ClipboardMinor } from './icons/polaris/clipboard_minor.svg.mjs';
export { S as ClockMajorMonotone } from './icons/polaris/clock_major_monotone.svg.mjs';
export { S as ClockMajorTwotone } from './icons/polaris/clock_major_twotone.svg.mjs';
export { S as ClockMinor } from './icons/polaris/clock_minor.svg.mjs';
export { S as CodeMajorMonotone } from './icons/polaris/code_major_monotone.svg.mjs';
export { S as CodeMajorTwotone } from './icons/polaris/code_major_twotone.svg.mjs';
export { S as CollectionsMajorMonotone } from './icons/polaris/collections_major_monotone.svg.mjs';
export { S as CollectionsMajorTwotone } from './icons/polaris/collections_major_twotone.svg.mjs';
export { S as ColorNoneMinor } from './icons/polaris/color-none_minor.svg.mjs';
export { S as ColorMajorMonotone, S as ColorsMajorMonotone } from './icons/polaris/colors_major_monotone.svg.mjs';
export { S as ColorMajorTwotone, S as ColorsMajorTwotone } from './icons/polaris/colors_major_twotone.svg.mjs';
export { S as ColumnWithTextMajorMonotone } from './icons/polaris/column-with-text_major_monotone.svg.mjs';
export { S as ColumnWithTextMajorTwotone } from './icons/polaris/column-with-text_major_twotone.svg.mjs';
export { S as ConfettiMajorMonotone } from './icons/polaris/confetti_major_monotone.svg.mjs';
export { S as ConfettiMajorTwotone } from './icons/polaris/confetti_major_twotone.svg.mjs';
export { S as ConnectMinor } from './icons/polaris/connect_minor.svg.mjs';
export { S as ConversationMinor } from './icons/polaris/conversation_minor.svg.mjs';
export { S as CreditCardMajorMonotone } from './icons/polaris/credit-card_major_monotone.svg.mjs';
export { S as CreditCardMajorTwotone } from './icons/polaris/credit-card_major_twotone.svg.mjs';
export { S as CreditCardPercentMajorMonotone } from './icons/polaris/credit-card-percent_major_monotone.svg.mjs';
export { S as CreditCardPercentMajorTwotone } from './icons/polaris/credit-card-percent_major_twotone.svg.mjs';
export { S as CreditCardSecureMajorMonotone } from './icons/polaris/credit-card-secure_major_monotone.svg.mjs';
export { S as CreditCardSecureMajorTwotone } from './icons/polaris/credit-card-secure_major_twotone.svg.mjs';
export { S as CurrencyConvertMinor } from './icons/polaris/currency-convert_minor.svg.mjs';
export { S as CustomerMinusMajorMonotone } from './icons/polaris/customer-minus_major_monotone.svg.mjs';
export { S as CustomerMinusMajorTwotone } from './icons/polaris/customer-minus_major_twotone.svg.mjs';
export { S as CustomerPlusMajorMonotone } from './icons/polaris/customer-plus_major_monotone.svg.mjs';
export { S as CustomerPlusMajorTwotone } from './icons/polaris/customer-plus_major_twotone.svg.mjs';
export { S as CustomersMajorMonotone } from './icons/polaris/customers_major_monotone.svg.mjs';
export { S as CustomersMajorTwotone } from './icons/polaris/customers_major_twotone.svg.mjs';
export { S as CustomersMinor } from './icons/polaris/customers_minor.svg.mjs';
export { S as DataVisualizationMajorMonotone } from './icons/polaris/data-visualization_major_monotone.svg.mjs';
export { S as DataVisualizationMajorTwotone } from './icons/polaris/data-visualization_major_twotone.svg.mjs';
export { S as DeleteMajorMonotone } from './icons/polaris/delete_major_monotone.svg.mjs';
export { S as DeleteMajorTwotone } from './icons/polaris/delete_major_twotone.svg.mjs';
export { S as DeleteMinor } from './icons/polaris/delete_minor.svg.mjs';
export { S as DesktopMajorMonotone } from './icons/polaris/desktop_major_monotone.svg.mjs';
export { S as DesktopMajorTwotone } from './icons/polaris/desktop_major_twotone.svg.mjs';
export { S as DigitalMediaReceiverMajorMonotone } from './icons/polaris/digital-media-receiver_major_monotone.svg.mjs';
export { S as DigitalMediaReceiverMajorTwotone } from './icons/polaris/digital-media-receiver_major_twotone.svg.mjs';
export { S as DiscountAutomaticMajorMonotone } from './icons/polaris/discount-automatic_major_monotone.svg.mjs';
export { S as DiscountAutomaticMajorTwotone } from './icons/polaris/discount-automatic_major_twotone.svg.mjs';
export { S as DiscountCodeMajorMonotone } from './icons/polaris/discount-code_major_monotone.svg.mjs';
export { S as DiscountCodeMajorTwotone } from './icons/polaris/discount-code_major_twotone.svg.mjs';
export { S as DiscountsMajorMonotone } from './icons/polaris/discounts_major_monotone.svg.mjs';
export { S as DiscountsMajorTwotone } from './icons/polaris/discounts_major_twotone.svg.mjs';
export { S as DisputeMinor } from './icons/polaris/dispute_minor.svg.mjs';
export { S as DnsSettingsMajorMonotone } from './icons/polaris/dns-settings_major_monotone.svg.mjs';
export { S as DnsSettingsMajorTwotone } from './icons/polaris/dns-settings_major_twotone.svg.mjs';
export { S as DomainNewMajorMonotone } from './icons/polaris/domain-new_major_monotone.svg.mjs';
export { S as DomainNewMajorTwotone } from './icons/polaris/domain-new_major_twotone.svg.mjs';
export { S as DomainsMajorMonotone } from './icons/polaris/domains_major_monotone.svg.mjs';
export { S as DomainsMajorTwotone } from './icons/polaris/domains_major_twotone.svg.mjs';
export { S as DraftOrdersMajorMonotone } from './icons/polaris/draft-orders_major_monotone.svg.mjs';
export { S as DraftOrdersMajorTwotone } from './icons/polaris/draft-orders_major_twotone.svg.mjs';
export { S as DragDropMajorMonotone } from './icons/polaris/drag-drop_major_monotone.svg.mjs';
export { S as DragDropMajorTwotone } from './icons/polaris/drag-drop_major_twotone.svg.mjs';
export { S as DragHandleMinor } from './icons/polaris/drag-handle_minor.svg.mjs';
export { S as DropdownMinor } from './icons/polaris/dropdown_minor.svg.mjs';
export { S as DuplicateMinor } from './icons/polaris/duplicate_minor.svg.mjs';
export { S as EditMajorMonotone } from './icons/polaris/edit_major_monotone.svg.mjs';
export { S as EditMajorTwotone } from './icons/polaris/edit_major_twotone.svg.mjs';
export { S as EditMinor } from './icons/polaris/edit_minor.svg.mjs';
export { S as EmailMajorMonotone } from './icons/polaris/email_major_monotone.svg.mjs';
export { S as EmailMajorTwotone } from './icons/polaris/email_major_twotone.svg.mjs';
export { S as EmailNewsletterMajorMonotone } from './icons/polaris/email-newsletter_major_monotone.svg.mjs';
export { S as EmailNewsletterMajorTwotone } from './icons/polaris/email-newsletter_major_twotone.svg.mjs';
export { S as EmbedMinor } from './icons/polaris/embed_minor.svg.mjs';
export { S as EnableSelectionMinor } from './icons/polaris/enable-selection_minor.svg.mjs';
export { S as EnvelopeMajorMonotone } from './icons/polaris/envelope_major_monotone.svg.mjs';
export { S as EnvelopeMajorTwotone } from './icons/polaris/envelope_major_twotone.svg.mjs';
export { S as ExchangeMajorMonotone } from './icons/polaris/exchange_major_monotone.svg.mjs';
export { S as ExchangeMajorTwotone } from './icons/polaris/exchange_major_twotone.svg.mjs';
export { S as ExistingInventoryMajorMonotone } from './icons/polaris/existing-inventory_major_monotone.svg.mjs';
export { S as ExistingInventoryMajorTwotone } from './icons/polaris/existing-inventory_major_twotone.svg.mjs';
export { S as ExportMinor } from './icons/polaris/export_minor.svg.mjs';
export { S as ExternalMinor } from './icons/polaris/external_minor.svg.mjs';
export { S as ExternalSmallMinor } from './icons/polaris/external-small_minor.svg.mjs';
export { S as FaviconMajorMonotone } from './icons/polaris/favicon_major_monotone.svg.mjs';
export { S as FaviconMajorTwotone } from './icons/polaris/favicon_major_twotone.svg.mjs';
export { S as FavoriteMajorMonotone, S as FavouriteMonotone } from './icons/polaris/favorite_major_monotone.svg.mjs';
export { S as FavoriteMajorTwotone, S as FavouriteTwotone } from './icons/polaris/favorite_major_twotone.svg.mjs';
export { S as FeaturedCollectionMajorMonotone } from './icons/polaris/featured-collection_major_monotone.svg.mjs';
export { S as FeaturedCollectionMajorTwotone } from './icons/polaris/featured-collection_major_twotone.svg.mjs';
export { S as FeaturedContentMajorMonotone } from './icons/polaris/featured-content_major_monotone.svg.mjs';
export { S as FeaturedContentMajorTwotone } from './icons/polaris/featured-content_major_twotone.svg.mjs';
export { S as FilterMajorMonotone } from './icons/polaris/filter_major_monotone.svg.mjs';
export { S as FilterMajorTwotone } from './icons/polaris/filter_major_twotone.svg.mjs';
export { S as FirstOrderMajorMonotone } from './icons/polaris/first-order_major_monotone.svg.mjs';
export { S as FirstOrderMajorTwotone } from './icons/polaris/first-order_major_twotone.svg.mjs';
export { S as FirstVisitMajorMonotone } from './icons/polaris/first-visit_major_monotone.svg.mjs';
export { S as FirstVisitMajorTwotone } from './icons/polaris/first-visit_major_twotone.svg.mjs';
export { S as FlagMajorMonotone } from './icons/polaris/flag_major_monotone.svg.mjs';
export { S as FlagMajorTwotone } from './icons/polaris/flag_major_twotone.svg.mjs';
export { S as FlipCameraMajorMonotone } from './icons/polaris/flip-camera_major_monotone.svg.mjs';
export { S as FlipCameraMajorTwotone } from './icons/polaris/flip-camera_major_twotone.svg.mjs';
export { S as FolderMajorMonotone } from './icons/polaris/folder_major_monotone.svg.mjs';
export { S as FolderMajorTwotone } from './icons/polaris/folder_major_twotone.svg.mjs';
export { S as FolderDownMajorMonotone } from './icons/polaris/folder-down_major_monotone.svg.mjs';
export { S as FolderDownMajorTwotone } from './icons/polaris/folder-down_major_twotone.svg.mjs';
export { S as FolderMinusMajorMonotone } from './icons/polaris/folder-minus_major_monotone.svg.mjs';
export { S as FolderMinusMajorTwotone } from './icons/polaris/folder-minus_major_twotone.svg.mjs';
export { S as FolderPlusMajorMonotone } from './icons/polaris/folder-plus_major_monotone.svg.mjs';
export { S as FolderPlusMajorTwotone } from './icons/polaris/folder-plus_major_twotone.svg.mjs';
export { S as FolderUpMajorMonotone } from './icons/polaris/folder-up_major_monotone.svg.mjs';
export { S as FolderUpMajorTwotone } from './icons/polaris/folder-up_major_twotone.svg.mjs';
export { S as FollowUpEmailMajorMonotone } from './icons/polaris/follow-up-email_major_monotone.svg.mjs';
export { S as FollowUpEmailMajorTwotone } from './icons/polaris/follow-up-email_major_twotone.svg.mjs';
export { S as FoodMajorMonotone } from './icons/polaris/food_major_monotone.svg.mjs';
export { S as FoodMajorTwotone } from './icons/polaris/food_major_twotone.svg.mjs';
export { S as FooterMajorMonotone } from './icons/polaris/footer_major_monotone.svg.mjs';
export { S as FooterMajorTwotone } from './icons/polaris/footer_major_twotone.svg.mjs';
export { S as FormsMajorMonotone } from './icons/polaris/forms_major_monotone.svg.mjs';
export { S as FormsMajorTwotone } from './icons/polaris/forms_major_twotone.svg.mjs';
export { S as GamesConsoleMajorMonotone } from './icons/polaris/games-console_major_monotone.svg.mjs';
export { S as GamesConsoleMajorTwotone } from './icons/polaris/games-console_major_twotone.svg.mjs';
export { S as GiftCardMajorMonotone } from './icons/polaris/gift-card_major_monotone.svg.mjs';
export { S as GiftCardMajorTwotone } from './icons/polaris/gift-card_major_twotone.svg.mjs';
export { S as GiftCardMinor } from './icons/polaris/gift-card_minor.svg.mjs';
export { S as GlobeMajorMonotone } from './icons/polaris/globe_major_monotone.svg.mjs';
export { S as GlobeMajorTwotone } from './icons/polaris/globe_major_twotone.svg.mjs';
export { S as GlobeMinor } from './icons/polaris/globe_minor.svg.mjs';
export { S as GrammarMajorMonotone } from './icons/polaris/grammar_major_monotone.svg.mjs';
export { S as GrammarMajorTwotone } from './icons/polaris/grammar_major_twotone.svg.mjs';
export { S as HashtagMajorMonotone } from './icons/polaris/hashtag_major_monotone.svg.mjs';
export { S as HashtagMajorTwotone } from './icons/polaris/hashtag_major_twotone.svg.mjs';
export { S as HeaderMajorMonotone } from './icons/polaris/header_major_monotone.svg.mjs';
export { S as HeaderMajorTwotone } from './icons/polaris/header_major_twotone.svg.mjs';
export { S as HeartMajorMonotone } from './icons/polaris/heart_major_monotone.svg.mjs';
export { S as HeartMajorTwotone } from './icons/polaris/heart_major_twotone.svg.mjs';
export { S as HideMinor } from './icons/polaris/hide_minor.svg.mjs';
export { S as HideKeyboardMajorMonotone } from './icons/polaris/hide-keyboard_major_monotone.svg.mjs';
export { S as HideKeyboardMajorTwotone } from './icons/polaris/hide-keyboard_major_twotone.svg.mjs';
export { S as HintMajorMonotone } from './icons/polaris/hint_major_monotone.svg.mjs';
export { S as HintMajorTwotone } from './icons/polaris/hint_major_twotone.svg.mjs';
export { S as HomeMajorMonotone } from './icons/polaris/home_major_monotone.svg.mjs';
export { S as HomeMajorTwotone } from './icons/polaris/home_major_twotone.svg.mjs';
export { S as HorizontalDotsMinor } from './icons/polaris/horizontal-dots_minor.svg.mjs';
export { S as IconsMajorMonotone } from './icons/polaris/icons_major_monotone.svg.mjs';
export { S as IconsMajorTwotone } from './icons/polaris/icons_major_twotone.svg.mjs';
export { S as IllustrationMajorMonotone } from './icons/polaris/illustration_major_monotone.svg.mjs';
export { S as IllustrationMajorTwotone } from './icons/polaris/illustration_major_twotone.svg.mjs';
export { S as ImageMajorMonotone } from './icons/polaris/image_major_monotone.svg.mjs';
export { S as ImageMajorTwotone } from './icons/polaris/image_major_twotone.svg.mjs';
export { S as ImageAltMajorMonotone } from './icons/polaris/image-alt_major_monotone.svg.mjs';
export { S as ImageAltMajorTwotone } from './icons/polaris/image-alt_major_twotone.svg.mjs';
export { S as ImageAltMinor } from './icons/polaris/image-alt_minor.svg.mjs';
export { S as ImageWithTextMajorMonotone } from './icons/polaris/image-with-text_major_monotone.svg.mjs';
export { S as ImageWithTextMajorTwotone } from './icons/polaris/image-with-text_major_twotone.svg.mjs';
export { S as ImageWithTextOverlayMajorMonotone } from './icons/polaris/image-with-text-overlay_major_monotone.svg.mjs';
export { S as ImageWithTextOverlayMajorTwotone } from './icons/polaris/image-with-text-overlay_major_twotone.svg.mjs';
export { S as ImagesMajorMonotone } from './icons/polaris/images_major_monotone.svg.mjs';
export { S as ImagesMajorTwotone } from './icons/polaris/images_major_twotone.svg.mjs';
export { S as ImportMinor } from './icons/polaris/import_minor.svg.mjs';
export { S as ImportStoreMajorMonotone } from './icons/polaris/import-store_major_monotone.svg.mjs';
export { S as ImportStoreMajorTwotone } from './icons/polaris/import-store_major_twotone.svg.mjs';
export { S as IncomingMajorMonotone } from './icons/polaris/incoming_major_monotone.svg.mjs';
export { S as IncomingMajorTwotone } from './icons/polaris/incoming_major_twotone.svg.mjs';
export { S as InfoMinor } from './icons/polaris/info_minor.svg.mjs';
export { S as InstallMinor } from './icons/polaris/install_minor.svg.mjs';
export { S as InventoryMajorMonotone } from './icons/polaris/inventory_major_monotone.svg.mjs';
export { S as InventoryMajorTwotone } from './icons/polaris/inventory_major_twotone.svg.mjs';
export { S as InviteMinor } from './icons/polaris/invite_minor.svg.mjs';
export { S as IqMajorMonotone } from './icons/polaris/iq_major_monotone.svg.mjs';
export { S as IqMajorTwotone } from './icons/polaris/iq_major_twotone.svg.mjs';
export { S as JobsMajorMonotone } from './icons/polaris/jobs_major_monotone.svg.mjs';
export { S as JobsMajorTwotone } from './icons/polaris/jobs_major_twotone.svg.mjs';
export { S as KeyMajorMonotone } from './icons/polaris/key_major_monotone.svg.mjs';
export { S as KeyMajorTwotone } from './icons/polaris/key_major_twotone.svg.mjs';
export { S as LabelPrinterMajorMonotone } from './icons/polaris/label-printer_major_monotone.svg.mjs';
export { S as LabelPrinterMajorTwotone } from './icons/polaris/label-printer_major_twotone.svg.mjs';
export { S as LandingPageMajorMonotone } from './icons/polaris/landing-page_major_monotone.svg.mjs';
export { S as LandingPageMajorTwotone } from './icons/polaris/landing-page_major_twotone.svg.mjs';
export { S as LanguageMinor } from './icons/polaris/language_minor.svg.mjs';
export { S as LegalMajorMonotone } from './icons/polaris/legal_major_monotone.svg.mjs';
export { S as LegalMajorTwotone } from './icons/polaris/legal_major_twotone.svg.mjs';
export { S as LinkMinor } from './icons/polaris/link_minor.svg.mjs';
export { S as ListMajorMonotone } from './icons/polaris/list_major_monotone.svg.mjs';
export { S as ListMajorTwotone } from './icons/polaris/list_major_twotone.svg.mjs';
export { S as LiveViewMajorMonotone } from './icons/polaris/live-view_major_monotone.svg.mjs';
export { S as LiveViewMajorTwotone } from './icons/polaris/live-view_major_twotone.svg.mjs';
export { S as LocationMajorMonotone } from './icons/polaris/location_major_monotone.svg.mjs';
export { S as LocationMajorTwotone } from './icons/polaris/location_major_twotone.svg.mjs';
export { S as LocationsMinor } from './icons/polaris/locations_minor.svg.mjs';
export { S as LockMajorMonotone } from './icons/polaris/lock_major_monotone.svg.mjs';
export { S as LockMajorTwotone } from './icons/polaris/lock_major_twotone.svg.mjs';
export { S as LockMinor } from './icons/polaris/lock_minor.svg.mjs';
export { S as LogOutMinor } from './icons/polaris/log-out_minor.svg.mjs';
export { S as LogoBlockMajorMonotone } from './icons/polaris/logo-block_major_monotone.svg.mjs';
export { S as LogoBlockMajorTwotone } from './icons/polaris/logo-block_major_twotone.svg.mjs';
export { S as ManagedStoreMajorMonotone } from './icons/polaris/managed-store_major_monotone.svg.mjs';
export { S as ManagedStoreMajorTwotone } from './icons/polaris/managed-store_major_twotone.svg.mjs';
export { S as MarkFulfilledMinor } from './icons/polaris/mark-fulfilled_minor.svg.mjs';
export { S as MarkPaidMinor } from './icons/polaris/mark-paid_minor.svg.mjs';
export { S as MarketingMajorMonotone } from './icons/polaris/marketing_major_monotone.svg.mjs';
export { S as MarketingMajorTwotone } from './icons/polaris/marketing_major_twotone.svg.mjs';
export { S as MaximizeMajorMonotone } from './icons/polaris/maximize_major_monotone.svg.mjs';
export { S as MaximizeMajorTwotone } from './icons/polaris/maximize_major_twotone.svg.mjs';
export { S as MaximizeMinor } from './icons/polaris/maximize_minor.svg.mjs';
export { S as MentionMajorMonotone } from './icons/polaris/mention_major_monotone.svg.mjs';
export { S as MentionMajorTwotone } from './icons/polaris/mention_major_twotone.svg.mjs';
export { S as MicrophoneMajorMonotone } from './icons/polaris/microphone_major_monotone.svg.mjs';
export { S as MicrophoneMajorTwotone } from './icons/polaris/microphone_major_twotone.svg.mjs';
export { S as MinimizeMajorMonotone } from './icons/polaris/minimize_major_monotone.svg.mjs';
export { S as MinimizeMajorTwotone } from './icons/polaris/minimize_major_twotone.svg.mjs';
export { S as MinimizeMinor } from './icons/polaris/minimize_minor.svg.mjs';
export { S as MinusMinor } from './icons/polaris/minus_minor.svg.mjs';
export { S as MobileMajorMonotone } from './icons/polaris/mobile_major_monotone.svg.mjs';
export { S as MobileMajorTwotone } from './icons/polaris/mobile_major_twotone.svg.mjs';
export { S as MobileAcceptMajorMonotone } from './icons/polaris/mobile-accept_major_monotone.svg.mjs';
export { S as MobileAcceptMajorTwotone } from './icons/polaris/mobile-accept_major_twotone.svg.mjs';
export { S as MobileBackArrowMajorMonotone } from './icons/polaris/mobile-back-arrow_major_monotone.svg.mjs';
export { S as MobileBackArrowMajorTwotone } from './icons/polaris/mobile-back-arrow_major_twotone.svg.mjs';
export { S as MobileCancelMajorMonotone } from './icons/polaris/mobile-cancel_major_monotone.svg.mjs';
export { S as MobileCancelMajorTwotone } from './icons/polaris/mobile-cancel_major_twotone.svg.mjs';
export { S as MobileChevronMajorMonotone } from './icons/polaris/mobile-chevron_major_monotone.svg.mjs';
export { S as MobileChevronMajorTwotone } from './icons/polaris/mobile-chevron_major_twotone.svg.mjs';
export { S as MobileHamburgerMajorMonotone } from './icons/polaris/mobile-hamburger_major_monotone.svg.mjs';
export { S as MobileHamburgerMajorTwotone } from './icons/polaris/mobile-hamburger_major_twotone.svg.mjs';
export { S as MobileHorizontalDotsMajorMonotone } from './icons/polaris/mobile-horizontal-dots_major_monotone.svg.mjs';
export { S as MobileHorizontalDotsMajorTwotone } from './icons/polaris/mobile-horizontal-dots_major_twotone.svg.mjs';
export { S as MobilePlusMajorMonotone } from './icons/polaris/mobile-plus_major_monotone.svg.mjs';
export { S as MobilePlusMajorTwotone } from './icons/polaris/mobile-plus_major_twotone.svg.mjs';
export { S as MobileVerticalDotsMajorMonotone } from './icons/polaris/mobile-vertical-dots_major_monotone.svg.mjs';
export { S as MobileVerticalDotsMajorTwotone } from './icons/polaris/mobile-vertical-dots_major_twotone.svg.mjs';
export { S as MonerisMajorMonotone } from './icons/polaris/moneris_major_monotone.svg.mjs';
export { S as MonerisMajorTwotone } from './icons/polaris/moneris_major_twotone.svg.mjs';
export { S as NatureMajorMonotone } from './icons/polaris/nature_major_monotone.svg.mjs';
export { S as NatureMajorTwotone } from './icons/polaris/nature_major_twotone.svg.mjs';
export { S as NavigationMajorMonotone } from './icons/polaris/navigation_major_monotone.svg.mjs';
export { S as NavigationMajorTwotone } from './icons/polaris/navigation_major_twotone.svg.mjs';
export { S as NoteMajorMonotone } from './icons/polaris/note_major_monotone.svg.mjs';
export { S as NoteMajorTwotone } from './icons/polaris/note_major_twotone.svg.mjs';
export { S as NoteMinor } from './icons/polaris/note_minor.svg.mjs';
export { S as NotesMinor } from './icons/polaris/notes_minor.svg.mjs';
export { S as NotificationMajorMonotone } from './icons/polaris/notification_major_monotone.svg.mjs';
export { S as NotificationMajorTwotone } from './icons/polaris/notification_major_twotone.svg.mjs';
export { S as OnlineStoreMajorMonotone } from './icons/polaris/online-store_major_monotone.svg.mjs';
export { S as OnlineStoreMajorTwotone } from './icons/polaris/online-store_major_twotone.svg.mjs';
export { S as OrderStatusMinor } from './icons/polaris/order-status_minor.svg.mjs';
export { S as OrdersMajorMonotone } from './icons/polaris/orders_major_monotone.svg.mjs';
export { S as OrdersMajorTwotone } from './icons/polaris/orders_major_twotone.svg.mjs';
export { S as OutgoingMajorMonotone } from './icons/polaris/outgoing_major_monotone.svg.mjs';
export { S as OutgoingMajorTwotone } from './icons/polaris/outgoing_major_twotone.svg.mjs';
export { S as PackageMajorMonotone } from './icons/polaris/package_major_monotone.svg.mjs';
export { S as PackageMajorTwotone } from './icons/polaris/package_major_twotone.svg.mjs';
export { S as PageMajorMonotone } from './icons/polaris/page_major_monotone.svg.mjs';
export { S as PageMajorTwotone } from './icons/polaris/page_major_twotone.svg.mjs';
export { S as PageDownMajorMonotone } from './icons/polaris/page-down_major_monotone.svg.mjs';
export { S as PageDownMajorTwotone } from './icons/polaris/page-down_major_twotone.svg.mjs';
export { S as PageMinusMajorMonotone } from './icons/polaris/page-minus_major_monotone.svg.mjs';
export { S as PageMinusMajorTwotone } from './icons/polaris/page-minus_major_twotone.svg.mjs';
export { S as PagePlusMajorMonotone } from './icons/polaris/page-plus_major_monotone.svg.mjs';
export { S as PagePlusMajorTwotone } from './icons/polaris/page-plus_major_twotone.svg.mjs';
export { S as PageUpMajorMonotone } from './icons/polaris/page-up_major_monotone.svg.mjs';
export { S as PageUpMajorTwotone } from './icons/polaris/page-up_major_twotone.svg.mjs';
export { S as PaginationEndMinor } from './icons/polaris/pagination-end_minor.svg.mjs';
export { S as PaginationStartMinor } from './icons/polaris/pagination-start_minor.svg.mjs';
export { S as PaintBrushMajorMonotone } from './icons/polaris/paint-brush_major_monotone.svg.mjs';
export { S as PaintBrushMajorTwotone } from './icons/polaris/paint-brush_major_twotone.svg.mjs';
export { S as PauseMajorMonotone } from './icons/polaris/pause_major_monotone.svg.mjs';
export { S as PauseMajorTwotone } from './icons/polaris/pause_major_twotone.svg.mjs';
export { S as PauseMinor } from './icons/polaris/pause_minor.svg.mjs';
export { S as PauseCircleMajorMonotone } from './icons/polaris/pause-circle_major_monotone.svg.mjs';
export { S as PauseCircleMajorTwotone } from './icons/polaris/pause-circle_major_twotone.svg.mjs';
export { S as PaymentsMajorMonotone } from './icons/polaris/payments_major_monotone.svg.mjs';
export { S as PaymentsMajorTwotone } from './icons/polaris/payments_major_twotone.svg.mjs';
export { S as PhoneMajorMonotone } from './icons/polaris/phone_major_monotone.svg.mjs';
export { S as PhoneMajorTwotone } from './icons/polaris/phone_major_twotone.svg.mjs';
export { S as PhoneInMajorMonotone } from './icons/polaris/phone-in_major_monotone.svg.mjs';
export { S as PhoneInMajorTwotone } from './icons/polaris/phone-in_major_twotone.svg.mjs';
export { S as PhoneOutMajorMonotone } from './icons/polaris/phone-out_major_monotone.svg.mjs';
export { S as PhoneOutMajorTwotone } from './icons/polaris/phone-out_major_twotone.svg.mjs';
export { S as PinMajorMonotone } from './icons/polaris/pin_major_monotone.svg.mjs';
export { S as PinMajorTwotone } from './icons/polaris/pin_major_twotone.svg.mjs';
export { S as PinMinor } from './icons/polaris/pin_minor.svg.mjs';
export { S as PlayMajorMonotone } from './icons/polaris/play_major_monotone.svg.mjs';
export { S as PlayMajorTwotone } from './icons/polaris/play_major_twotone.svg.mjs';
export { S as PlayMinor } from './icons/polaris/play_minor.svg.mjs';
export { S as PlayCircleMajorMonotone } from './icons/polaris/play-circle_major_monotone.svg.mjs';
export { S as PlayCircleMajorTwotone } from './icons/polaris/play-circle_major_twotone.svg.mjs';
export { S as PlusMinor } from './icons/polaris/plus_minor.svg.mjs';
export { S as PointOfSaleMajorMonotone } from './icons/polaris/point-of-sale_major_monotone.svg.mjs';
export { S as PointOfSaleMajorTwotone } from './icons/polaris/point-of-sale_major_twotone.svg.mjs';
export { S as PopularMajorMonotone } from './icons/polaris/popular_major_monotone.svg.mjs';
export { S as PopularMajorTwotone } from './icons/polaris/popular_major_twotone.svg.mjs';
export { S as PriceLookupMinor } from './icons/polaris/price-lookup_minor.svg.mjs';
export { S as PrintMajorMonotone } from './icons/polaris/print_major_monotone.svg.mjs';
export { S as PrintMajorTwotone } from './icons/polaris/print_major_twotone.svg.mjs';
export { S as PrintMinor } from './icons/polaris/print_minor.svg.mjs';
export { S as ProductsMajorMonotone } from './icons/polaris/products_major_monotone.svg.mjs';
export { S as ProductsMajorTwotone } from './icons/polaris/products_major_twotone.svg.mjs';
export { S as ProfileMajorMonotone } from './icons/polaris/profile_major_monotone.svg.mjs';
export { S as ProfileMajorTwotone } from './icons/polaris/profile_major_twotone.svg.mjs';
export { S as ProfileMinor } from './icons/polaris/profile_minor.svg.mjs';
export { S as PromoteMinor } from './icons/polaris/promote_minor.svg.mjs';
export { S as QuestionMarkMajorMonotone } from './icons/polaris/question-mark_major_monotone.svg.mjs';
export { S as QuestionMarkMajorTwotone } from './icons/polaris/question-mark_major_twotone.svg.mjs';
export { S as QuestionMarkMinor } from './icons/polaris/question-mark_minor.svg.mjs';
export { S as QuickSaleMajorMonotone } from './icons/polaris/quick-sale_major_monotone.svg.mjs';
export { S as QuickSaleMajorTwotone } from './icons/polaris/quick-sale_major_twotone.svg.mjs';
export { S as ReadTimeMinor } from './icons/polaris/read-time_minor.svg.mjs';
export { S as ReceiptMajorMonotone } from './icons/polaris/receipt_major_monotone.svg.mjs';
export { S as ReceiptMajorTwotone } from './icons/polaris/receipt_major_twotone.svg.mjs';
export { S as RecentSearchesMajorMonotone } from './icons/polaris/recent-searches_major_monotone.svg.mjs';
export { S as RecentSearchesMajorTwotone } from './icons/polaris/recent-searches_major_twotone.svg.mjs';
export { S as RedoMajorMonotone } from './icons/polaris/redo_major_monotone.svg.mjs';
export { S as RedoMajorTwotone } from './icons/polaris/redo_major_twotone.svg.mjs';
export { S as ReferralMajorMonotone } from './icons/polaris/referral_major_monotone.svg.mjs';
export { S as ReferralMajorTwotone } from './icons/polaris/referral_major_twotone.svg.mjs';
export { S as ReferralCodeMajorMonotone } from './icons/polaris/referral-code_major_monotone.svg.mjs';
export { S as ReferralCodeMajorTwotone } from './icons/polaris/referral-code_major_twotone.svg.mjs';
export { S as RefreshMajorMonotone } from './icons/polaris/refresh_major_monotone.svg.mjs';
export { S as RefreshMajorTwotone } from './icons/polaris/refresh_major_twotone.svg.mjs';
export { S as RefreshMinor } from './icons/polaris/refresh_minor.svg.mjs';
export { S as RefundMajorMonotone } from './icons/polaris/refund_major_monotone.svg.mjs';
export { S as RefundMajorTwotone } from './icons/polaris/refund_major_twotone.svg.mjs';
export { S as RefundMinor } from './icons/polaris/refund_minor.svg.mjs';
export { S as RemoveProductMajorMonotone } from './icons/polaris/remove-product_major_monotone.svg.mjs';
export { S as RemoveProductMajorTwotone } from './icons/polaris/remove-product_major_twotone.svg.mjs';
export { S as RepeatOrderMajorMonotone } from './icons/polaris/repeat-order_major_monotone.svg.mjs';
export { S as RepeatOrderMajorTwotone } from './icons/polaris/repeat-order_major_twotone.svg.mjs';
export { S as ReplaceMajorMonotone } from './icons/polaris/replace_major_monotone.svg.mjs';
export { S as ReplaceMajorTwotone } from './icons/polaris/replace_major_twotone.svg.mjs';
export { S as ReplayMinor } from './icons/polaris/replay_minor.svg.mjs';
export { S as ReportMinor } from './icons/polaris/report_minor.svg.mjs';
export { S as ReportsMajorMonotone } from './icons/polaris/reports_major_monotone.svg.mjs';
export { S as ReportsMajorTwotone } from './icons/polaris/reports_major_twotone.svg.mjs';
export { S as ResetMinor } from './icons/polaris/reset_minor.svg.mjs';
export { S as ResourcesMajorMonotone } from './icons/polaris/resources_major_monotone.svg.mjs';
export { S as ResourcesMajorTwotone } from './icons/polaris/resources_major_twotone.svg.mjs';
export { S as ReturnMinor } from './icons/polaris/return_minor.svg.mjs';
export { S as RiskMajorMonotone } from './icons/polaris/risk_major_monotone.svg.mjs';
export { S as RiskMajorTwotone } from './icons/polaris/risk_major_twotone.svg.mjs';
export { S as RiskMinor } from './icons/polaris/risk_minor.svg.mjs';
export { S as SandboxMajorMonotone } from './icons/polaris/sandbox_major_monotone.svg.mjs';
export { S as SandboxMajorTwotone } from './icons/polaris/sandbox_major_twotone.svg.mjs';
export { S as SaveMinor } from './icons/polaris/save_minor.svg.mjs';
export { S as SearchMajorMonotone } from './icons/polaris/search_major_monotone.svg.mjs';
export { S as SearchMajorTwotone } from './icons/polaris/search_major_twotone.svg.mjs';
export { S as SearchMinor } from './icons/polaris/search_minor.svg.mjs';
export { S as SectionMajorMonotone } from './icons/polaris/section_major_monotone.svg.mjs';
export { S as SectionMajorTwotone } from './icons/polaris/section_major_twotone.svg.mjs';
export { S as SecureMajorMonotone } from './icons/polaris/secure_major_monotone.svg.mjs';
export { S as SecureMajorTwotone } from './icons/polaris/secure_major_twotone.svg.mjs';
export { S as ArrowUpDownMinor, S as SelectMinor } from './icons/polaris/select_minor.svg.mjs';
export { S as SendMajorMonotone } from './icons/polaris/send_major_monotone.svg.mjs';
export { S as SendMajorTwotone } from './icons/polaris/send_major_twotone.svg.mjs';
export { S as SettingsMajorMonotone } from './icons/polaris/settings_major_monotone.svg.mjs';
export { S as SettingsMajorTwotone } from './icons/polaris/settings_major_twotone.svg.mjs';
export { S as SettingsMinor } from './icons/polaris/settings_minor.svg.mjs';
export { S as ShareMinor } from './icons/polaris/share_minor.svg.mjs';
export { S as ShareIosMinor } from './icons/polaris/share-ios_minor.svg.mjs';
export { S as ShipmentMajorMonotone } from './icons/polaris/shipment_major_monotone.svg.mjs';
export { S as ShipmentMajorTwotone } from './icons/polaris/shipment_major_twotone.svg.mjs';
export { S as ShopcodesMajorMonotone } from './icons/polaris/shopcodes_major_monotone.svg.mjs';
export { S as ShopcodesMajorTwotone } from './icons/polaris/shopcodes_major_twotone.svg.mjs';
export { S as SidebarLeftMajorMonotone, S as SidebarMajorMonotone } from './icons/polaris/sidebar-left_major_monotone.svg.mjs';
export { S as SidebarLeftMajorTwotone, S as SidebarMajorTwotone } from './icons/polaris/sidebar-left_major_twotone.svg.mjs';
export { S as SidebarRightMajorMonotone } from './icons/polaris/sidebar-right_major_monotone.svg.mjs';
export { S as SidebarRightMajorTwotone } from './icons/polaris/sidebar-right_major_twotone.svg.mjs';
export { S as SlideshowMajorMonotone } from './icons/polaris/slideshow_major_monotone.svg.mjs';
export { S as SlideshowMajorTwotone } from './icons/polaris/slideshow_major_twotone.svg.mjs';
export { S as SmileyHappyMajorMonotone } from './icons/polaris/smiley-happy_major_monotone.svg.mjs';
export { S as SmileyHappyMajorTwotone } from './icons/polaris/smiley-happy_major_twotone.svg.mjs';
export { S as SmileyJoyMajorMonotone } from './icons/polaris/smiley-joy_major_monotone.svg.mjs';
export { S as SmileyJoyMajorTwotone } from './icons/polaris/smiley-joy_major_twotone.svg.mjs';
export { S as SmileyNeutralMajorMonotone } from './icons/polaris/smiley-neutral_major_monotone.svg.mjs';
export { S as SmileyNeutralMajorTwotone } from './icons/polaris/smiley-neutral_major_twotone.svg.mjs';
export { S as SmileySadMajorMonotone } from './icons/polaris/smiley-sad_major_monotone.svg.mjs';
export { S as SmileySadMajorTwotone } from './icons/polaris/smiley-sad_major_twotone.svg.mjs';
export { S as SocialAdMajorMonotone } from './icons/polaris/social-ad_major_monotone.svg.mjs';
export { S as SocialAdMajorTwotone } from './icons/polaris/social-ad_major_twotone.svg.mjs';
export { S as SocialPostMajorMonotone } from './icons/polaris/social-post_major_monotone.svg.mjs';
export { S as SocialPostMajorTwotone } from './icons/polaris/social-post_major_twotone.svg.mjs';
export { S as SoftPackMajorMonotone } from './icons/polaris/soft-pack_major_monotone.svg.mjs';
export { S as SoftPackMajorTwotone } from './icons/polaris/soft-pack_major_twotone.svg.mjs';
export { S as SortMinor } from './icons/polaris/sort_minor.svg.mjs';
export { S as SortAscendingMajorMonotone } from './icons/polaris/sort-ascending_major_monotone.svg.mjs';
export { S as SortAscendingMajorTwotone } from './icons/polaris/sort-ascending_major_twotone.svg.mjs';
export { S as SortDescendingMajorMonotone } from './icons/polaris/sort-descending_major_monotone.svg.mjs';
export { S as SortDescendingMajorTwotone } from './icons/polaris/sort-descending_major_twotone.svg.mjs';
export { S as SoundMajorMonotone } from './icons/polaris/sound_major_monotone.svg.mjs';
export { S as SoundMajorTwotone } from './icons/polaris/sound_major_twotone.svg.mjs';
export { S as StarFilledMinor } from './icons/polaris/star-filled_minor.svg.mjs';
export { S as StarOutlineMinor } from './icons/polaris/star-outline_minor.svg.mjs';
export { S as StoreMajorMonotone } from './icons/polaris/store_major_monotone.svg.mjs';
export { S as StoreMajorTwotone } from './icons/polaris/store_major_twotone.svg.mjs';
export { S as StoreStatusMajorMonotone } from './icons/polaris/store-status_major_monotone.svg.mjs';
export { S as StoreStatusMajorTwotone } from './icons/polaris/store-status_major_twotone.svg.mjs';
export { S as TabletMajorMonotone } from './icons/polaris/tablet_major_monotone.svg.mjs';
export { S as TabletMajorTwotone } from './icons/polaris/tablet_major_twotone.svg.mjs';
export { S as TapChipMajorMonotone } from './icons/polaris/tap-chip_major_monotone.svg.mjs';
export { S as TapChipMajorTwotone } from './icons/polaris/tap-chip_major_twotone.svg.mjs';
export { S as TaxMajorMonotone } from './icons/polaris/tax_major_monotone.svg.mjs';
export { S as TaxMajorTwotone } from './icons/polaris/tax_major_twotone.svg.mjs';
export { S as TeamMajorMonotone } from './icons/polaris/team_major_monotone.svg.mjs';
export { S as TeamMajorTwotone } from './icons/polaris/team_major_twotone.svg.mjs';
export { S as TextMajorMonotone } from './icons/polaris/text_major_monotone.svg.mjs';
export { S as TextMajorTwotone } from './icons/polaris/text_major_twotone.svg.mjs';
export { S as TextAlignmentCenterMajorMonotone } from './icons/polaris/text-alignment-center_major_monotone.svg.mjs';
export { S as TextAlignmentCenterMajorTwotone } from './icons/polaris/text-alignment-center_major_twotone.svg.mjs';
export { S as TextAlignmentLeftMajorMonotone } from './icons/polaris/text-alignment-left_major_monotone.svg.mjs';
export { S as TextAlignmentLeftMajorTwotone } from './icons/polaris/text-alignment-left_major_twotone.svg.mjs';
export { S as TextAlignmentRightMajorMonotone } from './icons/polaris/text-alignment-right_major_monotone.svg.mjs';
export { S as TextAlignmentRightMajorTwotone } from './icons/polaris/text-alignment-right_major_twotone.svg.mjs';
export { S as TextBlockMajorMonotone } from './icons/polaris/text-block_major_monotone.svg.mjs';
export { S as TextBlockMajorTwotone } from './icons/polaris/text-block_major_twotone.svg.mjs';
export { S as ThemeEditMajorMonotone } from './icons/polaris/theme-edit_major_monotone.svg.mjs';
export { S as ThemeEditMajorTwotone } from './icons/polaris/theme-edit_major_twotone.svg.mjs';
export { S as ThemeStoreMajorMonotone } from './icons/polaris/theme-store_major_monotone.svg.mjs';
export { S as ThemeStoreMajorTwotone } from './icons/polaris/theme-store_major_twotone.svg.mjs';
export { S as ThemesMajorMonotone } from './icons/polaris/themes_major_monotone.svg.mjs';
export { S as ThemesMajorTwotone } from './icons/polaris/themes_major_twotone.svg.mjs';
export { S as ThumbsDownMajorMonotone } from './icons/polaris/thumbs-down_major_monotone.svg.mjs';
export { S as ThumbsDownMajorTwotone } from './icons/polaris/thumbs-down_major_twotone.svg.mjs';
export { S as ThumbsDownMinor } from './icons/polaris/thumbs-down_minor.svg.mjs';
export { S as ThumbsUpMajorMonotone } from './icons/polaris/thumbs-up_major_monotone.svg.mjs';
export { S as ThumbsUpMajorTwotone } from './icons/polaris/thumbs-up_major_twotone.svg.mjs';
export { S as ThumbsUpMinor } from './icons/polaris/thumbs-up_minor.svg.mjs';
export { S as TickMinor } from './icons/polaris/tick_minor.svg.mjs';
export { S as TickSmallMinor } from './icons/polaris/tick-small_minor.svg.mjs';
export { S as TimelineAttachmentMajorMonotone } from './icons/polaris/timeline-attachment_major_monotone.svg.mjs';
export { S as TimelineAttachmentMajorTwotone } from './icons/polaris/timeline-attachment_major_twotone.svg.mjs';
export { S as TipsMajorMonotone } from './icons/polaris/tips_major_monotone.svg.mjs';
export { S as TipsMajorTwotone } from './icons/polaris/tips_major_twotone.svg.mjs';
export { S as ToolsMajorMonotone } from './icons/polaris/tools_major_monotone.svg.mjs';
export { S as ToolsMajorTwotone } from './icons/polaris/tools_major_twotone.svg.mjs';
export { S as TransactionMajorMonotone } from './icons/polaris/transaction_major_monotone.svg.mjs';
export { S as TransactionMajorTwotone } from './icons/polaris/transaction_major_twotone.svg.mjs';
export { S as TransactionFeeDollarMajorMonotone } from './icons/polaris/transaction-fee-dollar_major_monotone.svg.mjs';
export { S as TransactionFeeDollarMajorTwotone } from './icons/polaris/transaction-fee-dollar_major_twotone.svg.mjs';
export { S as TransactionFeeEuroMajorMonotone } from './icons/polaris/transaction-fee-euro_major_monotone.svg.mjs';
export { S as TransactionFeeEuroMajorTwotone } from './icons/polaris/transaction-fee-euro_major_twotone.svg.mjs';
export { S as TransactionFeePoundMajorMonotone } from './icons/polaris/transaction-fee-pound_major_monotone.svg.mjs';
export { S as TransactionFeePoundMajorTwotone } from './icons/polaris/transaction-fee-pound_major_twotone.svg.mjs';
export { S as TransactionFeeRupeeMajorMonotone } from './icons/polaris/transaction-fee-rupee_major_monotone.svg.mjs';
export { S as TransactionFeeRupeeMajorTwotone } from './icons/polaris/transaction-fee-rupee_major_twotone.svg.mjs';
export { S as TransactionFeeYenMajorMonotone } from './icons/polaris/transaction-fee-yen_major_monotone.svg.mjs';
export { S as TransactionFeeYenMajorTwotone } from './icons/polaris/transaction-fee-yen_major_twotone.svg.mjs';
export { S as TransferMajorMonotone } from './icons/polaris/transfer_major_monotone.svg.mjs';
export { S as TransferMajorTwotone } from './icons/polaris/transfer_major_twotone.svg.mjs';
export { S as TransferInMajorMonotone } from './icons/polaris/transfer-in_major_monotone.svg.mjs';
export { S as TransferInMajorTwotone } from './icons/polaris/transfer-in_major_twotone.svg.mjs';
export { S as TransferOutMajorMonotone } from './icons/polaris/transfer-out_major_monotone.svg.mjs';
export { S as TransferOutMajorTwotone } from './icons/polaris/transfer-out_major_twotone.svg.mjs';
export { S as TransferWithinShopifyMajorMonotone } from './icons/polaris/transfer-within-shopify_major_monotone.svg.mjs';
export { S as TransferWithinShopifyMajorTwotone } from './icons/polaris/transfer-within-shopify_major_twotone.svg.mjs';
export { S as TransportMajorMonotone } from './icons/polaris/transport_major_monotone.svg.mjs';
export { S as TransportMajorTwotone } from './icons/polaris/transport_major_twotone.svg.mjs';
export { S as TroubleshootMajorMonotone } from './icons/polaris/troubleshoot_major_monotone.svg.mjs';
export { S as TroubleshootMajorTwotone } from './icons/polaris/troubleshoot_major_twotone.svg.mjs';
export { S as TypeMajorMonotone } from './icons/polaris/type_major_monotone.svg.mjs';
export { S as TypeMajorTwotone } from './icons/polaris/type_major_twotone.svg.mjs';
export { S as UndoMajorMonotone } from './icons/polaris/undo_major_monotone.svg.mjs';
export { S as UndoMajorTwotone } from './icons/polaris/undo_major_twotone.svg.mjs';
export { S as UnfulfilledMajorMonotone } from './icons/polaris/unfulfilled_major_monotone.svg.mjs';
export { S as UnfulfilledMajorTwotone } from './icons/polaris/unfulfilled_major_twotone.svg.mjs';
export { S as UnknownDeviceMajorMonotone } from './icons/polaris/unknown-device_major_monotone.svg.mjs';
export { S as UnknownDeviceMajorTwotone } from './icons/polaris/unknown-device_major_twotone.svg.mjs';
export { S as UpdateInventoryMajorMonotone } from './icons/polaris/update-inventory_major_monotone.svg.mjs';
export { S as UpdateInventoryMajorTwotone } from './icons/polaris/update-inventory_major_twotone.svg.mjs';
export { S as VariantMajorMonotone } from './icons/polaris/variant_major_monotone.svg.mjs';
export { S as VariantMajorTwotone } from './icons/polaris/variant_major_twotone.svg.mjs';
export { S as ViewMajorMonotone } from './icons/polaris/view_major_monotone.svg.mjs';
export { S as ViewMajorTwotone } from './icons/polaris/view_major_twotone.svg.mjs';
export { S as ViewMinor } from './icons/polaris/view_minor.svg.mjs';
export { S as ViewportNarrowMajorMonotone } from './icons/polaris/viewport-narrow_major_monotone.svg.mjs';
export { S as ViewportNarrowMajorTwotone } from './icons/polaris/viewport-narrow_major_twotone.svg.mjs';
export { S as ViewportWideMajorMonotone } from './icons/polaris/viewport-wide_major_monotone.svg.mjs';
export { S as ViewportWideMajorTwotone } from './icons/polaris/viewport-wide_major_twotone.svg.mjs';
export { S as VocabularyMajorMonotone } from './icons/polaris/vocabulary_major_monotone.svg.mjs';
export { S as VocabularyMajorTwotone } from './icons/polaris/vocabulary_major_twotone.svg.mjs';
export { S as WandMajorMonotone } from './icons/polaris/wand_major_monotone.svg.mjs';
export { S as WandMajorTwotone } from './icons/polaris/wand_major_twotone.svg.mjs';
export { S as WearableMajorMonotone } from './icons/polaris/wearable_major_monotone.svg.mjs';
export { S as WearableMajorTwotone } from './icons/polaris/wearable_major_twotone.svg.mjs';
export { S as WholesaleMajorMonotone } from './icons/polaris/wholesale_major_monotone.svg.mjs';
export { S as WholesaleMajorTwotone } from './icons/polaris/wholesale_major_twotone.svg.mjs';
export { S as WifiMajorMonotone } from './icons/polaris/wifi_major_monotone.svg.mjs';
export { S as WifiMajorTwotone } from './icons/polaris/wifi_major_twotone.svg.mjs';
