import React, { useState } from 'react'
import { CompanyToAddRelation, PORTFOLIO_OPTIONS } from '../types'
import { dispatch } from '../../../store'
import { createCompanyPortfolio } from '../../../modules/adminDashboard/adminDashboard'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { CompanySummary } from './CompanySummary'
import Grid from '@mui/material/Grid'

interface Props {
  companyToAddRelation: CompanyToAddRelation
  onCancel: () => void
  onCreate: (newPortfolio: string) => void
}
export const CreatePortfolioModal = (props: Props): JSX.Element => {
  const { companyToAddRelation, onCancel, onCreate } = props

  const [portfolioToAdd, setPortfolioToAdd] = useState<string>('')
  const handleAddPortfolioSelectChange = (event: SelectChangeEvent): void => {
    setPortfolioToAdd(event.target.value)
  }

  const handleAddPortfolioButtonClicked = (): void => {
    if (portfolioToAdd && companyToAddRelation) {
      onCreate(portfolioToAdd)
      dispatch(
        createCompanyPortfolio({
          companyId: companyToAddRelation?.company?.id,
          portfolioName: portfolioToAdd,
        }),
      )
    }
    setPortfolioToAdd('')
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Add portfolio</h5>
        <CompanySummary companyToAddRelation={companyToAddRelation} />
        <Grid container={true} justifyContent={'end'}>
          <Grid container={true} xs={12}>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: '100%' }}
              >
                <InputLabel>Portfolios</InputLabel>
                <Select
                  value={portfolioToAdd}
                  onChange={handleAddPortfolioSelectChange}
                  label="Portfolio"
                >
                  {PORTFOLIO_OPTIONS.filter(
                    option =>
                      !companyToAddRelation?.company?.portfolios?.includes(
                        option,
                      ),
                  ).map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sx={{ mr: 1 }}>
            <Button
              variant={'outlined'}
              color={'warning'}
              onClick={(): void => {
                setPortfolioToAdd('')
                onCancel()
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!portfolioToAdd}
              variant={'contained'}
              color={'success'}
              onClick={(): void => handleAddPortfolioButtonClicked()}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
