import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import {
  createCashflowScenario,
  deleteCashflowScenario,
  editCashflowScenario,
  getCashflowScenarios,
} from './cashflowScenarios'
import { LoadingAndError } from '../types'
import { CashFlowForecastScenariosResponse } from '../../../server/routes/api/forecast/cashflow'

const CASHFLOW_SCENARIO_KEY = 'cashflowScenarios'

export type CashflowScenarioPayload = {
  [CASHFLOW_SCENARIO_KEY]: CashFlowForecastScenariosResponse | null
  hasLoaded: boolean
} & LoadingAndError

// REDUCERS
type CashflowScenariosStoreData = {
  [CASHFLOW_SCENARIO_KEY]: CashflowScenarioPayload
}

export type CashflowScenariosData = Readonly<CashflowScenariosStoreData>

const initialCashflowScenariosState: CashflowScenariosData = {
  [CASHFLOW_SCENARIO_KEY]: {
    [CASHFLOW_SCENARIO_KEY]: null,
    isLoading: false,
    hasLoaded: false,
    error: null,
  },
}

export const cashflowScenariosReducer = createReducer(
  initialCashflowScenariosState,
  builder => {
    // GET CASHFLOW SCENARIOS
    createThunkReducer(
      builder,
      getCashflowScenarios.typePrefix,
      CASHFLOW_SCENARIO_KEY,
      (state, action) =>
        ({
          ...state,
          [CASHFLOW_SCENARIO_KEY]: {
            ...state[CASHFLOW_SCENARIO_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            hasLoaded: !!action.payload,
            [CASHFLOW_SCENARIO_KEY]: action.payload,
          },
        } as CashflowScenariosData),
    )

    // CREATE CASHFLOW SCENARIO
    createThunkReducer(
      builder,
      createCashflowScenario.typePrefix,
      CASHFLOW_SCENARIO_KEY,
      (state, action) =>
        ({
          ...state,
          [CASHFLOW_SCENARIO_KEY]: {
            ...state[CASHFLOW_SCENARIO_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            hasLoaded: !!action.payload,
            [CASHFLOW_SCENARIO_KEY]: {
              ...state[CASHFLOW_SCENARIO_KEY][CASHFLOW_SCENARIO_KEY],
              ...action.payload,
              cashflowForecastScenarios: [
                ...(state[CASHFLOW_SCENARIO_KEY][CASHFLOW_SCENARIO_KEY]
                  ?.cashflowForecastScenarios ?? []),
                ...action.payload.cashflowForecastScenarios,
              ],
            },
          },
        } as CashflowScenariosData),
    )

    // EDIT CASHFLOW SCENARIO
    createThunkReducer(
      builder,
      editCashflowScenario.typePrefix,
      CASHFLOW_SCENARIO_KEY,
      (state, action) =>
        ({
          ...state,
          [CASHFLOW_SCENARIO_KEY]: {
            ...state[CASHFLOW_SCENARIO_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            hasLoaded: !!action.payload,
            [CASHFLOW_SCENARIO_KEY]: {
              ...state[CASHFLOW_SCENARIO_KEY][CASHFLOW_SCENARIO_KEY],
              ...action.payload,
              cashflowForecastScenarios: [
                ...(state[CASHFLOW_SCENARIO_KEY][
                  CASHFLOW_SCENARIO_KEY
                ]?.cashflowForecastScenarios?.map(scenario =>
                  scenario.scenario.id ===
                  action?.payload?.cashflowForecastScenario?.scenario?.id
                    ? action.payload.cashflowForecastScenario
                    : scenario,
                ) ?? []),
              ],
            },
          },
        } as CashflowScenariosData),
    )

    // DELETE CASHFLOW SCENARIO
    createThunkReducer(
      builder,
      deleteCashflowScenario.typePrefix,
      CASHFLOW_SCENARIO_KEY,
      (state, action) =>
        ({
          ...state,
          [CASHFLOW_SCENARIO_KEY]: {
            ...state[CASHFLOW_SCENARIO_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            hasLoaded: !!action.payload,
            [CASHFLOW_SCENARIO_KEY]: {
              ...state[CASHFLOW_SCENARIO_KEY][CASHFLOW_SCENARIO_KEY],
              ...action.payload,
              cashflowForecastScenarios: [
                ...(state[CASHFLOW_SCENARIO_KEY][
                  CASHFLOW_SCENARIO_KEY
                ]?.cashflowForecastScenarios?.filter(
                  scenario =>
                    scenario.scenario.id !== action?.payload?.deletedId,
                ) ?? []),
              ],
            },
          },
        } as CashflowScenariosData),
    )
  },
)
