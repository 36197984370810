import React, { useMemo, useState } from 'react'
import { Color } from '../Color'

export interface CreditScoreRating {
  minimum: number
  maximum: number
  rating: string
  color: Color
}

export const useRatingCreditScore = (
  score: number,
): {
  rating: CreditScoreRating
  ratingDataSource: CreditScoreRating[]
} => {
  const [rating, setRating] = useState({
    minimum: 0,
    maximum: 24,
    rating: 'Invalid',
    color: Color.ErrorRed,
  })

  const ratingDataSource: CreditScoreRating[] = useMemo(() => {
    return [
      { minimum: 0, maximum: 49, rating: 'Poor', color: Color.ErrorRed },
      { minimum: 50, maximum: 59, rating: 'Fair', color: Color.Fair },
      { minimum: 60, maximum: 69, rating: 'Good', color: Color.Good },
      { minimum: 70, maximum: 79, rating: 'Great', color: Color.Great },
      {
        minimum: 80,
        maximum: 100,
        rating: 'Excellent',
        color: Color.Excellent,
      },
    ]
  }, [])

  React.useEffect(() => {
    const newRating = ratingDataSource.find(
      (r: CreditScoreRating) => score >= r.minimum && score <= r.maximum,
    )
    if (newRating) {
      setRating(newRating)
    }
  }, [score, ratingDataSource])

  return useMemo(() => {
    return { rating, ratingDataSource }
  }, [rating, ratingDataSource])
}
