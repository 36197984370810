import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import LockIcon from '@mui/icons-material/Lock'
import { Color } from '../../../../Color'
import { NavigationLink } from '../../atoms/NavigationLink/NavigationLink'

type Props = {
  icon?: JSX.Element
  title: string
  link: string
  isLocked?: boolean
  shouldBeSelected?: boolean
  isChildItem?: boolean
  shouldIncludeSeparation?: boolean
}

export const NavigationItem: React.FunctionComponent<Props> = ({
  icon,
  title,
  link,
  isLocked,
  shouldBeSelected = true,
  isChildItem,
  shouldIncludeSeparation,
}) => {
  return (
    <MenuItem
      className={shouldBeSelected ? 'selected-item' : ''}
      sx={{
        padding: 0,
        marginBottom: shouldIncludeSeparation ? 1 : 0,
        '&.selected-item': {
          'a.active': {
            background: Color.BrandHighlightBlue,
            borderRadius: '4px',
          },
        },
        '&:hover': {
          background: 'unset',
        },
        '.MuiListItemIcon-root': {
          minWidth: '25px',
        },
      }}
    >
      <NavigationLink to={link}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText sx={{ color: Color.White }}>
          <Typography
            sx={{ fontWeight: isChildItem ? 300 : 400 }}
            variant="subtitle2"
          >
            {title}
            {isLocked && (
              <LockIcon
                sx={{
                  width: 12,
                  height: 12,
                  color: Color.White,
                  marginLeft: '4px',
                }}
              />
            )}
          </Typography>
        </ListItemText>
      </NavigationLink>
    </MenuItem>
  )
}
