import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'

import { Middleware } from '@reduxjs/toolkit'

const failureLogger: Middleware = thunkApi => (next: (action: any) => void) => (
  action: any,
): void => {
  // manually thrown errors
  const isFailure = action.type && /\/FAILURE$/.test(action.type)
  // rejections from createAsyncThunk and result of rejectWithValue
  const isRejected = action.type && /\/rejected$/.test(action.type)
  if (!isFailure && !isRejected) return next(action)

  const userId = thunkApi.getState().user.data.id
  const companyId = thunkApi.getState().companies.data.id

  Sentry.addBreadcrumb({
    category: 'Redux',
    message: action.type,
    level: Severity.Error,
    data: action.payload,
  })

  Sentry.captureException(
    new Error(
      `Error in redux result from action type: '${action.type}', user id: '${userId}', company id: '${companyId}'`,
    ),
  )

  return next(action)
}

export default failureLogger
