import React from 'react'
import Grid from '@mui/material/Grid'

export const LegendContent = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Grid item={true} xs={12} sx={{ pl: 2 }}>
      {children}
    </Grid>
  )
}
