import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { YogaSVG } from './YogaSVG'
import Hidden from '@mui/material/Hidden'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { ExternalLink } from '../../../components/forms/StyledElements'
import { removeCampaignTracking } from '../../../hooks/useNavigationCampaign'
import { ScoreStatus, useCreditScore } from '../../../hooks/useCreditScore'

export const ThankYouPage = (): JSX.Element => {
  const { creditScore, creditScoreRequest } = useCreditScore()

  React.useEffect(() => {
    if (creditScore.scoreStatus !== ScoreStatus.Requested) {
      removeCampaignTracking()
      creditScoreRequest()
    }
  }, [])

  return (
    <>
      <Typography mt={8} mb={4} variant="h4">
        Now, sit back and relax.
      </Typography>

      <Grid container={true} alignItems="top" gap={1}>
        <Grid item={true} xs={12} sm={6}>
          <Typography mb={3} variant="body1">
            Your accounts are syncing! We’ll email you as soon as your
            Brightflow AI Credit Score is ready.
          </Typography>
          <Typography mb={3} variant="body1">
            In the meantime, explore the Brightflow AI platform, including the
            popular revenue forecast feature. Simply connect your revenue
            accounts.
          </Typography>
          <Grid
            container={true}
            spacing={3}
            sx={{ mb: 4 }}
            justifyContent="center"
          >
            <Grid item={true}>
              <Link to="/connectors/shopify">
                <Button size="large" variant="outlined" color="primary">
                  Shopify
                </Button>
              </Link>
            </Grid>
            <Grid item={true}>
              <Link to="/connectors/amazonSeller">
                <Button size="large" variant="outlined" color="primary">
                  Amazon
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Typography mb={2} variant="body1">
            Don&rsquo;t use Shopify or Amazon?&nbsp;
            <ExternalLink href="../connectors">
              Connect your other accounts
            </ExternalLink>
            &nbsp;or&nbsp;
            <ExternalLink href="https://brightflow.ai/request-a-call/?utm_campaign=Credit%20score&utm_source=onboarding">
              schedule a call
            </ExternalLink>
            &nbsp;with a member of our customer success team.
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item={true} justifySelf="end" xs={5}>
            <YogaSVG></YogaSVG>
          </Grid>
        </Hidden>
      </Grid>
    </>
  )
}
