import React from 'react'
import { InformativeTable } from '../../../../components/designSystem/organisms/InteractiveTable/InformativeTable'
import { useSalesForecast } from './SalesForecastProvider'
import { numberAsCommaDelimitiedMoney } from '../../../../util/displayValue'
import { Color } from '../../../../Color'

export const SalesForecastTable = () => {
  const {
    apiRef,
    tableColumns,
    tableRows,
    tableGroups,
    granularity,
  } = useSalesForecast()

  return (
    <InformativeTable
      apiRef={apiRef}
      treeDataPath="category"
      headerName={`${granularity}s`}
      columns={tableColumns || []}
      rows={tableRows || []}
      formatCellValue={numberAsCommaDelimitiedMoney}
      headerStyles={{
        '.row-level-parent, .MuiDataGrid-row.row-level-parent:hover, .row-level-parent.Mui-hovered': {
          background: Color.Grey2,
          color: Color.DarkerPurple,
          fontWeight: 'bold',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
          '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--filledGroup': {
            '&[aria-label="Actuals"]': {
              background: Color.Grey1,
            },
            '&[aria-label="Forecast"]': {
              background: Color.LighterBlue,
            },
          },
        },
      }}
      columnGroups={
        tableGroups && [
          {
            groupId: 'Actuals',
            children: tableGroups.actuals,
          },
          {
            groupId: 'Forecast',
            children: tableGroups.forecasted,
          },
        ]
      }
    />
  )
}
