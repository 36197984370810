import React from 'react'
import { Formik } from 'formik'

import { dispatch } from '../../../store'
import { putFacebookAdsAccount } from '../../../modules/welcome'
import { AdAccountObjectFetched } from '../../../../server/api-wrappers/facebook-marketing'
import { SimpleRadioFormComponent } from './SimpleRadioFormComponent'

type Props = {
  onClose: () => void
  accounts: AdAccountObjectFetched[]
}

export const SelectFacebookAdsAccountForm: React.FunctionComponent<Props> = ({
  accounts,
  onClose,
}) => {
  const headings = ['Account Name', 'Ad spend']
  const formValues = accounts.map(account => {
    return {
      label: account.name,
      value: account.id,
      cost: account.amount_spent,
    }
  })

  return (
    <Formik
      key="select-facebook-ads-account-form"
      initialValues={{
        value: null,
      }}
      onSubmit={(values): void => {
        const account = accounts.find(account => account.id === values.value)
        dispatch(putFacebookAdsAccount(account!))
        onClose()
      }}
    >
      {(renderProps): any => {
        return (
          <SimpleRadioFormComponent
            headings={headings}
            formData={formValues}
            disableSubmit={!renderProps.values.value}
            onCancel={onClose}
          />
        )
      }}
    </Formik>
  )
}
