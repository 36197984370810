import { HeroHeaderProps } from '../components/designSystem/molecules/HeroHeader/HeroHeader'

export const urlPathPrefixToHeroHeaderPropsMap: {
  [urlPathPrefix: string]: HeroHeaderProps
} = {
  '/overview': {
    title: 'Overview',
    description:
      'A complete picture of your business in one comprehensive report, without the manual work.',
    localStorageKey: 'overview-hero-header-content',
  },
  '/cash-position': {
    title: 'Cash Position',
    description:
      'Understand how you are positioned today and how the business has been using cash over time.',
    localStorageKey: 'cash-position-hero-header-content',
  },
  '/transactions': {
    title: 'Transactions',
    description:
      'Investigate detailed transactions to see the details of individual charges and apply updates to align them with your business reality.',
    localStorageKey: 'transactions-hero-header-content',
  },
  '/forecast': {
    title: 'Sales Planning',
    description:
      'Use sales projection to predict upcoming revenue to help your business set plans for growth.',
    localStorageKey: 'sales-forecast-hero-header-content',
  },
  '/product': {
    title: 'Product Demand',
    description:
      'Understand which products are in demand to advertise and order more of the right products at the right time.',
    localStorageKey: 'pd-forecast-hero-header-content',
  },
  '/revenue': {
    title: 'Revenue Tracker',
    description:
      'Compare order revenue and units sold across your sales channels.',
    localStorageKey: 'revenue-hero-header-content',
  },
  '/ltv': {
    title: 'LTV - Customer Lifetime Value',
    description:
      'Measure the value of a new customer over time and grow your per customer cumulative spend over time.',
    localStorageKey: 'ltv-hero-header-content',
  },
  '/roas': {
    title: 'Return on Ad Spend',
    description:
      'Track the return on advertising spend to help your business understand the return they can expect for each new customer added.',
    localStorageKey: 'roas-hero-header-content',
  },
  '/connectors': {
    title: 'Connections',
    description:
      'Brightflow AI is able to offer enhanced business insights with additional data sources. Connect your accounts to get access to these insights and to grow your capital.',
    localStorageKey: 'connectors-hero-header-content',
  },
}
