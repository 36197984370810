import { subMilliseconds } from 'date-fns'

export interface Quarter {
  quarter: number
  year: number
}

/**
 * This class provides various static methods that are useful
 * for calculating the current, previous, and next quarters
 * using JS Date
 */
export class QuarterCalculator {
  /**
   * Takes in a monthIndex and returns the associated quarter
   *
   * Note: Zero index, Jan = 0
   * @param monthIndex number (zero index (Jan = 0))
   * @returns number (i.e. 1, 2, 3, 4)
   */
  public static getQuarterFromMonthIndex(monthIndex: number): number {
    return (monthIndex + (3 - (monthIndex % 3))) / 3
  }

  /**
   * Returns the quarter that a specified
   * date is in
   * @param date
   * @returns Quarter
   */
  public static getQuarterFromDate(date: Date): Quarter {
    const currentMonth = date.getMonth()

    const year = date.getFullYear()

    return {
      quarter: this.getQuarterFromMonthIndex(currentMonth),
      year,
    }
  }

  /**
   * Returns the previous quarter
   *
   * e.g. 2Q 2022 -> 1Q 2022
   * @returns Quarter the previous quarter
   */
  public static getPreviousQuarter(quarter: Quarter): Quarter {
    // in the first quarter of the year
    // go back to Q4 of last year
    if (quarter.quarter === 1) {
      return {
        quarter: 4,
        year: quarter.year - 1,
      }
    }

    // normal case
    // increment a quarter
    return {
      quarter: quarter.quarter - 1,
      year: quarter.year,
    }
  }

  /**
   * Returns the next quarter
   * based on the current date
   *
   * e.g. in 2022-04-23 -> 2Q 2022
   * @returns Quarter the next quarter
   */
  public static getNextQuarter(quarter: Quarter): Quarter {
    // If Q4 increment to Q1 of next year
    if (quarter.quarter === 4) {
      return {
        quarter: 1,
        year: quarter.year + 1,
      }
    }

    // normal case, increment quarter
    return {
      quarter: quarter.quarter + 1,
      year: quarter.year,
    }
  }

  /**
   *
   * Gets the start date for the specified quarter
   * in local time
   *
   * @param quarter
   * @returns start date of quarter in local time
   */
  public static getQuarterStartDate(quarter: Quarter): Date {
    const quarterStartMonth = quarter.quarter * 3 - 3
    const date = new Date(quarter.year, quarterStartMonth, 1)
    return date
  }

  /**
   *
   * Gets the end date for the specified quarter
   * in local time
   *
   * @param quarter
   * @returns end date of quarter in local time
   */
  public static getQuarterEndDate(quarter: Quarter): Date {
    const nextQuarter = this.getNextQuarter(quarter)
    const nextQuarterStartDate = this.getQuarterStartDate(nextQuarter)

    return subMilliseconds(nextQuarterStartDate, 1)
  }
}
