import { Money } from '../Money'

export abstract class Formatter {
  // eslint-disable-next-line no-useless-constructor
  constructor(readonly money: Money) {}

  abstract convert(s?: boolean): string

  toString(s?: boolean): string {
    return this.convert(s)
  }
}
