import React from 'react'
import { Form } from 'formik'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import { Content, ContentBox, ContentHead } from '../StyledElements'
import { RadioField } from '../../designSystem/molecules/RadioField/RadioField'
import { Money } from '../../../../util/money'

const FormLayout = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const FormHeading = styled('h5')`
  align-items: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
`

const FormContent = styled('div')`
  padding: 30px 0 70px 0;
  width: 100%;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ButtonProps = {
  width: '108px',
  height: '45px',
}

type FormItem = {
  label: string | number
  value: any
  cost: string | number
}

type Props = {
  title?: string
  headings: string[]
  formData: FormItem[]
  disableSubmit: boolean
  onCancel: () => void
}

export const SimpleRadioFormComponent: React.FunctionComponent<Props> = ({
  title,
  headings,
  formData,
  disableSubmit,
  onCancel,
}) => (
  <Form noValidate>
    <FormLayout>
      {title ? <FormHeading>{title}</FormHeading> : null}
      <FormContent>
        <ContentBox>
          {headings.map((heading, index) => (
            <ContentHead key={index}>{heading}</ContentHead>
          ))}
        </ContentBox>
        {formData.map(item => (
          <ContentBox key={item.value}>
            <Content>
              <RadioField
                label={item.label}
                value={item.value}
                propName="value"
              />
            </Content>
            <Content>{`${new Money(item.cost).humanize()}`}</Content>
          </ContentBox>
        ))}
      </FormContent>
      <ButtonContainer>
        <Button
          variant="outlined"
          sx={ButtonProps}
          onClick={(): void => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={ButtonProps}
          type="submit"
          disabled={disableSubmit}
        >
          Save
        </Button>
      </ButtonContainer>
    </FormLayout>
  </Form>
)
