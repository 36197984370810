import { styled } from '@mui/material/styles'
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { Headline } from '../../../components/designSystem/molecules/Headline/Headline'
import { ProjectedSection as Ps } from './section'
import { postGrossMargin } from '../../../modules/reports/ltv'
import { GrossMarginSection } from './section'
import { dispatch } from '../../../store'

const SectionWrap = styled('div')``

const GrossInput = styled('input')`
  margin-left: 4px;
  width: 2rem;
  height: 2.625rem;
  border: none;
  border-bottom: 3px solid #08678c;
  font-size: 20px;
  &:active {
    border-bottom: 3px solid #08678c;
  }
`

const RevenueContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
`

const RevenueWrap = styled('div')`
  width: 222px;
  font-size: 18px;
  padding: 1.75rem 0 0 0;
`

const MarginWrap = styled('div')`
  display: flex;
  flex-direction: column;
`

const MarginHeaderContainer = styled('div')`
  font-size: 18px;
  width: 222px;
`

const MarginHeaderDivider = styled('div')`
  text-align: center;
  font-size: 14px;
  padding: 0;
  width: 7.875rem;
`

const GrossMarginDiv = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 0.875rem;
  align-items: center;
`

const DataContainer = styled('div')`
  margin-left: 15px;
`

type ProjectedProps = {
  projectedCohort: []
  min: number
  max: number
  setInputMargin: Dispatch<SetStateAction<string>>
  inputMargin: string
}

export const ProjectedSection: React.FunctionComponent<ProjectedProps> = ({
  projectedCohort,
  min,
  max,
  setInputMargin,
  inputMargin,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    e.preventDefault()
    e.persist()
    setInputMargin(target.value)
    dispatch(postGrossMargin(parseInt(target.value)))
  }

  return (
    <div>
      <SectionWrap>
        <Headline
          sticky={true}
          title="Calculate the Value of a New Customer (projected)"
        />
        <DataContainer>
          <RevenueContainer>
            <RevenueWrap>Average spend:</RevenueWrap>
            <div>
              <Ps data={projectedCohort} min={min} max={max} />
            </div>
          </RevenueContainer>
          <GrossMarginDiv>
            <MarginHeaderDivider>X multipled by</MarginHeaderDivider>
          </GrossMarginDiv>
          <MarginWrap>
            <GrossMarginDiv>
              <MarginHeaderContainer>Gross Margin %:</MarginHeaderContainer>
              <GrossInput value={inputMargin} onChange={handleChange} />%
            </GrossMarginDiv>
            <GrossMarginDiv>
              <MarginHeaderDivider>= equals</MarginHeaderDivider>
            </GrossMarginDiv>
            <GrossMarginDiv>
              <MarginHeaderContainer>Gross Margin $:</MarginHeaderContainer>
              {projectedCohort.length ? (
                <div>
                  <GrossMarginSection
                    data={projectedCohort}
                    gross={inputMargin}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </GrossMarginDiv>
          </MarginWrap>
        </DataContainer>
        <div></div>
      </SectionWrap>
    </div>
  )
}
