import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Instructions } from '../../forms/StyledElements'
import { MuiCard } from '../../designSystem/molecules/MuiCard/MuiCard'
import { SideBanner } from './SideBanner'
import {
  QueryParamRoute,
  QueryParamRouter,
} from '../../routes/QueryParamRouter'
import { ProductHuntSplash } from './ProductHuntSplash'

const logos = [
  'magicspoon.png',
  'flex.png',
  'facetory.png',
  'ps.png',
  'grabgreen.png',
  'hammit.png',
]

export const SignContent = ({
  title,
  Form,
  InstructionsContent,
}: {
  title: string
  Form: JSX.Element
  InstructionsContent: JSX.Element
}): JSX.Element => {
  return (
    <Grid container height={'100vh'} overflow={'auto'}>
      <Grid item xs={12} lg={6} sx={{ marginTop: 5 }}>
        <Grid
          container
          direction="column"
          height="100%"
          justifyContent="center"
        >
          <Grid item>
            <MuiCard title={title}>
              <>
                {Form}
                <Instructions>
                  <p>{InstructionsContent}</p>
                </Instructions>
              </>
            </MuiCard>
          </Grid>
          <Grid item mt={10}>
            <Grid container justifyContent="center">
              <Grid item xs={11} lg={7}>
                <Typography variant="body1" textAlign="center">
                  With more than <b>73,000 transactions</b> syncing daily,
                  Brightflow AI is the trusted platform of top eCommerce brands.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container pt={5} justifyContent="center">
              {logos.map(logo => (
                <Grid
                  key={logo}
                  item
                  textAlign="center"
                  sx={{
                    ':first-child': {
                      padding: 0,
                    },
                    paddingLeft: { xs: '15px', sm: '50px', lg: '50px' },
                  }}
                >
                  <img src={`/images/logos/${logo}`} alt="logo" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
        <QueryParamRouter defaultComponent={<SideBanner />}>
          <QueryParamRoute
            path="/users/signup"
            paramName="utm_campaign"
            paramValue="product-hunt_launch"
            component={<ProductHuntSplash />}
          />
          <QueryParamRoute
            path="/users/signup"
            paramName="utm_campaign"
            paramValue="Credit score"
            component={<SideBanner />}
          />
          <QueryParamRoute
            path="/users/login"
            paramName=""
            paramValue=""
            component={<SideBanner />}
          />
        </QueryParamRouter>
      </Grid>
    </Grid>
  )
}
