import { api } from '../../../lib/api'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const getProductSegmentDashboardData = createAsyncThunk(
  '/app/customer-service/productDemand/productSegments/',
  async (companyId: string) =>
    api.get(`/customer-service/productSegments/products/${companyId}`),
)

export const createProductSegments = createAsyncThunk(
  '/app/customer-service/productDemand/productSegment/create',
  async ({
    companyId,
    segmentName,
  }: {
    segmentName: string
    companyId: string
  }) =>
    api.post(`/customer-service/productSegments/${companyId}`, { segmentName }),
)

export const updateProductSegment = createAsyncThunk(
  '/app/customer-service/productSegments/update',
  async ({
    segmentId,
    segmentName,
  }: {
    segmentId: string
    segmentName: string
  }) =>
    api.put(`/customer-service/productSegments/segment/${segmentId}`, {
      segmentName,
    }),
)

export const deleteProductSegment = createAsyncThunk(
  '/app/customer-service/productSegments/delete',
  async ({ segmentId }: { segmentId: string }) =>
    api.delete(`/customer-service/productSegments/segment/${segmentId}`),
)

export const upsertProductSegmentMappings = createAsyncThunk(
  '/app/customer-service/productSegments/mappings/upsert',
  async ({
    mappings,
    companyId,
  }: {
    mappings: {
      productId: string
      customerSegmentId: string
      quantity: number | null
    }[]
    companyId: string
  }) =>
    api.put(`/customer-service/productSegmentMapping/${companyId}`, {
      mappings,
    }),
)

export const deleteProductSegmentMapping = createAsyncThunk(
  '/app/customer-service/productSegments/mapping/delete',
  async ({
    customerSegmentId,
    productId,
  }: {
    customerSegmentId: string
    productId: string
  }) =>
    api.delete(
      `/customer-service/productSegments/mapping/product/${productId}/segment/${customerSegmentId}`,
    ),
)

export const triggerParameterOptimization = createAsyncThunk(
  '/app/customer-service/productSegments/optimize',
  async ({ companyId }: { companyId: string }) =>
    api.post(`/customer-service/productSegments/optimize/${companyId}`, {}),
)
