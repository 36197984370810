import React from 'react'

import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import {
  DataGridPro,
  GridCellParams,
  GridRowParams,
  GridRowsProp,
} from '@mui/x-data-grid-pro'

import { TransactionsTableToolbar } from '../TransactionsTableToolbar/TransactionsTableToolbar'
import { Color } from '../../../../../Color'
import { useTransactionsTable } from './TransactionsTable.hooks'
import {
  Account,
  Category,
  Merchant,
  RefetchMerchantsFn,
  SetDateRangeFn,
  SetSelectedAccountsFn,
  TransactionStatus,
} from '../../Transactions.types'
import { TableColumns } from '../TransactionsTableColumns/TransactionsTableColumns'
import { AddMerchantModal } from '../AddMerchantModal/AddMerchantModal'

type Props = {
  accounts: Account[]
  rows: GridRowsProp
  isContentLoading: boolean
  isLabelsLoading: boolean
  merchants: Merchant[]
  categories: Category[]
  setDateRange: SetDateRangeFn
  refetchMerchants: RefetchMerchantsFn
  setSelectedAccounts: SetSelectedAccountsFn
}

export const TransactionsTable = ({
  accounts,
  rows,
  categories,
  isContentLoading,
  isLabelsLoading,
  merchants,
  refetchMerchants,
  setDateRange,
  setSelectedAccounts,
}: Props): JSX.Element => {
  const {
    apiRef,
    handleAddMerchant,
    handleAccountFilterChange,
    handleAmountFilterChange,
    handleBulkUpdate,
    handleCategoryFilterChange,
    handleCloseAddMerchantModal,
    handleDateRangeFilterChange,
    handleDescriptionFilterChange,
    handleExportData,
    handleMerchantFilterChange,
    handleOpenAddMerchantModal,
    handleSelectionModelChange,
    handleUpdateRow,
    isAddMerchantOpen,
    selectedRows,
    setSnackBarState,
    snackBarState,
    urlAttributes,
    setDescriptionFilterWidth,
    descriptionFilterWidth,
  } = useTransactionsTable({
    refetchMerchants,
    setDateRange,
    setSelectedAccounts,
  })

  return (
    <Box sx={{ flex: 1, height: 350, width: '100%', padding: '20px 0 25px' }}>
      <DataGridPro
        // the description column is the only one that is resizable
        onColumnResize={(params): void => {
          setDescriptionFilterWidth(params.width)
        }}
        apiRef={apiRef}
        checkboxSelection
        columns={TableColumns({
          apiRef,
          categories,
          handleOpenAddMerchantModal,
          handleUpdateRow,
          merchants,
          descriptionFilterWidth,
        })}
        components={{
          Toolbar: TransactionsTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            accounts,
            categories,
            handleAccountFilterChange,
            handleAmountFilterChange,
            handleBulkUpdate,
            handleCategoryFilterChange,
            handleDateRangeFilterChange,
            handleDescriptionFilterChange,
            handleExportData,
            handleMerchantFilterChange,
            handleOpenAddMerchantModal,
            handleSelectionModelChange,
            isLoading: isLabelsLoading,
            merchants,
            selectedRows,
            setSelectedAccounts,
            urlAttributes,
            descriptionFilterWidth,
          },
        }}
        isRowSelectable={({ row }: GridRowParams): boolean =>
          row.status !== TransactionStatus.pending
        }
        isCellEditable={({ row }: GridCellParams): boolean =>
          row.status !== TransactionStatus.pending
        }
        disableSelectionOnClick
        disableColumnFilter
        loading={isContentLoading}
        pagination={true}
        rows={rows}
        onSelectionModelChange={handleSelectionModelChange}
        sx={{
          borderRadius: '25px',
          border: `1px solid ${Color.LightGrey}`,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F4F4F4F4',
            borderColor: Color.LightGrey,
            borderStyle: 'solid',
            borderWidth: '1px 0',
            borderRadius: '0',
          },
        }}
      />
      <AddMerchantModal
        isOpen={isAddMerchantOpen}
        handleAddMerchant={handleAddMerchant}
        handleClose={handleCloseAddMerchantModal}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        open={snackBarState !== 'Closed'}
        onClose={(): void => setSnackBarState('Closed')}
        message={
          snackBarState === 'Success'
            ? 'Transaction Updated'
            : 'Unable to Update Transaction'
        }
        sx={{
          '.MuiPaper-root': {
            backgroundColor:
              snackBarState === 'Success'
                ? Color.VioletEggplant
                : Color.ErrorRed,
          },
        }}
        transitionDuration={{ exit: 0 }}
      />
    </Box>
  )
}
