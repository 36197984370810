import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getAmazonSellerUri } from './amazonSeller'
import { LoadingAndError } from '../types'
const AMAZON_SELLER_URI_KEY = 'amazonSellerUri'

export type AmazonSellerUriPayload = {
  [AMAZON_SELLER_URI_KEY]: string | null
} & LoadingAndError

type AmazonSellerUriStoreData = {
  [AMAZON_SELLER_URI_KEY]: AmazonSellerUriPayload
}

export type AmazonSellerUriDataData = Readonly<AmazonSellerUriStoreData>

const initialAmazonSellerUriState: AmazonSellerUriDataData = {
  [AMAZON_SELLER_URI_KEY]: {
    [AMAZON_SELLER_URI_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const amazonSellerUriReducer = createReducer(
  initialAmazonSellerUriState,
  builder => {
    // GET CONFIRMATION URL
    createThunkReducer(
      builder,
      getAmazonSellerUri.typePrefix,
      AMAZON_SELLER_URI_KEY,
      (state, action) =>
        ({
          ...state,
          [AMAZON_SELLER_URI_KEY]: {
            ...state[AMAZON_SELLER_URI_KEY],
            isLoading: null,
            error: null,
            amazonSellerUri: action.payload.amazonSellerUri,
          },
        } as AmazonSellerUriDataData),
    )
  },
)
