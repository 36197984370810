import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../../thunkReducerBuilder'

import {
  bulkUpdateActionFields,
  bulkUpdateBfCategoryLabels,
  getAccountLabellingDashboardData,
  indexLabel,
  updateBfCategoryLabels,
} from './accountLabellingDashboard'

import { AccountLabelDashboardResults } from '../../../../server/routes/admin/credit/accountLabelling'
import { LoadingAndError } from '../../types'
import { AccountingLabelMappingData } from '../../../../server/services/creditDashboard/accountLabelling/AccountingLabelMappingService'

export const ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY = 'accountLabelDashboard'

export type AccountLabelDashboardPayload = {
  [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: AccountLabelDashboardResults | null
} & LoadingAndError

type AccountLabelDashboardStoreData = {
  [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: AccountLabelDashboardPayload
}

export type AccountLabelDashboardData = Readonly<AccountLabelDashboardStoreData>

const initialState: AccountLabelDashboardData = {
  [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
    [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const accountLabelDashboadReducer = createReducer(
  initialState,
  builder => {
    // get account mappings reducer
    createThunkReducer(
      builder,
      getAccountLabellingDashboardData.typePrefix,
      ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
      (state, action) =>
        ({
          ...state,
          [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
            ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY],
            isLoading: false,
            error: action.payload.errorMessages.join(','),
            [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: action.payload,
          },
        } as AccountLabelDashboardData),
    )

    // update label reducer
    createThunkReducer(
      builder,
      updateBfCategoryLabels.typePrefix,
      ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
      (state, action) => ({
        ...state,
        [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
          ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY],
          isLoading: false,
          error: action.payload.errorMessages.join(','),
          [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
            ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
              ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
            ],
            errorMessages: action?.payload?.errorMessages ?? [],
            results:
              state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
                ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
              ]?.results?.map(row =>
                action?.payload?.result?.id === row.id
                  ? {
                      ...row,
                      ...action?.payload.result,
                    }
                  : row,
              ) ?? [],
          },
        },
      }),
    )

    // index label reducer
    createThunkReducer(
      builder,
      indexLabel.typePrefix,
      ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
      (state, action) => ({
        ...state,
        [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
          ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY],
          isLoading: false,
          error: action.payload.errorMessages.join(','),
          [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
            ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
              ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
            ],
            errorMessages: action?.payload?.errorMessages ?? [],
            results:
              state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
                ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
              ]?.results?.map(row =>
                action?.payload?.result?.id === row.id
                  ? {
                      ...row,
                      ...action?.payload.result,
                    }
                  : row,
              ) ?? [],
          },
        },
      }),
    )

    // bulk update label reducer(
    createThunkReducer(
      builder,
      bulkUpdateBfCategoryLabels.typePrefix,
      ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
      (state, action) => ({
        ...state,
        [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
          ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY],
          isLoading: false,
          error: action.payload.errorMessages.join(','),
          [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
            ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
              ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
            ],
            errorMessages: action?.payload?.errorMessages ?? [],
            results:
              state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
                ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
              ]?.results?.map(row => {
                const found = action?.payload?.results?.find(
                  (updateResult: AccountingLabelMappingData) =>
                    updateResult?.id === row.id,
                )
                return found ? { ...row, ...found } : row
              }) ?? [],
          },
        },
      }),
    )

    // bulk update actions reducer
    createThunkReducer(
      builder,
      bulkUpdateActionFields.typePrefix,
      ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
      (state, action) => ({
        ...state,
        [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
          ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY],
          isLoading: false,
          error: action.payload.errorMessages.join(','),
          [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: {
            ...state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
              ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
            ],
            errorMessages: action?.payload?.errorMessages ?? [],
            results:
              state[ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY][
                ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY
              ]?.results?.map(row => {
                const found = action?.payload?.results?.find(
                  (updateResult: AccountingLabelMappingData) =>
                    updateResult?.id === row.id,
                )

                return found ? { ...row, ...found } : row
              }) ?? [],
          },
        },
      }),
    )
  },
)
