import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Color } from '../../../../Color'

export const RowHeadingsContainer = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-end;
  padding: 15px 0;
`

export const TableBody = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${Color.LightGrey};
  display: flex;
  flex: 2;
  padding: 0 15px;

  & > :first-child {
    border-left: none;
  }

  & > :last-child {
    border-right: none;
  }

  ${({ theme }): string => theme.breakpoints.down('sm')} {
    & > :nth-last-child(n + 2):nth-child(even) {
      display: none;
    }
  }
`

export const TableColumn = styled(Box)`
  align-items: center;
  border-left: 0.5px solid ${Color.LightGrey};
  border-right: 0.5px solid ${Color.LightGrey};
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 25px;
  justify-content: flex-end;
  padding: 15px 0;

  & > :nth-child(even) {
    background-color: ${Color.LightestGrey};
  }
`
