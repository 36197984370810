import { AdminDashboardConnection } from '../../../server/services/adminDashboard'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import React from 'react'
import { formatTimestamp } from './DashboardGridPanelDetail'

export const AdminConnectionDetail = (props: {
  connections: AdminDashboardConnection[]
}): JSX.Element => {
  const { connections } = props
  return (
    <Card>
      <CardHeader title="Connections" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">Id</TableCell>
                <TableCell component="th">Type</TableCell>
                <TableCell component="th">Created on</TableCell>
                <TableCell component="th">Invalidated at</TableCell>
                <TableCell component="th">Oldest transaction date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connections.map((conn: AdminDashboardConnection) => (
                <TableRow key={conn.key}>
                  <TableCell scope="row">{conn.id}</TableCell>
                  <TableCell scope="row">{conn.key}</TableCell>
                  <TableCell scope="row">
                    {formatTimestamp(new Date(conn.createdAt))}
                  </TableCell>
                  <TableCell scope="row">
                    {conn.invalidatedAt
                      ? formatTimestamp(new Date(conn.invalidatedAt))
                      : 'N/A'}
                  </TableCell>
                  <TableCell scope="row">
                    {conn.oldestTransactionDate
                      ? formatTimestamp(new Date(conn.oldestTransactionDate))
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
