/**
 * Error handler middleware for api calls.
 */
export const withErrorHandler = async (
  fn: () => Promise<any>,
): Promise<any> => {
  try {
    return await fn()
  } catch (error: any) {
    if (error?.errorMessages || error?.error) {
      return error
    }
    const errorMessages: string[] = []
    if (error?.status === 404) {
      const message = error?.url
        ? `Not Found: ${error.url}`
        : error?.errorMessage && typeof error.errorMessage === 'string'
        ? error.errorMessage
        : 'Not Found'
      errorMessages.push(message)
    } else if (error?.errorMessage && typeof error.errorMessage === 'string') {
      errorMessages.push(error.errorMessage)
    }
    return { ...error, errorMessages }
  }
}

/**
 * Aggregates errors from api responses.
 */
export const aggregateErrors = (
  responses: Array<{
    error?: string | null
    errorMessages?: Array<string | null>
  }>,
): string => {
  const errors: string[] = []
  responses.forEach(response => {
    if (response.error && typeof response.error === 'string') {
      errors.push(response.error)
    }
    if (Array.isArray(response.errorMessages)) {
      response.errorMessages.forEach(errorMessage => {
        if (errorMessage && typeof errorMessage === 'string') {
          errors.push(errorMessage)
        }
      })
    }
  })
  return errors.join(',')
}
