import React, { useCallback, useMemo, useState } from 'react'
import { CustomTooltip, ToolTipProps } from './ProductDemandTooltip'
import { Color } from '../../../../Color'
import { BFLineGraph } from '../../../../components/designSystem/organisms/BFGraph/BFGraph'
import { commaDelimitedThousands } from '../../../../util/displayValue'
import { StackedXAxisMonthYear } from '../../../../components/designSystem/organisms/BFGraph/components/StackedXAxisMonthYear'
import { HorizontalTab } from '../../../../components/designSystem/organisms/HorizontalTab/HorizontalTab'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { AxisDomain } from 'recharts'
import { WeeklyProductDemandDataTransformer } from './transformers/WeeklyProductDemandPeriodTransformer'
import { MonthlyProductDemandDataTransformer } from './transformers/MonthlyProductDemandPeriodTransformer'
import { LegendContainer } from './LegendContainer'
import { InsightCards } from './InsightCards'
import { Segment } from '../../../../../server/services/forecast/types'
import { MultiSelect } from '../../../../components/designSystem/atoms/MultiSelect/MultiSelect'
import { INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW } from './constants'

type Props = {
  segments: Segment[]
}

const calculateIfTopSellingSegmentsSelected = (
  segments: Segment[],
  selectedSegments: Segment[],
) => {
  if (selectedSegments.length !== INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW) {
    return false
  }

  const topFiveSegmentsIdSet = segments
    .slice(0, INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW)
    .reduce<Set<string>>((acc, segment) => {
      acc.add(segment.segmentId)
      return acc
    }, new Set())

  return selectedSegments.every(segment =>
    topFiveSegmentsIdSet.has(segment.segmentId),
  )
}

const getInsightCardsData = (segments: Segment[]) => {
  let bestPerformerLastWeek = { segmentName: '', unitsSoldLastWeek: 0 }
  let bestPerformerLast6Months = { segmentName: '', unitsSoldLastSixMonths: 0 }
  let worstPerformerLastWeek = { segmentName: '', unitsSoldLastWeek: Infinity }
  let worstPerformerLast6Months = {
    segmentName: '',
    unitsSoldLastSixMonths: Infinity,
  }

  for (const segment of segments) {
    if (segment.unitsSoldLastWeek) {
      if (segment.unitsSoldLastWeek > bestPerformerLastWeek.unitsSoldLastWeek) {
        bestPerformerLastWeek = {
          segmentName: segment.segmentName,
          unitsSoldLastWeek: segment.unitsSoldLastWeek,
        }
      }
      if (
        segment.unitsSoldLastWeek < worstPerformerLastWeek.unitsSoldLastWeek
      ) {
        worstPerformerLastWeek = {
          segmentName: segment.segmentName,
          unitsSoldLastWeek: segment.unitsSoldLastWeek,
        }
      }
    }
    if (segment.unitsSoldLastSixMonths) {
      if (
        segment.unitsSoldLastSixMonths >
        bestPerformerLast6Months.unitsSoldLastSixMonths
      ) {
        bestPerformerLast6Months = {
          segmentName: segment.segmentName,
          unitsSoldLastSixMonths: segment.unitsSoldLastSixMonths,
        }
      }
      if (
        segment.unitsSoldLastSixMonths <
        worstPerformerLast6Months.unitsSoldLastSixMonths
      ) {
        worstPerformerLast6Months = {
          segmentName: segment.segmentName,
          unitsSoldLastSixMonths: segment.unitsSoldLastSixMonths,
        }
      }
    }
  }

  return {
    bestPerformerLastWeek: bestPerformerLastWeek.segmentName,
    bestPerformerLast6Months: bestPerformerLast6Months.segmentName,
    worstPerformerLastWeek: worstPerformerLastWeek.segmentName,
    worstPerformerLast6Months: worstPerformerLast6Months.segmentName,
  }
}

export const ProductForecastGraph = ({ segments }: Props): JSX.Element => {
  const insigntCardsData = getInsightCardsData(segments)

  const [selectedSegments, setSelectedSegments] = useState<Segment[]>(
    segments.slice(0, INITIAL_NUMBER_OF_SEGMENTS_TO_SHOW),
  )

  const defaultDomainScale: [AxisDomain, AxisDomain] = [0, 'auto']

  const [selectedUnitSegmentIndex, setSelectedUnitSegmentIndex] = useState(3)

  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(0)

  const weeklyData = selectedSegments.map(segment => {
    return { segmentName: segment.segmentName, data: segment.graphData.weekly }
  })

  const monthlyData = selectedSegments.map(segment => {
    return { segmentName: segment.segmentName, data: segment.graphData.monthly }
  })

  const transformer =
    selectedPeriodIndex === 1
      ? new MonthlyProductDemandDataTransformer(monthlyData)
      : new WeeklyProductDemandDataTransformer(weeklyData)

  const traces = useMemo(
    () => transformer.createTraces(selectedUnitSegmentIndex),
    [weeklyData, monthlyData, selectedUnitSegmentIndex, selectedPeriodIndex],
  )

  const tickFormatterCallback = useCallback(
    (v): string => (v ? commaDelimitedThousands(v) : ''),
    [],
  )

  const tooltipFormatterCallback = useCallback((value, category): [
    string,
    string,
  ] => {
    if (value === null || value === undefined) {
      return ['N/A', category]
    }

    return [commaDelimitedThousands(value as number), category]
  }, [])

  const handleSelectSegmentChange = (activeSegmentIds: string[]) => {
    const activeSegments = segments.filter(segment =>
      activeSegmentIds.includes(segment.segmentId),
    )

    // don't allow removing the last one
    if (activeSegments.length === 0) {
      return
    }

    setSelectedSegments(activeSegments)
  }

  const handleOnRemoveSegmentClicked = (segmentId: string) => {
    const activeSegments = selectedSegments.filter(
      segment => segment.segmentId !== segmentId,
    )

    // don't allow removing the last one
    if (activeSegments.length === 0) {
      return
    }

    setSelectedSegments(activeSegments)
  }

  const areTopSellingSegmentsSelected = useMemo(() => {
    return calculateIfTopSellingSegmentsSelected(segments, selectedSegments)
  }, [selectedSegments])

  return (
    <Grid container pt={1} width={'95%'}>
      <Grid item sx={{ mt: 1, mb: 2 }} xs={12}>
        <MultiSelect
          options={segments.map(segment => ({
            id: segment.segmentId,
            name: segment.segmentName,
            isSelected:
              !!selectedSegments?.find(
                selectedSegment =>
                  selectedSegment.segmentId === segment.segmentId,
              ) ?? false,
          }))}
          title={'Select Segment'}
          onChange={handleSelectSegmentChange}
          enableDelete={selectedSegments.length > 1}
          allowEmptyOptions={false}
        />
      </Grid>
      {areTopSellingSegmentsSelected && (
        <Grid item xs={12} mt={2} mb={2}>
          Top selling segments this week
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          boxShadow: `0 0 4px 0 ${Color.Shadow}`,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={'space-between'}
              pl={6}
              pr={6}
              mt={2}
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <Box>
                  <HorizontalTab
                    selectedTabIndex={selectedUnitSegmentIndex}
                    onChange={(
                      _event: React.SyntheticEvent<Element, Event>,
                      value: number,
                    ): void => {
                      setSelectedUnitSegmentIndex(value)
                    }}
                    tabOptions={['2 yr', '1 yr', '6 mo', '3 mo', '1 mo']}
                    ariaLabel={'Total unit by segment'}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={0}
                xs={0}
                sm={0}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Box>
                  <HorizontalTab
                    selectedTabIndex={selectedPeriodIndex}
                    onChange={(
                      _event: React.SyntheticEvent<Element, Event>,
                      value: number,
                    ): void => {
                      setSelectedPeriodIndex(value)
                    }}
                    tabWidth={100}
                    tabOptions={['Weekly', 'Monthly']}
                    ariaLabel={'Time period toggle'}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BFLineGraph
              disableLegend={true}
              containerProps={{
                sx: {
                  display: 'flex',
                  padding: '16px',
                  width: '100%',
                  height: '450px',
                  borderRadius: '8px',
                  userSelect: 'none',
                },
              }}
              data={traces}
              xAxisOptions={{
                label: {
                  value: `${selectedPeriodIndex === 0 ? 'Weeks' : 'Months'}`,
                  position: 'bottom',
                },
                minTickGap: transformer.minTickGap[selectedUnitSegmentIndex],
                ticks: transformer.getXAxisTicks(traces),
                height: 60,
                stroke: Color.Black,
                tick: props => (
                  <StackedXAxisMonthYear
                    {...props}
                    dateFormat="LLL"
                    parseFormat={transformer.tooltipDateStringFormat}
                  />
                ),
              }}
              yAxisOptions={{
                label: {
                  value: 'Units',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -10,
                },
                stroke: Color.Black,
                tickFormatter: tickFormatterCallback,
                type: 'number',
                allowDataOverflow: true,
                tickLine: true,
                domain: defaultDomainScale,
              }}
              tooltipOptions={{
                wrapperStyle: { pointerEvents: 'auto' },
                filterNull: false,
                formatter: tooltipFormatterCallback,
                content: ({ active, payload, label }: ToolTipProps) => {
                  return (
                    <CustomTooltip
                      active={active}
                      payload={payload}
                      label={label}
                      selectedLine={null}
                      segmentDataMap={transformer.segmentDataMap}
                      transformer={transformer}
                    />
                  )
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {selectedSegments.length !== segments.length ? (
          <LegendContainer
            segments={selectedSegments}
            onClose={handleOnRemoveSegmentClicked}
          />
        ) : (
          <InsightCards
            insightsContent={[
              {
                title: 'Best performer last week',
                content: insigntCardsData.bestPerformerLastWeek,
              },
              {
                title: 'Best performer last 6 mo',
                content: insigntCardsData.bestPerformerLast6Months,
              },
              {
                title: 'Worst performer last week',
                content: insigntCardsData.worstPerformerLastWeek,
              },
              {
                title: 'Worst performer last 6 mo',
                content: insigntCardsData.worstPerformerLast6Months,
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  )
}
