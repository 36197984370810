import { Category, Merchant } from '../../Transactions.types'

export const isCategory = (input: any): input is Category => {
  return (input as Category)?.label !== undefined
}

export const findMatchingIndex = (
  value: string,
  options: (Category | Merchant)[],
): number =>
  options.findIndex(option =>
    isCategory(option)
      ? option.id === value || option.label === value
      : option.merchantName === value,
  )
