import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { addUser } from '../../modules/user/user'
import { NewUserPayload } from '../../modules/user'
import { dispatch } from '../../store'
import { Store } from '../../modules/rootReducer'
import { PasswordInput } from '../designSystem/molecules/PasswordInput/PasswordInput'
import { CheckboxInput } from '../designSystem/atoms/CheckboxInput/CheckboxInput'
import { TermsOfServiceLink } from '../designSystem/atoms/TermOfServiceLink/TermsOfServiceLink'
import { Lock } from '../designSystem/assets/Lock/Lock'
import {
  CampaignRoute,
  useNavigationCampaign,
} from '../../hooks/useNavigationCampaign'
import Grid from '@mui/material/Grid'

// NOTE: English only, special characters like @:%_+~#?&=\ not allowed (other than the protocol)
const urlRegex = new RegExp(
  // starts with http(s):// protocol and/or www. subdomain, or no protocol/subdomain at all
  '^(http(s)?://)?(www\\.)?' +
    // has an alphanumeric (sub)domain name
    '([a-zA-Z0-9])+' +
    // with optional . or - separators
    '((\\.|\\-)([a-zA-Z0-9])+)*' +
    // must include a . followed by one or more letters
    '(\\.)([a-zA-Z])+' +
    // optionally ends with a /
    '(/)?$',
)

interface Props {
  newUser: NewUserPayload
}

const TermsOfServiceText = (): JSX.Element => {
  return (
    <Typography variant={'body1'}>
      <>
        <span>I agree to the </span>
        <TermsOfServiceLink />
      </>
    </Typography>
  )
}

export const SignUpFormImpl: React.FC<Props> = ({ newUser }) => {
  const { user, duplicateEmail } = newUser
  const navigate = useNavigationCampaign()

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required('Enter first name.')
      .max(50, 'Max characters is 50'),
    lastName: yup
      .string()
      .required('Enter last name.')
      .max(50, 'Max characters is 50'),
    companyName: yup
      .string()
      .required('Enter your company name.')
      .min(3, 'Enter a minimum of 3 characters'),
    companyUrl: yup
      .string()
      .required('Enter your company website.')
      .min(4, 'Enter a minimum of 4 characters')
      .max(50, 'Max characters is 50')
      .matches(urlRegex, 'Enter a valid URL format'),
    email: yup
      .string()
      .required('Enter your business email.')
      .email('Enter a valid email address format')
      .notOneOf([duplicateEmail], 'This email has a current account. Log in.'),
    password: yup
      .string()
      .required('Enter a password.')
      .min(5, 'Enter a minimum of 5 characters'),
    termsOfService: yup
      .bool()
      .oneOf([true], 'You must agree to the Terms of Service')
      .required('You must agree to the Terms of Service'),
  })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      companyUrl: '',
      email: '',
      password: '',
      termsOfService: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(addUser(values))
    },
  })

  // needed to trigger a revalidation
  // if a duplicate email was submitted initially
  useEffect(() => {
    if (formik) {
      formik.validateForm().catch(e => console.error(e))
    }
  }, [duplicateEmail])

  if (user?.id) {
    navigate(CampaignRoute.Home)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <div>
            <Grid container={true} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <div></div>
          </div>
          <TextField
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="companyUrl"
            label="Company Website (URL)"
            value={formik.values.companyUrl}
            onChange={formik.handleChange}
            error={
              formik.touched.companyUrl && Boolean(formik.errors.companyUrl)
            }
            helperText={formik.touched.companyUrl && formik.errors.companyUrl}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="email"
            label="Business Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />
          <PasswordInput
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password ? formik.errors.password : undefined
            }
            onBlur={formik.handleBlur}
          />
        </Stack>
        <CheckboxInput
          sx={{ mt: 3, width: '100%' }}
          renderLabel={(): JSX.Element => <TermsOfServiceText />}
          isError={
            !!(formik.touched.termsOfService && formik.errors.termsOfService)
          }
          value={formik.values.termsOfService}
          name="termsOfService"
          onChange={formik.handleChange}
          errorMessage={formik.errors.termsOfService || ''}
        />
        <Stack sx={{ mt: 3 }}>
          <Button
            startIcon={<Lock height={19} width={16} />}
            type="submit"
            variant="contained"
            color="success"
            size={'large'}
            style={{ height: '45px' }}
          >
            Create account
          </Button>
        </Stack>
      </form>
    </>
  )
}

const mapStateToProps = ({ newUser: { newUser } }: Store): Props => ({
  newUser,
})

export const SignupForm = connect(mapStateToProps)(SignUpFormImpl)
