import React, { useState } from 'react'
import { CompanyToAddRelation } from '../types'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SubscriptionPlan } from '../../../../server/data/models'
import {
  AppSubscriptionStatus,
  SubscriptionProvider,
} from '../../../../server/data/models/types'
import Grid from '@mui/material/Grid'
import { dispatch } from '../../../store'
import { createCompanySubscription } from '../../../modules/adminDashboard/adminDashboard'
import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Stack from '@mui/material/Stack'
import { CompanySummary } from './CompanySummary'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { AdminDashboardSubscription } from '../../../../server/services/adminDashboard'

interface Props {
  companyToAddRelation: CompanyToAddRelation
  subscriptionPlans: SubscriptionPlan[]

  onCancel: () => void
  onCreate: (newSubscription: AdminDashboardSubscription) => void
}
export const CreateSubscriptionModal = (props: Props): JSX.Element => {
  const { companyToAddRelation, subscriptionPlans, onCancel, onCreate } = props
  const [subscriptionPlanToAdd, setSubscriptionPlanToAdd] = useState<string>('')
  const handleAddSubscriptionSelectChange = (
    event: SelectChangeEvent,
  ): void => {
    setSubscriptionPlanToAdd(event.target.value)
  }

  const handleAddSubscriptionButtonPressed = (): void => {
    const newSubscriptionPlan:
      | SubscriptionPlan
      | undefined = subscriptionPlans.find(
      subscriptionPlan => subscriptionPlan.name === subscriptionPlanToAdd,
    )

    const companyId = companyToAddRelation?.company?.id

    if (newSubscriptionPlan && companyToAddRelation) {
      const adminDashboardSubscriptionPlan = {
        id: newSubscriptionPlan.id,
        feeCents: newSubscriptionPlan.feeCents,
        name: newSubscriptionPlan.name,
      }
      // use placeholder for id until we fetch active subscriptions from the db
      const adminDashboardSubscription = {
        id: '(refresh page for ID)',
        status: AppSubscriptionStatus.active,
        provider: SubscriptionProvider.brightflow,
        plan: adminDashboardSubscriptionPlan,
        companyId,
        createdAt: new Date(),
      }
      onCreate(adminDashboardSubscription)
      dispatch(
        createCompanySubscription({
          companyId,
          subscriptionPlanId: newSubscriptionPlan.id,
        }),
      )
    }
    setSubscriptionPlanToAdd('')
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Add Subscription</h5>
        <CompanySummary companyToAddRelation={companyToAddRelation} />
        <Grid container={true} justifyContent={'end'}>
          <Grid container={true} xs={12}>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: '100%' }}
              >
                <InputLabel>Subscription Plan</InputLabel>
                <Select
                  labelId="subscriptionPlanSelect"
                  id="subscriptionPlanSelect"
                  value={subscriptionPlanToAdd}
                  onChange={handleAddSubscriptionSelectChange}
                  label="Feature"
                >
                  {subscriptionPlans.map(subscriptionPlan => (
                    <MenuItem
                      key={subscriptionPlan.id}
                      value={subscriptionPlan.name}
                    >
                      {subscriptionPlan.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sx={{ mr: 1 }}>
            <Button
              variant={'outlined'}
              color={'warning'}
              onClick={(): void => {
                setSubscriptionPlanToAdd('')
                onCancel()
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!subscriptionPlanToAdd}
              variant={'contained'}
              color={'success'}
              onClick={(): void => handleAddSubscriptionButtonPressed()}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
