import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { catchThunk } from '../../error'
import { createThunkReducer } from '../../thunkReducerBuilder'
import { api } from '../../../lib/api'

// COHORTS
export const getAccountsCohorts = createAsyncThunk(
  'reports/cohorts/get',
  async (interval: string, thunkApi) =>
    api
      .get(`/api/reports/ltv?interval=${interval}`)
      .catch(catchThunk(thunkApi)),
)

const SET_GROSS_MARGIN = 'reports/gross-margin/set'

// GROSS MARGIN
export const getGrossMargin = createAsyncThunk(
  SET_GROSS_MARGIN,
  async thunkApi => api.get(`/api/reports/gross`),
)

// We don't actually dispatch this, it jitters the UI
// when the value you entered a quarter second ago
// comes back to the store
export const postGrossMargin = createAsyncThunk(
  'NO-OP',
  async (grossMargin: number, thunkApi) =>
    api
      .post('/api/reports/gross', {
        grossMargin,
      })
      .catch(catchThunk(thunkApi)),
)

export const selectGrossMargin = (s: LtvStoreData): number =>
  s.grossMargin.grossMargin

// REDUCERS
type LtvStoreData = {
  accountsCohorts: {
    cohorts: []
    min: number
    max: number
    minProjected: number
    maxProjected: number
    projectedCohort: []
    isLoading: boolean
    isPutting: boolean
    error: null | any
  }
  grossMargin: {
    isPutting: boolean
    grossMargin: number
    error: any | null
  }
}

export type StoreLtv = Readonly<LtvStoreData>

const cohortsKey = 'accountsCohorts'
const grossMarginKey = 'grossMargin'
const initialState: StoreLtv = {
  [cohortsKey]: {
    cohorts: [],
    min: 0,
    max: 0,
    minProjected: 0,
    maxProjected: 0,
    projectedCohort: [],
    isLoading: false,
    isPutting: false,
    error: null,
  },
  [grossMarginKey]: {
    grossMargin: 40,
    isPutting: false,
    error: null,
  },
}

export const ltvReducer = createReducer(initialState, builder => {
  // GET COHORTS
  createThunkReducer<StoreLtv>(
    builder,
    getAccountsCohorts.typePrefix,
    cohortsKey,
    (state, action) => {
      return {
        ...state,
        [cohortsKey]: {
          cohorts: action.payload.cohorts,
          min: action.payload.min,
          max: action.payload.max,
          minProjected: action.payload.minProjected,
          maxProjected: action.payload.maxProjected,
          projectedCohort: action.payload.projected,
          isLoading: false,
          isPutting: false,
          error: null,
        },
      }
    },
  )

  createThunkReducer(
    builder,
    SET_GROSS_MARGIN,
    grossMarginKey,
    (state, action) => {
      return {
        ...state,
        [grossMarginKey]: {
          // TODO(connor): payload should not be "any" type
          grossMargin:
            action.payload.grossMargin ?? state[grossMarginKey].grossMargin,
          isPutting: false,
          error: null,
        },
      }
    },
    'isPutting',
  )
})
