import React from 'react'

import { LoginForm } from '../../components/forms'
import { Link } from '../../components/forms/StyledElements'
import { SignContent } from '../../components/users/Sign'

export const LoginScreen = (): JSX.Element => (
  <SignContent
    title={'Log in to your account'}
    Form={<LoginForm />}
    InstructionsContent={
      <>
        <span>Don't have an account? </span>
        <Link to="/users/signup">Create one</Link>
      </>
    }
  />
)
