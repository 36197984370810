import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { KnownConnectors } from '../../../../server/connectors/known-connectors'
import { Providers } from './Providers'
import { Color } from '../../../Color'
import useLocalStorage from '../../../hooks/useLocalStorage'

type Props = {
  providerName: KnownConnectors
  redirectUrl?: string
}

export const NewConnection: React.FunctionComponent<Props> = ({
  providerName,
  redirectUrl = '/connectors',
}) => {
  const {
    title,
    icon: LogoIcon,
    modal: Modal,
    isOutboundUrl,
    shouldRedirect,
    url,
  } = Providers[providerName]

  const { modalToShow } = useParams<{ modalToShow: string }>()

  const history = useHistory()
  const outboundNav = (): void => {
    const parsedURL = shouldRedirect
      ? `${url}${encodeURIComponent(`${redirectUrl}/${providerName}`)}`
      : url
    document.location.href = parsedURL
  }
  const localNav = (): void => history.push(`${redirectUrl}${url}`)

  const initAuth: () => void = isOutboundUrl ? outboundNav : localNav

  // Using localStorage to handle modal opening mostly for external connections since we dont rely fully on modalToShow now
  // If exists on localStorage then the modal is opened if url is /connector/:connection
  // localStorage value is created on modal opening and removed on modal close
  // Need to allow opening the specific connector modal on multiple connections
  const localStorageKey = `connection-${providerName}`

  const localStorageValue = 'new'

  const [newConnection, setNewConnection] = useLocalStorage(localStorageKey, '')

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    newConnection === localStorageValue,
  )

  const handleCloseModal = () => {
    localStorage.removeItem(localStorageKey)
    setIsModalOpen(false)
    history.push(redirectUrl)
  }

  const handleClick = () => {
    setNewConnection(localStorageValue)
    setIsModalOpen(true)
    initAuth()
  }

  return (
    <>
      {Modal && isModalOpen && modalToShow === providerName && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
      <Box
        onClick={handleClick}
        sx={{
          borderRadius: '4px',
          border: `1px solid ${Color.VioletEggplant}`,
          color: Color.VioletEggplant,
          display: 'flex',
          padding: '4px 10px',
          cursor: 'pointer',
          maxWidth: '205px',
        }}
      >
        <AddOutlinedIcon sx={{ fontSize: 12, margin: '4px 4px 4px 0' }} />
        <LogoIcon height={20} width={20} />
        <Typography variant="body1" ml={1}>
          {title}
        </Typography>
      </Box>
    </>
  )
}
