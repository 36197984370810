import { createReducer } from '@reduxjs/toolkit'

import { createThunkReducer } from '../thunkReducerBuilder'

import {
  createPendingSubscription,
  getActiveSubscription,
  getPendingSubscription,
} from './subscriptions'
import { Subscription } from '../../../server/data/models'
import { LoadingAndError } from '../types'

export type SerializedSubscription = Omit<
  Subscription,
  'trialExpirationDate'
> & {
  trialExpirationDate: string | null
}

export type SubscriptionPayload = {
  subscription: SerializedSubscription | null
} & LoadingAndError

export type SubscriptionConfirmationUrlPayload = {
  confirmationUrl: string | null
} & LoadingAndError

// REDUCERS
type PendingSubscriptionStoreData = {
  pendingSubscriptions: SubscriptionPayload
}

type ActiveSubscriptionStoreData = {
  activeSubscriptions: SubscriptionPayload
}

type SubscriptionConfirmationUrlStoreData = {
  subscriptionConfirmationUrl: SubscriptionConfirmationUrlPayload
}

export type PendingSubscriptionData = Readonly<PendingSubscriptionStoreData>
export type ActiveSubscriptionData = Readonly<ActiveSubscriptionStoreData>
export type SubscriptionConfirmationUrlData = Readonly<SubscriptionConfirmationUrlStoreData>

const PENDING_SUBSCRIPTIONS_KEY = 'pendingSubscriptions'
const ACTIVE_SUBSCRIPTIONS_KEY = 'activeSubscriptions'
const SUBSCRIPTION_CONFIRMATION_URL_KEY = 'subscriptionConfirmationUrl'

const initialPendingSubscriptionState: PendingSubscriptionData = {
  pendingSubscriptions: {
    subscription: null,
    isLoading: false,
    error: null,
  },
}

const initialActiveSubscriptionState: ActiveSubscriptionData = {
  activeSubscriptions: {
    subscription: null,
    isLoading: false,
    error: null,
  },
}

const initialSubscriptionConfirmationUrlState: SubscriptionConfirmationUrlData = {
  subscriptionConfirmationUrl: {
    confirmationUrl: null,
    isLoading: false,
    error: null,
  },
}

export const pendingSubscriptionsReducer = createReducer(
  initialPendingSubscriptionState,
  builder => {
    // GET PENDING SUBSCRIPTIONS
    createThunkReducer(
      builder,
      getPendingSubscription.typePrefix,
      PENDING_SUBSCRIPTIONS_KEY,
      (state, action) =>
        ({
          ...state,
          [PENDING_SUBSCRIPTIONS_KEY]: {
            ...state[PENDING_SUBSCRIPTIONS_KEY],
            isLoading: null,
            error: null,
            subscription: action.payload.subscription,
          },
        } as PendingSubscriptionData),
    )
  },
)

export const activeSubscriptionsReducer = createReducer(
  initialActiveSubscriptionState,
  builder => {
    // GET ACTIVE SUBSCRIPTIONS
    createThunkReducer(
      builder,
      getActiveSubscription.typePrefix,
      ACTIVE_SUBSCRIPTIONS_KEY,
      (state, action) =>
        ({
          ...state,
          [ACTIVE_SUBSCRIPTIONS_KEY]: {
            ...state[ACTIVE_SUBSCRIPTIONS_KEY],
            isLoading: null,
            error: null,
            subscription: action.payload.subscription,
          },
        } as ActiveSubscriptionStoreData),
    )
  },
)

export const subscriptionConfirmationUrlReducer = createReducer(
  initialSubscriptionConfirmationUrlState,
  builder => {
    // GET CONFIRMATION URL
    createThunkReducer(
      builder,
      createPendingSubscription.typePrefix,
      SUBSCRIPTION_CONFIRMATION_URL_KEY,
      (state, action) =>
        ({
          ...state,
          [SUBSCRIPTION_CONFIRMATION_URL_KEY]: {
            ...state[SUBSCRIPTION_CONFIRMATION_URL_KEY],
            isLoading: null,
            error: null,
            confirmationUrl: action.payload.confirmationUrl,
          },
        } as SubscriptionConfirmationUrlData),
    )
  },
)
