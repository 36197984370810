import React from 'react'

import { OverviewCardTypes } from '../../Overview.types'
import { OverviewGraphCard } from '../OverviewGraphCard/OverviewGraphCard'

import { OverviewMarketingCardProps } from './OverviewMarketingCard.types'
import { useOverviewMarketingCard } from './OverviewMarketingCard.hooks'
import { OverviewMarketingCardEmptyState } from './components/OverviewMarketingCardEmptyState/OverviewMarketingCardEmptyState'

export const OverviewMarketingCard: React.FunctionComponent<OverviewMarketingCardProps> = ({
  expenseConnections,
  duration,
  granularity,
}) => {
  const { channels, isEmpty, isLoading, totals } = useOverviewMarketingCard({
    expenseConnections,
    duration,
    granularity,
  })

  return (
    <OverviewGraphCard
      type={OverviewCardTypes.Marketing}
      isEmpty={isEmpty}
      emptyState={<OverviewMarketingCardEmptyState />}
      channels={channels}
      totals={totals}
      isLoading={isLoading}
    />
  )
}
