import React from 'react'

interface Props {
  height: number
  width: number
}

export const SuccessIcon: React.FunctionComponent<Props> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${height} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Check Circle</title>
      <g id="v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Email-resent"
          transform="translate(-531.000000, -176.000000)"
          fill="#78BE20"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(531.000000, 176.000000)">
            <path
              d="M16.5,0 C7.392,0 0,7.392 0,16.5 C0,25.608 7.392,33 16.5,33 C25.608,33 33,25.608 33,
              16.5 C33,7.392 25.608,0 16.5,0 Z M16.5,29.7 C9.2235,29.7 3.3,23.7765 3.3,16.5 C3.3,
              9.2235 9.2235,3.3 16.5,3.3 C23.7765,3.3 29.7, 9.2235 29.7,16.5 C29.7,23.7765 23.7765,
              29.7 16.5,29.7 Z M24.0735,9.207 L13.2,20.0805 L8.9265,15.8235 L6.6,18.15 L13.2,
              24.75 L26.4,11.55 L24.0735,9.207 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
