import React, { useState } from 'react'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'

import { TableActionButton } from '../TransactionsTableToolbar/TransactionsTableToolbar.styles'

import { BasicModal } from '../../../../../components/designSystem/organisms/BasicModal/BasicModal'
import { BulkActionModal } from '../BulkActionModal/BulkActionModal'
import { BulkActionModalTypes } from '../BulkActionModal/BulkActionModal.types'
import { Category, Merchant } from '../../Transactions.types'
import { UpdatedLabelInput } from '../../../../../../server/app-data-service/generatedTypes'
import { HandleOpenAddMerchantModalFn } from '../TransactionsTable/TransactionsTable.types'

type Props = {
  categories: Category[]
  merchants: Merchant[]
  selectedRows: Map<GridRowId, GridValidRowModel>
  handleBulkUpdate: (
    newValue: Category | Merchant,
    rows: UpdatedLabelInput[],
  ) => Promise<void>
  handleOpenAddMerchantModal: HandleOpenAddMerchantModalFn
}

export const BulkActionsMenu = ({
  categories,
  merchants,
  selectedRows,
  handleBulkUpdate,
  handleOpenAddMerchantModal,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState<BulkActionModalTypes>(
    BulkActionModalTypes.Category,
  )
  const isMenuOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (type: BulkActionModalTypes): void => {
    setAnchorEl(null)
    setModalType(type)
    setIsModalOpen(true)
  }

  const handleApplyBulkUpdate = async (
    newValue: Category | Merchant,
  ): Promise<void> =>
    handleBulkUpdate(
      newValue,
      Array.from(selectedRows.values()).map(
        ({
          id,
          categoryId,
          categoryLabel,
          merchantId,
          merchantName,
          transactionDate,
          accountId,
          platformId,
        }) => ({
          id,
          categoryId,
          categoryLabel,
          merchantId,
          merchantName,
          transactionDate,
          accountId,
          platformId,
        }),
      ),
    )

  return (
    <>
      <TableActionButton
        startIcon={<CheckBoxOutlinedIcon />}
        id="bulk-action-button"
        aria-controls={isMenuOpen ? 'bulk-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        disabled={selectedRows.size <= 1}
      >
        Bulk Actions
      </TableActionButton>
      <Menu
        id="bulk-action-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'bulk-action-button',
        }}
      >
        <MenuItem
          onClick={(): void =>
            handleMenuItemClick(BulkActionModalTypes.Category)
          }
        >
          <ListItemIcon>
            <CategoryOutlinedIcon />
          </ListItemIcon>
          Update Category Labels
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(): void =>
            handleMenuItemClick(BulkActionModalTypes.Merchant)
          }
        >
          <ListItemIcon>
            <StorefrontOutlinedIcon />
          </ListItemIcon>
          Update Merchant Labels
        </MenuItem>
      </Menu>
      <BulkActionModal
        handleClose={(): void => setIsModalOpen(false)}
        handleApplyBulkUpdate={handleApplyBulkUpdate}
        handleOpenAddMerchantModal={handleOpenAddMerchantModal}
        isOpen={isModalOpen}
        options={
          modalType === BulkActionModalTypes.Category ? categories : merchants
        }
        transactionCount={selectedRows.size}
      />
    </>
  )
}
