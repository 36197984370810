import React, { useEffect, useImperativeHandle } from 'react'
import {
  DataGridPro,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCell,
  GridCellProps,
  GridColumnGroupingModel,
  GridColumns,
  GridRow,
  GridRowHeightParams,
  GridRowProps,
  GridRowTreeNodeConfig,
  GridRowsProp,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { SxProps } from '@mui/material'
import CustomGroupCell from './components/CustomGroupCell'
import { Color } from '../../../../Color'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

type Props = {
  apiRef: React.MutableRefObject<GridApiPro>
  columns: GridColumns
  rows: GridRowsProp
  treeDataPath: string
  headerName: string
  headerStyles?: { [key: string]: SxProps }
  renderCustomCellValue?: (args: {
    value: any
    field: string
    renderValue: any
    rowNode: GridRowTreeNodeConfig | null
    rowContext: any
  }) => JSX.Element
  formatCellValue?: (value: any) => string
  tooltips?: { [key: string]: string }
  columnGroups?: GridColumnGroupingModel
}

export const InformativeTable = ({
  apiRef: ref,
  columns,
  rows,
  treeDataPath,
  headerName,
  headerStyles,
  renderCustomCellValue,
  formatCellValue,
  tooltips,
  columnGroups,
}: Props): JSX.Element => {
  const apiRef = useGridApiRef()
  useImperativeHandle(ref, () => apiRef.current, [{}])

  useEffect(() => {
    const scrollToIndexes = (): void => {
      if (apiRef.current) {
        apiRef.current.scrollToIndexes({
          colIndex: columns.length,
          rowIndex: 0,
        })
      }
    }

    const timeoutId = setTimeout(scrollToIndexes, 100)
    return () => clearTimeout(timeoutId)
  }, [apiRef, columns.length])

  const CustomRow = (props: GridRowProps) => {
    const { index, ...other } = props
    const level = props.row?.[treeDataPath].length
    const isChildRow = level !== 1
    const className = isChildRow ? `row-child` : 'row-parent'
    return <GridRow className={className} index={index} {...other} />
  }
  const CustomCell = (props: GridCellProps) => {
    const { children, value, field, rowId } = props
    const rowNode = apiRef.current.getRowNode(rowId)
    const rowContext = apiRef.current.getRow(rowId)
    if (!children) {
      const formattedValue = formatCellValue ? formatCellValue(value) : value
      if (renderCustomCellValue) {
        const customValue = renderCustomCellValue({
          value,
          field,
          rowNode,
          renderValue: formattedValue,
          rowContext,
        })
        return <GridCell {...props}>{customValue}</GridCell>
      }
      return <GridCell {...props}>{formattedValue}</GridCell>
    }
    return <GridCell {...props} />
  }

  return (
    <DataGridPro
      experimentalFeatures={{ columnGrouping: !!columnGroups }}
      autoHeight={true}
      sx={{
        height: '100%',
        width: '100%',
        ...headerStyles,
        '.MuiDataGrid-columnHeaderTitleContainer': {
          justifyContent: 'center',
        },
        '.MuiDataGrid-virtualScroller': {
          fontSize: 13,
        },
        '.MuiDataGrid-treeDataGroupingCellToggle': {
          marginRight: 0,
          flex: 0,
        },
        '.MuiDataGrid-columnSeparator, .MuiDataGrid-footerContainer': {
          display: 'none',
        },
        '.MuiDataGrid-cell.center-align-cell': {
          justifyContent: 'center',
        },
        '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
          '&:focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-columnHeader': {
          color: Color.DarkerPurple,
        },
        '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderRight: `1px solid ${Color.Grey4}`,
        },
        '.MuiDataGrid-cell--withRenderer': {
          '.row-group-childless-level-0, .row-group-level-0, .row-group-childless-level-1': {
            paddingLeft: '48px',
          },
          '.row-group-childless-level-2': {
            paddingLeft: '64px',
          },
          '.row-group-childless-level-3': {
            paddingLeft: '80px',
          },
          '.row-group-level-2': {
            marginLeft: '15px',
          },
          padding: 0,
        },
        '.MuiDataGrid-row:hover, .row-child.Mui-hovered': {
          backgroundColor: 'unset',
        },
        '.row-group-collapse': {
          '&:hover, &:focus-visible': {
            background: 'none',
          },
          padding: '0px 14px 0px 21px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '.row-parent ~ .row-child': {
          color: Color.Grey5,
          '.MuiDataGrid-cell--withRenderer': {
            color: Color.DarkerPurple,
          },
        },
        '.row-parent ~ .row-child:nth-child(even)': {
          background: Color.Grey1,
        },
        '.MuiDataGrid-row': {
          'a, a:hover': {
            color: 'inherit',
            textDecorationColor: 'unset',
          },
        },
        '.row-group-header': {
          display: 'flex',
          '.icon-container': {
            padding: '2px 0 0 21px',
          },
          '.icon-container + .row-group-level-0': {
            paddingLeft: '14px',
          },
        },
        '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--filledGroup': {
          border: 'none',
          '.MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'left',
            border: 'none',
          },
        },
        '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--emptyGroup': {
          border: 'none',
        },
        '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
          boxShadow: 'none',
        },
      }}
      components={{
        Row: CustomRow,
        Cell: CustomCell,
      }}
      treeData
      getTreeDataPath={row => row[treeDataPath]}
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      loading={rows.length === 0}
      disableSelectionOnClick
      disableColumnReorder
      disableColumnMenu
      disableChildrenSorting
      disableExtendRowFullWidth
      disableColumnFilter
      disableColumnSelector
      disableColumnResize
      disableChildrenFiltering
      disableDensitySelector
      disableMultipleColumnsFiltering
      disableMultipleColumnsSorting
      disableMultipleSelection
      disableVirtualization
      hideFooterSelectedRowCount
      isGroupExpandedByDefault={(node: GridRowTreeNodeConfig) =>
        node.depth === 0
      }
      groupingColDef={{
        width: 330,
        headerName,
        renderCell: params => (
          <CustomGroupCell tooltips={tooltips} {...params} />
        ),
      }}
      getRowClassName={params => {
        const rowNode = apiRef.current.getRowNode(params.id)
        const isChildrenExpanded = rowNode?.childrenExpanded
        const depthClass =
          rowNode?.depth !== undefined
            ? rowNode.depth >= 1
              ? 'child'
              : 'parent'
            : ''
        if (params.row.headerClassName) {
          // If a headerClassName is set to not parent categories this will make them exanded in terms of CSS
          const className = params.row.headerClassName
          return `row-level-${className} ${depthClass}-expanded`
        }
        return isChildrenExpanded ? `${depthClass}-expanded` : ''
      }}
      getCellClassName={params => {
        return params.field !== GRID_TREE_DATA_GROUPING_FIELD
          ? 'center-align-cell'
          : ''
      }}
      getRowHeight={(params: GridRowHeightParams) => {
        const rowNode = apiRef.current.getRowNode(params.id)
        const level = rowNode?.depth
        return level === 0 ? 40 : 25
      }}
      pinnedColumns={{ left: [GRID_TREE_DATA_GROUPING_FIELD] }}
      columnGroupingModel={columnGroups}
    />
  )
}
