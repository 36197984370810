import React, { useCallback, useState } from 'react'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Edit from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Check from '@mui/icons-material/Check'
import { dispatch } from '../../../store'

import { Color } from '../../../Color'
import {
  deleteProductSegment,
  updateProductSegment,
} from '../../../modules/adminDashboard/productDemand/productSegmentActions'

import { LockableTextInput } from '../../../components/designSystem/atoms/LockableTextInput/LockableTextInput'

import Typography from '@mui/material/Typography'

import { BasicModal } from '../../../components/designSystem/organisms/BasicModal/BasicModal'

const iconStyle = {
  height: '14px',
  width: '14px',
  color: Color.LightGrey,
  '&:hover': {
    color: Color.VioletEggplant,
  },
}

export const SegmentCard = ({
  segmentName,
  segmentId,
}: {
  segmentName: string
  segmentId: string
}): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false)
  const [segValue, setSegValue] = useState(segmentName)
  const [displayWarning, setDisplayWarning] = useState(false)

  const onModalDeleteButtonClicked = useCallback(() => {
    dispatch(deleteProductSegment({ segmentId }))
    setDisplayWarning(false)
  }, [segmentId])

  const onModalButtonClicked = useCallback(() => {
    setDisplayWarning(false)
  }, [])
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          maxWidth: 500,
          width: 'fit-content',
          maxHeight: 100,
          display: 'flex',
          margin: '4px 4px 8px 8px',
        }}
      >
        <CardContent>
          <LockableTextInput
            value={segValue}
            isLocked={!isEditable}
            setValue={(val): void => {
              setSegValue(val)
            }}
          />
        </CardContent>
        <CardActions
          sx={{
            borderLeft: `1px solid ${Color.LighterGrey}`,
          }}
        >
          <div
            style={{
              borderRight: `1px solid ${Color.LighterGrey}`,
              paddingRight: '4px',
            }}
          >
            <IconButton
              aria-label="Edit product segment"
              onClick={(): void => {
                // if in editing mode, and button is clicked, we want to save the value
                if (isEditable && segmentName !== segValue) {
                  dispatch(
                    updateProductSegment({ segmentId, segmentName: segValue }),
                  )
                }
                setIsEditable(!isEditable)
              }}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {isEditable ? <Check sx={iconStyle} /> : <Edit sx={iconStyle} />}
            </IconButton>
          </div>
          <div>
            <IconButton
              aria-label="Delete product segment"
              onClick={(): void => {
                setDisplayWarning(true)
              }}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <DeleteForeverIcon sx={{ ...iconStyle, color: Color.ErrorRed }} />
            </IconButton>
          </div>
        </CardActions>
      </Card>
      <BasicModal
        height={200}
        width={400}
        isOpen={displayWarning}
        handleClose={onModalButtonClicked}
        onDismiss={onModalButtonClicked}
        title={segmentName}
        confirmButtonText={'Delete segment'}
        cancelButtonText={'Cancel'}
        onConfirmButtonClicked={onModalDeleteButtonClicked}
        onCancelButtonClicked={onModalButtonClicked}
        buttonJustifyContent={'flex-end'}
        renderContent={(): JSX.Element => (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you delete this, you will need to re-map any products that have
            this segment
          </Typography>
        )}
        confirmButtonColor="warning"
      />
    </>
  )
}
