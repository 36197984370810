export enum KNOWN_CONNECTOR_KEYS {
  amazonSeller = 'amazonSeller',
  amazon = 'amazon',
  shopify = 'shopify',
  googleAds = 'googleAds',
  facebookMarketing = 'facebookMarketing',
  quickbooksOnline = 'quickbooksOnline',
  netSuite = 'netSuite',
  xero = 'xero',
  plaid = 'plaid',
}

export enum CONNECTORS_CATEGORIES {
  expense = 'expense',
  asset = 'asset',
  accounting = 'accounting',
  banking = 'banking',
}

export type CategoryKeys = CONNECTORS_CATEGORIES

export type KnownConnectors = `${KNOWN_CONNECTOR_KEYS}`

/* NOTE(josé): BIDIRECTIONAL_CONNECTORS
 * These are the syncs that can go in both
 * directions at the same time:
 * Forwards and Backwards.
 * These syncs can do so because their
 * implementation uses 2 separate syncInfos,
 * one for the forwards sync and another one
 * for the backwards sync
 * */
export const BIDIRECTIONAL_CONNECTORS: Array<KnownConnectors> = [
  'amazonSeller',
  'shopify',
]

export const REVENUE_CONNECTORS: Array<KnownConnectors> = [
  'amazonSeller',
  'shopify',
]

export const EXPENSE_CONNECTORS: Array<KnownConnectors> = [
  'amazon',
  'facebookMarketing',
  'googleAds',
]

export const ACCOUNTING_CONNECTORS: Array<KnownConnectors> = [
  'quickbooksOnline',
  'netSuite',
  'xero',
]

export const MARKETING_CONNECTORS: Array<KnownConnectors> = [
  'amazon',
  'facebookMarketing',
  'googleAds',
]

export const BANKING_CONNECTORS: Array<KnownConnectors> = ['plaid']

export const ALLOW_MULTIPLE_CONNECTIONS: Array<KnownConnectors> = ['plaid']

export interface KnownConnectorInfo {
  title: string
  category: CategoryKeys
  // TODO(connor): I think we can deprecate infoIsReadyToSync?
  // We shouldn't be making connections with unsyncable info, that was
  // a part of the old onboarding flow.
  infoIsReadyToSync?: (
    info: { [key: string]: number | string | null | undefined } | undefined,
  ) => boolean
}

export const KNOWN_CONNECTORS: {
  [connector in KnownConnectors]: KnownConnectorInfo
} = {
  shopify: {
    title: 'Shopify',
    category: CONNECTORS_CATEGORIES.asset,
  },
  amazonSeller: {
    title: 'Amazon.com Seller Central Marketplace',
    category: CONNECTORS_CATEGORIES.asset,
  },
  amazon: {
    title: 'Amazon Advertising',
    category: CONNECTORS_CATEGORIES.expense,
    // infoIsReadyToSync: (info): boolean =>
    //   !!info && !!info.
  },
  googleAds: {
    title: 'Google Ads Adwords',
    category: CONNECTORS_CATEGORIES.expense,
    infoIsReadyToSync: (info): boolean =>
      !!info && !!info.customerId && !!info.currencyCode && !!info.dateTimeZone,
  },
  facebookMarketing: {
    title: 'Facebook Ads',
    category: CONNECTORS_CATEGORIES.expense,
    infoIsReadyToSync: (info): boolean =>
      !!info && !!info.account_id && !!info.timezone_name,
  },
  quickbooksOnline: {
    title: 'QuickBooks Online',
    category: CONNECTORS_CATEGORIES.accounting,
    infoIsReadyToSync: (info): boolean => !!info && !!info.hasExpenseAccounts,
  },
  netSuite: {
    title: 'NetSuite',
    category: CONNECTORS_CATEGORIES.accounting,
    infoIsReadyToSync: (info): boolean => !!info && !!info.infoIsReadyToSync,
  },
  xero: {
    title: 'Xero',
    category: CONNECTORS_CATEGORIES.accounting,
    infoIsReadyToSync: (info): boolean => !!info && !!info.infoIsReadyToSync,
  },
  plaid: {
    title: 'Bank account',
    category: CONNECTORS_CATEGORIES.banking,
    infoIsReadyToSync: (info): boolean => !!info && !!info.infoIsReadyToSync,
  },
}

export const isKnownConnectorKey = (
  key: string | KnownConnectors | undefined,
): key is KNOWN_CONNECTOR_KEYS => {
  if (!key) return false
  return Object.keys(KNOWN_CONNECTORS).includes(key)
}
