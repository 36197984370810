export const mapCreditScoreFactorToDisplayValue = (
  factorName: string,
): string => {
  const creditScoreFactorToDisplayValueMap: { [key: string]: string } = {
    embedded: 'Embedded Value',
    liquidity: 'Liquidity',
    ability: 'Ability to Scale',
  }

  return creditScoreFactorToDisplayValueMap?.[factorName] ?? factorName
}

export const getCreditScoreFactorDescription = (factorName: string): string => {
  const creditScoreFactorToDescriptionMap: { [key: string]: string } = {
    creditScore:
      'Our business credit score helps you benchmark how your business is performing and understand your ability to qualify for a loan.',
    embedded:
      'Focuses on how fast your business is growing and how profitable it is.',
    liquidity:
      'Measures the strength of your balance sheet and borrowing capacity.',
    ability: 'Focuses on future growth, cash generation, and sustainability.',
  }

  return creditScoreFactorToDescriptionMap?.[factorName] ?? ''
}
