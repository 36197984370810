import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const CreditScoreRatingContainer = styled(Box, {
  shouldForwardProp: (prop: any): boolean => prop !== 'borderColor',
})<{ borderColor: string }>(({ borderColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '15px',
  padding: '15px',
  gap: '15px',
  border: '3px solid',
  borderRadius: '30px',
  borderColor,
}))

export const CreditScoreFactorContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  padding: 0 45px;

  /* No padding in mobile view */
  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`,
)
