import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import MuiInput from '@mui/material/Input'

const Input = styled(MuiInput)`
  width: 42px;
`

interface InputSliderProps {
  min: number
  max: number
  label: string
  helperText: string
  disabled?: boolean
  value: number
  onValueChanged?: (value: number) => void
}

export const InputSlider = (props: InputSliderProps): JSX.Element => {
  const { min, max, label, helperText, onValueChanged } = props
  const [value, setValue] = React.useState<
    number | string | Array<number | string>
  >(props.value)

  const [disabled, setDisabled] = React.useState(props.disabled)

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const updateValue = (newValue: number | string | number[]): void => {
    setValue(newValue)
    if (!onValueChanged) {
      return
    }
    if (Array.isArray(newValue)) {
      const [num1] = newValue
      onValueChanged(Number(num1))
    } else {
      onValueChanged(Number(newValue))
    }
  }

  const onChange = (event: Event, newValue: number | number[]): void => {
    updateValue(newValue)
  }

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value)
    updateValue(newValue)
  }

  const handleBlur = (): void => {
    if (value < min) {
      updateValue(min)
    } else if (value > max) {
      updateValue(max)
    }
  }

  return (
    <Box>
      <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Slider
            disabled={disabled}
            valueLabelDisplay="auto"
            marks
            min={min}
            max={max}
            value={typeof value === 'number' ? value : min}
            onChange={onChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            disabled={disabled}
            value={value}
            size="small"
            onChange={inputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.secondary">
            <small>{helperText}</small>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
