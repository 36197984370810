/**
 * `DatePrecision` represents the scale or granularity of a period of time. The
 * members of this enum correspond to the allowable values of PostgreSQL's
 * [`date_trunc()`][date_trunc] function.
 *
 * [date_trunc]: https://www.postgresql.org/docs/9.1/functions-datetime.html#FUNCTIONS-DATETIME-TRUNC
 */
export enum DatePrecision {
  Microsecond = 'microseconds',
  Millisecond = 'milliseconds',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
  Decade = 'decade',
  Century = 'century',
  Millenium = 'millenium',
}

export class InvalidDatePrecisionError extends Error {
  constructor(readonly precision: any) {
    super(`invalid date precision: ${precision}`)
  }
}
