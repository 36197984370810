import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const linkPlaid = createAsyncThunk(
  '/plaid/link',
  async ({ externalId }: { externalId: string | null }) =>
    api.post('/connect/plaid/auth/link', { externalId }),
)

export const authPlaid = createAsyncThunk(
  '/plaid/auth',
  async (payload: { publicToken: string; connectionKey: string }) =>
    api.post('/connect/plaid/auth/access', { ...payload }),
)
