import React from 'react'
import { Formik } from 'formik'
import { GoogleAdsSimpleCustomer } from '../../../../server/api-wrappers/google-ads/types'
import { dispatch } from '../../../store'
import { putGoogleAdsCustomer } from '../../../modules/welcome'
import { SimpleRadioFormComponent } from './SimpleRadioFormComponent'

type Props = {
  onClose: () => void
  customers: Array<
    GoogleAdsSimpleCustomer & {
      cost: number | undefined
    }
  >
  className?: string
}

export const SelectGoogleAdsCustomerForm: React.FunctionComponent<Props> = ({
  customers,
  onClose,
}) => {
  const headings = ['Account Name', 'Ad spend \n (last 7 days)']
  const formValues = customers.map(customer => {
    return {
      label: customer.descriptiveName,
      value: customer.customerId,
      cost: customer.cost!,
    }
  })

  return (
    <>
      <Formik
        key="select-google-ads-customer-form"
        initialValues={{
          value: null,
        }}
        onSubmit={(values): void => {
          const customer = customers.find(
            customer => customer.customerId === values.value,
          )
          dispatch(putGoogleAdsCustomer(customer!))
          onClose()
        }}
      >
        {(renderProps): any => {
          return (
            <SimpleRadioFormComponent
              title="Select Google Ads account"
              headings={headings}
              formData={formValues}
              disableSubmit={!renderProps.values.value}
              onCancel={onClose}
            />
          )
        }}
      </Formik>
    </>
  )
}
