import React from 'react'

import { useOverviewBankingCard } from './OverviewBankingCard.hooks'
import { TimeParams } from '../../Overview.types'

import { OverviewCardTypes } from '../../Overview.types'
import { OverviewGraphCard } from '../OverviewGraphCard/OverviewGraphCard'
import { OverviewBankingCardEmptyState } from './components/OverviewBankingCardEmptyState/OverviewBankingCardEmptyState'

export const OverviewBankingCard: React.FunctionComponent<TimeParams> = ({
  duration,
  granularity,
}) => {
  const { channels, isEmpty, isLoading, totals } = useOverviewBankingCard({
    duration,
    granularity,
  })

  return (
    <OverviewGraphCard
      type={OverviewCardTypes.Banking}
      isEmpty={isEmpty}
      channels={channels}
      totals={totals}
      isLoading={isLoading}
      emptyState={<OverviewBankingCardEmptyState />}
    />
  )
}
