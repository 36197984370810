import React from 'react'
import { format, parse } from 'date-fns'
import { Color } from '../../../../../Color'

export const StackedXAxisMonthYear = ({
  x,
  y,
  payload,
  dateFormat = 'LLL d',
  parseFormat = 'LLL dd, yyyy',
}: any): JSX.Element => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill={Color.Black}>
        <tspan textAnchor="middle" x="0">
          {format(parse(payload.value, parseFormat, new Date()), dateFormat)}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20">
          {format(parse(payload.value, parseFormat, new Date()), 'yyyy')}
        </tspan>
      </text>
    </g>
  )
}
