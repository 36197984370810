import {
  TimeSeriesChannel,
  TimeSeriesData,
  useGetMarketingByDurationQuery,
} from '../../../../../../server/app-data-service/generatedTypes'
import { OverviewMarketingCardProps } from './OverviewMarketingCard.types'

export const useOverviewMarketingCard = ({
  expenseConnections,
  duration,
  granularity,
}: OverviewMarketingCardProps) => {
  const { data, loading } = useGetMarketingByDurationQuery({
    variables: {
      duration,
      granularity,
    },
  })

  const hasOneYearExpenseData = expenseConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const channels = data?.MarketingByDuration?.marketingExpenseCents?.channels?.filter(
    (channel): channel is TimeSeriesChannel => !!channel,
  )

  const nonZeroChannels = channels?.filter(
    channel => channel?.total && channel?.total > 0,
  )

  const totals: TimeSeriesData | null = data?.MarketingByDuration
    ?.marketingExpenseCents?.totals ?? {
    total: null,
    timeSeries: null,
  }

  return {
    channels: nonZeroChannels?.length ? nonZeroChannels : null,
    isEmpty: !hasOneYearExpenseData,
    isLoading: loading,
    totals,
  }
}
