import { DateGranularity } from '../../../../server/app-data-service/generatedTypes'
import { Connection } from '../../../modules/connectors/types'

export type TimeParams = {
  duration: number
  granularity: DateGranularity
}

export type OverviewProps = {
  revenueConnections: Connection[]
  expenseConnectors: Connection[]
  accountConnectors: Connection[]
  bankingConnectors: Connection[]
}

export enum TimePeriods {
  yesterday = 'yeserday',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
}

export enum OverviewHighlightMetrics {
  AOV = 'AOV',
  CAC = 'CAC',
  CashBalance = 'CashBalance',
  NewCustomers = 'NewCustomers',
  OrderRevenue = 'OrderRevenue',
  OrderVolume = 'OrderVolume',
  ROAS = 'ROAS',
}

export enum OverviewCardTypes {
  AOV = 'AOV',
  Banking = 'Banking',
  CAC = 'CAC',
  CreditScore = 'CreditScore',
  CustomerValue = 'CustomerValue',
  Marketing = 'Marketing',
  NewCustomer = 'NewCustomers',
  OrderRevenue = 'OrderRevenue',
  OrderVolume = 'OrderVolume',
  Units = 'Units',
}
