import React from 'react'
import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'

export const TextInput = styled('input')`
  background: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid;
  border-color: ${Color.DarkBlue};
  align-self: 'flex-start';
`
