import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CompanyScenarioCreationPayload,
  CompanyScenarioUpdatePayload,
} from '../../../server/services/cashFlowForecast'

export const getCashflowScenarios = createAsyncThunk(
  'app/forecast/cashflow/scenarios/get',
  async () => api.get(`/api/forecast/cashflow/scenarios`),
)

export const createCashflowScenario = createAsyncThunk(
  'app/forecast/cashflow/scenarios/create',
  async (payload: CompanyScenarioCreationPayload, thunkAPI) => {
    try {
      const response = api.post(`/api/forecast/cashflow/scenarios/create`, {
        ...payload,
      })

      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)

export const editCashflowScenario = createAsyncThunk(
  'app/forecast/cashflow/scenarios/edit',
  async (payload: CompanyScenarioUpdatePayload, thunkAPI) => {
    try {
      const response = api.put(`/api/forecast/cashflow/scenarios/update`, {
        ...payload,
      })

      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)

export const deleteCashflowScenario = createAsyncThunk(
  'app/forecast/cashflow/scenarios/delete',
  async (companyScenarioId: string, thunkAPI) => {
    try {
      const response = api.delete(
        `/api/forecast/cashflow/scenarios/delete?companyScenarioId=${companyScenarioId}`,
      )

      return response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)
