import React from 'react'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'

export interface QueryParamRouteProps {
  path: string
  paramValue: string
  paramName: string
  component: JSX.Element
}

/*
 * Used as guidance to set up query param routers
 * */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const QueryParamRoute = (props: QueryParamRouteProps): JSX.Element => {
  return <React.Fragment></React.Fragment>
}

interface QueryParamDictionary {
  [key: string]: QueryParamRouteProps[]
}

/**
 * Parses <CampaignRoute /> child elements to build a dictionary
 * of campaigns grouped by path/campaign
 * */
const parseQueryParamRoutes = (
  queryParamNodes: React.ReactNode,
): QueryParamDictionary => {
  return React.Children.toArray(queryParamNodes).reduce<QueryParamDictionary>(
    (queryParamDict, queryParamRoute) => {
      const {
        props,
      } = queryParamRoute as React.ReactElement<QueryParamRouteProps>
      const { path, paramName } = props as QueryParamRouteProps
      const key = `${paramName}-${path}`
      if (queryParamDict[key]) {
        queryParamDict[key] = [...queryParamDict[key], props]
      } else {
        queryParamDict[key] = [props]
      }
      return queryParamDict
    },
    {},
  )
}

export const QueryParamRouter = ({
  defaultComponent,
  children,
}: {
  defaultComponent: JSX.Element
  children: React.ReactNode
}): JSX.Element => {
  const queryParamDictionary = parseQueryParamRoutes(children)

  return (
    <Switch>
      {Object.entries(queryParamDictionary).map(([key, routes]) => {
        const { path, paramName } = routes[0]

        return (
          <Route
            key={key}
            path={path}
            exact
            render={(props): JSX.Element => {
              const paramValue = new URLSearchParams(props.location.search).get(
                paramName,
              )
              const queryParamRoute = routes.find(
                route => route.paramValue === paramValue,
              )
              if (queryParamRoute) {
                return queryParamRoute.component
              } else {
                return defaultComponent
              }
            }}
          />
        )
      })}
    </Switch>
  )
}
