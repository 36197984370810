import React from 'react'

import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'

import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import { DialogCloseIcon } from '../../../atoms/DialogCloseIcon/DialogCloseIcon'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

export const LegendCard = ({
  onClose,
  titleComponent,
  contentComponent,
  renderLegendAction,
  sx,
}: {
  onClose?: (event: React.MouseEvent) => void
  titleComponent?: JSX.Element
  contentComponent?: JSX.Element
  renderLegendAction?: JSX.Element
  sx?: SxProps<Theme>
}): JSX.Element => {
  const [fadeOut, setFadeOut] = React.useState(false)

  const handleFadeOut = (event: React.MouseEvent): void => {
    if (onClose) {
      onClose(event)
    }
    setFadeOut(true)
  }

  return (
    <Fade in={!fadeOut} unmountOnExit>
      <Paper variant="outlined" elevation={0} sx={{ ...sx, p: 1 }}>
        <Grid container={true}>
          <Grid item container xs={12}>
            <Grid item={true} xs={10}>
              <Card
                sx={{
                  border: 'none !important',
                  boxShadow: 'none !important',
                  borderRadius: 0,
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Grid container={true}>
                    {titleComponent}
                    {contentComponent}
                  </Grid>
                </CardContent>
                <CardActions>{renderLegendAction}</CardActions>
              </Card>
            </Grid>
            {onClose && (
              <Grid
                item={true}
                container={true}
                xs={2}
                justifyContent="flex-end"
              >
                <DialogCloseIcon onClick={handleFadeOut} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  )
}
