import { combineReducers } from 'redux'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { amazonSellerUriReducer } from './amazonSeller'
import { bootstrapReducer } from './bootstrap'
import { companies } from './companies'
import { companyFeaturesReducer } from './companyFeatures'
import { connectorsReducer } from './connectors'
import { revenueReducer } from './reports/revenue'
import { executiveReducer } from './reports/executive'
import { roasReducer } from './reports/roas'
import { ltvReducer } from './reports/ltv'
import {
  activeSubscriptionsReducer,
  pendingSubscriptionsReducer,
  subscriptionConfirmationUrlReducer,
} from './subscriptions'
import { subscriptionPlansRootReducer } from './subscriptionPlans'
import {
  forgotPasswordReducer,
  loggedInUserReducer,
  newUserReducer,
  resetPasswordReducer,
  userReducer,
} from './user'
import { welcomeReducer } from './welcome'
import { revenueForecastReducer } from './revenueForecast'
import { productDemandForecastReducer } from './productDemandForecast'
import { rutterReducer } from './rutterApi'
import { plaidReducer } from './plaid'
import { adminDashboadReducer } from './adminDashboard'
import { featuresReducer } from './features'
import { companyGoalsReducer } from './companyGoals'
import { creditScoreRequestReducer } from './credit'
import { cashflowScenariosReducer } from './cashflow'
import {
  ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY,
  accountLabelDashboadReducer,
} from './adminDashboard/credit'

import {
  PRODUCT_SEGMENT_DASHBOARD_KEY,
  productSegmentsDashboardReducer,
} from './adminDashboard/productDemand/productSegmentReducers'
import { overviewReducer } from './reports/overview'

const rootReducer = combineReducers({
  ltv: ltvReducer,
  bootstrap: bootstrapReducer,
  companies: companies,
  companyFeatures: companyFeaturesReducer,
  connectors: connectorsReducer,
  revenueForecast: revenueForecastReducer,
  productDemandForecast: productDemandForecastReducer,
  revenue: revenueReducer,
  executive: executiveReducer,
  pendingSubscriptions: pendingSubscriptionsReducer,
  activeSubscriptions: activeSubscriptionsReducer,
  roas: roasReducer,
  user: userReducer,
  newUser: newUserReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  loggedInUser: loggedInUserReducer,
  welcome: welcomeReducer,
  subscriptionConfirmationUrl: subscriptionConfirmationUrlReducer,
  subscriptionPlans: subscriptionPlansRootReducer,
  amazonSellerUri: amazonSellerUriReducer,
  rutterReducer,
  plaidReducer,
  adminDashboard: adminDashboadReducer,
  features: featuresReducer,
  companyGoals: companyGoalsReducer,
  creditScoreRequest: creditScoreRequestReducer,
  cashflowScenarios: cashflowScenariosReducer,
  overview: overviewReducer,
  [ACCOUNT_LABEL_ADMIN_DASHBOARD_KEY]: accountLabelDashboadReducer,
  [PRODUCT_SEGMENT_DASHBOARD_KEY]: productSegmentsDashboardReducer,
})

export type Store = ReturnType<typeof rootReducer>
export const useAppSelector: TypedUseSelectorHook<Store> = useSelector

export default rootReducer
