import React from 'react'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import { lighten } from 'polished'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Color } from '../../../../Color'

export interface ScreenContainerProps {
  title: string
  subtitle?: string
  updatedAt?: Date
  tooltipText?: string
}

export const ScreenContainer: React.FunctionComponent<ScreenContainerProps> = ({
  title,
  subtitle,
  updatedAt,
  tooltipText,
  children,
}) => {
  const updatedString = updatedAt
    ? `Updated ${format(updatedAt, 'MMM dd, hh:mm a')}`
    : null
  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', alignItems: 'end', marginBottom: '16px' }}>
        <Typography variant="h6">{title}</Typography>
        {tooltipText && (
          <Tooltip title={tooltipText} arrow>
            <HelpOutlineOutlinedIcon
              color="secondary"
              fontSize="small"
              sx={{ ml: 1, mb: 1, mr: 2 }}
            />
          </Tooltip>
        )}
        {/**
         * TODO: Replace <p> tag with Typography
         * There is a bug where the caption MuiTheme is not getting picked up
         * in certain pages very annoying. After killing mui core dependency and
         * upgrading to a new version of MUI / switching to emotion stylng
         */}
        <p
          style={{
            fontSize: '12px',
            color: `${Color.LightGrey}`,
            marginLeft: '8px',
            marginBottom: '4px',
          }}
        >
          {updatedString}
        </p>
      </Box>
      {subtitle && (
        <Typography variant="subtitle1" gutterBottom>
          {subtitle}
        </Typography>
      )}
      {children}
    </Container>
  )
}

export interface ChartContainerProps {
  title?: string
  subtitle?: string
  height?: string
  width?: string
  minWidth?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export const ChartContainer: React.FunctionComponent<ChartContainerProps> = ({
  title,
  subtitle,
  height = '600px',
  width,
  minWidth = '600px',
  maxWidth = 'md',
  children,
}) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        height,
        width,
        minWidth,
        margin: '0px',
        marginTop: '16px',
        padding: '32px',
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
        borderRadius: '8px',
        userSelect: 'none',
        position: 'relative',
      }}
    >
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="subtitle1" gutterBottom>
          {subtitle}
        </Typography>
      )}
      {children}
    </Container>
  )
}

export const MainFlex = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const TopBarContainer = styled('div')`
  z-index: 1;
  box-shadow: 0 0 12px 0 ${lighten(0.75, Color.Black)};
  padding: 1.05em;
  display: flex;
  // max-height: 92px;
  height: 100px;
  justify-content: space-between;
`
