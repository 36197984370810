import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { LegendCard } from '../../../../components/designSystem/molecules/LegendEntry/components/LegendCard'
import { Color } from '../../../../Color'

interface InsightCardsContent {
  title: string
  content: string
}

const LegendHeader = ({ title }: { title: string }): JSX.Element => {
  return (
    <Grid item xs={12} ml={2}>
      <Typography variant="body2" color={Color.DarkGrey}>
        {title}
      </Typography>
    </Grid>
  )
}

const LegendContent = ({ content }: { content: string }): JSX.Element => {
  return (
    <Grid item xs={12} mt={1} ml={2} height={60}>
      <Typography variant="subtitle2">{content}</Typography>
    </Grid>
  )
}

export const InsightCards = ({
  insightsContent,
}: {
  insightsContent: InsightCardsContent[]
}): JSX.Element => {
  return (
    <Grid container mt={3} mb={4} spacing={2}>
      {insightsContent.map((item, idx) => (
        <Grid item lg={3} xs={12} key={idx}>
          <LegendCard
            titleComponent={<LegendHeader title={item.title} />}
            contentComponent={<LegendContent content={item.content} />}
          />
        </Grid>
      ))}
    </Grid>
  )
}
