import * as React from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Color } from '../../../../Color'

const ProgressLabel = styled('p')`
  color: ${Color.DarkCharcoal};
  font-size: 16px;
  margin: 0px;
`

export const CircularProgressWithLabel: React.FunctionComponent<
  CircularProgressProps & { value: number }
> = props => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#f1f1f1',
        }}
        size={55}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'absolute',
        }}
        size={55}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ProgressLabel>{`${Math.round(props.value)}%`}</ProgressLabel>
      </Box>
    </Box>
  )
}
