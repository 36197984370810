import { styled } from '@mui/material/styles'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Color } from '../../../../Color'

export const Control = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
`

export const ControlIcon = styled(ArrowDropDownIcon, {
  shouldForwardProp: (prop: any): boolean => prop !== 'isActive',
})<{ isActive: boolean }>`
  color: ${Color.LightGrey};
  transform: ${({ isActive }) =>
    isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.25s ease-in-out;
`
