import React from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

type Props = {
  value: string
  onClick: () => void
}

export const TransactionsTableEditableCell = ({
  value,
  onClick,
}: Props): JSX.Element => (
  <ButtonBase
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
    onClick={onClick}
  >
    <Typography variant="body2">{value}</Typography>
    <ArrowDropDownIcon />
  </ButtonBase>
)
