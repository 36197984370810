import React from 'react'

import Box from '@mui/material/Box'

import { ChartLegendMetric } from '../../../../../components/designSystem/molecules/ChartLegendMetric/ChartLegendMetric'

import { Color } from '../../../../../Color'

type Props = {
  balance: string
  netInflow: string
  netOutflow: string
}

export const CashPositionTopLineMetrics = ({
  balance,
  netInflow,
  netOutflow,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      gap: '10px',
    }}
  >
    <ChartLegendMetric
      legendColor={Color.LightBlue}
      legendShape="circle"
      metricValue={balance}
      title="Ending Balance"
      tooltip=""
      sx={{ flex: 1 }}
    />
    <ChartLegendMetric
      legendColor={Color.Pear}
      legendShape="square"
      metricValue={netInflow}
      title="Cumulative Cash Inflows"
      tooltip=""
      sx={{ flex: 1 }}
    />
    <ChartLegendMetric
      legendColor={Color.Peach}
      legendShape="square"
      metricValue={netOutflow}
      title="Cumulative Cash Outflows"
      tooltip=""
      sx={{ flex: 1 }}
    />
  </Box>
)
