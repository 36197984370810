import { useState, useEffect } from 'react'
import {
  useCreditScore,
  ScoreStatus,
} from '../../../../../hooks/useCreditScore'
import { useRatingCreditScore } from '../../../../../hooks/useRatingCreditScore'
import { OverviewCreditScoreCardProps } from './OverviewCreditScoreCard.types'

export const useOverviewCreditScoreCard = ({
  accountConnections,
}: OverviewCreditScoreCardProps) => {
  const { creditScore, creditScoreRequest } = useCreditScore()
  const { rating, ratingDataSource } = useRatingCreditScore(
    creditScore.creditScore ?? 0,
  )
  const [status, setStatus] = useState<ScoreStatus | undefined>(
    creditScore.scoreStatus,
  )

  useEffect(() => {
    setStatus(creditScore.scoreStatus)
  }, [creditScore])

  const hasInitialSyncCompleted = accountConnections.some(
    ({ initialSyncStatus }) => initialSyncStatus === 'complete',
  )

  return {
    creditScoreStatus: status,
    creditScoreRating: rating,
    creditScoreRatingScale: ratingDataSource,
    creditScore: creditScore.creditScore,
    creditScoreFactors: {
      embedded: creditScore.embedded,
      liquidity: creditScore.liquidity,
      ability: creditScore.ability,
    },
    creditScoreRequest,
    isEmpty: !hasInitialSyncCompleted,
  }
}
