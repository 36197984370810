import React from 'react'
import Box from '@mui/material/Box'

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipFormatter,
  XAxis,
  YAxis,
} from 'recharts'
import { Color } from '../../../../../Color'

type Props = {
  title: string
  tickFormatter?: (value: number) => any
  tooltipFormatter?: TooltipFormatter
  chartData: Record<string, any>[]
  lines: { name: string; color: Color }[]
  showLegend?: boolean
  xAxisTicks?: string[]
  showTooltip?: boolean
  backgroundColor?: string
  showTicks?: boolean
  strokeWidth?: number
  hideAxes?: boolean
}

export const LineGraph = ({
  title,
  tickFormatter,
  tooltipFormatter,
  chartData,
  lines,
  xAxisTicks,
  backgroundColor = 'white',
  showTicks = true,
  strokeWidth = 2,
  hideAxes = false,
}: Props): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', height: '190px' }}>
      <ResponsiveContainer width="99%">
        <LineChart
          data={chartData}
          margin={{ top: 12, right: 30, bottom: 0, left: 0 }}
        >
          {tooltipFormatter && <Tooltip formatter={tooltipFormatter} />}

          <CartesianGrid
            fill={backgroundColor}
            vertical={false}
            horizontal={false}
          />

          {!hideAxes && (
            <XAxis
              dataKey="date"
              tick={
                showTicks && {
                  textAnchor: 'middle',
                  fontSize: 12,
                  fill: Color.LightGrey,
                }
              }
              ticks={xAxisTicks}
              tickMargin={10}
              stroke={Color.LightGrey}
            />
          )}

          {!hideAxes && (
            <YAxis
              tickFormatter={tickFormatter}
              tick={showTicks && { fontSize: 12, fill: Color.LightGrey }}
              tickSize={0}
              tickMargin={10}
              stroke={Color.LightGrey}
            />
          )}

          {lines.length &&
            lines.map(({ name, color }: any) => (
              <Line
                key={`${title}-chartline-${name}`}
                dataKey={name}
                stroke={color}
                type="linear"
                dot={false}
                strokeWidth={strokeWidth}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}
