import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Store } from '../../modules/rootReducer'
import { Layout } from '../../layout'
import { Color } from '../../Color'
import { styled } from '@mui/material/styles'
import { PricingCard } from './PricingCard'
import { PricingCardContent } from './PricingCardContent'
import { SubscriptionPlanPayload } from '../../modules/subscriptionPlans'
import { getSubscriptionPlans } from '../../modules/subscriptionPlans/subscriptionPlans'
import { getActiveSubscription } from '../../modules/subscriptions/subscriptions'
import { getConnectors } from '../../modules/connectors'
import { selectConnectors } from '../../modules/connectors'
import { Connection } from '../../modules/connectors/types'
import { KNOWN_CONNECTOR_KEYS } from '../../../server/connectors/known-connectors'
import { SubscriptionPayload } from '../../modules/subscriptions'
import { useDispatchApiCall } from '../../hooks/useDispatchApiCall'
import { SubscriptionConfirmationUrlPayload } from '../../modules/subscriptions'
import { createPendingSubscription } from '../../modules/subscriptions/subscriptions'
import { dispatch } from '../../store'
import { SpeedBumpModal } from '../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { OutIcon } from '../../components/designSystem/assets/OutIcon/OutIcon'
import Button from '@mui/material/Button'
import { StandardSubscriptionPlanNames } from '../../../server/data/models/types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export const FlexCenter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`

export const PlanButton = styled(Button, {
  shouldForwardProp: (prop: any): boolean => prop !== 'width',
})<{ width?: number }>(
  ({ width }) => `
  box-sizing: border-box;
  height: 45px;
  width: ${width ? `${width}px` : '100%'};
  border: 1px solid #5a8f18;
  border-radius: 4px;
  background-color: #ffffff;
  color: #5a8f18;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin: 30px 0px;
  cursor: pointer;
  padding: 8px 22px;

  &:hover {
    background-color: ${Color.VioletEggplant};
    color: #ffffff;
  }
`,
)

export const LENDING_PLAN_PLACEHOLDER = {
  id: '4',
  name: 'Lending',
  feeCents: -1,
  features: [
    'Everything in Cash Management plus:',
    '1:1 Onboarding',
    'Dedicated Financial Advisory',
    'Capital Advising',
    'Unlimited Users',
    'Unlimited Integrations',
  ],
}

const SETTINGS_PAGE_REDIRECT = '/users/account'

interface Props {
  subscriptionConfirmationUrl: SubscriptionConfirmationUrlPayload
  subscriptionPlans: SubscriptionPlanPayload
  activeSubscriptions: SubscriptionPayload
  connections: Connection[]
}

interface FormattedSubscriptionPlan {
  id: string
  name: string
  feeCents: number
  features: string[]
}

export const PricingScreenImpl = (props: Props): JSX.Element => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)
  const {
    subscriptionConfirmationUrl,
    subscriptionPlans,
    activeSubscriptions,
    connections,
  } = props

  useDispatchApiCall([
    getSubscriptionPlans,
    getActiveSubscription,
    getConnectors,
  ])

  // if there's a non-null confirmationUrl then a subscription was created
  if (subscriptionConfirmationUrl?.confirmationUrl !== null) {
    // navigate the user to Shopify's confirmation page
    // or back to the settings page if a free plan was selected
    window.location.href =
      subscriptionConfirmationUrl?.confirmationUrl || SETTINGS_PAGE_REDIRECT
  }

  // display the loading indicator if the confirmationUrl is loading - waiting for redirect
  const isLoading = !!subscriptionConfirmationUrl?.isLoading

  const subscriptionPlansFormatted: FormattedSubscriptionPlan[] = subscriptionPlans?.subscriptionPlans
    ? subscriptionPlans?.subscriptionPlans?.map(subscriptionPlan => ({
        id: subscriptionPlan.id,
        name: subscriptionPlan.name,
        feeCents: subscriptionPlan.feeCents,
        features: subscriptionPlan.features,
      }))
    : []

  // add Lending if we have subscriptionPlans data
  if (subscriptionPlansFormatted.length) {
    subscriptionPlansFormatted.push(LENDING_PLAN_PLACEHOLDER)
  }

  // Note: Since the id for Lending is a placeholder it will never match the activeSubscriptionId
  // even if the company has a custom Lending subscription
  const activeSubscriptionId =
    activeSubscriptions?.subscription?.subscriptionPlanId

  // activePlanSelection will never be set to Lending
  // because Lending will never be assigned selectedPlanId
  const activePlanSelection = subscriptionPlansFormatted.find(
    ({ id }) => id === selectedPlanId,
  )

  const hasShopifyConnection = connections.some(
    ({ key }) => key === KNOWN_CONNECTOR_KEYS.shopify,
  )

  // navigate to the settings page if the user selects their active subscription plan
  // navigate to the request a call link if the button text is 'Schedule a call'
  // otherwise create a subscription for the selected subscription plan
  const handlePlanSelectionClick = (id: string, name: string): void => {
    if (id === activeSubscriptionId) {
      window.location.href = SETTINGS_PAGE_REDIRECT
    } else if (
      id === LENDING_PLAN_PLACEHOLDER.id ||
      (!hasShopifyConnection && name !== StandardSubscriptionPlanNames.free)
    ) {
      window.location.href = 'https://brightflow.ai/request-a-call/'
    } else {
      setSelectedPlanId(id)
      dispatch(createPendingSubscription(id))
    }
  }

  return (
    <Layout showSidebar={false} shouldCenter={false}>
      {isLoading &&
        activePlanSelection?.name !== StandardSubscriptionPlanNames.free && (
          <SpeedBumpModal
            title={'Redirecting to Shopify'}
            text={`You're being redirected to Shopify to confirm plan and billing details.`}
          >
            <OutIcon height={27} width={27} />
          </SpeedBumpModal>
        )}
      {subscriptionPlansFormatted?.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" sx={{ margin: '20px' }}>
            Select a pricing plan
          </Typography>
          <Grid
            container
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Grid
              container
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {subscriptionPlansFormatted.map(subscriptionPlan => (
                <Grid item key={subscriptionPlan.id}>
                  <PricingCard key={subscriptionPlan.id}>
                    <PricingCardContent
                      {...subscriptionPlan}
                      hasShopifyConnection={hasShopifyConnection}
                      isActiveSubscription={
                        subscriptionPlan.id === activeSubscriptionId
                      }
                      handlePlanSelectionClick={handlePlanSelectionClick}
                    />
                  </PricingCard>
                </Grid>
              ))}
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <Typography variant="caption">
                All charges are billed in USD. Recurring and usage-based charges
                are billed every 30 days.
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="caption"
            sx={{ fontWeight: 'bold', marginTop: '40px' }}
          >
            Selecting a plan above will take you to Shopify billing. If you do
            not have an account on Shopify, please contact us at
            sales@brightflow.ai
          </Typography>
        </Box>
      )}
    </Layout>
  )
}

const mapStateToProps = ({
  subscriptionConfirmationUrl: { subscriptionConfirmationUrl },
  subscriptionPlans: { subscriptionPlans },
  activeSubscriptions: { activeSubscriptions },
  connectors,
}: Store): Props => ({
  subscriptionConfirmationUrl,
  subscriptionPlans,
  activeSubscriptions,
  connections: selectConnectors(connectors),
})

export const PricingScreen = connect(mapStateToProps)(
  React.memo(PricingScreenImpl),
)
