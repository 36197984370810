import { TimePeriodFilterOption } from './TimePeriodFilter.types'

export const getInitialFilterOption = <K extends string | number>(
  options: TimePeriodFilterOption<K>[],
  initialGranularity?: K,
): TimePeriodFilterOption<K> => {
  if (initialGranularity) {
    const matchingOption = options.find(
      option => option.granularity === initialGranularity,
    )
    return matchingOption || options[0]
  } else {
    const defaultOption = options.find(option => option.isDefaultGranularity)
    return defaultOption || options[0]
  }
}

export const getDurationOptions = (
  minDuration = 1,
  maxDuration: number,
): number[] => {
  return Array.from(
    { length: maxDuration - (minDuration - 1) },
    (_, i) => i + minDuration,
  )
}
