import { styled } from '@mui/material/styles'

import TextField from '@mui/material/TextField'

import { Color } from '../../../../Color'

export const StyledTextField = styled(TextField)`
  width: 205px;
  height: 50px;
  border-radius: 5px 5px 0px 0px;

  & > .MuiInputBase-root {
    background-color: ${Color.White};

    & > .MuiSelect-select:focus {
      background-color: ${Color.White};
    }
  }
`

StyledTextField.defaultProps = {
  select: true,
  variant: 'filled',
}
