import { endOfMonth, format, previousSunday, startOfMonth, sub } from 'date-fns'

import { TimePeriods } from './Overview.types'
import { DateGranularity } from '../../../../server/app-data-service/generatedTypes'

const DATE_FORMAT = 'MM/dd/yy'

type DateRange = {
  startDate?: string
  endDate: string
}

const dateRangeHandlers = {
  [TimePeriods.yesterday]: (date: Date): DateRange => ({
    endDate: format(sub(date, { days: 1 }), DATE_FORMAT),
  }),
  [TimePeriods.lastWeek]: (date: Date): DateRange => ({
    startDate: format(sub(previousSunday(date), { days: 6 }), DATE_FORMAT),
    endDate: format(previousSunday(date), DATE_FORMAT),
  }),
  [TimePeriods.lastMonth]: (date: Date): DateRange => ({
    startDate: format(startOfMonth(sub(date, { months: 1 })), DATE_FORMAT),
    endDate: format(endOfMonth(sub(date, { months: 1 })), DATE_FORMAT),
  }),
}

export const getDateRangeByTimePeriod = (
  timePeriod: TimePeriods,
  date = new Date(),
): string => {
  const { startDate, endDate } = dateRangeHandlers[timePeriod](date)

  return startDate ? `${startDate} - ${endDate}` : `${endDate}`
}

export const dateRangeToGranularityMap: {
  [key in TimePeriods]: DateGranularity
} = {
  [TimePeriods.yesterday]: 'Day',
  [TimePeriods.lastWeek]: 'FullWeek',
  [TimePeriods.lastMonth]: 'FullMonth',
}
