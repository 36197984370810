import React, { useState } from 'react'
import { TextField } from '@shopify/polaris'
import Box from '@mui/material/Box'
import { BaseModal } from './BaseModal'
import { ProviderModalProps } from '../Providers'

const TextFieldWrapStyles = {
  marginTop: '5px',
  '&:first-child': {
    overflow: 'hidden',
  },
}

const ModalBodyStyles = {
  padding: '1.925rem 2.19rem',
  width: '100%',
  height: '10rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}

export const ShopifyModal: React.FunctionComponent<ProviderModalProps> = props => {
  const [formValues, setFormValues] = useState<{ storeUrl: string }>({
    storeUrl: '',
  })

  // remove http(s):// and/or www. from the beginning of the URL
  // in addition to any trailing slash to avoid Shopify API errors
  const cleanedUrl = formValues.storeUrl
    .replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .replace(/\/$/, '')

  const onSubmit = (): void => {
    const { pathname } = document.location
    const redirect = encodeURIComponent(pathname)
    const redirectTo = `/connect/shopify/auth?redirect=${redirect}&shop=${
      cleanedUrl || ''
    }`

    document.location.href = redirectTo
  }

  const disableSubmit =
    formValues.storeUrl === undefined ||
    !/.*.myshopify.com[/]?$/.test(formValues.storeUrl)

  return (
    <BaseModal
      title="Enter Shopify store URL"
      onSubmit={onSubmit}
      disableSubmit={disableSubmit}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Box sx={ModalBodyStyles}>
        <Box sx={TextFieldWrapStyles}>
          <TextField
            label=""
            placeholder="your-shop-url.myshopify.com"
            value={formValues.storeUrl}
            onChange={(value: string): void => {
              setFormValues({
                storeUrl: value,
              })
            }}
          />
        </Box>
      </Box>
    </BaseModal>
  )
}
