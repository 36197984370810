import Avatar from '@mui/material/Avatar'
import React from 'react'

export const SquareBullet = ({ color }: { color: string }): JSX.Element => {
  return (
    <>
      <Avatar
        sx={{
          width: '6px',
          height: '6px',
          backgroundColor: color,
          margin: '2px;',
          marginTop: '6px;',
        }}
        variant="square"
      >
        &nbsp;
      </Avatar>
    </>
  )
}
