import React from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import Box from '@mui/material/Box'
import {
  format,
  parseISO,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
} from 'date-fns'
import { Money } from '../../../../../../../util/money'
import { Color } from '../../../../../../Color'
import { SalesForecastChartRow } from '../../SalesForecast.types'
import { formatDateString } from '../../SalesForecast.utils'
import { DateGranularity } from '../../../../../../../server/app-data-service/generatedTypes'

interface Props {
  rows: SalesForecastChartRow[]
  granularity: DateGranularity
}

export const SalesForecastChart: React.FunctionComponent<Props> = ({
  rows,
  granularity,
}) => {
  const today =
    granularity === 'Week'
      ? format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'PP')
      : format(startOfMonth(new Date()), 'PP')

  const mapDs = ({ ds }: SalesForecastChartRow) => ds

  // helper component needed to create
  // stacked Month / Year format for X-Axis
  const CustomizedAxisTick = ({ x, y, payload }: any): JSX.Element => {
    return (
      <g transform={`translate(${x || 0},${y})`}>
        <text x={0} y={0} dy={16} fill={Color.LighterGrey}>
          <tspan textAnchor="middle" x="0">
            {formatDateString(payload.value, granularity)}
          </tspan>
        </text>
      </g>
    )
  }

  // maps input column labels
  // to human readable format
  const labelMap: { [key: string]: string } = {
    y: 'Actual Sales',
    trend: 'Trend',
    yhat: 'Forecast',
  }

  const formatTooltipLabel = (date: string | number): string =>
    granularity === 'Week'
      ? `${format(parseISO(new Date(date).toISOString()), 'M/dd')}-${format(
          parseISO(
            endOfWeek(new Date(date), { weekStartsOn: 1 }).toISOString(),
          ),
          'M/dd',
        )}`
      : `${format(parseISO(new Date(date).toISOString()), 'M/yy')}`

  return (
    <Box
      sx={{
        padding: '30px 0',
        width: '100%',
        height: '540px',
        userSelect: 'none',
        mt: 4,
      }}
    >
      <ResponsiveContainer width="100%" height="95.5%">
        <LineChart
          width={500}
          height={500}
          data={rows}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          {...{ overflow: 'visible' }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            interval={12}
            height={60}
            stroke={Color.LighterGrey}
            dataKey={'ds'}
            ticks={rows.map(mapDs)}
            tick={<CustomizedAxisTick />}
          />

          <YAxis
            stroke={Color.LighterGrey}
            tickFormatter={(v): string =>
              v ? new Money(v * 100).humanize() : ''
            }
            type="number"
            domain={[0, 'auto']}
            allowDataOverflow={true}
            tickLine={true}
          />

          <Tooltip
            filterNull={false}
            formatter={(value, category): [string, string] => {
              if (value === null && category === 'y') {
                return ['N/A', labelMap[category]]
              }
              return [
                new Money(((value as any) * 100) as number).toString(),
                labelMap[category],
              ]
            }}
            labelFormatter={date => formatTooltipLabel(date)}
          />
          <Legend
            verticalAlign={'top'}
            height={60}
            iconSize={10}
            formatter={value => {
              return labelMap[value]
            }}
          />
          <ReferenceLine
            x={today}
            stroke={Color.Green}
            strokeWidth={2}
            label={{
              // position here is throwing a TS error for some reason, ignoring
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              position: 'top',
              value: 'CURRENT',
              fill: Color.Green,
              fontSize: 14,
            }}
          />
          <Line
            legendType="plainline"
            strokeWidth={4}
            type="monotone"
            dataKey="yhat"
            stroke={Color.LightBlue}
            dot={{ stroke: 'none', fill: 'none' }}
          />
          <Line
            legendType="circle"
            type="monotone"
            dataKey="y"
            stroke="#000000"
            strokeWidth={0}
            dot={{ stroke: '#000000', fill: '#000000', r: 2 }}
            isAnimationActive={false}
          />
          <Line
            legendType="plainline"
            type="monotone"
            dataKey="trend"
            stroke={Color.Pink}
            activeDot={{ r: 8 }}
            dot={{ stroke: 'none', fill: 'none' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}
