import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const addUser = createAsyncThunk(
  'users/add',
  async (payload: {
    firstName: string
    lastName: string
    companyName: string
    companyUrl: string
    password: string
    email: string
  }) => api.post('/users/add', { ...payload }),
)

export const loginUser = createAsyncThunk(
  'users/login',
  async (payload: { password: string; email: string }) =>
    api.post('/users/login', { ...payload }),
)

export const forgotPasswordLink = createAsyncThunk(
  'users/forgot-password',
  async (email: string, thunkAPI) => {
    try {
      return api.post('/users/forgot-password', { email })
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)

export const resetPasswordLinkValidation = createAsyncThunk(
  'users/reset-password',
  async (token: string, thunkAPI) => {
    try {
      return api.post('/users/reset-password', { token })
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)

export const updatePassword = createAsyncThunk(
  'users/update-password',
  async (payload: { password: string; userId: string }, thunkAPI) => {
    try {
      return api.put('/users/reset-password', { ...payload })
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.errorMessage)
    }
  },
)
