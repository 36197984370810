import React, { useCallback, useMemo, useState } from 'react'
import {
  GridColumns,
  GridEditSingleSelectCell,
  GridRowId,
  GridRowModel,
  GridToolbar,
  GridValidRowModel,
  GridValueGetterParams,
  GridValueOptionsParams,
  ValueOptions,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'

import { EditSaveButton } from '../../../components/designSystem/atoms/EditSaveButton/EditSaveButton'
import { format, parseISO } from 'date-fns'
import Button from '@mui/material/Button'
import { dispatch } from '../../../store'
import {
  bulkUpdateActionFields,
  bulkUpdateBfCategoryLabels,
  indexLabel,
  updateBfCategoryLabels,
} from '../../../modules/adminDashboard/credit/accountLabellingDashboard'
import { AccountingLabelMappingData } from '../../../../server/services/creditDashboard/accountLabelling/AccountingLabelMappingService'
import {
  BfCategoryLabels,
  BfSubCategoryLabels,
} from '../../../../server/data/models/types'
import { PageLoading } from '../../../components/designSystem/atoms/Loading/Loading'

import { AdminDataGrid } from '../AdminDataGrid'

interface Props {
  accountLabels?: AccountingLabelMappingData[] | null
}

interface DashboardRow {
  companyName?: string
  connectionSource?: string
  accountName: string
  bfCategoryLabel?: string
  bfSubCategoryLabel?: string
  updatedAt: string
  reviewed: string
  indexed: string
  validated: string
}

const mapDateField = (date: Date): string =>
  format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd h:m a')

const getRows = (
  accountLabels?: AccountingLabelMappingData[] | null,
): DashboardRow[] => {
  if (!accountLabels) return []
  return accountLabels.map((mapping, i) => ({
    id: mapping?.id,
    company: mapping?.connection?.company,
    connection: mapping?.connection,
    accountName: mapping.accountName,
    bfCategoryLabel: mapping.bfCategoryLabel ?? '',
    bfSubCategoryLabel: mapping.bfSubCategoryLabel ?? '',
    bfCategoryPredictedLabel:
      mapping?.autoCategorization?.bfCategoryAutoCategorizedLabel ?? '',
    bfSubCategoryPredictedLabel:
      mapping?.autoCategorization?.bfSubCategoryAutoCategorizedLabel ?? '',
    predictionScore: mapping?.autoCategorization?.score ?? '',
    updatedAt: mapDateField(mapping.updatedAt),
    reviewed: mapping.reviewed ? mapDateField(mapping.reviewed) : '',
    indexed: mapping.indexed ? mapDateField(mapping.indexed) : '',
    validated: mapping.validated ? mapDateField(mapping.validated) : '',
  }))
}

const MAIN_CATEGORY_OPTIONS = [
  '',
  BfCategoryLabels.revenue,
  BfCategoryLabels.cogs,
  BfCategoryLabels.totalExpenses,
  BfCategoryLabels.miscelaneous,
  BfCategoryLabels.totalAssets,
  BfCategoryLabels.totalLiabilities,
  BfCategoryLabels.totalEquity,
  BfCategoryLabels.noCategory,
]

const categoryToSubCategoryOptions: Record<string, string[]> = {
  [BfCategoryLabels.revenue]: [
    BfSubCategoryLabels.shopify,
    BfSubCategoryLabels.amazon,
    BfSubCategoryLabels.wholesale,
    BfSubCategoryLabels.retail,
    BfSubCategoryLabels.shippingIncome,
    BfSubCategoryLabels.otherRevenue,
    BfSubCategoryLabels.refunds,
    BfSubCategoryLabels.discounts,
    BfSubCategoryLabels.otherDiscounts,
  ],
  [BfCategoryLabels.cogs]: [BfSubCategoryLabels.cogs],
  [BfCategoryLabels.totalExpenses]: [
    BfSubCategoryLabels.marketing,
    BfSubCategoryLabels.googleAdSpend,
    BfSubCategoryLabels.facebookAdSpend,
    BfSubCategoryLabels.amazonAdSpend,
    BfSubCategoryLabels.otherAdSpend,
    BfSubCategoryLabels.payroll,
    BfSubCategoryLabels.otherVariableCosts,
    BfSubCategoryLabels.otherFixedCosts,
  ],
  [BfCategoryLabels.miscelaneous]: [
    BfSubCategoryLabels.miscelaneous,
    BfSubCategoryLabels.taxes,
    BfSubCategoryLabels.depreciationAndAmortization,
  ],
  [BfCategoryLabels.totalAssets]: [
    BfSubCategoryLabels.cash,
    BfSubCategoryLabels.accountsReceivable,
    BfSubCategoryLabels.inventory,
    BfSubCategoryLabels.ppe,
    BfSubCategoryLabels.otherCurrentAssets,
    BfSubCategoryLabels.otherAssets,
  ],
  [BfCategoryLabels.totalLiabilities]: [
    BfSubCategoryLabels.accountsPayable,
    BfSubCategoryLabels.creditCards,
    BfSubCategoryLabels.otherCurrentLiabilities,
    BfSubCategoryLabels.shortTermBorrowing,
    BfSubCategoryLabels.longTermBorrowing,
    BfSubCategoryLabels.brightflowLoan,
    BfSubCategoryLabels.convertibleLoan,
    BfSubCategoryLabels.otherLongTermLiabilities,
  ],
  [BfCategoryLabels.totalEquity]: [
    BfSubCategoryLabels.equity,
    BfSubCategoryLabels.retainedEarnings,
    BfSubCategoryLabels.other,
  ],
}

const getSubCategoryValueOptions = (
  params: GridValueOptionsParams<any>,
): ValueOptions[] => {
  const {
    row: { bfCategoryLabel },
  } = params
  if (bfCategoryLabel in categoryToSubCategoryOptions) {
    return categoryToSubCategoryOptions[bfCategoryLabel as string]
  }
  return ['']
}

const selectGetSubCategoryValues = (mainCategory: string): string[] => {
  if (mainCategory in categoryToSubCategoryOptions) {
    return categoryToSubCategoryOptions[mainCategory]
  }
  return []
}

const EditCategoryComponent = (props: any): JSX.Element => {
  const { field, id, ...rest } = props

  const apiRef = useGridApiContext()

  // When we change the bfCategory, we want to reset the sub category
  // because they are linked
  // https://mui.com/x/react-data-grid/recipes-editing/#linked-fields
  const handleValueChange = async (): Promise<void> => {
    await apiRef.current.setEditCellValue({
      id: id,
      field: 'bfSubCategoryLabel',
      value: '',
    })
  }

  return (
    <GridEditSingleSelectCell
      onValueChange={handleValueChange}
      field={field}
      id={id}
      {...rest}
    />
  )
}

const cols: GridColumns = [
  {
    field: 'companyId',
    headerName: 'Company id',
    width: 100,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.company?.id,
  },
  {
    field: 'companyName',
    headerName: 'Company',
    width: 150,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.company?.name,
  },
  {
    field: 'connectionSource',
    headerName: 'Connection',
    width: 150,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.connection?.key,
  },
  {
    field: 'accountName',
    headerName: 'Customer Account',
    width: 300,
  },
  {
    field: 'bfCategoryLabel',
    headerName: 'Brightflow Category',
    width: 250,
    type: 'singleSelect',
    valueOptions: MAIN_CATEGORY_OPTIONS,
    editable: true,
    renderEditCell: (params: any): JSX.Element => (
      <EditCategoryComponent {...params} />
    ),
  },
  {
    field: 'bfSubCategoryLabel',
    headerName: 'Brightflow Sub Category',
    width: 250,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSubCategoryValueOptions,
  },
  {
    field: 'bfCategoryPredictedLabel',
    headerName: 'Brightflow Predicted Category',
    width: 250,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.bfCategoryPredictedLabel,
  },
  {
    field: 'bfSubCategoryPredictedLabel',
    headerName: 'Brightflow Predicted Sub Category',
    width: 250,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.bfSubCategoryPredictedLabel,
  },
  {
    field: 'predictionScore',
    headerName: 'Prediction Score',
    width: 250,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.predictionScore,
  },
  {
    field: 'reviewed',
    headerName: 'Review',
    width: 250,
    renderCell: (params: any): JSX.Element => (
      <Button
        disabled={!params?.row?.bfCategoryLabel}
        color={params?.row?.reviewed ? 'secondary' : 'primary'}
        variant="contained"
        size="small"
        onClick={(): void => {
          dispatch(
            updateBfCategoryLabels({
              bfCategoryLabel: params?.row?.bfCategoryLabel,
              bfSubCategoryLabel: params?.row?.bfSubCategoryLabel,
              connectionId: params?.row?.connection.id,
              accountName: params?.row?.accountName,
              reviewed: Boolean(!params?.row?.reviewed),
            }),
          )
        }}
      >
        Mark as {params?.row?.reviewed ? 'Non Reviewed' : 'Reviewed'}
      </Button>
    ),
  },
  {
    field: 'validated',
    headerName: 'Validate',
    width: 250,
    renderCell: (params: any): JSX.Element => (
      <Button
        disabled={!params?.row?.bfCategoryLabel}
        color={params?.row?.validated ? 'secondary' : 'primary'}
        variant="contained"
        size="small"
        onClick={(): void => {
          dispatch(
            updateBfCategoryLabels({
              bfCategoryLabel: params?.row?.bfCategoryLabel,
              bfSubCategoryLabel: params?.row?.bfSubCategoryLabel,
              connectionId: params?.row?.connection.id,
              accountName: params?.row?.accountName,
              validated: Boolean(!params?.row?.validated),
            }),
          )
        }}
      >
        Mark as {params?.row?.validated ? 'Non Validated' : 'Validated'}
      </Button>
    ),
  },
  {
    field: 'indexed',
    headerName: 'Index',
    width: 250,
    renderCell: (params: any): JSX.Element => (
      <Button
        color={params?.row?.indexed ? 'secondary' : 'primary'}
        variant="contained"
        size="small"
        disabled={
          !params?.row?.validated ||
          !params?.row?.reviewed ||
          !params?.row?.bfCategoryLabel
        }
        onClick={(): void => {
          dispatch(
            indexLabel({
              id: params?.row?.id,
              connectionId: params?.row?.connection.id,
              accountName: params?.row?.accountName,
              bfCategory: params?.row?.bfCategoryLabel,
              bfSubCategory: params?.row?.bfSubCategoryLabel,
            }),
          )
        }}
      >
        {params?.row?.indexed ? 'Re-Index' : 'Index'}
      </Button>
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'last updated',
    width: 250,
  },
]

// Checks whether a main category has a required subcategory filled out
// and if it does, returns true
// ie Total equity cannot have a blank sub category - would return false
const requiredSubCategoryPresent = (
  bfCategoryLabel: BfCategoryLabels,
  bfSubCategoryLabel: string,
): boolean => {
  if (
    [
      BfCategoryLabels.revenue,
      BfCategoryLabels.totalExpenses,
      BfCategoryLabels.miscelaneous,
      BfCategoryLabels.totalEquity,
      BfCategoryLabels.totalLiabilities,
      BfCategoryLabels.totalAssets,
      BfCategoryLabels.cogs,
    ].includes(bfCategoryLabel)
  ) {
    return Boolean(bfSubCategoryLabel)
  }
  return true
}

const BulkLabelSection = ({
  selectedRows,
  setSelectedRows,
}: {
  selectedRows: Map<GridRowId, GridValidRowModel>
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Map<GridRowId, GridValidRowModel>>
  >
}): JSX.Element => {
  const [mainCategory, setMainCategory] = useState('')
  const [availableSubCategories, setAvailableSubCategories] = useState<
    Array<string>
  >([])
  const [subCategory, setSubCategory] = useState('')
  const canDoUpdate = useMemo(
    () =>
      requiredSubCategoryPresent(mainCategory as BfCategoryLabels, subCategory),
    [mainCategory, subCategory],
  )

  const [allReviewed, setAllReviewed] = useState(() =>
    Array.from(selectedRows.values()).every(row => row.reviewed),
  )

  const onSave = useCallback(() => {
    const updatedRows = new Map<GridRowId, GridValidRowModel>()
    const connectionAccountRows = []
    for (const [rowId, row] of selectedRows) {
      const updatedVal = {
        ...row,
        bfCategoryLabel: mainCategory,
        bfSubCategoryLabel: subCategory,
      }
      updatedRows.set(rowId, updatedVal)
      connectionAccountRows.push({
        connectionId: row.connection.id,
        accountName: row.accountName,
      })
    }
    setSelectedRows(updatedRows)
    dispatch(
      bulkUpdateBfCategoryLabels({
        connectionAccountObs: connectionAccountRows,
        bfCategoryLabel: mainCategory,
        bfSubCategoryLabel: subCategory,
      }),
    )
  }, [mainCategory, selectedRows, subCategory, setSelectedRows])

  const canReview = useMemo(
    () =>
      Array.from(selectedRows.values()).every(row => {
        return (
          row.bfCategoryLabel &&
          requiredSubCategoryPresent(
            row.bfCategoryLabel,
            row.bfSubCategoryLabel,
          )
        )
      }),
    [selectedRows],
  )

  return (
    <div style={{ margin: '25px' }}>
      <div>{`Apply labels to ${selectedRows.size} selected accounts`}</div>
      <div style={{ display: 'flex' }}>
        <FormControl required sx={{ m: 1, width: 200 }}>
          <p>Brightflow Category</p>
          <Select
            labelId="main category"
            value={mainCategory}
            onChange={(event: SelectChangeEvent): void => {
              const mainCategory = event.target.value
              setMainCategory(mainCategory)

              const subOptions = selectGetSubCategoryValues(mainCategory)
              setAvailableSubCategories(subOptions)
              if (!subOptions.length) setSubCategory('')
            }}
          >
            {MAIN_CATEGORY_OPTIONS.map((category, i) => (
              <MenuItem key={i} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {availableSubCategories.length ? (
          <FormControl required sx={{ marginTop: 1, width: 200 }}>
            <p>Brightflow Sub Category</p>
            <Select
              labelId="sub category"
              value={subCategory}
              onChange={(event: SelectChangeEvent): void => {
                const subCat = event.target.value
                setSubCategory(subCat)
              }}
            >
              {availableSubCategories.map((category, i) => (
                <MenuItem key={i} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {canDoUpdate ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginLeft: '16px',
              paddingBottom: '8px',
            }}
          >
            <EditSaveButton isEditing={true} onEditSave={onSave} />
          </div>
        ) : null}
      </div>
      <div>{`Mark ${selectedRows.size} selected accounts as ${
        allReviewed ? 'Non Reviewed' : 'Reviewed'
      }`}</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginLeft: '8px',
          marginTop: '8px',
          paddingBottom: '8px',
        }}
      >
        <Button
          disabled={!canReview}
          color={allReviewed ? 'secondary' : 'primary'}
          variant="contained"
          size="medium"
          onClick={(): void => {
            const ids = Array.from(selectedRows.values()).map(row => row.id)
            const updatedRows = new Map<GridRowId, GridValidRowModel>(
              Array.from(selectedRows.entries()).map(([rowId, row]) => {
                const updatedVal = {
                  ...row,
                  reviewed: !allReviewed,
                }
                return [rowId, updatedVal]
              }),
            )
            setSelectedRows(updatedRows)
            dispatch(
              bulkUpdateActionFields({
                ids,
                reviewed: !allReviewed,
              }),
            )
            setAllReviewed(!allReviewed)
          }}
        >
          Mark as {allReviewed ? 'non reviewed' : 'reviewed'}
        </Button>
      </div>
    </div>
  )
}

export const AccountLabellingGrid = ({
  accountLabels,
}: Props): JSX.Element | null => {
  const apiRef = useGridApiRef()

  const formattedRows = useMemo(() => getRows(accountLabels), [accountLabels])

  const [selectedRows, setSelectedRows] = useState<
    Map<GridRowId, GridValidRowModel>
  >(new Map())

  const processRowUpdate = useCallback((newRow: GridRowModel): any => {
    const updatedRow = { ...newRow, isNew: false }
    const {
      connection,
      accountName,
      bfCategoryLabel,
      bfSubCategoryLabel,
    } = newRow

    const shouldDoUpdate = requiredSubCategoryPresent(
      bfCategoryLabel,
      bfSubCategoryLabel,
    )
    if (shouldDoUpdate) {
      dispatch(
        updateBfCategoryLabels({
          connectionId: connection.id,
          accountName,
          bfCategoryLabel,
          bfSubCategoryLabel,
        }),
      )
    }
    return updatedRow
  }, [])

  const onSelectionModelChange = useCallback(() => {
    const selectedRows = apiRef.current.getSelectedRows()
    setSelectedRows(selectedRows)
  }, [apiRef])

  if (!accountLabels) return <PageLoading />

  return (
    <div style={{ height: '80vh', width: '95vw' }}>
      {
        // only show the bulk setter if there are more than one selected rows
        selectedRows.size ? (
          <BulkLabelSection
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ) : null
      }
      <AdminDataGrid
        components={{ Toolbar: GridToolbar }}
        rows={formattedRows}
        columns={cols}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        editMode="row"
        apiRef={apiRef}
        onSelectionModelChange={onSelectionModelChange}
      />
    </div>
  )
}
