import React from 'react'
import { IconProps } from '../../assets.types'

export const PlaidIcon: React.FunctionComponent<IconProps> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 43 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 21</title>
      <g id="v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="bank-account-connected"
          transform="translate(-370.000000, -701.000000)"
          stroke="#000000"
        >
          <g id="Group-Copy" transform="translate(332.000000, 668.000000)">
            <g id="Group-21" transform="translate(39.000000, 34.000000)">
              <g id="Group-5">
                <path
                  d="M0,36.1444 L41.261,36.1444 L0,36.1444 Z"
                  id="Stroke-1"
                ></path>
                <polygon
                  id="Stroke-3"
                  points="20.6302 0 41.2602 9.783 41.2602 14.441 0.0002 14.441 0.0002 9.783"
                ></polygon>
              </g>
              <path
                d="M10.3151,18.5672 L10.3151,32.0182 L10.3151,18.5672 Z"
                id="Stroke-6"
              ></path>
              <path
                d="M6.1891,32.0184 L6.1891,18.5674 L6.1891,32.0184 Z"
                id="Stroke-7"
              ></path>
              <polygon
                id="Stroke-8"
                points="4.126 18.5675 12.378 18.5675 12.378 14.4415 4.126 14.4415"
              ></polygon>
              <polygon
                id="Stroke-9"
                points="4.126 36.1445 12.378 36.1445 12.378 32.0185 4.126 32.0185"
              ></polygon>
              <path
                d="M22.6933,18.5672 L22.6933,32.0182 L22.6933,18.5672 Z"
                id="Stroke-10"
              ></path>
              <path
                d="M18.5672,32.0184 L18.5672,18.5674 L18.5672,32.0184 Z"
                id="Stroke-11"
              ></path>
              <polygon
                id="Stroke-12"
                points="16.504 18.5675 24.756 18.5675 24.756 14.4415 16.504 14.4415"
              ></polygon>
              <polygon
                id="Stroke-13"
                points="16.504 36.1445 24.756 36.1445 24.756 32.0185 16.504 32.0185"
              ></polygon>
              <path
                d="M35.0714,18.5672 L35.0714,32.0182 L35.0714,18.5672 Z"
                id="Stroke-14"
              ></path>
              <path
                d="M30.9454,32.0184 L30.9454,18.5674 L30.9454,32.0184 Z"
                id="Stroke-15"
              ></path>
              <polygon
                id="Stroke-16"
                points="28.882 18.5675 37.134 18.5675 37.134 14.4415 28.882 14.4415"
              ></polygon>
              <polygon
                id="Stroke-17"
                points="28.882 36.1445 37.134 36.1445 37.134 32.0185 28.882 32.0185"
              ></polygon>
              <path
                d="M9.2836,10.2648 L31.9766,10.2648 L9.2836,10.2648 Z"
                id="Stroke-18"
              ></path>
              <path
                d="M0,40.2705 L41.261,40.2705 L0,40.2705 Z"
                id="Stroke-19"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
