import React, { useState } from 'react'

import { MultiSelect } from '../../../../../components/designSystem/atoms/MultiSelect/MultiSelect'
import { HandleAccountFilterChangeFn } from '../TransactionsTable/TransactionsTable.types'

type Props = {
  handleAccountFilterChange: HandleAccountFilterChangeFn
  options: { id: string; name: string }[]
}

export const TransationsAccountSelect = ({
  handleAccountFilterChange,
  options,
}: Props): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState(options)

  const handleChangeClicked = (values: string[]): void => {
    const newSelectedOptions = options.map(option => ({
      ...option,
      isSelected: values.includes(option.id),
    }))
    handleAccountFilterChange(values)
    setSelectedOptions(newSelectedOptions)
  }

  return (
    <MultiSelect
      allowEmptyOptions={false}
      title="Account Select"
      options={selectedOptions}
      onChange={handleChangeClicked}
      sx={{
        minHeight: '38px',
        minWidth: '500px',
        padding: 0,
        '.MuiSelect-select': { padding: '5px 32px 5px 10px' },
      }}
    />
  )
}
