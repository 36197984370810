import React from 'react'

import { OverviewCardConsts } from '../../Overview.consts'

import { LineGraphPresentationCard } from '../../../../../components/designSystem/organisms/LineGraphPresentationCard/LineGraphPresentationCard'

import { Props } from './OverviewGraphCard.types'
import { cardTypeToGraphPresentationTypeMap } from './OverviewGraphCard.utils'
import { useNewOverviewCard } from './OverviewGraphCard.hooks'
import { Grid } from '@mui/material'
import { OverviewCardTypes } from '../../Overview.types'

export const OverviewGraphCard: React.FunctionComponent<Props> = props => {
  const { emptyState, isEmpty, isLoading, sx, type } = props
  const { description, title } = OverviewCardConsts[type]

  const { chartData, legendData, shouldRender } = useNewOverviewCard(props)

  if (!shouldRender) return null

  return (
    <Grid item xs={12} lg={6}>
      <LineGraphPresentationCard
        chartData={chartData}
        emptyState={emptyState}
        isEmpty={isEmpty}
        isLoading={isLoading}
        legendData={legendData}
        presentationType={cardTypeToGraphPresentationTypeMap[type]}
        shouldExcludeTotal={type === OverviewCardTypes.Banking}
        sx={{ ...sx, minHeight: '100%' }}
        title={title}
        tooltipDescription={description}
      />
    </Grid>
  )
}
