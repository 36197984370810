import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getSubscriptionPlans } from './subscriptionPlans'
import { SubscriptionPlan } from '../../../server/data/models'
import { LoadingAndError } from '../types'

type SubscriptionPlanWithFeatures = SubscriptionPlan & { features: string[] }

export type SubscriptionPlanPayload = {
  subscriptionPlans: SubscriptionPlanWithFeatures[] | null
} & LoadingAndError

// REDUCERS
type SubscriptionPlansStoreData = {
  subscriptionPlans: SubscriptionPlanPayload
}

export type SubscriptionPlansData = Readonly<SubscriptionPlansStoreData>

const SUBSCRIPTION_PLANS_KEY = 'subscriptionPlans'

const initialSubscriptionPlansState: SubscriptionPlansData = {
  [SUBSCRIPTION_PLANS_KEY]: {
    [SUBSCRIPTION_PLANS_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const subscriptionPlansRootReducer = createReducer(
  initialSubscriptionPlansState,
  builder => {
    // GET SUBSCRIPTION_PLANS
    createThunkReducer(
      builder,
      getSubscriptionPlans.typePrefix,
      SUBSCRIPTION_PLANS_KEY,
      (state, action) =>
        ({
          ...state,
          [SUBSCRIPTION_PLANS_KEY]: {
            ...state[SUBSCRIPTION_PLANS_KEY],
            isLoading: null,
            error: null,
            subscriptionPlans: action.payload.subscriptionPlans,
          },
        } as SubscriptionPlansData),
    )
  },
)
