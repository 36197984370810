import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminDashboardSubscriptionPlan } from '../../../server/services/adminDashboard'

export const getAdminDashboardData = createAsyncThunk(
  'app/customer-service/companies/get',
  async () => api.get('/customer-service/companies'),
)

export const updateCompanyPlanType = createAsyncThunk(
  'app/customer-service/company-plan-type/update',
  async ({ companyId, planType }: { companyId: string; planType: string }) =>
    api.post('/customer-service/company-plan-type/update', {
      companyId,
      planType,
    }),
)

export const updateCompanyIcpAt = createAsyncThunk(
  'app/customer-service/icp-at/update',
  async (companyId: string) =>
    api.post('/customer-service/icp-at/update', { companyId }),
)

export const createCompanyFeature = createAsyncThunk(
  'app/customer-service/company-feature/create',
  async ({ companyId, featureId }: { companyId: string; featureId: string }) =>
    api.post('/customer-service/company-feature/create', {
      companyId,
      featureId,
    }),
)

export const deleteCompanyFeature = createAsyncThunk(
  'app/customer-service/company-feature/delete',
  async ({ companyId, id }: { companyId: string; id: string }) =>
    api.post('/customer-service/company-feature/delete', {
      companyId,
      featureId: id,
    }),
)

export const loginAdminUser = createAsyncThunk(
  '/customer-service/login',
  async (login: string) => api.post('/customer-service/login', { login }),
)

export const createUser = createAsyncThunk(
  'app/customer-service/users/create',
  async ({
    companyId,
    userEmail,
    firstName,
    lastName,
  }: {
    companyId: string
    userEmail: string
    firstName: string
    lastName: string
  }) =>
    api.post('/customer-service/users/create', {
      companyId,
      userEmail,
      firstName,
      lastName,
    }),
)

export const deleteCompany = createAsyncThunk(
  'app/customer-service/company/delete',
  async ({ companyId }: { companyId: string }) =>
    api.post('/customer-service/company/delete', { companyId }),
)

export const createCompanyPortfolio = createAsyncThunk(
  'app/customer-service/company/portfolios/create',
  async ({
    companyId,
    portfolioName,
  }: {
    companyId: string
    portfolioName: string
  }) =>
    api.post('/customer-service/company/portfolios/create', {
      companyId,
      portfolioName,
    }),
)

export const deleteCompanyPortfolio = createAsyncThunk(
  'app/customer-service/company/portfolios/delete',
  async ({
    companyId,
    portfolioName,
  }: {
    companyId: string
    portfolioName: string
  }) =>
    api.delete(
      `/customer-service/company/portfolios/delete?companyId=${companyId}&portfolioName=${portfolioName}`,
    ),
)

export const createCompanySubscription = createAsyncThunk(
  'app/customer-service/subscriptions/create/brightflow',
  async ({
    companyId,
    subscriptionPlanId,
  }: {
    companyId: string
    subscriptionPlanId: string
  }) =>
    api.post('/customer-service/subscriptions/create/brightflow', {
      companyId,
      subscriptionPlanId,
    }),
)

export const cancelCompanySubscription = createAsyncThunk(
  'app/customer-service/subscriptions/cancel',
  async ({
    companyId,
    plan,
  }: {
    companyId: string
    plan: AdminDashboardSubscriptionPlan
  }) =>
    api.post('/customer-service/subscriptions/cancel', {
      companyId,
      subscriptionPlanId: plan.id,
    }),
)
