import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import { TableSectionHeader } from './DashboardGridPanelDetail'
import { format } from 'date-fns'
import { UserWithSessionData } from './types'

const formatTimestamp = (date: Date): string => {
  return format(date, 'MM/dd/yyyy h:mm a')
}

export const AdminUserDetail = ({
  renderButton,
  usersWithSessionData,
}: {
  renderButton: () => JSX.Element
  usersWithSessionData: UserWithSessionData[]
}): JSX.Element => {
  return (
    <>
      <TableSectionHeader title={'Users'} renderButton={renderButton} />
      <TableContainer component={Paper} sx={{ width: '750px' }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell component="th">Login</TableCell>
              <TableCell component="th">Full Name</TableCell>
              <TableCell component="th">Created on</TableCell>
              <TableCell component="th">Soft Deleted At</TableCell>
              <TableCell component="th">Last Session</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersWithSessionData.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell scope="row">{row.email}</TableCell>
                <TableCell scope="row">{`${row.firstName} ${row.lastName}`}</TableCell>
                <TableCell scope="row">
                  {formatTimestamp(new Date(row.createdAt))}
                </TableCell>
                <TableCell scope="row">
                  {row.softDeletedAt
                    ? formatTimestamp(new Date(row.softDeletedAt))
                    : 'N/A'}
                </TableCell>
                <TableCell scope="row">
                  {row?.lastSession ? (
                    <a href={row.lastSession.fsUrl}>
                      {formatTimestamp(
                        new Date(row?.lastSession?.createdTime * 1000),
                      )}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
