import { ApolloQueryResult } from '@apollo/react-hooks'
import { GetTransactionMerchantsQuery } from '../../../../server/app-data-service/generatedTypes'
import { NumericCriteriaOperators } from '../../../components/designSystem/molecules/NumericCriteriaInput/NumberCriteriaInput.types'
import { Connection } from '../../../modules/connectors/types'
import { CompanyFeaturesPayload } from '../../../modules/companyFeatures'

export enum TransactionStatus {
  pending = 'pending',
  success = 'success',
}

export type NumericFilter = {
  op: NumericCriteriaOperators // 'operator'
  val: string // 'value'
}

export type UrlAttributes = {
  accs?: string[] // Accounts
  amt?: NumericFilter // Amount
  cat?: string[] // Category
  conf?: NumericFilter // Confidence
  desc?: string // Description
  ed?: string | null // End Date
  merch?: string // merchant
  sd?: string | null // start Date
}

export type Merchant = {
  merchantId: string
  merchantName: string
}

export type Category = {
  id: string
  label: string
  parent: string
}

export type Account = {
  id: string
  name: string
  isSelected: boolean
}

export type Transaction = {
  id: string
  accountId: string
  accountMask?: string | null
  accountName: string
  accountOfficialName?: string | null
  accountSubtype?: string | null
  accountType?: string | null
  amountCents: number
  cacheDate: string
  categoryId?: string | null
  categoryLabel?: string | null
  companyId: string
  confidence?: number | null
  currency: string
  description: string
  descriptionClean: string
  externalId?: string | null
  institutionId: string
  institutionName: string
  merchantId?: string | null
  merchantName?: string | null
  platformId: string
  status: string
  transactionTimestamp: string
}

export type categories = Category[]

export type FormatedDateRange = {
  startDate: string | null
  endDate: string | null
}

export type SetDateRangeFn = React.Dispatch<
  React.SetStateAction<{
    startDate: string | null
    endDate: string | null
  }>
>

export type RefetchMerchantsFn = () => Promise<
  ApolloQueryResult<GetTransactionMerchantsQuery>
>

export type SetSelectedAccountsFn = React.Dispatch<
  React.SetStateAction<string[] | null>
>

export type TransactionsHook = {
  accounts: Account[]
  categories: Category[]
  dateRange: FormatedDateRange
  hasBankingConnection: boolean
  hasError: boolean
  hasFeatureAccess: boolean
  isContentLoading: boolean
  isLabelsLoading: boolean
  isSynced: boolean
  merchants: Merchant[]
  refetchMerchants: RefetchMerchantsFn
  rows: Transaction[]
  setDateRange: SetDateRangeFn
  setSelectedAccounts: SetSelectedAccountsFn
}

export type TransactionsScreenProps = {
  bankingConnections: Connection[]
  companyFeatures: CompanyFeaturesPayload
}
