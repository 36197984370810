import { add, differenceInHours, format, sub } from 'date-fns'
import {
  GridColumns,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'

import {
  CashPosition,
  CashPositionAggregate,
  CashPositionCategory,
  CashPositionMerchant,
} from '../../../../server/app-data-service/generatedTypes'
import { CashPositionContextType, GraphData } from './CashPosition.types'
import { HoursBeforeStaleSync } from './CashPosition.consts'
import { Color } from '../../../Color'
import { TableHeaderClasses } from './components/CashPositionTable/CashPositionTable.consts'
import { centsAsDollars } from '../../../util/displayValue'

export const formatDateRangeAsString = (
  startDate: number,
  endDate: number,
): string => {
  const formattedStart = format(new Date(startDate), 'M/d')
  const formattedEnd = format(sub(new Date(endDate), { days: 1 }), 'M/d')

  return formattedStart === formattedEnd
    ? formattedStart
    : `${formattedStart} - ${formattedEnd}`
}

export const formatAggregatesAsGraphData = (
  aggregates: CashPositionAggregate[],
): GraphData =>
  aggregates.map(
    ({ categories, endDate, endingBalanceCents, startDate }, index) => ({
      date: formatDateRangeAsString(
        startDate,
        index === aggregates.length - 1
          ? add(Date.now(), { days: 1 })
          : endDate,
      ),
      cashInflows: Math.abs(categories[0]?.totalCents ?? 0),
      cashOutflows: Math.abs(categories[1]?.totalCents ?? 0),
      cashBalance: endingBalanceCents,
    }),
  )

export const aggregatesToDateRangeMap = (
  aggregates: CashPositionAggregate[],
): { [key: string]: { start: string; end: string } } =>
  aggregates.reduce(
    (acc, curr, index) =>
      Object.assign(acc, {
        [curr.startDate]: {
          start: curr.startDate,
          end:
            index === aggregates.length - 1
              ? new Date().toISOString()
              : sub(new Date(curr.endDate), { days: 1 }).toISOString(),
        },
      }),
    {},
  )

export const formatAggregatesAsTableColumns = (
  aggregates: CashPositionAggregate[],
): GridColumns =>
  aggregates.map(({ endDate, startDate }, index) => ({
    field: String(startDate),
    headerName: formatDateRangeAsString(
      startDate,
      index === aggregates.length - 1 ? add(Date.now(), { days: 1 }) : endDate,
    ),
    sortable: false,
    valueFormatter: ({ value }): string => centsAsDollars(value),
  }))

export const formatAggregateValueAsRow = (
  aggregates: CashPositionAggregate[],
  key: 'startingBalanceCents' | 'cashDifferenceCents' | 'endingBalanceCents',
): { [key: string]: string } =>
  aggregates.reduce(
    (acc, curr) =>
      Object.assign(acc, {
        [curr.startDate]: curr[key],
      }),
    {},
  )

export const aggregateFilterableSubCategories = (
  subCategories: CashPositionCategory[],
): string[] =>
  subCategories.flatMap(({ categoryId, subCategories }) =>
    !subCategories || !subCategories.length
      ? categoryId
      : aggregateFilterableSubCategories(subCategories),
  )

export const getAggregateMerchantRowValues = (
  categoryId: string,
  merchants: CashPositionMerchant[],
  labels: string[],
  startDate: string,
): { [key: string]: GridRowModel } =>
  merchants.reduce(
    (acc, curr) =>
      Object.assign(acc, {
        [`${categoryId}-${curr.merchantId}`]: {
          id: [...labels, curr.merchantName].join(''),
          categoryId,
          category: [...labels, curr.merchantName],
          merchantName: curr.merchantName,
          [startDate]: curr?.totalCents || 0,
        },
      }),
    {},
  )

export const getAggregateCategoryRowValues = (
  category: CashPositionCategory,
  labels: string[],
  startDate: string,
  headerClassName?: string,
  headerIconColor?: string,
): { [key: string]: GridRowModel } => {
  const {
    categoryId,
    categoryLabel,
    merchants,
    subCategories,
    totalCents,
  } = category
  const newLabels = [...labels, categoryLabel]

  const categoryRow = {
    id: categoryId,
    category: newLabels,
    categoryId,
    headerClassName,
    headerIconColor,
    [startDate]: totalCents,
  }

  if (!subCategories || !subCategories.length) {
    return merchants
      ? Object.assign(
          {
            [categoryId]: categoryRow,
          },
          {
            ...getAggregateMerchantRowValues(
              categoryId,
              merchants,
              newLabels,
              startDate,
            ),
          },
        )
      : { [categoryId]: categoryRow }
  }
  return Object.assign(
    {
      [categoryId]: Object.assign(categoryRow, {
        subCategories: aggregateFilterableSubCategories(subCategories),
      }),
    },
    ...subCategories.flatMap(subCategory =>
      getAggregateCategoryRowValues(subCategory, newLabels, startDate),
    ),
  )
}

export const formatAggregateCategoryAsTableRows = (
  aggregates: CashPositionAggregate[],
  categoryIdx: number,
  headerClassName: string,
  headerIconColor: string,
): GridRowModel[] =>
  Object.values(
    aggregates
      .filter(({ categories }) => !!categories[categoryIdx])
      .map(({ categories, startDate }) =>
        getAggregateCategoryRowValues(
          categories[categoryIdx],
          [],
          startDate,
          headerClassName,
          headerIconColor,
        ),
      )
      .reduce((acc, curr) => {
        Object.keys(curr).forEach(key => {
          acc[key] = acc[key] ? { ...acc[key], ...curr[key] } : { ...curr[key] }
        })
        return acc
      }, {}),
  )

export const formatAggregatesAsTableRows = (
  aggregates: CashPositionAggregate[],
): GridValidRowModel[] => {
  const startingBalance = {
    id: 'startingBalanceRow',
    category: ['Starting Cash Balance'],
    headerClassName: TableHeaderClasses.parent,
    ...formatAggregateValueAsRow(aggregates, 'startingBalanceCents'),
  }

  const cashDifference = {
    id: 'cashDifferenceRow',
    category: ['Cash Change'],
    headerClassName: TableHeaderClasses.cashChange,
    ...formatAggregateValueAsRow(aggregates, 'cashDifferenceCents'),
  }

  const endingBalance = {
    id: 'endingBalanceRow',
    category: ['Ending Cash Balance'],
    headerClassName: TableHeaderClasses.ending,
    ...formatAggregateValueAsRow(aggregates, 'endingBalanceCents'),
  }

  return [
    startingBalance,
    ...formatAggregateCategoryAsTableRows(
      aggregates,
      0,
      TableHeaderClasses.cashInflow,
      Color.Pear,
    ),
    ...formatAggregateCategoryAsTableRows(
      aggregates,
      1,
      TableHeaderClasses.cashOutflow,
      Color.Peach,
    ),
    cashDifference,
    endingBalance,
  ]
}

export const getCalculatedStateFromCashPositionResponse = (
  cashPositionResponse: CashPosition,
): Partial<CashPositionContextType> => {
  const {
    aggregates,
    netInflowCents,
    netOutflowCents,
    syncTimestamp,
  } = cashPositionResponse

  return {
    dateRangeMap: aggregatesToDateRangeMap(aggregates),
    endDate: new Date(),
    endingBalanceCents: aggregates.length
      ? aggregates[aggregates.length - 1].endingBalanceCents
      : 0,
    graphData: formatAggregatesAsGraphData(aggregates),
    hasSyncError:
      differenceInHours(new Date(), new Date(syncTimestamp)) >
      HoursBeforeStaleSync,
    netInflowCents,
    netOutflowCents,
    startDate: aggregates.length
      ? new Date(aggregates[0].startDate)
      : new Date(),
    syncTimestamp,
    tableColumns: formatAggregatesAsTableColumns(aggregates),
    tableRows: formatAggregatesAsTableRows(aggregates),
  }
}
