import React from 'react'

import { TrendLabel } from '../../../atoms/TrendLabel/TrendLabel'
import { TrendLabelData } from '../../../atoms/TrendLabel/types'
import { Color } from '../../../../../Color'
import { LegendRow } from './LegendItemRow'

export interface LegendItemData {
  name: string
  value: string
  color: string
  trendLabelData: TrendLabelData[]
}

export const LegendItem = ({
  name,
  color,
  value,
  trendLabelData,
}: LegendItemData): JSX.Element => (
  <LegendRow
    bulletColor={color}
    name={name}
    value={value}
    comparisonComponents={[
      <TrendLabel
        key={`${name}-trend-label-0`}
        trendPercent={trendLabelData[0].trendPercent}
        target={trendLabelData[0].target}
      />,
      <TrendLabel
        key={`${name}-trend-label-1`}
        trendPercent={trendLabelData[1].trendPercent}
        target={trendLabelData[1].target}
      />,
    ]}
  />
)
