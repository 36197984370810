import React, { useState } from 'react'

import { MultiSelect } from '../../../../../../components/designSystem/atoms/MultiSelect/MultiSelect'
import { useSalesForecast } from '../../SalesForecastProvider'
import { Account } from '../../SalesForecast.types'

export const SalesForecastAccountSelect = (): JSX.Element => {
  const { accounts, setFilterAccounts } = useSalesForecast()

  const [selectedOptions, setSelectedOptions] = useState(accounts)

  const handleChangeClicked = (values: string[]): void => {
    const newSelectedOptions = accounts.map((option: Account) => ({
      ...option,
      isSelected: values.includes(option.id),
    }))
    setFilterAccounts(values)
    setSelectedOptions(newSelectedOptions)
  }

  return (
    <MultiSelect
      allowEmptyOptions={false}
      title="Select Accounts"
      options={selectedOptions}
      onChange={handleChangeClicked}
      sx={{ minWidth: '750px' }}
    />
  )
}
