import { last } from 'lodash'

import { OverviewHighlightMetrics } from '../../Overview.types'

import {
  TimeSeriesData,
  Trends,
} from '../../../../../../server/app-data-service/generatedTypes'

import {
  commaDelimitedThousands,
  compactNumber,
  numberAsCommaDelimitiedMoney,
  numberAsMoney,
} from '../../../../../util/displayValue'
import { OverviewHighlightConsts } from '../../Overview.consts'
import { TrendTarget } from '../../../../../components/designSystem/atoms/TrendLabel/types'

type MetricHandler = {
  trendTarget: TrendTarget
  valueFormatter: (value: number) => string
}

export type HightlightMetric = {
  description: React.ReactNode
  label: string
  trends: Trends | null
  trendTarget: TrendTarget
  value: string
}

export const metricTypeHandlers: {
  [key in OverviewHighlightMetrics]: MetricHandler
} = {
  [OverviewHighlightMetrics.AOV]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: numberAsMoney,
  },
  [OverviewHighlightMetrics.CAC]: {
    trendTarget: TrendTarget.negative,
    valueFormatter: numberAsMoney,
  },
  [OverviewHighlightMetrics.CashBalance]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: numberAsCommaDelimitiedMoney,
  },
  [OverviewHighlightMetrics.NewCustomers]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: commaDelimitedThousands,
  },
  [OverviewHighlightMetrics.OrderRevenue]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: numberAsCommaDelimitiedMoney,
  },
  [OverviewHighlightMetrics.OrderVolume]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: compactNumber,
  },
  [OverviewHighlightMetrics.ROAS]: {
    trendTarget: TrendTarget.positive,
    valueFormatter: value => value.toFixed(2),
  },
}

export const hasMeaningfulData = (data: TimeSeriesData): boolean =>
  !!data.timeSeries &&
  data.timeSeries?.reduce((acc, curr) => (acc || 0) + (curr?.value || 0), 0) !==
    0

export const getHighlightMetric = (
  metricType: OverviewHighlightMetrics,
  data?: TimeSeriesData | null,
): HightlightMetric | null => {
  const { trendTarget, valueFormatter } = metricTypeHandlers[metricType]

  if (!data || !hasMeaningfulData(data)) return null

  return {
    description: OverviewHighlightConsts[metricType].description,
    label: OverviewHighlightConsts[metricType].title,
    trends: data?.trends ?? null,
    trendTarget,
    value: valueFormatter(last(data.timeSeries)?.value || 0),
  }
}

export const determineIsLoading = (loadingStates: boolean[]): boolean =>
  loadingStates.filter(isLoading => !isLoading).length < 3
