import { FullstorySession } from '../../../server/services/fullstory/types'
import {
  AdminDashboardCompany,
  AdminDashboardUser,
} from '../../../server/services/adminDashboard'
import { CompanyPlanType } from '../../../server/data/models/types'

export enum CompanyRelationType {
  features = 'features',
  portfolios = 'portfolios',
  users = 'users',
  deletion = 'deletion',
  subscriptions = 'subscriptions',
}

export interface CompanyToAddRelation {
  company: AdminDashboardCompany
  type: CompanyRelationType
}

export const PORTFOLIO_OPTIONS = ['Brightflow', 'CU']

export type UserWithSessionData = AdminDashboardUser & {
  lastSession: FullstorySession | null
}

export const COMPANY_PLAN_TYPE_OPTIONS: string[] = Object.entries(
  CompanyPlanType,
).map(([key, value]) => value)
