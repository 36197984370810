import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { Store } from '../../../../modules/rootReducer'
import { dispatch } from '../../../../store'
import { getGoogleAdsCustomers } from '../../../../modules/welcome'
import { ListGoogleAdsCustomersResponse } from '../../../../../server/routes/api/welcome/google-customers'

import { Caution } from '../../../../components/designSystem/assets/Caution/Caution'
import { UserGuidanceBase } from '../../../shared'

import { PostAuthModal } from '../../../../components/designSystem/organisms/PostAuthModal/PostAuthModal'
import { Spinner } from '../../../../components/designSystem/atoms/Spinner/Spinner'

import { SelectGoogleAdsCustomerForm } from '../../../../components/forms/welcome/SelectGoogleAdsCustomerForm'
import { ProviderModalProps, Providers } from '../Providers'

const UserGuidance = styled(UserGuidanceBase)`
  margin-top: 0px !important;
  min-height: 384px;

  & > * {
    max-width: 450px;
  }
`

const AD_SPEND_DAYS_TO_LOAD = 7
const UNKNOWN_ERROR_MESSAGE = `
There was an error connecting to your Google Ads account.
Going through the flow again may result in success. If not,
please reach out to support.
`
const NO_ACCOUNTS_MESSAGE = `
We were not able to make a connection as this account
does not have an associated Google Ad account. Start a
new connection with a valid account.
`

type GoogleModalReduxProps = ListGoogleAdsCustomersResponse & {
  isLoading: boolean
  isPutting: boolean
  didPut: boolean
  error: Error | null
}

type Props = ProviderModalProps & GoogleModalReduxProps

export const GoogleAdsModalRaw: React.FunctionComponent<Props> = ({
  customers,
  isLoading,
  isOpen,
  onClose,
  error,
}: Props) => {
  const [hasDispatched, setHasDispatched] = useState<boolean>(false)

  if (isOpen && !hasDispatched && typeof window !== 'undefined') {
    setHasDispatched(true)
    dispatch(getGoogleAdsCustomers(AD_SPEND_DAYS_TO_LOAD))
  }

  return (
    <PostAuthModal isOpen={isOpen} onClose={onClose}>
      {((): React.ReactElement => {
        if (!hasDispatched || isLoading) {
          return (
            <UserGuidance
              renderIcon={(): React.ReactNode => (
                <Spinner loading={true} height={50} width={320} />
              )}
              title=""
              subtitle=""
            />
          )
        } else if (customers.length > 0) {
          return (
            <SelectGoogleAdsCustomerForm
              onClose={onClose}
              customers={customers}
            />
          )
        } else {
          return (
            <UserGuidance
              renderIcon={(): React.ReactNode => (
                <Caution height={98} width={114} />
              )}
              title="Connection not completed"
              subtitle={error ? UNKNOWN_ERROR_MESSAGE : NO_ACCOUNTS_MESSAGE}
            >
              <Button
                onClick={(): void => {
                  document.location.href = Providers.googleAds.url
                }}
                variant="outlined"
                color="success"
                size={'large'}
                style={{ height: '30px', fontSize: '18px', padding: '20px' }}
              >
                Start a connection
              </Button>
            </UserGuidance>
          )
        }
      })()}
    </PostAuthModal>
  )
}

const mapStateToProps = ({
  welcome: {
    googleAdsCustomers: { customers, isLoading, isPutting, didPut, error },
  },
}: Store): GoogleModalReduxProps => {
  return {
    customers: customers,
    isLoading,
    isPutting,
    didPut,
    error,
  }
}

export const GoogleAdsModal = connect(mapStateToProps)(
  React.memo(GoogleAdsModalRaw),
)
