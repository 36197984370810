import { api } from '../lib/api'
import React, { useEffect, useState } from 'react'

import { CreditScore, CreditScoreResults } from './useCreditScore'

export const useAdminCreditScore = ({
  companyId,
}: {
  companyId: string
}): {
  creditScore: CreditScore
  notifyUsers: () => Promise<void>
  updateCreditScore: (newScore: CreditScore) => Promise<CreditScore>
} => {
  const emptyScore: CreditScore = React.useMemo(() => {
    return {
      creditScore: undefined,
      isActive: false,
    }
  }, [])

  const [creditScore, setCreditScore] = useState(emptyScore)
  const adminFetchCreditScore = React.useCallback(
    async (companyId: string): Promise<CreditScore> => {
      try {
        const result: CreditScoreResults = await api.get(
          `/customer-service/company-credit-score/${companyId}`,
        )
        const response = result?.results
        if (response) {
          return response
        }
        return emptyScore
      } catch (err) {
        console.error(err)
        return emptyScore
      }
    },
    [emptyScore],
  )

  useEffect(() => {
    const getCreditScore = async (): Promise<void> => {
      const score = await adminFetchCreditScore(companyId)
      setCreditScore(score)
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getCreditScore().catch(() => {})
  }, [adminFetchCreditScore, companyId])

  return React.useMemo(() => {
    const updateCreditScore = async (
      newScore: CreditScore,
    ): Promise<CreditScore> =>
      api.post('/customer-service/company-credit-score/update', {
        ...newScore,
      })
    const notifyUsers = async (): Promise<void> =>
      api.post('/customer-service/company-credit-score/notify-users', {
        companyId,
      })

    return {
      creditScore,
      notifyUsers,
      updateCreditScore,
    }
  }, [companyId, creditScore])
}
