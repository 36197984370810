import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Layout } from '../../../layout'
import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'
import {
  getConnectors,
  selectIsLoaded,
  selectRevenueConnectors,
} from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'
import { useInterval } from '../../../hooks/useInterval'
import { DataSyncing, NoConnections, RevenueLoading } from '../shared'
import { WomanCoinBillIcon } from '../../../components/designSystem/assets/WomanCoinBillIcon/WomanCoinBillIcon'

type FCProps = {
  connectorsLoaded: boolean
  revenueConnections: Connection[]
  loadingRevenueConnections: Connection[]
}

export type ForecastWrapperRenderProps = {
  hasLessThanOneYearOfRevenueData: boolean
  previousYearSyncStillInProgress: boolean
}

type Props = FCProps & {
  renderReport: (props: ForecastWrapperRenderProps) => JSX.Element
  title: string
  icon?: JSX.Element
}

/**
 *
 * This component handles the logic of determining if the forecast
 * report is ready to be shown based on the state of revenue syncs.
 *
 * A forecast report is provided to the component via render prop
 */
const ForecastWrapperImpl: React.FunctionComponent<Props> = ({
  connectorsLoaded,
  revenueConnections,
  loadingRevenueConnections,
  renderReport,
  title,
  icon,
}) => {
  useEffect(() => {
    dispatch(getConnectors())
  }, [])

  // Your revenue sources must be in one of three states for us to
  // show the forecast:

  // 1. One of your revenue connections has 12 or more months of data
  const hasFullYearRevenueData = revenueConnections.some(
    ({ percentOfYearComplete }) => percentOfYearComplete === 100,
  )

  // 2. None of your revenue sources go back at least a year
  // and  your data is fully synced
  const hasLessThanOneYearOfRevenueData = revenueConnections.every(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      initialSyncStatus === 'complete' && percentOfYearComplete < 100,
  )

  // 3. You have a year of data, but your sync is still going
  const previousYearSyncStillInProgress = revenueConnections.some(
    ({ initialSyncStatus, percentOfYearComplete }) =>
      initialSyncStatus !== 'complete' && percentOfYearComplete === 100,
  )

  useInterval(
    () => {
      dispatch(getConnectors())
    },
    hasFullYearRevenueData ? null : 5000,
  )

  return (
    <Layout>
      {((): JSX.Element | null => {
        if (!connectorsLoaded) {
          return (
            <RevenueLoading connections={loadingRevenueConnections}>
              {null}
            </RevenueLoading>
          )
        } else if (revenueConnections.length === 0) {
          return (
            <NoConnections
              title={title}
              icon={icon}
              subtitle={'To get started, connect a revenue account.'}
            />
          )
        } else if (
          hasLessThanOneYearOfRevenueData ||
          previousYearSyncStillInProgress ||
          hasFullYearRevenueData
        ) {
          return renderReport({
            hasLessThanOneYearOfRevenueData,
            previousYearSyncStillInProgress,
          })
        } else {
          return (
            <RevenueLoading connections={loadingRevenueConnections}>
              <DataSyncing
                icon={<WomanCoinBillIcon height={170} width={167} />}
              />
            </RevenueLoading>
          )
        }
      })()}
    </Layout>
  )
}

const mapStateToProps = (store: Store): FCProps => {
  return {
    connectorsLoaded: selectIsLoaded(store.connectors),
    revenueConnections: selectRevenueConnectors(store.connectors),
    loadingRevenueConnections: selectRevenueConnectors(store.connectors).filter(
      c =>
        c.percentOfYearComplete !== 100 && c.initialSyncStatus !== 'complete',
    ),
  }
}

export const ForecastWrapper = connect(mapStateToProps)(
  React.memo(ForecastWrapperImpl),
)
