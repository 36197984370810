import { GraphTrace } from '../../../../../components/designSystem/organisms/BFGraph/BFGraph.types'
import { LINE_COLOR_PALLETE } from '../../../../../components/designSystem/organisms/BFGraph/components/GraphColorPalette'
import {
  ForecastData,
  ForecastDataBySegmentMap,
  ForecastDataMap,
  ForecastPoint,
} from '../types'
import { format, parseISO } from 'date-fns'

/**
 * Parent class for dealing with transformation of raw product demand data provided by API
 * into data format for a given time period
 *
 * Transformation calculations need to be dynamic as users can swith between weekly/monthly views
 * as well as between different historical time periods
 */
export abstract class AbstractProductDemandPeriodTransformer {
  // governs the tick gap used by re-charts for various time period intervals
  // for example, tick gap needs to be wider if we are showing more historical data
  public abstract minTickGap: number[]

  // this is the raw data provided by API
  // contains weekly actuals/forecast for all product segments
  public abstract segmentData: ForecastData[]

  // handy for looking up segment data by segmentName
  // key
  public abstract segmentDataMap: ForecastDataBySegmentMap

  // useful for keeping track of when forecast data begins within the
  // segmentData array
  public abstract firstForecastIndex: number

  // used to help calculate array index offset when different historical time periods
  // are provided by user in order to decide how much data to show in graph
  // example - user enters one year so we offset 52 weeks from last actual index
  public abstract timePeriodArray: number[]

  // date-fns string format used in tooltip
  abstract tooltipDateStringFormat: string

  // gets the appropriate line color based on index position of series
  public static getLineColor(i: number): string {
    return LINE_COLOR_PALLETE[i % LINE_COLOR_PALLETE.length]
  }

  public get lastActualIndex(): number {
    return this.firstForecastIndex - 1
  }

  public get firstForecastDateString(): string {
    return this.segmentData[0].data[this.firstForecastIndex].ds
  }

  public get lastActualDateString(): string {
    return this.segmentData[0].data[this.lastActualIndex].ds
  }

  // converts segmentData to graph traces
  // needs to be dynamic as graph traces will be filtered based on time period
  public createTraces(selectedUnitSegmentIndex: number): GraphTrace[] {
    return this.segmentData
      .map(({ segmentName, data }, i) => {
        const forecastDataPoints = this.getForecastDataPoints(data)

        const actualDataPoints = this.getActualDataPoints(
          data,
          selectedUnitSegmentIndex,
        )

        const graphTrace: GraphTrace[] = [
          // data trace object for the actual units sold
          {
            x: actualDataPoints.map(({ ds }) => {
              const formattedDate = format(
                parseISO(new Date(ds).toISOString()),
                this.tooltipDateStringFormat,
              )
              return formattedDate
            }),
            y: actualDataPoints.map(({ y }) => {
              return y
            }),
            name: segmentName,
            type: 'line',
            color: AbstractProductDemandPeriodTransformer.getLineColor(i),
            width: 2,
            traceOptions: { isAnimationActive: false },
            info: actualDataPoints.map(({ yhat }) => ({ yhat })),
          },
          // data trace object for the forecast units sold
          {
            // we only want the values for the forecast to be past what the last
            x: forecastDataPoints.map(({ ds }) => {
              const formattedDate = format(
                parseISO(new Date(ds).toISOString()),
                this.tooltipDateStringFormat,
              )

              return formattedDate
            }),
            y: forecastDataPoints.map(({ yhat, y }, index) => {
              // note that we double plot the last full week of actuals
              // for the purposes of connecting the two series lines (actuals + forecast)
              // so the first "forecast" data point is actually the actuals week
              // thus use y instead of yhat
              return index === 0 ? y : yhat
            }),
            name: `${segmentName} forecast`,
            type: 'dashed_line',
            color: AbstractProductDemandPeriodTransformer.getLineColor(i),
            width: 2,
            traceOptions: {
              legendType: 'none',
              isAnimationActive: false,
            },
          },
        ]

        return graphTrace
      })
      .flat()
  }

  public abstract getForecastDataPoints(data: ForecastPoint[]): ForecastPoint[]

  public abstract getActualDataPoints(
    data: ForecastPoint[],
    selectedUnitSegmentIndex: number,
  ): ForecastPoint[]

  //
  public buildSegmentDataMap(): ForecastDataBySegmentMap {
    return this.segmentData.reduce(
      (acc: ForecastDataBySegmentMap, { segmentName, data }) => {
        acc[segmentName] = { data }
        return acc
      },
      {} as ForecastDataBySegmentMap,
    )
  }

  /**
   * Helper to calculate X-Axis ticks to be supplied to re-charts
   * We only need a tick for the first week of a month
   * @param traces
   * @returns
   */
  public abstract getXAxisTicks(traces: GraphTrace[]): Array<string | number>

  public abstract getLastYearActualFromForecastLabel(
    segmentName: string,
    forecastLabel: string,
  ): number

  public isLabelForecast(label: string): boolean {
    return new Date(label) >= new Date(this.firstForecastDateString)
  }

  public getPayloadValues = (
    label: string,
    payload: {
      name: string
      value: number
      color: string
      payload: { x: string; y: number | null; yhat: number | null }
    }[],
    selectedLine: string | null,
  ): {
    baseValue: string | number
    comparisonValue: string | number
    groupName: string
    color: string
  }[] => {
    const isForecast = this.isLabelForecast(label)

    const groupValues = []

    // if there is a selected line we use that in the tooltip
    const filteredPayload = selectedLine
      ? payload.filter(({ name }) => name.includes(selectedLine))
      : payload

    const uniqueNames = new Set()

    const forecastDataMap = filteredPayload.reduce<ForecastDataMap>(
      (accum, line) => {
        const { name, value, color, payload: payloadData } = line

        // hacky but there are situations where we could accidentally plot the same item twice
        const formattedName = name.replace(' forecast', '')

        if (uniqueNames.has(formattedName)) {
          return accum
        }

        uniqueNames.add(formattedName)

        accum[formattedName] = {
          ...(accum[formattedName] && accum[formattedName]),
          ...(isForecast && { forecast: value }),
          ...(!isForecast && { actual: value }),
          ...(!isForecast && { yhat: payloadData?.yhat }),
          color,
        }
        return accum
      },
      {},
    )

    for (const [
      segmentName,
      { color, actual, forecast, yhat },
    ] of Object.entries(forecastDataMap)) {
      const forecastValue = forecast || 'N/A'
      const actualValue = actual || 'N/A'
      groupValues.push({
        comparisonValue: isForecast ? forecastValue : yhat || 'N/A',
        baseValue: isForecast
          ? this.getLastYearActualFromForecastLabel(segmentName, label)
          : actualValue,
        groupName: segmentName,
        color,
      })
    }

    return groupValues
  }

  public abstract getBaseName(label: string): string
}
