import React, { useState } from 'react'
import { connect } from 'react-redux'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import { SpeedBumpModal } from '../../designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { SuccessIcon } from '../../designSystem/assets/SuccessIcon/SuccessIcon'
import { forgotPasswordLink } from '../../../modules/user/user'
import { dispatch } from '../../../store'
import { Store } from '../../../modules/rootReducer'

const LinkActive = styled('a')`
  color: #08678c;
  cursor: pointer;
`

const LinkClicked = styled('a')`
  color: #a8a8a8;
`

interface Props {
  forgotPassword?: any
}

const Link = ({ email, error }: { email: string; error: any }): JSX.Element => {
  const [resend, setResent] = useState(false)

  const resendEmail = async (): Promise<void> => {
    setResent(true)
    dispatch(forgotPasswordLink(email))
    setTimeout(() => {
      setResent(false)
    }, 30000)
  }
  return !error ? (
    <span>
      Didn’t receive an email?{' '}
      {resend ? (
        <LinkClicked>Email sent. Wait 30 secs</LinkClicked>
      ) : (
        <LinkActive onClick={resendEmail}>Resend</LinkActive>
      )}
    </span>
  ) : (
    <Alert severity="error">{error.payload}</Alert>
  )
}

const EmailSentForm = ({
  email,
  forgotPassword,
  handleClose,
}: {
  email: string
  forgotPassword: any
  handleClose: () => void
}): JSX.Element => {
  const { error } = forgotPassword
  return (
    <div>
      <SpeedBumpModal
        title={'Email Sent'}
        text={`Check your  email for instructions to reset your password.`}
        secondaryContent={<Link email={email} error={error} />}
        handleClose={handleClose}
      >
        <SuccessIcon height={35} width={35} />
      </SpeedBumpModal>
    </div>
  )
}

const mapStateToProps = ({
  forgotPassword: { forgotPassword },
}: Store): Props => ({
  forgotPassword,
})

export const EmailSent = connect(mapStateToProps)(EmailSentForm)
