import React from 'react'
import { AdminDashboardCompanyFeature } from '../../../../server/services/adminDashboard'
import { dispatch } from '../../../store'
import { deleteCompanyFeature } from '../../../modules/adminDashboard/adminDashboard'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'

interface Props {
  feature: AdminDashboardCompanyFeature
  onCancel: () => void
  onRemove: (feature: AdminDashboardCompanyFeature) => void
}

export const DeleteFeatureModal = (props: Props): JSX.Element => {
  const { feature, onCancel, onRemove } = props

  const handleFeatureDeleteConfirmClicked = (): void => {
    if (feature) {
      dispatch(deleteCompanyFeature(feature ?? { companyId: '', id: '' }))
      onRemove(feature)
    }
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Confirm Delete</h5>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Feature Flag</strong>
                </TableCell>
                <TableCell>{feature.label}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Company ID:</strong>
                </TableCell>
                <TableCell>{feature.companyId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container={true} justifyContent={'end'}>
          <Grid item sx={{ mr: 1 }}>
            <Button variant={'outlined'} color={'warning'} onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={handleFeatureDeleteConfirmClicked}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
