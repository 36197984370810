import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Color } from '../../../../../Color'

export const LegendBottom = styled(Box)`
  padding: 24px;
  grid-area: legend-bottom;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const LegendBody = styled(Box)`
  display: flex;
  flex-direction: column;

  & > * {
    border-left: 1px solid ${Color.LightestGrey};
    padding-left: 8px;
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid ${Color.LightestGrey};
  }
`
