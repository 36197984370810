import { createTheme } from '@mui/material/styles'
import { Color } from './Color'

// For custom variants, we need to declare these modules
// as per https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    navTitle: React.CSSProperties
    h7: React.CSSProperties
    comparisonPrimary: React.CSSProperties
    comparisonSecondary: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    navTitle?: React.CSSProperties
    h7?: React.CSSProperties
    comparisonPrimary?: React.CSSProperties
    comparisonSecondary?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    navTitle: true
    h7: true
    comparisonPrimary: true
    comparisonSecondary: true
  }
}

const FONT_FAMILY = [
  '"Open Sans"',
  '"Helvetica Neue"',
  'Helvetica',
  'sans-serif',
].join(',')

export const materialUITheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          position: 'absolute',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          color: '#000000',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 0 4px 0 rgba(0,0,0,0.19)',
          padding: '20px',
          maxWidth: '360px',
        },
        arrow: {
          color: '#FFFFFF',
          background: 'white',
          '&::before': {
            boxShadow: '0 0 4px 0 rgba(0,0,0,0.19)',
            boxSizing: 'border-box',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            input: {
              color: Color.ErrorRed,
            },
          },
        },
        sizeSmall: {
          input: {
            height: '20px',
            padding: '10px',
          },
        },
        input: {
          padding: '16.5px 14px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: Color.VioletEggplant,
    },
    secondary: {
      main: Color.DarkBlue,
    },
    success: {
      main: Color.VioletEggplant,
    },
    error: {
      main: Color.ErrorRed,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontWeightBold: 600,
    button: {
      textTransform: 'none',
    },
    navTitle: {
      fontSize: '1.25rem',
      fontWeight: 'var(--font-weight--normal)',
      color: Color.Green,
    },
    h1: {
      fontWeight: 400,
      fontSize: '5.25rem',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 400,
      fontSize: '3.28125rem',
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.63rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.86rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.53rem',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.31rem',
    },
    h7: {
      fontWeight: 400,
      fontSize: '1.15rem',
      fontFamily: FONT_FAMILY,
    },
    overline: {
      fontWeight: 400,
      fontSize: '.55rem',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '.66rem',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '.98rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.12rem',
      letterSpacing: '0.15px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '.875rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '.766rem',
    },
    comparisonPrimary: {
      fontSize: '13px',
      fontWeight: 500,
    },
    comparisonSecondary: {
      fontSize: '10px',
    },
  },
})
