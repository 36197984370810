import { styled } from '@mui/material/styles'
import { Link as UnstyledLink } from 'react-router-dom'

import { Color } from '../../Color'

export const Instructions = styled('div')`
  text-align: center;
`

export const ExternalLink = styled('a')`
  text-decoration: none;
  color: ${Color.DarkerBlue};
  font-weight: bold;
`

export const Link = styled(UnstyledLink)`
  text-decoration: none;
  color: ${Color.DarkerBlue};
  padding-left: 5px;
  font-weight: bold;
`

export const EmailLink = styled('a')`
  color: ${Color.DarkerBlue};
`

export const ContentBox = styled('div')`
  display: flex;
  gap: 0.438rem;
  padding: 0.219rem;
  align-items: center;
  grid-area: content;
  justify-content: center;
  width: 100%;
  div:nth-child(2) {
    text-align: center;
    padding-left: 1.5em;
  }
`

export const Content = styled('div')`
  font-size: 14px;
  padding: 0.219rem;
  width: 100%;
  height: 100%;
`

export const SmallContent = styled(Content)`
  font-size: 12px;
`

export const ContentHead = styled(Content)`
  font-size: 16px;
  white-space: pre-line;
`
