import { Color } from '../../../../Color'
import {
  commaDelimitedThousands,
  compactNumber,
  createCentsAsDollarsFormatter,
  numberAsCommaDelimitiedMoney,
  numberAsMoney,
} from '../../../../util/displayValue'
import { mapBrandNameToColor } from '../../../../util/mapBrandNameToColor'

import { mapChannelNameToDisplayValue } from '../../../../util/mapChannelNameToDisplayValue'

import {
  LegendItem,
  LineGraphPresentationTypes,
} from './LineGraphPresentationCard.types'

export const formatXAxisTicks = (chartData: Record<string, any>[]) =>
  chartData.reduce<string[]>((acc: string[], data, index) => {
    acc = index % 2 === 0 ? [...acc, data.date] : [...acc]
    return acc
  }, [])

export const formatLineObject = (
  chartItem: Record<string, any>,
  shouldExcludeTotal?: boolean,
): { name: string; color: Color }[] => {
  const channels = chartItem
    ? Object.entries(chartItem).filter(([key]) => key !== 'date')
    : []

  const viewableChannels =
    channels.length === 2 || shouldExcludeTotal
      ? channels.filter(([key]) => key !== 'total')
      : channels

  return viewableChannels.map(([key, _value]) => {
    return { name: key, color: mapBrandNameToColor(key) }
  })
}

export const presentationTypeToFormatterMap: {
  [key in LineGraphPresentationTypes]: {
    legendFormatter: (value: number) => any
    tickFormatter: (value: number) => any
    tooltipFormatter: (
      value: string | number | (string | number)[],
      name: string,
    ) => string[]
  }
} = {
  [LineGraphPresentationTypes.LargeMoney]: {
    legendFormatter: createCentsAsDollarsFormatter(0),
    tickFormatter: numberAsMoney,
    tooltipFormatter: (value, name) => [
      createCentsAsDollarsFormatter(0)(value as number),
      mapChannelNameToDisplayValue(name),
    ],
  },
  [LineGraphPresentationTypes.SmallMoney]: {
    legendFormatter: numberAsMoney,
    tickFormatter: numberAsMoney,
    tooltipFormatter: (value, name) => [
      numberAsMoney(value as number),
      mapChannelNameToDisplayValue(name),
    ],
  },
  [LineGraphPresentationTypes.Volume]: {
    legendFormatter: commaDelimitedThousands,
    tickFormatter: compactNumber,
    tooltipFormatter: (value, name) => [
      commaDelimitedThousands(value as number),
      mapChannelNameToDisplayValue(name),
    ],
  },
}

export const formatLegendData = (
  legendData: LegendItem[],
  presentationType: LineGraphPresentationTypes,
  shouldExcludeTotal?: boolean,
): any[] => {
  const legendEntries = shouldExcludeTotal
    ? legendData.filter(({ name }) => name !== 'total')
    : legendData

  return legendEntries.map(({ name, value, trendLabelData }) => ({
    name: mapChannelNameToDisplayValue(name),
    color: mapBrandNameToColor(name),
    value: presentationTypeToFormatterMap[presentationType].legendFormatter(
      value,
    ),
    trendLabelData,
  }))
}
