import React from 'react'
import { styled } from '@mui/material/styles'

import {
  UnavailableValue,
  formatDate,
  formatMaxDecimals,
  numberAsMoney,
} from '../../../util/displayValue'

import {
  HeadingTr,
  ROASConfig,
  ROASData,
  ROAS_CATEGORY_LABELS,
  Table,
  aggregateRoasData,
} from './shared'

import { AccountCategories } from '../../../../server/data/models/sync/types'
import { GetMarketingExpenses } from '../../../modules/reports/roas'
import { GetRevenueResults } from '../../../../server/routes/api/reports'

type RowType = 'heading' | 'subheading' | 'row' | 'subrow'
type Row = { values: string[]; type: RowType }

type RowProps = Readonly<{
  data: Row
}>

const SubheadingTr = styled('tr')`
  border-bottom: 1px solid black;
`

const PaddingTh = styled('th')`
  white-space: nowrap;
  && {
    padding-left: 1.5em;
  }
`

const RoasTable = styled(Table)`
  table-layout: auto;
  th:first-child {
    padding-right: 30px;
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
    filter: drop-shadow(3px 10px 4px #ccc);
    width: 200px;
  }
`

export const StickyColumnTableContainer = styled('div')`
  overflow-x: scroll;
`

const GeneralTh = styled('th')`
  white-space: nowrap;
`

const SubrowTh: React.FC = ({ children }) => (
  <PaddingTh scope="rowgroup">{children}</PaddingTh>
)
const HeadingTh: React.FC = ({ children }) => (
  <GeneralTh style={{ width: '180px' }} scope="colgroup">
    {children}
  </GeneralTh>
)
const CommonTh: React.FC = ({ children }) => (
  <GeneralTh scope="rowgroup">{children}</GeneralTh>
)

const Row: React.FC<RowProps> = ({ data }) => {
  const {
    type,
    values: [title, ...rest],
  } = data
  const [Tr, Th, Td] = ((
    type: RowType,
  ): (React.FC | keyof React.ReactHTML)[] => {
    switch (type) {
      case 'heading':
        return [HeadingTr, HeadingTh, CommonTh]
      case 'subheading':
        return [SubheadingTr, CommonTh, 'td']
      case 'row':
        return ['tr', CommonTh, 'td']
      case 'subrow':
        return ['tr', SubrowTh, 'td']
      default:
        throw new Error('Row type is not valid')
    }
  })(type)

  return (
    <Tr>
      <Th>{title}</Th>
      {rest.map((value, idx) => (
        <Td key={idx}>{value}</Td>
      ))}
    </Tr>
  )
}

type SectionProps = Readonly<{
  data: Row[]
}>

const Section: React.FunctionComponent<SectionProps> = ({ data }) => {
  return (
    <tbody>
      {data.map((row, idx) => {
        return <Row data={row} key={idx} />
      })}
    </tbody>
  )
}

type Props = Readonly<{
  revenueResults: GetRevenueResults
  marketingExpenses: GetMarketingExpenses
}>

const roasRow = (data: Array<ROASData>): Row => {
  return {
    type: 'row',
    values: [
      ROAS_CATEGORY_LABELS.roas,
      ...data.map(value => formatMaxDecimals(value.roas, 2)),
    ],
  }
}

const revenueRow = (data: Array<ROASData>): Row => {
  return {
    type: 'row',
    values: [
      ROAS_CATEGORY_LABELS.revenue,
      ...data.map(value => numberAsMoney(value.revenue, true)),
    ],
  }
}

const marketingRow = (
  data: Array<ROASData>,
  type: 'row' | 'subheading' = 'row',
): Row => {
  return {
    type: type,
    values: [
      ROAS_CATEGORY_LABELS.expense,
      ...data.map(value => numberAsMoney(value.expense, true)),
    ],
  }
}
export const SectionedTable: React.FunctionComponent<{
  data: Props
}> = ({ data: { revenueResults, marketingExpenses } }) => {
  const allRoasData = aggregateRoasData(
    'all',
    revenueResults,
    marketingExpenses,
  )
  const shopifyRoasData = aggregateRoasData(
    AccountCategories.shopifySales,
    revenueResults,
    marketingExpenses,
  )
  const amazonRoasData = aggregateRoasData(
    AccountCategories.amazonSales,
    revenueResults,
    marketingExpenses,
  )
  const timestamps = allRoasData.map(value =>
    formatDate(value.ts, revenueResults.precision),
  )

  const allData: Row[] = [
    { type: 'heading', values: [ROASConfig.all.label, ...timestamps] },
    roasRow(allRoasData),
    revenueRow(allRoasData),
    marketingRow(allRoasData),
  ]

  const shopifyData: Row[] = [
    { type: 'heading', values: [ROASConfig.shopifySales.label] },
    roasRow(shopifyRoasData),
    revenueRow(shopifyRoasData),
    marketingRow(shopifyRoasData, 'subheading'),
    {
      type: 'subrow',
      values: [
        'Google Ads',
        ...(marketingExpenses.categories.googleAds?.marketingExpenseCents.map(
          value => numberAsMoney(value, true),
        ) ?? allRoasData.map(() => UnavailableValue)),
      ],
    },
    {
      type: 'subrow',
      values: [
        'Facebook Ads',
        ...(marketingExpenses.categories.facebookAds?.marketingExpenseCents.map(
          value => numberAsMoney(value, true),
        ) ?? allRoasData.map(() => UnavailableValue)),
      ],
    },
  ]

  const amazonData: Row[] = [
    { type: 'heading', values: [ROASConfig.amazonSales.label] },
    roasRow(amazonRoasData),
    revenueRow(amazonRoasData),
    marketingRow(amazonRoasData, 'subheading'),
    {
      type: 'subrow',
      values: [
        'Amazon Ads',
        ...(marketingExpenses.categories.amazonAds?.marketingExpenseCents.map(
          value => numberAsMoney(value, true),
        ) ?? allRoasData.map(() => UnavailableValue)),
      ],
    },
  ]

  return (
    <StickyColumnTableContainer>
      <RoasTable>
        <Section data={allData} />
        <Section data={shopifyData} />
        <Section data={amazonData} />
      </RoasTable>
    </StickyColumnTableContainer>
  )
}
