export enum Color {
  Black = '#000',
  BlackOpaque = `rgb(0, 0, 0, 0.5)`,
  Blue = '#064D69',
  NavyBlue = '#1B394A',
  LightNavyBlue = '#264E65',
  MidGreen = '#79BF43',
  DarkBlue = '#08678C', // aka Teal in Figma
  DarkerBlue = '#19678C',
  DarkGray = '#757575',
  DarkGrey = '#757575',
  DarkerGray = '#c4c4c4',
  TooltipGrey = '#A5A5A5',
  DimGray = '#242424',
  MediumDarkGray = '#444444',
  DarkCharcoal = '#333333',
  DarkCharcoalOpaque = 'rgba(51, 51, 51, 0.5)',
  Disabled = 'rgba(0,0,0,0.38)',
  DisabledLight = 'rgba(0,0,0,0.12)',
  Transparent = 'rgba(0,0,0,0.08)',
  Shadow = 'rgba(0,0,0,0.2)',
  Gray = '#ecf0f4',
  Grey = '#ecf0f4',
  Grey50 = '#fafafa',
  Green = '#78be20',
  TrendGreen = '#0B9E00',
  LightBlue = '#66b8d9',
  LightBrown = '#D9BBA0',
  LightGreen = 'rgba(120, 190, 32, 0.2)', // #78be20 (Green) Opacity 0.2
  LightGrey = '#A3A3A3',
  LighterGrey = 'rgba(102,102,102,0.49)', // #666666 (Grey) Opacity 0.49
  LightestGrey = 'rgba(102,102,102,0.08)', // #666666 (Grey) Opacity 0.08
  TransparentGrey = 'rgba(216,216,216,0.12)', // #666666 (Grey) Opacity 0.49
  TransparentWhite = 'rgba(255, 255, 255, 0.8)', // Blurred Looking #ffffffcc Opacity 0.8
  Orange = '#f2541b',
  ErrorOrange = '#F27731',
  Pink = '#FF007E',
  LightOrange = 'rgba(242, 84, 27, 0.2)',
  LightSilver = '#e1e1e1',
  Red = '#F44336',
  AccountingRed = '#BE4A4A',
  StartScreenBlue = '#e7f0f4',
  SimiSaltPan = '#f1f8e8',
  White = '#fff',
  SemiWhite = '#E7E7E7',
  // NOTE(connor): Oddly enough, VioletEggplant is a shade of green
  VioletEggplant = '#5a8f18',
  ErrorRed = '#BF0711',
  Fair = '#FF8F33',
  Good = '#FFD500',
  Great = '#BDDD32',
  Excellent = '#6DA23A',
  // Brands
  Amazon = '#FF9900',
  Shopify = '#78BE20',
  Facebook = '#4267B2',
  GoogleAds = '#009CE9',
  // Informative Table
  Grey1 = 'rgba(248, 248, 248, 1)',
  Grey2 = 'rgba(147, 147, 147, 0.23)',
  Grey3 = 'rgba(105, 103, 108, 1)',
  Grey4 = 'rgba(188, 188, 188, 1)',
  Grey5 = 'rgba(105, 103, 108, 1)',
  Grey6 = 'rgba(217, 217, 217, 1)',
  Green2 = 'rgba(102, 152, 65, 1)',
  Yellow2 = 'rgba(245, 168, 47, 1)',
  LighterYellow = 'rgba(245, 168, 47, 0.11)',
  LighterGreen = 'rgba(140, 178, 112, 0.11)',
  LighterBlue = '#edf7fa',
  DarkerPurple = '#271F39',
  TeaGreen = '#E9EFE2',

  // Gradients
  GradientYellow = '#FCFCDB',
  GradientBlue = '#D4E5EC',
  GradientGreen = '#F1FFE0',
  // Primary
  Pear = '#A2C27A',
  PearWithOpacity = 'rgba(162, 194, 122, 0.11)',
  // Secondary
  Peach = '#E6A48A',
  PeachWithOpacity = 'rgba(230, 164, 138, 0.11)',

  // New Brand Colors
  BrandPurple = '#00003B',
  BrandHighlightBlue = '#1B284A',
}
