import { Connection } from '../../../../../modules/connectors/types'

export const useOverviewDatePicker = ({
  bankingConnections,
  expenseConnections,
  revenueConnections,
}: {
  bankingConnections: Connection[]
  expenseConnections: Connection[]
  revenueConnections: Connection[]
}): { shouldRenderDatePicker: boolean } => {
  const hasOneYearRevenueData = revenueConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const hasOneYearExpenseData = expenseConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const hasOneYearBankingData = bankingConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  return {
    shouldRenderDatePicker:
      hasOneYearRevenueData || hasOneYearExpenseData || hasOneYearBankingData,
  }
}
