import React from 'react'
import { styled } from '@mui/material/styles'
import { css, keyframes } from '@emotion/react'
import { Color } from '../../../../Color'

const Icon: React.FC<React.ReactNode> = ({ children }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    {children}
  </svg>
)

const rotate = keyframes`
  from {
    transform: rotate(360deg)
  }
  to {
    transform: rotate(0deg)
  }
`
// Loader designed to be 50px^2
// Root font size 87.5% -> 50px/(.875 * 16px) ~= 3.57rem
// Fixed size might beat magic ratio (also, future props)
export const SpinStyle = styled(Icon, {
  shouldForwardProp: (prop: any): boolean =>
    prop !== '$loading' &&
    prop !== 'height' &&
    prop !== 'width' &&
    prop !== 'fill',
})<{
  $loading?: boolean
  height?: number
  width?: number
  fill: string
}>(
  ({ $loading, height, width, fill }) => css`
    animation: ${rotate} infinite 2s linear;
    color: ${Color.DarkBlue};
    height: 1.75rem;
    width: 3.12rem;
    ${!$loading ? 'visibility: hidden;' : ''}
    ${height ? `height: ${height}px;` : ''}
  ${width ? `width: ${width}px;` : ''}
  fill: ${fill}
  `,
)

export const Spinner: React.FunctionComponent<{
  loading: boolean
  height?: number
  width?: number
  color?: string
}> = ({ loading, height, width, color }) => (
  <SpinStyle
    // this verbose ternary removes a warning message
    // eslint-disable-next-line no-unneeded-ternary
    $loading={loading ? loading : (undefined as any)}
    height={height}
    width={width}
    fill={color ?? 'currentColor'}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1V4C16.42 4 20 7.58 20 12C20 13.57 19.54 15.03 18.76 16.26L17.3 14.8C17.75 13.97 18 13.01 18 12C18 8.69 15.31 6 12 6V9L8 5L12 1ZM6 12C6 15.31 8.69 18 12 18V15L16 19L12 23V20C7.58 20 4 16.42 4 12C4 10.43 4.46 8.97 5.24 7.74L6.7 9.2C6.25 10.03 6 10.99 6 12Z"
      />
    </g>
  </SpinStyle>
)
