import { useState } from 'react'
import { head } from 'lodash'

import { useGetOverviewLtvReportQuery } from '../../../../../../server/app-data-service/generatedTypes'
import { OverviewCustomerValueProps } from './OverviewCustomerValueCard.types'

export const useOverviewCustomerValueCard = ({
  expenseConnections,
  revenueConnections,
}: OverviewCustomerValueProps) => {
  const hasOneYearExpenseData = expenseConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const hasOneYearRevenueData = revenueConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const { data, loading } = useGetOverviewLtvReportQuery()

  const projected = data?.LTVReport?.projected

  const columns = [
    {
      heading: '3 mo. average',
      values: [
        projected?.threeMonth?.grossMargin || null,
        projected?.threeMonth?.cac || null,
        projected?.threeMonth?.customerMargin || null,
        projected?.threeMonth?.ltvToCacRatio || null,
      ],
    },
    {
      heading: '6 mo. average',
      values: [
        projected?.sixMonth?.grossMargin || null,
        projected?.sixMonth?.cac || null,
        projected?.sixMonth?.customerMargin || null,
        projected?.sixMonth?.ltvToCacRatio || null,
      ],
    },
    {
      heading: '12 mo. average',
      values: [
        projected?.tweleveMonth?.grossMargin || null,
        projected?.tweleveMonth?.cac || null,
        projected?.tweleveMonth?.customerMargin || null,
        projected?.tweleveMonth?.ltvToCacRatio || null,
      ],
    },
  ]

  // check if any column has non-null gross margin
  const hasLTVReport = !!columns.filter(({ values }) => head(values)).length

  return {
    columns,
    isLoading: loading,
    shouldRender:
      hasOneYearExpenseData && hasOneYearRevenueData && hasLTVReport,
  }
}
