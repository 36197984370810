// https://brightflow.invisionapp.com/console/share/AW3005J8MY/975857814
export const LINE_COLOR_PALLETE = [
  '#C8840E',
  '#967B2C',
  '#807A1B',
  '#1B7080',
  '#39856A',
  '#B36E4D',
  '#962C39',
  '#A1186C',
  '#6c254b',
  '#4D188F',
  '#483364',
]
