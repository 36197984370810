import { NumericCriteriaOperators } from '../../../../../components/designSystem/molecules/NumericCriteriaInput/NumberCriteriaInput.types'

export const NumericOperatorToFilterOperatorMap: {
  [key in NumericCriteriaOperators]: string
} = {
  [NumericCriteriaOperators.EQUAL]: '=',
  [NumericCriteriaOperators.NOTEQUAL]: '!=',
  [NumericCriteriaOperators.LESS]: '<',
  [NumericCriteriaOperators.LESSOREQUAL]: '<=',
  [NumericCriteriaOperators.GREATER]: '>',
  [NumericCriteriaOperators.GREATEROREQUAL]: '>=',
}
