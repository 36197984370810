import {
  TimeSeriesChannel,
  TimeSeriesData,
  useGetCustomersByDurationQuery,
} from '../../../../../../server/app-data-service/generatedTypes'
import { TimeParams } from '../../Overview.types'

export const useOverviewNewCustomerCard = ({
  duration,
  granularity,
}: TimeParams) => {
  const { data, loading } = useGetCustomersByDurationQuery({
    variables: {
      duration,
      granularity,
    },
  })

  const channels = data?.CustomersByDuration?.newCustomers?.channels?.filter(
    (channel): channel is TimeSeriesChannel => !!channel,
  )

  const nonZeroChannels = channels?.filter(
    channel => channel?.total && channel?.total > 0,
  )

  const totals: TimeSeriesData = data?.CustomersByDuration?.newCustomers
    ?.totals ?? {
    total: null,
    timeSeries: null,
  }

  return {
    channels: nonZeroChannels?.length ? nonZeroChannels : null,
    isEmpty: !nonZeroChannels || nonZeroChannels?.length === 0,
    isLoading: loading,
    totals,
  }
}
