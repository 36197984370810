import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React from 'react'

import { Color } from '../../../Color'
import { ROASData, ROAS_CATEGORY_LABELS } from './shared'

import {
  formatDate,
  formatMaxDecimals,
  isNumeric,
  numberAsMoney,
} from '../../../util/displayValue'

import { DatePrecision } from '../../../../server/data/models/DatePrecision'

const BAR_COLORS: { [key: string]: string } = {
  amazonSales: Color.Amazon,
  shopifySales: Color.Shopify,
}

type ROASGraphProps = Readonly<{
  data: Array<ROASData>
  precision: DatePrecision
}>

export const ROASGraph: React.FunctionComponent<ROASGraphProps> = ({
  data,
  precision,
}) => {
  const isMissing = (v: number): boolean => !isNumeric(v)
  const revenueMissing = data.map(v => v.revenue).every(isMissing)
  const expenseMissing = data.map(v => v.expense).every(isMissing)
  const roasMissing = data.map(v => v.roas).every(isMissing)

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey="ts"
          tickFormatter={(ts): string => formatDate(ts, precision)}
        />

        <YAxis tickFormatter={numberAsMoney} />

        <YAxis orientation="right" yAxisId="roas" />

        <CartesianGrid vertical={false} strokeDasharray="3 3" />

        <Tooltip
          labelFormatter={(ts): string => formatDate(ts, precision)}
          formatter={(value, category): [string, string] => [
            category === 'roas'
              ? formatMaxDecimals(value as number, 2)
              : numberAsMoney(value as number, true),
            ROAS_CATEGORY_LABELS[category] || category,
          ]}
        />

        <Legend
          formatter={(category): string =>
            ROAS_CATEGORY_LABELS[category] || category
          }
        />

        {!revenueMissing && (
          <Bar dataKey={'revenue'} fill={BAR_COLORS.amazonSales} />
        )}

        {!expenseMissing && (
          <Bar dataKey={'expense'} fill={BAR_COLORS.shopifySales} />
        )}

        {!roasMissing && (
          <Line type="linear" dataKey="roas" stroke="black" yAxisId="roas" />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
