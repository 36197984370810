import React from 'react'
import { IconProps } from '../../assets.types'

export const NetSuiteIcon: React.FunctionComponent<IconProps> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 501 289"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2</title>
      <defs>
        <polygon id="path-1" points="0 0 45 0 45 77 0 77"></polygon>
        <polygon id="path-3" points="0 0 112 0 112 95 0 95"></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-2">
          <g id="Netsuite" transform="translate(0.000000, 210.000000)">
            <polyline
              id="Path"
              fill="#000000"
              fillRule="nonzero"
              points="16.4288787 78 0 78 0 2 21.4344828 2 46.6344828 45.6748582 46.6344828 2 63 2 63 78 45.6206897 78 16.4288787 26.2438563"
            ></polyline>
            <polyline
              id="Path"
              fill="#000000"
              fillRule="nonzero"
              points="127 2 77 2 77 78 127 78 127 63.3882428 94.7248925 63.3882428 94.7248925 44.9012699 124.565933 44.8922897 124.565933 29.669837 94.8242416 29.669837 94.8242416 16.9305758 127 16.9305758"
            ></polyline>
            <path
              d="M138,2 L198,2 L198,16.9305758 L176.829851,16.9305758 L176.829851,78 L159.295522,78 L159.304477,16.9485374 C159.304477,16.9485374 140.722388,16.9305758 138,16.9305758"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            ></path>
            <path
              d="M159,16.5097655 C159,16.5097655 138.82976,16.4902345 138,16.4902345"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            ></path>
            <path
              d="M208.001889,60.1997353 C212.284828,62.7187112 219.189142,69.1800384 230.906102,70.3923798 C244.927519,71.8471889 250.826746,64.3486353 251.510386,57.9277192 C252.520001,48.4220688 244.17144,46.3880293 231.802532,43.9678385 C218.125198,41.296198 209.337478,38.7592627 208.060747,22.4420515 C207.100934,10.1974081 217.536634,-0.794482843 237.837581,0.0451757776 C245.611159,0.363976822 253.823898,2.65395352 257.952904,5.33008382 L257.903102,17.2110253 C254.883313,15.3251617 247.449292,9.49694443 234.048132,8.81893241 C223.607903,8.28909396 217.903355,15.0018708 217.858081,20.6684414 C217.749423,33.5821171 228.502042,32.1317991 238.942271,34.7989485 C253.425485,38.4943435 261.307721,42.1223859 260.990801,56.6614959 C260.691992,70.199303 248.735079,79 230.797444,79 C222.3855,79 213.398573,76.126303 208.001889,72.9787071"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            ></path>
            <path
              d="M288.384162,2 L288.384162,46.9631453 C288.384162,63.8248829 295.529327,71.0175583 305.127576,71.0175583 C315.684759,71.0175583 322.615838,63.6017844 322.615838,46.9631453 L322.615838,2 L332,2 L332,46.2893894 C332,69.558498 320.37684,79 304.806445,79 C290.092401,79 279,70.2322538 279,46.6240365 L279,2"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            ></path>
            <polygon
              id="Path"
              fill="#000000"
              fillRule="nonzero"
              points="360 78 369 78 369 2 360 2"
            ></polygon>
            <polyline
              id="Path"
              fill="#000000"
              fillRule="nonzero"
              points="385 2 443 2 443 10.931403 418.461711 10.931403 418.461711 78 409.421525 78 409.421525 10.931403 385 10.931403"
            ></polyline>
            <g id="Path-Clipped" transform="translate(456.000000, 1.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="path-1"></g>
              <polyline
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                mask="url(#mask-2)"
                points="44.9955151 0.839494224 0.461975771 0.839494224 0.461975771 76.8204291 44.9955151 76.8204291 44.9955151 68.0573694 9.10495429 68.0573694 9.10495429 39.7569963 44.9955151 39.7569963 44.9955151 30.8502799 9.10495429 30.8502799 9.10495429 9.76865672 44.9955151 9.76865672"
              ></polyline>
            </g>
          </g>
          <g id="Group" transform="translate(161.000000, 0.000000)">
            <polyline
              id="Path"
              fill="#00467F"
              fillRule="nonzero"
              points="0 0 111 0 111 95 67.7729036 40.2182399 0 40.2182399"
            ></polyline>
            <g id="Path-Clipped" transform="translate(67.000000, 76.000000)">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g id="path-3"></g>
              <path
                d="M0.609975692,0.827547816 C14.988582,18.588326 28.7908654,36.9288085 43.0096154,54.8492147 L110.998799,54.8492147 L110.998799,95 L0.609975692,95"
                id="Path"
                fill="#00467F"
                fillRule="nonzero"
                mask="url(#mask-4)"
              ></path>
            </g>
            <polyline
              id="Path"
              fill="#B9C7D4"
              fillRule="nonzero"
              points="118 40.8139636 118 9 170 9 170 124 135.458877 124 135.458877 40.8139636"
            ></polyline>
            <path
              d="M42.7888809,48 L42.7888809,130.509591 C48.2885159,130.509591 54.3022936,130.509591 60,130.509591 L60,163 L8,163 L8,48"
              id="Path"
              fill="#B9C7D4"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
