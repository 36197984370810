import { useEffect, useState } from 'react'

import { dispatch } from '../../../store'
import { useInterval } from '../../../hooks'
import { getConnectors } from '../../../modules/connectors'

import { TimePeriods } from './Overview.types'
import {
  dateRangeToGranularityMap,
  getDateRangeByTimePeriod,
} from './Overview.utils'

const FIVE_SECONDS_MS = 5 * 1000 // convert seconds to MS

const TIME_PERIOD_OPTIONS: Array<{ key: TimePeriods; value: string }> = [
  { key: TimePeriods.yesterday, value: 'Yesterday' },
  { key: TimePeriods.lastWeek, value: 'Last Week' },
  { key: TimePeriods.lastMonth, value: 'Last Month' },
]

export const useOverview = () => {
  const { key: initialKey, value: initialValue } = TIME_PERIOD_OPTIONS[1]
  const [timePeriod, setTimePeriod] = useState(initialValue)
  const [dateRange, setDateRange] = useState(
    getDateRangeByTimePeriod(initialKey),
  )
  const [granularity, setGranularity] = useState(
    dateRangeToGranularityMap[initialKey],
  )

  // Initial Fetch
  useEffect(() => {
    dispatch(getConnectors())
  }, [])

  // Interval calls to show updated state of connections
  useInterval(() => {
    dispatch(getConnectors())
  }, FIVE_SECONDS_MS)

  const handleTimePeriodSelect = (key: TimePeriods, value: string) => {
    setDateRange(getDateRangeByTimePeriod(key))
    setGranularity(dateRangeToGranularityMap[key])
    setTimePeriod(value)
  }

  return {
    dateRange,
    duration: 7,
    granularity,
    handleTimePeriodSelect,
    timePeriodOptions: TIME_PERIOD_OPTIONS,
    timePeriod,
  }
}
