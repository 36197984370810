import React from 'react'
import Grid from '@mui/material/Grid'
import { Layout } from '../../../layout'
import { connect } from 'react-redux'
import {
  selectAccountConnectors,
  selectBankingConnectors,
  selectExpenseConnectors,
  selectRevenueConnectors,
} from '../../../modules/connectors'

import { Store } from '../../../modules/rootReducer'

import { OverviewMarketingCard } from './components/OverviewMarketingCard/OverviewMarketingCard'
import { OverviewBankingCard } from './components/OverviewBankingCard/OverviewBankingCard'
import { Color } from '../../../Color'
import { useOverview } from './Overview.hooks'
import { OverviewCardTypes, OverviewProps } from './Overview.types'
import { OverviewCustomerValueCard } from './components/OverviewCustomerValueCard/OverviewCustomerValueCard'
import { OverviewCreditScoreCard } from './components/OverviewCreditScoreCard/OverviewCreditScoreCard'
import { OverviewRevenueCard } from './components/OverviewRevenueCard/OverviewRevenueCard'
import { OverviewNewCustomerCard } from './components/OverviewNewCustomerCard/OverviewNewCustomerCard'
import { OverviewHighlightBar } from './components/OverviewHighlightBar/OverviewHighlightBar'
import { OverviewHeadingSection } from './components/OverviewHeadingSection/OverviewHeadingSection'

const OverviewImpl: React.FunctionComponent<OverviewProps> = ({
  accountConnectors,
  bankingConnectors,
  expenseConnectors,
  revenueConnections,
}) => {
  const {
    dateRange,
    duration,
    granularity,
    handleTimePeriodSelect,
    timePeriod,
    timePeriodOptions,
  } = useOverview()
  return (
    <Layout background={Color.LightestGrey}>
      <OverviewHeadingSection
        bankingConnections={bankingConnectors}
        dateRange={dateRange}
        expenseConnections={expenseConnectors}
        revenueConnections={revenueConnections}
        handleTimePeriodSelect={handleTimePeriodSelect}
        timePeriod={timePeriod}
        timePeriodOptions={timePeriodOptions}
      />
      <Grid container spacing={2}>
        <OverviewHighlightBar duration={duration} granularity={granularity} />
        <OverviewBankingCard duration={duration} granularity={granularity} />
        <OverviewCreditScoreCard accountConnections={accountConnectors} />
        <OverviewRevenueCard
          cardType={OverviewCardTypes.OrderRevenue}
          duration={duration}
          granularity={granularity}
          revenueConnections={revenueConnections}
        />
        <OverviewMarketingCard
          duration={duration}
          granularity={granularity}
          expenseConnections={expenseConnectors}
        />

        <OverviewCustomerValueCard
          expenseConnections={expenseConnectors}
          revenueConnections={revenueConnections}
        />
        <OverviewNewCustomerCard
          duration={duration}
          granularity={granularity}
        />
        <OverviewRevenueCard
          cardType={OverviewCardTypes.AOV}
          duration={duration}
          granularity={granularity}
          revenueConnections={revenueConnections}
        />
        <OverviewRevenueCard
          cardType={OverviewCardTypes.OrderVolume}
          duration={duration}
          granularity={granularity}
          revenueConnections={revenueConnections}
        />
        <OverviewRevenueCard
          cardType={OverviewCardTypes.Units}
          duration={duration}
          granularity={granularity}
          revenueConnections={revenueConnections}
        />
      </Grid>
    </Layout>
  )
}

const mapStateToProps = (store: Store): OverviewProps => {
  return {
    revenueConnections: selectRevenueConnectors(store.connectors),
    expenseConnectors: selectExpenseConnectors(store.connectors),
    accountConnectors: selectAccountConnectors(store.connectors),
    bankingConnectors: selectBankingConnectors(store.connectors),
  }
}

export const OverviewScreen = connect(mapStateToProps)(OverviewImpl)
