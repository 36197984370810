import { store } from '../../store'
import {
  Quarter,
  QuarterCalculator,
} from '../../../server/util/quarterCalculator'
import {
  QuarterlyCompanyGoalResult,
  QuarterlyCompanyGoalToCreate,
  RelativeCompanyGoalSerialized,
} from '../../../server/services/companyGoals'
import { RelativePeriod } from '../../../server/util/relativePeriod'
import {
  CompanyGoalDataUnit,
  CompanyGoalPeriod,
  CompanyGoalType,
} from '../../../server/data/models/types'

import {
  RelativeCompanyGoalValues,
  RelativePeriodMap,
  RelativeQuarterMap,
} from './types'

/**
 * Maps goal types to units.
 */
export const GoalUnitMap: {
  [goalType in CompanyGoalType]: CompanyGoalDataUnit
} = {
  revenue: CompanyGoalDataUnit.CENTS,
  adSpend: CompanyGoalDataUnit.CENTS,
  roas: CompanyGoalDataUnit.MULTIPLIER,
}

/**
 * Gets relative quarter map.
 */
export const getRelativeQuarterMap = (now: Date): RelativeQuarterMap => {
  const currentQuarter = QuarterCalculator.getQuarterFromDate(now)
  return {
    [RelativePeriod.current]: currentQuarter,
    [RelativePeriod.next]: QuarterCalculator.getNextQuarter(currentQuarter),
    [RelativePeriod.previous]: QuarterCalculator.getPreviousQuarter(
      currentQuarter,
    ),
  }
}

/**
 * Gets relative period map.
 */
export const getRelativePeriodMap = (): RelativePeriodMap => {
  const now = new Date()
  return {
    quarter: getRelativeQuarterMap(now),
  }
}

/**
 * Gets relative quarterly goals.
 */
export const getRelativeQuarterlyGoals = (
  quarterlyGoals: QuarterlyCompanyGoalResult,
  relativeQuarterMap: RelativeQuarterMap,
): RelativeCompanyGoalSerialized => {
  const relativeQuarterlyGoals: RelativeCompanyGoalSerialized = {}
  if (!quarterlyGoals) {
    return relativeQuarterlyGoals
  }
  const relativeQuarters: Array<
    Quarter & {
      name: RelativePeriod
    }
  > = [
    {
      name: RelativePeriod.previous,
      ...relativeQuarterMap[RelativePeriod.previous],
    },
    {
      name: RelativePeriod.current,
      ...relativeQuarterMap[RelativePeriod.current],
    },
    {
      name: RelativePeriod.next,
      ...relativeQuarterMap[RelativePeriod.next],
    },
  ]
  Object.entries(quarterlyGoals).forEach(([goalType, goalQuarters]) => {
    goalQuarters.forEach(({ quarter, goal, qtd }) => {
      const relativeQuarter = relativeQuarters.find(
        relativeQuarter =>
          relativeQuarter.year === quarter.year &&
          relativeQuarter.quarter === quarter.quarter,
      )
      if (relativeQuarter) {
        relativeQuarterlyGoals[relativeQuarter.name] = {
          quarter,
          goals: {
            ...relativeQuarterlyGoals[relativeQuarter.name]?.goals,
            [goalType]: goal,
          },
          progress: {
            ...relativeQuarterlyGoals[relativeQuarter.name]?.progress,
            [goalType]: qtd?.value ?? 0,
          },
        }
      }
    })
  })
  return relativeQuarterlyGoals
}

/**
 * Gets quarterly goals to update.
 */
export const getQuarterlyGoalsUpdate = ({
  goals,
  relativePeriodMap,
}: {
  goals: RelativeCompanyGoalValues
  relativePeriodMap: RelativePeriodMap
}): QuarterlyCompanyGoalToCreate[] => {
  const companyGoals: QuarterlyCompanyGoalToCreate[] = []
  Object.entries(goals).forEach(([relativePeriod, periodGoals]) => {
    Object.entries(periodGoals).forEach(([goalType, { value, hasChanged }]) => {
      if (hasChanged) {
        companyGoals.push({
          companyId: store.getState().companies.data.id,
          period: CompanyGoalPeriod.quarterly,
          quarter: relativePeriodMap.quarter[relativePeriod as RelativePeriod],
          type: goalType as CompanyGoalType,
          data: {
            value,
            unit: GoalUnitMap[goalType as CompanyGoalType],
          },
        })
      }
    })
  })
  return companyGoals
}
