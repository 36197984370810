import { useState } from 'react'
import { api } from '../lib/api'

export type ApiMethods = 'get' | 'post' | 'put' | 'delete'

const useApiRequest = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const makeRequest = async (
    url: string,
    method: ApiMethods,
    data: {} | null = null,
  ) => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await api[method](url, data)
      setIsLoading(false)
      return response
    } catch (error: any) {
      setIsLoading(false)
      setError(error)
    }
  }

  return {
    isLoading,
    error,
    makeRequest,
  }
}

export default useApiRequest
