import { api } from '../lib/api'
import React, { useEffect, useMemo, useState } from 'react'
import { createCreditScoreRequest } from '../modules/credit/creditScoreRequest'
import { dispatch } from '../store'

export interface CreditScore {
  companyId?: string
  creditScore?: number
  scoreStatus?: ScoreStatus
  liquidity?: number
  ability?: number
  embedded?: number
  isActive?: boolean
  notificationDate?: Date
}
export enum ScoreStatus {
  MissingConnections = 'MissingConnections',
  NotRequested = 'NotRequested',
  Requested = 'Requested',
  HasCreditScore = 'HasCreditScore',
  Undefined = 'Undefined',
}
export interface CreditScoreResults {
  errorMessages: string[]
  results?: CreditScore
}

export const useCreditScore = (): {
  creditScore: CreditScore
  creditScoreRequest: () => void
} => {
  const emptyScore: CreditScore = useMemo(() => {
    return {
      creditScore: undefined,
      scoreStatus: ScoreStatus.Undefined,
    }
  }, [])

  const [creditScore, setCreditScore] = useState(emptyScore)

  const fetchCreditScore = React.useCallback(async (): Promise<CreditScore> => {
    try {
      const result: CreditScoreResults = await api.get(`/api/credit/score`)
      const response = result?.results
      if (response) {
        return { ...response }
      }
      return emptyScore
    } catch (err) {
      console.error(err)
      return emptyScore
    }
  }, [emptyScore])
  useEffect(() => {
    const getCreditScore = async (): Promise<void> => {
      const score = await fetchCreditScore()
      setCreditScore(score)
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getCreditScore().catch(() => {})
  }, [fetchCreditScore])

  return React.useMemo(() => {
    const creditScoreRequest = (): void => {
      setCreditScore({ ...creditScore, scoreStatus: ScoreStatus.Requested })
      dispatch(createCreditScoreRequest())
    }
    return {
      creditScore,
      creditScoreRequest,
    }
  }, [creditScore])
}
