import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ShopifyIcon } from '../../../../../../components/designSystem/assets/providers/ShopifyIcon/ShopifyIcon'
import { AmazonIcon } from '../../../../../../components/designSystem/assets/providers/AmazonIcon/AmazonIcon'
import { Color } from '../../../../../../Color'

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  margin: '10px 0',
})

const LogoIconWrapper = styled(Box)({
  display: 'flex',
  padding: '5px',
  borderRadius: '4px',
  backgroundColor: Color.White,
})

const REVENUE_CONNECTION_DESCRIPTIONS: {
  title: string
  icon: JSX.Element
  descriptions: string[]
}[] = [
  {
    title: 'Shopify',
    icon: <ShopifyIcon height={20} width={20} />,
    descriptions: [
      'Refunds, including taxes/shipping refunds, are not included',
      'Cancelled & test orders are included',
      'Revenue from gift cards may be double counted',
    ],
  },
  {
    title: 'Amazon',
    icon: <AmazonIcon height={20} width={20} />,
    descriptions: [
      'Refunds, including taxes/shipping refunds, are not included',
    ],
  },
]

export const OverviewOrderRevenueTooltip = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '10px 20px 20px 20px',
    }}
  >
    {REVENUE_CONNECTION_DESCRIPTIONS.map(({ title, icon, descriptions }) => (
      <>
        <LogoContainer>
          <LogoIconWrapper>{icon}</LogoIconWrapper>
          <Typography variant="h7">{title}</Typography>
        </LogoContainer>
        <Typography variant="subtitle2">
          Gross Sales - Discounts + Shipping + Tax
        </Typography>
        {descriptions.map((description, i) => (
          <Typography variant="body1" key={`${title}-description-${i}`}>
            {description}
          </Typography>
        ))}
      </>
    ))}
  </Box>
)
