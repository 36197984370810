import React from 'react'
import { connect } from 'react-redux'

import { useDispatchApiCall } from '../../../hooks'
import { CompanyFeaturesPayload } from '../../../modules/companyFeatures'
import { Connection } from '../../../modules/connectors/types'
import { Store } from '../../../modules/rootReducer'
import {
  getConnectors,
  selectBankingConnectors,
} from '../../../modules/connectors'
import { hasCashPositionFeature } from '../../../util/companyFeatures'
import { CashPositionProvider } from './CashPositionProvider'
import { CashPositionWrapper } from './components/CashPositionWrapper/CashPositionWrapper'

type Props = {
  bankingConnections: Connection[]
  companyFeatures: CompanyFeaturesPayload
}

const CashPositionImpl = ({
  bankingConnections,
  companyFeatures,
}: Props): JSX.Element => {
  useDispatchApiCall([getConnectors])

  const hasBankingConnection = !!bankingConnections.length

  const isSynced = bankingConnections.some(
    ({ initialSyncStatus }) => initialSyncStatus === 'complete',
  )

  return (
    <CashPositionProvider>
      <CashPositionWrapper
        hasBankingConnection={hasBankingConnection}
        hasFeatureAccess={hasCashPositionFeature(companyFeatures)}
        isSynced={isSynced}
      />
    </CashPositionProvider>
  )
}

const mapStateToProps = (store: Store): Props => {
  return {
    bankingConnections: selectBankingConnectors(store.connectors),
    companyFeatures: store.companyFeatures.companyFeatures,
  }
}

export const CashPositionScreen = connect(mapStateToProps)(CashPositionImpl)
