import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getCreditScoreRequest } from './creditScoreRequest'
import { CreditScoreRequestResponse } from '../../../server/services/creditScore/'

const CREDIT_SCORE_REQUEST_KEY = 'creditScoreRequest'

export type CreditScoreRequestPayload = CreditScoreRequestResponse

// REDUCERS
type CreditScoreRequestStoreData = {
  [CREDIT_SCORE_REQUEST_KEY]: CreditScoreRequestPayload
}

export type CreditScoreRequestData = Readonly<CreditScoreRequestStoreData>

const initialCreditScoreRequestState: CreditScoreRequestData = {
  [CREDIT_SCORE_REQUEST_KEY]: {
    [CREDIT_SCORE_REQUEST_KEY]: null,
    errorMessages: [],
  },
}

export const creditScoreRequestReducer = createReducer(
  initialCreditScoreRequestState,
  builder => {
    // GET CREDIT_SCORE_REQUEST
    createThunkReducer(
      builder,
      getCreditScoreRequest.typePrefix,
      CREDIT_SCORE_REQUEST_KEY,
      (state, action) =>
        ({
          ...state,
          [CREDIT_SCORE_REQUEST_KEY]: {
            ...state[CREDIT_SCORE_REQUEST_KEY],
            [CREDIT_SCORE_REQUEST_KEY]:
              action?.payload?.[CREDIT_SCORE_REQUEST_KEY],
          },
        } as CreditScoreRequestData),
    )
  },
)
