import React, { useCallback, useState } from 'react'

import TextField from '@mui/material/TextField'

import { GridRowId } from '@mui/x-data-grid-pro'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { EditSaveButton } from '../../../components/designSystem/atoms/EditSaveButton/EditSaveButton'
import { dispatch } from '../../../store'
import { upsertProductSegmentMappings } from '../../../modules/adminDashboard/productDemand/productSegmentActions'

import { ProductSegmentData } from '../../../../server/services/productSegmentDashboard/ProductSegmentService'

export const SelectSegmentAndQuantity = ({
  selectedRowIds,
  productSegments,
  companyId,
}: {
  selectedRowIds: GridRowId[]
  productSegments: ProductSegmentData[]
  companyId: string
}): JSX.Element => {
  const [selectedSegment, setSelectedSegment] = useState('')

  const [quantity, setQuantity] = useState<number>(1)

  const onSave = useCallback(() => {
    const selectedSegmentId = productSegments.find(
      ({ segmentName }) => segmentName === selectedSegment,
    )?.id

    if (selectedSegmentId) {
      const updateVals = selectedRowIds.map(productId => ({
        productId: productId as string,
        customerSegmentId: selectedSegmentId,
        quantity,
      }))

      dispatch(
        upsertProductSegmentMappings({ mappings: updateVals, companyId }),
      )

      setSelectedSegment('')
      setQuantity(1)
    }
  }, [productSegments, selectedSegment, selectedRowIds, companyId, quantity])

  return (
    <div style={{ display: 'flex' }}>
      <FormControl required sx={{ m: 1, width: 200 }}>
        <p>Product Segment</p>
        <Select
          labelId="main category"
          value={selectedSegment}
          onChange={(event: SelectChangeEvent): void => {
            const seg = event.target.value
            setSelectedSegment(seg)
          }}
        >
          {productSegments.map((segment, i) => (
            <MenuItem key={i} value={segment.segmentName}>
              {segment.segmentName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl required sx={{ m: 1, width: 200 }}>
        <p>Quantity</p>
        <TextField
          value={quantity}
          error={quantity < 1}
          helperText={quantity < 1 ? 'Quantity must be at least 1' : null}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ): void => {
            const valueString = event?.target?.value
            if (typeof valueString !== 'string') {
              return
            }
            const numericValue = Number(valueString.replace(/,/g, ''))
            if (isNaN(numericValue)) {
              return
            }
            setQuantity(numericValue)
          }}
          inputProps={{ min: 1, inputMode: 'numeric' }}
        />
      </FormControl>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingBottom: '12px',
          marginLeft: '8px',
        }}
      >
        <EditSaveButton
          isEditing={true}
          onEditSave={onSave}
          disabled={
            !(quantity > 0 && selectedRowIds.length > 0 && !!selectedSegment)
          }
        />
      </div>
    </div>
  )
}
