import { DateGranularity } from '../../../../server/app-data-service/generatedTypes'

export const TimePeriodFilterOptions: {
  granularity: DateGranularity
  granularityLabel: string
  defaultDuration: number
  minDuration: number
  maxDuration: number
  isDefaultGranularity?: boolean
}[] = [
  {
    granularity: 'Week',
    granularityLabel: 'Weeks',
    defaultDuration: 13,
    minDuration: 1,
    maxDuration: 13,
    isDefaultGranularity: true,
  },
  {
    granularity: 'Month',
    granularityLabel: 'Months',
    defaultDuration: 6,
    minDuration: 3,
    maxDuration: 12,
  },
]
