import { useState } from 'react'
import { dispatch } from '../store'
import { updateCompanyPlanType } from '../modules/adminDashboard/adminDashboard'
import { COMPANY_PLAN_TYPE_OPTIONS } from '../screens/admin/types'

export const usePlanType = (
  planType: string,
): {
  planTypes: string[]
  selectedPlanType: string
  updatePlanType: (companyId: string, planType: string) => void
} => {
  const [selectedPlanType, setPlanType] = useState(planType)

  const updatePlanType = (companyId: string, planType: string): void => {
    setPlanType(planType)
    dispatch(
      updateCompanyPlanType({
        companyId: companyId,
        planType: planType,
      }),
    )
  }

  return {
    planTypes: COMPANY_PLAN_TYPE_OPTIONS,
    selectedPlanType,
    updatePlanType,
  }
}
