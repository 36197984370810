import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { HeroStripes } from '../../assets/HeroStripes/HeroStripes'
import { Color } from '../../../../Color'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import useLocalStorage from '../../../../hooks/useLocalStorage'

export type HeroHeaderProps = {
  title: string
  description: string
  buttonText?: string
  buttonLink?: string
  localStorageKey: string
}

export const HeroHeader: React.FunctionComponent<HeroHeaderProps> = ({
  title,
  description,
  buttonText,
  buttonLink,
  localStorageKey,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [heroHeaderState, setHeroHeaderState] = useLocalStorage(
    localStorageKey,
    'expanded',
  )
  const [shouldShowBanner, setShouldShowBanner] = useState<boolean>(
    heroHeaderState === 'expanded',
  )

  const iconProps = {
    color: Color.White,
    background: Color.BlackOpaque,
    borderRadius: '12px',
    width: { xs: 12, lg: 20 },
    height: { xs: 12, lg: 20 },
  }
  return (
    <Box
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        position: 'relative',
        display: 'flex',
        height: shouldShowBanner ? { xs: 153, lg: 216 } : { xs: 38, lg: 70 },
        background: `radial-gradient(147.93% 134.95% at 17.37% -10.88%, #18003F 36.7%, #251F75 77.24%, #3224AA 100%);`,
        borderRadius: '4px',
      }}
    >
      <IconButton
        onClick={() => {
          if (heroHeaderState === 'collapsed') {
            setHeroHeaderState('expanded')
          } else {
            setHeroHeaderState('collapsed')
          }
          setShouldShowBanner(!shouldShowBanner)
        }}
        sx={{
          height: { xs: 12, lg: 20 },
          width: { xs: 12, lg: 20 },
          position: 'absolute',
          top: { xs: 10, lg: 20 },
          right: { xs: 10, lg: 25 },
        }}
      >
        {shouldShowBanner ? (
          <CloseIcon sx={iconProps} />
        ) : (
          <ExpandMoreIcon sx={iconProps} />
        )}
      </IconButton>
      {shouldShowBanner ? (
        <>
          <Stack
            sx={{
              position: 'absolute',
              left: { xs: 24, lg: 32 },
              top: { xs: 27, lg: 39 },
              maxWidth: { xs: '80%', sm: '48%' },
            }}
          >
            <Typography
              variant={isMobile ? 'subtitle2' : 'h5'}
              sx={{ color: Color.White }}
            >
              {title}
            </Typography>
            <Typography
              variant={isMobile ? 'body2' : 'subtitle2'}
              sx={{ fontWeight: 300, marginTop: '4px', color: Color.White }}
            >
              {description}
            </Typography>
            {buttonText && (
              <Button
                sx={{
                  background: Color.NavyBlue,
                  color: Color.White,
                  width: { xs: 97, md: 138 },
                  height: { xs: 30, md: 42 },
                  marginTop: { xs: '12px', lg: '16px' },
                  '&:hover': {
                    background: Color.NavyBlue,
                  },
                }}
                href={buttonLink}
              >
                <Typography variant={isMobile ? 'body1' : 'subtitle2'}>
                  {buttonText}
                </Typography>
              </Button>
            )}
          </Stack>
          <Box
            sx={{
              width: { xs: 278, lg: 950 },
              height: { xs: '60%', lg: 216 },
            }}
          >
            <HeroStripes height={'100%'} width={'100%'} />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              left: { xs: 24, lg: 32 },
              color: Color.White,
            }}
            variant={isMobile ? 'subtitle2' : 'h5'}
          >
            {title}
          </Typography>
          <Box sx={{ height: { xs: 38, lg: 70 }, overflow: 'hidden' }}>
            <Box
              sx={{
                width: { xs: 278, lg: 950 },
                height: { xs: 0, lg: 216 },
              }}
            >
              <HeroStripes
                height={'50%'}
                width={'100%'}
                viewBox="0 150 50 125"
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
