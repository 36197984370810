import React from 'react'

import { InformativeTable } from '../../../../../components/designSystem/organisms/InteractiveTable/InformativeTable'

import { numberAsCommaDelimitiedMoney } from '../../../../../util/displayValue'
import { Color } from '../../../../../Color'

import { useCashPosition } from '../../CashPositionProvider'
import { CashPositionTableCell } from '../CashPositionTableCell/CashPositionTableCell'
import { TableTooltips } from './CashPositionTable.consts'
import { granularityToTableHeaderMap } from '../../CashPosition.consts'

export const CashPositionTable = (): JSX.Element => {
  const { apiRef, tableColumns, tableRows, granularity } = useCashPosition()

  return (
    <InformativeTable
      apiRef={apiRef}
      treeDataPath="category"
      headerName={granularityToTableHeaderMap[granularity]}
      columns={tableColumns || []}
      rows={tableRows || []}
      renderCustomCellValue={(params): JSX.Element => {
        const { rowContext, value, field } = params
        return (
          <CashPositionTableCell
            categoryId={rowContext.categoryId}
            headerClassName={rowContext.headerClassName}
            merchantName={rowContext.merchantName}
            renderValue={value}
            startDate={field}
            subCategories={rowContext.subCategories}
          />
        )
      }}
      formatCellValue={numberAsCommaDelimitiedMoney}
      tooltips={TableTooltips}
      headerStyles={{
        '.row-level-parent, .MuiDataGrid-row.row-level-parent:hover, .row-level-parent.Mui-hovered': {
          fontWeight: 'bold',
          background: Color.Grey2,
          color: Color.DarkerPurple,
        },
        '.row-level-ending, .MuiDataGrid-row.row-level-ending:hover, .row-level-ending.Mui-hovered': {
          fontWeight: 'bold',
          background: Color.Grey2,
        },
        '.row-level-cash-outflow, .MuiDataGrid-row.row-level-cash-outflow:hover, .row-level-cash-outflow.Mui-hovered': {
          fontWeight: 'bold',
          background: Color.PeachWithOpacity,
        },
        '.row-parent.row-level-cash-inflow ~ .row-child.MuiDataGrid-row.child-expanded': {
          background: Color.PearWithOpacity,
        },
        '.row-parent.row-level-cash-outflow ~ .row-child.MuiDataGrid-row.child-expanded': {
          background: Color.PeachWithOpacity,
        },
        '.row-level-cash-inflow, .MuiDataGrid-row.row-level-cash-inflow:hover, .row-level-cash-inflow.Mui-hovered': {
          fontWeight: 'bold',
          background: Color.PearWithOpacity,
        },
        '.row-level-cash-change, .MuiDataGrid-row.row-level-cash-change:hover, .row-level-cash-change.Mui-hovered': {
          fontWeight: 'bold',
          color: Color.DarkerPurple,
          background: Color.Grey1,
        },
      }}
    />
  )
}
