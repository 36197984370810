export const enum CampaignRoute {
  Home = 'Home',
}

interface RouteMap {
  [prop: string]: string
}

const OnboardingCreditScoreRoutes: RouteMap = {
  [CampaignRoute.Home]: '/get-started/credit-score',
}

const DefaultRoutes: RouteMap = {
  [CampaignRoute.Home]: '/users/home',
}

const CAMPAIGN_PARAM = 'utm_campaign'
const CREDIT_SCORE = 'Credit score'

const upsertQueryCampaignParam = (): string | null => {
  const searchParams = new URLSearchParams(window.location.search)
  const { localStorage } = window
  const param = searchParams.get(CAMPAIGN_PARAM)
  if (param) {
    localStorage.setItem(CAMPAIGN_PARAM, param)
    return param
  } else {
    return localStorage.getItem(CAMPAIGN_PARAM) || null
  }
}

export const useNavigationCampaign = (): ((route: CampaignRoute) => void) => {
  return (route: CampaignRoute): void => {
    const campaign = upsertQueryCampaignParam()
    let routePath: string
    switch (campaign) {
      case CREDIT_SCORE:
        routePath = OnboardingCreditScoreRoutes[route]
        break
      default:
        routePath = DefaultRoutes[route]
        break
    }
    window.location.replace(window.origin + routePath)
  }
}

export const useConnectionsNavigation = () => {
  return () => {
    const campaign = upsertQueryCampaignParam()
    switch (campaign) {
      case CREDIT_SCORE:
        // We can validate in here if the required path to send the user is the step #2 of onboarding
        window.location.replace(
          `${window.origin}${OnboardingCreditScoreRoutes[CampaignRoute.Home]}`,
        )
        break
      default:
        break
    }
  }
}

export const removeCampaignTracking = (): void => {
  if (!window) {
    return
  }
  const { localStorage } = window
  localStorage.removeItem(CAMPAIGN_PARAM)
}
