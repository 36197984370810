import React from 'react'

import { RevenueIndicators } from './RevenueIndicators'
import { GetRevenueResults } from '../../../../server/routes/api/reports/index'
import { AggregateType, average, sum } from './shared'

export type LatestValueType = {
  averageOrderValueCents: number | null
  orderVolume: number | null
  revenueCents: number | null
  unitVolume: number | null
}[]

function getLatestValues({
  categories,
}: GetRevenueResults): { [key in AggregateType]: number | null } {
  const latestValuesByCategory: LatestValueType = Object.entries(
    categories,
  ).map(
    ([
      ,
      { averageOrderValueCents, orderVolume, revenueCents, unitVolume },
    ]) => ({
      averageOrderValueCents: averageOrderValueCents.slice(-1)[0] ?? null,
      orderVolume: orderVolume.slice(-1)[0] ?? null,
      revenueCents: revenueCents.slice(-1)[0] ?? null,
      unitVolume: unitVolume.slice(-1)[0] ?? null,
    }),
  )

  return (
    latestValuesByCategory.reduce((a, b) => {
      return {
        averageOrderValueCents: average(
          a?.averageOrderValueCents,
          b?.averageOrderValueCents,
        ),
        orderVolume: sum(a?.orderVolume, b?.orderVolume),
        revenueCents: sum(a?.revenueCents, b?.revenueCents),
        unitVolume: sum(a?.unitVolume, b?.unitVolume),
      }
    }, latestValuesByCategory.pop()) || {
      averageOrderValueCents: null,
      orderVolume: null,
      revenueCents: null,
      unitVolume: null,
    }
  )
}

export const CurrentRevenue: React.FunctionComponent<{
  interval: string
  data: GetRevenueResults
}> = ({ interval, data }) => {
  const {
    revenueCents,
    unitVolume,
    orderVolume,
    averageOrderValueCents,
  } = getLatestValues(data)

  return !orderVolume ? (
    <> </>
  ) : (
    <>
      <RevenueIndicators
        interval={interval}
        revenueCents={revenueCents}
        unitVolume={unitVolume}
        orderVolume={orderVolume}
        averageOrderValueCents={averageOrderValueCents}
      />
    </>
  )
}
