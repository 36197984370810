import React, { useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Color } from '../../../Color'

import {
  GridColumns,
  GridRowId,
  GridSelectionModel,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import Typography from '@mui/material/Typography'
import { numberAsMoney } from '../../../util/displayValue'
import { ProductSegmentMappingResult } from '../../../../server/routes/admin/productDemand/productSegments'
import { dispatch } from '../../../store'
import {
  createProductSegments,
  triggerParameterOptimization,
} from '../../../modules/adminDashboard/productDemand/productSegmentActions'

import { PageLoading } from '../../../components/designSystem/atoms/Loading/Loading'
import { BasicModal } from '../../../components/designSystem/organisms/BasicModal/BasicModal'
import { ProductSegmentData } from '../../../../server/services/productSegmentDashboard/ProductSegmentService'
import { ProductAggregateInfoWithCustomerSegment } from '../../../../server/data/models/sync/ProductAggregate'

import { SegmentCard } from './ProductSegmentCard'
import { ProductSegmentDetail } from './ProductSegmentDetail'
import { SelectSegmentAndQuantity } from './SelectSegmentAndQuantity'

import { AdminDataGrid } from '../AdminDataGrid'

const columns: GridColumns = [
  {
    field: 'channelName',
    headerName: 'Store front',
    width: 100,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.channelName,
  },
  {
    field: 'productId',
    headerName: 'productId',
  },
  {
    field: 'name',
    headerName: 'Product name',
    width: 400,
  },
  {
    field: 'oneYearCumulativeRevenue',
    headerName: 'Cumulative Revenue within 12 months',
    width: 150,
    valueFormatter: (params: GridValueFormatterParams): string =>
      numberAsMoney(params.value),
  },
  {
    field: 'unitVolume',
    headerName: '12 Month sales volume',
    width: 150,
  },
  {
    field: 'customerSegments',
    headerName: 'Product segments',
    width: 400,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.customerSegments
        .map(
          ({
            segmentName,
            quantity,
          }: {
            segmentName: string
            quantity: number
          }) => `${segmentName} ${quantity > 1 ? `(${quantity})` : ''}`,
        )
        .join(', '),
  },
  {
    field: 'hasMoreThanOneYearData',
    headerName: 'Has full year of sales',
    width: 100,
    type: 'boolean',
  },
  {
    field: 'firstSaleDate',
    headerName: 'First Sale Date',
    width: 100,
  },
  {
    field: 'lastSaleDate',
    headerName: 'Last Sale Date',
    width: 100,
  },

  {
    field: 'hasBundle',
    headerName: 'Has Bundle',
    width: 100,
    type: 'boolean',
  },
]

type Props = {
  productSegmentResults: ProductSegmentMappingResult | null
  companyId: string
}

const getRows = (
  productSegmentsMapping: ProductAggregateInfoWithCustomerSegment[],
): (ProductAggregateInfoWithCustomerSegment & {
  id: string
  hasBundle: boolean
})[] => {
  const mappings = productSegmentsMapping.map(
    ({
      productId,
      customerSegments,
      firstSaleDate,
      lastSaleDate,
      ...rest
    }) => ({
      id: productId,
      productId,
      customerSegments,
      hasBundle: customerSegments.length > 1,
      firstSaleDate: firstSaleDate
        ? format(new Date(firstSaleDate), 'MM-dd-yyyy')
        : 'N/A',
      lastSaleDate: lastSaleDate
        ? format(new Date(lastSaleDate), 'MM-dd-yyyy')
        : 'N/A',
      ...rest,
    }),
  )

  return mappings
}

const NewSegmentSection = ({
  open,
  companyId,
}: {
  open: boolean
  companyId: string
}): JSX.Element | null => {
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  if (open) {
    return (
      <div style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}>
        <TextField
          label={'Create a new segment name'}
          helperText={error ? "Cannot contain '/' character" : null}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ): void => {
            const valueString = event?.target?.value
            if (typeof valueString !== 'string') {
              return
            }
            if (/\//.test(valueString)) setError(true)
            else if (error) setError(false)
            setValue(valueString)
          }}
          value={value}
        />
        <Button
          onClick={(): void => {
            dispatch(createProductSegments({ segmentName: value, companyId }))
            setValue('')
          }}
          variant="contained"
          disabled={error || !value}
          sx={{
            marginLeft: '8px',
            width: '150px',
            height: '44px',
            fontSize: '14px',
          }}
        >
          Save
        </Button>
      </div>
    )
  }
  return null
}

const CreateNewSection = ({
  companyId,
}: {
  companyId: string
}): JSX.Element => {
  const [createNewOpen, setCreateNewOpen] = useState(false)

  return (
    <div>
      <Button
        onClick={(): void => setCreateNewOpen(!createNewOpen)}
        variant="contained"
      >
        {createNewOpen ? 'Close' : 'Create a new segment'}
      </Button>
      <NewSegmentSection open={createNewOpen} companyId={companyId} />
    </div>
  )
}

const AllSegmentsSection = ({
  productSegments,
}: {
  productSegments: ProductSegmentData[]
}): JSX.Element => (
  <div style={{ margin: '8px 8px 8px 0px' }}>
    <Typography variant="h6">Your Product Segments</Typography>
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: '128px',
        overflow: 'scroll',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }}
    >
      {productSegments.map(seg => {
        return (
          <SegmentCard
            key={seg.id}
            segmentName={seg.segmentName}
            segmentId={seg.id}
          />
        )
      })}
    </div>
  </div>
)

const SetProductMappingSection = ({
  selectedRowIds,
  productSegments,
  companyId,
}: {
  selectedRowIds: GridRowId[]
  productSegments: ProductSegmentData[]
  companyId: string
}): JSX.Element => {
  return (
    <div style={{ display: 'flex', margin: '24px 24px 24px 0px' }}>
      <div>
        <div>{`Choose a segment for ${selectedRowIds.length} selected products`}</div>
        <SelectSegmentAndQuantity
          productSegments={productSegments}
          companyId={companyId}
          selectedRowIds={selectedRowIds}
        />
      </div>
    </div>
  )
}

const allSegmentsHave12Months = (
  rows: (ProductAggregateInfoWithCustomerSegment & {
    id: string
    hasBundle: boolean
  })[],
  productSegments: ProductSegmentData[],
): Record<string, boolean> => {
  const segmentNameMap: Record<string, boolean> = {}
  const segmentToProductMap: Record<
    string,
    (ProductAggregateInfoWithCustomerSegment & {
      id: string
      hasBundle: boolean
    })[]
  > = productSegments.reduce<{ [key: string]: [] }>(
    (accum, { segmentName }) => {
      accum[segmentName] = []
      return accum
    },
    {},
  )

  for (const ob of rows) {
    const { customerSegments, ...rest } = ob
    customerSegments.forEach(({ segmentName }) => {
      if (segmentName && segmentToProductMap[segmentName]) {
        segmentToProductMap[segmentName].push(ob)
      }
    })
  }

  for (const [segment, productsInSegment] of Object.entries(
    segmentToProductMap,
  )) {
    // at least one product in the product segment has to
    // have 12 months of data
    const atLeastOneWith12Months = productsInSegment.some(
      ({ hasMoreThanOneYearData }) => hasMoreThanOneYearData,
    )
    segmentNameMap[segment] = atLeastOneWith12Months
  }

  return segmentNameMap
}

export const ProductSegmentsGrid = ({
  productSegmentResults,
  companyId,
}: Props): JSX.Element | null => {
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([])

  const [optimizeClicked, setOptimizedClicked] = useState<boolean>(false)
  const [canOptimize, setCanOptimize] = useState<boolean>(false)
  // state var to keep track of which segments don't have enough data
  const [incompleteSegments, setIncompleteSegments] = useState<string[]>([])

  const handleOptimizeClosed = useCallback(() => {
    setOptimizedClicked(false)
  }, [])

  const handleTriggerOptimization = useCallback(async () => {
    dispatch(triggerParameterOptimization({ companyId }))

    setOptimizedClicked(false)
  }, [companyId])

  const onSelectionModelChange = useCallback(
    (newSelectionModel: GridSelectionModel) => {
      setSelectedRowIds(newSelectionModel)
    },
    [],
  )

  const onFilterModelChange = useCallback(() => {
    setSelectedRowIds([])
  }, [])

  const rows = useMemo(() => {
    if (productSegmentResults && productSegmentResults.results) {
      const {
        results: { productSegmentsMapping },
      } = productSegmentResults
      return getRows(productSegmentsMapping)
    }
    return []
  }, [productSegmentResults])

  const onOptimizeClick = useCallback((): void => {
    if (productSegmentResults && productSegmentResults.results) {
      const {
        results: { productSegments },
      } = productSegmentResults

      const segmentsToCanOptimize = allSegmentsHave12Months(
        rows,
        productSegments,
      )

      if (
        Object.values(segmentsToCanOptimize).every(
          canSegmentOptimize => canSegmentOptimize,
        )
      ) {
        setCanOptimize(true)
        setIncompleteSegments([])
      } else {
        setCanOptimize(false)
        const cantOptimizeNames = Object.keys(segmentsToCanOptimize).filter(
          segmentName => !segmentsToCanOptimize[segmentName],
        )
        setIncompleteSegments(cantOptimizeNames)
      }

      setOptimizedClicked(true)
    }
  }, [productSegmentResults, rows])

  if (!productSegmentResults || !productSegmentResults.results) {
    return <PageLoading />
  }

  const {
    results: { productSegments },
  } = productSegmentResults

  return (
    <div style={{ height: '80vh', width: '95vw', marginBottom: '20px' }}>
      <div
        style={{ margin: '10px', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button onClick={onOptimizeClick} variant="contained">
          Run optimization
        </Button>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <AllSegmentsSection productSegments={productSegments} />
        <CreateNewSection companyId={companyId} />
        <div
          style={{
            borderBottom: `1px solid ${Color.LighterGrey}`,
            color: 'grey',
            height: '1px',
            width: '100%',
            marginBottom: '12px',
            marginTop: '12px',
          }}
        />
        <SetProductMappingSection
          selectedRowIds={selectedRowIds}
          productSegments={productSegments}
          companyId={companyId}
        />
      </div>
      <AdminDataGrid
        getDetailPanelHeight={(): 'auto' => 'auto'}
        getDetailPanelContent={({
          row,
        }: {
          row: ProductAggregateInfoWithCustomerSegment
        }): JSX.Element => (
          <ProductSegmentDetail
            row={row}
            availableProductSegments={productSegments}
            companyId={companyId}
          />
        )}
        components={{ Toolbar: GridToolbar }}
        columns={columns}
        rows={rows}
        density="compact"
        onSelectionModelChange={onSelectionModelChange}
        onFilterModelChange={onFilterModelChange}
        selectionModel={selectedRowIds}
      />

      <BasicModal
        height={200}
        width={400}
        isOpen={optimizeClicked}
        handleClose={handleOptimizeClosed}
        onDismiss={handleOptimizeClosed}
        title={`Trigger Parameter Optimization for ${productSegments.length} Segments`}
        confirmButtonText={'Run optimization'}
        cancelButtonText={'Cancel'}
        onConfirmButtonClicked={handleTriggerOptimization}
        onConfirmDisabled={!canOptimize}
        onCancelButtonClicked={handleOptimizeClosed}
        buttonJustifyContent={'flex-end'}
        renderContent={(): JSX.Element =>
          canOptimize ? (
            <div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                This process can take up to 3 hours and changes should be
                reflected in the UI the following business day. If there are
                more than 35 segments, reach out to the dev team to manually run
                the training job.
              </Typography>
            </div>
          ) : (
            <div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`Some segments have less than 12 months of data: ${incompleteSegments.join(
                  ', ',
                )}`}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                A segment should include at least 1 product that has more than a
                year of data.
              </Typography>
            </div>
          )
        }
        confirmButtonColor="warning"
      />
    </div>
  )
}
