import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  OptimizationState,
  ChannelMetrics,
  ForecastChannel,
  usePerformanceMetrics,
} from '../../hooks/useAdminModelPerformance'
import { LoadingCircular } from '../../components/designSystem/atoms/Loading/Loading'
import { ExternalLink } from '../../components/forms/StyledElements'

export const AdminPerformanceDetail = ({
  companyId,
}: {
  companyId: string
}): JSX.Element => {
  const { perfMetrics } = usePerformanceMetrics({
    companyId,
  })

  const emptyMetrics: ChannelMetrics[] = React.useMemo(() => {
    return [
      {
        channel: ForecastChannel.Combined,
        metricsDetails: {
          experimentId: '0',
          evaluationDate: new Date().toDateString(),
          optimizationDate: OptimizationState.NotOptimized,
          metrics: [],
        },
      },
    ]
  }, [])

  const [perfMetricsRetrieved, setperfMetricsRetrieved] = useState(emptyMetrics)
  const [loadingState, setLoadingState] = useState(true)
  const [evaluationDate, setEvaluationDate] = useState(
    new Date().toDateString(),
  )
  const [experimentId, setExperimentId] = useState('0')
  const [optimizationDate, setOptimizationDate] = useState<string>(
    OptimizationState.NotOptimized,
  )

  React.useEffect(() => {
    if (
      perfMetrics[0]?.metricsDetails.evaluationDate &&
      perfMetrics[0]?.metricsDetails.optimizationDate
    ) {
      setperfMetricsRetrieved(perfMetrics)
      setLoadingState(false)
      setEvaluationDate(perfMetrics[0].metricsDetails.evaluationDate)
      setOptimizationDate(perfMetrics[0].metricsDetails.optimizationDate)
      setExperimentId(perfMetrics[0].metricsDetails.experimentId)
    }
  }, [perfMetricsRetrieved, perfMetrics, evaluationDate])

  let results = <LoadingCircular />

  const metricsDoc = (
    <ExternalLink
      href="https://www.notion.so/brightflow-ai/Forecasting-accuracy-51a6bf063acb4c1db852333cb5423ed9?pvs=4"
      target="_blank"
    >
      Read more
    </ExternalLink>
  )
  let metricsResults = (
    <TableRow>
      <TableCell colSpan={2}>No metrics saved for this company</TableCell>
    </TableRow>
  )
  let channelHeaders = <TableRow></TableRow>

  if (!loadingState) {
    if (
      perfMetricsRetrieved.length &&
      perfMetricsRetrieved[0].metricsDetails.metrics.length &&
      Object.keys(perfMetricsRetrieved[0].metricsDetails.metrics[0]).length
    ) {
      const metricsList: string[] = perfMetricsRetrieved[0].metricsDetails.metrics.map(
        m => m.key,
      )
      const channelList: string[] = perfMetricsRetrieved.map(pm =>
        pm.channel.valueOf(),
      )
      channelHeaders = (
        <TableRow>
          {channelList.map(channel => (
            <TableCell
              component="th"
              variant={'head'}
              scope="row"
              style={{ fontWeight: 'bold' }}
            >
              {channel}
            </TableCell>
          ))}
        </TableRow>
      )
      metricsResults = (
        <>
          {metricsList.map(metricName => (
            <TableRow>
              <TableCell component="th" variant={'head'} scope="row">
                {metricName}
              </TableCell>
              {channelList.map(channel => (
                <TableCell component="td" scope="row">
                  {perfMetricsRetrieved
                    .find(ele => ele.channel === channel)
                    ?.metricsDetails.metrics.find(
                      metric => metric.key === metricName,
                    )
                    ?.value.toFixed(2)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      )
    }
    results = (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                variant={'head'}
                scope="row"
                style={{ fontWeight: 'bold' }}
                rowSpan={2}
              >
                Metric name
              </TableCell>
              <TableCell
                component="td"
                scope="row"
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                colSpan={perfMetricsRetrieved.length}
              >
                Metric Values
              </TableCell>
            </TableRow>
            {channelHeaders}
            {metricsResults}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <Card>
          <CardHeader title="Forecast Metrics" subheader={metricsDoc} />
          <CardContent>{results}</CardContent>
          <CardActions sx={{ ml: 1 }}>
            <Typography variant="caption" color="text.secondary">
              Last time metrics were evaluated:{' '}
              <Box component="span" fontWeight={'bold'} marginLeft={0.1}>
                {evaluationDate}
              </Box>
              <br />
              Last time revenue forecast was optimized:{' '}
              <Box component="span" fontWeight={'bold'} marginLeft={0.1}>
                {optimizationDate}
              </Box>
              <br />
              View metrics in MLFlow:
              <br />
              {perfMetricsRetrieved.map(cm => (
                <>
                  <Box component="span" fontWeight={'bold'} marginLeft={0.1}>
                    <ExternalLink
                      href={`https://mlflow.heliodata.com/#/experiments/${cm.metricsDetails.experimentId}?searchFilter=tags.environment%3D"prod"`}
                      target="_blank"
                    >
                      {cm.channel.valueOf()}
                    </ExternalLink>
                  </Box>
                  <br />
                </>
              ))}
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
