import { Input, InputProps } from '@mui/material'
import React, { useState } from 'react'

type Props = InputProps & {
  initialValue?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ControlledInput = ({
  initialValue,
  onChange,
  ...rest
}: Props): JSX.Element => {
  const [value, setValue] = useState<string | undefined>(initialValue)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onChange(event)
  }

  return <Input {...rest} onChange={handleOnChange} value={value} />
}
