import {
  AnyAction,
  Middleware,
  Store,
  applyMiddleware,
  compose,
  createStore as rCreateStore,
} from 'redux'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import failureLogger from './modules/middleware/failureLogger'

import rootReducer from './modules/rootReducer'
export type StoreType = ReturnType<typeof rootReducer>

const middleware: Middleware[] = [thunkMiddleware, failureLogger]

const reduxDevTools =
  (typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__?.()) ||
  compose

export const createStore = (
  storeData: Partial<StoreType>,
): Store<StoreType, AnyAction> => {
  return rCreateStore(
    rootReducer,
    storeData,
    compose(applyMiddleware(...middleware), reduxDevTools),
  )
}

const preloadedState =
  typeof window !== 'undefined' ? (window as any).__PRELOADED_STATE__ : {}

export const store = createStore(preloadedState)

export const dispatch = (action: AnyAction | Thunk): void => {
  // TODO: figure out this dispatch & redux-thunk type issue
  store.dispatch(action as any)
}

// Abstract types

// export type Action = {
//   type: string
//   payload?: any
// }

export type AsyncState = {
  error: Error | null
  isLoading: boolean
}

export type Thunk = ThunkAction<void, Store, unknown, AnyAction>
