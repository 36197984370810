import React, { useState } from 'react'

import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import { Color } from '../../../../Color'

import {
  NumericCriteriaInputProps,
  NumericCriteriaOperators,
} from './NumberCriteriaInput.types'

const options: { value: NumericCriteriaOperators; label: JSX.Element }[] = [
  { value: NumericCriteriaOperators.EQUAL, label: <span>&#61;</span> },
  { value: NumericCriteriaOperators.NOTEQUAL, label: <span>&#8800;</span> },
  { value: NumericCriteriaOperators.LESS, label: <span>&#60;</span> },
  { value: NumericCriteriaOperators.LESSOREQUAL, label: <span>&#8804;</span> },
  { value: NumericCriteriaOperators.GREATER, label: <span>&#62;</span> },
  {
    value: NumericCriteriaOperators.GREATEROREQUAL,
    label: <span>&#8805;</span>,
  },
]

export const NumericCriteriaInput = ({
  onChange,
  defaultOperator,
  defaultValue,
  min,
  max,
  placeholder,
}: NumericCriteriaInputProps): JSX.Element => {
  const defaultIdx = defaultOperator
    ? options.findIndex(({ value }) => value === defaultOperator)
    : 0

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [operatorOptionsIndex, setOperatorOptionsIndex] = useState(defaultIdx)
  const [inputValue, setInputValue] = useState(defaultValue || '')
  const isOpen = !!anchorEl

  const handleClickButton = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    index: number,
  ): void => {
    setOperatorOptionsIndex(index)
    onChange(options[index].value, inputValue)
    setAnchorEl(null)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target
    const inputAsNumber = parseFloat(value)

    if (value === '' || value === '-' || !isNaN(inputAsNumber)) {
      if (
        (min !== undefined && inputAsNumber < min) ||
        (max !== undefined && inputAsNumber > max)
      ) {
        return
      }
      setInputValue(value)
      onChange(options[operatorOptionsIndex].value, value)
    }
  }

  const handleClearInput = (): void => {
    setInputValue('')
    onChange(options[operatorOptionsIndex].value, '')
  }

  return (
    <Box
      tabIndex={-1}
      sx={{
        display: 'flex',
        border: `1px solid ${Color.LightGrey}`,
        alignItems: 'center',
        borderRadius: '5px',
        height: '38px',
        backgroundColor: Color.White,
      }}
    >
      <Box sx={{ width: '25px' }}>
        <Button
          id="numeric-criteria-button"
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClickButton}
          sx={{ minWidth: '25px' }}
        >
          {options[operatorOptionsIndex].label}
        </Button>
        <Menu
          id="numeric-criteria-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.value}
              value={option.value}
              selected={index === operatorOptionsIndex}
              onClick={(event): void => handleMenuItemClick(event, index)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        placeholder={placeholder}
        onChange={handleInputChange}
        value={inputValue}
        sx={{ flex: 1, fontSize: '.766rem' }}
        inputProps={{
          style: { paddingLeft: '0' },
        }}
      />
      <IconButton
        onClick={handleClearInput}
        sx={{ width: '15px', height: '15px', marginRight: '5px' }}
      >
        <CloseIcon sx={{ height: '15px' }} />
      </IconButton>
    </Box>
  )
}
