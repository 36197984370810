import { GraphTrace } from '../../../../../components/designSystem/organisms/BFGraph/BFGraph.types'
import { subWeeks } from 'date-fns'
import { ForecastData, ForecastDataBySegmentMap, ForecastPoint } from '../types'
import { AbstractProductDemandPeriodTransformer } from './AbstractProductDemandPeriodTransformer'

const ONE_MONTH_WEEKS = 4
const ONE_YEAR_WEEKS = 52

const TWO_YEARS_WEEKS = ONE_YEAR_WEEKS * 2
const SIX_MONTHS_WEEKS = ONE_YEAR_WEEKS / 2
const THREE_MONTH_WEEKS = ONE_MONTH_WEEKS * 3

/**
 * Transforms raw weekly product demand data provided by API
 * into data format that can be utilized in recharts graph
 */
export class WeeklyProductDemandDataTransformer extends AbstractProductDemandPeriodTransformer {
  public minTickGap = [-5, -5, -1000, -10000, -10000]
  // this is the raw data provided by API
  // contains weekly actuals/forecast for all product segments
  public segmentData: ForecastData[] = []

  // handy for looking up segment data by segmentName
  // key
  public segmentDataMap: ForecastDataBySegmentMap

  // useful for keeping track of when forecast data begins within the
  // segmentData array
  public firstForecastIndex: number

  // used to help calculate array index offset when different time periods
  // are provided by user in order to decide how much data to show in graph
  // example - user enters one year so we offset 52 weeks from last actual index
  public timePeriodArray: number[] = [
    TWO_YEARS_WEEKS,
    ONE_YEAR_WEEKS,
    SIX_MONTHS_WEEKS,
    THREE_MONTH_WEEKS,
    ONE_MONTH_WEEKS,
  ]

  // date-fns string format used in tooltip
  public tooltipDateStringFormat = 'LLL dd, yyyy'

  constructor(segmentData: ForecastData[]) {
    super()
    this.segmentData = segmentData
    this.segmentDataMap = this.buildSegmentDataMap()
    this.firstForecastIndex = segmentData[0].data.reduce(
      (acc, { ds }, index) => {
        const forecastWeekStart = new Date(ds)

        if (forecastWeekStart > new Date() && acc === 0) {
          acc = index - 1
        }

        return acc
      },
      0,
    )
  }

  get lastActualIndex(): number {
    return this.firstForecastIndex - 1
  }

  get firstForecastDateString(): string {
    return this.segmentData[0].data[this.firstForecastIndex].ds
  }

  public getForecastDataPoints(data: ForecastPoint[]): ForecastPoint[] {
    return data.slice(
      this.lastActualIndex,
      this.firstForecastIndex + SIX_MONTHS_WEEKS,
    )
  }

  public getActualDataPoints(
    data: ForecastPoint[],
    selectedUnitSegmentIndex: number,
  ): ForecastPoint[] {
    return data.filter(({ ds }, index) => {
      const date = new Date(ds)

      const historicalStartDate = subWeeks(
        new Date(data[this.firstForecastIndex].ds),
        this.timePeriodArray[selectedUnitSegmentIndex],
      )

      return date >= historicalStartDate && index <= this.lastActualIndex
    })
  }

  public getXAxisTicks(traces: GraphTrace[]): Array<string | number> {
    const FIRST_WEEK_IN_MONTH_DAYS = 7

    const getXAxisTicks = (
      weekDateStrings: Array<string | number>,
    ): Array<string | number> => {
      return weekDateStrings.filter(weekDateString => {
        const date = new Date(weekDateString)

        const day = date.getDate()

        return day <= FIRST_WEEK_IN_MONTH_DAYS
      })
    }

    // actuals ticks can be gathered from first trace
    const axisTickDates = getXAxisTicks(traces?.[0]?.x)

    // forecast ticks can be gathered from last trace
    const forecastTickDates = getXAxisTicks(traces?.[traces.length - 1]?.x)

    return [...axisTickDates, ...forecastTickDates]
  }

  public getLastYearActualFromForecastLabel = (
    segmentName: string,
    forecastLabel: string,
  ): number => {
    const segment = this.segmentDataMap[segmentName]

    const forecastPoints = segment.data

    const currentDataPoint = forecastPoints?.findIndex(
      data => data.ds === forecastLabel,
    )

    if (!currentDataPoint) {
      return 0
    }

    const offsetIndex = currentDataPoint - ONE_YEAR_WEEKS
    return forecastPoints?.[offsetIndex]?.y ?? 0
  }

  public getBaseName(label: string): string {
    const isForecast = this.isLabelForecast(label)

    return isForecast ? 'Units sold last year' : 'Units sold'
  }
}
