import React from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Color } from '../../../../Color'

interface Props {
  columns: number
  items: string[]
}

export const InfiniteGrid = ({ columns, items }: Props): JSX.Element => {
  const MAX_COLUMNS = 2 * columns
  const THRESHOLD = columns
  const isBeyondThreshold = (index: number): boolean =>
    index % MAX_COLUMNS >= THRESHOLD
  return (
    <Grid container columns={columns} columnSpacing={2}>
      {items.map((item, idx) => (
        <Grid item key={`grid-item-${idx}`} xs={1}>
          <Box
            sx={{
              bgcolor: isBeyondThreshold(idx) ? 'none' : Color.Grey50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                p: '6px 18px',
              }}
              variant="body1"
            >
              {item}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
