import * as React from 'react'

interface Props {
  height: number
  width: number
}

export const Lock: React.FunctionComponent<Props> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{'Secure'}</title>
      <g
        id="Product-features"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-account"
          transform="translate(-199.000000, -465.000000)"
          stroke="#FFFFFF"
        >
          <g id="Group-4" transform="translate(128.000000, 95.000000)">
            <g
              id="create-account-button"
              transform="translate(0.000000, 356.000000)"
            >
              <g id="Group-5" transform="translate(72.000000, 13.000000)">
                <g id="lock" transform="translate(0.000000, 2.000000)">
                  <path
                    d="M7,0 C7.69105556,0.953555556 12.0252222,2.72222222 14,2.72222222 L14,10.1111111 C14,12.7473889 7.39472222,16.6195556 7,16.7222222 C6.60488889,16.6195556 0,12.7473889 0,10.1111111 L0,2.72222222 C1.97477778,2.72222222 6.30894444,0.953555556 7,0 Z"
                    id="Stroke-1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M10.1111111,11.6666667 C10.1111111,11.8813333 9.93688889,12.0555556 9.72222222,12.0555556 L4.27777778,12.0555556 C4.06311111,12.0555556 3.88888889,11.8813333 3.88888889,11.6666667 L3.88888889,7.38888889 C3.88888889,7.17422222 4.06311111,7 4.27777778,7 L9.72222222,7 C9.93688889,7 10.1111111,7.17422222 10.1111111,7.38888889 L10.1111111,11.6666667 Z"
                    id="Stroke-3"
                  ></path>
                  <path
                    d="M5.05555556,5.05555556 C5.05555556,3.98183333 5.92627778,3.11111111 7,3.11111111 C8.07372222,3.11111111 8.94444444,3.98183333 8.94444444,5.05555556 L8.94444444,7 L5.05555556,7 L5.05555556,5.05555556 Z"
                    id="Stroke-5"
                  ></path>
                  <line
                    x1="7"
                    y1="8.94444444"
                    x2="7"
                    y2="10.1111111"
                    id="Stroke-7"
                    strokeLinecap="round"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Lock
