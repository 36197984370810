import { api } from '../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getPendingSubscription = createAsyncThunk(
  'app/subscriptions/pending/get',
  async () => api.get('/api/subscriptions/pending'),
)

export const getActiveSubscription = createAsyncThunk(
  'app/subscriptions/active/get',
  async () => api.get('/api/subscriptions/active'),
)

export const createPendingSubscription = createAsyncThunk(
  'app/subscriptions/create/shopify',
  async (subscriptionPlanId: string) =>
    api.post('/api/subscriptions/create/shopify', { subscriptionPlanId }),
)
