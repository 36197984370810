import React, { useState } from 'react'

import {
  DateRange,
  DateRangePicker,
} from '@mui/x-date-pickers-pro/DateRangePicker'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'

import {
  CalendarAdornment,
  ClearInputButton,
  DateRangeInput,
} from './DateRangeSelector.styles'
import { formatDateRange } from './DateRangeSelector.utils'

type Props = {
  intialValue?: DateRange<Date | null>
  onChange: (value: DateRange<Date | null>) => void
  placeholder?: string
  isFutureDisabled?: boolean
}

export const DateRangeSelector = ({
  intialValue = [null, null],
  onChange,
  placeholder,
  isFutureDisabled,
}: Props): JSX.Element => {
  const [value, setValue] = useState<DateRange<Date | null>>(intialValue)

  const handleOnChange = (newValue: DateRange<Date | null>): void => {
    onChange(newValue)
    setValue(newValue)
  }

  const handleClearInput = (): void => {
    onChange([null, null])
    setValue([null, null])
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: '', end: '' }}
    >
      <DateRangePicker
        value={value}
        onChange={handleOnChange}
        componentsProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
        disableFuture={isFutureDisabled}
        renderInput={({ inputProps, inputRef }): JSX.Element => (
          <Box
            sx={{
              position: 'relative' as 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CalendarAdornment />
            <DateRangeInput
              ref={inputRef as React.Ref<HTMLInputElement>}
              {...inputProps}
              placeholder={placeholder}
              value={formatDateRange(value)}
            />
            <ClearInputButton onClick={handleClearInput}>
              <CloseIcon sx={{ height: '15px' }} />
            </ClearInputButton>
          </Box>
        )}
      />
    </LocalizationProvider>
  )
}
