import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DescriptionIcon from '@mui/icons-material/Description'

import { Color } from '../../../../../Color'

import { TimePeriodFilter } from '../../../../../components/designSystem/organisms/TimePeriodFilter/TimePeriodFilter'

import { TimePeriodFilterOptions } from '../../CashPosition.consts'
import { useCashPosition } from '../../CashPositionProvider'

export const CashPositionPageActions = (): JSX.Element => {
  const {
    handleDateRangeFilterChange,
    handleExportData,
    duration,
    granularity,
  } = useCashPosition()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0 30px 24px',
      }}
    >
      <TimePeriodFilter
        filterOptions={TimePeriodFilterOptions}
        handleSubmit={handleDateRangeFilterChange}
        initialDuration={duration}
        initialGranularity={granularity}
      />
      <Button
        variant="contained"
        startIcon={<DescriptionIcon />}
        onClick={handleExportData}
        sx={{ width: '100px', height: '48px', backgroundColor: Color.Green }}
      >
        Export
      </Button>
    </Box>
  )
}
