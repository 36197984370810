import { AmazonModal } from './Modals/AmazonModal'
import { ShopifyModal } from './Modals/ShopifyModal'
import { GoogleAdsModal } from './Modals/GoogleAdsModal'
import { FacebookAdsModal } from './Modals/FacebookAdsModal'
import { BaseModalControlProps } from './Modals/BaseModal'
import { NetSuiteModal, XeroModal } from './Modals/RutterModals'
import { PlaidModal } from './Modals/PlaidModal'
import { KnownConnectors } from '../../../../server/connectors/known-connectors'
import { AmazonIcon } from '../../../components/designSystem/assets/providers/AmazonIcon/AmazonIcon'
import { FacebookIcon } from '../../../components/designSystem/assets/providers/FacebookIcon/FacebookIcon'
import { GoogleAdsIcon } from '../../../components/designSystem/assets/providers/GoogleAdsIcon/GoogleAdsIcon'
import { NetSuiteIcon } from '../../../components/designSystem/assets/providers/NetsuiteIcon/NetSuiteIcon'
import { PlaidIcon } from '../../../components/designSystem/assets/providers/PlaidIcon/PlaidIcon'
import { QuickbooksIcon } from '../../../components/designSystem/assets/providers/QuickbooksIcon/QuickbooksIcon'
import { ShopifyIcon } from '../../../components/designSystem/assets/providers/ShopifyIcon/ShopifyIcon'
import { XeroIcon } from '../../../components/designSystem/assets/providers/XeroIcon/XeroIcon'
import { Connection } from '../../../modules/connectors/types'

import { IconProps } from '../../../components/designSystem/assets/assets.types'

export type ProviderModalProps = BaseModalControlProps & {
  connectionKey?: string
  authIsPending?: boolean
  connection?: Connection
}

export type Provider = {
  title: string
  icon: React.FunctionComponent<IconProps>
  url: string
  isOutboundUrl: boolean
  modal?: React.FunctionComponent<ProviderModalProps>
  shouldRedirect?: boolean
}

export const Providers: {
  [key in KnownConnectors]: Provider
} = {
  amazonSeller: {
    icon: AmazonIcon,
    modal: AmazonModal,
    title: 'Amazon Marketplace',
    url: '/amazonSeller',
    isOutboundUrl: false,
  },
  amazon: {
    icon: AmazonIcon,
    title: 'Amazon Advertising',
    url: `/connect/amazon`,
    isOutboundUrl: true,
  },
  facebookMarketing: {
    icon: FacebookIcon,
    title: 'Facebook Ads',
    url: '/connect/facebookmarketing/auth?redirect=',
    isOutboundUrl: true,
    modal: FacebookAdsModal,
    shouldRedirect: true,
  },
  googleAds: {
    icon: GoogleAdsIcon,
    title: 'Google Ads',
    url: '/connect/googleads/auth?redirect=',
    isOutboundUrl: true,
    modal: GoogleAdsModal,
    shouldRedirect: true,
  },
  shopify: {
    icon: ShopifyIcon,
    modal: ShopifyModal,
    title: 'Shopify',
    url: '/shopify',
    isOutboundUrl: false,
  },
  quickbooksOnline: {
    icon: QuickbooksIcon,
    title: 'QuickBooks Online',
    url: `/connect/qbo/auth`,
    isOutboundUrl: true,
  },
  netSuite: {
    icon: NetSuiteIcon,
    title: 'NetSuite',
    modal: NetSuiteModal,
    url: `/netSuite`,
    isOutboundUrl: false,
  },
  xero: {
    icon: XeroIcon,
    title: 'Xero',
    modal: XeroModal,
    url: `/xero`,
    isOutboundUrl: false,
  },
  plaid: {
    icon: PlaidIcon,
    title: 'Bank account',
    modal: PlaidModal,
    url: `/plaid`,
    isOutboundUrl: false,
  },
}
