import { createSelector } from '@reduxjs/toolkit'
import { differenceInCalendarDays } from 'date-fns'

import {
  ACCOUNTING_CONNECTORS,
  BANKING_CONNECTORS,
  EXPENSE_CONNECTORS,
  KNOWN_CONNECTOR_KEYS,
  REVENUE_CONNECTORS,
} from '../../../server/connectors/known-connectors'
import { Connection, StoreConnection, StoreConnectors } from './types'

import {
  GET_FAILURE,
  GET_PENDING,
  GET_SUCCESS,
  GetFailureAction,
  GetPendingAction,
  GetSuccessAction,
} from './list'

export { getConnectors, getSuccess } from './list'

// ACTIONS

const SET_OWN = 'connectors/own/SET'
const CLEAR = 'connectors/CLEAR'

type Actions =
  | GetFailureAction
  | GetPendingAction
  | GetSuccessAction
  | SetOwnAction
  | ClearAction

type SetOwnAction = {
  type: typeof SET_OWN
  payload: number
}

type ClearAction = {
  type: typeof CLEAR
}

export function setOwnConnectionsCount(
  ownConnectionsCount: number,
): SetOwnAction {
  return {
    type: SET_OWN,
    payload: ownConnectionsCount,
  }
}

export function clearConnectors(): ClearAction {
  return {
    type: CLEAR,
  }
}

// This could be a util but keeping it locally scoped until we want to use it twice
const getPercentOfPeriodComplete = (
  oldestTransactionDate: Date | undefined,
  periodInDays: number,
): number => {
  if (!oldestTransactionDate) return 0

  const daysSinceOldestTransaction = differenceInCalendarDays(
    new Date(),
    oldestTransactionDate,
  )

  if (daysSinceOldestTransaction >= periodInDays) return 100

  return Math.floor((daysSinceOldestTransaction / periodInDays) * 100)
}

// SELECTORS
// TODO: Selectors and reducers go well together, but this file is pretty hairy

// It feels weird sending all connections while leaning into hardcoding
// the layouts, but it's a convenient "has this ever loaded" indicator...
export const selectIsLoaded = (sc: StoreConnectors): boolean => {
  return sc.data.all.length > 0
}

export const selectConnectors = createSelector(
  (sc: StoreConnectors): StoreConnection[] => sc.data.connections,
  (connectors): Connection[] => {
    return connectors.map(connector => {
      return {
        ...connector,
        percentOfWeekComplete: getPercentOfPeriodComplete(
          connector.oldestTransactionDate,
          7,
        ),
        percentOfYearComplete: getPercentOfPeriodComplete(
          connector.oldestTransactionDate,
          365,
        ),
      }
    })
  },
)

export const selectRevenueConnectors = createSelector(
  selectConnectors,
  connectors => {
    return connectors.filter(({ key }) => REVENUE_CONNECTORS.includes(key))
  },
)

export const selectExpenseConnectors = createSelector(
  selectConnectors,
  connectors => {
    return connectors.filter(({ key }) => EXPENSE_CONNECTORS.includes(key))
  },
)

export const selectAccountConnectors = createSelector(
  selectConnectors,
  connectors => {
    return connectors.filter(({ key }) => ACCOUNTING_CONNECTORS.includes(key))
  },
)

export const selectBankingConnectors = createSelector(
  selectConnectors,
  connectors => {
    return connectors.filter(({ key }) => BANKING_CONNECTORS.includes(key))
  },
)

// REDUCERS

const initialState: StoreConnectors = {
  data: {
    all: [],
    connections: [],
  },
  ownConnectionsCount: 0,
  get: {
    isLoading: false,
    error: null,
  },
  add: {
    isLoading: false,
    error: null,
  },
  providerData: {
    [KNOWN_CONNECTOR_KEYS.shopify]: {
      orders: [],
    },
    isLoading: false,
    error: null,
  },
}

export function connectorsReducer(
  state: StoreConnectors = initialState,
  action: Actions,
): StoreConnectors {
  switch (action.type) {
    case GET_PENDING:
      return {
        ...state,
        get: {
          isLoading: true,
          error: null,
        },
      }
    case GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        get: {
          isLoading: false,
          error: null,
        },
      }
    case GET_FAILURE:
      return {
        ...state,
        get: {
          isLoading: false,
          error: action.payload,
        },
      }

    case SET_OWN:
      return {
        ...state,
        ownConnectionsCount: action.payload,
        get: {
          isLoading: false,
          error: null,
        },
      }

    // get provider data - would do with createThunkReducer
    case 'connectors/providers/shopify/get-data/pending' as any:
      return {
        ...state,
        providerData: {
          ...state.providerData,
          isLoading: true,
        },
      }
    case 'connectors/providers/shopify/get-data/rejected' as any:
      return {
        ...state,
        providerData: {
          ...state.providerData,
          isLoading: true,
          error: (action as any).payload,
        },
      }
    case 'connectors/providers/shopify/get-data/fulfilled' as any:
      return {
        ...state,
        providerData: {
          ...state.providerData,
          isLoading: false,
          ...(action as any).payload,
        },
      }

    case CLEAR:
      return initialState
    default:
      return state
  }
}
