import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import { TableSectionHeader } from './DashboardGridPanelDetail'
import { styled } from '@mui/material/styles'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const DeleteIcon = styled(DeleteForeverIcon)`
  cursor: pointer;
`

export const AdminPortfolioDetail = (props: {
  renderButton: () => JSX.Element
  companyId: string
  deletePortfolio: ({
    companyId,
    portfolioName,
  }: {
    companyId: string
    portfolioName: string
  }) => void
  strings: string[]
}): JSX.Element => {
  const { deletePortfolio, companyId } = props

  return (
    <>
      <TableSectionHeader
        title={'Portfolios'}
        renderButton={props.renderButton}
      />
      <TableContainer component={Paper} sx={{ width: '300px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Delete</TableCell>
              <TableCell component="th">Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.strings.map(row => (
              <TableRow
                key={row}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th">
                  <DeleteIcon
                    color={'error'}
                    onClick={(): void =>
                      deletePortfolio({
                        companyId: companyId,
                        portfolioName: row,
                      })
                    }
                  />
                </TableCell>
                <TableCell scope="row">{row}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
