import React, { useEffect } from 'react'
import { getAccountLabellingDashboardData } from '../../../modules/adminDashboard/credit/accountLabellingDashboard'
import { AccountLabelDashboardPayload } from '../../../modules/adminDashboard/credit'
import { Layout } from '../../../layout'
import { connect } from 'react-redux'
import { Store } from '../../../modules/rootReducer'

import { dispatch } from '../../../store'
import { AccountLabellingGrid } from './AccountLabellingGrid'

interface Props {
  accountLabelDashboard: AccountLabelDashboardPayload
}
export const CreditDashboardImpl = (props: Props): JSX.Element => {
  useEffect(() => {
    dispatch(getAccountLabellingDashboardData())
  }, [])

  const { accountLabelDashboard } = props

  const {
    error,
    accountLabelDashboard: accountLabelsResults,
  } = accountLabelDashboard

  if (error || accountLabelsResults?.errorMessages.length) {
    return (
      <p>{`Ben broke something go bug him ${
        JSON.stringify(error) ||
        JSON.stringify(accountLabelsResults?.errorMessages)
      }`}</p>
    )
  }

  return (
    <Layout showSidebar={false} background={'white'}>
      <AccountLabellingGrid accountLabels={accountLabelsResults?.results} />
    </Layout>
  )
}

const mapStateToProps = (state: Store): Props => {
  const {
    accountLabelDashboard: { accountLabelDashboard },
  } = state

  return {
    accountLabelDashboard,
  }
}

export const CreditDashboardScreen = connect(mapStateToProps)(
  CreditDashboardImpl,
)
