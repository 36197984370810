export enum RelativePeriod {
  previous = 'previous',
  current = 'current',
  next = 'next',
}

export type CurrentOrNextPeriod = RelativePeriod.current | RelativePeriod.next

export type CurrentOrPreviousPeriod =
  | RelativePeriod.current
  | RelativePeriod.previous
