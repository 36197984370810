import { api } from '../../../lib/api'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAccountLabellingDashboardData = createAsyncThunk(
  'app/customer-service/credit',
  async () => api.get('/customer-service/credit'),
)

export const bulkUpdateBfCategoryLabels = createAsyncThunk(
  'app/customer-service/credit/bulkUpdate',
  async ({
    connectionAccountObs,
    bfCategoryLabel,
    bfSubCategoryLabel,
  }: {
    connectionAccountObs: { connectionId: string; accountName: string }[]
    bfCategoryLabel: string
    bfSubCategoryLabel: string
  }) =>
    api.post('/customer-service/credit/bulkUpdate', {
      connectionAccountObs,
      bfCategoryLabel,
      bfSubCategoryLabel,
    }),
)

export const bulkUpdateActionFields = createAsyncThunk(
  '/app/customer-service/credit/bulkUpdateActions',
  async ({
    ids,
    reviewed,
    validated,
    indexed,
  }: {
    ids: string[]
    reviewed?: boolean | null
    validated?: boolean | null
    indexed?: boolean | null
  }) =>
    api.post('/customer-service/credit/bulkUpdateActions', {
      ids,
      reviewed,
      validated,
      indexed,
    }),
)

export const updateBfCategoryLabels = createAsyncThunk(
  'app/customer-service/credit/update',
  async ({
    connectionId,
    accountName,
    bfCategoryLabel,
    bfSubCategoryLabel,
    reviewed,
    validated,
    indexed,
  }: {
    connectionId: string
    accountName: string
    bfCategoryLabel: string
    bfSubCategoryLabel: string
    reviewed?: boolean
    validated?: boolean
    indexed?: boolean
  }) =>
    api.post('/customer-service/credit/update', {
      connectionId,
      accountName,
      bfCategoryLabel,
      bfSubCategoryLabel,
      reviewed,
      validated,
      indexed,
    }),
)

export const indexLabel = createAsyncThunk(
  'app/customer-service/credit/label/index',
  async ({
    id,
    connectionId,
    accountName,
    bfCategory,
    bfSubCategory,
  }: {
    id: string
    connectionId: string
    accountName: string
    bfCategory: string
    bfSubCategory: string
  }) =>
    api.post('/api/elasticsearch/index/label', {
      id,
      connectionId,
      accountName,
      bfCategory,
      bfSubCategory,
    }),
)
