import React, { useEffect, useState } from 'react'
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'

import { ProviderModalProps } from '../Providers'
import { dispatch, store } from '../../../../store'
import { Store } from '../../../../modules/rootReducer'
import { KNOWN_CONNECTOR_KEYS } from '../../../../../server/connectors/known-connectors'
import { authPlaid, linkPlaid } from '../../../../modules/plaid/plaid'
import { LoadingCircular } from '../../../../components/designSystem/atoms/Loading/Loading'
import { BasicModal } from '../../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { track } from '../../../../lib/analytics'
import { Connection } from '../../../../modules/connectors/types'
import { selectBankingConnectors } from '../../../../modules/connectors'
import Button from '@mui/material/Button'
import { Caution } from '../../../../components/designSystem/assets/Caution/Caution'
import { UserGuidanceBase as UserGuidance } from '../../../shared'
import { EmailLink } from '../../../../components/forms/StyledElements'

interface Props {
  plaidReducer?: any
  connection?: Connection
  plaidConnections: Connection[]
}

const PlaidLink = ({
  link,
  closeHandler,
  successHandler,
  plaidConnections,
  isReauth,
}: {
  link: string
  plaidConnections: Connection[]
  isReauth: boolean
  closeHandler: (
    error: null | string,
    metadata: PlaidLinkOnExitMetadata | PlaidLinkOnSuccessMetadata,
  ) => void
  successHandler: (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => void
}): null => {
  const plaidConfig = {
    token: link,
    onSuccess: (
      publicToken: string,
      metadata: PlaidLinkOnSuccessMetadata,
    ): void => {
      const companyId = store.getState().companies.data.id
      const { link_session_id: linkSessionId, institution } = metadata
      if (institution?.name) {
        const found = plaidConnections.find(
          connection =>
            connection.connectionInfo.name === institution.name && !isReauth,
        )
        if (found) {
          // Institution already exists and connection is valid hence do not allow connection to be created
          const error = new Error('duplicateInstitution')
          track('Plaid Link Failure', { linkSessionId, companyId, error })
          closeHandler(error.message, metadata)
        } else {
          track('Plaid Link Success', { linkSessionId, companyId })
          successHandler(publicToken, metadata)
        }
      } else {
        // really small scenario
        track('Plaid Link Failure', {
          linkSessionId,
          companyId,
          error: new Error('No Institution Name'),
        })
      }
    },
    onExit: (
      error: null | PlaidLinkError,
      metadata: PlaidLinkOnExitMetadata,
    ): void => {
      if (error) {
        const companyId = store.getState().companies.data.id
        const { link_session_id: linkSessionId } = metadata
        const message =
          `Unable to link with plaid: ${error.error_message}, ` +
          `linkSessionId: ${linkSessionId}, companyId: ${companyId}`
        Sentry.addBreadcrumb({
          category: 'Plaid',
          message,
          level: Severity.Error,
          data: { error, linkSessionId, companyId },
        })
        Sentry.captureException(new Error(message))
        track('Plaid Link Failure', { linkSessionId, companyId, error })
      }
      closeHandler(error?.error_message || null, metadata)
    },
  }
  const { open } = usePlaidLink(plaidConfig)
  open()
  return null
}

const getMessageFromKnownError = (error: string) => {
  switch (error) {
    case 'duplicateInstitution':
      return (
        <>
          We already have an existing connection for this institution. Please
          contact{' '}
          <EmailLink
            href="mailto:support@brightflow.ai?subject=I'm having an issue with my plaid connections"
            target="_blank"
          >
            support@brightflow.ai
          </EmailLink>{' '}
          for additional support.
        </>
      )
    default:
      return error
  }
}

const PlaidModalScreen: React.FunctionComponent<
  ProviderModalProps & Props
> = props => {
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [linkToken, setLink] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { onClose, plaidReducer, connection, plaidConnections } = props

  const closeHandler = (error: null | string): void => {
    setLink('')
    if (error) {
      setError(error)
    } else {
      setShouldRedirect(true)
      onClose()
    }
  }

  const successHandler = (publicToken: string): void => {
    setIsLoading(true)
    setLink('')
    // Exchange Tokens and Create new connnection
    dispatch(
      authPlaid({
        publicToken,
        connectionKey: KNOWN_CONNECTOR_KEYS.plaid,
      }),
    )

    // connections can take up to 5 seconds to refresh
    // and we don't want the user to reclick the button
    // so doing a hacky setTimeout before we dismiss the loading modal
    setTimeout(() => {
      setIsLoading(false)
      closeHandler(null)
    }, 5000)
  }

  useEffect(() => {
    const {
      plaid: { link },
    } = plaidReducer
    if (link) {
      setLink(link)
    }
    if (shouldRedirect) {
      dispatch(linkPlaid({ externalId: connection?.externalId ?? null }))
      setShouldRedirect(false)
    }
  }, [plaidReducer, connection])

  if (linkToken) {
    return (
      <PlaidLink
        plaidConnections={plaidConnections}
        link={linkToken}
        closeHandler={closeHandler}
        successHandler={successHandler}
        isReauth={!!connection}
      />
    )
  }

  // appears for a few seconds after the connection succeeds
  // to prevent the user from doing anything
  if (isLoading) {
    return (
      <BasicModal>
        <LoadingCircular />
      </BasicModal>
    )
  }

  if (error) {
    const errorMessage = getMessageFromKnownError(error)
    return (
      <BasicModal>
        <UserGuidance
          renderIcon={(): React.ReactNode => (
            <Caution height={98} width={114} />
          )}
          title="Connection not completed"
          subtitle={errorMessage}
        >
          <Button
            onClick={(): void => onClose()}
            variant="outlined"
            color="success"
            size={'large'}
            style={{ height: '30px', fontSize: '18px', padding: '20px' }}
          >
            Close
          </Button>
        </UserGuidance>
      </BasicModal>
    )
  }

  return null
}

const mapStateToProps = (
  { plaidReducer, connectors }: Store,
  ownProps: ProviderModalProps,
): Props => {
  const plaidConnections = selectBankingConnectors(connectors)
  return {
    plaidReducer,
    plaidConnections,
    connection: ownProps.connection,
  }
}

export const PlaidModal = connect(mapStateToProps)(PlaidModalScreen)
