import { useState, useEffect } from 'react'

import { Props } from './OverviewGraphCard.types'
import {
  formatAsChartData,
  formatAsLegendData,
} from './OverviewGraphCard.utils'

export const useNewOverviewCard = ({
  channels,
  emptyState,
  isEmpty,
  isLoading,
  showTotal = true,
  totals,
  type,
}: Props) => {
  const [hasPreviouslyRendered, setHasPreviouslyRendered] = useState(false)

  const { __typename, ...totalsValue } = totals

  const validChannels = channels ?? []
  const totalChannel = showTotal ? [{ ...totalsValue, name: 'total' }] : []

  // if there is only 1 channel of data then do not display totals
  const allChannels =
    validChannels.length === 1
      ? [...validChannels]
      : [...totalChannel, ...validChannels]

  const hasEmptyState = !!emptyState

  const shouldRender =
    !isEmpty ||
    (isLoading && (hasEmptyState || hasPreviouslyRendered)) ||
    (isEmpty && hasEmptyState)

  useEffect(() => {
    if (!hasPreviouslyRendered && ((isLoading && hasEmptyState) || !isEmpty)) {
      setHasPreviouslyRendered(true)
    }
  }, [hasPreviouslyRendered, hasEmptyState, isEmpty, isLoading])

  return {
    shouldRender,
    chartData: formatAsChartData(totals, channels),
    legendData: formatAsLegendData(allChannels, type),
  }
}
