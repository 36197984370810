import React from 'react'
import Button from '@mui/material/Button'
import { WomanCoinIcon } from '../../../components/designSystem/assets/WomanCoinIcon/WomanCoinIcon'
import { NavLinkPlain } from '../../../components/designSystem/atoms/NavLinkPlain/NavLinkPlain'
import { UserGuidanceBase } from '../../shared/'

type Props = {
  title: string
  subtitle: string
  icon?: JSX.Element
  buttonText?: string
}

export const NoConnections: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  icon,
  buttonText,
}) => {
  return (
    <UserGuidanceBase
      renderIcon={(): React.ReactNode =>
        icon || <WomanCoinIcon height={179} width={181} />
      }
      title={title}
      subtitle={subtitle}
    >
      <NavLinkPlain to={'/connectors'}>
        <Button
          variant="contained"
          color="primary"
          size={'large'}
          style={{ height: '30px', fontSize: '1rem', padding: '20px' }}
        >
          {buttonText || 'Start a Connection'}
        </Button>
      </NavLinkPlain>
    </UserGuidanceBase>
  )
}
