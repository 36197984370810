import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { Store } from '../../../../modules/rootReducer'
import { dispatch } from '../../../../store'
import { getFacebookAdsAccounts } from '../../../../modules/welcome'
import { ListFacebookAdsAccountsResponse } from '../../../../../server/routes/api/welcome/facebook-ads-accounts'

import { Caution } from '../../../../components/designSystem/assets/Caution/Caution'
import { UserGuidanceBase } from '../../../shared'

import { PostAuthModal } from '../../../../components/designSystem/organisms/PostAuthModal/PostAuthModal'
import { Spinner } from '../../../../components/designSystem/atoms/Spinner/Spinner'

import { SelectFacebookAdsAccountForm } from '../../../../components/forms/welcome/SelectFacebookAdsAccountForm'
import { ProviderModalProps } from '../Providers'

const CenterDiv = styled('div')`
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
`

const UserGuidance = styled(UserGuidanceBase)`
  margin-top: 0px !important;
  min-height: 420px;

  & > * {
    max-width: 450px;
  }
`

type FacebookModalReduxProps = ListFacebookAdsAccountsResponse & {
  isLoading: boolean
  isPutting: boolean
  didPut: boolean
  error: Error | null
}

type Props = ProviderModalProps & FacebookModalReduxProps

const getErrorData = (
  error: any,
  hasAccounts: boolean,
): {
  element: JSX.Element
  title: string
} => {
  const errMsg = error?.payload?.errorMessage
  const parsedErr = errMsg ? JSON.parse(errMsg) : {}
  if (parsedErr?.error?.reason === 'InvalidAccount') {
    return {
      title: 'Incorrect account',
      element: (
        <CenterDiv>
          <p>
            The account you&apos;re trying to sync does not match the previously
            synced account for
          </p>
          <p>
            <b>{parsedErr.payload.name}</b>{' '}
          </p>
          <p>
            In your browser, logout of the current Facebook account and login to
            the account previously connected for your company, then try again.
          </p>
        </CenterDiv>
      ),
    }
  } else if (!hasAccounts) {
    return {
      title: 'Connection not completed',
      element: (
        <CenterDiv>
          <p>
            The account you&apos;re trying to connect does not have an
            associated Facebook Ads account.
          </p>
          <p>
            In your browser, logout of the current Facebook account and login
            with a different account, then try again.
          </p>
          <p>If you need further help, reach out to support@brightflow.ai</p>
        </CenterDiv>
      ),
    }
  }

  return {
    // Defaults
    title: 'Connection not completed',
    element: (
      <CenterDiv>
        <p>
          There was an error connecting to your Facebook Ads account. Try
          connecting again.
        </p>
        <p>If you need further help, reach out to support@brightflow.ai</p>
      </CenterDiv>
    ),
  }
}

const ErrorModal = ({
  error,
  onClose,
  hasAccounts,
}: {
  error: any
  onClose: () => void
  hasAccounts: boolean
}): React.ReactElement => {
  const { title, element } = getErrorData(error, hasAccounts)
  return (
    <UserGuidance
      renderIcon={(): React.ReactNode => <Caution height={60} width={70} />}
      title={title}
      subtitle=""
    >
      {element}
      <Button
        onClick={() => onClose()}
        variant="outlined"
        color="success"
        size={'large'}
        style={{ height: '30px', fontSize: '18px', padding: '20px' }}
      >
        Close
      </Button>
    </UserGuidance>
  )
}

export const FacebookAdsModalRaw: React.FunctionComponent<Props> = ({
  accounts,
  isLoading,
  isOpen,
  onClose,
  error,
}: Props) => {
  const [hasDispatched, setHasDispatched] = useState<boolean>(false)
  const hasAccounts = accounts.length > 0

  if (isOpen && !hasDispatched && typeof window !== 'undefined') {
    setHasDispatched(true)
    dispatch(getFacebookAdsAccounts())
  }
  return (
    <PostAuthModal isOpen={isOpen} onClose={onClose}>
      {((): React.ReactElement => {
        if (!hasDispatched || isLoading) {
          return (
            <UserGuidance
              renderIcon={(): React.ReactNode => (
                <Spinner loading={true} height={50} width={320} />
              )}
              title=""
              subtitle=""
            />
          )
        } else if (hasAccounts) {
          return (
            <SelectFacebookAdsAccountForm
              onClose={onClose}
              accounts={accounts}
            />
          )
        } else {
          return (
            <ErrorModal
              error={error}
              onClose={onClose}
              hasAccounts={hasAccounts}
            />
          )
        }
      })()}
    </PostAuthModal>
  )
}

const mapStateToProps = ({
  welcome: {
    facebookAdsAccounts: { accounts, isLoading, isPutting, didPut, error },
  },
}: Store): FacebookModalReduxProps => {
  return {
    accounts: accounts,
    isLoading,
    isPutting,
    didPut,
    error,
  }
}

export const FacebookAdsModal = connect(mapStateToProps)(
  React.memo(FacebookAdsModalRaw),
)
