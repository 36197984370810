import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getFeatures } from './features'
import { Feature } from '../../../server/data/models'
import { LoadingAndError } from '../types'

const FEATURES_KEY = 'features'

export type FeaturesPayload = {
  [FEATURES_KEY]: Feature[] | null
} & LoadingAndError

// REDUCERS
type FeaturesStoreData = {
  [FEATURES_KEY]: FeaturesPayload
}

export type FeaturesData = Readonly<FeaturesStoreData>

const initialFeaturesState: FeaturesData = {
  [FEATURES_KEY]: {
    [FEATURES_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const featuresReducer = createReducer(initialFeaturesState, builder => {
  // GET FEATURES
  createThunkReducer(
    builder,
    getFeatures.typePrefix,
    FEATURES_KEY,
    (state, action) =>
      ({
        ...state,
        [FEATURES_KEY]: {
          ...state[FEATURES_KEY],
          isLoading: null,
          error: null,
          [FEATURES_KEY]: action.payload.features,
        },
      } as FeaturesData),
  )
})
