import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { GridColumns, GridRowModel } from '@mui/x-data-grid-pro'
import { ApolloError } from '@apollo/react-hooks'
import { DateGranularity } from '../../../../../server/app-data-service/generatedTypes'

export enum SalesForecastDataCategory {
  Actuals = 'Actuals',
  Forecast = 'Forecast',
}

export type Account = {
  id: string
  name: string
  isSelected: boolean
}

export type SalesForecastChartRow = {
  y: number | null
  trend: number | null
  yhat: number | null
  ds: string
}

export type SalesForecastTableGroups = {
  actuals: {
    field: string
  }[]
  forecasted: {
    field: string
  }[]
}

export interface SalesForecastCalculatedStateType {
  chartRows?: SalesForecastChartRow[]
  tableColumns?: GridColumns
  tableRows?: GridRowModel[]
  tableGroups?: SalesForecastTableGroups
  totalActualValue?: number
  totalProjectedValue?: number
}

export type SalesForecastContextType = SalesForecastCalculatedStateType & {
  apiRef: React.MutableRefObject<GridApiPro>
  handleExportData: () => void
  duration: number
  granularity: DateGranularity
  handleTimePeriodChange: (durationGranularityString: string) => void
  accounts: Account[]
  setFilterAccounts: React.Dispatch<React.SetStateAction<string[] | null>>
  isLoading: boolean
  hasError?: ApolloError
}
