import React from 'react'

import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import { DialogCloseIcon } from '../../../../components/designSystem/atoms/DialogCloseIcon/DialogCloseIcon'
import { InfiniteGrid } from '../../../../components/designSystem/organisms/InfiniteGrid/InfiniteGrid'

const bodyStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: '4px',
  p: '24px',
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
  width: '733px',
}

type SegmentDetail = {
  title: string
  value: string
}

interface Props {
  details: SegmentDetail[]
  handleClose: () => void
  isOpen: boolean
  products: string[]
  title: string
}

export const SegmentDetailModal = ({
  details,
  handleClose,
  isOpen,
  products,
  title,
}: Props): JSX.Element => (
  <Modal open={isOpen} onClose={handleClose}>
    <Box sx={bodyStyle}>
      <Stack spacing={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h7">{title}</Typography>
          </Box>
          <Box>
            <DialogCloseIcon onClick={handleClose} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          {details.map(({ title, value }) => (
            <Box key={title} sx={{ flexGrow: 1 }}>
              <Card variant="outlined">
                <CardContent>
                  <Stack gap="5px">
                    <Typography variant="body2">{title}</Typography>
                    <Typography variant="h7">{value}</Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Products in segment</Typography>
          <Box sx={{ height: '175px', overflow: 'scroll' }}>
            <InfiniteGrid columns={3} items={products} />
          </Box>
        </Stack>
      </Stack>
    </Box>
  </Modal>
)
