import { ReactNode } from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import { TrendLabelData } from '../../atoms/TrendLabel/types'

export enum LineGraphPresentationTypes {
  LargeMoney = 'largeMoney',
  SmallMoney = 'smallMoney',
  Volume = 'volume',
}

export type LegendItem = {
  name: string
  value: number
  trendLabelData: TrendLabelData[]
}

export type Props = {
  title: string
  presentationType: LineGraphPresentationTypes
  chartData: Record<string, any>[]
  legendData: LegendItem[]
  emptyState?: ReactNode
  isEmpty?: boolean
  isLoading?: boolean
  shouldExcludeTotal?: boolean
  sx?: SxProps<Theme>
  tooltipDescription?: React.ReactNode
}
