import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { FlexibleActionMenu } from '../../../../../components/designSystem/molecules/FlexibleActionMenu/FlexibleActionMenu'
import { Color } from '../../../../../Color'

import { TimePeriods } from '../../Overview.types'
import { Connection } from '../../../../../modules/connectors/types'
import { useOverviewDatePicker } from './OverviewHeadingSection.hooks'

type Props = {
  bankingConnections: Connection[]
  dateRange: string
  expenseConnections: Connection[]
  handleTimePeriodSelect: (key: TimePeriods, value: string) => void
  revenueConnections: Connection[]
  timePeriod: string
  timePeriodOptions: {
    key: TimePeriods
    value: string
  }[]
}

export const OverviewHeadingSection: React.FunctionComponent<Props> = ({
  bankingConnections,
  dateRange,
  expenseConnections,
  handleTimePeriodSelect,
  revenueConnections,
  timePeriod,
  timePeriodOptions,
}) => {
  const { shouldRenderDatePicker } = useOverviewDatePicker({
    bankingConnections,
    expenseConnections,
    revenueConnections,
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
          padding: '10px 0',
          alignItems: 'center',
        }}
      >
        {shouldRenderDatePicker && (
          <>
            <FlexibleActionMenu<TimePeriods>
              handleOnSelect={handleTimePeriodSelect}
              options={timePeriodOptions}
              value={timePeriod}
              sx={{ width: '110px' }}
            />
            <Typography variant="body1" color={Color.Black}>
              {dateRange}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
