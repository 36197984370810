import { styled } from '@mui/material/styles'

export const Table = styled('table')`
  font-family: DroidSansMonoWXX-Regular;
  border-collapse: collapse;
  table-layout: auto;
  text-align: right;
  width: 100%;

  tbody tr:not(:first-child) th {
    font-weight: var(--font-weight--normal);
  }

  th:first-child {
    text-align: left;
  }

  tr:nth-child(2) {
    background: #f7f7f7;
  }

  td,
  th {
    padding: 0.25em 0.5em;
  }
`

export const HeadingTr = styled('tr')`
  font-weight: var(--font-weight--semibold);
  white-space: nowrap;
  td,
  th {
    padding-top: 20px;
    :first-child {
      text-transform: uppercase;
    }
  }
`
