import { Color } from '../Color'

export const mapBrandNameToColor = (brandName?: string): Color => {
  if (!brandName) return Color.Blue

  const brandNameToColorMap: { [key: string]: Color } = {
    shopifySales: Color.Shopify,
    amazonSales: Color.Amazon,
    amazonAds: Color.Amazon,
    facebookAds: Color.Facebook,
    googleAds: Color.GoogleAds,
    balanceCents: Color.Blue,
    cashInflowCents: Color.Shopify,
    cashOutflowCents: Color.AccountingRed,
  }

  return brandNameToColorMap[brandName] ?? Color.Blue
}
