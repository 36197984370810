import { styled } from '@mui/material/styles'
import React from 'react'
import { Headline } from '../../../components/designSystem/molecules/Headline/Headline'
import { HeatSection, Row } from './section'

const SectionWrap = styled('div')`
  margin: 0 0 1.75rem 0;
`

type ProjectedProps = {
  cohorts: Row[]
  min: number
  max: number
}

export const ByCohortSection: React.FunctionComponent<ProjectedProps> = ({
  cohorts,
  min,
  max,
}) => {
  return (
    <div>
      <Headline
        sticky={true}
        title="Cumulative Spend by Customer (historical)"
      />
      <SectionWrap>
        <HeatSection data={cohorts} min={min} max={max} />
      </SectionWrap>
    </div>
  )
}
