import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import { getAdminDashboardData } from './adminDashboard'
import { LoadingAndError } from '../types'
import { AdminDashboardCompanyResults } from '../../../server/routes/admin/dashboard'

const ADMIN_DASHBOARD__KEY = 'adminDashboard'

export type AdminDashboardPayload = {
  [ADMIN_DASHBOARD__KEY]: AdminDashboardCompanyResults | null
} & LoadingAndError

// REDUCERS
type AdminDashboardStoreData = {
  [ADMIN_DASHBOARD__KEY]: AdminDashboardPayload
}

export type AdminDashboardData = Readonly<AdminDashboardStoreData>

const initialCompanyFeaturesState: AdminDashboardData = {
  [ADMIN_DASHBOARD__KEY]: {
    [ADMIN_DASHBOARD__KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const adminDashboadReducer = createReducer(
  initialCompanyFeaturesState,
  builder => {
    // GET DEMAND FORECAST DATA
    createThunkReducer(
      builder,
      getAdminDashboardData.typePrefix,
      ADMIN_DASHBOARD__KEY,
      (state, action) =>
        ({
          ...state,
          [ADMIN_DASHBOARD__KEY]: {
            ...state[ADMIN_DASHBOARD__KEY],
            isLoading: false,
            error: action.payload.errorMessages.join(','),
            [ADMIN_DASHBOARD__KEY]: action.payload,
          },
        } as AdminDashboardData),
    )
  },
)
