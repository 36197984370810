import {
  useBalanceCentsQuery,
  useGetCacByDurationQuery,
  useGetCashPositionSeriesByDurationQuery,
  useGetCustomersByDurationQuery,
  useGetSalesByDurationQuery,
  useRoasByDurationQuery,
} from '../../../../../../server/app-data-service/generatedTypes'
import { TimeParams } from '../../Overview.types'

import { OverviewHighlightMetrics } from '../../Overview.types'
import {
  HightlightMetric,
  determineIsLoading,
  getHighlightMetric,
} from './OverviewHighlightBar.utils'

type HookResponse = {
  highlightCategories: HightlightMetric[]
  isLoading: boolean
  shouldHide: boolean
}
export const useOverviewHighlightBar = ({
  duration,
  granularity,
}: TimeParams): HookResponse => {
  const {
    data: customerData,
    loading: isCustomerDataLoading,
  } = useGetCustomersByDurationQuery({
    variables: { duration, granularity },
  })

  const { data: cacData, loading: isCacDataLoading } = useGetCacByDurationQuery(
    {
      variables: { duration, granularity },
    },
  )

  const {
    data: bankingData,
    loading: isBankingDataLoading,
  } = useGetCashPositionSeriesByDurationQuery({
    variables: { duration, granularity },
  })

  const {
    data: salesData,
    loading: isSalesDataLoading,
  } = useGetSalesByDurationQuery({
    variables: { duration, granularity },
  })

  const { data: roasData, loading: isRoasDataLoading } = useRoasByDurationQuery(
    {
      variables: { duration, granularity },
    },
  )

  const revenueHighlight = getHighlightMetric(
    OverviewHighlightMetrics.OrderRevenue,
    salesData?.SalesByDuration?.revenue?.totals,
  )

  const cashBalanceHighlight = getHighlightMetric(
    OverviewHighlightMetrics.CashBalance,
    bankingData?.getCashPositionSeriesByDuration?.balanceCents?.totals,
  )

  const roasHighlight = getHighlightMetric(
    OverviewHighlightMetrics.ROAS,
    roasData?.RoasByDuration?.roas,
  )

  const cacHighlight = getHighlightMetric(
    OverviewHighlightMetrics.CAC,
    cacData?.CacByDuration?.totalCac,
  )

  const customerHighlight = getHighlightMetric(
    OverviewHighlightMetrics.NewCustomers,
    customerData?.CustomersByDuration?.newCustomers?.totals,
  )

  const aovHighlight = getHighlightMetric(
    OverviewHighlightMetrics.AOV,
    salesData?.SalesByDuration?.averageOrderValue?.totals,
  )

  const orderHighlight = getHighlightMetric(
    OverviewHighlightMetrics.OrderVolume,
    salesData?.SalesByDuration?.orderVolume?.totals,
  )

  const highlights = [
    revenueHighlight,
    cashBalanceHighlight,
    roasHighlight,
    cacHighlight,
    customerHighlight,
    aovHighlight,
    orderHighlight,
  ]
    .filter((metric): metric is HightlightMetric => !!metric)
    .slice(0, 6)

  const isLoading = determineIsLoading([
    isCustomerDataLoading,
    isCacDataLoading,
    isBankingDataLoading,
    isSalesDataLoading,
    isRoasDataLoading,
  ])

  return {
    highlightCategories: highlights,
    isLoading,
    shouldHide: !isLoading && highlights.length < 3,
  }
}
