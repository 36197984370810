import React from 'react'
import { TooltipPayload } from 'recharts'
import DateRange from '@mui/icons-material/DateRange'
import SquareIcon from '@mui/icons-material/Square'
import Circle from '@mui/icons-material/Circle'
import { Typography } from '@mui/material'
import { Color } from '../../../../../Color'
import { numberAsCommaDelimitiedMoney } from '../../../../../util/displayValue'

export const labelFormatter = (label: string | number): React.ReactNode => {
  return (
    <>
      <DateRange sx={{ fontSize: '14px' }} />
      <Typography variant="body1">Period:</Typography>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
    </>
  )
}

export const dataFormatter = (data: TooltipPayload): React.ReactNode => {
  const { dataKey, value, fill } = data

  const mapDataKeyToDisplayName: { [key: string]: string } = {
    cashInflows: 'Inflow',
    cashOutflows: 'Outflow',
    cashBalance: 'Balance',
  }

  return (
    <>
      {dataKey === 'cashInflows' || dataKey === 'cashOutflows' ? (
        <SquareIcon sx={{ fontSize: '14px', color: fill }} />
      ) : (
        <Circle
          stroke={fill}
          strokeWidth={3}
          sx={{ fontSize: '14px', color: Color.LightBlue }}
        />
      )}
      <Typography variant="body1">
        {mapDataKeyToDisplayName[String(dataKey)]}:
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {numberAsCommaDelimitiedMoney(Number(value))}
      </Typography>
    </>
  )
}
