import { ReactNode } from 'react'

import {
  GetSalesByDurationQuery,
  TimeSeriesMetric,
} from '../../../../../../server/app-data-service/generatedTypes'

import { OverviewCardTypes } from '../../Overview.types'
import { RevenueCards } from './OverviewRevenueCard.types'

const cardTypeValueHandlers: {
  [key in RevenueCards]: (
    data: GetSalesByDurationQuery,
  ) => TimeSeriesMetric | null
} = {
  [OverviewCardTypes.AOV]: data =>
    data?.SalesByDuration?.averageOrderValue ?? null,
  [OverviewCardTypes.OrderVolume]: data =>
    data?.SalesByDuration?.orderVolume ?? null,
  [OverviewCardTypes.Units]: data => data?.SalesByDuration?.unitVolume ?? null,
  [OverviewCardTypes.OrderRevenue]: data =>
    data?.SalesByDuration?.revenue ?? null,
}

export const extractMetricValueByType = (
  type: RevenueCards,
  data: GetSalesByDurationQuery,
): TimeSeriesMetric | null => (data ? cardTypeValueHandlers[type](data) : null)
