import React from 'react'
import Modal from 'react-modal'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import { Color } from '../../../../Color'
import { ActionButton } from '../../../../components/designSystem/atoms/ActionButton/ActionButton'

const ModalHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.93rem 2.19rem 0 2.19rem;',
}

const ModalFooterStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1.93rem 2.19rem',
}

const ModalCloseButtonStyles = {
  float: 'right',
  border: 'none',
  borderRadius: '4px',
  fontFamily: 'var(--font-family)',
  fontWeight: 'var(--font-weight--medium)',
  fontSize: '1.30rem',
  cursor: 'pointer',
  marginRight: '0.5rem',
  backgroundColor: `${Color.White}`,
  '&:active:enabled': {
    opacity: 0.4,
  },
}

export type BaseModalControlProps = {
  isOpen: boolean
  onClose: () => void
}

type Props = BaseModalControlProps & {
  title: string
  onSubmit: (evt: React.MouseEvent<HTMLButtonElement>) => void
  disableSubmit: boolean
}

export const BaseModal: React.FunctionComponent<Props> = ({
  title,
  onSubmit,
  disableSubmit,
  isOpen,
  onClose,
  children,
}) => {
  Modal.setAppElement('#root')

  return (
    <Modal isOpen={isOpen} className={'Modal'} overlayClassName="Modal-Overlay">
      <Box sx={ModalCloseButtonStyles} onClick={(): void => onClose()}>
        &#10005;
      </Box>
      <Box sx={ModalHeaderStyles}>
        <Hidden smDown>
          <Typography variant="h5">{title}</Typography>
        </Hidden>
        <Hidden smUp>
          <Typography variant="h6">{title}</Typography>
        </Hidden>
      </Box>
      {children}
      <Box sx={ModalFooterStyles}>
        <ActionButton
          buttonType="secondary"
          text="Cancel"
          onClick={(): void => onClose()}
        />
        <ActionButton
          buttonType="primary"
          text="Next"
          onClick={onSubmit}
          disabled={disableSubmit}
        />
      </Box>
    </Modal>
  )
}
