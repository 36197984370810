import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { Layout } from '../../../layout'
import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'
import {
  getConnectors,
  selectExpenseConnectors,
  selectIsLoaded,
  selectRevenueConnectors,
} from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'
import { useInterval } from '../../../hooks'

import {
  ConnectionsLoading,
  DataSyncing,
  MissingConnection,
  NoConnections,
} from './'

type RenderReportProps = {
  disableMonthly: boolean
  oneYearOfDataCompleted: boolean
}

type WrapperProps = Readonly<{
  connectionsLoaded: boolean
  revenueConnectors: Connection[]
  expenseConnectors: Connection[]
}>

type Props = WrapperProps & {
  renderReport: (props: RenderReportProps) => JSX.Element
  title: string
}

const AdsAndRevenueWrapperImpl: React.FunctionComponent<Props> = ({
  connectionsLoaded,
  revenueConnectors,
  expenseConnectors,
  title,
  renderReport,
}) => {
  useEffect(() => {
    dispatch(getConnectors())
  }, [])
  useInterval(() => {
    dispatch(getConnectors())
  }, 5000)

  const loadingConnectors = [...revenueConnectors, ...expenseConnectors].filter(
    c => c.percentOfWeekComplete !== 100,
  )

  const disableMonthly = !(
    expenseConnectors.some(
      c =>
        c.percentOfYearComplete === 100 || c.initialSyncStatus === 'complete',
    ) &&
    revenueConnectors.some(
      c =>
        c.percentOfYearComplete === 100 || c.initialSyncStatus === 'complete',
    )
  )

  const oneYearOfDataCompleted =
    expenseConnectors.some(c => c.percentOfYearComplete === 100) &&
    revenueConnectors.some(c => c.percentOfYearComplete === 100)

  return (
    <Layout>
      {(() => {
        if (!connectionsLoaded) {
          return (
            <ConnectionsLoading connections={loadingConnectors}>
              {null}
            </ConnectionsLoading>
          )
        } else if (
          expenseConnectors.length === 0 &&
          revenueConnectors.length === 0
        ) {
          return (
            <NoConnections
              title={title}
              subtitle={
                'To get started, connect a revenue and ad spend account.'
              }
            />
          )
        } else if (expenseConnectors.length === 0) {
          return (
            <MissingConnection
              subtitle={
                'For a complete experience, we also need an ad spend account.'
              }
              buttonText={'Connect ad spend'}
            />
          )
        } else if (revenueConnectors.length === 0) {
          return (
            <MissingConnection
              subtitle={
                'For a complete experience, we also need a revenue account.'
              }
              buttonText={'Connect revenue'}
            />
          )
        } else if (
          !expenseConnectors.some(c => c.percentOfWeekComplete === 100) ||
          !revenueConnectors.some(c => c.percentOfWeekComplete === 100)
        ) {
          return (
            <ConnectionsLoading connections={loadingConnectors}>
              <DataSyncing />
            </ConnectionsLoading>
          )
        } else {
          return renderReport({
            disableMonthly: disableMonthly,
            oneYearOfDataCompleted,
          })
        }
      })()}
    </Layout>
  )
}

const mapStateToProps = (store: Store): WrapperProps => {
  return {
    connectionsLoaded: selectIsLoaded(store.connectors),
    revenueConnectors: selectRevenueConnectors(store.connectors),
    expenseConnectors: selectExpenseConnectors(store.connectors),
  }
}

export const AdsAndRevenueWrapper = connect(mapStateToProps)(
  React.memo(AdsAndRevenueWrapperImpl),
)
