import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { dispatch } from '../../../store'
import { updatePassword } from '../../../modules/user/user'
import { Store } from '../../../modules/rootReducer'
import { resetPasswordLinkValidation } from '../../../modules/user/user'
import { SpeedBumpModal } from '../../designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { Caution } from '../../designSystem/assets/Caution/Caution'
import { PasswordInput } from '../../designSystem/molecules/PasswordInput/PasswordInput'
import { PasswordUpdated } from './PasswordUpdated'

interface Props {
  resetPassword?: any
}

const StackedMargin = styled(Stack)`
  margin: 40px 0px;
  width: 100%;
`

const StyledForm = styled('form')`
  width: 100%;
`

const NewPasswordForm = ({ userId }: { userId: string }): JSX.Element => {
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(5, 'Enter a minimum of 5 characters'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      dispatch(
        updatePassword({
          userId,
          password: values.password,
        }),
      )
    },
  })
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={
            formik.touched.password ? formik.errors.password : undefined
          }
          onBlur={formik.handleBlur}
        />
      </Stack>
      <StackedMargin spacing={3}>
        <Button
          disabled={!(formik.isValid && formik.dirty)}
          type="submit"
          variant="contained"
          color="success"
          size={'large'}
          style={{ height: '45px', width: '100%' }}
        >
          Update
        </Button>
      </StackedMargin>
    </StyledForm>
  )
}

const RenderModal = ({
  title,
  text,
  children,
  secondaryContent,
}: {
  title: string
  text: string
  children?: React.ReactNode
  secondaryContent?: JSX.Element
}): JSX.Element => {
  return (
    <SpeedBumpModal
      title={title}
      text={text}
      secondaryContent={secondaryContent}
    >
      {!children ? <Caution height={98} width={114} /> : children}
    </SpeedBumpModal>
  )
}

const ResetPasswordForm: React.FC<Props> = ({ resetPassword }): JSX.Element => {
  const location = useLocation()
  const [invalidToken, setInvalidToken] = useState(false)
  const { error, userId, updated } = resetPassword

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    if (token) {
      dispatch(resetPasswordLinkValidation(token))
    } else {
      setInvalidToken(true)
    }
  }, [])

  const renderContent = (): JSX.Element => {
    if (error) {
      return (
        <RenderModal title="Reset password link" text={error.payload || ''} />
      )
    }
    if (invalidToken) {
      return <RenderModal title="Reset password link" text="Invalid Link" />
    }
    if (!updated) {
      return (
        <RenderModal
          title="New password"
          text="Password must contain 5 characters."
          secondaryContent={<NewPasswordForm userId={userId} />}
        >
          <></>
        </RenderModal>
      )
    }
    if (updated) return <PasswordUpdated />
    return <></>
  }

  return renderContent()
}

const mapStateToProps = ({
  resetPassword: { resetPassword },
}: Store): Props => ({
  resetPassword,
})

export const ResetPassword = connect(mapStateToProps)(ResetPasswordForm)
