import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import InfoIcon from '@mui/icons-material/Info'
import { dispatch } from '../../store'
import { Layout } from '../../layout'
import { getConnectors } from '../../modules/connectors'
import { EmailLink } from '../../components/forms/StyledElements'
import { Breadcrumb } from '../../components/designSystem/molecules/Breadcrumb/Breadcrumb'
import { Connector } from './components/Connector'
import { TimedMessageFromQueryParam, useInterval } from '../../hooks'
import { useConnectionsNavigation } from '../../hooks/useNavigationCampaign'
import { NewConnection } from './components/NewConnection'
import { connect } from 'react-redux'
import { selectConnectors } from '../../modules/connectors'
import { Store } from '../../modules/rootReducer'
import { Connection } from '../../modules/connectors/types'
import { Color } from '../../../ui/Color'

import {
  ACCOUNTING_CONNECTORS,
  ALLOW_MULTIPLE_CONNECTIONS,
  BANKING_CONNECTORS,
  CONNECTORS_CATEGORIES,
  EXPENSE_CONNECTORS,
  KNOWN_CONNECTORS,
  KnownConnectors,
  REVENUE_CONNECTORS,
} from '../../../server/connectors/known-connectors'

type Props = {
  connections?: Connection[]
}

const renderAvailableConnector = (
  connectors: KnownConnectors[],
  title: string,
) => {
  return (
    connectors.length > 0 && (
      <Box>
        <Typography variant="subtitle1" mb={2}>
          {title}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2, sm: 1.5 }}
        >
          {connectors.map(c => (
            <NewConnection key={c} providerName={c} />
          ))}
        </Stack>
      </Box>
    )
  )
}

const isMultipleConnectionAllowed = (connector: KnownConnectors) =>
  ALLOW_MULTIPLE_CONNECTIONS.includes(connector)

const getAvailableConnectionsByType = (
  currentConnections: Connection[],
  connectors: Array<KnownConnectors>,
) => {
  return connectors.filter(
    key =>
      !currentConnections.find(
        connection =>
          connection.key === key && !isMultipleConnectionAllowed(key),
      ),
  )
}

const sortConnectionsByCategory = (connections: Connection[]) => {
  return connections.sort((a: Connection, b: Connection) => {
    const categoryOrder = [
      CONNECTORS_CATEGORIES.asset,
      CONNECTORS_CATEGORIES.banking,
      CONNECTORS_CATEGORIES.expense,
      CONNECTORS_CATEGORIES.accounting,
    ]
    const connectionAKey = KNOWN_CONNECTORS[a.key].category
    const connectionBKey = KNOWN_CONNECTORS[b.key].category
    return (
      categoryOrder.indexOf(connectionAKey) -
      categoryOrder.indexOf(connectionBKey)
    )
  })
}

const ConnectorsImpl: React.FunctionComponent<Props> = ({ connections }) => {
  const activeConnections = sortConnectionsByCategory(
    connections?.filter(
      connection =>
        !connection.invalidatedAt && KNOWN_CONNECTORS[connection.key],
    ) || [],
  )
  const invalidConnections = sortConnectionsByCategory(
    connections?.filter(
      connection =>
        connection.invalidatedAt && KNOWN_CONNECTORS[connection.key],
    ) || [],
  )
  const currentConnections = [...activeConnections, ...invalidConnections]
  const availableRevenueConnections = getAvailableConnectionsByType(
    currentConnections,
    REVENUE_CONNECTORS,
  )
  const availableBankingConnections = getAvailableConnectionsByType(
    currentConnections,
    BANKING_CONNECTORS,
  )
  const availableAdSpendConnections = getAvailableConnectionsByType(
    currentConnections,
    EXPENSE_CONNECTORS,
  )
  const availableAccountingConnections = getAvailableConnectionsByType(
    currentConnections,
    ACCOUNTING_CONNECTORS,
  )

  const navigate = useConnectionsNavigation()
  const showBreadcrumb = TimedMessageFromQueryParam('isUpgrade', 'true')

  // poll for connectors after initial fetch
  useEffect(() => {
    navigate()
    dispatch(getConnectors())
  }, [])
  useInterval(() => {
    dispatch(getConnectors())
  }, 5000)

  return (
    <Layout background={Color.LightestGrey}>
      {showBreadcrumb && (
        <Breadcrumb message={'Subscription updated successfully'} />
      )}
      <Alert
        severity="info"
        icon={<InfoIcon fontSize="inherit" />}
        sx={{
          alignItems: 'center',
          margin: '22px 0',
          border: `1px solid ${Color.LightBlue}`,
        }}
      >
        <Typography variant="body1">
          Depending on your store&apos;s size, data import may take 30 minutes
          to 24 hours. You&apos;ll be notified via email once the sync is
          complete. For inquiries, contact{' '}
          <EmailLink href="mailto:support@brightflow.ai">
            support@brightflow.ai
          </EmailLink>
          .
        </Typography>
      </Alert>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '30px',
          backgroundColor: Color.White,
          borderRadius: '4px',
          border: `1px solid ${Color.LightGrey}`,
        }}
      >
        {invalidConnections?.length > 0 && (
          <Stack spacing={3} maxWidth="700px" pb={4}>
            <Typography variant="h6" fontWeight={600}>
              Needs Attention
            </Typography>
            <Stack spacing={1.5}>
              {invalidConnections.map(invalidConnection => {
                const { key, id } = invalidConnection
                return (
                  <Connector
                    key={key}
                    providerName={key}
                    providerId={id}
                    category={KNOWN_CONNECTORS[key].category}
                  />
                )
              })}
            </Stack>
          </Stack>
        )}
        {activeConnections?.length > 0 && (
          <Stack spacing={3} maxWidth="700px" pb={4}>
            <Typography variant="h6" fontWeight={600}>
              Active
            </Typography>
            <Stack spacing={1.5}>
              {activeConnections.map(activeConnection => {
                const { key, id } = activeConnection
                return (
                  <Connector
                    key={id}
                    providerName={key}
                    providerId={id}
                    category={KNOWN_CONNECTORS[key].category}
                  />
                )
              })}
            </Stack>
          </Stack>
        )}
        <Typography variant="h6" mb={'20px'} fontWeight={600}>
          Available
        </Typography>
        <Stack spacing={3} maxWidth="700px">
          {renderAvailableConnector(
            availableBankingConnections,
            'Financial Institutions',
          )}
          {renderAvailableConnector(
            availableRevenueConnections,
            'Online Revenue',
          )}
          {renderAvailableConnector(availableAdSpendConnections, 'Ad Spend')}
          {renderAvailableConnector(
            availableAccountingConnections,
            'Accounting',
          )}
        </Stack>
      </Box>
    </Layout>
  )
}

const mapStateToProps = (state: Store): Props => {
  const connections = selectConnectors(state.connectors)
  return {
    connections,
  }
}

export const ConnectorsScreen = connect(mapStateToProps)(ConnectorsImpl)
