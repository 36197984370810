import React from 'react'
import { styled } from '@mui/material/styles'

const StyledSvg = styled('svg')`
  border-radius: 4px;
`

interface Props {
  height: string
  width: string
  viewBox?: string
}

export const HeroStripes: React.FunctionComponent<Props> = props => {
  const { height, width, viewBox } = props
  return (
    <StyledSvg
      width={`${width}`}
      height={`${height}`}
      viewBox={viewBox || `0 150 25 200`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Check Circle</title>
      <g opacity="0.45">
        <mask
          id="mask0_8_2"
          maskUnits="userSpaceOnUse"
          x="17"
          y="47"
          width="785"
          height="585"
        >
          <path
            opacity="0.5"
            d="M681.597 198.248C290.523 342.56 503.064 370.075 499.095 445.646C493.616 549.996 308.923 623.071 308.923 623.071C308.923 623.071 220.573 652.018 145.667 600.201C97.2539 566.712 59.7415 558.486 26.9078 480.676C-5.92592 402.865 50.5903 301.552 106.981 245.295C174.127 178.309 252.051 142.997 303.699 120.688C356.132 98.0408 543.813 47.0362 617.779 47.3428C721.829 47.7742 937.424 103.844 681.597 198.248Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_8_2)">
          <path
            d="M1352.35 -168.036L1341.14 -172.838L108.483 963.329L119.691 968.131L1352.35 -168.036Z"
            fill="white"
          />
          <path
            d="M1329.94 -177.64L1318.73 -182.441L86.0664 953.726L97.2746 958.527L1329.94 -177.64Z"
            fill="white"
          />
          <path
            d="M1307.52 -187.243L1296.31 -192.045L63.65 944.122L74.8582 948.924L1307.52 -187.243Z"
            fill="white"
          />
          <path
            d="M1285.1 -196.847L1273.9 -201.649L41.2335 934.518L52.4417 939.32L1285.1 -196.847Z"
            fill="white"
          />
          <path
            d="M1262.69 -206.45L1251.48 -211.252L18.8171 924.915L30.0253 929.717L1262.69 -206.45Z"
            fill="white"
          />
          <path
            d="M1240.27 -216.054L1229.06 -220.856L-3.59937 915.311L7.60885 920.113L1240.27 -216.054Z"
            fill="white"
          />
          <path
            d="M1217.85 -225.658L1206.65 -230.459L-26.0158 905.708L-14.8076 910.509L1217.85 -225.658Z"
            fill="white"
          />
          <path
            d="M1195.44 -235.261L1184.23 -240.063L-48.4323 896.104L-37.224 900.906L1195.44 -235.261Z"
            fill="white"
          />
          <path
            d="M1173.02 -244.865L1161.81 -249.667L-70.8487 886.5L-59.6405 891.302L1173.02 -244.865Z"
            fill="white"
          />
          <path
            d="M1150.61 -254.468L1139.4 -259.27L-93.2651 876.897L-82.0569 881.699L1150.61 -254.468Z"
            fill="white"
          />
          <path
            d="M1128.19 -264.072L1116.98 -268.874L-115.682 867.293L-104.473 872.095L1128.19 -264.072Z"
            fill="white"
          />
          <path
            d="M1105.77 -273.675L1094.56 -278.477L-138.098 857.69L-126.89 862.492L1105.77 -273.675Z"
            fill="white"
          />
          <path
            d="M1083.36 -283.279L1072.15 -288.081L-160.514 848.086L-149.306 852.888L1083.36 -283.279Z"
            fill="white"
          />
          <path
            d="M1060.94 -292.883L1049.73 -297.684L-182.931 838.483L-171.723 843.284L1060.94 -292.883Z"
            fill="white"
          />
          <path
            d="M1038.52 -302.486L1027.31 -307.288L-205.347 828.879L-194.139 833.681L1038.52 -302.486Z"
            fill="white"
          />
          <path
            d="M1016.11 -312.09L1004.9 -316.892L-227.764 819.275L-216.556 824.077L1016.11 -312.09Z"
            fill="white"
          />
          <path
            d="M993.69 -321.693L982.482 -326.495L-250.18 809.672L-238.972 814.474L993.69 -321.693Z"
            fill="white"
          />
          <path
            d="M971.274 -331.297L960.065 -336.099L-272.597 800.068L-261.388 804.87L971.274 -331.297Z"
            fill="white"
          />
          <path
            d="M948.857 -340.901L937.649 -345.702L-295.013 790.465L-283.805 795.266L948.857 -340.901Z"
            fill="white"
          />
          <path
            d="M926.441 -350.504L915.233 -355.306L-317.43 780.861L-306.221 785.663L926.441 -350.504Z"
            fill="white"
          />
          <path
            d="M904.024 -360.108L892.816 -364.91L-339.846 771.257L-328.638 776.059L904.024 -360.108Z"
            fill="white"
          />
          <path
            d="M881.608 -369.711L870.4 -374.513L-362.262 761.654L-351.054 766.456L881.608 -369.711Z"
            fill="white"
          />
          <path
            d="M859.191 -379.315L847.983 -384.117L-384.679 752.05L-373.471 756.852L859.191 -379.315Z"
            fill="white"
          />
          <path
            d="M836.775 -388.918L825.567 -393.72L-407.095 742.447L-395.887 747.248L836.775 -388.918Z"
            fill="white"
          />
          <path
            d="M814.359 -398.522L803.15 -403.324L-429.512 732.843L-418.304 737.645L814.359 -398.522Z"
            fill="white"
          />
          <path
            d="M791.942 -408.126L780.734 -412.927L-451.928 723.24L-440.72 728.041L791.942 -408.126Z"
            fill="white"
          />
          <path
            d="M769.526 -417.729L758.317 -422.531L-474.345 713.636L-463.136 718.438L769.526 -417.729Z"
            fill="white"
          />
          <path
            d="M747.109 -427.333L735.901 -432.135L-496.761 704.032L-485.553 708.834L747.109 -427.333Z"
            fill="white"
          />
          <path
            d="M724.693 -436.936L713.485 -441.738L-519.178 694.429L-507.969 699.231L724.693 -436.936Z"
            fill="white"
          />
          <path
            d="M702.276 -446.54L691.068 -451.342L-541.594 684.825L-530.386 689.627L702.276 -446.54Z"
            fill="white"
          />
          <path
            d="M679.86 -456.144L668.652 -460.945L-564.01 675.222L-552.802 680.023L679.86 -456.144Z"
            fill="white"
          />
          <path
            d="M657.443 -465.747L646.235 -470.549L-586.427 665.618L-575.219 670.42L657.443 -465.747Z"
            fill="white"
          />
          <path
            d="M635.027 -475.351L623.819 -480.153L-608.843 656.014L-597.635 660.816L635.027 -475.351Z"
            fill="white"
          />
          <path
            d="M612.611 -484.954L601.402 -489.756L-631.26 646.411L-620.052 651.213L612.611 -484.954Z"
            fill="white"
          />
          <path
            d="M590.194 -494.558L578.986 -499.36L-653.676 636.807L-642.468 641.609L590.194 -494.558Z"
            fill="white"
          />
          <path
            d="M567.778 -504.162L556.569 -508.963L-676.093 627.204L-664.884 632.005L567.778 -504.162Z"
            fill="white"
          />
          <path
            d="M545.361 -513.765L534.153 -518.567L-698.509 617.6L-687.301 622.402L545.361 -513.765Z"
            fill="white"
          />
          <path
            d="M522.945 -523.369L511.737 -528.17L-720.926 607.996L-709.717 612.798L522.945 -523.369Z"
            fill="white"
          />
          <path
            d="M500.528 -532.972L489.32 -537.774L-743.342 598.393L-732.134 603.195L500.528 -532.972Z"
            fill="white"
          />
          <path
            d="M478.112 -542.576L466.904 -547.378L-765.758 588.789L-754.55 593.591L478.112 -542.576Z"
            fill="white"
          />
          <path
            d="M455.695 -552.179L444.487 -556.981L-788.175 579.186L-776.967 583.988L455.695 -552.179Z"
            fill="white"
          />
          <path
            d="M433.279 -561.783L422.071 -566.585L-810.591 569.582L-799.383 574.384L433.279 -561.783Z"
            fill="white"
          />
          <path
            d="M410.863 -571.387L399.654 -576.188L-833.008 559.979L-821.799 564.78L410.863 -571.387Z"
            fill="white"
          />
          <path
            d="M388.446 -580.99L377.238 -585.792L-855.424 550.375L-844.216 555.177L388.446 -580.99Z"
            fill="white"
          />
          <path
            d="M366.03 -590.594L354.821 -595.396L-877.841 540.771L-866.632 545.573L366.03 -590.594Z"
            fill="white"
          />
          <path
            d="M343.613 -600.197L332.405 -604.999L-900.257 531.168L-889.049 535.97L343.613 -600.197Z"
            fill="white"
          />
          <path
            d="M321.197 -609.801L309.989 -614.603L-922.673 521.564L-911.465 526.366L321.197 -609.801Z"
            fill="white"
          />
          <path
            d="M298.78 -619.405L287.572 -624.206L-945.09 511.961L-933.882 516.762L298.78 -619.405Z"
            fill="white"
          />
          <path
            d="M276.364 -629.008L265.156 -633.81L-967.506 502.357L-956.298 507.159L276.364 -629.008Z"
            fill="white"
          />
          <path
            d="M253.948 -638.612L242.739 -643.414L-989.923 492.753L-978.715 497.555L253.948 -638.612Z"
            fill="white"
          />
          <path
            d="M231.531 -648.215L220.323 -653.017L-1012.34 483.15L-1001.13 487.952L231.531 -648.215Z"
            fill="white"
          />
          <path
            d="M209.115 -657.819L197.906 -662.621L-1034.76 473.546L-1023.55 478.348L209.115 -657.819Z"
            fill="white"
          />
          <path
            d="M186.698 -667.422L175.49 -672.224L-1057.17 463.943L-1045.96 468.745L186.698 -667.422Z"
            fill="white"
          />
          <path
            d="M164.282 -677.026L153.074 -681.828L-1079.59 454.339L-1068.38 459.141L164.282 -677.026Z"
            fill="white"
          />
          <path
            d="M141.865 -686.63L130.657 -691.431L-1102.01 444.736L-1090.8 449.537L141.865 -686.63Z"
            fill="white"
          />
        </g>
      </g>
    </StyledSvg>
  )
}
