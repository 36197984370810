import React from 'react'
import { WomanCoinIcon } from '../../../components/designSystem/assets/WomanCoinIcon/WomanCoinIcon'
import { UserGuidanceBase } from '../../shared/'

interface Props {
  title?: string
  subtitle?: string
  message?: string
  icon?: JSX.Element
}

export const DataSyncing: React.FunctionComponent<Props> = ({
  title = 'Your data is syncing',
  subtitle = `We'll email you when it's ready.`,
  message = '',
  icon,
}) => {
  return (
    <UserGuidanceBase
      renderIcon={(): React.ReactNode =>
        icon || <WomanCoinIcon height={170} width={167} />
      }
      title={title}
      subtitle={subtitle}
      message={message}
    />
  )
}
