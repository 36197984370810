import { useEffect, useState } from 'react'
import { dispatch } from '../store'

export const useDispatchApiCall = (callbacks: Function[]): void => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!hasLoaded && typeof window !== 'undefined') {
      setHasLoaded(true)
      // trigger all specified callbacks
      callbacks.forEach(callback => {
        dispatch(callback())
      })
    }
  })
}
