import React from 'react'
import { IconProps } from '../../assets.types'

export const FacebookIcon: React.FunctionComponent<IconProps> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 65 65`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>FacebookIcon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="FacebookIcon" fillRule="nonzero">
          <path
            d="M65,32.5 C65,14.5507324 50.4492676,0 32.5,0 C14.5507324,0 0,14.5507324 0,32.5 C0,48.7214355 11.8847168,62.167041 27.421875,64.6051758 L27.421875,41.8945313 L19.1699219,41.8945313 L19.1699219,32.5 L27.421875,32.5 L27.421875,25.3398438 C27.421875,17.1945313 32.2740234,12.6953125 39.6976074,12.6953125 C43.2522949,12.6953125 46.9726562,13.3300781 46.9726562,13.3300781 L46.9726562,21.328125 L42.8746094,21.328125 C38.8375,21.328125 37.578125,23.8335449 37.578125,26.40625 L37.578125,32.5 L46.5917969,32.5 L45.1508789,41.8945313 L37.578125,41.8945313 L37.578125,64.6051758 C53.1152832,62.167041 65,48.7214355 65,32.5 Z"
            id="Path"
            fill="#1877F2"
          ></path>
          <path
            d="M45.1508789,41.8945312 L46.5917969,32.5 L37.578125,32.5 L37.578125,26.40625 C37.578125,23.836084 38.8375,21.328125 42.8746094,21.328125 L46.9726562,21.328125 L46.9726562,13.3300781 C46.9726562,13.3300781 43.2535645,12.6953125 39.6976074,12.6953125 C32.2740234,12.6953125 27.421875,17.1945313 27.421875,25.3398438 L27.421875,32.5 L19.1699219,32.5 L19.1699219,41.8945312 L27.421875,41.8945312 L27.421875,64.6051758 C30.7868267,65.1316075 34.2131733,65.1316075 37.578125,64.6051758 L37.578125,41.8945312 L45.1508789,41.8945312 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  )
}
