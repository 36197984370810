import { DateGranularity } from '../../../../server/app-data-service/generatedTypes'

export const HoursBeforeStaleSync = 24

export const TimePeriodFilterOptions: {
  granularity: DateGranularity
  granularityLabel: string
  defaultDuration: number
  minDuration?: number
  maxDuration: number
  isDefaultGranularity?: boolean
}[] = [
  {
    granularity: 'Day',
    granularityLabel: 'Days',
    defaultDuration: 30,
    minDuration: 7,
    maxDuration: 60,
  },
  {
    granularity: 'Week',
    granularityLabel: 'Weeks',
    defaultDuration: 13,
    maxDuration: 13,
    isDefaultGranularity: true,
  },
  {
    granularity: 'Month',
    granularityLabel: 'Months',
    defaultDuration: 6,
    maxDuration: 12,
  },
]

export const granularityToHeadingMap: { [key in DateGranularity]: string } = {
  Day: 'Day',
  FullMonth: 'Month',
  FullWeek: 'Week',
  Month: 'Month',
  Week: 'Week',
}

export const granularityToTableHeaderMap: {
  [key in DateGranularity]: string
} = {
  Day: 'Days',
  FullMonth: 'Months',
  FullWeek: 'Weeks',
  Month: 'Months',
  Week: 'Weeks',
}
