import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Color } from '../../../../Color'

export const TrendLabelContainer = styled(Box)`
  background-color: ${Color.LightestGrey};
  width: 60px;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
`
