import { api } from '../lib/api'
import React, { useEffect, useState } from 'react'

export interface Metric {
  key: string
  value: number
  step: number
  timestamp: number
}

export interface PerformanceMetrics {
  experimentId: string
  evaluationDate?: string
  optimizationDate?: string
  metrics: Metric[]
}

export interface ChannelMetricsResults {
  errorMessages: string[]
  results?: ChannelMetrics[]
}

export interface ChannelMetrics {
  channel: ForecastChannel
  metricsDetails: PerformanceMetrics
}

export enum OptimizationState {
  NotOptimized = 'Not optimized',
}

export enum ForecastChannel {
  Shopify = 'shopify',
  Amazon = 'amazon',
  Combined = 'combined',
}

export const usePerformanceMetrics = ({
  companyId,
}: {
  companyId: string
}): {
  perfMetrics: ChannelMetrics[]
} => {
  const emptyMetrics: ChannelMetrics[] = React.useMemo(() => {
    return [
      {
        channel: ForecastChannel.Combined,
        metricsDetails: {
          experimentId: '0',
          evaluationDate: new Date().toDateString(),
          optimizationDate: OptimizationState.NotOptimized,
          metrics: [],
        },
      },
    ]
  }, [])

  const [perfMetrics, setPerfMetrics] = useState(emptyMetrics)
  const adminFetchPerformanceMetrics = React.useCallback(
    async (companyId: string): Promise<ChannelMetrics[]> => {
      try {
        const result: ChannelMetricsResults = await api.get(
          `/customer-service/performance-metrics/${companyId}`,
        )
        const response = result?.results
        if (response) {
          return response
        }
        return []
      } catch (err) {
        console.error(err)
        return emptyMetrics
      }
    },
    [emptyMetrics],
  )

  useEffect(() => {
    const getPerfMetrics = async (): Promise<void> => {
      const metric = await adminFetchPerformanceMetrics(companyId)
      setPerfMetrics(metric)
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getPerfMetrics().catch(() => {})
  }, [adminFetchPerformanceMetrics, companyId])

  return React.useMemo(() => {
    return {
      perfMetrics,
    }
  }, [perfMetrics])
}
