import React from 'react'
import * as Sentry from '@sentry/browser'

export interface SentryErrorComponentProps {
  eventId?: string
}

export interface SentryErrorBoundaryProps {
  errorComponent: React.FC<SentryErrorComponentProps>
  children?: React.ReactNode
}

export interface SentryErrorBoundaryState {
  error?: Error
  eventId?: string
  hasError: boolean
}

export class SentryErrorBoundary extends React.PureComponent<
  SentryErrorBoundaryProps,
  SentryErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: Error): SentryErrorBoundaryState {
    return {
      error,
      hasError: true,
    }
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render(): React.ReactNode {
    const { hasError, eventId } = this.state
    const { children, errorComponent } = this.props

    if (!hasError) return children
    else return errorComponent({ eventId })
  }
}
