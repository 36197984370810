import { TrendTarget } from './types'

export const hasDesiredTrend = (
  value: number | null,
  target: TrendTarget,
): boolean => {
  // if value is null or target is neutral then stick to default color
  if (value === null || target === TrendTarget.neutral) {
    return true
  }

  const isPositive = value >= 0

  if (target === TrendTarget.positive) {
    return isPositive
  } else {
    return !isPositive
  }
}
