import React from 'react'
import Button from '@mui/material/Button'

import { NavLinkPlain } from '../../../components/designSystem/atoms/NavLinkPlain/NavLinkPlain'
import { CircleBang } from '../../../components/designSystem/assets/CircleBang/CircleBang'

import { UserGuidanceBase } from '../../shared/'

type Props = {
  subtitle: string
  title?: string
  buttonText?: string
}

export const MissingConnection: React.FunctionComponent<Props> = ({
  title = 'Not quite done',
  subtitle,
  buttonText,
}) => {
  return (
    <UserGuidanceBase
      renderIcon={(): React.ReactNode => (
        <CircleBang height={100} width={100} />
      )}
      title={title}
      subtitle={subtitle}
    >
      {buttonText && (
        <NavLinkPlain to={'/connectors'}>
          <Button
            variant="contained"
            color="primary"
            size={'large'}
            style={{ height: '30px', fontSize: '1rem', padding: '20px' }}
          >
            {buttonText}
          </Button>
        </NavLinkPlain>
      )}
    </UserGuidanceBase>
  )
}
