import React from 'react'
import { connect } from 'react-redux'
import { Store } from '../../../../modules/rootReducer'
import { ProviderModalProps } from '../Providers'
import { SpeedBumpModal } from '../../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import { OutIcon } from '../../../../components/designSystem/assets/OutIcon/OutIcon'
import { useDispatchApiCall } from '../../../../hooks/useDispatchApiCall'
import { getAmazonSellerUri } from '../../../../modules/amazonSeller/amazonSeller'
import { AmazonSellerUriPayload } from '../../../../modules/amazonSeller'

type Props = {
  amazonSellerUri: AmazonSellerUriPayload
}

export const AmazonModalImpl: React.FunctionComponent<
  ProviderModalProps & Props
> = ({ isOpen, amazonSellerUri }) => {
  if (amazonSellerUri?.amazonSellerUri) {
    // redirect the user to Amazon marketplace for auth
    window.location.href = amazonSellerUri?.amazonSellerUri
  }

  if (isOpen) {
    useDispatchApiCall([getAmazonSellerUri])
  }

  return (
    <>
      {isOpen && (
        <SpeedBumpModal
          title={'Redirecting to Amazon Marketplace'}
          text={`You're being redirected to Amazon Marketplace to authorize brightflow`}
        >
          <OutIcon height={27} width={27} />
        </SpeedBumpModal>
      )}
    </>
  )
}

const mapStateToProps = ({
  amazonSellerUri: { amazonSellerUri },
}: Store): Props => ({
  amazonSellerUri,
})

export const AmazonModal = connect(mapStateToProps)(React.memo(AmazonModalImpl))
