import { createReducer } from '@reduxjs/toolkit'
import { KNOWN_CONNECTOR_KEYS } from '../../../server/connectors/known-connectors'
import { createThunkReducer } from '../thunkReducerBuilder'
import { LoadingAndError } from '../types'
import { authPlaid, linkPlaid } from './plaid'

const PLAID_KEY = KNOWN_CONNECTOR_KEYS.plaid

export type PlaidPayload = {
  [PLAID_KEY]: { connected: boolean; errorMessages: string[] } | null
} & LoadingAndError

type PlaidStoreData = {
  [PLAID_KEY]: PlaidPayload
}

export type PlaidData = Readonly<PlaidStoreData>

const initialPLaidState: PlaidData = {
  [PLAID_KEY]: {
    [PLAID_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const plaidReducer = createReducer(initialPLaidState, builder => {
  // Token
  createThunkReducer(
    builder,
    authPlaid.typePrefix,
    PLAID_KEY,
    (state, action) =>
      ({
        ...state,
        [PLAID_KEY]: {
          ...state[PLAID_KEY],
          isLoading: null,
          error: null,
          connected: action.payload.connected,
          errorMessages: action.payload.errorMessages,
        },
      } as PlaidData),
  )

  // Link
  createThunkReducer(
    builder,
    linkPlaid.typePrefix,
    PLAID_KEY,
    (state, action) =>
      ({
        ...state,
        [PLAID_KEY]: {
          ...state[PLAID_KEY],
          isLoading: null,
          error: null,
          link: action.payload.linkToken,
          errorMessages: action.payload.errorMessages,
        },
      } as PlaidData),
  )
})
