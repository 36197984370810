import React from 'react'
import { connect } from 'react-redux'
import { Store } from '../../../../modules/rootReducer'
import { useDispatchApiCall } from '../../../../hooks'
import {
  getConnectors,
  selectRevenueConnectors,
} from '../../../../modules/connectors'
import { Connection } from '../../../../modules/connectors/types'
import { CompanyFeaturesPayload } from '../../../../modules/companyFeatures'
import { hasRevenueForecastFeature } from '../../../../util/companyFeatures'

import { SalesForecastWrapper } from './components/SalesForecastWrapper/SalesForecastWrapper'
import { SalesForecastProvider } from './SalesForecastProvider'

type Props = {
  companyFeatures: CompanyFeaturesPayload
  revenueConnections: Connection[]
}

const SalesForecastImpl = ({
  companyFeatures,
  revenueConnections,
}: Props): JSX.Element => {
  useDispatchApiCall([getConnectors])

  const hasSalesForecastFeature = hasRevenueForecastFeature(companyFeatures)

  const hasRevenueConnection = !!revenueConnections.length

  // The revenue connections must be in one of the following states to show the forecast:
  // 1. One of the revenue connections has 12 or more months of data
  // 2. None of the revenue connections go back at least a year but all the data is fully synced
  // 3. There is a year of data, but the sync is still in-progress

  const hasOneYearOfRevenueData = revenueConnections.some(
    ({ percentOfYearComplete }) => percentOfYearComplete === 100,
  )

  const hasAllRevenueDataSynced = revenueConnections.every(
    ({ initialSyncStatus }) => initialSyncStatus === 'complete',
  )

  return (
    <SalesForecastProvider>
      <SalesForecastWrapper
        hasFeatureAccess={hasSalesForecastFeature}
        hasRevenueConnection={hasRevenueConnection}
        isSynced={hasOneYearOfRevenueData || hasAllRevenueDataSynced}
      />
    </SalesForecastProvider>
  )
}

const mapStateToProps = (state: Store): Props => {
  return {
    companyFeatures: state.companyFeatures.companyFeatures,
    revenueConnections: selectRevenueConnectors(state.connectors),
  }
}

export const SalesForecastScreen = connect(mapStateToProps)(
  React.memo(SalesForecastImpl),
)
