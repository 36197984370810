import React from 'react'

import { LegendRow } from './LegendItemRow'
import Typography from '@mui/material/Typography'

export interface LegendItemHeaderProps {
  titles: [string, string]
}

export const LegendItemHeader = ({
  titles,
}: LegendItemHeaderProps): JSX.Element => {
  return (
    <LegendRow
      name={''}
      value={''}
      alignItems="flex-end"
      height={24}
      comparisonComponents={[
        <Typography variant={'caption'}>{titles[0]}</Typography>,
        <Typography variant={'caption'}>{titles[1]}</Typography>,
      ]}
    />
  )
}
