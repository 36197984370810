import BigNumber from 'bignumber.js'

import { Formatter } from './Formatter'

type Unit = {
  /**
   * The exponent of log10 this unit will match.
   */
  exponent: number

  /**
   * An optional exponent to be used when calculating the divisor. Defaults
   * to [[exponent]].
   */
  scale?: number

  /**
   * The number of decimals that this unit rounds to.
   */
  precision: number

  /**
   * An optional suffix appended to the formatted string.
   */
  suffix: string
}

const UNITS: Unit[] = [
  // Cents
  { exponent: 2, precision: 2, suffix: '' },
  // Hundreds
  { exponent: 4, scale: 2, precision: 0, suffix: '' },
  // Thousands
  { exponent: 5, precision: 1, suffix: 'k' },
  // Ten thousands
  { exponent: 6, scale: 5, precision: 0, suffix: 'k' },
  // Hundred thousands
  { exponent: 7, scale: 5, precision: 0, suffix: 'k' },
  // Millions
  { exponent: 8, precision: 1, suffix: 'M' },
  // Ten millions
  { exponent: 9, scale: 8, precision: 0, suffix: 'M' },
  // Hundred millions
  { exponent: 10, scale: 8, precision: 0, suffix: 'M' },
  // Billions
  { exponent: 11, precision: 1, suffix: 'B' },
  // Ten billions
  { exponent: 12, scale: 11, precision: 0, suffix: 'B' },
  // Hundred billions
  { exponent: 13, scale: 11, precision: 0, suffix: 'B' },
  // Trillions
  { exponent: 14, precision: 1, suffix: 'T' },
  // Ten trillions
  { exponent: 15, scale: 14, precision: 0, suffix: 'T' },
  // Hundred trillions
  { exponent: 16, scale: 14, precision: 0, suffix: 'T' },
]

const NUMBER_FORMAT: BigNumber.Format = {
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
}

function calculateUnit(value: BigNumber): Unit {
  if (value.isZero()) {
    return UNITS[0]
  }
  const valueExponent = Math.floor(Math.log10(value.toNumber()))
  const unit =
    [...UNITS].reverse().find(({ exponent }) => valueExponent >= exponent) ||
    UNITS[0]

  return unit
}

export class HumanFormatter extends Formatter {
  public convert(s?: boolean): string {
    const { value } = this.money
    const unit = calculateUnit(value.abs())
    const { exponent, scale, suffix } = unit
    const scaledAmount = value
      .abs()
      .dividedBy(Math.pow(10, s !== undefined ? 2 : scale ?? exponent))
    // NOTE(ivy): We use round-half-even (a.k.a. bankers' rounding) to eliminate
    // bias. This is a good default but we might have a need for other rounding
    // methods in the future.
    //
    // See https://en.wikipedia.org/wiki/Rounding#Round_half_to_even
    const formattedAmount = scaledAmount.toFormat(
      s && s !== undefined ? 0 : s === undefined ? this.getPrecision(unit) : 2,
      BigNumber.ROUND_HALF_EVEN,
      NUMBER_FORMAT,
    )

    // TODO(ivy): support other currency symbols... someday.
    let prefix = '$'
    if (value.isNegative()) {
      prefix = `-${prefix}`
    }

    return [prefix, formattedAmount, s !== undefined ? '' : suffix].join('')
  }

  private getPrecision(unit: Unit): number {
    const { precision } = unit
    if (precision === 0) {
      return 0
    }

    const exponent = unit.scale ?? unit.exponent
    const divisor = Math.pow(10, exponent)
    const remainder = this.money.value.abs().mod(divisor)
    const minRemainder = divisor / Math.pow(10, precision)

    if (
      remainder.isZero() ||
      (unit.exponent !== 2 && remainder.isLessThan(minRemainder))
    ) {
      // TODO(ivy): Accomodate precisions between zero and unit.precision
      return 0
    }
    return precision
  }
}
