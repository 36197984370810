import {
  BootstrapData,
  BootstrapDataCompanyInfo,
  BootstrapDataUserInfo,
} from '../../server/routes/types'

type UserInfo = BootstrapDataUserInfo & {
  company?: BootstrapDataCompanyInfo
  connectionCount: number
  activeConnectionCount: number
}

const ANALYTICS_TIMEOUT_CHECK_MS = 1 * 1000 // 1 second intervals

const ANALYTICS_IDENTIFY_MAX_RETRY_ATTEMPTS = 15

const windowIsMissing = (): boolean => {
  return typeof window === 'undefined'
}

const isBrightflowEmail = (email: string): boolean => {
  return email?.includes('brightflow.ai')
}

const analyticsExists = (analytics?: { identify?: Function }): boolean => {
  return typeof analytics?.identify === 'function'
}

const getUserEmail = (): string => {
  if (windowIsMissing()) {
    console.warn('Segment User Stopped: No `window` object')
    return ''
  }

  const data: BootstrapData = (window as any).__INITIAL_DATA__

  return data?.userInfo?.login ?? ''
}

const isInternalUser = (): boolean => {
  const email = getUserEmail()

  if (!email) {
    return false
  }

  return isBrightflowEmail(email)
}

const userInfoIsMissing = (userInfo: UserInfo): boolean => {
  return !userInfo?.login
}

// before making a call to Segment's analytics API we want to make sure that
// the analytics object from segment is available.
// using setTimeout to poll for this using JS event queue
const executeAnalyticsTask = (attemptCount = 1, callback: Function): void => {
  setTimeout(() => {
    // SSR check
    if (windowIsMissing()) {
      console.warn('Segment Identify Stopped: No `window` object')
      return
    }

    if (isInternalUser()) {
      console.warn('Tracking Stopped: internal user')
      return
    }

    const analytics = (window as any)?.analytics as {
      identify: (...args: any) => void
    }

    if (!analyticsExists(analytics)) {
      // it's possible that there was a race condition where the
      // analytics object has not yet been loaded into the window
      // try again after another ANALYTICS_TIMEOUT_CHECK_MS
      if (attemptCount >= ANALYTICS_IDENTIFY_MAX_RETRY_ATTEMPTS) {
        // don't want to hit segment API indefinitely, so
        // putting a max retry attempt on this seems sensible
        console.error(
          'Segment Identify Stopped: `window.analytics` missing. Max retry attempts exceeded',
        )
        return
      }

      console.warn(
        'Segment Identify Stopped: `window.analytics` missing. Trying again',
      )

      executeAnalyticsTask(attemptCount + 1, callback)
      return
    }

    callback()
  }, ANALYTICS_TIMEOUT_CHECK_MS)
}

export const setAnalyticsUser = (userInfo: UserInfo): void => {
  // if user info is missing, we can't set an analytics user
  if (userInfoIsMissing(userInfo)) {
    console.error(
      `Trying to set identify for segment, missing data for "${
        userInfo?.id ?? 'no user'
      }"`,
    )
    return
  }

  executeAnalyticsTask(1, () => {
    const {
      id,
      login: email,
      firstName,
      lastName,
      connectionCount,
      activeConnectionCount,
    } = userInfo

    const company = userInfo?.company

    const analytics = (window as any).analytics

    analytics.identify(id, {
      firstName,
      lastName,
      email,
      isInternalUser: false,
      companyName: company?.name ?? null,
      companyId: company?.id ?? null,
      isCompanyIcp: !!company?.icpAt ?? false,
      connectionCount,
      activeConnectionCount,
      plan: company?.plan ?? null,
    })
  })
}

/**
 * Track the pageview, sending to Segment
 *
 * @param pageName Name of page
 */
export const trackPage = (
  pageName: string,
  title: string,
  userEmail?: string,
): void => {
  executeAnalyticsTask(1, () => {
    const analytics = (window as any).analytics
    analytics.page(pageName, { title, userEmail })
  })
}

/**
 * Tracks an event, sending to Segment
 *
 * @param event Name of event
 * @param properties Properties to track
 */
export const track = (event: string, properties?: object): void => {
  executeAnalyticsTask(1, () => {
    const analytics = (window as any).analytics
    analytics.track(event, properties)
  })
}
