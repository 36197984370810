import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import DateRangeIcon from '@mui/icons-material/DateRange'
import SyncIcon from '@mui/icons-material/Sync'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { Color } from '../../../../Color'

type Props = {
  heading?: string
  subHeading: string
  synced?: string | null
  hasSyncError?: boolean
}

export const DashboardHeadingSection = ({
  heading,
  subHeading,
  synced,
  hasSyncError,
}: Props): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginTop: '10px',
    }}
  >
    {heading && <Typography variant="h4">{heading}</Typography>}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '16px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateRangeIcon sx={{ height: '16px' }} />
        <Typography variant="body2">{subHeading}</Typography>
      </Box>
      {synced && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SyncIcon sx={{ height: '16px' }} />
          <Typography variant="body2">Last update {synced}</Typography>
          {hasSyncError && (
            <CloudOffIcon
              sx={{
                color: Color.ErrorOrange,
                height: '16px',
                marginLeft: '10px',
              }}
            />
          )}
        </Box>
      )}
    </Box>
  </Box>
)
