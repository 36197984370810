import { CompanyFeaturesPayload } from '../modules/companyFeatures'
import { ExportType } from '../../server/data/models/types'

export type UIFeatures = {
  shouldHideLtv: boolean
  shouldHideRoas: boolean
  shouldHideSalesForecast: boolean
  shouldHideProductDemand: boolean
  shouldHideCashPosition: boolean
  shouldHideInsights: boolean
}

enum UI_FEATURES_KEYS {
  LIFETIME_CUSTOMER_VALUE = 'lifetimeCustomerValueUI',
  RETURN_ON_AD_SPEND = 'returnOnAdSpendUI',
  REVENUE_FORECAST = 'revenueForecastFeatureUI',
  PRODUCT_DEMAND_FORECAST = 'productDemandForecast',
  GOALS = 'goalsUi',
  CASH_POSITION = 'cashPositionUI',
  WEEKLY_CASHFLOW = 'weeklyCashflowFeatureUI',
}

const hasCompanyFeature = (
  companyFeatures: CompanyFeaturesPayload,
  key: string,
) =>
  companyFeatures.companyFeatures?.some(feature => feature.key === key) ?? false

export const hasLifetimeCustomerValueFeature = (
  companyFeatures: CompanyFeaturesPayload,
) =>
  hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.LIFETIME_CUSTOMER_VALUE)

export const hasReturnOnAdSpendFeature = (
  companyFeatures: CompanyFeaturesPayload,
) => hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.RETURN_ON_AD_SPEND)

export const hasRevenueForecastFeature = (
  companyFeatures: CompanyFeaturesPayload,
) => hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.REVENUE_FORECAST)

export const hasProductDemandForecastFeature = (
  companyFeatures: CompanyFeaturesPayload,
) =>
  hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.PRODUCT_DEMAND_FORECAST)

export const hasGoalsFeature = (companyFeatures: CompanyFeaturesPayload) =>
  hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.GOALS)

export const hasCashPositionFeature = (
  companyFeatures: CompanyFeaturesPayload,
) => hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.CASH_POSITION)

export const hasWeeklyCashflowFeature = (
  companyFeatures: CompanyFeaturesPayload,
) => hasCompanyFeature(companyFeatures, UI_FEATURES_KEYS.WEEKLY_CASHFLOW)
