import { AdminDashboardSubscription } from '../../../server/services/adminDashboard'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import React from 'react'
import { formatTimestamp } from './DashboardGridPanelDetail'
import { TableSectionHeader } from './DashboardGridPanelDetail'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import { SubscriptionProvider } from '../../../server/data/models/types'

export const AdminSubscriptionsDetail = (props: {
  subscriptions: AdminDashboardSubscription[]
  renderButton: () => JSX.Element
  deleteCompanySubscription: (subscription: AdminDashboardSubscription) => void
}): JSX.Element => {
  const { subscriptions, renderButton, deleteCompanySubscription } = props
  return (
    <>
      <TableSectionHeader title={'Subscriptions'} renderButton={renderButton} />
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Delete</TableCell>
                  <TableCell component="th">Id</TableCell>
                  <TableCell component="th">Plan</TableCell>
                  <TableCell component="th">Cost</TableCell>
                  <TableCell component="th">Status</TableCell>
                  <TableCell component="th">Provider</TableCell>
                  <TableCell component="th">Trial Expiration Date</TableCell>
                  <TableCell component="th">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((sub: AdminDashboardSubscription) => (
                  <TableRow key={sub.id}>
                    <TableCell component="th">
                      {sub.provider === SubscriptionProvider.brightflow && (
                        <IconButton
                          onClick={(): void => deleteCompanySubscription(sub)}
                        >
                          <DeleteForeverIcon color={'error'} />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell scope="row">{sub.id}</TableCell>
                    <TableCell scope="row">{sub.plan.name}</TableCell>
                    <TableCell scope="row">{sub.plan.feeCents}</TableCell>
                    <TableCell scope="row">{sub.status}</TableCell>
                    <TableCell scope="row">{sub.provider}</TableCell>
                    <TableCell scope="row">
                      {sub.expirationDate
                        ? formatTimestamp(new Date(sub.expirationDate))
                        : ''}
                    </TableCell>
                    <TableCell scope="row">
                      {formatTimestamp(new Date(sub.createdAt))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}
