import React from 'react'
import { connect } from 'react-redux'
import { Store } from '../../../../modules/rootReducer'
import { CompanyFeaturesPayload } from '../../../../modules/companyFeatures'
import { ProductDemandForecastReport } from './ProductDemandForecastReport'
import { ForecastWrapper } from '../ForecastWrapper'
import { ScheduleACallScreen } from '../../shared'
import { WomanReading } from '../../../../components/designSystem/assets/WomanReading/WomanReading'
import { hasProductDemandForecastFeature } from '../../../../util/companyFeatures'

type Props = {
  companyFeatures: CompanyFeaturesPayload
}

const ForecastImpl = ({ companyFeatures }: Props): JSX.Element => {
  return (
    <>
      {hasProductDemandForecastFeature(companyFeatures) ? (
        <ForecastWrapper
          title={
            'Track And Plan Your Product Needs With Product Demand Forecast'
          }
          renderReport={props => <ProductDemandForecastReport {...props} />}
        />
      ) : (
        <ScheduleACallScreen
          title="Track And Plan Your Product Needs With Product Demand Forecast"
          subtitle="Use our proprietary forecast to predict future units sold by SKU and product segment."
          renderIcon={(): React.ReactNode => (
            <WomanReading height={170} width={100} />
          )}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store): Props => {
  return {
    companyFeatures: state.companyFeatures.companyFeatures,
  }
}

export const ProductDemandForecastScreen = connect(mapStateToProps)(
  ForecastImpl,
)
