import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material/styles'
import { Color } from '../../../../../Color'

export const OverviewEmptyStateContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  min-height: 100%;
  padding: 24px 0;
`

export const OverviewEmptyStateDescription = styled(Typography)`
  text-align: center;
  padding-bottom: 14px;
  color: ${Color.LightGrey};
`

OverviewEmptyStateDescription.defaultProps = {
  variant: 'body1',
}
