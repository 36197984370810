import { connect } from 'react-redux'
import React, { useState } from 'react'
import { ActionButton } from '../../components/designSystem/atoms/ActionButton/ActionButton'
import { Banner } from '../../components/designSystem/organisms/Banner/Banner'
import { Headline, Layout } from '../../layout'
import { Store } from '../../modules/rootReducer'
import { TextInput } from '../../components/designSystem/atoms/TextInput/TextInput'
import { deleteUser } from '../../modules/user'
import { getActiveSubscription } from '../../modules/subscriptions/subscriptions'
import { getSubscriptionPlans } from '../../modules/subscriptionPlans/subscriptionPlans'
import { SubscriptionPlanPayload } from '../../modules/subscriptionPlans'
import { SubscriptionPayload } from '../../modules/subscriptions'
import { dispatch } from '../../store'
import { useDispatchApiCall } from '../../hooks'
import { StandardSubscriptionPlanNames } from '../../../server/data/models/types'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

type Props = {
  isDeleteFetching: boolean
  isUserPendingDeletion: boolean
  ownConnectionsCount: number
  subscriptionPlans: SubscriptionPlanPayload
  activeSubscriptions: SubscriptionPayload
}

const AccountScreenImpl: React.FC<Props> = props => {
  const hasConnections = props.ownConnectionsCount > 0
  const [confirmationText, setConfirmationText] = useState<string>('')

  useDispatchApiCall([getSubscriptionPlans, getActiveSubscription])

  const subscriptionPlans = props.subscriptionPlans?.subscriptionPlans
  const activeSubscriptionPlan = props.activeSubscriptions?.subscription

  const hasCustomSubscriptionPlan =
    activeSubscriptionPlan &&
    !subscriptionPlans?.some(
      subscriptionPlan =>
        subscriptionPlan.id === activeSubscriptionPlan.subscriptionPlanId,
    )

  const freeSubscriptionPlan = subscriptionPlans?.find(
    ({ name }) => name === StandardSubscriptionPlanNames.free,
  )

  const hasFreeSubscription =
    !activeSubscriptionPlan ||
    activeSubscriptionPlan?.subscriptionPlanId === freeSubscriptionPlan?.id

  const canDelete =
    !props.isUserPendingDeletion &&
    confirmationText.toLocaleLowerCase().startsWith('delete')

  return (
    <Layout>
      <Headline title="Settings" />
      {/* TODO: When we have actions other than delete, show this page but hide delete inside Shopify */}
      {hasConnections && (
        <>
          <Banner bannerType="alert">
            Warning: Removing your account with also remove the
            {` ${props.ownConnectionsCount}`} connections it is authorizing and
            everyone &nbsp;
            <em>will stop receiving data</em>.
          </Banner>
        </>
      )}
      <p>{`Type "delete" into this box to delete`}</p>
      <TextInput
        value={confirmationText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setConfirmationText(e.target.value)
        }
      />
      <ActionButton
        buttonType="alert"
        onClick={(): void => dispatch(deleteUser())}
        text="Delete"
        disabled={!canDelete}
      />
      {props.isDeleteFetching && <p>loading...</p>}
      {props.isUserPendingDeletion && (
        <p>We will delete your account within 5 business days.</p>
      )}
      {/* {!hasCustomSubscriptionPlan && (
        <Box sx={{ marginTop: '30px' }}>
          <Link to={'/users/pricing'}>
            {hasFreeSubscription ? 'Upgrade Plan' : 'Manage Plan'}
          </Link>
        </Box>
      )} */}
    </Layout>
  )
}

const mapStateToProps = (state: Store): Props => ({
  isDeleteFetching: state.user.delete.isLoading,
  isUserPendingDeletion: state.user.data.isPendingDeletion,
  ownConnectionsCount: state.connectors.ownConnectionsCount,
  subscriptionPlans: state.subscriptionPlans.subscriptionPlans,
  activeSubscriptions: state.activeSubscriptions.activeSubscriptions,
})

export const AccountScreen = connect(mapStateToProps)(
  React.memo(AccountScreenImpl),
)
