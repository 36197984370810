import React from 'react'

interface Props {
  height: number
  width: number
}

export const OutIcon: React.FunctionComponent<Props> = props => {
  const { height, width } = props
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${height} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shape</title>
      <g
        id="v2-copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Shopify"
          transform="translate(-546.000000, -305.000000)"
          fill="#78BE20"
          fillRule="nonzero"
        >
          <path
            d="M556.635,323.885 L558.75,326 L566.25,318.5 L558.75,311 L556.635,313.115 L560.505,317 L546,317 L546,320 L560.505,320 L556.635,323.885 Z M570,305 L549,305 C547.335,305 546,306.35 546,308 L546,314 L549,314 L549,308 L570,308 L570,329 L549,329 L549,323 L546,323 L546,329 C546,330.65 547.335,332 549,332 L570,332 C571.65,332 573,330.65 573,329 L573,308 C573,306.35 571.65,305 570,305 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}
