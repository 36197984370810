import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../../thunkReducerBuilder'
import { LoadingAndError } from '../../types'
import {
  createProductSegments,
  deleteProductSegment,
  deleteProductSegmentMapping,
  getProductSegmentDashboardData,
  updateProductSegment,
  upsertProductSegmentMappings,
} from './productSegmentActions'
import { ProductSegmentMappingResult } from '../../../../server/routes/admin/productDemand/productSegments'

import { UpdateOrCreateProductSegmentMapping } from '../../../../server/services/productSegmentDashboard/ProductSegmentService'

export const PRODUCT_SEGMENT_DASHBOARD_KEY = 'productSegmentDashboard'

export type ProductSegmentDashboardPayload = {
  [PRODUCT_SEGMENT_DASHBOARD_KEY]: ProductSegmentMappingResult | null
} & LoadingAndError

// REDUCERS
type ProductSegmentDashboardStoreData = {
  [PRODUCT_SEGMENT_DASHBOARD_KEY]: ProductSegmentDashboardPayload
}

export type ProductSegmentDashboardData = Readonly<ProductSegmentDashboardStoreData>

const initialProductSegmentDashboardState: ProductSegmentDashboardData = {
  [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
    [PRODUCT_SEGMENT_DASHBOARD_KEY]: null,
    isLoading: false,
    error: null,
  },
}

export const productSegmentsDashboardReducer = createReducer(
  initialProductSegmentDashboardState,
  builder => {
    // fetch all
    createThunkReducer(
      builder,
      getProductSegmentDashboardData.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) =>
        ({
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: action.payload,
          },
        } as ProductSegmentDashboardData),
    )

    // create single Segment reducer
    createThunkReducer(
      builder,
      createProductSegments.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) => {
        const newSegments = action?.payload?.results
        const combined = (
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegments ?? []
        ).concat(newSegments)
        return {
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              results: {
                productSegmentsMapping:
                  state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
                    PRODUCT_SEGMENT_DASHBOARD_KEY
                  ]?.results?.productSegmentsMapping ?? [],
                productSegments: combined,
              },
              errorMessages: [],
            },
          },
        }
      },
    )

    // update segment reducer
    createThunkReducer(
      builder,
      updateProductSegment.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) => {
        const updatedSegment = action?.payload?.result
        const combined = [
          ...(
            state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
              PRODUCT_SEGMENT_DASHBOARD_KEY
            ]?.results?.productSegments ?? []
          ).filter(({ id }) => id !== updatedSegment.id),
          updatedSegment,
        ]

        return {
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              results: {
                productSegmentsMapping:
                  state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
                    PRODUCT_SEGMENT_DASHBOARD_KEY
                  ]?.results?.productSegmentsMapping ?? [],
                productSegments: combined,
              },
              errorMessages: [],
            },
          },
        }
      },
    )

    // delete segment reducer
    createThunkReducer(
      builder,
      deleteProductSegment.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) => {
        const errorMessages = action?.payload?.errorMessages

        if (errorMessages && errorMessages.length) {
          return {
            ...state,
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
              isLoading: null,
              error: action.payload.errorMessages.join(','),
              [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
                results: null,
                errorMessages,
              },
            },
          }
        }

        const { id } = action?.payload?.result
        // filter out segments that have the id
        const segments = (
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegments ?? []
        ).filter(({ id: segId }) => segId !== id)

        const segmentMappings = (
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegmentsMapping ?? []
        ).map(({ customerSegments, ...rest }) => {
          // filter out the customer segments that have the same id
          // as the one that got deleted
          const newSegArr = customerSegments.filter(
            ({ id: existingSegmentId }) => existingSegmentId !== id,
          )

          return {
            customerSegments: newSegArr,
            ...rest,
          }
        })

        return {
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              results: {
                productSegmentsMapping: segmentMappings,
                productSegments: segments,
              },
              errorMessages: [],
            },
          },
        }
      },
    )

    // delete product segment mapping
    createThunkReducer(
      builder,
      deleteProductSegmentMapping.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) => {
        const errorMessages = action?.payload?.errorMessages

        if (errorMessages && errorMessages.length) {
          return {
            ...state,
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
              isLoading: null,
              error: action.payload.errorMessages.join(','),
              [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
                results: null,
                errorMessages,
              },
            },
          }
        }
        const { productId: actionProductId, customerSegmentId } = action
          ?.payload?.result as { productId: string; customerSegmentId: string }

        // find the product in the state whose productId matches
        const productSegmentsMapping = (
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegmentsMapping ?? []
        ).map(({ productId, customerSegments, ...rest }) => {
          if (productId === actionProductId) {
            // delete the customer segment that was deleted from this products array of associated segments
            const newCustomerSegments = customerSegments.filter(
              ({ id }) => id !== customerSegmentId,
            )
            return {
              productId,
              customerSegments: newCustomerSegments,
              ...rest,
            }
          }

          return {
            productId,
            customerSegments,
            ...rest,
          }
        })

        return {
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              results: {
                productSegmentsMapping,
                productSegments:
                  state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
                    PRODUCT_SEGMENT_DASHBOARD_KEY
                  ]?.results?.productSegments ?? [],
              },
              errorMessages: [],
            },
          },
        }
      },
    )

    // upsert mappings
    createThunkReducer(
      builder,
      upsertProductSegmentMappings.typePrefix,
      PRODUCT_SEGMENT_DASHBOARD_KEY,
      (state, action) => {
        const errorMessages = action?.payload?.errorMessages

        if (errorMessages && errorMessages.length) {
          return {
            ...state,
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
              isLoading: null,
              error: action.payload.errorMessages.join(','),
              [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
                results: null,
                errorMessages,
              },
            },
          }
        }
        const updatedProductSegmentMappings = action?.payload
          ?.results as UpdateOrCreateProductSegmentMapping[]

        // create map by productId
        const mapUpdatedProductSegmentMappings = new Map(
          updatedProductSegmentMappings.map(
            ({ productId, customerSegments }) => [productId, customerSegments],
          ),
        )

        const productSegmentsMapping = (
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegmentsMapping ?? []
        ).map(({ productId, customerSegments, ...rest }) => {
          if (mapUpdatedProductSegmentMappings.has(productId)) {
            const updatedSegs = (
              mapUpdatedProductSegmentMappings.get(productId) ?? []
            ).map(({ id, companyId, segmentName, quantity }) => ({
              id,
              companyId,
              segmentName,
              quantity,
            }))

            // the order matters here - we want the updated segments to take
            // precedence over the old ones
            const merged = [...updatedSegs, ...customerSegments]

            const uniqueSet = new Set()

            // Filter the mergedArray to keep only unique items based on the specified property
            const uniqueArray = merged.filter(item => {
              if (!uniqueSet.has(item['id'])) {
                uniqueSet.add(item['id'])
                return true
              }
              return false
            })

            return {
              productId,
              ...rest,
              customerSegments: uniqueArray,
            }
          }
          return {
            productId,
            customerSegments,
            ...rest,
          }
        })

        const segments =
          state?.[PRODUCT_SEGMENT_DASHBOARD_KEY]?.[
            PRODUCT_SEGMENT_DASHBOARD_KEY
          ]?.results?.productSegments ?? []
        return {
          ...state,
          [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
            ...state[PRODUCT_SEGMENT_DASHBOARD_KEY],
            isLoading: null,
            error: action.payload.errorMessages.join(','),
            [PRODUCT_SEGMENT_DASHBOARD_KEY]: {
              results: {
                productSegmentsMapping,
                productSegments: segments,
              },
              errorMessages: [],
            },
          },
        }
      },
    )
  },
)
