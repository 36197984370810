import React, { useMemo } from 'react'
import { format } from 'date-fns'
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import Chip from '@mui/material/Chip'

import { Color } from '../../../../../Color'

import { Category, Merchant, TransactionStatus } from '../../Transactions.types'
import {
  HandleOpenAddMerchantModalFn,
  HandleUpdateRowFn,
} from '../TransactionsTable/TransactionsTable.types'

import { TransactionsTableEditableCell } from '../TransactionsTableEditableCell/TransactionsTableEditableCell'
import { TransactionsTableAutocomplete } from '../TransactionsTableAutocomplete/TransactionsTableAutocomplete'
import { AddMerchantButton } from '../TransactionsTableAutocomplete/AddMerchantButton/AddMerchantButton'

type Props = {
  apiRef: React.MutableRefObject<GridApiPro>
  categories: Category[]
  handleOpenAddMerchantModal: HandleOpenAddMerchantModalFn
  handleUpdateRow: HandleUpdateRowFn
  merchants: Merchant[]
  descriptionFilterWidth: number
}

const sharedAttributes: Partial<GridColDef> = {
  align: 'left',
  headerAlign: 'center',
  resizable: false,
  hideable: false,
  disableColumnMenu: true,
}

export const TableColumns = ({
  apiRef,
  categories,
  handleOpenAddMerchantModal,
  handleUpdateRow,
  merchants,
  descriptionFilterWidth,
}: Props): GridColumns =>
  useMemo(
    () => [
      {
        ...sharedAttributes,
        field: 'transactionDate',
        flex: 1,
        headerName: 'Date',
        type: 'date',
        valueGetter: ({ value }: { value: any }): Date =>
          value && new Date(value),
        valueFormatter: ({ value }: { value: Date }): string =>
          format(value, 'MM/dd/yyyy'),
      },
      {
        ...sharedAttributes,
        field: 'accountNumber',
        flex: 0.5,
        headerName: 'Account Number',
        valueGetter: ({ row }: { row: any }): string =>
          row.accountMask ?? row.accountName,
      },
      {
        ...sharedAttributes,
        field: 'description',
        headerName: 'Description',
        resizable: true,
        maxWidth: 650,
        ...(descriptionFilterWidth
          ? { width: descriptionFilterWidth }
          : { flex: 1 }),
      },
      {
        ...sharedAttributes,
        align: 'center',
        field: 'amountCents',
        flex: 1,
        headerName: 'Amount',
        type: 'number',
        valueFormatter: ({ value }: { value: number }): string => {
          const dollars = Math.abs(value)
          const formattedDollars = `$${dollars.toFixed(2)}`

          return value < 0 ? `- ${formattedDollars}` : formattedDollars
        },
        valueGetter: ({ value }: { value: number }): number => value / 100,
      },
      {
        ...sharedAttributes,
        align: 'center',
        field: 'status',
        flex: 0.5,
        headerName: 'Status',
        type: 'text',
        renderCell: ({ row }: GridRenderCellParams): JSX.Element => (
          <Chip
            size="small"
            label={
              row.status === TransactionStatus.pending ? 'pending' : 'complete'
            }
            sx={{
              backgroundColor:
                row.status === TransactionStatus.pending
                  ? Color.Grey2
                  : Color.TeaGreen,
            }}
          />
        ),
      },
      {
        ...sharedAttributes,
        field: 'merchantName',
        flex: 1,
        headerName: 'Merchant',
        type: 'text',
        editable: true,
        renderCell: ({ id, field, row }: GridRenderCellParams): JSX.Element =>
          row.status === 'pending' ? (
            row.merchantName
          ) : (
            <TransactionsTableEditableCell
              value={row.merchantName}
              onClick={(): void =>
                apiRef.current.setCellMode(id, field, 'edit')
              }
            />
          ),
        renderEditCell: ({
          id,
          field,
          row,
        }: GridRenderEditCellParams<string>): JSX.Element => (
          <TransactionsTableAutocomplete
            defaultValue={row.merchantName}
            options={merchants}
            isGridCell={true}
            onChange={(value: Category | Merchant | null): void => {
              handleUpdateRow(id, field, value, row)
            }}
            DropDownAdditon={
              <AddMerchantButton
                onClick={() => handleOpenAddMerchantModal(row)}
              />
            }
          />
        ),
      },
      {
        ...sharedAttributes,
        field: 'categoryId',
        flex: 1,
        headerName: 'Category',
        type: 'text',
        editable: true,
        renderCell: ({ id, field, row }: GridRenderCellParams): JSX.Element =>
          row.status === 'pending' ? (
            row.categoryLabel
          ) : (
            <TransactionsTableEditableCell
              value={row.categoryLabel}
              onClick={(): void =>
                apiRef.current.setCellMode(id, field, 'edit')
              }
            />
          ),
        renderEditCell: ({
          id,
          field,
          row,
        }: GridRenderEditCellParams<string>): JSX.Element => (
          <TransactionsTableAutocomplete
            defaultValue={row.categoryLabel}
            options={categories}
            isGridCell={true}
            onChange={(value: Category | Merchant | null): void => {
              handleUpdateRow(id, field, value, row)
            }}
          />
        ),
        valueFormatter: ({ value }: { value: string }): string =>
          categories[categories.findIndex(({ id }) => id === value)]?.label ||
          '',
      },
    ],
    [
      categories,
      handleUpdateRow,
      merchants,
      apiRef,
      descriptionFilterWidth,
      handleOpenAddMerchantModal,
    ],
  )
