import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'

type BannerType = 'alert' | 'alert-borderless'

export const Banner = styled('section', {
  shouldForwardProp: (prop: any): boolean => prop !== 'bannerType',
})<{ bannerType: BannerType }>(
  ({ bannerType }) => `
  border: solid;
  border-width: 3px;
  background-color: ${Color.LightOrange};
  font-family: var(--font-family);
  font-weight: var(--font-weight--medium);
  padding: 0.613rem 34px;
  margin-bottom: 10px;
  color: ${Color.Black};
  
  ${
    bannerType === 'alert'
      ? `
        color: ${Color.Black};
        border-color: ${Color.Orange};
      `
      : ``
  }
  ${
    bannerType === 'alert-borderless'
      ? `
        color: ${Color.Black};
        border: none;
        margin-top: 10px;
      `
      : ``
  }
`,
)

// TODO(jose): Add (!) exclamation mark icon to alert banner
