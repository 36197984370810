import React from 'react'
import Box from '@mui/material/Box'
import { TimePeriodFilter } from '../../../../../components/designSystem/organisms/TimePeriodFilter/TimePeriodFilter'
import { useCashPosition } from '../../../cashPosition/CashPositionProvider'
import { TimePeriodFilterOptions } from '../../Insights.consts'

export const InsightsPageActions = (): JSX.Element => {
  const {
    handleDateRangeFilterChange,
    duration,
    granularity,
  } = useCashPosition()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0 30px 24px',
      }}
    >
      <TimePeriodFilter
        filterOptions={TimePeriodFilterOptions}
        handleSubmit={handleDateRangeFilterChange}
        initialDuration={duration}
        initialGranularity={granularity}
      />
    </Box>
  )
}
