import React from 'react'
import { styled } from '@mui/material/styles'

const ImageContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: start;
  background: #000000 url('/images/product-hunt-splash.png') no-repeat center;
  background-size: cover;
`

export const ProductHuntSplash = (): JSX.Element => {
  return <ImageContainer />
}
