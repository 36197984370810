import React, { useCallback, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { connect } from 'react-redux'

import { Store } from '../../../../modules/rootReducer'
import { dispatch } from '../../../../store'
import { getProductDemandForecast } from '../../../../modules/productDemandForecast/productDemandForecast'
import { ProductDemandForecastPayload } from '../../../../modules/productDemandForecast'
import { ProductDemandForecastResponse } from '../../../../../server/services/forecast/types'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import { commaDelimitedThousands } from '../../../../util/displayValue'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  GridColumnGroupingModel,
  GridColumns,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { PageLoading } from '../../../../components/designSystem/atoms/Loading/Loading'
import { UserGuidanceBase } from '../../../shared/'
import { Caution } from '../../../../components/designSystem/assets/Caution/Caution'
import { MissingConnection } from '../../shared/MissingConnection'
import { DashboardHeadingSection } from '../../../../components/designSystem/organisms/DashboardHeadingSection/DashboardHeadingSection'
import { ProductForecastGraph } from './ProductForecastGraph'
import { InformativeTable } from '../../../../components/designSystem/organisms/InteractiveTable/InformativeTable'
import { Callout } from '../../../../components/designSystem/molecules/Callout/Callout'
import { CalloutText } from './styledComponents'
import {
  DisclaimerType,
  FooterDisclaimerText,
} from '../../shared/FooterDisclaimerText'
import { Color } from '../../../../Color'

interface Props {
  productDemandForecast: ProductDemandForecastPayload
  hasLessThanOneYearOfRevenueData: boolean
  previousYearSyncStillInProgress: boolean
}

const ACTUALS_COLUMN_GROUP_NAME = 'Units Sold'
const FORECAST_COLUMN_GROUP_NAME = 'Forecasted Unit Sales'

const getTableColumnGroups = (
  columns: GridColumns,
): GridColumnGroupingModel => [
  {
    groupId: 'Actuals',
    headerName: ACTUALS_COLUMN_GROUP_NAME,
    children: columns.slice(0, 2).map(({ field }) => ({ field })),
  },
  {
    groupId: 'Forecast',
    headerName: FORECAST_COLUMN_GROUP_NAME,
    children: columns
      .slice(2, columns.length - 1)
      .map(({ field }) => ({ field })),
  },
]

const getTableColumns = (
  forecast: ProductDemandForecastResponse,
): GridColumns => {
  if (!forecast?.result?.segments.length) return []

  const columns = forecast.result.segments[0].monthlyData.map(
    ({ startPeriod }) => {
      const date = `${format(parseISO(startPeriod), 'MMM')} ${format(
        parseISO(startPeriod),
        'yyyy',
      )}`

      return {
        field: startPeriod,
        headerName: date,
        sortable: false,
      }
    },
  )

  return [...columns, { field: 'total', headerName: 'Total', sortable: false }]
}

const getTableRows = (
  forecast: ProductDemandForecastResponse,
): GridValidRowModel[] => {
  if (!forecast?.result?.segments.length) return []

  const rows = forecast.result.segments.map(({ segmentName, monthlyData }) => {
    const values = monthlyData.reduce(
      (acc, { startPeriod, value }) =>
        Object.assign(acc, {
          [startPeriod]: value,
        }),
      {},
    )

    const total = monthlyData.reduce((acc, { value }) => acc + value, 0)

    return {
      ...values,
      total,
      id: segmentName,
      category: [segmentName],
    }
  })

  return rows
}

const ProductDemandForecastReportImpl = ({
  productDemandForecast,
  hasLessThanOneYearOfRevenueData,
  previousYearSyncStillInProgress,
}: Props): JSX.Element | null => {
  useEffect(() => {
    dispatch(getProductDemandForecast())
  }, [])

  const {
    isLoading,
    error,
    productDemandForecast: forecast,
  } = productDemandForecast

  const [
    isForecastCalloutDismissed,
    setForecastCalloutDismissed,
  ] = useLocalStorage('forecastInfoMessage', false)

  const dismissForecastCallout = useCallback((): void => {
    setForecastCalloutDismissed(true)
  }, [setForecastCalloutDismissed])

  const apiRef = useGridApiRef()

  if (isLoading) {
    return <PageLoading />
  }

  if (error) {
    return (
      <UserGuidanceBase
        renderIcon={(): React.ReactNode => <Caution height={98} width={114} />}
        title="Error"
        subtitle={error}
      />
    )
  }

  if (!forecast?.result) {
    return (
      <MissingConnection
        title={'Calculation in progress'}
        subtitle={'Hang tight, your forecast is being prepared.'}
      />
    )
  }

  const tableCols = getTableColumns(forecast)
  const tableColGroups = getTableColumnGroups(tableCols.map(col => col))
  const tableRows = getTableRows(forecast)

  // transform the UTC datetime stamp to
  // local time
  const lastUpdatedIsoString = `${forecast?.result.completedOn}Z`

  const updatedAt = parseISO(lastUpdatedIsoString)

  const isForecastCalloutVisible =
    !isForecastCalloutDismissed &&
    (hasLessThanOneYearOfRevenueData || previousYearSyncStillInProgress)

  const calloutText = hasLessThanOneYearOfRevenueData
    ? `Your preliminary forecast is ready! Our algorithm works best with at least 12 
    months of data. It will improve as we learn more about your business.`
    : `Your initial forecast is ready! This forecast will continue to update as we 
    sync more of your historical data.`

  return (
    <>
      <DashboardHeadingSection
        subHeading={`Data aggregated on ${format(
          new Date() || 0,
          'MMMM d, y',
        )}`}
        synced={format(updatedAt, 'M/d/y')}
      />
      <Typography variant="subtitle1" sx={{ margin: '18px 0 6px 0' }}>
        Select segments to compare.
      </Typography>
      {isForecastCalloutVisible && (
        <Callout title="" onDismiss={dismissForecastCallout}>
          <>
            <CalloutText>{calloutText}</CalloutText>
          </>
        </Callout>
      )}
      <ProductForecastGraph segments={forecast.result.segments} />
      <Box sx={{ width: '95%', height: '100%', margin: '20px 0' }}>
        <InformativeTable
          apiRef={apiRef}
          rows={tableRows}
          columns={tableCols}
          columnGroups={tableColGroups}
          treeDataPath="category"
          headerName=""
          formatCellValue={commaDelimitedThousands}
          headerStyles={{
            '&.MuiDataGrid-root': {
              border: 'none',
              '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--filledGroup': {
                [`&[aria-label='${ACTUALS_COLUMN_GROUP_NAME}']`]: {
                  background: Color.Grey1,
                },
                [`&[aria-label='${FORECAST_COLUMN_GROUP_NAME}']`]: {
                  background: Color.LighterBlue,
                },
              },
            },
          }}
        />
      </Box>
      <FooterDisclaimerText type={DisclaimerType.forecast} />
    </>
  )
}

const mapStateToProps = ({
  productDemandForecast: { productDemandForecast },
}: Store): { productDemandForecast: ProductDemandForecastPayload } => {
  return {
    productDemandForecast,
  }
}

// eslint-disable-next-line max-len
export const ProductDemandForecastReport = connect(mapStateToProps)(
  React.memo(ProductDemandForecastReportImpl),
)
