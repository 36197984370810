import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'

import { Color } from '../../../../../../Color'

type Props = {
  onClick: () => void
}

export const AddMerchantButton = ({ onClick }: Props): JSX.Element => (
  <Box sx={{ padding: '5px', borderTop: `1px solid ${Color.Green}` }}>
    <Button
      onMouseDown={onClick}
      startIcon={<ControlPointOutlinedIcon />}
      sx={{ width: '100%' }}
    >
      Add a Merchant
    </Button>
  </Box>
)
