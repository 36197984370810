import React from 'react'

import Box from '@mui/material/Box'
import BarChartIcon from '@mui/icons-material/BarChart'
import VisibilityIcon from '@mui/icons-material/Visibility'
import InsightsIcon from '@mui/icons-material/Insights'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CommitIcon from '@mui/icons-material/Commit'

import { BFLogo } from '../../atoms/BFLogo/BFLogo'
import { NavigationSection } from '../../molecules/NavigationSection/NavigationSection'
import { NavigationSettings } from '../../molecules/NavigationSettings/NavigationSettings'
import { Color } from '../../../../Color'
import { UIFeatures } from '../../../../util/companyFeatures'

type Props = {
  companyName: string
  activeEmail: string
  uiFeatures: UIFeatures
}

export const NavigationSidebar: React.FunctionComponent<Props> = ({
  companyName,
  activeEmail,
  uiFeatures,
}) => {
  // Can be used to show menu item only for internal users
  const isInternalUser = /@brightflow.ai$/.test(activeEmail)

  const SETTINGS_ITEMS = [
    {
      title: 'Settings',
      link: '/users/account',
      isInternal: true,
    },
    {
      title: 'Log Out',
      link: '/users/logout',
      isInternal: false,
    },
  ]

  const MENU_ITEMS: {
    title: string
    icon: JSX.Element
    link?: string
    items?: {
      title: string
      link: string
      isLocked?: boolean
      isHidden?: boolean
    }[]
  }[] = [
    {
      title: 'Overview',
      icon: <VisibilityIcon sx={{ width: 16, height: 16 }} color="primary" />,
      link: '/overview',
    },
    {
      title: 'Cash Flow Management',
      icon: <AttachMoneyIcon sx={{ width: 16, height: 16 }} color="primary" />,
      items: [
        {
          title: 'Cash Position',
          link: '/cash-position',
          isLocked: uiFeatures.shouldHideCashPosition,
        },
        {
          title: 'Transactions',
          link: '/transactions',
          isLocked: uiFeatures.shouldHideCashPosition,
        },
        {
          title: 'Insights',
          link: '/insights',
          isHidden: uiFeatures.shouldHideInsights,
          isLocked: !uiFeatures.shouldHideInsights,
        },
      ],
    },
    {
      title: 'Profitability',
      icon: <BarChartIcon sx={{ width: 16, height: 16 }} color="primary" />,
      items: [
        { title: 'Sales Tracker', link: '/revenue' },
        {
          title: 'Customer Lifetime Value',
          link: '/ltv',
          isLocked: uiFeatures.shouldHideLtv,
        },
        {
          title: 'Ad Spend',
          link: '/roas',
          isLocked: uiFeatures.shouldHideRoas,
        },
      ],
    },
    {
      title: 'Forecasting',
      icon: <InsightsIcon sx={{ width: 16, height: 16 }} color="primary" />,
      items: [
        {
          title: 'Sales Planning',
          link: '/forecast',
          isLocked: uiFeatures.shouldHideSalesForecast,
        },
        {
          title: 'Product Demand',
          link: '/product/forecast',
          isLocked: uiFeatures.shouldHideProductDemand,
        },
      ],
    },
    {
      title: 'Connections',
      icon: <CommitIcon sx={{ width: 16, height: 16 }} color="primary" />,
      link: '/connectors',
    },
  ]

  return (
    <>
      <Box
        sx={{
          maxWidth: '276px',
          height: '100vh',
          background: Color.BrandPurple,
          position: 'relative',
          overflow: { xs: 'hidden', lg: 'visible' },
        }}
      >
        <Box
          sx={{
            marginLeft: 3,
            marginTop: { xs: 0, lg: 4 },
            marginRight: 5,
            marginBottom: 4,
          }}
        >
          <BFLogo width={211} height={47} useVariant />
        </Box>
        <Box
          sx={{
            maxHeight: { xs: '75%', lg: '70%' },
            overflow: 'auto',
          }}
        >
          {MENU_ITEMS.map((item, index) => {
            return (
              <NavigationSection
                key={index}
                header={item.title}
                headerIcon={item.icon}
                headerLink={item.link}
                items={item.items?.filter(child => !child.isHidden)}
                isLast={index === MENU_ITEMS.length - 1}
              />
            )
          })}
        </Box>
        <NavigationSettings
          companyName={companyName}
          options={SETTINGS_ITEMS}
        />
      </Box>
    </>
  )
}
