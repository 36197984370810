import React, { useCallback, useMemo, useState } from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  LineChartProps,
  ResponsiveContainer,
  ResponsiveContainerProps,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'

import Box, { BoxProps } from '@mui/material/Box'

import { BFLegend, Props as LegendProps } from './components/BFLegend'
import { GraphTrace, LegendItem, RechartsGraphTrace } from './BFGraph.types'
import { Color } from '../../../../Color'
import { Money } from '../../../../../util/money'

const getTraces = (data: RechartsGraphTrace[]): (JSX.Element | null)[] => {
  return data.map(trace => {
    const { type, color, width, name, data: rechartsData, traceOptions } = trace
    if (type === 'line') {
      return (
        <Line
          data={rechartsData}
          key={name}
          type="monotone"
          name={name}
          strokeWidth={width}
          dataKey="y"
          stroke={color}
          dot={false}
          {...traceOptions}
        />
      )
    } else if (type === 'dashed_line') {
      return (
        <Line
          data={rechartsData}
          key={name}
          type="monotone"
          name={name}
          strokeWidth={width}
          dataKey="y"
          stroke={color}
          strokeDasharray="6"
          dot={false}
          {...traceOptions}
        />
      )
    } else if (type === 'marker') {
      return (
        <Line
          data={rechartsData}
          key={name}
          type="monotone"
          name={name}
          strokeWidth={0}
          dataKey="y"
          stroke={color}
          dot={{ stroke: color, fill: color, r: width }}
          {...traceOptions}
        />
      )
    }
    return null
  })
}

const makeRechartsData = (data: GraphTrace[]): RechartsGraphTrace[] =>
  data.map(trace => {
    const { x, y, info, ...rest } = trace
    if (x.length !== y.length) {
      throw Error(
        `Data arrays must contain the same amount of data: 
        length x: ${x.length} length y: ${y.length}`,
      )
    }

    // I feel like there should be some kind of zip() function exposed by lodash?
    const rechartsDataArr = info
      ? x.map((value, i) => ({ x: value, y: y[i], ...info[i] }))
      : x.map((value, i) => ({ x: value, y: y[i] }))
    return {
      data: rechartsDataArr,
      ...rest,
    }
  })

const getXAxesProps = (
  transformedData: RechartsGraphTrace[],
): { type: 'category' | 'number'; allowDuplicatedCategory?: boolean } => {
  const xType = typeof transformedData[0].data[0].x
  if (xType === 'string') {
    return { type: 'category', allowDuplicatedCategory: false }
  }
  return { type: 'number' }
}

interface Props {
  data: GraphTrace[]
  disableLegend?: boolean
  height?: number
  width?: number
  containerProps?: Partial<BoxProps>
  responsiveContainerProps?: Partial<ResponsiveContainerProps>
  tooltipOptions?: Partial<TooltipProps>
  yAxisOptions?: Partial<YAxisProps>
  xAxisOptions?: Partial<XAxisProps>
  lineChartOptions?: Partial<LineChartProps>
  legendProps?: Partial<LegendProps>
  onLegendItemClick?: (
    item: LegendItem,
    index: number,
    e: React.SyntheticEvent<Element, Event>,
  ) => void
  children?: React.ReactNode
  renderCustomLegend?: () => JSX.Element
}

export const BFLineGraph = (props: Props): JSX.Element => {
  const {
    data,
    containerProps = {},
    height = 500,
    width = 500,
    tooltipOptions = {},
    yAxisOptions = {},
    xAxisOptions = {},
    lineChartOptions = {},
    legendProps = {},
    disableLegend = false,
    onLegendItemClick,
    children,
    renderCustomLegend = null,
    responsiveContainerProps = { width: '100%', height: '100%' },
  } = props

  const [selectedItem, setSelectedItem] = useState<null | string>(null)

  const onClick = useCallback(
    (item: LegendItem, index: number, e: React.SyntheticEvent) => {
      if (item.name === selectedItem) {
        setSelectedItem(null)
      } else {
        setSelectedItem(item.name)
      }
      if (onLegendItemClick) onLegendItemClick(item, index, e)
    },
    [selectedItem, onLegendItemClick],
  )

  const transformedData = useMemo(() => makeRechartsData(data), [data])

  const legendItems = useMemo(
    () =>
      transformedData
        .filter(({ traceOptions }) => traceOptions?.legendType !== 'none')
        .map(({ name, color }) => ({ name, color })),
    [transformedData],
  )

  const defaultYTickFormatter = useCallback(
    (v): string => (v ? new Money(v * 100).humanize() : ''),
    [],
  )

  return (
    <Box sx={{ display: 'flex' }} {...containerProps}>
      <ResponsiveContainer {...responsiveContainerProps}>
        <LineChart
          height={height}
          width={width}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
          {...lineChartOptions}
        >
          <Tooltip {...tooltipOptions} />
          <CartesianGrid />
          <XAxis
            dataKey="x"
            stroke={xAxisOptions.stroke || Color.LighterGrey}
            {...getXAxesProps(transformedData)}
            {...xAxisOptions}
          />
          <YAxis
            type="number"
            dataKey="y"
            stroke={yAxisOptions.stroke || Color.LighterGrey}
            tickFormatter={defaultYTickFormatter}
            {...yAxisOptions}
          />
          {getTraces(transformedData)}
          {children}
        </LineChart>
      </ResponsiveContainer>
      {disableLegend ? null : (
        <BFLegend
          items={legendItems}
          onItemClick={onClick}
          selectedValue={selectedItem}
          {...legendProps}
        />
      )}
      {renderCustomLegend && renderCustomLegend()}
    </Box>
  )
}
