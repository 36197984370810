import { DateGranularity } from '../../../../../server/app-data-service/generatedTypes'

export const HoursBeforeStaleSync = 24

export const TimePeriodFilterOptions: {
  granularity: DateGranularity
  label: string
  duration: number
}[] = [
  {
    granularity: 'Week',
    label: '13 Weeks',
    duration: 13,
  },
  {
    granularity: 'Month',
    label: '13 Months',
    duration: 13,
  },
]
