import React from 'react'
import { zip } from 'lodash'
import { styled } from '@mui/material/styles'

import { AccountCategoryBreakdown } from '../../../../server/data/models/sync/AccountCategoryBreakdown'
import { DepositAggregateSeries } from '../../../../server/data/models/sync/DepositAggregate'
import { GetRevenueResults } from '../../../../server/routes/api/reports/index'
import { InflowCategory } from '../../../../server/data/models/sync/types'
import {
  commaDelimitedThousands,
  numberAsMoney,
  numberAsString,
} from '../../../util/displayValue'

import {
  AggregateType,
  CATEGORY_LABELS,
  HeadingTr,
  Table,
  average,
  formatDate,
  formatValue,
  sum,
} from './shared'

const RevenueTable = styled(Table)`
  table-layout: auto;
  th:first-child {
    padding-right: 60px;
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
    filter: drop-shadow(3px 10px 4px #ccc);
    width: 200px;
  }
`

export const StickyColumnTableContainer = styled('div')`
  overflow-x: scroll;
`

const CommonTh = styled('th')``

const SECTIONS: AggregateType[] = [
  AggregateType.revenueCents,
  AggregateType.unitVolume,
  AggregateType.orderVolume,
  AggregateType.averageOrderValueCents,
]

const SECTION_LABELS: { [key in AggregateType]: string } = {
  [AggregateType.averageOrderValueCents]: 'AOV',
  [AggregateType.orderVolume]: 'Orders',
  [AggregateType.revenueCents]: 'Order Revenue',
  [AggregateType.unitVolume]: 'Units',
}

function getFormattedValues(
  sectionType: AggregateType,
  series: Omit<DepositAggregateSeries, 'timestamps' | 'precision'>,
): string[] {
  const values = series[sectionType] as Array<number | null>
  // TODO(ivy): Use curried formatValue
  // NOTE(connor): consider map(series, v => formatValue(sectionType, v))
  // (formatValue from shared)
  switch (sectionType) {
    case AggregateType.unitVolume:
    case AggregateType.orderVolume:
      return values.map(commaDelimitedThousands)
    case AggregateType.averageOrderValueCents:
    case AggregateType.revenueCents:
      return values.map(v => numberAsMoney(v, true))
    default:
      console.error('revenue SectionedTable: invalid section type', sectionType)
      return values.map(numberAsString)
  }
}

function getFormattedTotals(
  sectionType: AggregateType,
  categories: AccountCategoryBreakdown<
    InflowCategory,
    Omit<DepositAggregateSeries, 'timestamps' | 'precision'>
  >,
): string[] {
  const reducer =
    sectionType === AggregateType.averageOrderValueCents ? average : sum

  return zip(...Object.values(categories).map(c => c[sectionType]))
    .map(period => period.reduce(reducer))
    .map(v => formatValue(sectionType, v))
}

const Section: React.FunctionComponent<{
  data: GetRevenueResults
  type: AggregateType
}> = ({ data: { timestamps, precision, categories }, type }) => (
  <tbody key={type}>
    <HeadingTr>
      {/* Dates */}
      <CommonTh
        scope="colgroup"
        title={
          type === AggregateType.averageOrderValueCents
            ? 'Average Order Value'
            : ''
        }
      >
        {SECTION_LABELS[type]}
      </CommonTh>
      {SECTIONS[0] === type
        ? timestamps.map((t, idx) => (
            <CommonTh scope="colgroup" key={idx}>
              {formatDate(t, precision)}
            </CommonTh>
          ))
        : null}
    </HeadingTr>

    {/* Averages & Totals */}
    <tr style={{ borderBottom: '1px dotted #d2d2d2' }}>
      <CommonTh scope="rowgroup">
        {type === AggregateType.averageOrderValueCents ? 'Averages' : 'Totals'}
      </CommonTh>
      {getFormattedTotals(type, categories).map((v, idx) => (
        <td key={idx}>{v}</td>
      ))}
    </tr>

    {/* Values */}
    {Object.entries(categories).map(([category, series]) => (
      <tr key={category}>
        {/* Nullish coalescing operator here for retail partners where category name serves as a label as well */}
        <CommonTh scope="rowgroup">
          {CATEGORY_LABELS[category as InflowCategory] ?? category}
        </CommonTh>
        {getFormattedValues(type, series).map((v, idx) => (
          <td key={idx}>{v}</td>
        ))}
      </tr>
    ))}
  </tbody>
)

export const SectionedTable: React.FunctionComponent<{
  data: GetRevenueResults
}> = ({ data }) => (
  <StickyColumnTableContainer>
    <RevenueTable>
      {SECTIONS.map(type => (
        <Section data={data} type={type} key={type} />
      ))}
    </RevenueTable>
  </StickyColumnTableContainer>
)
