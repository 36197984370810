{
  "Polaris": {
    "Avatar": {
      "label": "Avatar",
      "labelWithInitials": "Avatar with initials {initials}"
    },

    "Autocomplete": {
      "spinnerAccessibilityLabel": "Loading"
    },

    "Badge": {
      "PROGRESS_LABELS": {
        "incomplete": "Incomplete",
        "partiallyComplete": "Partially complete",
        "complete": "Complete"
      },
      "STATUS_LABELS": {
        "info": "Info",
        "success": "Success",
        "warning": "Warning",
        "attention": "Attention",
        "new": "New"
      }
    },

    "Button": {
      "spinnerAccessibilityLabel": "Loading",
      "connectedDisclosureAccessibilityLabel": "Related actions"
    },

    "Common": {
      "checkbox": "checkbox",
      "undo": "Undo",
      "cancel": "Cancel",
      "newWindowAccessibilityHint": "(opens a new window)",
      "clear": "Clear",
      "close": "Close",
      "submit": "Submit",
      "more": "More"
    },

    "ContextualSaveBar": {
      "save": "Save",
      "discard": "Discard"
    },

    "DataTable": {
      "sortAccessibilityLabel": "sort {direction} by",
      "navAccessibilityLabel": "Scroll table {direction} one column",
      "totalsRowHeading": "Totals",
      "totalRowHeading": "Total"
    },

    "DatePicker": {
      "previousMonth": "Show previous month, {previousMonthName} {showPreviousYear}",
      "nextMonth": "Show next month, {nextMonth} {nextYear}",
      "today": "Today ",
      "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      },
      "daysAbbreviated": {
        "monday": "Mo",
        "tuesday": "Tu",
        "wednesday": "We",
        "thursday": "Th",
        "friday": "Fr",
        "saturday": "Sa",
        "sunday": "Su"
      }
    },

    "DiscardConfirmationModal": {
      "title": "Discard all unsaved changes",
      "message": "If you discard changes, you’ll delete any edits you made since you last saved.",
      "primaryAction": "Discard changes",
      "secondaryAction": "Continue editing"
    },

    "DropZone": {
      "overlayTextFile": "Drop file to upload",
      "overlayTextImage": "Drop image to upload",
      "errorOverlayTextFile": "File type is not valid",
      "errorOverlayTextImage": "Image type is not valid",

      "FileUpload": {
        "actionTitleFile": "Add file",
        "actionTitleImage": "Add image",
        "actionHintFile": "or drop files to upload",
        "actionHintImage": "or drop images to upload",
        "label": "Upload file"
      }
    },

    "EmptySearchResult": {
      "altText": "Empty search results"
    },

    "Frame": {
      "skipToContent": "Skip to content",
      "Navigation": {
        "closeMobileNavigationLabel": "Close navigation"
      }
    },

    "Icon": {
      "backdropWarning": "The {color} icon doesn’t accept backdrops. The icon colors that have backdrops are: {colorsWithBackDrops}"
    },

    "ActionMenu": {
      "RollupActions": {
        "rollupButton": "Actions"
      }
    },

    "Filters": {
      "moreFilters": "More filters",
      "moreFiltersWithCount": "More filters ({count})",
      "filter": "Filter {resourceName}",
      "noFiltersApplied": "No filters applied",
      "cancel": "Cancel",
      "done": "Done",
      "clearAllFilters": "Clear all filters",
      "clear": "Clear",
      "clearLabel": "Clear {filterName}"
    },

    "Modal": {
      "iFrameTitle": "body markup",
      "modalWarning": "These required properties are missing from Modal: {missingProps}"
    },

    "Pagination": {
      "previous": "Previous",
      "next": "Next",
      "pagination": "Pagination"
    },

    "ProgressBar": {
      "negativeWarningMessage": "Values passed to the progress prop shouldn’t be negative. Resetting {progress} to 0.",
      "exceedWarningMessage": "Values passed to the progress prop shouldn’t exceed 100. Setting {progress} to 100."
    },

    "ResourceList": {
      "sortingLabel": "Sort by",
      "defaultItemSingular": "item",
      "defaultItemPlural": "items",
      "showing": "Showing {itemsCount} {resource}",
      "showingTotalCount": "Showing {itemsCount} of {totalItemsCount} {resource}",
      "loading": "Loading {resource}",
      "selected": "{selectedItemsCount} selected",
      "allItemsSelected": "All {itemsLength}+ {resourceNamePlural} in your store are selected.",
      "selectAllItems": "Select all {itemsLength}+ {resourceNamePlural} in your store",
      "emptySearchResultTitle": "No {resourceNamePlural} found",
      "emptySearchResultDescription": "Try changing the filters or search term",
      "selectButtonText": "Select",
      "a11yCheckboxDeselectAllSingle": "Deselect {resourceNameSingular}",
      "a11yCheckboxSelectAllSingle": "Select {resourceNameSingular}",
      "a11yCheckboxDeselectAllMultiple": "Deselect all {itemsLength} {resourceNamePlural}",
      "a11yCheckboxSelectAllMultiple": "Select all {itemsLength} {resourceNamePlural}",
      "ariaLiveSingular": "{itemsLength} item",
      "ariaLivePlural": "{itemsLength} items",

      "Item": {
        "actionsDropdownLabel": "Actions for {accessibilityLabel}",
        "actionsDropdown": "Actions dropdown",
        "viewItem": "View details for {itemName}"
      },

      "BulkActions": {
        "actionsActivatorLabel": "Actions",
        "moreActionsActivatorLabel": "More actions",
        "warningMessage": "To provide a better user experience. There should only be a maximum of {maxPromotedActions} promoted actions."
      },

      "FilterCreator": {
        "filterButtonLabel": "Filter",
        "selectFilterKeyPlaceholder": "Select a filter\u2026",
        "addFilterButtonLabel": "Add filter",
        "showAllWhere": "Show all {resourceNamePlural} where:"
      },

      "FilterControl": {
        "textFieldLabel": "Search {resourceNamePlural}"
      },

      "FilterValueSelector": {
        "selectFilterValuePlaceholder": "Select a filter\u2026"
      },

      "DateSelector": {
        "dateFilterLabel": "Select a value",
        "dateValueLabel": "Date",
        "dateValueError": "Match YYYY-MM-DD format",
        "dateValuePlaceholder": "YYYY-MM-DD",
        "SelectOptions": {
          "PastWeek": "in the last week",
          "PastMonth": "in the last month",
          "PastQuarter": "in the last 3 months",
          "PastYear": "in the last year",
          "ComingWeek": "next week",
          "ComingMonth": "next month",
          "ComingQuarter": "in the next 3 months",
          "ComingYear": "in the next year",
          "OnOrBefore": "on or before",
          "OnOrAfter": "on or after"
        },
        "FilterLabelForValue": {
          "past_week": "in the last week",
          "past_month": "in the last month",
          "past_quarter": "in the last 3 months",
          "past_year": "in the last year",
          "coming_week": "next week",
          "coming_month": "next month",
          "coming_quarter": "in the next 3 months",
          "coming_year": "in the next year",
          "on_or_before": "before {date}",
          "on_or_after": "after {date}"
        }
      }
    },

    "SkeletonPage": {
      "loadingLabel": "Page loading"
    },

    "Spinner": {
      "warningMessage": "The color {color} is not meant to be used on {size} spinners. The colors available on large spinners are: {colors}"
    },

    "Tabs": {
      "toggleTabsLabel": "More tabs"
    },

    "Tag": {
      "ariaLabel": "Remove {children}"
    },

    "TextField": {
      "characterCount": "{count} characters",
      "characterCountWithMaxLength": "{count} of {limit} characters used"
    },

    "TopBar": {
      "toggleMenuLabel": "Toggle menu",

      "SearchField": {
        "clearButtonLabel": "Clear",
        "search": "Search"
      }
    }
  }
}
