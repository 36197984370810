import React from 'react'

import { LineBarChart } from '../../../../../components/designSystem/molecules/Graph/LineBarChart/LineBarChart'

import {
  bars,
  lines,
  tooltipOptions,
  xAxisOptions,
  yAxisOptions,
} from './CashPositionLineBarChart.consts'

import { useCashPosition } from '../../CashPositionProvider'

export const CashPositionLineBarChart = (): JSX.Element => {
  const { graphData } = useCashPosition()
  return (
    <LineBarChart
      title="Cash Position"
      data={graphData || []}
      lines={lines}
      bars={bars}
      xAxisOptions={xAxisOptions}
      yAxisOptions={yAxisOptions}
      hasNegativeValues={true}
      tooltipOptions={tooltipOptions}
      shouldRenderLegend={false}
    />
  )
}
