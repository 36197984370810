import React from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Color } from '../../../Color'

const ContainerStyles = {
  height: '100%',
  maxWidth: 'none !important',
  backgroundImage: "url('/images/creditscore-splash.png')",
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
}

export const SideBanner = () => {
  return (
    <Container sx={ContainerStyles}>
      <Grid container height="100%" justifyContent="center" alignItems="center">
        <Grid
          item
          sx={{
            textAlign: 'center',
            position: 'relative',
            top: '224px',
            margin: '0 64px',
          }}
        >
          <Typography variant="h3" color={Color.White} mb={2}>
            Manage and grow your business’ cash
          </Typography>
          <Typography variant="h6" color={Color.White}>
            Create a secure account and apply for growth capital today.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
