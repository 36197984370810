import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { StyledTextField } from '../../atoms/TextFieldSelect/TextFieldSelect'

type Props = {
  filterOptions: { label: string; value: string | number }[]
  title: string
  defaultValue: string | number
  handleChange: (value: string | number) => void
}

export const SelectFilter: React.FunctionComponent<Props> = ({
  filterOptions,
  title,
  defaultValue,
  handleChange,
}) => {
  const [value, setValue] = useState<string | number>(defaultValue)
  return (
    <StyledTextField
      label={title}
      value={value}
      onChange={(event): void => {
        const { value } = event.target
        setValue(value)
        handleChange(value)
      }}
    >
      {filterOptions.map(({ label, value }) => (
        <MenuItem key={`menu-${label}`} value={value}>
          {label}
        </MenuItem>
      ))}
    </StyledTextField>
  )
}
