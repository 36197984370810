import React from 'react'
import { styled } from '@mui/material/styles'
import { Color } from '../../../../Color'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'

type Props = {
  title: string
  children: JSX.Element
  onDismiss: React.MouseEventHandler<HTMLButtonElement>
}

const CalloutWrapper = styled('div')`
  box-sizing: border-box;
  border: 2px solid ${Color.DarkBlue};
  border-radius: 5px;
  padding: 25px;
  width: 100%;
  margin: 25px 0px;
`

const Title = styled('p')`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 0px;
`

export const Callout: React.FunctionComponent<Props> = props => {
  const { title, children, onDismiss } = props
  return (
    <CalloutWrapper>
      <Grid container spacing={0} columns={24}>
        <Grid item xs={23}>
          {title && <Title>{title}</Title>}
          <div>{children}</div>
        </Grid>
        <Grid item xs={1}>
          <Grid
            container
            xs={12}
            columns={12}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <IconButton sx={{ padding: '0px' }} onClick={onDismiss}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </CalloutWrapper>
  )
}
