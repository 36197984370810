import Alert, { AlertColor } from '@mui/material/Alert'
import React, { useEffect, useState } from 'react'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AlertTitle from '@mui/material/AlertTitle'

export interface AlertTypeProps {
  title: string
  description: string
  severity: AlertColor
  showAlert: boolean
}
export const NotificationAlert = ({
  severity,
  title,
  description,
  showAlert,
}: AlertTypeProps): JSX.Element => {
  const [show, setShow] = useState(showAlert)
  useEffect(() => {
    setShow(showAlert)
  }, [showAlert])

  return (
    <Fade in={show} timeout={{ exit: 1000 }} unmountOnExit>
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(): void => {
              setShow(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{title}</AlertTitle>
        <div>{description}</div>
      </Alert>
    </Fade>
  )
}
