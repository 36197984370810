import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import { Color } from '../../../../Color'

export const DateRangeInput = styled('input')`
  height: 38px;
  border-radius: 5px;
  border: 1px solid ${Color.LightGrey};
  font-size: 0.766rem;
  width: 100%;
  padding: 0 30px;

  &:focus {
    outline: 1px solid ${Color.Green};
  }
`

export const CalendarAdornment = styled(CalendarTodayIcon)`
  position: absolute;
  left: 5px;
  height: 15px;
  width: 15px;
  color: ${Color.LightGrey};
`

export const ClearInputButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  height: 15px;
  width: 15px;
`
