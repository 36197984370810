import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const TabStyledComponent = styled(Tab, {
  shouldForwardProp: (prop: any): boolean => prop !== 'width',
})<{ width: number }>(
  ({ width }) => `
  font-size: 14px;
  min-width: ${width}px;
  max-width: 60px;
  padding: 5px;
  font-weight: 400;
`,
)

interface Props {
  onChange: (event: React.SyntheticEvent<Element, Event>, value: number) => void
  selectedTabIndex: number
  tabOptions: string[]
  tabWidth?: number
  ariaLabel: string
}

export const HorizontalTab = ({
  onChange,
  selectedTabIndex,
  tabOptions,
  tabWidth = 60,
  ariaLabel,
}: Props): JSX.Element => {
  const width = `${tabWidth * tabOptions.length}px`
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width }}>
        <Tabs
          value={selectedTabIndex}
          onChange={onChange}
          aria-label={ariaLabel}
        >
          {tabOptions.map(option => (
            <TabStyledComponent
              label={option}
              key={`tab-option-${option}`}
              width={tabWidth}
            />
          ))}
        </Tabs>
      </Box>
    </>
  )
}
