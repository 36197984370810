import React from 'react'

import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Color } from '../../../../Color'
import { InfoTooltip } from '../InfoTooltip/InfoTooltip'

type Props = {
  legendColor?: Color
  legendShape?: 'circle' | 'square'
  metricValue: string
  title: string
  tooltip?: string
  sx?: SxProps<Theme>
}

export const ChartLegendMetric = ({
  legendColor,
  legendShape,
  metricValue,
  title,
  tooltip,
  sx,
}: Props): JSX.Element => (
  <Box
    sx={{
      padding: '19px 23px',
      display: 'flex',
      flexDirection: 'column',
      border: `3px solid ${Color.Grey1}`,
      borderRadius: '10px',
      gap: '10px',
      ...sx,
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        <Typography>{title}</Typography>
        {legendShape && legendShape === 'circle' ? (
          <Box
            sx={{
              height: '9px',
              width: '9px',
              borderRadius: '4.5px',
              border: `2px solid ${Color.DarkGrey}`,
              backgroundColor: legendColor,
            }}
          />
        ) : (
          <Box
            sx={{ height: '9px', width: '9px', backgroundColor: legendColor }}
          />
        )}
      </Box>
      {tooltip && <InfoTooltip description={tooltip} />}
    </Box>
    <Box>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        {metricValue}
      </Typography>
    </Box>
  </Box>
)
