import React from 'react'
import { BasicTooltip } from '../../../../../components/designSystem/molecules/BasicTooltip/BasicTooltip'
import { Color } from '../../../../../Color'
import { centsAsDollars, numberAsMoney } from '../../../../../util/displayValue'
import { dataFormatter, labelFormatter } from './CashPositionLineBarChart.utils'

const dataKeyDisplayValueMap: { [key: string]: string } = {
  cashInflows: 'Cash Inflow',
  cashOutflows: 'Cash Outflow',
  cashBalance: 'Cash Balance',
}

export const bars = [
  { dataKey: 'cashInflows', color: Color.Pear },
  { dataKey: 'cashOutflows', color: Color.Peach },
]
export const lines = [
  {
    dataKey: 'cashBalance',
    color: Color.Black,
    options: {
      dataKey: 'cashBalance',
      dot: {
        stroke: Color.White,
        strokeWidth: 1,
        fill: Color.LightGrey,
      },
      activeDot: {
        stroke: Color.Black,
        strokeWidth: 1,
        fill: Color.LightBlue,
      },
    },
  },
]
export const xAxisOptions = {
  dataKey: 'date',
  tickLine: false,
}
export const yAxisOptions = {
  tickLine: false,
  tickFormatter: numberAsMoney,
}

export const tooltipOptions = {
  formatter: (
    value: string | number | (string | number)[],
    name: string,
  ): (number | string)[] => [
    centsAsDollars(value as number),
    dataKeyDisplayValueMap[name] || name,
  ],
  content: (props: any): JSX.Element => (
    <BasicTooltip
      {...props}
      labelFormatter={labelFormatter}
      dataFormatter={dataFormatter}
    />
  ),
}
