import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { BasicModal } from '../BasicModal/BasicModal'
import React, { useEffect, useState } from 'react'
import { DialogCloseIcon } from '../../atoms/DialogCloseIcon/DialogCloseIcon'

export interface ConfirmationDialogProps {
  visible: boolean
  title: string
  content: string
  confirmationButtonText: string
  cancelButtonText: string
  onConfirmation: () => Promise<void>
  onCancel: () => Promise<void>
}

export const ConfirmationDialog = ({
  visible,
  title,
  content,
  onCancel,
  onConfirmation,
  cancelButtonText,
  confirmationButtonText,
}: ConfirmationDialogProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(visible)
  }, [visible])
  const closeHandler = React.useCallback(async () => {
    setIsOpen(false)
    await onCancel()
  }, [onCancel])
  return (
    <BasicModal
      height={100}
      width={600}
      isOpen={isOpen}
      handleClose={closeHandler}
      onDismiss={closeHandler}
      buttonJustifyContent={'center'}
      renderContent={(): JSX.Element => (
        <Grid container={true} spacing={2}>
          <Grid item xs={10} sx={{ p: 1 }}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item={true} container={true} xs={2} justifyContent="flex-end">
            <DialogCloseIcon onClick={closeHandler} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body1">{content}</Typography>
          </Grid>
          <Grid item xs={12} container={true} justifyContent="flex-end">
            <Button variant={'outlined'} size={'large'} onClick={closeHandler}>
              {cancelButtonText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size={'large'}
              sx={{ ml: 2 }}
              onClick={async (): Promise<void> => {
                setIsOpen(false)
                await onConfirmation()
              }}
            >
              {confirmationButtonText}
            </Button>
          </Grid>
        </Grid>
      )}
    />
  )
}
