import { TransactionOrKnex } from 'objection'

import { KNOWN_CONNECTOR_KEYS } from '../../connectors/known-connectors'
import { Company } from './Company'
import { SyncPageDirection } from '../../sync/types'

export type CompanyItemLocatorArgs = {
  key: KNOWN_CONNECTOR_KEYS
  company: Company
  type?: string
  direction?: SyncPageDirection
}

// Couldn't get the inference to work CompanyItemLocator = <TModel>()… downstream, no dice
export type CompanyItemLocatorBase<TModel, TArgs> = (
  args: TArgs,
  opts?: QueryOpts,
) => Promise<TModel>
export type CompanyItemLocator<
  TModel,
  TArgs extends CompanyItemLocatorArgs = CompanyItemLocatorArgs,
  TResult extends TModel | undefined = TModel | undefined
> = CompanyItemLocatorBase<TResult, TArgs>
export type CompanyItemLocatorAssured<
  TModel,
  TArgs extends CompanyItemLocatorArgs = CompanyItemLocatorArgs
> = CompanyItemLocatorBase<TModel, TArgs>

/**
 *
 * Brightflow OrderStatus enum to normalize
 *  from the breadth of upstream data source statuses for an order
 *
 * shopify-api-node:
 *  FulfillmentOrderStatus, FulfillmentOrderRequestStatus,
 *  OrderFinancialStatus, and OrderFulfillmentStatus
 * AmazonMWSOrderStatuses:
 *  connectors/amazon/seller/types
 *
 * Note: shopify-api-node the status to request is slightly different
 * https://shopify.dev/docs/admin-api/rest/reference/orders/order#index-2020-10
 *
 * Bonus?
 * Shopify 🇨🇦 uses double "l"s for cancelled. Amazon 🇺🇸 uses single "l"s, as canceled
 */
export enum OrderStatus {
  /** Reflect any status of the order has been created, until it has been shipped */
  Pending = 'pending',
  /** Once the order has been fully completed (e.g. fully shipped) */
  Paid = 'paid',
  /** Any status of the order that is "no longer a sale" (e.g. refund, cancel) */
  Obsolete = 'obsolete',
  /** Any status that is returned from the API that is unexpected */
  Unknown = 'unknown',
}

export const ORDER_STATUSES: OrderStatus[] = Object.values(OrderStatus)

export enum FinancialEventType {
  order = 'order',
  refund = 'refund',
}

export const FINANCIAL_EVENT_TYPES: FinancialEventType[] = Object.values(
  FinancialEventType,
)

export const SYNC_INFO_TYPES = {
  AMAZON: {
    DRAFT_ORDERS: 'DraftOrderSync',
    REPORT_ORDERS: 'OrderReportSync',
    INVOICE: 'InvoiceSync',
    REFUND: 'RefundSync',
  },
}

// From shopify originally, see https://shopify.dev/api/admin/graphql/reference/billing/appsubscriptionstatus
export enum AppSubscriptionStatus {
  active = 'ACTIVE',
  cancelled = 'CANCELLED',
  declined = 'DECLINED',
  expired = 'EXPIRED',
  frozen = 'FROZEN',
  pending = 'PENDING',
}

// Also orig from shopify, but will be our standard
export enum AppPricingInterval {
  annual = 'ANNUAL',
  every30Days = 'EVERY_30_DAYS',
}

export enum SubscriptionPlanTypes {
  standard = 'STANDARD',
  custom = 'CUSTOM',
}

export enum StandardSubscriptionPlanNames {
  free = 'Free',
  starter = 'Starter',
  professional = 'Professional',
}

export const ACTIVE_SUBSCRIPTION_STATUSES = [AppSubscriptionStatus.active]

export enum SubscriptionProvider {
  brightflow = 'brightflow',
  shopify = 'shopify',
}

/**
 * @param {TransactionOrKnex} [trx] - optional transaction to run query in
 */
export type QueryOpts = {
  trx?: TransactionOrKnex
  logPayload?: any
}

export enum ExportType {
  customer = 'customer',
  deposit = 'deposit',
  expense = 'expense',
  depositDaily = 'depositDaily',
  productSegmentDaily = 'productDemandForecast',
  productSegmentData = 'productDemandData',
  qboBalanceSheet = 'qboBalanceSheet',
  qboProfitAndLoss = 'qboProfitAndLoss',
  executiveSummary = 'executiveSummary',
  netSuiteBalanceSheet = 'netSuiteBalanceSheet',
  netSuiteIncomeStatement = 'netSuiteIncomeStatement',
  netSuitePurchaseOrders = 'netSuitePurchaseOrders',
  xeroBalanceSheet = 'xeroBalanceSheet',
  xeroIncomeStatement = 'xeroIncomeStatement',
  xeroPurchaseOrders = 'xeroPurchaseOrders',
  plaidBalance = 'plaidBalance',
  plaidTransactions = 'plaidTransactions',
  revenueForecast = 'revenueForecast',
  revenueForecastFeatureUI = 'revenueForecastFeatureUI',
  revenueForecastByChannel = 'revenueForecastByChannel',
  ltv = 'ltv',
}

export enum ExportStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum CompanyDataProcessingJobStatus {
  initialized = 'initialized',
  failed = 'failed',
  success = 'success',
}

export type CompanyExportSettingsJson = {
  // settings associated with the export of the record
  // e.g. startDate/endDate
  export?: Record<string, any>
  // settings associated with the model to be run on the given export
  model?: Record<string, any>
}

export enum CompanyGoalType {
  roas = 'roas',
  adSpend = 'adSpend',
  revenue = 'revenue',
}

export enum CompanyGoalPeriod {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  annual = 'annual',
}

export enum CompanyGoalDataUnit {
  MULTIPLIER = 'multiplier',
  CENTS = 'cents',
  DOLLARS = 'dollars',
}

export interface CompanyGoalData {
  value: number
  unit: CompanyGoalDataUnit
}

export enum CompanyScenarioType {
  cashflowForecast = 'cashflowForecast',
}

export enum CompanyScenarioAssumptionType {
  outflow = 'outflow',
  inflow = 'inflow',
  cashBalance = 'cashBalance',
}

export enum CompanyScenarioAssumptionKey {
  startingBalance = 'startingBalance',
  payroll = 'payroll',
  marketing = 'marketing',
  inventoryPurchases = 'inventoryPurchases',
  fixed = 'fixed',
  variable = 'variable',
}

export enum CompanyScenarioAssumptionUnit {
  cents = 'cents',
  percentOfRevenue = 'percentOfRevenue',
  percentOfExpense = 'percentOfExpense',
  fixedPercentOfRevenue = 'fixedPercentOfRevenue',
}

export enum BfCategoryLabels {
  revenue = '--Revenue',
  cogs = '--Cost of Goods Sold (COGS)',
  totalExpenses = '--Total Expenses',
  miscelaneous = '--Miscelaneous',
  netIncome = '--Net Income',
  totalAssets = '--Total Assets',
  totalLiabilities = '--Total Liabilities',
  totalEquity = '--Total Equity',
  noCategory = 'NO CATEGORY',
}

export enum BfSubCategoryLabels {
  // Profit and loss sub categories
  shopify = '-Shopify',
  amazon = '-Amazon',
  wholesale = '-Wholesale',
  retail = '-Retail',
  shippingIncome = '-Shipping Income',
  otherRevenue = '-Other Revenue',
  refunds = '-Refunds',
  discounts = '-Discounts',
  otherDiscounts = '-Other Discounts',
  cogs = '-Cost of Goods Sold (COGS)',
  marketing = '-Marketing',
  googleAdSpend = '-Google Ad Spend',
  facebookAdSpend = '-Facebook Ad Spend',
  amazonAdSpend = '-Amazon Ad Spend',
  otherAdSpend = '-Other Ad Spend',
  payroll = '-Payroll',
  otherVariableCosts = '-Other Variable Costs',
  otherFixedCosts = '-Other Fixed Costs',
  miscelaneous = '-Miscelaneous',
  taxes = '-Taxes',
  depreciationAndAmortization = '-Depreciation and Amortization',
  // balance sheet sub categories
  cash = '-Cash',
  accountsReceivable = '-Accounts Receivable',
  inventory = '-Inventory',
  ppe = '-PP&E',
  otherCurrentAssets = '-Other Current Assets',
  otherAssets = '-Other Assets',
  accountsPayable = '-Accounts Payable',
  creditCards = '-Credit Card',
  otherCurrentLiabilities = '-Other Current Liabilities',
  otherLongTermLiabilities = '-Other Long-term Liabilities',
  shortTermBorrowing = '-Short-term Borrowing',
  longTermBorrowing = '-Long-term Borrowing',
  brightflowLoan = '-Brightflow Loan',
  convertibleLoan = '-Convertible Loan',
  equity = '-Equity',
  retainedEarnings = '-Retained Earnings',
  other = '-Other',
}

export interface AccountNameData {
  accountName: string
}

export enum CompanyPlanType {
  lending = 'lending',
  lendingProspect = 'lending prospect',
  test = 'test',
  trial = 'trial',
}

export enum AgreementType {
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
}

export type ProductInfo = {
  segmentId: string
  segmentName: string
  productId: string
  productName: string
}
