import React from 'react'
import { format } from 'date-fns'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CloudOffIcon from '@mui/icons-material/CloudOff'
import InfoIcon from '@mui/icons-material/Info'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

import { Color } from '../../../../../Color'

import { CashPositionAccountSelect } from '../CashPositionAccountSelect/CashPositionAccountSelect'
import { CashPositionContentAlert } from '../CashPositionContentAlert/CashPositionContentAlert'
import { CashPositionTopLineMetrics } from '../CashPositionTopLineMetrics/CashPositionTopLineMetrics'
import { CashPositionLineBarChart } from '../CashPositionLineBarChart/CashPositionLineBarChart'
import { CashPositionTable } from '../CashPositionTable/CashPositionTable'
import { useCashPosition } from '../../CashPositionProvider'
import { centsAsDollars } from '../../../../../util/displayValue'

import { granularityToHeadingMap } from '../../CashPosition.consts'

export const CashPositionContentBody = (): JSX.Element => {
  const {
    duration,
    endingBalanceCents,
    granularity,
    hasSyncError,
    isLoading,
    netInflowCents,
    netOutflowCents,
    syncTimestamp,
  } = useCashPosition()

  if (isLoading) return <></>

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '48px',
        backgroundColor: Color.White,
        borderRadius: '20px',
        border: `1px solid ${Color.LightGrey}`,
      }}
    >
      <Typography variant="h6" color={Color.DarkGrey}>
        <b>
          {duration} {granularityToHeadingMap[granularity]} |
        </b>{' '}
        Cash Position from Banking Data
      </Typography>
      <Box>
        <CashPositionAccountSelect />
      </Box>
      {hasSyncError && (
        <CashPositionContentAlert
          icon={<CloudOffIcon />}
          message={
            <Typography>
              {`Bank Data sync is incomplete, the last full sync was complete on ${format(
                syncTimestamp || 0,
                'M/d/y',
              )}`}
            </Typography>
          }
          severity="warning"
        />
      )}
      <CashPositionTopLineMetrics
        balance={centsAsDollars(endingBalanceCents)}
        netInflow={centsAsDollars(netInflowCents)}
        netOutflow={centsAsDollars(netOutflowCents)}
      />

      <CashPositionLineBarChart />
      <CashPositionContentAlert
        icon={<InfoIcon />}
        message={
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            Click <AddBoxOutlinedIcon sx={{ height: '16px' }} />
            to expand each row. Click any value in the table to view and/or
            recategorize transactions.
          </Typography>
        }
        severity="info"
      />
      <CashPositionTable />
    </Box>
  )
}
