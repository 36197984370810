import { styled } from '@mui/material/styles'

import { Color } from '../../../../../Color'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ControlledInput } from '../../../../../components/designSystem/molecules/ControlledInput/ControlledInput'

export const ToolBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const TableActions = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 14.5px;
  padding: 15px 30.5px;
  align-items: center;
`

export const TableActionButton = styled(Button)`
  height: 38px;
  border-width: 2px;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    border-width: 2px;
  }
`

TableActionButton.defaultProps = {
  variant: 'outlined',
}

export const ColumnFilters = styled(Box)`
  display: flex;
  align-items: center;
  padding: 9px 0 9px 50px;
  background-color: #f8f8f8;
  border-color: ${Color.LightGrey};
  border-style: solid;
  border-width: 1px 0;
`

export const ColumnFilterContainer = styled(Box)`
  padding: 0 17px;
`

export const StyledInput = styled(ControlledInput)`
  width: 100%;
  height: 38px;
  border: 1px solid ${Color.LightGrey};
  border-radius: 5px;
  font-size: 0.766rem;
  background-color: ${Color.White};
`

StyledInput.defaultProps = {
  disableUnderline: true,
}
