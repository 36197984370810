import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { dispatch } from '../../../store'
import {
  getConnectors,
  selectBankingConnectors,
} from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'
import { Store } from '../../../modules/rootReducer'
import { useInterval } from '../../../hooks'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import { Connector } from '../../connectors/components/Connector'
import { ExternalLink } from '../../../components/forms/StyledElements'

import {
  BANKING_CONNECTORS,
  CONNECTORS_CATEGORIES,
} from '../../../../server/connectors/known-connectors'

const Footer = ({
  hasBankingConnection,
}: {
  hasBankingConnection: boolean
}): JSX.Element => {
  const buttonVariant = hasBankingConnection ? 'contained' : 'outlined'
  const buttonText = hasBankingConnection ? 'Continue' : 'Skip'
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '17px',
        marginTop: '38px',
      }}
    >
      {hasBankingConnection ? (
        <Typography>
          <b>Thanks for connecting your account(s)!</b> Add another account or
          continue to overview!
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '17px',
            maxWidth: '600px',
          }}
        >
          <Typography>
            <b>Not ready to connect your account?</b> We use bank data to create
            a shared a picture of your business health, but you can add it later
            on the overview or connection page.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Typography>Want to talk to someone directly?</Typography>
            <ExternalLink
              href="https://brightflow.ai/request-a-call/?utm_campaign=Credit%20score&utm_source=onboarding"
              target="_blank"
            >
              Schedule a Call
            </ExternalLink>
          </Box>
        </Box>
      )}
      <Link to="/overview">
        <Button size="large" variant={buttonVariant} color="success">
          {buttonText}
        </Button>
      </Link>
    </Box>
  )
}

const BankingConnectScreenImpl = ({
  bankingConnections,
}: Props): JSX.Element => {
  useEffect(() => {
    dispatch(getConnectors())
  }, [])

  useInterval(() => {
    dispatch(getConnectors())
  }, 5000)

  const hasBankingConnection = !!bankingConnections.length

  const subtitleText = hasBankingConnection
    ? 'Thanks for your interest in Brightflow AI. Connect your bank account to get access and grow your capital.'
    : 'Connect your bank account to get access and grow your capital.'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '17px',
      }}
    >
      <Typography variant="h5">Manage and Grow Your Strategic Cash</Typography>
      <Typography>{subtitleText}</Typography>
      <Stack spacing={3} alignItems="center" sx={{ margin: '38px 0' }}>
        {bankingConnections.map(connection => (
          <Connector
            key={connection.id}
            providerName={connection.key}
            providerId={connection.id}
            category={CONNECTORS_CATEGORIES.banking}
          />
        ))}
        {BANKING_CONNECTORS.map(key => (
          <Connector
            key={key}
            providerName={key}
            category={CONNECTORS_CATEGORIES.banking}
            redirectUrl="/get-started/credit-score"
            allowMultipleConnection={true}
            isButtonFilled={!hasBankingConnection}
          />
        ))}
      </Stack>
      <Divider />
      <Footer hasBankingConnection={hasBankingConnection} />
    </Box>
  )
}

type Props = {
  bankingConnections: Connection[]
}

const mapStateToProps = (state: Store): Props => {
  return {
    bankingConnections: selectBankingConnectors(state.connectors),
  }
}

export const BankingConnectScreen = connect(mapStateToProps)(
  BankingConnectScreenImpl,
)
