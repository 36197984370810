import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'

import { Store } from '../../../modules/rootReducer'
import {
  getAccountsCohorts,
  getGrossMargin,
  selectGrossMargin,
} from '../../../modules/reports/ltv'
import { dispatch } from '../../../store'
import { Toolbar } from '../../../layout/Toolbar'
import { HEROKU_TIMEOUT } from '../../../statusCodes'
import { MissingConnection } from '../shared/MissingConnection'

import { ByCohortSection } from './bycohort'
import { ProjectedSection } from './projectedCohort'

type FCProps = {
  cohorts: []
  min: number
  max: number
  minProjected: number
  maxProjected: number
  projectedCohort: []
  grossMargin: number
  isLoading: boolean
  error: null | any
}

const ScrollContainer = styled('div')`
  overflow-x: auto;
`

const LTVReportImpl: React.FunctionComponent<FCProps> = ({
  max,
  min,
  maxProjected,
  minProjected,
  projectedCohort,
  cohorts,
  isLoading,
  error,
  grossMargin,
}) => {
  const [inputMargin, setInputMargin] = useState<string>(grossMargin.toString())
  const [interval, setInterval] = useState<string>('year')
  useEffect(() => {
    dispatch(getGrossMargin())
    dispatch(getAccountsCohorts(interval))
  }, [])

  useEffect(() => {
    setInputMargin(grossMargin.toString())
  }, [grossMargin])

  if (isLoading) {
    return null
  } else if (
    (isLoading === false && error) ||
    (cohorts.length <= 1 && projectedCohort.length <= 2)
  ) {
    return (
      <MissingConnection
        title="Calculation in progress"
        subtitle="Hang tight, your LTV is being prepared"
      />
    )
  } else {
    return (
      <React.Fragment>
        <Toolbar interval={interval} type="ltv" />
        <ScrollContainer>
          <ByCohortSection cohorts={cohorts} min={min} max={max} />
          <ProjectedSection
            projectedCohort={projectedCohort}
            min={minProjected}
            max={maxProjected}
            setInputMargin={setInputMargin}
            inputMargin={inputMargin}
          />
        </ScrollContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (store: Store): FCProps => {
  const {
    cohorts,
    min,
    max,
    minProjected,
    maxProjected,
    projectedCohort,
    isLoading,
    error,
  } = store.ltv.accountsCohorts

  const grossMargin = selectGrossMargin(store.ltv)

  return {
    cohorts,
    min,
    max,
    minProjected,
    maxProjected,
    projectedCohort,
    grossMargin,
    isLoading,
    error,
  }
}

export const LTVReport = connect(mapStateToProps)(React.memo(LTVReportImpl))
