import React from 'react'
import {
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import ExtendIcon from '@mui/icons-material/AddBoxOutlined'
import UnextendIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SquareIcon from '@mui/icons-material/Square'
import { Color } from '../../../../../Color'

const MAX_CHARACTER_COUNT = 36

const CustomValue = (value: string, tooltip?: string): JSX.Element => {
  if (tooltip) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {value}
        <Tooltip title={tooltip} placement="right">
          <InfoOutlinedIcon
            sx={{
              fontSize: 14,
              color: Color.Grey3,
              cursor: 'pointer',
              marginLeft: '5px',
            }}
          />
        </Tooltip>
      </Box>
    )
  }

  if (value.length > MAX_CHARACTER_COUNT) {
    const displayValue = value.substring(0, MAX_CHARACTER_COUNT - 3) + '...'
    return (
      <Tooltip title={value}>
        <div>{displayValue}</div>
      </Tooltip>
    )
  }

  return <>{value}</>
}

export default (
  props: GridRenderCellParams & { tooltips?: { [key: string]: string } },
) => {
  const { id, field, rowNode, value, tooltips, row } = props
  const tooltip = tooltips?.[value]
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  const handleClick: IconButtonProps['onClick'] = event => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }
  const ValueComponent = CustomValue(value, tooltip)
  const headerIconColor = row.headerIconColor
  return (
    <div>
      {filteredDescendantCount > 0 ? (
        rowNode.depth === 0 ? (
          <div className="row-group-header">
            {headerIconColor && (
              <div className="icon-container">
                <SquareIcon
                  sx={{ fontSize: 13, color: `${headerIconColor}` }}
                />
              </div>
            )}
            <div className={`row-group-level-${rowNode.depth}`}>
              {ValueComponent}
            </div>
          </div>
        ) : (
          <Box
            sx={{
              display: 'flex',
            }}
            className={`row-group-level-${rowNode.depth}`}
          >
            <IconButton
              className="row-group-collapse"
              size="small"
              onClick={handleClick}
            >
              {!rowNode.childrenExpanded ? (
                <ExtendIcon sx={{ fontSize: 13 }} />
              ) : (
                <UnextendIcon sx={{ fontSize: 13 }} />
              )}
            </IconButton>
            {ValueComponent}
          </Box>
        )
      ) : (
        <div className={`row-group-childless-level-${rowNode.depth}`}>
          {ValueComponent}
        </div>
      )}
    </div>
  )
}
