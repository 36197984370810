import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Color } from '../../../../../../Color'
import { SalesForecastTable } from '../../SalesForecastTable'
import { SalesForecastChart } from '../SalesForecastChart/SalesForecastChart'
import { SalesForecastAccountSelect } from '../SalesForecastAccountSelect/SalesForecastAccountSelect'
import { SalesForecastTopLineMetrics } from '../SalesForecastTopLineMetrics/SalesForecastTopLineMetrics'
import { useSalesForecast } from '../../SalesForecastProvider'
import { numberAsCommaDelimitiedMoney } from '../../../../../../util/displayValue'

export const SalesForecastContentBody = () => {
  const {
    duration,
    granularity,
    totalActualValue,
    totalProjectedValue,
    chartRows,
  } = useSalesForecast()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '48px',
        backgroundColor: Color.White,
        borderRadius: '20px',
        border: `1px solid ${Color.LightGrey}`,
      }}
    >
      <Stack spacing={3} direction="column">
        <Typography variant="h6" color={Color.DarkGrey}>
          <b>
            {duration} {granularity} Forecast |
          </b>{' '}
          Sales Planning
        </Typography>
        <Box>
          <SalesForecastAccountSelect />
        </Box>
        <SalesForecastTopLineMetrics
          actuals={numberAsCommaDelimitiedMoney(totalActualValue)}
          forecast={numberAsCommaDelimitiedMoney(totalProjectedValue)}
          timePeriod={`${duration} ${granularity}s`}
        />
        <SalesForecastChart rows={chartRows || []} granularity={granularity} />
        <SalesForecastTable />
      </Stack>
    </Box>
  )
}
