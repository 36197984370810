import React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { Connection } from '../../../modules/connectors/types'
import { ConnectorStatusContainer } from '../../connectors/components/ConnectorStatusContainer'
import { CircularProgressWithLabel } from '../../../components/designSystem/molecules/CircularProgressWithLabel/CircularProgressWithLabel'
import { Color } from '../../../Color'
import { DataSyncing } from '../shared/DataSyncing'

const MessageText = styled('p')`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  color: ${Color.DarkCharcoal};
  margin-right: 20px;
`

interface Props {
  connections: Connection[]
}

export const LoadingRevenueConnections: React.FunctionComponent<Props> = ({
  connections,
}) => {
  return (
    <Stack spacing={7} sx={{ margin: '12px 0' }}>
      <Stack spacing={1.5}>
        {connections.map((connection, index) => (
          <ConnectorStatusContainer
            key={`${connection.key}-${index}`}
            providerKey={connection.key}
          >
            <MessageText>Syncing</MessageText>
            <CircularProgressWithLabel
              value={connection.percentOfWeekComplete}
              color={'secondary'}
            />
          </ConnectorStatusContainer>
        ))}
      </Stack>
      <DataSyncing />
    </Stack>
  )
}
