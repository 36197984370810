import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { createThunkReducer } from '../../thunkReducerBuilder'
import { api } from '../../../lib/api'

import { DatePrecision } from '../../../../server/data/models/DatePrecision'

export type GetMarketing = {
  start: Date | number | string
  end: Date | number | string
  precision: DatePrecision
}

export const getMarketingExpenses = createAsyncThunk(
  'reports/marketing-expenses/get',
  async ({ start, end, precision }: GetMarketing, thunkApi) =>
    api.get(
      `api/reports/marketing-expenses?start=${start}&end=${end}&precision=${precision}`,
    ),
)

type MarketingExpensesCategories = {
  [key: string]: { marketingExpenseCents: number[] }
}

export type GetMarketingExpenses = {
  hasLoaded: boolean
  timestamps: string[]
  precision: DatePrecision
  categories: MarketingExpensesCategories
}

export type RoasStore = Readonly<{
  marketingExpenses: GetMarketingExpenses
}>

const initialState: RoasStore = {
  marketingExpenses: {
    hasLoaded: false,
    timestamps: [],
    precision: DatePrecision.Month,
    categories: {} as MarketingExpensesCategories,
  },
}

export const roasReducer = createReducer(initialState, builder => {
  createThunkReducer(
    builder,
    getMarketingExpenses.typePrefix,
    'marketingExpenses',
    (state, action) => ({
      ...state,
      marketingExpenses: action.payload,
      hasLoaded: true,
    }),
  )
})
