import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import React, { useState } from 'react'
import { dispatch } from '../../../store'
import { createUser } from '../../../modules/adminDashboard/adminDashboard'
import { CompanyToAddRelation } from '../types'
import { AdminDashboardUser } from '../../../../server/services/adminDashboard'
import * as yup from 'yup'
import { CompanySummary } from './CompanySummary'
import TextField from '@mui/material/TextField'

interface Props {
  companyToAddRelation: CompanyToAddRelation
  onCancel: () => void
  onCreate: (newUser: AdminDashboardUser) => void
}
export const CreateUserModal = (props: Props): JSX.Element => {
  const { companyToAddRelation, onCancel, onCreate } = props
  const [userEmail, setUserEmail] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Enter an email.')
      .email('Enter a valid email address format')
      .notOneOf(
        companyToAddRelation.company.users.map(user => user.email),
        'Provided email exists',
      ),
  })
  const isFormValid = (): boolean => {
    return validationSchema.isValidSync({
      email: userEmail,
    })
  }

  const handleAddUserCreateButtonPressed = (): void => {
    const newUser = {
      id: userEmail,
      firstName,
      lastName,
      email: userEmail,
      createdAt: new Date().toISOString() as any,
      softDeletedAt: null,
    }
    if (userEmail && companyToAddRelation) {
      onCreate(newUser)

      dispatch(
        createUser({
          companyId: companyToAddRelation.company.id,
          userEmail: userEmail,
          firstName,
          lastName,
        }),
      )
    }

    setUserEmail('')
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Add User</h5>
        <CompanySummary companyToAddRelation={companyToAddRelation} />
        <Grid container={true} justifyContent={'end'}>
          <Grid container={true} sx={{ width: '100%' }}>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: '100%' }}
              >
                <Grid container={true} spacing={2} item={true} xs={12}>
                  <Grid item={true} xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      value={userEmail}
                      onChange={(e): void => setUserEmail(e?.target?.value)}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={firstName}
                      onChange={(e): void => setFirstName(e?.target?.value)}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={lastName}
                      onChange={(e): void => setLastName(e?.target?.value)}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sx={{ mr: 1 }}>
            <Button
              variant={'outlined'}
              color={'warning'}
              onClick={(): void => {
                setUserEmail('')
                onCancel()
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button
              disabled={!isFormValid()}
              variant={'contained'}
              color={'success'}
              onClick={handleAddUserCreateButtonPressed}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
