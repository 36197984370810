import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const CardBody = styled(Box)`
  display: grid;
  grid-template-areas: 'chart-container' 'legend-bottom';
  padding-top: 24px;
  max-width: 100%;
`

export const LoadingBody = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  height: 326px;
`
