import React, { CSSProperties, useState } from 'react'
import { LegendItem as LegendItemType } from '../BFGraph.types'
import { Color } from '../../../../../Color'
import { styled } from '@mui/material/styles'
import { Grid, Stack, Typography } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

const LegendColorBox = styled('div', {
  shouldForwardProp: (prop: any): boolean => prop !== 'backgroundColor',
})<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
  width: 8px;
  height: 8px;
  background: ${backgroundColor};
`,
)

const LegendItemExpandContainer = styled(Grid)`
  .MuiGrid-item {
    padding-left: 10px;
  }
`

interface BaseProps {
  itemStyle?: CSSProperties
  selectedValue: string | null
  renderCustomContent?: (
    item: LegendItemType,
    selectedValue: string | null,
  ) => JSX.Element
  renderLegendItemSubheading?: () => JSX.Element
  renderLegendItemExpandContent?: () => JSX.Element
  selectedExpansionValue?: string
  setSelectedExpansionValue?: (selectedValue: string) => void
}

export interface Props extends BaseProps {
  items: LegendItemType[]
  onItemClick: (
    item: LegendItemType,
    index: number,
    e: React.SyntheticEvent,
  ) => void
  legendTitle?: string
  containerStyle?: CSSProperties
  titleStyle?: CSSProperties
}

interface LegendItemProps extends BaseProps {
  item: LegendItemType
  onClick: (
    item: LegendItemType,
    index: number,
    e: React.SyntheticEvent,
  ) => void
  index: number
}

const ArrowExpand = ({ isExpanded }: { isExpanded: boolean }): JSX.Element => {
  if (isExpanded) {
    return <KeyboardArrowUp color="primary" />
  }

  return <KeyboardArrowDown color="primary" />
}

const LegendItem = (props: LegendItemProps): JSX.Element => {
  const {
    item,
    onClick,
    index,
    renderCustomContent,
    selectedValue,
    selectedExpansionValue,
    setSelectedExpansionValue,
  } = props

  const [showLegendItemContent, setShowLegendExpandContent] = useState<boolean>(
    false,
  )

  const isLegendContentExpandable = !!item?.renderLegendItemExpandContent

  if (renderCustomContent) return renderCustomContent(item, selectedValue)

  const expandContent =
    showLegendItemContent || selectedExpansionValue === item.name

  return (
    <Stack
      sx={{
        borderBottom: `2px solid ${Color.Grey}`,
        minHeight: '40px',
        p: 1,
      }}
      key={`legend-${item.name}`}
      justifyContent={'center'}
      onClick={(e): void => onClick(item, index, e)}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
      >
        <Grid
          item
          xs={isLegendContentExpandable ? 10 : 12}
          sx={{ cursor: 'pointer' }}
        >
          <Grid container spacing={2} alignItems={'center'} minWidth={'200px'}>
            <Grid item xs={1}>
              <LegendColorBox backgroundColor={item.color} />
            </Grid>
            <Grid item xs={isLegendContentExpandable ? 7 : 9}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: selectedValue === item.name ? '600' : 'normal',
                }}
              >
                {item.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isLegendContentExpandable ? (
          <Grid
            item
            xs={2}
            sx={{ cursor: 'pointer' }}
            onClick={(e): void => e.stopPropagation()}
          >
            <Grid container>
              <Grid
                item
                xs={8}
                sx={{ p: 1 }}
                onClick={(): void => {
                  setSelectedExpansionValue
                    ? setSelectedExpansionValue(item.name)
                    : setShowLegendExpandContent(!showLegendItemContent)
                }}
              >
                <ArrowExpand isExpanded={expandContent} />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {item?.renderLegendItemSubheading && (
        <LegendItemExpandContainer
          container
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={2}
          onClick={(e): void => e.stopPropagation()}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {item.renderLegendItemSubheading()}
          </Grid>
        </LegendItemExpandContainer>
      )}
      {item?.renderLegendItemExpandContent && expandContent && (
        <LegendItemExpandContainer
          container
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={2}
          onClick={(e): void => e.stopPropagation()}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {item?.renderLegendItemExpandContent()}
          </Grid>
        </LegendItemExpandContainer>
      )}
    </Stack>
  )
}

export const BFLegend = (props: Props): JSX.Element => {
  const {
    items,
    onItemClick,
    selectedValue,
    renderCustomContent,
    itemStyle,
    containerStyle,
    legendTitle,
    titleStyle,
    renderLegendItemSubheading,
    renderLegendItemExpandContent,
    selectedExpansionValue,
    setSelectedExpansionValue,
  } = props

  return (
    <div
      style={{
        ...containerStyle,
      }}
    >
      {legendTitle ? (
        <div
          style={{
            marginLeft: '8px',
            paddingBottom: '8px',
            fontSize: 'medium',
            borderBottom: `2px solid ${Color.Grey}`,
            ...titleStyle,
          }}
        >
          {legendTitle}
        </div>
      ) : null}
      {items.map((item, i) => (
        <LegendItem
          key={`legend-${item.name}`}
          item={item}
          onClick={onItemClick}
          index={i}
          selectedValue={selectedValue}
          renderCustomContent={renderCustomContent}
          itemStyle={itemStyle}
          renderLegendItemSubheading={renderLegendItemSubheading}
          renderLegendItemExpandContent={renderLegendItemExpandContent}
          selectedExpansionValue={selectedExpansionValue}
          setSelectedExpansionValue={setSelectedExpansionValue}
        />
      ))}
    </div>
  )
}
