import React from 'react'
import enTranslations from '@shopify/polaris/locales/en.json'
import { AppProvider, Modal } from '@shopify/polaris'

import { connect } from 'react-redux'

import { Store } from './modules/rootReducer'
import { theme } from './Theme'
import { AppRoutes } from './Routes'
import { ToolbarEx } from './components/designSystem/organisms/TopBar/Topbar'
import { MainFlex } from './components/designSystem/organisms/Containers/Containers'
import { Route, Switch } from 'react-router'

import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from './util/apolloClient'

function reloadPage(): void {
  window.location.reload()
}

type Props = {
  bootstrapError: string
  isBootstrapDone: boolean
}

const AppRaw: React.FC<Props> = props => {
  const ShopifyAppProviderWrapper = ({
    children,
  }: {
    children: React.ReactNode
  }): JSX.Element => (
    <ApolloProvider client={ApolloClient}>
      <AppProvider i18n={enTranslations} theme={theme}>
        {children}
      </AppProvider>
    </ApolloProvider>
  )

  if (props.isBootstrapDone && props.bootstrapError) {
    return (
      <ShopifyAppProviderWrapper>
        <Modal
          open={true}
          onClose={reloadPage}
          title="Error loading Brightflow"
          primaryAction={{
            content: 'Reload Brightflow',
            onAction: reloadPage,
          }}
        >
          <Modal.Section>{props.bootstrapError}</Modal.Section>
        </Modal>
      </ShopifyAppProviderWrapper>
    )
  }

  return (
    <ShopifyAppProviderWrapper>
      <MainFlex>
        <Switch>
          <Route path="/users/login">
            <></>
          </Route>
          <Route path="/users/signup">
            <></>
          </Route>
          <Route path="/users/terms-of-service">
            <></>
          </Route>
          <Route path="/get-started/credit-score">
            <></>
          </Route>
          <Route path="/get-started/thank-you">
            <></>
          </Route>
        </Switch>

        <AppRoutes />
      </MainFlex>
    </ShopifyAppProviderWrapper>
  )
}

function mapStateToProps(state: Store): Props {
  return {
    bootstrapError: state.bootstrap.data.error?.message || '',
    isBootstrapDone: state.bootstrap.data.isDone,
  }
}

export const App = connect(mapStateToProps)(React.memo(AppRaw))
