import { Category, Merchant } from '../../Transactions.types'
import { HandleOpenAddMerchantModalFn } from '../TransactionsTable/TransactionsTable.types'

export enum BulkActionModalTypes {
  Category = 'Category',
  Merchant = 'Merchant',
}

export type BulkActionModalProps = {
  handleApplyBulkUpdate: (newValue: Category | Merchant) => Promise<void>
  handleClose: () => void
  handleOpenAddMerchantModal: HandleOpenAddMerchantModalFn
  isOpen: boolean
  options: (Category | Merchant)[]
  transactionCount: number
}
