import { createReducer } from '@reduxjs/toolkit'
import { KNOWN_CONNECTOR_KEYS } from '../../../server/connectors/known-connectors'
import { createThunkReducer } from '../thunkReducerBuilder'
import { authRutterAPI } from './rutterApi'

// const initialRutterState = { ...initialXeroState, ...initialNetSuiteState }
// export const RUTTER_KEY = 'rutter'

// interface RutterStoreData {
//   [NETSUITE_KEY]: NetSuitePayload
//   [XERO_KEY]: XeroPayload
// }

// export type RutterPayload = {
//   [KNOWN_CONNECTOR_KEYS.netSuite]: RutterPayload
//   [KNOWN_CONNECTOR_KEYS.xero]: RutterPayload
// }

// export type RutterPayload = typeof SUPPORTED_CONNECTOR_KEYS[number]

// SUPPORTED_CONNECTOR_KEYS.map(key => ({ [key]: { authIsPending: false } })),

// const SUPPORTED_CONNECTOR_KEYS = [
//   KNOWN_CONNECTOR_KEYS.netSuite,
//   KNOWN_CONNECTOR_KEYS.xero,
// ]

export type SupportedConnectorKey =
  | typeof KNOWN_CONNECTOR_KEYS.netSuite
  | typeof KNOWN_CONNECTOR_KEYS.xero

export type ConnectionPayload = {
  authIsPending: boolean
}

export type RutterStoreData = {
  rutter: {
    [KNOWN_CONNECTOR_KEYS.netSuite]: ConnectionPayload
    [KNOWN_CONNECTOR_KEYS.xero]: ConnectionPayload
  }
}

export type RutterData = Readonly<RutterStoreData>

export const initialRutterState: RutterData = {
  rutter: {
    [KNOWN_CONNECTOR_KEYS.netSuite]: { authIsPending: false },
    [KNOWN_CONNECTOR_KEYS.xero]: { authIsPending: false },
  },
}

const AUTH_IS_PENDING = 'rutter/AUTH_IS_PENDING'

type AuthIsPendingPayload = {
  isPending: boolean
  connectionKey: string
}

type AuthIsPendingAction = {
  type: typeof AUTH_IS_PENDING
  payload: AuthIsPendingPayload
}

export const setAuthIsPending = (
  payload: AuthIsPendingPayload,
): AuthIsPendingAction => {
  return {
    type: AUTH_IS_PENDING,
    payload,
  }
}

export const rutterReducer = createReducer(initialRutterState, builder => {
  createThunkReducer(
    builder,
    authRutterAPI.typePrefix,
    'rutter',
    (state, action) => {
      const connectionKey = action.payload.connectionKey
      if (action.type === AUTH_IS_PENDING) {
        return {
          ...state,
          rutter: {
            ...state.rutter,
            [connectionKey]: {
              ...state.rutter[connectionKey as SupportedConnectorKey],
              authIsPending: action.payload.isPending,
            },
          },
        }
      } else {
        return {
          ...state,
          rutter: {
            ...state.rutter,
            [connectionKey]: {
              ...state.rutter[connectionKey as SupportedConnectorKey],
              authIsPending: typeof action.payload.connected === 'boolean',
            },
          },
        }
      }
    },
  )
})
