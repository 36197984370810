import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'

import { Layout } from '../../../layout'
import { WomanCoinBillIcon } from '../../../components/designSystem/assets/WomanCoinBillIcon/WomanCoinBillIcon'

import { TransactionsHeadingSection } from './components/TransactionsHeadingSection/TransactionsHeadingSection'
import { TransactionsTable } from './components/TransactionsTable/TransactionsTable'
import { TransactionsErrorState } from './components/TransactionsErrorState/TransactionsErrorState'

import { selectBankingConnectors } from '../../../modules/connectors'
import { NoConnections, UpgradePlanScreen } from '../shared'
import { Store } from '../../../modules/rootReducer'

import { useTransactions } from './Transactions.hooks'
import { TransactionsScreenProps } from './Transactions.types'
import { LoadingCircular } from '../../../components/designSystem/atoms/Loading/Loading'

const TransactionsImpl = (props: TransactionsScreenProps): JSX.Element => {
  const {
    accounts,
    categories,
    dateRange,
    hasBankingConnection,
    hasError,
    hasFeatureAccess,
    isContentLoading,
    isLabelsLoading,
    isSynced,
    merchants,
    refetchMerchants,
    rows,
    setDateRange,
    setSelectedAccounts,
  } = useTransactions(props)
  const { startDate, endDate } = dateRange

  if (isLabelsLoading) {
    return (
      <Layout>
        <LoadingCircular />
      </Layout>
    )
  }

  if (!hasFeatureAccess) {
    return (
      <Layout>
        <UpgradePlanScreen
          title="Label Banking Transactions To Enhance Data Feeds"
          subtitle="Our technology aggregates and enriches transaction data across all your cash sources to provide a real-time view on your transaction history."
          renderIcon={(): ReactNode => (
            <WomanCoinBillIcon height={177} width={131.62} />
          )}
        />
      </Layout>
    )
  }

  if (!hasBankingConnection) {
    return (
      <Layout>
        <NoConnections
          title="Get Visibility Into Your Banking Transactions"
          subtitle="To get started, please connect your bank account."
          icon={<WomanCoinBillIcon height={177} width={131.62} />}
          buttonText="Make a Connection"
        />
      </Layout>
    )
  }

  if (!isSynced) {
    return (
      <Layout>
        <NoConnections
          title="Collecting And Enriching Transaction Data"
          subtitle="Please allow 24 hours to collect and enrich transaction data."
          icon={<WomanCoinBillIcon height={177} width={131.62} />}
          buttonText="Check Connection Status"
        />
      </Layout>
    )
  }

  if (hasError) {
    return <TransactionsErrorState />
  }

  return (
    <Layout>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TransactionsHeadingSection
          start={startDate}
          end={endDate}
          synced={rows[0]?.cacheDate}
        />
        <TransactionsTable
          accounts={accounts}
          isContentLoading={isContentLoading}
          isLabelsLoading={isLabelsLoading}
          rows={rows}
          merchants={merchants}
          categories={categories}
          setDateRange={setDateRange}
          refetchMerchants={refetchMerchants}
          setSelectedAccounts={setSelectedAccounts}
        />
      </Box>
    </Layout>
  )
}

const mapStateToProps = (store: Store): TransactionsScreenProps => {
  return {
    bankingConnections: selectBankingConnectors(store.connectors),
    companyFeatures: store.companyFeatures.companyFeatures,
  }
}

export const TransactionsScreen = connect(mapStateToProps)(TransactionsImpl)
