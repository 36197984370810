import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')`
  display: block;
  position: fixed;
  z-index: 1;
  left: 20px;
  bottom: 20px;
  overflow: auto;
  height: 48px;
  width: 320px;
  border-radius: 4px;
  background-color: #333333;
`

const Text = styled('p')`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`

interface Props {
  message: string
}

export const Breadcrumb: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <Container>
      <Text>{message}</Text>
    </Container>
  )
}
