import React from 'react'
import { AdminDashboardSubscription } from '../../../../server/services/adminDashboard'
import { dispatch } from '../../../store'
import { cancelCompanySubscription } from '../../../modules/adminDashboard/adminDashboard'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { BasicModal } from '../../../components/designSystem/organisms/SpeedBumpModal/SpeedBumpModal'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'

interface Props {
  subscription: AdminDashboardSubscription
  onCancel: () => void
  onRemove: (subscription: AdminDashboardSubscription) => void
}

export const DeleteSubscriptionModal = (props: Props): JSX.Element => {
  const { subscription, onCancel, onRemove } = props

  const handleSubscriptionDeleteConfirmClicked = (): void => {
    if (subscription) {
      dispatch(cancelCompanySubscription(subscription))
      onRemove(subscription)
    }
  }

  return (
    <BasicModal contentWidth="550px">
      <Stack spacing={2}>
        <h5>Confirm Delete</h5>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Subscription</strong>
                </TableCell>
                <TableCell>{subscription.plan.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Company ID:</strong>
                </TableCell>
                <TableCell>{subscription.companyId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container={true} justifyContent={'end'}>
          <Grid item sx={{ mr: 1 }}>
            <Button variant={'outlined'} color={'warning'} onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={handleSubscriptionDeleteConfirmClicked}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </BasicModal>
  )
}
