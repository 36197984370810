import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { flatten, fromPairs } from 'lodash'

import { Color } from '../../../Color'
import { GetRevenueResults } from '../../../../server/routes/api/reports/index'
import { InflowCategory } from '../../../../server/data/models/sync/types'
import { Money } from '../../../../util/money'

import { CATEGORY_LABELS, formatDate } from './shared'

type DataPoint = {
  ts: Date
} & {
  [key in InflowCategory]: number
}

function getRevenueData({
  timestamps,
  categories,
}: GetRevenueResults): Readonly<DataPoint[]> {
  return timestamps.map((ts, idx) =>
    fromPairs(
      flatten([
        [['ts', new Date(ts).getTime()]],
        Object.entries(categories).map(([category, values]) => [
          category,
          values.revenueCents[idx],
        ]),
      ] as any),
    ),
  ) as any
}

const BAR_COLORS: { [key: string]: Record<string, string> } = {
  CONNECTORS: {
    amazonSales: Color.Amazon,
    shopifySales: Color.Shopify,
  },
}

function getBarColor(allCategories: string[], category: string): string {
  return BAR_COLORS.CONNECTORS[category]
}

export const RevenueGraph: React.FunctionComponent<{
  data: GetRevenueResults
}> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={getRevenueData(data)}>
        <XAxis
          dataKey="ts"
          tickFormatter={(ts): string => formatDate(ts, data.precision)}
        />

        <YAxis tickFormatter={(v): string => new Money(v).humanize()} />

        <CartesianGrid vertical={false} strokeDasharray="3 3" />

        <Tooltip
          labelFormatter={(ts): string => formatDate(ts, data.precision)}
          formatter={(value, category): [string, string] => [
            new Money((value as any) as number).toString(),
            CATEGORY_LABELS[category as InflowCategory] || category,
          ]}
        />

        <Legend
          formatter={(category): string =>
            CATEGORY_LABELS[category as InflowCategory] || category
          }
        />

        {Object.keys(data.categories).map(category => (
          <Bar
            dataKey={category}
            key={category}
            stackId="categories"
            fill={getBarColor(Object.keys(data.categories), category)}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
