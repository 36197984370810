import {
  CompanyUpsertRouteParams,
  CompanyUpsertRouteResponse,
} from '../../../server/routes/api/companies/upsert'

import { api } from '../../lib/api'
import { Thunk } from '../../store'

export const UPSERT_PENDING = 'companies/upsert/PENDING'
export const UPSERT_SUCCESS = 'companies/upsert/SUCCESS'
export const UPSERT_FAILURE = 'companies/upsert/FAILURE'

export type UpsertPendingAction = {
  type: typeof UPSERT_PENDING
}
export type UpsertSuccessAction = {
  type: typeof UPSERT_SUCCESS
  payload: CompanyUpsertRouteResponse
}

export type UpsertFailureAction = {
  type: typeof UPSERT_FAILURE
  payload: Error
}

const upsertPending = (): UpsertPendingAction => ({ type: UPSERT_PENDING })

const upsertSuccess = (
  payload: CompanyUpsertRouteResponse,
): UpsertSuccessAction => ({ type: UPSERT_SUCCESS, payload })

const upsertFailure = (payload: Error): UpsertFailureAction => ({
  type: UPSERT_FAILURE,
  payload,
})

export const upsertCompany = (data: CompanyUpsertRouteParams): Thunk => async (
  dispatch,
): Promise<void> => {
  dispatch(upsertPending())

  try {
    const response = await api.post('/api/companies', data)
    if (response.success) dispatch(upsertSuccess(response))
    else dispatch(upsertFailure(new Error(`Failed to upsert Company`)))
  } catch (error: any) {
    dispatch(upsertFailure(error))
  }
}
