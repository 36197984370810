import React, { ReactNode, createContext, useContext, useState } from 'react'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import {
  DateGranularity,
  useGetCashPositionByDurationQuery,
  useGetTransactionAccountsQuery,
} from '../../../../server/app-data-service/generatedTypes'

import { CashPositionContextType } from './CashPosition.types'
import { getCalculatedStateFromCashPositionResponse } from './CashPosition.utils'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { Layout } from '../../../layout'
import { Color } from '../../../Color'

type Props = {
  children: ReactNode
}

const CashPositionContext = createContext<CashPositionContextType | undefined>(
  undefined,
)

export const CashPositionProvider = ({ children }: Props): JSX.Element => {
  const apiRef = useGridApiRef()
  const [filterState, setFilterState] = useLocalStorage<{
    granularity: DateGranularity
    duration: number
  }>('cashPositionFilters', { granularity: 'Week', duration: 13 })
  const [selectedAccounts, setSelectedAccounts] = useLocalStorage<
    string[] | null
  >('transactionAccounts', null)

  const {
    data: accountsData,
    loading: isAccountsLoading,
    error: hasAccountsError,
  } = useGetTransactionAccountsQuery()

  const {
    data: CashPositionData,
    loading: isCashPositionLoading,
    error: hasCashPositionError,
  } = useGetCashPositionByDurationQuery({
    variables: {
      accountIds: selectedAccounts,
      duration: filterState.duration,
      granularity: filterState.granularity,
    },
  })

  const handleDateRangeFilterChange = (
    granularity: DateGranularity,
    duration: number,
  ): void => {
    setFilterState({ granularity, duration })
  }

  const handleExportData = (): void => {
    apiRef.current.exportDataAsCsv()
  }

  const accounts =
    accountsData?.transactionAccounts.map(
      ({ accountId, accountName, accountMask }) => ({
        id: accountId,
        name: `${accountName} <${accountMask}>`,
        isSelected: selectedAccounts
          ? selectedAccounts.includes(accountId)
          : true,
      }),
    ) || []

  const calculatedState = CashPositionData?.getCashPositionByDuration
    ? getCalculatedStateFromCashPositionResponse(
        CashPositionData?.getCashPositionByDuration,
      )
    : {}

  const hasError = hasAccountsError || hasCashPositionError

  return (
    <CashPositionContext.Provider
      value={{
        ...calculatedState,
        apiRef,
        accounts,
        duration: filterState.duration,
        granularity: filterState.granularity,
        handleDateRangeFilterChange,
        handleExportData,
        hasError,
        isLoading: isAccountsLoading || isCashPositionLoading,
        selectedAccounts,
        setSelectedAccounts,
      }}
    >
      <Layout background={hasError ? Color.White : Color.LightestGrey}>
        {children}
      </Layout>
    </CashPositionContext.Provider>
  )
}

export const useCashPosition = (): CashPositionContextType => {
  const context = useContext(CashPositionContext)
  if (context === undefined) {
    throw new Error(
      'useCashPosition must be used within a CashPositionProvider',
    )
  }
  return context
}
