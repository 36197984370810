import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { format, subDays, subMonths } from 'date-fns'
import _ from 'lodash'

// TODO(connor): remove server imports
import { DatePrecision } from '../../../../server/data/models/DatePrecision'

import { Headline } from '../../../layout'
import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'
import {
  RevenueStore,
  getRevenueResults,
} from '../../../modules/reports/revenue'
import { Toolbar } from '../../../layout/Toolbar'

import { RevenueGraph } from './RevenueGraph'
import { CurrentRevenue } from './CurrentRevenue'
import { SectionedTable } from './SectionedTable'

interface OwnProps {
  interval: 'day' | 'month'
  disableMonthly: boolean
}

type Props = RevenueStore & OwnProps

const HISTORICAL_MONTHS_TO_SHOW = 12 // will actually show 13 months as we subtracting
const HISTORICAL_DAYS_TO_SHOW = 7 // will actually show 8 days as we are subtracting

const RevenueReportImpl: React.FunctionComponent<Props> = ({
  revenueResults,
  interval,
  disableMonthly,
}) => {
  const history = useHistory()

  useEffect(() => {
    let start
    let end
    let precision

    switch (interval) {
      case 'day':
        end = format(subDays(new Date(), 1), 'yyyyMMdd')
        start = format(
          subDays(subDays(new Date(), 1), HISTORICAL_DAYS_TO_SHOW),
          'yyyyMMdd',
        )
        precision = DatePrecision.Day
        break
      case 'month':
        if (disableMonthly) {
          history.push({
            pathname: '/revenue/day',
          })
          return
        }
        end = format(new Date(), 'yyyyMMdd')
        start = format(
          subMonths(new Date(), HISTORICAL_MONTHS_TO_SHOW),
          'yyyyMMdd',
        )
        precision = DatePrecision.Month
        break
    }

    dispatch(
      getRevenueResults({
        start: start,
        end: end,
        precision,
      }),
    )
  }, [interval])

  return (
    <>
      <Toolbar
        interval={interval}
        type="revenue"
        time={_.last(revenueResults?.timestamps) || ''}
        disableMonthly={disableMonthly}
      />
      <CurrentRevenue interval={interval} data={revenueResults} />
      <Headline title="Order Revenue By Channel" />
      <RevenueGraph data={revenueResults} />
      <SectionedTable data={revenueResults} />
    </>
  )
}

const mapStateToProps = (store: Store, ownProps: OwnProps): Props => {
  return {
    ...store.revenue,
    ...ownProps,
  }
}

export const RevenueReport = connect(mapStateToProps)(RevenueReportImpl)
