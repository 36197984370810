import React, { ReactNode, createContext, useContext, useState } from 'react'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import {
  DateGranularity,
  useGetSalesForecastByDurationQuery,
  useGetSalesForecastStoresQuery,
} from '../../../../../server/app-data-service/generatedTypes'

import { getCalculatedStateFromSalesForecastResponse } from './SalesForecast.utils'
import { SalesForecastContextType } from './SalesForecast.types'

import { Layout } from '../../../../layout'
import { Color } from '../../../../Color'

const SalesForecastContext = createContext<
  SalesForecastContextType | undefined
>(undefined)

export const SalesForecastProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const apiRef = useGridApiRef()
  const handleExportData = (): void => apiRef.current.exportDataAsCsv()

  const [timePeriod, setTimePeriod] = useState<{
    granularity: DateGranularity
    duration: number
  }>({
    granularity: 'Week',
    duration: 13,
  })
  const [filterAccounts, setFilterAccounts] = useState<string[] | null>(null)

  const {
    data: salesForecastData,
    loading: isSalesForecastLoading,
    error: hasSalesForecastError,
  } = useGetSalesForecastByDurationQuery({
    variables: {
      granularity: timePeriod.granularity,
      duration: timePeriod.duration,
      storeIds: filterAccounts,
    },
  })

  const handleTimePeriodChange = (durationGranularityString: string): void => {
    const [duration, granularity] = durationGranularityString.split('-')
    setTimePeriod({
      granularity: granularity as DateGranularity,
      duration: Number(duration),
    })
  }

  const {
    data: salesForecastStoresData,
    loading: isSalesForecastStoresLoading,
    error: hasSalesForecastStoresError,
  } = useGetSalesForecastStoresQuery()

  const accounts =
    salesForecastStoresData?.salesForecastStores.map(({ id, name }) => ({
      id,
      name,
      isSelected: filterAccounts ? filterAccounts.includes(id) : true,
    })) || []

  const calculatedState = salesForecastData?.salesForecastByDuration
    ? getCalculatedStateFromSalesForecastResponse(
        salesForecastData?.salesForecastByDuration,
        timePeriod.granularity,
      )
    : {}

  const isLoading = isSalesForecastLoading || isSalesForecastStoresLoading

  const hasError = hasSalesForecastError || hasSalesForecastStoresError

  return (
    <SalesForecastContext.Provider
      value={{
        ...calculatedState,
        apiRef,
        handleExportData,
        duration: timePeriod.duration,
        granularity: timePeriod.granularity,
        handleTimePeriodChange,
        accounts,
        setFilterAccounts,
        isLoading,
        hasError,
      }}
    >
      <Layout background={hasError ? Color.White : Color.LightestGrey}>
        {children}
      </Layout>
    </SalesForecastContext.Provider>
  )
}

export const useSalesForecast = (): SalesForecastContextType => {
  const context = useContext(SalesForecastContext)
  if (context === undefined) {
    throw new Error(
      'useSalesForecast must be used within a SalesForecastProvider',
    )
  }
  return context
}
