import React from 'react'
import CSS from 'csstype'
import { Button } from '@mui/material'

const baselineStyles = {
  width: '150px',
  height: '44px',
  fontSize: '14px',
}
interface Props {
  isEditing: boolean
  onEditSave: () => void
  editText?: string
  saveText?: string
  styles?: CSS.Properties
  disabled?: boolean
}

export const EditSaveButton: React.FunctionComponent<Props> = ({
  isEditing,
  onEditSave,
  editText = 'Edit',
  saveText = 'Save',
  styles,
  disabled = false,
}) => {
  return (
    <Button
      variant={'contained'}
      sx={{ ...baselineStyles, ...styles }}
      onClick={onEditSave}
      disabled={disabled}
    >
      {isEditing ? saveText : editText}
    </Button>
  )
}
