import React from 'react'

import { OverviewGraphCard } from '../OverviewGraphCard/OverviewGraphCard'

import { Props } from './OverviewRevenueCard.types'
import { useOverviewRevenueCard } from './OverviewRevenueCard.hooks'
import { OverviewCardTypes } from '../../Overview.types'
import { OverviewOrderRevenueEmptyState } from './components/OverviewOrderRevenueEmptyState'

export const OverviewRevenueCard: React.FunctionComponent<Props> = ({
  cardType,
  revenueConnections,
  duration,
  granularity,
}) => {
  const { channels, totals, isLoading, isEmpty } = useOverviewRevenueCard({
    cardType,
    revenueConnections,
    duration,
    granularity,
  })
  return (
    <OverviewGraphCard
      type={cardType}
      isEmpty={isEmpty}
      emptyState={
        cardType === OverviewCardTypes.OrderRevenue && (
          <OverviewOrderRevenueEmptyState />
        )
      }
      channels={channels}
      totals={totals}
      isLoading={isLoading}
    />
  )
}
