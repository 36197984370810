import React from 'react'
import { Typography } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Color } from '../../../../../Color'

export interface RechartsPayloadItem {
  baseValue: number | string
  comparisonValue: number | string
  groupName: string
  color: string
}

type FormatFunction = (value: string | number) => string | number

const getPercentDiff = (base: number, comparison: number): number => {
  const perDiff = (comparison - base) / base

  return perDiff
}

interface ToolTipProps {
  active: boolean
  payload: RechartsPayloadItem[]
  label: string
  baseName: string
  comparisonName?: string
  showTotal?: boolean
  formatLabelFunc: FormatFunction
  boldBase?: boolean
  boldComparison?: boolean
}

const GroupNameItem = ({
  value,
  color,
}: {
  value: string | number
  color: string
}): JSX.Element => (
  <Grid item xs={6} ml={1} minWidth="185px">
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: '8px',
          width: '8px',
          marginRight: '4px',
          backgroundColor: color,
        }}
      />
      <Typography variant="comparisonPrimary">{value}</Typography>
    </div>
  </Grid>
)

const BaseItem = ({
  value,
  boldBase = true,
  formatLabelFunc,
}: {
  value: string | number
  boldBase?: boolean
  formatLabelFunc: FormatFunction
}): JSX.Element => (
  <Grid item xs alignSelf="center">
    <Typography
      variant={boldBase ? 'comparisonPrimary' : 'comparisonSecondary'}
      sx={{ ...(!boldBase && { color: Color.TooltipGrey }) }}
    >
      {formatLabelFunc(value)}
    </Typography>
  </Grid>
)

const RelativeChangeItem = ({
  value,
}: {
  value: string | number
}): JSX.Element => {
  let icon: JSX.Element | null =
    value < 0 ? (
      <ArrowDownwardIcon htmlColor="#BF0711" sx={{ fontSize: 12 }} />
    ) : (
      <ArrowUpwardIcon htmlColor="#0B9E00" sx={{ fontSize: 12 }} />
    )
  let formattedValue
  if (typeof value === 'string') {
    icon = null
    formattedValue = value
  } else {
    formattedValue = `${(value * 100).toFixed(0)}%`
  }

  return (
    <Grid
      item
      xs
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      {icon}
      <Typography
        variant="comparisonSecondary"
        sx={{ color: Color.TooltipGrey }}
      >
        {formattedValue}
      </Typography>
    </Grid>
  )
}

const ComparisonItem = ({
  value,
  boldComparison = false,
  formatLabelFunc,
}: {
  value: string | number
  boldComparison?: boolean
  formatLabelFunc: FormatFunction
}): JSX.Element => (
  <Grid item xs alignSelf="center" maxWidth="50px">
    <Typography
      variant={boldComparison ? 'comparisonPrimary' : 'comparisonSecondary'}
      sx={{ ...(!boldComparison && { color: Color.TooltipGrey }) }}
    >
      {formatLabelFunc(value)}
    </Typography>
  </Grid>
)

const ComparisonTitle = ({
  baseName,
  boldBase = true,
  comparisonName,
  boldComparison = false,
  label,
}: {
  baseName: string
  boldBase?: boolean
  comparisonName?: string
  boldComparison?: boolean
  label: string
}): JSX.Element => {
  return (
    <Grid
      pl={1}
      container
      sx={{
        borderBottom: `1px solid ${Color.DisabledLight}`,
        display: 'flex',
        justifyContent: 'space-between',
        height: '45px',
      }}
    >
      <Grid item xs={6} alignSelf="center" minWidth="185px">
        <Typography variant="comparisonPrimary">{label}</Typography>
      </Grid>
      <Grid item xs alignSelf="center">
        <Typography
          variant={boldBase ? 'comparisonPrimary' : 'comparisonSecondary'}
          sx={{ ...(!boldBase && { color: Color.TooltipGrey }) }}
        >
          {baseName}
        </Typography>
      </Grid>
      {comparisonName && (
        <>
          <Grid
            item
            xs
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="comparisonSecondary"
              sx={{ color: Color.TooltipGrey }}
            >
              vs
            </Typography>
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              alignSelf: 'center',
              maxWidth: '70px',
            }}
          >
            <Typography
              variant={
                boldComparison ? 'comparisonPrimary' : 'comparisonSecondary'
              }
              sx={{ ...(!boldComparison && { color: Color.TooltipGrey }) }}
            >
              {comparisonName}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const TotalRow = ({
  showComparison,
  compareValue,
  formatLabelFunc,
  boldComparison = false,
  boldBase = true,
}: {
  showComparison: boolean
  compareValue: RechartsPayloadItem[]
  formatLabelFunc: FormatFunction
  boldComparison?: boolean
  boldBase?: boolean
}): JSX.Element => {
  const comparisonOb = compareValue.reduce<{
    totalBaseValue: number
    totalComparisonValue: number
    totalRelativeChange?: number | string
  }>(
    (accum, compareGroup) => {
      const { baseValue, comparisonValue } = compareGroup
      accum.totalBaseValue += typeof baseValue === 'string' ? 0 : baseValue
      accum.totalComparisonValue +=
        typeof comparisonValue === 'string' ? 0 : comparisonValue
      return accum
    },
    { totalBaseValue: 0, totalComparisonValue: 0 },
  )

  comparisonOb.totalRelativeChange =
    comparisonOb.totalBaseValue === 0
      ? 'N/A'
      : getPercentDiff(
          comparisonOb.totalBaseValue,
          comparisonOb.totalComparisonValue,
        )

  // if every basevalue is 'n/a', set the total to be n/a
  const formattedBaseValue = compareValue.every(
    ({ baseValue }) => baseValue === 'N/A',
  )
    ? 'N/A'
    : comparisonOb.totalBaseValue

  return (
    <Grid
      container
      sx={{
        borderTop: `1px solid ${Color.DisabledLight}`,
        display: 'flex',
        justifyContent: 'space-between',
        height: '40px',
      }}
    >
      <Grid item xs={6} ml={1} alignSelf="center" minWidth="185px">
        <Typography variant="comparisonPrimary">Total</Typography>
      </Grid>
      <BaseItem
        value={formattedBaseValue}
        formatLabelFunc={formatLabelFunc}
        boldBase={boldBase}
      />
      {showComparison && (
        <>
          <RelativeChangeItem value={comparisonOb.totalRelativeChange} />
          <ComparisonItem
            value={comparisonOb.totalComparisonValue}
            formatLabelFunc={formatLabelFunc}
            boldComparison={boldComparison}
          />
        </>
      )}
    </Grid>
  )
}

export const ComparatorTooltip = (props: ToolTipProps): JSX.Element | null => {
  const {
    active,
    payload,
    label,
    baseName,
    comparisonName,
    showTotal,
    formatLabelFunc,
    boldBase,
    boldComparison,
  } = props

  if (!active || !payload || !payload.length) return null

  const minWidth = comparisonName ? '380px' : '265px'

  return (
    <Box
      sx={{
        border: `1px solid ${Color.SemiWhite}`,
        boxShadow: `0 2px 4px 0 ${Color.Transparent}`,
        borderRadius: '8px',
        opacity: '100%',
        backgroundColor: Color.White,
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        maxHeight: '500px',
        flexWrap: 'wrap',
        flexGrow: 1,
        maxWidth: '100%',
        minWidth,
        width: 'fit-content',
      }}
    >
      <ComparisonTitle
        baseName={baseName}
        comparisonName={comparisonName}
        label={label}
        boldBase={boldBase}
        boldComparison={boldComparison}
      />
      <Box maxHeight={400} overflow="scroll">
        {payload.map(({ comparisonValue, baseValue, groupName, color }) => {
          let relChange
          if (
            typeof baseValue === 'number' &&
            typeof comparisonValue === 'number' &&
            baseValue !== 0
          ) {
            relChange = getPercentDiff(baseValue, comparisonValue)
          } else {
            relChange = 'N/A'
          }
          return (
            <Grid
              pt={1}
              pb={1}
              container
              key={groupName}
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <GroupNameItem value={groupName} color={color} />
              <BaseItem
                boldBase={boldBase}
                value={baseValue}
                formatLabelFunc={formatLabelFunc}
              />
              {comparisonName && (
                <>
                  <RelativeChangeItem value={relChange} />
                  <ComparisonItem
                    value={comparisonValue || 'N/A'}
                    formatLabelFunc={formatLabelFunc}
                    boldComparison={boldComparison}
                  />
                </>
              )}
            </Grid>
          )
        })}
      </Box>
      {showTotal ? (
        <TotalRow
          showComparison={!!comparisonName}
          compareValue={payload}
          formatLabelFunc={formatLabelFunc}
          boldBase={boldBase}
          boldComparison={boldComparison}
        />
      ) : null}
    </Box>
  )
}
