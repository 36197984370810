import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { Layout } from '../../../layout'
import { Store } from '../../../modules/rootReducer'
import { dispatch } from '../../../store'
import {
  getConnectors,
  selectRevenueConnectors,
} from '../../../modules/connectors'
import { Connection } from '../../../modules/connectors/types'
import { useInterval } from '../../../hooks/useInterval'

import { NoConnections } from '../shared/'

import { RevenueReport } from './RevenueReport'
import { LoadingRevenueConnections } from './LoadingRevenueConnections'

interface OwnProps {
  match: {
    params: {
      interval: string
    }
  }
}

type Props = {
  revenueConnections: Connection[]
  interval: string
}

const RevenueImpl: React.FunctionComponent<Props> = ({
  interval,
  revenueConnections: existingRevenueConnections,
}) => {
  const hasExistingRevenueConnections = existingRevenueConnections?.length > 0

  const hasOneWeekRevenueData = existingRevenueConnections.some(
    ({ percentOfWeekComplete }) => percentOfWeekComplete === 100,
  )

  /**
   * We consider one year to be complete if one of the following is true
   * 1. One year of historical data has synced (oldestTransactionDate < 365 days ago)
   * 2. The initial backwards sync has completed (i.e. all historical data that exists has synced)
   */
  const hasOneYearRevenueData = existingRevenueConnections.some(
    ({ percentOfYearComplete, initialSyncStatus }) =>
      percentOfYearComplete === 100 || initialSyncStatus === 'complete',
  )

  const shouldShowRevenue =
    hasExistingRevenueConnections && hasOneWeekRevenueData

  const shouldShowLoadingRevenue =
    hasExistingRevenueConnections && !hasOneWeekRevenueData

  if (!hasOneYearRevenueData && interval === 'month') {
    return <Redirect to="/revenue/day" />
  }
  if (interval !== 'month' && interval !== 'day') {
    return <Redirect to="/revenue" />
  }

  // initial call to get connectors
  // so that we don't have to wait for polling
  useEffect(() => {
    dispatch(getConnectors())
  }, [])

  // poll for connection info
  useInterval(
    () => {
      dispatch(getConnectors())
    },
    // poll every 5 seconds unless
    // revenue data is fully available
    hasOneYearRevenueData ? null : 5000,
  )

  return (
    <Layout>
      {!hasExistingRevenueConnections && (
        <NoConnections
          title={'Start Tracking Your Revenue'}
          subtitle={'To start tracking, connect a revenue source.'}
        />
      )}
      {shouldShowRevenue && (
        <RevenueReport
          interval={interval}
          disableMonthly={!hasOneYearRevenueData}
        />
      )}
      {shouldShowLoadingRevenue && (
        <LoadingRevenueConnections connections={existingRevenueConnections} />
      )}
    </Layout>
  )
}

const mapStateToProps = (store: Store, ownProps: OwnProps): Props => {
  return {
    interval: ownProps.match.params.interval,
    revenueConnections: selectRevenueConnectors(store.connectors),
  }
}

export const RevenueScreen = connect(mapStateToProps)(React.memo(RevenueImpl))
