import React, { ElementType } from 'react'
import { styled } from '@mui/material/styles'
import { scaleThreshold } from 'd3-scale'
import { numberAsMoney } from '../../../util/displayValue'

type RowType = 'heading' | 'subheading' | 'row' | 'subrow'
export type Row = { values: string[]; type: RowType }

type SectionProps = Readonly<{
  data: Row[]
  min: number
  max: number
}>

type GrossMarginSectionProps = Readonly<{
  data: GrossMarginRow[]
  gross: string
}>

export type GrossMarginRow = { values: number[]; type: RowType }

type RowProps = Readonly<{
  data: Row
  min: number
  max: number
}>

type GrossMarginRowProps = Readonly<{
  data: number[]
  gross: string
}>

type Props = {
  color?: string
  lessWeight?: boolean
}

const HeatTd = styled('td', {
  shouldForwardProp: (prop: any): boolean =>
    prop !== 'color' && prop !== 'lessWeight',
})<Props>(
  ({ color, lessWeight }) => `
  padding: 4px 10px;
  border: 3px solid white;
  min-width: 80px;
  max-width: 80px;
  text-align: center;

  background-color: ${color ?? 'white'};
  font-weight: ${lessWeight ? 'lighter' : ''};
`,
)

export const HeadingTr = styled('tr')`
  white-space: break-spaces;
  th:first-child {
    width: 130px;
  }
  th:first-child + th {
    width: 103px;
  }
  th {
    width: 40px;
    font-size: 10px;
  }
  td,
  th {
    font-weight: lighter;
    padding: 0 18px;
  }
`

const SubheadingTr = styled('tr')`
  border-bottom: 1px solid black;
`

const PaddingTh = styled('th')`
  && {
    padding-left: 3em;
  }
  font-weight: lighter;
`

const GrossMarginRowValue = styled('div')`
  &&:last-child {
    margin: 0;
  }
  padding: 4px 10px;
  margin-right: 7px;
  min-width: 75px;
  max-width: 75px;
  border: 1px solid grey;
  text-align: center;
`

const GrossMarginRowContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 0 4px;
`

const SubrowTh: React.FC = ({ children }) => (
  <PaddingTh scope="rowgroup">{children}</PaddingTh>
)
const HeadingTh: React.FC = ({ children }) => (
  <th scope="colgroup" style={{ fontWeight: 'lighter' }}>
    {children}
  </th>
)
const CommonTh: React.FC = ({ children }) => (
  <th scope="rowgroup" style={{ fontWeight: 'lighter' }}>
    {children}
  </th>
)

const FindHeat = (
  Td: ElementType,
  value: number,
  idx: number,
  maxRangeValue: number,
  minRangeValue: number,
) => {
  const middleRangeValue = (maxRangeValue - minRangeValue) / 2 + minRangeValue
  const color = scaleThreshold<number, string>()
    .domain([
      Math.ceil(minRangeValue), // Min of Range
      Math.round((middleRangeValue - minRangeValue) / 2 + minRangeValue), // Middle value between range min and middle
      middleRangeValue, // Middle of Range
      Math.round((maxRangeValue - middleRangeValue) / 2 + middleRangeValue), // Middle value between range middle and max
      Math.floor(maxRangeValue), // Max of Range
    ])
    .range(['#E4F2D2', '#C9E5A6', '#AED879', '#78BE20', '#5A8F18'])
  return (
    <Td key={idx} color={color(value)} lessWeight={true}>
      {numberAsMoney(value)}
    </Td>
  )
}

export const GrossMarginRow: React.FC<GrossMarginRowProps> = ({
  data,
  gross,
}) => {
  return (
    <GrossMarginRowContainer>
      {data.map((value, idx) => {
        const grossMargin = parseInt(gross) / 100
        return (
          <GrossMarginRowValue key={idx}>
            {numberAsMoney(value * grossMargin)}
          </GrossMarginRowValue>
        )
      })}
    </GrossMarginRowContainer>
  )
}

export const GrossMarginSection: React.FunctionComponent<GrossMarginSectionProps> = ({
  data,
  gross,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <GrossMarginRow data={data[1].values} gross={gross} />
    </div>
  )
}

export const ProjectedRow: React.FC<RowProps> = ({ data, min, max }) => {
  return (
    <tr>
      {data.type === 'heading'
        ? data.values.map((value, idx) => {
            return (
              <td style={{ textAlign: 'center', fontSize: '10px' }} key={idx}>
                {value}
              </td>
            )
          })
        : data.values.map((value, idx) =>
            FindHeat(HeatTd, parseFloat(value), idx, max, min),
          )}
    </tr>
  )
}

export const ProjectedSection: React.FunctionComponent<SectionProps> = ({
  data,
  min,
  max,
}) => {
  return (
    <table>
      <tbody style={{ width: '100%', borderCollapse: 'collapse' }}>
        {data.map((row, idx) => {
          return <ProjectedRow data={row} key={idx} min={min} max={max} />
        })}
      </tbody>
    </table>
  )
}

export const HeatRow: React.FC<RowProps> = ({ data, min, max }) => {
  const {
    type,
    values: [title, members, ...rest],
  } = data
  const [Tr, Th, Td] = ((
    type: RowType,
  ): (React.FC | keyof React.ReactHTML)[] => {
    switch (type) {
      case 'heading':
        return [HeadingTr, HeadingTh, HeadingTh]
      case 'row':
        return ['tr', CommonTh, HeatTd]
      default:
        throw new Error('Row type is not valid')
    }
  })(type)

  return (
    <Tr>
      <Th style={{ textAlign: 'center' }}>{title}</Th>
      <Th style={{ textAlign: 'center' }}>{members.toLocaleString()}</Th>
      {type === 'heading'
        ? rest.map((value, idx) => {
            return <Td key={idx}>{value}</Td>
          })
        : rest.map((value, idx) =>
            FindHeat(Td, parseFloat(value), idx, max, min),
          )}
    </Tr>
  )
}

export const HeatSection: React.FunctionComponent<SectionProps> = ({
  data,
  min,
  max,
}) => {
  return (
    <table>
      <tbody style={{ width: '100%', borderCollapse: 'collapse' }}>
        {data.map((row, idx) => {
          return <HeatRow data={row} key={idx} min={min} max={max} />
        })}
      </tbody>
    </table>
  )
}
