import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { Color } from '../../../../Color'
import { NavigationLink } from '../../atoms/NavigationLink/NavigationLink'

type Props = {
  companyName: string
  options: {
    title: string
    link: string
    isInternal: boolean
  }[]
}

const RenderDynamicNavLink: React.FunctionComponent<{
  isInternal: boolean
  link: string
}> = ({ isInternal, link, children }) => {
  if (isInternal) return <NavigationLink to={link}>{children}</NavigationLink>
  else {
    return (
      <NavigationLink to="" onClick={() => (window.location.href = link)}>
        {children}
      </NavigationLink>
    )
  }
}

export const NavigationSettings: React.FunctionComponent<Props> = ({
  companyName,
  options,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  let initials
  const name = companyName ? companyName.split(' ') : []

  if (name.length === 1) {
    initials = name[0][0] + name[0][1]
  }

  if (name.length >= 2) {
    initials = name[0][0] + name[1][0]
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        bottom: { xs: 24, lg: 40 },
        left: 14,
        background: Color.BrandHighlightBlue,
        padding: '10px 16px',
        borderRadius: '4px',
        width: '248px',
      }}
    >
      <Avatar
        sx={{
          bgcolor: Color.Grey6,
          width: 30,
          height: 30,
          color: Color.Black,
          fontSize: 16,
        }}
      >
        {initials}
      </Avatar>
      <Tooltip title={companyName}>
        <Typography
          sx={{
            marginLeft: 1,
            width: '155px',
            maxWidth: '155px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="subtitle1"
          color={Color.White}
        >
          {companyName}
        </Typography>
      </Tooltip>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <SettingsIcon
          sx={{ width: '16px', height: '16px', color: Color.White }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            background: Color.BrandHighlightBlue,
            width: '248px',
            color: Color.White,
            marginLeft: '-2px',
          },
        }}
        anchorOrigin={{
          vertical: -14,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index}>
              <RenderDynamicNavLink
                link={option.link}
                isInternal={option.isInternal}
              >
                <Typography variant="subtitle2">{option.title}</Typography>
              </RenderDynamicNavLink>
            </MenuItem>
          )
        })}
      </Menu>
    </Stack>
  )
}
