import {
  BootstrapDataUserInfo,
  EmbeddedShopifyInfo,
} from '../../../server/routes/types'
import { DeleteUserResponse } from '../../../server/routes/api/users/delete'
import { AsyncState, Thunk } from '../../store'
import { api } from '../../lib/api'
import { createReducer } from '@reduxjs/toolkit'
import { createThunkReducer } from '../thunkReducerBuilder'
import {
  addUser,
  forgotPasswordLink,
  loginUser,
  resetPasswordLinkValidation,
  updatePassword,
} from './user'
import { User as DBUser } from '../../../server/data/models'
import { LoadingAndError } from '../types'

// ACTIONS

const SET = 'user/SET'
const DELETE_PENDING = 'user/delete/PENDING'
const DELETE_SUCCESS = 'user/delete/SUCCESS'
const DELETE_FAILURE = 'user/delete/FAILURE'
const CLEAR = 'user/CLEAR'

type Actions =
  | SetAction
  | DeletePendingAction
  | DeleteSuccessAction
  | DeleteFailureAction
  | ClearAction

type SetAction = {
  type: typeof SET
  payload: {
    shopifyInfo: EmbeddedShopifyInfo | undefined
    user: BootstrapDataUserInfo
  }
}

type DeletePendingAction = {
  type: typeof DELETE_PENDING
}
type DeleteSuccessAction = {
  type: typeof DELETE_SUCCESS
}
type DeleteFailureAction = {
  type: typeof DELETE_FAILURE
  payload: Error
}

type ClearAction = {
  type: typeof CLEAR
}

export function setUser(
  user: BootstrapDataUserInfo,
  shopifyInfo: EmbeddedShopifyInfo | undefined,
): SetAction {
  return {
    type: SET,
    payload: { shopifyInfo, user },
  }
}

function deletePending(): DeletePendingAction {
  return {
    type: DELETE_PENDING,
  }
}
function deleteSuccess(): DeleteSuccessAction {
  return {
    type: DELETE_SUCCESS,
  }
}
function deleteFailure(payload: Error): DeleteFailureAction {
  return {
    type: DELETE_FAILURE,
    payload,
  }
}
export function deleteUser(): Thunk {
  return async (dispatch): Promise<void> => {
    dispatch(deletePending())

    try {
      const response: DeleteUserResponse = await api.delete('/api/users')
      if (!response.success) {
        throw new Error('Error deleting user')
      }
      dispatch(deleteSuccess())
    } catch (error: any) {
      dispatch(deleteFailure(error))
    }
  }
}

export function clearUser(): ClearAction {
  return {
    type: CLEAR,
  }
}

// REDUCERS

type User = {
  createdAt: Date | null
  isPendingDeletion: boolean
  login: string
  name: string
}
export type StoreUser = Readonly<{
  data: User
  delete: AsyncState
  shopifyInfo: EmbeddedShopifyInfo
}>

const initialState: StoreUser = {
  data: {
    createdAt: null,
    isPendingDeletion: false,
    login: '',
    name: '',
  },
  delete: {
    isLoading: false,
    error: null,
  },
  shopifyInfo: {} as any,
}

export function userReducer(
  state: StoreUser = initialState,
  action: Actions,
): StoreUser {
  switch (action.type) {
    case SET:
      const createdAt = action.payload.user.createdAt
        ? new Date(action.payload.user.createdAt)
        : null
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.user,
          createdAt,
        },
        shopifyInfo: {
          ...state.shopifyInfo,
          ...action.payload.shopifyInfo,
        },
      }

    case DELETE_PENDING:
      return {
        ...state,
        delete: {
          isLoading: true,
          error: null,
        },
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          isPendingDeletion: true,
        },
        delete: {
          isLoading: false,
          error: null,
        },
      }
    case DELETE_FAILURE:
      return {
        ...state,
        delete: {
          isLoading: false,
          error: action.payload,
        },
      }

    case CLEAR:
      return initialState
    default:
      return state
  }
}

export type LoggedInUserPayload = { user: DBUser | null } & LoadingAndError

export type NewUserPayload = LoggedInUserPayload & {
  duplicateEmail: string | null
} & LoadingAndError

const NEW_USER_KEY = 'newUser'
const LOGGED_IN_USER_KEY = 'loggedInUser'
const FORGOT_PASSWORD_KEY = 'forgotPassword'
const RESET_PASSWORD_KEY = 'resetPassword'

// REDUCERS
export type LoggedInUserStoreData = {
  [LOGGED_IN_USER_KEY]: LoggedInUserPayload
}

export type NewUserStoreData = {
  [NEW_USER_KEY]: NewUserPayload
}

export type LoggedInUserData = Readonly<LoggedInUserStoreData>

export type NewUserData = Readonly<NewUserStoreData>

const initialLoggedInUserState: LoggedInUserData = {
  [LOGGED_IN_USER_KEY]: {
    user: null,
    isLoading: false,
    error: null,
  },
}

const initialNewUserState: NewUserData = {
  [NEW_USER_KEY]: {
    user: null,
    isLoading: false,
    error: null,
    duplicateEmail: null,
  },
}

const initialForgotPasswordState = {
  [FORGOT_PASSWORD_KEY]: {
    isLoading: false,
    error: null,
    emailSent: false,
    emailAddress: '',
  },
}

const initialResetPasswordState = {
  [RESET_PASSWORD_KEY]: {
    isLoading: false,
    error: null,
    userId: '',
    updated: false,
  },
}

export const forgotPasswordReducer = createReducer(
  initialForgotPasswordState,
  builder => {
    createThunkReducer(
      builder,
      forgotPasswordLink.typePrefix,
      FORGOT_PASSWORD_KEY,
      (state, action) => ({
        ...state,
        [FORGOT_PASSWORD_KEY]: {
          ...state[FORGOT_PASSWORD_KEY],
          error: null,
          emailSent: action.payload.sent,
          emailAddress: action.payload.email,
        },
      }),
    )
  },
)

export const resetPasswordReducer = createReducer(
  initialResetPasswordState,
  builder => {
    // Token validation
    createThunkReducer(
      builder,
      resetPasswordLinkValidation.typePrefix,
      RESET_PASSWORD_KEY,
      (state, action) => ({
        ...state,
        [RESET_PASSWORD_KEY]: {
          ...state[RESET_PASSWORD_KEY],
          error: null,
          userId: action.payload.userId,
        },
      }),
    )

    // Password Update
    createThunkReducer(
      builder,
      updatePassword.typePrefix,
      RESET_PASSWORD_KEY,
      state => ({
        ...state,
        [RESET_PASSWORD_KEY]: {
          ...state[RESET_PASSWORD_KEY],
          error: null,
          userId: '',
          updated: true,
        },
      }),
    )
  },
)

export const newUserReducer = createReducer(initialNewUserState, builder => {
  // GET SUBSCRIPTION_PLANS
  createThunkReducer(
    builder,
    addUser.typePrefix,
    NEW_USER_KEY,
    (state, action) =>
      ({
        ...state,
        [NEW_USER_KEY]: {
          ...state[NEW_USER_KEY],
          isLoading: null,
          error: action.payload.error,
          user: action.payload.user,
          duplicateEmail: action.payload.duplicateEmail,
        },
      } as NewUserStoreData),
  )
})

export const loggedInUserReducer = createReducer(
  initialLoggedInUserState,
  builder => {
    // GET SUBSCRIPTION_PLANS
    createThunkReducer(
      builder,
      loginUser.typePrefix,
      LOGGED_IN_USER_KEY,
      (state, action) =>
        ({
          ...state,
          [LOGGED_IN_USER_KEY]: {
            ...state[LOGGED_IN_USER_KEY],
            isLoading: null,
            error: action.payload.error,
            user: action.payload.user,
          },
        } as LoggedInUserStoreData),
    )
  },
)
