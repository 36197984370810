import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import TuneIcon from '@mui/icons-material/Tune'
import React, { useCallback, useState } from 'react'
import { formatTimestamp } from './DashboardGridPanelDetail'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { AdminDashboardCompany } from '../../../server/services/adminDashboard'
import { usePlanType } from '../../hooks/usePlanType'
import { dispatch } from '../../store'
import { triggerParameterOptimization } from '../../modules/adminDashboard/revenue/revenueActions'
import { BasicModal } from '../../components/designSystem/organisms/BasicModal/BasicModal'

export const AdminCompanyDetail = (props: {
  company: AdminDashboardCompany
  onClick: () => void
}): JSX.Element => {
  const { company } = props
  const { planTypes, selectedPlanType, updatePlanType } = usePlanType(
    company.planType || '',
  )

  const [optimizeClicked, setOptimizedClicked] = useState<boolean>(false)

  const onRevenueOptimizeClick = useCallback((): void => {
    setOptimizedClicked(true)
  }, [])

  const handleOptimizeClosed = useCallback(() => {
    setOptimizedClicked(false)
  }, [])

  const handleTriggerOptimization = useCallback(async () => {
    dispatch(triggerParameterOptimization({ companyId: company.id }))

    setOptimizedClicked(false)
  }, [company.id])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <Card>
          <CardHeader title={company.name} subheader={company.id} />
          <CardContent>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" variant={'head'} scope="row">
                      Company URL
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {company.companyUrl ?? 'unknown'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" variant={'head'} scope="row">
                      Shop Name
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {company.shopName ?? 'unknown'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" variant={'head'} scope="row">
                      Create on
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {formatTimestamp(new Date(company.createdAt))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TextField
                        id="planTypeSelect"
                        select
                        label="Company Plan"
                        defaultValue=""
                        value={selectedPlanType}
                        sx={{ width: '100%' }}
                        onChange={(event): void => {
                          const value = event.target.value
                          updatePlanType(company.id, value)
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {planTypes.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              color={'error'}
              startIcon={<DeleteForeverIcon />}
              onClick={props.onClick}
              sx={{ ml: 1 }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              onClick={() =>
                setTimeout(resolve => {
                  window.open(
                    `/customer-service/productDemand/productSegments/${company.id}`,
                    '_blank',
                  )
                  resolve()
                }, 2000)
              }
              sx={{ ml: 1 }}
            >
              Product Dashboard
            </Button>
            <Button
              variant="outlined"
              startIcon={<TuneIcon />}
              onClick={onRevenueOptimizeClick}
              sx={{ ml: 1 }}
            >
              Optimize Split Revenue Forecast
            </Button>
          </CardActions>
        </Card>
        <BasicModal
          height={200}
          width={400}
          isOpen={optimizeClicked}
          handleClose={handleOptimizeClosed}
          onDismiss={handleOptimizeClosed}
          title={`Trigger Parameter Optimization for ${company.name}`}
          confirmButtonText={'Run optimization'}
          cancelButtonText={'Cancel'}
          onConfirmButtonClicked={handleTriggerOptimization}
          onCancelButtonClicked={handleOptimizeClosed}
          buttonJustifyContent={'flex-end'}
          renderContent={(): JSX.Element => (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              This process can take up to 3 hours and changes should be
              reflected in the UI following completion.
            </Typography>
          )}
          confirmButtonColor="warning"
        />
      </Grid>
    </Grid>
  )
}
