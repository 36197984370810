import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type RequireFields<T, K extends keyof T> = Omit<T, K> &
  { [P in K]-?: NonNullable<T[P]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** custom scalar that represents a date */
  Date: { input: any; output: any }
  /**
   * Any key value pair.
   * Note: This is handy for debugging/development but should be avoided in production
   */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any } }
  /** custom scalar that represents a date in UTC */
  UTCDate: { input: any; output: any }
}

/** Aggregated company-level banking data over a period of time */
export type BankingDataByDuration = {
  __typename?: 'BankingDataByDuration'
  balanceCents?: Maybe<TimeSeriesMetric>
  cashInflowCents?: Maybe<TimeSeriesMetric>
  cashOutflowCents?: Maybe<TimeSeriesMetric>
}

/** Aggregated company-level customer acquisition cost (CAC) data over a period of time */
export type CacByDuration = {
  __typename?: 'CacByDuration'
  totalCac?: Maybe<TimeSeriesData>
}

export type CashPosition = {
  __typename?: 'CashPosition'
  aggregates: Array<CashPositionAggregate>
  netInflowCents: Scalars['Float']['output']
  netOutflowCents: Scalars['Float']['output']
  syncTimestamp?: Maybe<Scalars['UTCDate']['output']>
}

export type CashPositionAggregate = {
  __typename?: 'CashPositionAggregate'
  cashDifferenceCents: Scalars['Float']['output']
  categories: Array<CashPositionCategory>
  endDate: Scalars['UTCDate']['output']
  endingBalanceCents: Scalars['Float']['output']
  startDate: Scalars['UTCDate']['output']
  startingBalanceCents: Scalars['Float']['output']
}

export type CashPositionCategory = {
  __typename?: 'CashPositionCategory'
  categoryId: Scalars['String']['output']
  categoryLabel: Scalars['String']['output']
  merchants?: Maybe<Array<CashPositionMerchant>>
  subCategories?: Maybe<Array<CashPositionCategory>>
  totalCents: Scalars['Float']['output']
}

export type CashPositionMerchant = {
  __typename?: 'CashPositionMerchant'
  merchantId: Scalars['String']['output']
  merchantName: Scalars['String']['output']
  totalCents?: Maybe<Scalars['Float']['output']>
}

export type CashPositionSeries = {
  __typename?: 'CashPositionSeries'
  balanceCents?: Maybe<TimeSeriesMetric>
  cashInflowCents?: Maybe<TimeSeriesMetric>
  cashOutflowCents?: Maybe<TimeSeriesMetric>
}

/** Represents a single cohort with its date, size, and average spend data. */
export type Cohort = {
  __typename?: 'Cohort'
  /** An array of average spend values for the cohort over a period of time. */
  averageSpend?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  /** The date associated with first order in the cohort */
  date?: Maybe<Scalars['String']['output']>
  /** Total number of new customers in cohort */
  size?: Maybe<Scalars['Int']['output']>
}

export type CompanyMerchant = {
  __typename?: 'CompanyMerchant'
  companyId?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['Date']['output']>
  id?: Maybe<Scalars['String']['output']>
  merchantName?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
  sourceId?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['Date']['output']>
}

/** Aggregated company-level customers data over a period of time */
export type CustomersByDuration = {
  __typename?: 'CustomersByDuration'
  newCustomers?: Maybe<TimeSeriesMetric>
}

/** Distinct units for structuring timeSeries data */
export type DateGranularity =
  /** represents data in full day increments */
  | 'Day'
  /** represents data as entire month. Note: partial or rolling months are not supported */
  | 'FullMonth'
  /** represents data in Monday - Sunday chunks. Note: partial or rolling weeks are not supported */
  | 'FullWeek'
  /** Represents data in month increments. note: the final aggrgate may represent a partial month */
  | 'Month'
  /** Represents data in month increments. note: the final aggrgate may represent a partial week */
  | 'Week'

export type EnrichedTransaction = {
  __typename?: 'EnrichedTransaction'
  accountId: Scalars['String']['output']
  accountMask?: Maybe<Scalars['String']['output']>
  accountName: Scalars['String']['output']
  accountOfficialName?: Maybe<Scalars['String']['output']>
  accountSubtype?: Maybe<Scalars['String']['output']>
  accountType?: Maybe<Scalars['String']['output']>
  amountCents: Scalars['Int']['output']
  cacheDate: Scalars['UTCDate']['output']
  categoryId?: Maybe<Scalars['String']['output']>
  categoryLabel?: Maybe<Scalars['String']['output']>
  companyId: Scalars['String']['output']
  confidence?: Maybe<Scalars['Float']['output']>
  currency: Scalars['String']['output']
  description: Scalars['String']['output']
  descriptionClean: Scalars['String']['output']
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  institutionId: Scalars['String']['output']
  institutionName: Scalars['String']['output']
  merchantId?: Maybe<Scalars['String']['output']>
  merchantName?: Maybe<Scalars['String']['output']>
  platformId: Scalars['String']['output']
  status: Scalars['String']['output']
  transactionDate: Scalars['UTCDate']['output']
  transactionTimestamp: Scalars['UTCDate']['output']
}

export type ForecastEntry = {
  __typename?: 'ForecastEntry'
  /** Timestamp or date marking the end of this time interval. */
  end: Scalars['UTCDate']['output']
  linearTrend?: Maybe<Scalars['Float']['output']>
  lowerConfidenceBound?: Maybe<Scalars['Float']['output']>
  /** Timestamp or date marking the beginning of this time interval. */
  start: Scalars['UTCDate']['output']
  upperConfidenceBound?: Maybe<Scalars['Float']['output']>
  /** Forecasted or Actual Value */
  value?: Maybe<Scalars['Float']['output']>
}

export type InsightResult = {
  __typename?: 'InsightResult'
  content?: Maybe<Scalars['String']['output']>
}

/**
 * The main type representing the Lifetime Value (LTV) report,
 * including cohort data and detailed monthly projections.
 */
export type LtvReport = {
  __typename?: 'LTVReport'
  /** An array of cohorts with their respective date, size, and average spend. */
  cohorts?: Maybe<Array<Maybe<Cohort>>>
  /** The maximum projected spend */
  maxProjectedSpend?: Maybe<Scalars['Float']['output']>
  /** The maximum spend recorded across all cohorts. */
  maxSpend?: Maybe<Scalars['Float']['output']>
  /** The minimum projected spend */
  minProjectedSpend?: Maybe<Scalars['Float']['output']>
  /** The minimum spend recorded across all cohorts. */
  minSpend?: Maybe<Scalars['Float']['output']>
  /** Detailed projected financial metrics for each month. */
  projected?: Maybe<Projected>
}

/** Aggregated company-level marketing data over a period of time */
export type MarketingByDuration = {
  __typename?: 'MarketingByDuration'
  marketingExpenseCents?: Maybe<TimeSeriesMetric>
}

export type Mutation = {
  __typename?: 'Mutation'
  createOrFindCompanyMerchant?: Maybe<CompanyMerchant>
  updateEnrichedTransactionLabels: Array<Maybe<EnrichedTransaction>>
}

export type MutationCreateOrFindCompanyMerchantArgs = {
  merchantName: Scalars['String']['input']
}

export type MutationUpdateEnrichedTransactionLabelsArgs = {
  transactions: Array<UpdatedLabelInput>
}

/** Contains projected financial details for each month within a year. */
export type Projected = {
  __typename?: 'Projected'
  eightMonth?: Maybe<ProjectedMonthDetail>
  elevenMonth?: Maybe<ProjectedMonthDetail>
  fiveMonth?: Maybe<ProjectedMonthDetail>
  fourMonth?: Maybe<ProjectedMonthDetail>
  nineMonth?: Maybe<ProjectedMonthDetail>
  oneMonth?: Maybe<ProjectedMonthDetail>
  sevenMonth?: Maybe<ProjectedMonthDetail>
  sixMonth?: Maybe<ProjectedMonthDetail>
  tenMonth?: Maybe<ProjectedMonthDetail>
  threeMonth?: Maybe<ProjectedMonthDetail>
  tweleveMonth?: Maybe<ProjectedMonthDetail>
  twoMonth?: Maybe<ProjectedMonthDetail>
}

/** Detailed projection data for a specific month. */
export type ProjectedMonthDetail = {
  __typename?: 'ProjectedMonthDetail'
  /** Customer Acquisition Cost (CAC) for the month. Nullable if not applicable. */
  cac?: Maybe<Scalars['String']['output']>
  /** Customer margin calculated from gross margin and CAC. Nullable if CAC is not provided. */
  customerMargin?: Maybe<Scalars['String']['output']>
  /** The date for the projection */
  date?: Maybe<Scalars['String']['output']>
  /** Calculated gross margin for the month. */
  grossMargin?: Maybe<Scalars['String']['output']>
  /** The ratio of Lifetime Value (LTV) to CAC. Nullable if CAC is not provided. */
  ltvToCacRatio?: Maybe<Scalars['String']['output']>
  /** The projected spend for the month. (average of previous cohorts) */
  projectedSpend?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  /**
   * Aggregates company-level banking data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  BankingDataByDuration?: Maybe<BankingDataByDuration>
  /**
   * Aggregates company-level customer acquisition cost (CAC) data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  CacByDuration?: Maybe<CacByDuration>
  /**
   * Aggregates company-level customers data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  CustomersByDuration?: Maybe<CustomersByDuration>
  InsightResult?: Maybe<InsightResult>
  LTVReport?: Maybe<LtvReport>
  /**
   * Aggregates company-level marketing data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  MarketingByDuration?: Maybe<MarketingByDuration>
  /**
   * Aggregates company-level return on ad spend data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  RoasByDuration?: Maybe<RoasByDuration>
  /**
   * Aggregates company-level sales data starting from the current date
   * and spanning the duration specified by the input arguments.
   * Note: companyId is derived from request context state
   */
  SalesByDuration?: Maybe<SalesByDuration>
  enrichedTransactionsByDateRange: Array<EnrichedTransaction>
  getCashPositionByDuration?: Maybe<CashPosition>
  getCashPositionSeriesByDuration?: Maybe<CashPositionSeries>
  salesForecastByDuration?: Maybe<SalesForecastByDuration>
  salesForecastStores: Array<SalesForecastStore>
  transactionAccounts: Array<TransactionAccount>
  transactionCategories: Array<TransactionCategory>
  transactionMerchants: Array<TransactionMerchant>
}

export type QueryBankingDataByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryCacByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryCustomersByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryInsightResultArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>
  granularity?: InputMaybe<DateGranularity>
}

export type QueryMarketingByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryRoasByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QuerySalesByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryEnrichedTransactionsByDateRangeArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']['input']>>
  endDate?: InputMaybe<Scalars['UTCDate']['input']>
  startDate?: InputMaybe<Scalars['UTCDate']['input']>
}

export type QueryGetCashPositionByDurationArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']['input']>>
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QueryGetCashPositionSeriesByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}

export type QuerySalesForecastByDurationArgs = {
  duration: Scalars['Int']['input']
  granularity: DateGranularity
  storeIds?: InputMaybe<Array<Scalars['String']['input']>>
}

/** Aggregated company-level return on ad spend data over a period of time */
export type RoasByDuration = {
  __typename?: 'RoasByDuration'
  roas?: Maybe<TimeSeriesData>
}

/** Aggregated company-level sales data over a period of time */
export type SalesByDuration = {
  __typename?: 'SalesByDuration'
  averageOrderValue?: Maybe<TimeSeriesMetric>
  orderVolume?: Maybe<TimeSeriesMetric>
  revenue?: Maybe<TimeSeriesMetric>
  unitVolume?: Maybe<TimeSeriesMetric>
}

export type SalesForecastByDuration = {
  __typename?: 'SalesForecastByDuration'
  combined: SalesForecastCombinedAggregate
  stores: Array<SalesForecastStoreAggregate>
  totalActualValue: Scalars['Float']['output']
  totalProjectedValue: Scalars['Float']['output']
}

export type SalesForecastCombinedAggregate = {
  __typename?: 'SalesForecastCombinedAggregate'
  actuals: Array<ForecastEntry>
  forecasted: Array<ForecastEntry>
}

export type SalesForecastStoreAggregate = {
  __typename?: 'SalesForecastStoreAggregate'
  actuals: Array<ForecastEntry>
  forecasted: Array<ForecastEntry>
  storeId?: Maybe<Scalars['String']['output']>
  storeName?: Maybe<Scalars['String']['output']>
}

/** A collection of time-series entries categorized under a specific channel name. */
export type TimeSeriesChannel = {
  __typename?: 'TimeSeriesChannel'
  /** The identifying name of this channel, corresponding to 'AccountCategories' enum */
  name: Scalars['String']['output']
  /** A sequence of time-series entries, capturing data across various intervals for this channel. */
  timeSeries?: Maybe<Array<Maybe<TimeSeriesEntry>>>
  /** The cumulative or combined value from all the time-series entries in this channel. */
  total?: Maybe<Scalars['Float']['output']>
  /** Analytical trends derived from the time-series data of this channel. */
  trends?: Maybe<Trends>
}

/** Aggregated time-series data */
export type TimeSeriesData = {
  __typename?: 'TimeSeriesData'
  /** A sequence of time-series entries capturing data across fixed intervals. */
  timeSeries?: Maybe<Array<Maybe<TimeSeriesEntry>>>
  /** The cumulative or combined value from all the time-series entries provided. */
  total?: Maybe<Scalars['Float']['output']>
  /** Analytical trends derived from the provided time-series data. */
  trends?: Maybe<Trends>
}

/** A single entry in a time-series, capturing data for a specific interval. */
export type TimeSeriesEntry = {
  __typename?: 'TimeSeriesEntry'
  /** Timestamp or date marking the end of this time interval. */
  end: Scalars['String']['output']
  /** Timestamp or date marking the beginning of this time interval. */
  start: Scalars['String']['output']
  /** The numerical or quantitative value recorded for this time interval. */
  value?: Maybe<Scalars['Float']['output']>
}

/**
 * A metric comprised of various channels and their combined total,
 * each channel containing its own time-series data.
 */
export type TimeSeriesMetric = {
  __typename?: 'TimeSeriesMetric'
  /** An array of channel Specific timeSeries data */
  channels?: Maybe<Array<Maybe<TimeSeriesChannel>>>
  /** Summarized or aggregated time-series data across all channels */
  totals?: Maybe<TimeSeriesData>
}

export type TransactionAccount = {
  __typename?: 'TransactionAccount'
  accountId: Scalars['String']['output']
  accountMask?: Maybe<Scalars['String']['output']>
  accountName: Scalars['String']['output']
  accountOfficialName?: Maybe<Scalars['String']['output']>
  accountSubtype?: Maybe<Scalars['String']['output']>
  accountType?: Maybe<Scalars['String']['output']>
}

export type TransactionCategory = {
  __typename?: 'TransactionCategory'
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  parentId?: Maybe<Scalars['String']['output']>
  rank: Scalars['Int']['output']
}

export type TransactionMerchant = {
  __typename?: 'TransactionMerchant'
  merchantId?: Maybe<Scalars['String']['output']>
  merchantName?: Maybe<Scalars['String']['output']>
}

/**
 * Represents trends in time-series data
 * by comparing the most recent data point against other reference points.
 */
export type Trends = {
  __typename?: 'Trends'
  /**
   * Compares the most recent data point against the average of all other data points in the series.
   * as a percentage.
   */
  currentVsIntervalAverage?: Maybe<Scalars['Float']['output']>
  /**
   * Represents the change between the most recent data point and the one directly before it,
   * as a percentage.
   */
  currentVsPrevious?: Maybe<Scalars['Float']['output']>
}

export type UpdatedLabelInput = {
  accountId: Scalars['String']['input']
  categoryId?: InputMaybe<Scalars['String']['input']>
  categoryLabel?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  merchantId?: InputMaybe<Scalars['String']['input']>
  merchantName?: InputMaybe<Scalars['String']['input']>
  platformId: Scalars['String']['input']
  transactionDate: Scalars['UTCDate']['input']
}

export type SalesForecastStore = {
  __typename?: 'salesForecastStore'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  BankingDataByDuration: ResolverTypeWrapper<BankingDataByDuration>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>
  CacByDuration: ResolverTypeWrapper<CacByDuration>
  CashPosition: ResolverTypeWrapper<CashPosition>
  CashPositionAggregate: ResolverTypeWrapper<CashPositionAggregate>
  CashPositionCategory: ResolverTypeWrapper<CashPositionCategory>
  CashPositionMerchant: ResolverTypeWrapper<CashPositionMerchant>
  CashPositionSeries: ResolverTypeWrapper<CashPositionSeries>
  Cohort: ResolverTypeWrapper<Cohort>
  CompanyMerchant: ResolverTypeWrapper<CompanyMerchant>
  CustomersByDuration: ResolverTypeWrapper<CustomersByDuration>
  Date: ResolverTypeWrapper<Scalars['Date']['output']>
  DateGranularity: DateGranularity
  EnrichedTransaction: ResolverTypeWrapper<EnrichedTransaction>
  Float: ResolverTypeWrapper<Scalars['Float']['output']>
  ForecastEntry: ResolverTypeWrapper<ForecastEntry>
  InsightResult: ResolverTypeWrapper<InsightResult>
  Int: ResolverTypeWrapper<Scalars['Int']['output']>
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>
  LTVReport: ResolverTypeWrapper<LtvReport>
  MarketingByDuration: ResolverTypeWrapper<MarketingByDuration>
  Mutation: ResolverTypeWrapper<{}>
  Projected: ResolverTypeWrapper<Projected>
  ProjectedMonthDetail: ResolverTypeWrapper<ProjectedMonthDetail>
  Query: ResolverTypeWrapper<{}>
  RoasByDuration: ResolverTypeWrapper<RoasByDuration>
  SalesByDuration: ResolverTypeWrapper<SalesByDuration>
  SalesForecastByDuration: ResolverTypeWrapper<SalesForecastByDuration>
  SalesForecastCombinedAggregate: ResolverTypeWrapper<SalesForecastCombinedAggregate>
  SalesForecastStoreAggregate: ResolverTypeWrapper<SalesForecastStoreAggregate>
  String: ResolverTypeWrapper<Scalars['String']['output']>
  TimeSeriesChannel: ResolverTypeWrapper<TimeSeriesChannel>
  TimeSeriesData: ResolverTypeWrapper<TimeSeriesData>
  TimeSeriesEntry: ResolverTypeWrapper<TimeSeriesEntry>
  TimeSeriesMetric: ResolverTypeWrapper<TimeSeriesMetric>
  TransactionAccount: ResolverTypeWrapper<TransactionAccount>
  TransactionCategory: ResolverTypeWrapper<TransactionCategory>
  TransactionMerchant: ResolverTypeWrapper<TransactionMerchant>
  Trends: ResolverTypeWrapper<Trends>
  UTCDate: ResolverTypeWrapper<Scalars['UTCDate']['output']>
  UpdatedLabelInput: UpdatedLabelInput
  salesForecastStore: ResolverTypeWrapper<SalesForecastStore>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  BankingDataByDuration: BankingDataByDuration
  Boolean: Scalars['Boolean']['output']
  CacByDuration: CacByDuration
  CashPosition: CashPosition
  CashPositionAggregate: CashPositionAggregate
  CashPositionCategory: CashPositionCategory
  CashPositionMerchant: CashPositionMerchant
  CashPositionSeries: CashPositionSeries
  Cohort: Cohort
  CompanyMerchant: CompanyMerchant
  CustomersByDuration: CustomersByDuration
  Date: Scalars['Date']['output']
  EnrichedTransaction: EnrichedTransaction
  Float: Scalars['Float']['output']
  ForecastEntry: ForecastEntry
  InsightResult: InsightResult
  Int: Scalars['Int']['output']
  JSON: Scalars['JSON']['output']
  LTVReport: LtvReport
  MarketingByDuration: MarketingByDuration
  Mutation: {}
  Projected: Projected
  ProjectedMonthDetail: ProjectedMonthDetail
  Query: {}
  RoasByDuration: RoasByDuration
  SalesByDuration: SalesByDuration
  SalesForecastByDuration: SalesForecastByDuration
  SalesForecastCombinedAggregate: SalesForecastCombinedAggregate
  SalesForecastStoreAggregate: SalesForecastStoreAggregate
  String: Scalars['String']['output']
  TimeSeriesChannel: TimeSeriesChannel
  TimeSeriesData: TimeSeriesData
  TimeSeriesEntry: TimeSeriesEntry
  TimeSeriesMetric: TimeSeriesMetric
  TransactionAccount: TransactionAccount
  TransactionCategory: TransactionCategory
  TransactionMerchant: TransactionMerchant
  Trends: Trends
  UTCDate: Scalars['UTCDate']['output']
  UpdatedLabelInput: UpdatedLabelInput
  salesForecastStore: SalesForecastStore
}

export type BankingDataByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BankingDataByDuration'] = ResolversParentTypes['BankingDataByDuration']
> = {
  balanceCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  cashInflowCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  cashOutflowCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CacByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CacByDuration'] = ResolversParentTypes['CacByDuration']
> = {
  totalCac?: Resolver<
    Maybe<ResolversTypes['TimeSeriesData']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashPositionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashPosition'] = ResolversParentTypes['CashPosition']
> = {
  aggregates?: Resolver<
    Array<ResolversTypes['CashPositionAggregate']>,
    ParentType,
    ContextType
  >
  netInflowCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  netOutflowCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  syncTimestamp?: Resolver<
    Maybe<ResolversTypes['UTCDate']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashPositionAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashPositionAggregate'] = ResolversParentTypes['CashPositionAggregate']
> = {
  cashDifferenceCents?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  categories?: Resolver<
    Array<ResolversTypes['CashPositionCategory']>,
    ParentType,
    ContextType
  >
  endDate?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  endingBalanceCents?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  startDate?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  startingBalanceCents?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashPositionCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashPositionCategory'] = ResolversParentTypes['CashPositionCategory']
> = {
  categoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  categoryLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  merchants?: Resolver<
    Maybe<Array<ResolversTypes['CashPositionMerchant']>>,
    ParentType,
    ContextType
  >
  subCategories?: Resolver<
    Maybe<Array<ResolversTypes['CashPositionCategory']>>,
    ParentType,
    ContextType
  >
  totalCents?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashPositionMerchantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashPositionMerchant'] = ResolversParentTypes['CashPositionMerchant']
> = {
  merchantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  merchantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalCents?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CashPositionSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CashPositionSeries'] = ResolversParentTypes['CashPositionSeries']
> = {
  balanceCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  cashInflowCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  cashOutflowCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Cohort'] = ResolversParentTypes['Cohort']
> = {
  averageSpend?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyMerchantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyMerchant'] = ResolversParentTypes['CompanyMerchant']
> = {
  companyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  merchantName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomersByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomersByDuration'] = ResolversParentTypes['CustomersByDuration']
> = {
  newCustomers?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type EnrichedTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrichedTransaction'] = ResolversParentTypes['EnrichedTransaction']
> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountMask?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountOfficialName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountSubtype?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  amountCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  cacheDate?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  categoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  categoryLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  companyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  descriptionClean?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  externalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  institutionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  institutionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  merchantId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  merchantName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  platformId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionDate?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  transactionTimestamp?: Resolver<
    ResolversTypes['UTCDate'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ForecastEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ForecastEntry'] = ResolversParentTypes['ForecastEntry']
> = {
  end?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  linearTrend?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  lowerConfidenceBound?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  start?: Resolver<ResolversTypes['UTCDate'], ParentType, ContextType>
  upperConfidenceBound?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InsightResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InsightResult'] = ResolversParentTypes['InsightResult']
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type LtvReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LTVReport'] = ResolversParentTypes['LTVReport']
> = {
  cohorts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Cohort']>>>,
    ParentType,
    ContextType
  >
  maxProjectedSpend?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  maxSpend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  minProjectedSpend?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  minSpend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  projected?: Resolver<
    Maybe<ResolversTypes['Projected']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingByDuration'] = ResolversParentTypes['MarketingByDuration']
> = {
  marketingExpenseCents?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  createOrFindCompanyMerchant?: Resolver<
    Maybe<ResolversTypes['CompanyMerchant']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrFindCompanyMerchantArgs, 'merchantName'>
  >
  updateEnrichedTransactionLabels?: Resolver<
    Array<Maybe<ResolversTypes['EnrichedTransaction']>>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEnrichedTransactionLabelsArgs, 'transactions'>
  >
}

export type ProjectedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Projected'] = ResolversParentTypes['Projected']
> = {
  eightMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  elevenMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  fiveMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  fourMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  nineMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  oneMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  sevenMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  sixMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  tenMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  threeMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  tweleveMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  twoMonth?: Resolver<
    Maybe<ResolversTypes['ProjectedMonthDetail']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProjectedMonthDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProjectedMonthDetail'] = ResolversParentTypes['ProjectedMonthDetail']
> = {
  cac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  customerMargin?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  grossMargin?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  ltvToCacRatio?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  projectedSpend?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  BankingDataByDuration?: Resolver<
    Maybe<ResolversTypes['BankingDataByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QueryBankingDataByDurationArgs, 'duration' | 'granularity'>
  >
  CacByDuration?: Resolver<
    Maybe<ResolversTypes['CacByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QueryCacByDurationArgs, 'duration' | 'granularity'>
  >
  CustomersByDuration?: Resolver<
    Maybe<ResolversTypes['CustomersByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QueryCustomersByDurationArgs, 'duration' | 'granularity'>
  >
  InsightResult?: Resolver<
    Maybe<ResolversTypes['InsightResult']>,
    ParentType,
    ContextType,
    Partial<QueryInsightResultArgs>
  >
  LTVReport?: Resolver<
    Maybe<ResolversTypes['LTVReport']>,
    ParentType,
    ContextType
  >
  MarketingByDuration?: Resolver<
    Maybe<ResolversTypes['MarketingByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QueryMarketingByDurationArgs, 'duration' | 'granularity'>
  >
  RoasByDuration?: Resolver<
    Maybe<ResolversTypes['RoasByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QueryRoasByDurationArgs, 'duration' | 'granularity'>
  >
  SalesByDuration?: Resolver<
    Maybe<ResolversTypes['SalesByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QuerySalesByDurationArgs, 'duration' | 'granularity'>
  >
  enrichedTransactionsByDateRange?: Resolver<
    Array<ResolversTypes['EnrichedTransaction']>,
    ParentType,
    ContextType,
    Partial<QueryEnrichedTransactionsByDateRangeArgs>
  >
  getCashPositionByDuration?: Resolver<
    Maybe<ResolversTypes['CashPosition']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryGetCashPositionByDurationArgs,
      'duration' | 'granularity'
    >
  >
  getCashPositionSeriesByDuration?: Resolver<
    Maybe<ResolversTypes['CashPositionSeries']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryGetCashPositionSeriesByDurationArgs,
      'duration' | 'granularity'
    >
  >
  salesForecastByDuration?: Resolver<
    Maybe<ResolversTypes['SalesForecastByDuration']>,
    ParentType,
    ContextType,
    RequireFields<QuerySalesForecastByDurationArgs, 'duration' | 'granularity'>
  >
  salesForecastStores?: Resolver<
    Array<ResolversTypes['salesForecastStore']>,
    ParentType,
    ContextType
  >
  transactionAccounts?: Resolver<
    Array<ResolversTypes['TransactionAccount']>,
    ParentType,
    ContextType
  >
  transactionCategories?: Resolver<
    Array<ResolversTypes['TransactionCategory']>,
    ParentType,
    ContextType
  >
  transactionMerchants?: Resolver<
    Array<ResolversTypes['TransactionMerchant']>,
    ParentType,
    ContextType
  >
}

export type RoasByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoasByDuration'] = ResolversParentTypes['RoasByDuration']
> = {
  roas?: Resolver<
    Maybe<ResolversTypes['TimeSeriesData']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesByDuration'] = ResolversParentTypes['SalesByDuration']
> = {
  averageOrderValue?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  orderVolume?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  revenue?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  unitVolume?: Resolver<
    Maybe<ResolversTypes['TimeSeriesMetric']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesForecastByDurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesForecastByDuration'] = ResolversParentTypes['SalesForecastByDuration']
> = {
  combined?: Resolver<
    ResolversTypes['SalesForecastCombinedAggregate'],
    ParentType,
    ContextType
  >
  stores?: Resolver<
    Array<ResolversTypes['SalesForecastStoreAggregate']>,
    ParentType,
    ContextType
  >
  totalActualValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  totalProjectedValue?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesForecastCombinedAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesForecastCombinedAggregate'] = ResolversParentTypes['SalesForecastCombinedAggregate']
> = {
  actuals?: Resolver<
    Array<ResolversTypes['ForecastEntry']>,
    ParentType,
    ContextType
  >
  forecasted?: Resolver<
    Array<ResolversTypes['ForecastEntry']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalesForecastStoreAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesForecastStoreAggregate'] = ResolversParentTypes['SalesForecastStoreAggregate']
> = {
  actuals?: Resolver<
    Array<ResolversTypes['ForecastEntry']>,
    ParentType,
    ContextType
  >
  forecasted?: Resolver<
    Array<ResolversTypes['ForecastEntry']>,
    ParentType,
    ContextType
  >
  storeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeSeriesChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeSeriesChannel'] = ResolversParentTypes['TimeSeriesChannel']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timeSeries?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeSeriesEntry']>>>,
    ParentType,
    ContextType
  >
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  trends?: Resolver<Maybe<ResolversTypes['Trends']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeSeriesDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeSeriesData'] = ResolversParentTypes['TimeSeriesData']
> = {
  timeSeries?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeSeriesEntry']>>>,
    ParentType,
    ContextType
  >
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  trends?: Resolver<Maybe<ResolversTypes['Trends']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeSeriesEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeSeriesEntry'] = ResolversParentTypes['TimeSeriesEntry']
> = {
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeSeriesMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeSeriesMetric'] = ResolversParentTypes['TimeSeriesMetric']
> = {
  channels?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TimeSeriesChannel']>>>,
    ParentType,
    ContextType
  >
  totals?: Resolver<
    Maybe<ResolversTypes['TimeSeriesData']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionAccount'] = ResolversParentTypes['TransactionAccount']
> = {
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountMask?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountOfficialName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountSubtype?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  accountType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionCategory'] = ResolversParentTypes['TransactionCategory']
> = {
  externalId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionMerchantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionMerchant'] = ResolversParentTypes['TransactionMerchant']
> = {
  merchantId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  merchantName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrendsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Trends'] = ResolversParentTypes['Trends']
> = {
  currentVsIntervalAverage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  currentVsPrevious?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UtcDateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UTCDate'], any> {
  name: 'UTCDate'
}

export type SalesForecastStoreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['salesForecastStore'] = ResolversParentTypes['salesForecastStore']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  BankingDataByDuration?: BankingDataByDurationResolvers<ContextType>
  CacByDuration?: CacByDurationResolvers<ContextType>
  CashPosition?: CashPositionResolvers<ContextType>
  CashPositionAggregate?: CashPositionAggregateResolvers<ContextType>
  CashPositionCategory?: CashPositionCategoryResolvers<ContextType>
  CashPositionMerchant?: CashPositionMerchantResolvers<ContextType>
  CashPositionSeries?: CashPositionSeriesResolvers<ContextType>
  Cohort?: CohortResolvers<ContextType>
  CompanyMerchant?: CompanyMerchantResolvers<ContextType>
  CustomersByDuration?: CustomersByDurationResolvers<ContextType>
  Date?: GraphQLScalarType
  EnrichedTransaction?: EnrichedTransactionResolvers<ContextType>
  ForecastEntry?: ForecastEntryResolvers<ContextType>
  InsightResult?: InsightResultResolvers<ContextType>
  JSON?: GraphQLScalarType
  LTVReport?: LtvReportResolvers<ContextType>
  MarketingByDuration?: MarketingByDurationResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  Projected?: ProjectedResolvers<ContextType>
  ProjectedMonthDetail?: ProjectedMonthDetailResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  RoasByDuration?: RoasByDurationResolvers<ContextType>
  SalesByDuration?: SalesByDurationResolvers<ContextType>
  SalesForecastByDuration?: SalesForecastByDurationResolvers<ContextType>
  SalesForecastCombinedAggregate?: SalesForecastCombinedAggregateResolvers<ContextType>
  SalesForecastStoreAggregate?: SalesForecastStoreAggregateResolvers<ContextType>
  TimeSeriesChannel?: TimeSeriesChannelResolvers<ContextType>
  TimeSeriesData?: TimeSeriesDataResolvers<ContextType>
  TimeSeriesEntry?: TimeSeriesEntryResolvers<ContextType>
  TimeSeriesMetric?: TimeSeriesMetricResolvers<ContextType>
  TransactionAccount?: TransactionAccountResolvers<ContextType>
  TransactionCategory?: TransactionCategoryResolvers<ContextType>
  TransactionMerchant?: TransactionMerchantResolvers<ContextType>
  Trends?: TrendsResolvers<ContextType>
  UTCDate?: GraphQLScalarType
  salesForecastStore?: SalesForecastStoreResolvers<ContextType>
}

export type BalanceCentsQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type BalanceCentsQuery = {
  __typename?: 'Query'
  BankingDataByDuration?: {
    __typename?: 'BankingDataByDuration'
    balanceCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
    cashInflowCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
      } | null
    } | null
    cashOutflowCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type GetCashPositionByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
  accountIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetCashPositionByDurationQuery = {
  __typename?: 'Query'
  getCashPositionByDuration?: {
    __typename?: 'CashPosition'
    netInflowCents: number
    netOutflowCents: number
    syncTimestamp?: any | null
    aggregates: Array<{
      __typename?: 'CashPositionAggregate'
      cashDifferenceCents: number
      endDate: any
      endingBalanceCents: number
      startDate: any
      startingBalanceCents: number
      categories: Array<{
        __typename?: 'CashPositionCategory'
        categoryId: string
        categoryLabel: string
        totalCents: number
        subCategories?: Array<{
          __typename?: 'CashPositionCategory'
          categoryId: string
          categoryLabel: string
          totalCents: number
          merchants?: Array<{
            __typename?: 'CashPositionMerchant'
            merchantName: string
            merchantId: string
            totalCents?: number | null
          }> | null
          subCategories?: Array<{
            __typename?: 'CashPositionCategory'
            categoryId: string
            categoryLabel: string
            totalCents: number
            merchants?: Array<{
              __typename?: 'CashPositionMerchant'
              merchantName: string
              merchantId: string
              totalCents?: number | null
            }> | null
          }> | null
        }> | null
        merchants?: Array<{
          __typename?: 'CashPositionMerchant'
          merchantName: string
          merchantId: string
          totalCents?: number | null
        }> | null
      }>
    }>
  } | null
}

export type GetCashPositionSeriesByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type GetCashPositionSeriesByDurationQuery = {
  __typename?: 'Query'
  getCashPositionSeriesByDuration?: {
    __typename?: 'CashPositionSeries'
    balanceCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: { __typename?: 'TimeSeriesData'; total?: number | null } | null
    } | null
    cashInflowCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: { __typename?: 'TimeSeriesData'; total?: number | null } | null
    } | null
    cashOutflowCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: { __typename?: 'TimeSeriesData'; total?: number | null } | null
    } | null
  } | null
}

export type CreateOrFindCompnayMerchantMutationVariables = Exact<{
  merchantName: Scalars['String']['input']
}>

export type CreateOrFindCompnayMerchantMutation = {
  __typename?: 'Mutation'
  createOrFindCompanyMerchant?: {
    __typename?: 'CompanyMerchant'
    id?: string | null
    merchantName?: string | null
  } | null
}

export type GetCacByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type GetCacByDurationQuery = {
  __typename?: 'Query'
  CacByDuration?: {
    __typename?: 'CacByDuration'
    totalCac?: {
      __typename?: 'TimeSeriesData'
      total?: number | null
      timeSeries?: Array<{
        __typename?: 'TimeSeriesEntry'
        start: string
        end: string
        value?: number | null
      } | null> | null
      trends?: {
        __typename?: 'Trends'
        currentVsPrevious?: number | null
        currentVsIntervalAverage?: number | null
      } | null
    } | null
  } | null
}

export type GetCustomersByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type GetCustomersByDurationQuery = {
  __typename?: 'Query'
  CustomersByDuration?: {
    __typename?: 'CustomersByDuration'
    newCustomers?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
  } | null
}

export type GetTransactionAccountsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTransactionAccountsQuery = {
  __typename?: 'Query'
  transactionAccounts: Array<{
    __typename?: 'TransactionAccount'
    accountId: string
    accountMask?: string | null
    accountName: string
    accountOfficialName?: string | null
    accountSubtype?: string | null
    accountType?: string | null
  }>
}

export type GetTransactionCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTransactionCategoriesQuery = {
  __typename?: 'Query'
  transactionCategories: Array<{
    __typename?: 'TransactionCategory'
    id: string
    label: string
    parentId?: string | null
    externalId?: string | null
    rank: number
  }>
}

export type GetTransactionMerchantsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTransactionMerchantsQuery = {
  __typename?: 'Query'
  transactionMerchants: Array<{
    __typename?: 'TransactionMerchant'
    merchantId?: string | null
    merchantName?: string | null
  }>
}

export type GetTransactionsByDateRangeQueryVariables = Exact<{
  accountIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  endDate?: InputMaybe<Scalars['UTCDate']['input']>
  startDate?: InputMaybe<Scalars['UTCDate']['input']>
}>

export type GetTransactionsByDateRangeQuery = {
  __typename?: 'Query'
  enrichedTransactionsByDateRange: Array<{
    __typename?: 'EnrichedTransaction'
    id: string
    accountId: string
    accountMask?: string | null
    accountName: string
    accountOfficialName?: string | null
    accountSubtype?: string | null
    accountType?: string | null
    amountCents: number
    cacheDate: any
    categoryId?: string | null
    categoryLabel?: string | null
    companyId: string
    confidence?: number | null
    currency: string
    description: string
    descriptionClean: string
    externalId?: string | null
    institutionId: string
    institutionName: string
    merchantId?: string | null
    merchantName?: string | null
    platformId: string
    status: string
    transactionTimestamp: any
    transactionDate: any
  }>
}

export type UpdateTransactionLabelsMutationVariables = Exact<{
  transactions: Array<UpdatedLabelInput> | UpdatedLabelInput
}>

export type UpdateTransactionLabelsMutation = {
  __typename?: 'Mutation'
  updateEnrichedTransactionLabels: Array<{
    __typename?: 'EnrichedTransaction'
    id: string
    accountId: string
    accountMask?: string | null
    accountName: string
    accountOfficialName?: string | null
    accountSubtype?: string | null
    accountType?: string | null
    amountCents: number
    cacheDate: any
    categoryId?: string | null
    categoryLabel?: string | null
    companyId: string
    confidence?: number | null
    currency: string
    description: string
    descriptionClean: string
    externalId?: string | null
    institutionId: string
    institutionName: string
    merchantId?: string | null
    merchantName?: string | null
    platformId: string
    status: string
    transactionTimestamp: any
    transactionDate: any
  } | null>
}

export type GetInsightQueryVariables = Exact<{
  duration?: InputMaybe<Scalars['Int']['input']>
  granularity?: InputMaybe<DateGranularity>
}>

export type GetInsightQuery = {
  __typename?: 'Query'
  InsightResult?: {
    __typename?: 'InsightResult'
    content?: string | null
  } | null
}

export type GetOverviewLtvReportQueryVariables = Exact<{ [key: string]: never }>

export type GetOverviewLtvReportQuery = {
  __typename?: 'Query'
  LTVReport?: {
    __typename?: 'LTVReport'
    projected?: {
      __typename?: 'Projected'
      threeMonth?: {
        __typename?: 'ProjectedMonthDetail'
        cac?: string | null
        customerMargin?: string | null
        grossMargin?: string | null
        ltvToCacRatio?: string | null
      } | null
      sixMonth?: {
        __typename?: 'ProjectedMonthDetail'
        cac?: string | null
        customerMargin?: string | null
        grossMargin?: string | null
        ltvToCacRatio?: string | null
      } | null
      tweleveMonth?: {
        __typename?: 'ProjectedMonthDetail'
        cac?: string | null
        customerMargin?: string | null
        grossMargin?: string | null
        ltvToCacRatio?: string | null
      } | null
    } | null
  } | null
}

export type GetMarketingByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type GetMarketingByDurationQuery = {
  __typename?: 'Query'
  MarketingByDuration?: {
    __typename?: 'MarketingByDuration'
    marketingExpenseCents?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsIntervalAverage?: number | null
          currentVsPrevious?: number | null
        } | null
      } | null
    } | null
  } | null
}

export type RoasByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type RoasByDurationQuery = {
  __typename?: 'Query'
  RoasByDuration?: {
    __typename?: 'RoasByDuration'
    roas?: {
      __typename?: 'TimeSeriesData'
      timeSeries?: Array<{
        __typename?: 'TimeSeriesEntry'
        start: string
        end: string
        value?: number | null
      } | null> | null
      trends?: {
        __typename?: 'Trends'
        currentVsPrevious?: number | null
        currentVsIntervalAverage?: number | null
      } | null
    } | null
  } | null
}

export type GetSalesForecastByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
  storeIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetSalesForecastByDurationQuery = {
  __typename?: 'Query'
  salesForecastByDuration?: {
    __typename?: 'SalesForecastByDuration'
    totalActualValue: number
    totalProjectedValue: number
    stores: Array<{
      __typename?: 'SalesForecastStoreAggregate'
      storeId?: string | null
      storeName?: string | null
      actuals: Array<{
        __typename?: 'ForecastEntry'
        start: any
        end: any
        value?: number | null
        upperConfidenceBound?: number | null
        lowerConfidenceBound?: number | null
        linearTrend?: number | null
      }>
      forecasted: Array<{
        __typename?: 'ForecastEntry'
        start: any
        end: any
        value?: number | null
        upperConfidenceBound?: number | null
        lowerConfidenceBound?: number | null
        linearTrend?: number | null
      }>
    }>
    combined: {
      __typename?: 'SalesForecastCombinedAggregate'
      actuals: Array<{
        __typename?: 'ForecastEntry'
        start: any
        end: any
        value?: number | null
        upperConfidenceBound?: number | null
        lowerConfidenceBound?: number | null
        linearTrend?: number | null
      }>
      forecasted: Array<{
        __typename?: 'ForecastEntry'
        start: any
        end: any
        value?: number | null
        upperConfidenceBound?: number | null
        lowerConfidenceBound?: number | null
        linearTrend?: number | null
      }>
    }
  } | null
}

export type GetSalesForecastStoresQueryVariables = Exact<{
  [key: string]: never
}>

export type GetSalesForecastStoresQuery = {
  __typename?: 'Query'
  salesForecastStores: Array<{
    __typename?: 'salesForecastStore'
    id: string
    name: string
  }>
}

export type GetSalesByDurationQueryVariables = Exact<{
  duration: Scalars['Int']['input']
  granularity: DateGranularity
}>

export type GetSalesByDurationQuery = {
  __typename?: 'Query'
  SalesByDuration?: {
    __typename?: 'SalesByDuration'
    averageOrderValue?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
    orderVolume?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
    unitVolume?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
    revenue?: {
      __typename?: 'TimeSeriesMetric'
      channels?: Array<{
        __typename?: 'TimeSeriesChannel'
        name: string
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null> | null
      totals?: {
        __typename?: 'TimeSeriesData'
        total?: number | null
        timeSeries?: Array<{
          __typename?: 'TimeSeriesEntry'
          start: string
          end: string
          value?: number | null
        } | null> | null
        trends?: {
          __typename?: 'Trends'
          currentVsPrevious?: number | null
          currentVsIntervalAverage?: number | null
        } | null
      } | null
    } | null
  } | null
}

export const BalanceCentsDocument = gql`
  query BalanceCents($duration: Int!, $granularity: DateGranularity!) {
    BankingDataByDuration(duration: $duration, granularity: $granularity) {
      balanceCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
      cashInflowCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
        }
      }
      cashOutflowCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
        }
      }
    }
  }
`

/**
 * __useBalanceCentsQuery__
 *
 * To run a query within a React component, call `useBalanceCentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceCentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceCentsQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useBalanceCentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BalanceCentsQuery,
    BalanceCentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BalanceCentsQuery, BalanceCentsQueryVariables>(
    BalanceCentsDocument,
    options,
  )
}
export function useBalanceCentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BalanceCentsQuery,
    BalanceCentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BalanceCentsQuery, BalanceCentsQueryVariables>(
    BalanceCentsDocument,
    options,
  )
}
export function useBalanceCentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BalanceCentsQuery,
    BalanceCentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BalanceCentsQuery, BalanceCentsQueryVariables>(
    BalanceCentsDocument,
    options,
  )
}
export type BalanceCentsQueryHookResult = ReturnType<
  typeof useBalanceCentsQuery
>
export type BalanceCentsLazyQueryHookResult = ReturnType<
  typeof useBalanceCentsLazyQuery
>
export type BalanceCentsSuspenseQueryHookResult = ReturnType<
  typeof useBalanceCentsSuspenseQuery
>
export type BalanceCentsQueryResult = Apollo.QueryResult<
  BalanceCentsQuery,
  BalanceCentsQueryVariables
>
export const GetCashPositionByDurationDocument = gql`
  query getCashPositionByDuration(
    $duration: Int!
    $granularity: DateGranularity!
    $accountIds: [String!]
  ) {
    getCashPositionByDuration(
      duration: $duration
      granularity: $granularity
      accountIds: $accountIds
    ) {
      aggregates {
        cashDifferenceCents
        endDate
        endingBalanceCents
        categories {
          categoryId
          categoryLabel
          subCategories {
            categoryId
            categoryLabel
            merchants {
              merchantName
              merchantId
              totalCents
            }
            subCategories {
              categoryId
              categoryLabel
              merchants {
                merchantName
                merchantId
                totalCents
              }
              totalCents
            }
            totalCents
          }
          totalCents
          merchants {
            merchantName
            merchantId
            totalCents
          }
        }
        startDate
        startingBalanceCents
      }
      netInflowCents
      netOutflowCents
      syncTimestamp
    }
  }
`

/**
 * __useGetCashPositionByDurationQuery__
 *
 * To run a query within a React component, call `useGetCashPositionByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashPositionByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashPositionByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useGetCashPositionByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >(GetCashPositionByDurationDocument, options)
}
export function useGetCashPositionByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >(GetCashPositionByDurationDocument, options)
}
export function useGetCashPositionByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCashPositionByDurationQuery,
    GetCashPositionByDurationQueryVariables
  >(GetCashPositionByDurationDocument, options)
}
export type GetCashPositionByDurationQueryHookResult = ReturnType<
  typeof useGetCashPositionByDurationQuery
>
export type GetCashPositionByDurationLazyQueryHookResult = ReturnType<
  typeof useGetCashPositionByDurationLazyQuery
>
export type GetCashPositionByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetCashPositionByDurationSuspenseQuery
>
export type GetCashPositionByDurationQueryResult = Apollo.QueryResult<
  GetCashPositionByDurationQuery,
  GetCashPositionByDurationQueryVariables
>
export const GetCashPositionSeriesByDurationDocument = gql`
  query getCashPositionSeriesByDuration(
    $duration: Int!
    $granularity: DateGranularity!
  ) {
    getCashPositionSeriesByDuration(
      duration: $duration
      granularity: $granularity
    ) {
      balanceCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          total
        }
      }
      cashInflowCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          total
        }
      }
      cashOutflowCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          total
        }
      }
    }
  }
`

/**
 * __useGetCashPositionSeriesByDurationQuery__
 *
 * To run a query within a React component, call `useGetCashPositionSeriesByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashPositionSeriesByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashPositionSeriesByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetCashPositionSeriesByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >(GetCashPositionSeriesByDurationDocument, options)
}
export function useGetCashPositionSeriesByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >(GetCashPositionSeriesByDurationDocument, options)
}
export function useGetCashPositionSeriesByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCashPositionSeriesByDurationQuery,
    GetCashPositionSeriesByDurationQueryVariables
  >(GetCashPositionSeriesByDurationDocument, options)
}
export type GetCashPositionSeriesByDurationQueryHookResult = ReturnType<
  typeof useGetCashPositionSeriesByDurationQuery
>
export type GetCashPositionSeriesByDurationLazyQueryHookResult = ReturnType<
  typeof useGetCashPositionSeriesByDurationLazyQuery
>
export type GetCashPositionSeriesByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetCashPositionSeriesByDurationSuspenseQuery
>
export type GetCashPositionSeriesByDurationQueryResult = Apollo.QueryResult<
  GetCashPositionSeriesByDurationQuery,
  GetCashPositionSeriesByDurationQueryVariables
>
export const CreateOrFindCompnayMerchantDocument = gql`
  mutation CreateOrFindCompnayMerchant($merchantName: String!) {
    createOrFindCompanyMerchant(merchantName: $merchantName) {
      id
      merchantName
    }
  }
`
export type CreateOrFindCompnayMerchantMutationFn = Apollo.MutationFunction<
  CreateOrFindCompnayMerchantMutation,
  CreateOrFindCompnayMerchantMutationVariables
>

/**
 * __useCreateOrFindCompnayMerchantMutation__
 *
 * To run a mutation, you first call `useCreateOrFindCompnayMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrFindCompnayMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrFindCompnayMerchantMutation, { data, loading, error }] = useCreateOrFindCompnayMerchantMutation({
 *   variables: {
 *      merchantName: // value for 'merchantName'
 *   },
 * });
 */
export function useCreateOrFindCompnayMerchantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrFindCompnayMerchantMutation,
    CreateOrFindCompnayMerchantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateOrFindCompnayMerchantMutation,
    CreateOrFindCompnayMerchantMutationVariables
  >(CreateOrFindCompnayMerchantDocument, options)
}
export type CreateOrFindCompnayMerchantMutationHookResult = ReturnType<
  typeof useCreateOrFindCompnayMerchantMutation
>
export type CreateOrFindCompnayMerchantMutationResult = Apollo.MutationResult<CreateOrFindCompnayMerchantMutation>
export type CreateOrFindCompnayMerchantMutationOptions = Apollo.BaseMutationOptions<
  CreateOrFindCompnayMerchantMutation,
  CreateOrFindCompnayMerchantMutationVariables
>
export const GetCacByDurationDocument = gql`
  query GetCacByDuration($duration: Int!, $granularity: DateGranularity!) {
    CacByDuration(duration: $duration, granularity: $granularity) {
      totalCac {
        timeSeries {
          start
          end
          value
        }
        total
        trends {
          currentVsPrevious
          currentVsIntervalAverage
        }
      }
    }
  }
`

/**
 * __useGetCacByDurationQuery__
 *
 * To run a query within a React component, call `useGetCacByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCacByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCacByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetCacByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCacByDurationQuery,
    GetCacByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCacByDurationQuery, GetCacByDurationQueryVariables>(
    GetCacByDurationDocument,
    options,
  )
}
export function useGetCacByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCacByDurationQuery,
    GetCacByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCacByDurationQuery,
    GetCacByDurationQueryVariables
  >(GetCacByDurationDocument, options)
}
export function useGetCacByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCacByDurationQuery,
    GetCacByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCacByDurationQuery,
    GetCacByDurationQueryVariables
  >(GetCacByDurationDocument, options)
}
export type GetCacByDurationQueryHookResult = ReturnType<
  typeof useGetCacByDurationQuery
>
export type GetCacByDurationLazyQueryHookResult = ReturnType<
  typeof useGetCacByDurationLazyQuery
>
export type GetCacByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetCacByDurationSuspenseQuery
>
export type GetCacByDurationQueryResult = Apollo.QueryResult<
  GetCacByDurationQuery,
  GetCacByDurationQueryVariables
>
export const GetCustomersByDurationDocument = gql`
  query GetCustomersByDuration(
    $duration: Int!
    $granularity: DateGranularity!
  ) {
    CustomersByDuration(duration: $duration, granularity: $granularity) {
      newCustomers {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          total
          timeSeries {
            start
            end
            value
          }
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
    }
  }
`

/**
 * __useGetCustomersByDurationQuery__
 *
 * To run a query within a React component, call `useGetCustomersByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetCustomersByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >(GetCustomersByDurationDocument, options)
}
export function useGetCustomersByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >(GetCustomersByDurationDocument, options)
}
export function useGetCustomersByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCustomersByDurationQuery,
    GetCustomersByDurationQueryVariables
  >(GetCustomersByDurationDocument, options)
}
export type GetCustomersByDurationQueryHookResult = ReturnType<
  typeof useGetCustomersByDurationQuery
>
export type GetCustomersByDurationLazyQueryHookResult = ReturnType<
  typeof useGetCustomersByDurationLazyQuery
>
export type GetCustomersByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomersByDurationSuspenseQuery
>
export type GetCustomersByDurationQueryResult = Apollo.QueryResult<
  GetCustomersByDurationQuery,
  GetCustomersByDurationQueryVariables
>
export const GetTransactionAccountsDocument = gql`
  query getTransactionAccounts {
    transactionAccounts {
      accountId
      accountMask
      accountName
      accountOfficialName
      accountSubtype
      accountType
    }
  }
`

/**
 * __useGetTransactionAccountsQuery__
 *
 * To run a query within a React component, call `useGetTransactionAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >(GetTransactionAccountsDocument, options)
}
export function useGetTransactionAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >(GetTransactionAccountsDocument, options)
}
export function useGetTransactionAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTransactionAccountsQuery,
    GetTransactionAccountsQueryVariables
  >(GetTransactionAccountsDocument, options)
}
export type GetTransactionAccountsQueryHookResult = ReturnType<
  typeof useGetTransactionAccountsQuery
>
export type GetTransactionAccountsLazyQueryHookResult = ReturnType<
  typeof useGetTransactionAccountsLazyQuery
>
export type GetTransactionAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetTransactionAccountsSuspenseQuery
>
export type GetTransactionAccountsQueryResult = Apollo.QueryResult<
  GetTransactionAccountsQuery,
  GetTransactionAccountsQueryVariables
>
export const GetTransactionCategoriesDocument = gql`
  query getTransactionCategories {
    transactionCategories {
      id
      label
      parentId
      externalId
      rank
    }
  }
`

/**
 * __useGetTransactionCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTransactionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >(GetTransactionCategoriesDocument, options)
}
export function useGetTransactionCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >(GetTransactionCategoriesDocument, options)
}
export function useGetTransactionCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTransactionCategoriesQuery,
    GetTransactionCategoriesQueryVariables
  >(GetTransactionCategoriesDocument, options)
}
export type GetTransactionCategoriesQueryHookResult = ReturnType<
  typeof useGetTransactionCategoriesQuery
>
export type GetTransactionCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetTransactionCategoriesLazyQuery
>
export type GetTransactionCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetTransactionCategoriesSuspenseQuery
>
export type GetTransactionCategoriesQueryResult = Apollo.QueryResult<
  GetTransactionCategoriesQuery,
  GetTransactionCategoriesQueryVariables
>
export const GetTransactionMerchantsDocument = gql`
  query getTransactionMerchants {
    transactionMerchants {
      merchantId
      merchantName
    }
  }
`

/**
 * __useGetTransactionMerchantsQuery__
 *
 * To run a query within a React component, call `useGetTransactionMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionMerchantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionMerchantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >(GetTransactionMerchantsDocument, options)
}
export function useGetTransactionMerchantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >(GetTransactionMerchantsDocument, options)
}
export function useGetTransactionMerchantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTransactionMerchantsQuery,
    GetTransactionMerchantsQueryVariables
  >(GetTransactionMerchantsDocument, options)
}
export type GetTransactionMerchantsQueryHookResult = ReturnType<
  typeof useGetTransactionMerchantsQuery
>
export type GetTransactionMerchantsLazyQueryHookResult = ReturnType<
  typeof useGetTransactionMerchantsLazyQuery
>
export type GetTransactionMerchantsSuspenseQueryHookResult = ReturnType<
  typeof useGetTransactionMerchantsSuspenseQuery
>
export type GetTransactionMerchantsQueryResult = Apollo.QueryResult<
  GetTransactionMerchantsQuery,
  GetTransactionMerchantsQueryVariables
>
export const GetTransactionsByDateRangeDocument = gql`
  query getTransactionsByDateRange(
    $accountIds: [String!]
    $endDate: UTCDate
    $startDate: UTCDate
  ) {
    enrichedTransactionsByDateRange(
      accountIds: $accountIds
      endDate: $endDate
      startDate: $startDate
    ) {
      id
      accountId
      accountMask
      accountName
      accountOfficialName
      accountSubtype
      accountType
      amountCents
      cacheDate
      categoryId
      categoryLabel
      companyId
      confidence
      currency
      description
      descriptionClean
      externalId
      institutionId
      institutionName
      merchantId
      merchantName
      platformId
      status
      transactionTimestamp
      transactionDate
    }
  }
`

/**
 * __useGetTransactionsByDateRangeQuery__
 *
 * To run a query within a React component, call `useGetTransactionsByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsByDateRangeQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetTransactionsByDateRangeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options)
}
export function useGetTransactionsByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options)
}
export function useGetTransactionsByDateRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options)
}
export type GetTransactionsByDateRangeQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeQuery
>
export type GetTransactionsByDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeLazyQuery
>
export type GetTransactionsByDateRangeSuspenseQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeSuspenseQuery
>
export type GetTransactionsByDateRangeQueryResult = Apollo.QueryResult<
  GetTransactionsByDateRangeQuery,
  GetTransactionsByDateRangeQueryVariables
>
export const UpdateTransactionLabelsDocument = gql`
  mutation updateTransactionLabels($transactions: [UpdatedLabelInput!]!) {
    updateEnrichedTransactionLabels(transactions: $transactions) {
      id
      accountId
      accountMask
      accountName
      accountOfficialName
      accountSubtype
      accountType
      amountCents
      cacheDate
      categoryId
      categoryLabel
      companyId
      confidence
      currency
      description
      descriptionClean
      externalId
      institutionId
      institutionName
      merchantId
      merchantName
      platformId
      status
      transactionTimestamp
      transactionDate
    }
  }
`
export type UpdateTransactionLabelsMutationFn = Apollo.MutationFunction<
  UpdateTransactionLabelsMutation,
  UpdateTransactionLabelsMutationVariables
>

/**
 * __useUpdateTransactionLabelsMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionLabelsMutation, { data, loading, error }] = useUpdateTransactionLabelsMutation({
 *   variables: {
 *      transactions: // value for 'transactions'
 *   },
 * });
 */
export function useUpdateTransactionLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransactionLabelsMutation,
    UpdateTransactionLabelsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTransactionLabelsMutation,
    UpdateTransactionLabelsMutationVariables
  >(UpdateTransactionLabelsDocument, options)
}
export type UpdateTransactionLabelsMutationHookResult = ReturnType<
  typeof useUpdateTransactionLabelsMutation
>
export type UpdateTransactionLabelsMutationResult = Apollo.MutationResult<UpdateTransactionLabelsMutation>
export type UpdateTransactionLabelsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransactionLabelsMutation,
  UpdateTransactionLabelsMutationVariables
>
export const GetInsightDocument = gql`
  query GetInsight($duration: Int, $granularity: DateGranularity) {
    InsightResult(duration: $duration, granularity: $granularity) {
      content
    }
  }
`

/**
 * __useGetInsightQuery__
 *
 * To run a query within a React component, call `useGetInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsightQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetInsightQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInsightQuery,
    GetInsightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInsightQuery, GetInsightQueryVariables>(
    GetInsightDocument,
    options,
  )
}
export function useGetInsightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInsightQuery,
    GetInsightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInsightQuery, GetInsightQueryVariables>(
    GetInsightDocument,
    options,
  )
}
export function useGetInsightSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInsightQuery,
    GetInsightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetInsightQuery, GetInsightQueryVariables>(
    GetInsightDocument,
    options,
  )
}
export type GetInsightQueryHookResult = ReturnType<typeof useGetInsightQuery>
export type GetInsightLazyQueryHookResult = ReturnType<
  typeof useGetInsightLazyQuery
>
export type GetInsightSuspenseQueryHookResult = ReturnType<
  typeof useGetInsightSuspenseQuery
>
export type GetInsightQueryResult = Apollo.QueryResult<
  GetInsightQuery,
  GetInsightQueryVariables
>
export const GetOverviewLtvReportDocument = gql`
  query getOverviewLTVReport {
    LTVReport {
      projected {
        threeMonth {
          cac
          customerMargin
          grossMargin
          ltvToCacRatio
        }
        sixMonth {
          cac
          customerMargin
          grossMargin
          ltvToCacRatio
        }
        tweleveMonth {
          cac
          customerMargin
          grossMargin
          ltvToCacRatio
        }
      }
    }
  }
`

/**
 * __useGetOverviewLtvReportQuery__
 *
 * To run a query within a React component, call `useGetOverviewLtvReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewLtvReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewLtvReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOverviewLtvReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >(GetOverviewLtvReportDocument, options)
}
export function useGetOverviewLtvReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >(GetOverviewLtvReportDocument, options)
}
export function useGetOverviewLtvReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetOverviewLtvReportQuery,
    GetOverviewLtvReportQueryVariables
  >(GetOverviewLtvReportDocument, options)
}
export type GetOverviewLtvReportQueryHookResult = ReturnType<
  typeof useGetOverviewLtvReportQuery
>
export type GetOverviewLtvReportLazyQueryHookResult = ReturnType<
  typeof useGetOverviewLtvReportLazyQuery
>
export type GetOverviewLtvReportSuspenseQueryHookResult = ReturnType<
  typeof useGetOverviewLtvReportSuspenseQuery
>
export type GetOverviewLtvReportQueryResult = Apollo.QueryResult<
  GetOverviewLtvReportQuery,
  GetOverviewLtvReportQueryVariables
>
export const GetMarketingByDurationDocument = gql`
  query GetMarketingByDuration(
    $duration: Int!
    $granularity: DateGranularity!
  ) {
    MarketingByDuration(duration: $duration, granularity: $granularity) {
      marketingExpenseCents {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          total
          timeSeries {
            start
            end
            value
          }
          trends {
            currentVsIntervalAverage
            currentVsPrevious
          }
        }
      }
    }
  }
`

/**
 * __useGetMarketingByDurationQuery__
 *
 * To run a query within a React component, call `useGetMarketingByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketingByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketingByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetMarketingByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >(GetMarketingByDurationDocument, options)
}
export function useGetMarketingByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >(GetMarketingByDurationDocument, options)
}
export function useGetMarketingByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetMarketingByDurationQuery,
    GetMarketingByDurationQueryVariables
  >(GetMarketingByDurationDocument, options)
}
export type GetMarketingByDurationQueryHookResult = ReturnType<
  typeof useGetMarketingByDurationQuery
>
export type GetMarketingByDurationLazyQueryHookResult = ReturnType<
  typeof useGetMarketingByDurationLazyQuery
>
export type GetMarketingByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetMarketingByDurationSuspenseQuery
>
export type GetMarketingByDurationQueryResult = Apollo.QueryResult<
  GetMarketingByDurationQuery,
  GetMarketingByDurationQueryVariables
>
export const RoasByDurationDocument = gql`
  query RoasByDuration($duration: Int!, $granularity: DateGranularity!) {
    RoasByDuration(duration: $duration, granularity: $granularity) {
      roas {
        timeSeries {
          start
          end
          value
        }
        trends {
          currentVsPrevious
          currentVsIntervalAverage
        }
      }
    }
  }
`

/**
 * __useRoasByDurationQuery__
 *
 * To run a query within a React component, call `useRoasByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoasByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoasByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useRoasByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoasByDurationQuery,
    RoasByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RoasByDurationQuery, RoasByDurationQueryVariables>(
    RoasByDurationDocument,
    options,
  )
}
export function useRoasByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoasByDurationQuery,
    RoasByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RoasByDurationQuery, RoasByDurationQueryVariables>(
    RoasByDurationDocument,
    options,
  )
}
export function useRoasByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RoasByDurationQuery,
    RoasByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RoasByDurationQuery,
    RoasByDurationQueryVariables
  >(RoasByDurationDocument, options)
}
export type RoasByDurationQueryHookResult = ReturnType<
  typeof useRoasByDurationQuery
>
export type RoasByDurationLazyQueryHookResult = ReturnType<
  typeof useRoasByDurationLazyQuery
>
export type RoasByDurationSuspenseQueryHookResult = ReturnType<
  typeof useRoasByDurationSuspenseQuery
>
export type RoasByDurationQueryResult = Apollo.QueryResult<
  RoasByDurationQuery,
  RoasByDurationQueryVariables
>
export const GetSalesForecastByDurationDocument = gql`
  query getSalesForecastByDuration(
    $duration: Int!
    $granularity: DateGranularity!
    $storeIds: [String!]
  ) {
    salesForecastByDuration(
      duration: $duration
      granularity: $granularity
      storeIds: $storeIds
    ) {
      stores {
        storeId
        storeName
        actuals {
          start
          end
          value
          upperConfidenceBound
          lowerConfidenceBound
          linearTrend
        }
        forecasted {
          start
          end
          value
          upperConfidenceBound
          lowerConfidenceBound
          linearTrend
        }
      }
      combined {
        actuals {
          start
          end
          value
          upperConfidenceBound
          lowerConfidenceBound
          linearTrend
        }
        forecasted {
          start
          end
          value
          upperConfidenceBound
          lowerConfidenceBound
          linearTrend
        }
      }
      totalActualValue
      totalProjectedValue
    }
  }
`

/**
 * __useGetSalesForecastByDurationQuery__
 *
 * To run a query within a React component, call `useGetSalesForecastByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesForecastByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesForecastByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useGetSalesForecastByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >(GetSalesForecastByDurationDocument, options)
}
export function useGetSalesForecastByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >(GetSalesForecastByDurationDocument, options)
}
export function useGetSalesForecastByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetSalesForecastByDurationQuery,
    GetSalesForecastByDurationQueryVariables
  >(GetSalesForecastByDurationDocument, options)
}
export type GetSalesForecastByDurationQueryHookResult = ReturnType<
  typeof useGetSalesForecastByDurationQuery
>
export type GetSalesForecastByDurationLazyQueryHookResult = ReturnType<
  typeof useGetSalesForecastByDurationLazyQuery
>
export type GetSalesForecastByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetSalesForecastByDurationSuspenseQuery
>
export type GetSalesForecastByDurationQueryResult = Apollo.QueryResult<
  GetSalesForecastByDurationQuery,
  GetSalesForecastByDurationQueryVariables
>
export const GetSalesForecastStoresDocument = gql`
  query getSalesForecastStores {
    salesForecastStores {
      id
      name
    }
  }
`

/**
 * __useGetSalesForecastStoresQuery__
 *
 * To run a query within a React component, call `useGetSalesForecastStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesForecastStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesForecastStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesForecastStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >(GetSalesForecastStoresDocument, options)
}
export function useGetSalesForecastStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >(GetSalesForecastStoresDocument, options)
}
export function useGetSalesForecastStoresSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetSalesForecastStoresQuery,
    GetSalesForecastStoresQueryVariables
  >(GetSalesForecastStoresDocument, options)
}
export type GetSalesForecastStoresQueryHookResult = ReturnType<
  typeof useGetSalesForecastStoresQuery
>
export type GetSalesForecastStoresLazyQueryHookResult = ReturnType<
  typeof useGetSalesForecastStoresLazyQuery
>
export type GetSalesForecastStoresSuspenseQueryHookResult = ReturnType<
  typeof useGetSalesForecastStoresSuspenseQuery
>
export type GetSalesForecastStoresQueryResult = Apollo.QueryResult<
  GetSalesForecastStoresQuery,
  GetSalesForecastStoresQueryVariables
>
export const GetSalesByDurationDocument = gql`
  query GetSalesByDuration($duration: Int!, $granularity: DateGranularity!) {
    SalesByDuration(duration: $duration, granularity: $granularity) {
      averageOrderValue {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
      orderVolume {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
      unitVolume {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
      revenue {
        channels {
          name
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
        totals {
          timeSeries {
            start
            end
            value
          }
          total
          trends {
            currentVsPrevious
            currentVsIntervalAverage
          }
        }
      }
    }
  }
`

/**
 * __useGetSalesByDurationQuery__
 *
 * To run a query within a React component, call `useGetSalesByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesByDurationQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetSalesByDurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >(GetSalesByDurationDocument, options)
}
export function useGetSalesByDurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >(GetSalesByDurationDocument, options)
}
export function useGetSalesByDurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetSalesByDurationQuery,
    GetSalesByDurationQueryVariables
  >(GetSalesByDurationDocument, options)
}
export type GetSalesByDurationQueryHookResult = ReturnType<
  typeof useGetSalesByDurationQuery
>
export type GetSalesByDurationLazyQueryHookResult = ReturnType<
  typeof useGetSalesByDurationLazyQuery
>
export type GetSalesByDurationSuspenseQueryHookResult = ReturnType<
  typeof useGetSalesByDurationSuspenseQuery
>
export type GetSalesByDurationQueryResult = Apollo.QueryResult<
  GetSalesByDurationQuery,
  GetSalesByDurationQueryVariables
>
export type Date = Scalars['Date']
export type Json = Scalars['JSON']
export type UtcDate = Scalars['UTCDate']
