import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { BulkActionModalProps } from './BulkActionModal.types'
import { Category, Merchant } from '../../Transactions.types'
import { TransactionsTableAutocomplete } from '../TransactionsTableAutocomplete/TransactionsTableAutocomplete'
import { AddMerchantButton } from '../TransactionsTableAutocomplete/AddMerchantButton/AddMerchantButton'

import { BasicModal } from '../../../../../components/designSystem/organisms/BasicModal/BasicModal'

export const BulkActionModal = ({
  handleApplyBulkUpdate,
  handleClose,
  handleOpenAddMerchantModal,
  isOpen,
  options,
  transactionCount,
}: BulkActionModalProps): JSX.Element => {
  const [value, setValue] = useState<Category | Merchant | null>(options[0])
  const [isLoading, setIsLoading] = useState(false)

  const isCategoryModal = 'label' in options[0]
  const version = isCategoryModal ? 'Category' : 'Merchant'

  const handleApplyClick = async (): Promise<void> => {
    if (!value) return
    setIsLoading(true)

    try {
      await handleApplyBulkUpdate(value)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasicModal
      isOpen={isOpen}
      height={100}
      width={100}
      handleClose={handleClose}
      onDismiss={handleClose}
      buttonJustifyContent="center"
      renderContent={(): JSX.Element => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            height: '300px',
            justifyContent: 'center',
            justifyItems: 'center',
            padding: '25px 50px',
            width: '600px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Typography variant="h7">Update {version} Labels</Typography>
            <Typography variant="body2">
              This action will apply the selected {version.toLocaleLowerCase()}{' '}
              label to <b>{transactionCount}</b> transactions
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <TransactionsTableAutocomplete
              options={options}
              onChange={(newValue: Category | Merchant | null): void =>
                setValue(newValue)
              }
              DropDownAdditon={
                isCategoryModal ? undefined : (
                  <AddMerchantButton
                    onClick={(): void => {
                      handleClose()
                      handleOpenAddMerchantModal()
                    }}
                  />
                )
              }
            />
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button color="inherit" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={handleApplyClick}
                variant="contained"
                disabled={isLoading}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}
