{
    "name": "@shopify/app-bridge",
    "version": "1.17.0",
    "types": "index.d.ts",
    "main": "index.js",
    "unpkg": "umd/index.js",
    "jsdelivr": "umd/index.js",
    "files": [
        "/actions/",
        "/client/",
        "/umd/",
        "/util/",
        "/validate/",
        "/MessageTransport.d.ts",
        "/MessageTransport.js",
        "/development.d.ts",
        "/development.js",
        "/index.d.ts",
        "/index.js",
        "/production.d.ts",
        "/production.js"
    ],
    "private": false,
    "publishConfig": {
        "access": "public",
        "@shopify:registry": "https://registry.npmjs.org"
    },
    "repository": "git@github.com:Shopify/app-bridge.git",
    "author": "Shopify Inc.",
    "license": "MIT",
    "scripts": {
        "build": "yarn build:tsc && yarn build:umd",
        "build:tsc": "NODE_ENV=production tsc",
        "build:umd": "NODE_ENV=production webpack -p",
        "check": "tsc --pretty --noEmit",
        "clean": "cat package.json | node -pe \"JSON.parse(require('fs').readFileSync('/dev/stdin').toString()).files.map(f => './'+f).join(' ')\" | xargs rm -rf",
        "pack": "yarn pack",
        "size": "size-limit"
    },
    "sideEffects": false,
    "size-limit": [
        {
            "limit": "16 KB",
            "path": "production.js"
        }
    ],
    "devDependencies": {
        "@types/node": "^10.12.5",
        "typescript": "3.2.1"
    },
    "gitHead": "76f02f55090b6fa419b722be7dfe1fd6b399cbb9"
}
